import { baseUrl } from "../../../../../Utility/Api/Urls";
import axios from "axios";
import { getAuthConfig } from "../../../../../Utility/Api/auth";

/**
 * Represents a day of the week
 * @property {String} dayName name of the day in all caps
 * @property {Boolean} isWorkDay false by default
 * @property {Array} opening includes objects with open & close times. empty by default
 */
class Day {
  constructor(dayName, isWorkDay = false, opening = []) {
    this.dayName = dayName;
    this.isWorkDay = isWorkDay;
    this.opening = opening;
  }

  /**
   * Creates an opening objects and pushes to opening array
   * @param {Object} openTime openTime of a session received from GMB endpoint
   * @param {Object} closeTime closeTime of a session received from GMB endpoint
   */
  createOpening(openTime, closeTime) {
    const newOpening = {
      openTime: {
        hours: openTime.hours,
        // hours: !openTime.hours || openTime.hours > 23 ? 9 : openTime.hours,
        minutes: openTime.minutes,
        // minutes: openTime.minutes || 0,
      },
      closeTime: {
        hours: closeTime.hours,
        // hours: !closeTime.hours || closeTime.hours > 23 ? 12 : closeTime.hours,
        minutes: closeTime.minutes,
        // minutes: closeTime.minutes || 0,
      },
    };
    this.opening.push(newOpening);
  }
  static toGMBSessions(data) {
    return data.opening.map((o) =>
      data.isWorkDay
        ? {
          closeDay: data.dayName,
          closeTime: {
            hours: o.closeTime.hours > 23 ? 0 : o.closeTime.hours,
            minutes: o.closeTime.minutes,
            // minutes: o.closeTime.minutes || 0,
          },
          openDay: data.dayName,
          openTime: {
            hours: o.openTime.hours,
            // minutes: o.openTime.minutes || 0,
            minutes: o.openTime.minutes,
          },
        }
        : {
          closeDay: data.dayName,
          closeTime: {
            hours: null,
            minutes: null,
          },
          openDay: data.dayName,
          openTime: {
            hours: null,
            minutes: null,
          },
        }
    );
  }
}
/**
 * Creates workable working hours object for rendering
 * @param {Object} regularHours regularHours as received from GMB endpoint
 */
function mapToLocalWorkingHours(regularHours) {
  const mappedDays = days.map((day) => new Day(day));
  return regularHours.reduce((mappedDays, session) => {
    const thisDay = mappedDays.find((d) => d.dayName === session.openDay);
    if (thisDay.dayName === session.openDay) {
      if (session?.openTime?.hours !== null) thisDay.isWorkDay = true;
      thisDay.createOpening(session.openTime, session.closeTime);
    }
    return mappedDays;
  }, mappedDays).map((e) =>
    !e.opening.length || !e.isWorkDay
      ? {
        ...e,
        opening: [
          {
            openTime: { hours: 0, minutes: 0 },
            closeTime: { hours: 0, minutes: 0 },
          },
        ],
      }
      : e
  )
}

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export { Day, mapToLocalWorkingHours, days };

const getUrl = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/locationData/get?showProvisional=true`;

export async function httpGetLocationDetails() {
  try {
    const res = await axios.get(getUrl, getAuthConfig())
    return res
  } catch (error) {
    throw console.log(error)
  }
}


export function handleResponseFormat(res) {
  return {
    title: res.data?.locationData?.name,
    primaryCategory: {
      primaryCategory: res.data?.locationData.primaryCategory,
      primaryCategoryDisplayName: res.data?.locationData.primaryCategoryDisplaName,
    },
    secondaryCategory: res.data?.locationData?.secondaryCategory?.map((cat, i) => ({
      secondaryCategory: cat,
      secondaryCategoryDisplayName:
        res.data?.locationData.secondaryCategoryDisplayName[i],
    }))
      || [],
    address: res.data?.locationData?.address,
    serviceArea: res.data?.locationData?.serviceArea,
    phoneNumber: res.data?.locationData?.primaryPhoneNumber,
    description: res.data?.locationData?.description,
  }
}



const patchUrl = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/locationData/requestChange`;


export async function handleRequestChange(localWorkingHoursData, patchData, patchData1) {

  const periods = localWorkingHoursData.reduce((allHours, current) => {
    const sessions = Day.toGMBSessions(current);
    if (sessions) allHours.push(...sessions);
    return allHours;
  }, []);
  const payload = {
    name: patchData1.name,
    phoneNumber: patchData.phoneNumber,
    address: patchData.address,
    periods: periods,
    description: patchData.description,
    primaryCategory: patchData.primaryCategory.primaryCategory.replace(
      "categories/",
      ""
    ),
    secondaryCategory: patchData.secondaryCategory.map((secondaryCat) =>
      secondaryCat.secondaryCategory.replace("categories/", "")
    ),
  };
  try {
    const res = await axios.post(patchUrl, payload, getAuthConfig())
    return res
  } catch (error) {
    throw console.log(error)
  }
}





const patchFbUrl = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/locationData/changeonfb`;

function formatHours(hour) {
  return `${hour?.toString().padStart(2, '0')}:00` || null
}
function filterEmptyKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== '') {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}


const FbPayload = (localWorkingHoursData) => {
  return {

    //monday//
    mon_1_open: formatHours(localWorkingHoursData[0]?.opening[0]?.openTime?.hours),
    mon_1_close: formatHours(localWorkingHoursData[0]?.opening[0]?.closeTime?.hours),
    mon_2_open: formatHours(localWorkingHoursData[0]?.opening[1]?.openTime?.hours),
    mon_2_close: formatHours(localWorkingHoursData[0]?.opening[1]?.closeTime?.hours),

    //tuesday//
    tue_1_open: formatHours(localWorkingHoursData[1]?.opening[0]?.openTime?.hours),
    tue_1_close: formatHours(localWorkingHoursData[1]?.opening[0]?.closeTime?.hours),
    tue_2_open: formatHours(localWorkingHoursData[1]?.opening[1]?.openTime?.hours),
    tue_2_close: formatHours(localWorkingHoursData[1]?.opening[1]?.closeTime?.hours),

    //wednesday//
    wed_1_open: formatHours(localWorkingHoursData[2]?.opening[0]?.openTime?.hours),
    wed_1_close: formatHours(localWorkingHoursData[2]?.opening[0]?.closeTime?.hours),
    wed_2_open: formatHours(localWorkingHoursData[2]?.opening[1]?.openTime?.hours),
    wed_2_close: formatHours(localWorkingHoursData[2]?.opening[1]?.closeTime?.hours),

    //thursday//
    thu_1_open: formatHours(localWorkingHoursData[3]?.opening[0]?.openTime?.hours),
    thu_1_close: formatHours(localWorkingHoursData[3]?.opening[0]?.closeTime?.hours),
    thu_2_open: formatHours(localWorkingHoursData[3]?.opening[1]?.openTime?.hours),
    thu_2_close: formatHours(localWorkingHoursData[3]?.opening[1]?.closeTime?.hours),

    //friday//
    fri_1_open: formatHours(localWorkingHoursData[4]?.opening[0]?.openTime?.hours),
    fri_1_close: formatHours(localWorkingHoursData[4]?.opening[0]?.closeTime?.hours),
    fri_2_open: formatHours(localWorkingHoursData[4]?.opening[1]?.openTime?.hours),
    fri_2_close: formatHours(localWorkingHoursData[4]?.opening[1]?.closeTime?.hours),

    //saturday//
    sat_1_open: formatHours(localWorkingHoursData[5]?.opening[0]?.openTime?.hours),
    sat_1_close: formatHours(localWorkingHoursData[5]?.opening[0]?.closeTime?.hours),
    sat_2_open: formatHours(localWorkingHoursData[5]?.opening[1]?.openTime?.hours),
    sat_2_close: formatHours(localWorkingHoursData[5]?.opening[1]?.closeTime?.hours),

    //sunday//
    sun_1_open: formatHours(localWorkingHoursData[6]?.opening[0]?.openTime?.hours),
    sun_1_close: formatHours(localWorkingHoursData[6]?.opening[0]?.closeTime?.hours),
    sun_2_open: formatHours(localWorkingHoursData[6]?.opening[1]?.openTime?.hours),
    sun_2_close: formatHours(localWorkingHoursData[6]?.opening[1]?.closeTime?.hours),
  };

}

export async function httpChangeOnFB(localWorkingHoursData) {

  const filteredFbPayload = filterEmptyKeys(FbPayload(localWorkingHoursData))

  try {
    const res = axios.post(patchFbUrl, filteredFbPayload, getAuthConfig())
    return res
  } catch (error) {
    throw console.log(error)
  }
}