import { React, useState, createContext, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";
export const ResultsCalendarContext = createContext();
export const ResultsCalendarProvider = ({ children }) => {
  const months = [
    { name: "Gennaio", number: 1 },
    { name: "Febbraio", number: 2 },
    { name: "Marzo", number: 3 },
    { name: "Aprile", number: 4 },
    { name: "Maggio", number: 5 },
    { name: "Giugno", number: 6 },
    { name: "Luglio", number: 7 },
    { name: "Agosto", number: 8 },
    { name: "Settembre", number: 9 },
    { name: "Ottobre", number: 10 },
    { name: "Novembre", number: 11 },
    { name: "Dicembre", number: 12 },
  ];

  const years = [
    { value: 2020 },
    { value: 2021 },
    { value: 2022 },
    { value: 2023 },
    { value: 2024 },
    { value: 2025 },
    { value: 2026 },
    { value: 2027 },
  ];
  function getCurrentMonthName() {
    const currentMonth = new Date().getMonth();
    return months[currentMonth].name;
  }

  
  const [dataPerMonth, setDataPerMonth] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({
    from: getCurrentMonthName(),
    to: getCurrentMonthName(),
  });
  const currentDate = new Date();
let currentMonth = currentDate.getMonth() + 1;
let currentYear = currentDate.getFullYear();
const [monthsForResults, setMonthsForResults] = useState([`${currentMonth}-${currentYear}`, `${currentMonth}-${currentYear}`]);

  const [type, setType] = useState("single");
  const setMonths = ({ from, to }) => {
    setSelectedMonths((prevState) => {
      return {
        from: from ?? prevState.from,
        to: to ?? prevState.to,
      };
    });
  };

const fetchData = async (typeToShow) => {
  try {
    if (monthsForResults?.length > 0) {
      let url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/analytics/getAnalyticsAccordingToMonths`;

      let startMonthYear = "";
      let endMonthYear = "";

      if (typeToShow === "single") {
        startMonthYear = monthsForResults[0];
        endMonthYear = monthsForResults[0];
      } else {
        startMonthYear = monthsForResults[0];
        endMonthYear = monthsForResults[1];
      }

      url += `?startMonthYear=${startMonthYear}&endMonthYear=${endMonthYear}`;

      const response = await axios.get(url, getAuthConfig());
      setDataPerMonth(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};


  useEffect(() => {
    fetchData();
  }, [monthsForResults]);
  const value = {
    selectedMonths,
    setMonths,
    months,
    getCurrentMonthName,
    dataPerMonth,
    type,
    setType,
    setMonthsForResults,
    monthsForResults,
    years,
    fetchData,
  };
  return (
    <ResultsCalendarContext.Provider value={value}>
      {children}
    </ResultsCalendarContext.Provider>
  );
};
