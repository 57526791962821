import React from 'react'
import BackArrow from "../../../../../Assets/Icons/BackArrow.svg";
import { useNavigate } from "react-router-dom"
import { Area, AreaChart, Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
import Carousel from "react-multi-carousel";


const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { totalItems, slidesToShow, currentSlide } } = rest;

    return (
        <>
            <button
                className="absolute right-0 top-0 bottom-0 my-auto h-fit hover:bg-gray-100 rounded-full border elevation-1 p-2"
                onClick={() => next()}
                disabled={totalItems - slidesToShow - currentSlide <= 0}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 18l6-6-6-6" />
                </svg>

            </button>

            <button
                className="absolute left-0 top-0 bottom-0 my-auto h-fit hover:bg-gray-100 rounded-full border elevation-1 p-2 "
                onClick={() => previous()}
                disabled={currentSlide === 0}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 18l-6-6 6-6" />
                </svg>
            </button>
        </>
    );
};

function SeeAllEmails() {
    const navigate = useNavigate()
    const data = [
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,

        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,

        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,

        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,

        },
        {
            name: "Page E",
            uv: 1890,
            pv: 4800,

        },
        {
            name: "Page F",
            uv: 2390,
            pv: 3800,

        },
        {
            name: "Page G",
            uv: 3490,
            pv: 4300,

        }
    ];

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1366,
            },
            items: 6,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
        },
        tablet: {
            breakpoint: {
                max: 1366,
                min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
        },
    };
    const Dati =
        [
            {
                id: 1,
                title: "Email Inviate",
                subtitle: "3,775",
                subtitle2: "+123,2"
            },
            {
                id: 2,
                title: "Totali Aperte",
                subtitle: "650",
                subtitle2: "+123,2"
            },
            {
                id: 3,
                title: "Open Rate",
                subtitle: "23%",
                subtitle2: "-23,2"
            },
            {
                id: 4,
                title: "Click Totali",
                subtitle: "67",
                subtitle2: "-23,2"
            },
            {
                id: 5,
                title: "Click Rate",
                subtitle: "67",
                subtitle2: "-23,2"
            },
            {
                id: 6,
                title: "Disiscritto",
                subtitle: "12",
                subtitle2: "-23,2"
            }
        ]
    return (
        <>
            <div className='flex flex-col w-[90%] mx-auto mt-7' >
                <button onClick={() => navigate(-1)} className="bg-newsletterbtn w-[99px]  h-[38px] rounded-md flex flex-row items-center gap-x-1 text-white justify-center "><img src={BackArrow} alt="Back Arrow" /> Back</button>
                <div className="flex flex-row gap-x-6 bg-[#F8F8F8] rounded-md p-5 w-fit mx-auto" >
                    <button className="w-[350.5px] h-[33px] rounded-md bg-newsletterbtn text-white" > Crea Nuova Email </button>
                </div>


                <div className="relative  px-8 py-8 w-[95%] self-center   mx-auto mb-4  ">

                    <Carousel
                        additionalTransfrom={0}
                        // autoPlay is bugged, for now, we are putting high autoPlaySpeed
                        // https://github.com/YIZHUANG/react-multi-carousel/issues/261
                        autoPlay={false}
                        autoPlaySpeed={9999999999}
                        arrows={false}
                        centerMode={false}
                        draggable={false}
                        infinite={false}
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        responsive={responsive}
                        focusOnSelect={false}
                        slidesToSlide={1}
                    >
                        {Dati.map((item) => {
                            return (
                                <div className='flex flex-col gap-y-2 items-center justify-center border-r mx-auto pr-7 w-[170px] h-[200px] ' >
                                    <p className='text-newslettertextcolour text-2xl font-semibold'>{item.title}</p>
                                    <div className='flex flex-row gap-x-2 items-center'>

                                        <p className="font-semibold text-black text-xl ">{item.subtitle}</p>
                                        <p className={`${item.subtitle2.startsWith("+") ? "text-[#1ABA80]" : "text-[#D6542B]"} `}>{item.subtitle2}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
                <div className='w-full h-[531px] flex flex-row gap-x-7 mt-8 ' >
                    <div className='w-1/2 h-full py-8 elevation-1 rounded-md' >
                        <p className='text-left text-newslettertextcolour font-semibold text-xl pl-8'>Email Aperte per giorno</p>
                        <ResponsiveContainer>
                            <AreaChart

                                data={data}
                                margin={{
                                    top: 50,
                                    right: 20,
                                    left: 10,
                                    bottom: 10
                                }}
                            >
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area
                                    type="monotone"
                                    dataKey="uv"
                                    stackId="1"
                                    stroke="#2B65BB"
                                    fill="rgba(43, 101, 187, 0.23)"
                                />


                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='w-1/2 h-full p-8 elevation-1 rounded-md' >
                        <p className='text-left text-newslettertextcolour font-semibold text-xl'>Prestazione in base all’ora</p>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={data} margin={{ top: 40, left: 10 }}>
                                <Legend iconType="square" iconSize={40} align="left" verticalAlign="top" height={160} margin={{ top: 10, left: 0, right: 0, bottom: 0 }} />

                                <XAxis dataKey="name" />
                                <YAxis />
                                <Bar dataKey="uv" fill="#8884d8" />
                                <Bar dataKey="pv" fill="#B8DBC1" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            </div>

        </>
    )
}

export default SeeAllEmails
