import React from 'react';
import { Outlet } from 'react-router-dom';
import ScrollToTop from '../../../Reusable/ScrollToTop';
import '../../../Reusable/CSS/Page-Layout.css';
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import{ getUserData }from '../../../Utility/getUserData';

function Socialmedia() {
  const userData = getUserData();
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!userData?.socialAccounts?.facebookPageResourceIdentifier) {
      navigate("/dashboard/account/general-settings")
      toast.info("Connetti una pagina Facebook")
    }
  }, [])

  return (

    <div className='pagelayout'>

      <ScrollToTop />

      <Outlet />

    </div>

  );
}

export default Socialmedia;
