import React from "react"
import DeliveryIcon from "../../../../../Assets/Icons/RC/Delivery-Icon.svg"
import moment from "moment-timezone"

function DeliveryCard({ Data }) {
  return (
    <div className=" bg-white flex flex-row rounded-md  elevation-1 ">

      <div className="flex flex-col justify-evenly py-4">
        <p className="font-semibold text-xl text-left text-primarytextcolour ">
          ORDINE #{Data?._id}
        </p>


        <div className="0 flex flex-row gap-1">
        <div className=" w-1/4 ">
        <p className=' text-black text-xs'>EFFETTUATO IL:</p>
        
        <p className="text-black text-xs ">
          {moment(Data?.createdAt).format("DD-MM-YYYY")}
        </p>
        </div>
        <div className=" w-1/6">
        <p className=' text-black text-xs'>ORARIO:</p>
        
        <p className="text-black text-xs ">
        {moment(Data?.createdAt).tz("Europe/Rome").format("HH:mm")}
        </p>

        </div>
        <div className=" w-[35%]">
        <p className=' text-black text-xs'>INDIRIZZO:</p>
        
        <p className="text-black text-xs ">
        {Data.billingAddress}
        </p>
        </div>
        <div className=" w-1/4">
        <p className=' text-black text-xs'>PAGAMENTO:</p>
        
        <p className="text-black text-xs ">
       xxxxx
        </p></div>
        </div>

       {Data?.products?.map((item, index) => {
                    return (
                        <div className="flex flex-row w-full justify-between">



                            <div className="w-1/3 flex flex-row justify-between text-left">
                                <p className='font-semibold text-primarytextcolour'>x{item?.quantity}</p>
                            </div>
                            <div className="w-1/3 flex flex-row justify-between text-left">
                                <p className='font-normal text-black'>{item._id.title}</p>
                                <p className='font-semibold text-primarytextcolour'>{item._id.price}$</p>
                            </div>
                        </div>
                    )
                })} 

        <p className="text-right py-4  w-full text-primarytextcolour font-bold">
          Totale: {Data.totalPrice}€
        </p>

      </div>
    </div>
  )
}

export default DeliveryCard
