import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { GrClose } from "react-icons/gr"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderTopWidth: 18,
    borderTopColor: "#00B27A",
    boxShadow: 5,
    p: 4,
    borderRadius: 5
};
function ChangeEmailModal({ setOpen2, open }) {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableScrollLock
            open={open}
            onClose={() => setOpen2(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>

                <Box sx={style}>

                    <div className='flex flex-col justify-center items-center gap-8'>




                        <button onClick={() => setOpen2(false)} className="absolute top-0 right-0 m-5">
                            <GrClose size={25} color="#00B27A" />
                        </button>



                        <div className='flex flex-col w-full gap-4'>
                            <p className=" font-semibold text-xl text-primarytextcolour text-center">Modifica Email</p>

                            <div className='flex flex-col w-full gap-2'>


                                <p className="font-base text-base text-black">Vecchia Email</p>

                                <input type={"email"} className="w-full h-10 pl-3 bg-[#F6F6F6] elevation-1 rounded-md" placeholder="Vecchia Email" />


                            </div>

                            <div className='flex flex-col w-full gap-2'>


                                <p className="font-base text-base text-black">Nuova Email</p>


                                <input type={"email"} className="w-full h-10 pl-3 bg-[#F6F6F6] elevation-1 rounded-md" placeholder="Nuova Email" />


                            </div>

                        </div>

                        <div className='flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-4'>


                            <button className="w-40 h-10 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white">
                                Salva
                            </button>



                            <button onClick={() => setOpen2(false)} className="w-40 h-10 rounded-md elevation-1 text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:shadow-innerbig active:border-white">
                                Annulla
                            </button>


                        </div>

                    </div>

                </Box>

            </Fade>

        </Modal >
    )
}

export default ChangeEmailModal