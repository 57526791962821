import axios from "axios";
import { toast } from "react-toastify";
import { getUserData } from "../../../../../Utility/getUserData";
import { baseUrl } from "../../../../../Utility/Api/Urls";

const baseEndpoint = `https://${
  process.env.REACT_APP_DASHBOARD_PREFIX
}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/payments/stripe`;
const userData = getUserData();

export const getStripeAccountData = async () => {
  try {
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const result = await axios.get(baseEndpoint, config);

    if (!result.data || result.data.error || result.data.Error)
      throw new Error(result.data.error || result.data.Error);

    return result.data;
  } catch (error) {
    console.error(
      "Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
    );
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};

export const connectStripe = async () => {
  try {
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const body = {
      type: "express",
      country: userData.nation,
    };
    const result = await axios.post(baseEndpoint, body, config);

    if (!result.data || result.data.error || result.data.Error)
      throw new Error(result.data.error || result.data.Error);

    return result.data;
  } catch (error) {
    console.error(
      "Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
    );
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};

export const generateStripeAccountLink = async () => {
  try {
    const url = `${baseEndpoint}/link`;
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const result = await axios.get(url, config);

    if (!result.data || result.data?.error || result.data?.Error)
      throw new Error(result.data.error || result.data.Error);

    return result.data;
  } catch (error) {
    console.error(
      "Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
    );
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};

export const saveStripeAccount = async id => {
  try {
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const body = { acc_id: id };
    const result = await axios.patch(baseEndpoint, body, config);

    if (!result.data || result.data.error || result.data.Error)
      throw new Error(result.data.error || result.data.Error);

    return result.data.account;
  } catch (error) {
    console.error(
      "Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
    );
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};

export const onBoardUser = async () => {
  try {
    const url = `${baseEndpoint}/onBoard`;
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const result = await axios.patch(url, null, config);

    if (!result.data || result.data.error || result.data.Error)
      throw new Error(result.data.error || result.data.Error);

    return result.data.success;
  } catch (error) {
    console.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};

export const disconnectStripeAccount = async () => {
  try {
    const config = { headers: { authorization: `Bearer ${userData.token}` } };
    const result = await axios.delete(baseEndpoint, config);

    if (!result.data || result.data.error || result.data.Error)
      throw new Error(result.data.error || result.data.Error);

    return !!result.data;
  } catch (error) {
    console.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
    toast.error("Errore di connessione con Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto");
  }
};
