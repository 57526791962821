import React, { useState } from 'react';
import { ButtonProva } from '../../../../Reusable/Button-NEW/ButtonProva';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Redo from './icon/redo.svg';
import Undo from './icon/undo.svg';
import { createTemplate } from '../../../../apis/editor/editor';
import GraphicEditorPortal from '../GraphicEditorPortal/GraphicEditorPortal';
import useSetImage from '../../../../Hooks/useSetImage';
import { toast } from 'react-toastify';

export const NavBar = ({ action, canvas, setIsEditorOpen, template }) => {
    const [confirmExitOpen, setConfirmExitOpen] = useState(false);
    const setImage = useSetImage();
    const handleSaveClick = async () => {
        if (canvas) {
            const toasta = toast.loading("Salvataggio del modello in corso...");    
            const canvasJson = canvas.toJSON();

            // Generate the thumbnail
            const thumbnail = canvas.toDataURL({
                format: 'png',
                width: canvas?.width,
                height: canvas?.height,
            });

            // Create a canvas element for resizing the thumbnail
            const thumbnailCanvas = document.createElement('canvas');
            const thumbnailContext = thumbnailCanvas.getContext('2d');

            // Load the image and resize if necessary
            const img = new Image();
            img.src = thumbnail;
            img.onload = async function() {
                const thumbnailSize = 2000; // Set the size to 2000 pixels
    
                // Resize the thumbnail
                thumbnailCanvas.width = thumbnailSize;
                thumbnailCanvas.height = thumbnailSize;
                thumbnailContext.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);
    
                // Convert the resized thumbnail to Blob
                const thumbnailBlob = await new Promise(resolve => thumbnailCanvas.toBlob(resolve, 'image/png'));
    
                // Create a File object from the Blob
                const thumbnailFile = new File([thumbnailBlob], 'thumbnail.png', { type: 'image/png' });
    
                const body = {
                templateName: template.templateName,
                availableFor: template.availableFor,
                restaurantType: template.restaurantType,
                section: template.section,
                objective: template.objective,
                buyerPersona: template.buyerPersona,
                packages: template.packages,
                restaurantId: template.restaurantId,
                templateId: template.templateId,
                objects: canvasJson.objects,
                canvasSize: canvas.getWidth(),
                thumbnail:thumbnailFile,
                ownerIsAdmin: false,
                action,
                backgroundColor: canvas.backgroundColor,
            };
            try {
                await createTemplate(body).then((res)=>{
                    if(res)
                    {
                        toast.update(toasta, {
                            render: "Modello salvato con successo.",
                            type: "success",
                            isLoading: false,
                            autoClose: 2000,
                          });
                        setIsEditorOpen(false)
                    }
                    else
                        toast.update(toasta, {
                            render: "Impossibile salvare il modello.",
                            type: "error",
                            isLoading: false,
                            autoClose: 2000,
                          });
                });
            } catch (error) {
                toast.update(toasta, {
                    render: "Errore durante il salvataggio del canvas:",
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                  });
                console.error(
                    'Errore durante il salvataggio del canvas:',
                    error
                );
            }
            };
            setImage(img.src);
            setIsEditorOpen(false);
        }
    };
    const handleEsciClick = () => {
        setConfirmExitOpen(true);
    };

    return (
        <div
            className='w-full bg-[#b8edd7] py-4 flex'
            id='navbar'
        >
            {confirmExitOpen && (
                <GraphicEditorPortal
                    inlineStyles={{
                        zIndex: 9999,
                        display: 'grid',
                        placeContent: 'center',
                        height: '100%',
                    }}
                    show={confirmExitOpen}
                >
                    <div className='bg-white p-4 rounded-md grid gap-4'>
                        <h2 className='font-semibold'>
                            Sei sicuro di voler uscire?
                        </h2>
                        <p>Tutte le modifiche andranno perse</p>
                        <div className='flex gap-2'>
                            <button
                                className='border-2  border-socialmediatextcolour text-socialmediatextcolour h-12 w-40 py-1 px-1 text-sm rounded-md w-1/3'
                                onClick={() => {
                                    setConfirmExitOpen(false);
                                    setIsEditorOpen(false);
                                }}
                            >
                                Esci
                            </button>
                            <button
                                className='bg-socialmediabtn  border-2 border-socialmediatextcolour h-12 w-40 py-1 px-1 text-sm text-white rounded-md w-1/3'
                                onClick={() => setConfirmExitOpen(false)}
                            >
                                Torna indietro
                            </button>
                        </div>
                    </div>
                </GraphicEditorPortal>
            )}
            <div className='text-left pl-6 w-[50%]'>
                <ButtonProva
                    text='Esci'
                    textcolor='black'
                    bgcolor='white'
                    Icon={ChevronLeftIcon}
                    event={handleEsciClick}
                />
            </div>
            <div className='w-[50%] text-right flex justify-end items-center'>
                <button className='bg-white p-2 rounded-lg border'>
                    <img
                        src={Undo}
                        alt='Undo'
                        onClick={() => {
                            canvas.undo();
                        }}
                    ></img>
                </button>
                <button className='bg-white p-2 rounded-lg ml-4 border'>
                    <img
                        src={Redo}
                        alt='Redo'
                        onClick={() => {
                            canvas.redo();
                        }}
                    ></img>
                </button>
                <button
                    className='bg-[#00b27a] py-2 rounded-lg pl-4 ml-4 mr-12 text-white flex'
                    onClick={handleSaveClick}
                >
                    <DoneRoundedIcon />
                    <span className='pr-4 ml-1'>Salva</span>
                </button>
            </div>
        </div>
    );
};
