import React from "react";
import TableIcon from "../../../../../Assets/Icons/RC/Table-Icon.svg";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";

function ReservationCard({ item, getBookings, isTheLoadingCard, isLoading }) {
  return (
    <>
      <div className="w-fit h-[135px] sm:w-[95%] xsm:w-[95%]  bg-white rounded-md elevation-1 flex flex-row">
        <div className="h-full w-[128px] bg-primarybtn rounded-l-md elevation-1 flex flex-col gap-y-3 py-3 items-center ">
          <div>
            <img src={TableIcon} alt="" />
          </div>
          <p className="text-lg text-white font-semibold">
            {dayjs(item?.booked_at).format("DD MMM")}
          </p>
          <p className="text-md text-white font-normal">
            {dayjs(item?.booked_at).format("hh:mm")}
          </p>
        </div>

        <div className="flex flex-col w-full gap-y-4 items-center justify-center">
          <p className="font-semibold text-xl text-primarytextcolour">
            {item?.persons} Persone
          </p>
          {item?.products.length !== 0 && (
            <button
              className="w-40 h-10 rounded-md text-sm  font-bold mx-auto flex items-center justify-center elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig "
              onClick={() => getBookings(item?._id)}>
              {isTheLoadingCard === item?._id && isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Dettagli"
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ReservationCard;
