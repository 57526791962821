import { CUSTOMER_ACTION, SEARCH_DETAILS } from '../constants';

export const generalSeoReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_ACTION:
      return {
        ...state,
        customerAction: action?.payload,
      };
    case SEARCH_DETAILS:
      return {
        ...state,
        searchDetails: action?.payload,
      };
    default:
      return state;
  }
};