import axios from "axios";
import { getUserId } from "../Utility/getUserData";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";
import * as imageHostingService from "./imageHostingService";

const getBaseEndpoint = () =>
  `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${
    process.env.REACT_APP_THEMES_API_PATH
  }/automaticMails`;

/**
 * Gets all automatic mails
 * @param {String?} type Status of newsletter to fetch
 * @param {Number?} limit Number of items to fetch
 * @param {Number?} skip Number of items to skip from start
 */
export const getAutomaticMails = async (type = null, limit = 20, skip = 0) => {
  try {
    const typeQuery = type ? `&type=${type}` : "";
    const query = `?limit=${limit}&skip=${skip}${typeQuery}`;
    const url = `${getBaseEndpoint()}${query}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Gets all automatic mails
 * @param {String?} type Status of newsletter to fetch
 * @param {Number?} limit Number of items to fetch
 * @param {Number?} skip Number of items to skip from start
 */
export const getAutomaticNewsletterMails = async (limit = 50, skip = 0) => {
  try {
    const query = `?type=newsletter&limit=${limit}&skip=${skip}`;
    const url = `${getBaseEndpoint()}${query}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Gets automatic mail by id
 * @param {String} id
 */
export const getAutomatedMail = async id => {
  try {
    // const url = `${baseEndpoint()}/subscribersNewsletters${query}`;
    const url = `${getBaseEndpoint()}/${id}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Create automatic mail
 * @param {Object} payload
 */
export const createAutomatedMail = async payload => {
  try {
    payload.image = await imageHostingService.hostBase64Image(payload.image);

    const { data } = await axios.post(
      getBaseEndpoint(),
      payload,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Modify mail by id
 * @param {String} id
 * @param {Object} payload
 */
export const modifyAutomatedMail = async (id, payload) => {
  try {
    // const url = `${baseEndpoint()}/subscribersNewsletters${query}`;
    const url = `${getBaseEndpoint()}/${id}`;
    const { data } = await axios.patch(url, payload, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Delete mail by id
 * @param {String} id
 */
export const deleteAutomatedMail = async id => {
  try {
    // const url = `${baseEndpoint()}/subscribersNewsletters${query}`;
    const url = `${getBaseEndpoint()}/${id}`;
    const { data } = await axios.delete(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Gets activated automails
 */
export const getActivatedAutoMails = async () => {
  try {
    const url = `${getBaseEndpoint()}?isCustomActive=true`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Gets activated automails
 * @param {String} type
 * @param {Boolean} isCustomActive
 */
export const toggleAutoMailActive = async (type, isCustomActive) => {
  try {
    const url = `${getBaseEndpoint()}/activate`;
    const payload = { type, isCustomActive };
    const { data } = await axios.post(url, payload, getAuthConfig());

    if (!data) throw new Error("No data received");

    return data;
  } catch (error) {
    console.error(error);
  }
};
