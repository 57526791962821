import React from "react";
import "./Backdrop.css";

const Backdrop = ({ animation, children, ...rest }) => {
  return (
    <div className={`Backdrop ${animation}`} {...rest}>
      {children}
    </div>
  );
};

export default Backdrop;
