import { useContext } from "react";
import { MarketingCampaignContext } from "../../../../../../Contexts/MarketingCampaignContext";
import BuyerPersonasSelection from "../../../../../../Reusable/MarketingCreateCampaign/BuyerPersona/BuyerPersonasSelection";
import OtherPersonaCampaignCard from "../../../../BuyerPersona/OtherTypes/OtherPersonaCampaignCard";

export default function MarkertingBuyerPersonas() {
  const {
    campaign,
    isSuggestedCampaign,
    externalBuyerPersona,
    selectedBuyerPersona,
    isOtherPersona,
    handleBuyerPersonaSelected,
  } = useContext(MarketingCampaignContext);

  return (
    <div className="flex w-full justify-center items-center">

      {isOtherPersona ? (

        <div className="flex w-full justify-center">

          <OtherPersonaCampaignCard
            persona={externalBuyerPersona}
            goal={campaign}
            onSelect={handleBuyerPersonaSelected}
            selected={selectedBuyerPersona}
          />

        </div>

      ) : (

        <div className="flex w-full justify-center">

          <BuyerPersonasSelection
            externalBuyerPersona={externalBuyerPersona}
            selected={selectedBuyerPersona}
            onSelect={handleBuyerPersonaSelected}
            isRandom={isSuggestedCampaign}
          />

        </div>

      )}
    </div>
  );
}
