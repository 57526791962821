import React from 'react'
import maleicon from "../../../../Assets/Icons/male.png"
import femaleicon from "../../../../Assets/Icons/female.png"

function ThirdSection() {
    return (
        <div className='w-[90%] mx-auto h-[204px] rounded-md border elevation-1 flex flex-row ' >
            <div className='w-1/2' >
                <p className="text-marketingtextcolour text-2xl text-left p-3 font-semibold">Dati Demografici</p>
                <p className='text-[#AEAEAE] text-left font-normal px-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>
            </div>
            <div className='w-1/2 flex flex-row gap-x-4 justify-evenly' >
                <div className="flex flex-row gap-x-3 items-center w-fit ">
                    <div className='my-auto mx-auto'>
                        <img src={maleicon} alt="" />
                    </div>
                    <div className='flex flex-col' >
                        <p className='text-[50px] text-marketingtextcolour font-semibold'>64,3</p>
                        <p className='text-[30px] text-[#2ACB91] font-semibold'>+12,3</p>

                    </div>
                </div>
                <div className="flex flex-row gap-x-3 items-center w-fit">
                    <div className='my-auto mx-auto'>
                        <img src={femaleicon} alt="" />
                    </div>
                    <div className='flex flex-col' >
                        <p className='text-[50px] text-[#D98CD1] font-semibold'>64,3</p>
                        <p className='text-[30px] text-[#EE1C1C] font-semibold'>+12,3</p>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default ThirdSection
