import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

import BeeComponent from "../../../../Reusable/BeePlugin/BeePlugin";
import Loader from "../../../../Reusable/Loader/Loader";
import { AutomatedMailType, frequencyOptions } from "./Helpers/CardsData";
import { formatDate, validationSchema } from "./Helpers/mailAutomationHelpers";
import * as service from "../../../../apis/automatedMailService";
import * as productsService from "../../../../Components/RC/Products/productsService";
import { holidays } from "./Helpers/holidays";
import { useNewsletterTemplates } from "./../../../../Hooks/useTemplates";
import { useUserNewsletterTemplates } from "./../../../../Hooks/useUserTemplates";
import SelectTemplate from "../../../../Reusable/BeeTemplateScheduling/SelectTemplate";



const createEmptySpecialParameters = ({ specialParameters }) =>
  specialParameters?.reduce((total, param) => ({ ...total, [param]: "" }), {});

export default function SingleEmailAutomation({ card }) {
  const navigate = useNavigate();
  // const { state: card } = useLocation();

  const [isTouched, setIsTouched] = useState(false);

  const [subject, setSubject] = useState("");
  const [specialParameters, setSpecialParameters] = useState(
    (card && createEmptySpecialParameters(card)) || {}
  );
  const handleSpecialParamChange = ({ target: { name, value } }) =>
    setSpecialParameters((sp) => ({ ...sp, [name]: value }));

  const [isSaving, setIsSaving] = useState(false);

  const [templates, isLoadingTemplates] = useNewsletterTemplates();
  const [userTemplates, isLoadingUserTemplates] = useUserNewsletterTemplates();
  const [template, setTemplate] = useState(null); //the template that is going to show to on beeplugin

  const handleTemplateSelected = (template) => {
    setTemplate(template);
  };

  const [selectedButton, setSelectedButton] = useState(0);
  const [json, setJson] = useState(null);
  const [image, setImage] = useState(null);

  // products summary obkects needed for AutomatedMailType.BuyASpecificProduct
  const [products, setProducts] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  useEffect(() => {
    setSpecialParameters({})
    setIsLoadingProducts(true);
    if (card?.type === AutomatedMailType.BuyASpecificProduct)
      productsService
        .getAllProductsSummary()
        .then((data) => {
          if (data) setProducts(data);
          else
            toast.error(
              "Non è stato possibile caricare i prodotti. Riprova o contatta l'assistenza."
            );
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoadingProducts(false));
    else setProducts(null);

    // setProducts(testProductsData); // test data
  }, [card]);

  const handleProductChange = (e, value) => {
    setSpecialParameters((sp) => ({
      ...sp,
      [card?.specialParameters[0]]: mapProductTitleToId(value),
    }));
  };

  const mapProductTitleToId = (title) =>
    products.find((p) => p.title === title)?._id || "";
  const mapProductIdToTitle = (id) =>
    products?.find((p) => p._id === id)?.title || "";



  const getPayload = (parseData = false) => {
    let parsedSpecialParams = specialParameters;

    // for final output
    if (parseData) {
      for (const param in specialParameters)
        if (param.includes("days"))
          parsedSpecialParams[param] = Number(parsedSpecialParams[param]);
    }

    return {
      subject,
      json,
      image,
      type: card?.type,
      specialParameters: parsedSpecialParams,
    };
  };

  const handleSubmit = async () => {
    setIsTouched(true);

    if (!isValid) return;

    const data = await service.createAutomatedMail(getPayload(true));
    if (data) {
      toast.success("La mail è stata creata con successo");
      navigate("/newsletter/automails-list");
    }
  };

  const validate = () => {
    const { error } = validationSchema.validate(getPayload());
    return error ? error.details[0].message : null;
  };

  const error = validate();
  const isValid = !error;

  const renderUniqueContent = () => {
    switch (card?.type) {
      case AutomatedMailType.Birthday:
        return (
          <div className="flex flex-col gap-y-2 mr-auto pl-[4%]">
            <p className="text-xl text-black font-semibold text-left">
              Giorni prima del compleanno (massimo 90){" "}
            </p>
            <input
              type="number"
              className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
              placeholder="Scrivi il numero di giorni prima del compleanno"
              name={card?.specialParameters[0]}
              value={specialParameters[card?.specialParameters[0]] || ''}
              onChange={handleSpecialParamChange}
            />
          </div>
        );

      case AutomatedMailType.DateOfSubscription:
        return (
          <>
            <div className="flex flex-col gap-y-2 pl-[4%]">
              <p className="text-xl text-black font-semibold text-left">
                Giorni prima della data (massimo 90)
              </p>
              <input
                type="number"
                placeholder="Scrivi il numero di giorni"
                className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
                name={card?.specialParameters[0]}
                value={specialParameters[card?.specialParameters[0]]}
                onChange={handleSpecialParamChange}
              />
            </div>


          </>
        );

      case AutomatedMailType.SpecificDate:
        return (
          <>
            <div className="flex flex-col gap-y-2 pl-[4%] ">
              <p className="text-xl text-black font-semibold text-left">
                Scegli Data
              </p>
              <input
                type="text"
                className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
                placeholder="GG/MM/AAAA HH:MM"
                name={card?.specialParameters[0]}
                value={specialParameters[card?.specialParameters[0]]}
                onChange={handleSpecialParamChange}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <p className="text-xl text-black font-semibold text-left">
                Ricorrenza
              </p>
              <select
                className="w-[530px] h-[50px] text-newslettertextcolour focus:outline-none rounded-lg bg-[#F6F6F6] placeholder:text-[#989898] px-3"
                name={card?.specialParameters[1]}
                value={specialParameters[card?.specialParameters[1]]}
                onChange={handleSpecialParamChange}
              >
                {frequencyOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </select>
            </div>
          </>
        );

      case AutomatedMailType.RecurringDate:
        return (
          <div className="flex flex-col gap-y-2 pl-[4%]">
            <p className="text-xl text-black font-semibold text-left">
              Scegli Data
            </p>
            <select
              className="w-[550px] h-[50px] text-newslettertextcolour focus:outline-none rounded-lg bg-[#F6F6F6] placeholder:text-[#989898] px-3"
              name={card?.specialParameters[0]}
              value={specialParameters[card?.specialParameters[0]]}
              onChange={handleSpecialParamChange}
            >
              {holidays.map((h) => (
                <option key={h.name} value={h.date}>
                  {formatDate(h.date)} - {h.name}
                </option>
              ))}
            </select>
          </div>
        );

      case AutomatedMailType.BuyASpecificProduct:
        return (
          <>
            <div className="flex flex-col gap-y-2 pl-[4%] justify-end items-center w-[550px]">
              {isLoadingProducts && (
                <div className="w-full m-auto">
                  <Loader />
                </div>
              )}

              {!isLoadingProducts && products?.length === 0 && (
                <div className="flex flex-col gap-y-2 col-span-2 mr-auto ">
                  <p className="text-xl text-black font-semibold text-left">
                    Non ci sono prodotti.
                  </p>
                </div>
              )}

              {products && (
                <Autocomplete
                  className="backgroundtext w-full"
                  disablePortal
                  autoHighlight
                  selectOnFocus
                  name={card?.specialParameters[0]}
                  options={products.map((p) => p.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Prodotto" />
                  )}
                  value={mapProductIdToTitle(
                    specialParameters[card?.specialParameters[0]]
                  )}
                  onChange={handleProductChange}
                  onBlur={() => { }}
                />
              )}
            </div>
            <div className="flex flex-col gap-y-2 mr-auto ">
              <p className="text-xl text-black font-semibold text-left">
                Giorni dopo l’acquisto (massimo 90)
              </p>
              <input
                type="number"
                className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
                placeholder="Scrivi il numero di giorni"
                name={card?.specialParameters[1]}
                value={specialParameters[card?.specialParameters[1]]}
                onChange={handleSpecialParamChange}
                max={90}
              />
            </div>
          </>
        );

      case AutomatedMailType.LastPurchaseDate:
        return (
          <div className="flex flex-col gap-y-2 mr-auto pl-[4%]">
            <p className="text-xl text-black font-semibold text-left">
              Giorni dopo l’acquisto (massimo 90)
            </p>
            <input
              type="number"
              className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
              placeholder="Scrivi il numero di giorni"
              name={card?.specialParameters[0]}
              value={specialParameters[card?.specialParameters[0]]}
              onChange={handleSpecialParamChange}
            />
          </div>
        );

      case AutomatedMailType.LeaveTheCart:
        return (
          <div className="flex flex-col gap-y-2 pl-[4%]">
            <p className="text-xl text-black font-semibold text-left">
              Giorni dopo l’abbandono (massimo 30)
            </p>
            <input
              type="number"
              className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3"
              placeholder="Scrivi il numero di giorni"
              max="30"
              name={card?.specialParameters[0]}
              value={specialParameters[card?.specialParameters[0]]}
              onChange={handleSpecialParamChange}
            />
          </div>
        );

      default:
        return null;
    }
  };

  if (!card) return <Navigate to="/newsletter/mail-automation" />;

  return (
    <>
      <div className="flex flex-col  gap-y-8 mt-8">
        {/* FORM */}
        <div className="grid grid-cols-2 gap-y-8 mt-7 ">
          {renderUniqueContent()}

          {/* SUBJECT */}
          <div className="flex flex-col gap-y-2 pl-[4%]">
            <p className="text-xl text-black font-semibold text-left">
              Oggetto
            </p>
            <input
              type="text"
              className="w-[530px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3 xsm:w-[350px] sm:w-[400px]"
              placeholder="Scrivi l’oggetto..."
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
        </div>

        {/* TEMPLATE */}
        <div className="flex flex-col w-full items-center">
          <p className="text-newslettertextcolour font-semibold text-2xl my-6 text-left xsm:px-2 sm:px-2">
            Progetta la tua email partendo da un Template
          </p>


          <div className="flex flex-row gap-x-6 w-full justify-center items-center mx-auto xsm:flex-col sm:flex-col ">
            <button
              className={`w-fit ${selectedButton === 0 ? "bg-newsletterbtn text-white" : "bg-white text-newslettertextcolour"} h-10 px-4 py-2   border-2 border-newslettertextcolour rounded-md xsm:mb-2 sm:mb-2 `}
              disabled={isLoadingTemplates}
              onClick={() => setSelectedButton(0)}
            >
              {isLoadingTemplates ? <Loader /> : "Seleziona un Template"}
            </button>

            <button
              className={`w-fit ${selectedButton === 1 ? "bg-newsletterbtn text-white" : "bg-white text-newslettertextcolour"}  h-10 px-4 py-2  border-2 border-newslettertextcolour rounded-md`}
              onClick={() => setSelectedButton(1)}
              disabled={isLoadingUserTemplates}
            >
              {isLoadingUserTemplates ? <Loader /> : "Utilizza una Campagna Precedente"}
            </button>
          </div>

          {(selectedButton === 0 ? templates : userTemplates)?.length > 0 ? (
            <SelectTemplate
              onTemplateSelected={handleTemplateSelected}
              templates={selectedButton === 0 ? templates : userTemplates}
            />
          ) : (
            <p className="text-xl text-newslettertextcolour text-center mx-auto">
              Non ci sono template.
            </p>
          )}
        </div>

        {template && (
          <>
            <BeeComponent
              template={template}
              setIsSaving={setIsSaving}
              isSaving={isSaving}
              setImage={setImage}
              setJson={setJson}
            />
            <button
              className="w-[270px] my-12 h-[44px] bg-newsletterbtn rounded-lg text-white mx-auto"
              onClick={() => setIsSaving(true)}
            >
              Salva
            </button>
            {image && <img alt="template" src={image} />}
          </>
        )}
      </div>

      {/* FORM ERROR */}
      {isTouched && error && (
        <div className="font-semibold text-red-600">{error}</div>
      )}

      {/* SUBMIT */}
      {template &&
        <div className="my-10">
          <button
            onClick={handleSubmit}
            className="w-40 h-10 rounded-md px-2 elevation-1 text-white bg-newsletterbtn border-2 border-newslettertextcolour hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-white active:shadow-innerbig"
          >
            Crea la mail
          </button>
        </div>}


    </>
  );
}
