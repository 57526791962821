import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { lightBlue } from "@mui/material/colors";
import axios from "axios";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import CircularProgress from '@mui/material/CircularProgress';
const VerificationModal = ({ handleClose, open, userData }) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };

  const [value, setValue] = React.useState("");
  const [verifCode, setVerifCode] = useState("");
  const [ActiveStep, SetActiveStep] = React.useState(0)
  const [isLoading, SetLoading] = React.useState(false)

  const config = { headers: { Authorization: `Bearer ${userData?.token}` } };

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };



  const handleValidateGMBConnection = async () => {
    let data = {
      email: userData.email,
      verificationMethod: value,
      languageCode: "it",
    };
    console.log(data, "data");
    SetLoading(true)
    try {
      const res = await axios.post(`https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/gmbVerification`, data, config);
      SetActiveStep(1)
      console.log(res)
    } catch (error) {
      console.log("error is", error);
    }
    SetLoading(false)

  };


  const handleGmbVerification = async () => {
    SetLoading(true)
    try {
      const res = await axios.get(`https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/getGmbVerification${verifCode}`, config);
      console.log(res)
      SetActiveStep(2)
    } catch (error) {
      console.log("error is", error);
    }
    SetLoading(false)
  };


  const showTheVerficationMethodModal = ActiveStep === 0
  const PinModalForPhoneORsms = (value === "sms" || value === "phone_call") && ActiveStep === 1
  const AddressModal = value === "address" && ActiveStep === 1
  const CompletedModal = ActiveStep === 2
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {showTheVerficationMethodModal &&
            <div className="flex flex-col gap-4">
              <div className="self-center">
                <p className="text-black font-medium text-xl  ">
                  Choose your verification method:
                </p>
              </div>
              <div className="self-center">
                <FormControl sx={{ m: 1 }} variant="standard">
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    value={value}
                    onChange={handleRadioChange} >

                    <FormControlLabel
                      value="sms"
                      disabled={value && value !== "sms"}
                      sx={{ marginBottom: 3 }}
                      control={<Radio sx={{ color: lightBlue[600], "&.Mui-checked": { color: lightBlue[400], }, }} />}
                      label={`Text Message (${userData?.phone})`} />

                    <FormControlLabel
                      value="phone_call"
                      disabled={value && value !== "phone_call"}
                      sx={{ marginBottom: 3 }}
                      control={<Radio sx={{ color: lightBlue[600], "&.Mui-checked": { color: lightBlue[400], }, }} />}
                      label={`Phone Call (${userData?.phone})`} />

                    <FormControlLabel
                      value="address"
                      disabled={value && value !== "address"}
                      sx={{ marginBottom: 1 }}
                      control={<Radio sx={{ color: lightBlue[600], "&.Mui-checked": { color: lightBlue[400], }, }} />}
                      label={`Post Card (${userData?.billingInformation?.billingAddress} )`} />

                  </RadioGroup>
                  <button
                    disabled={!value}
                    className=" bg-[#00ACC1] mx-auto w-24 h-12 flex items-center justify-center elevation-1 disabled:opacity-50  rounded-md text-white   mt-6"
                    onClick={() => handleValidateGMBConnection()}
                  >
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : "NEXT"}
                  </button>
                </FormControl>
              </div>
            </div>
          }

          {AddressModal &&
            <div className={` flex flex-col gap-6 mt-6`} >
              <div className="self-center">
                <p className="text-black font-medium text-base  ">
                  We send the request to Google! You’ll receive the post card in
                  maxium 15 days. If you meet any problem, please contact us.
                </p>
              </div>
              <button
                onClick={() => { handleClose() }}
                className=" bg-[#00ACC1] w-24 elevation-1 mx-auto rounded-md text-white p-2 mt-6">
                DONE
              </button>
            </div>
          }

          {PinModalForPhoneORsms &&
            <div className={` flex flex-col gap-6 mt-6 `} >
              <div className="self-center">
                <p className="text-black font-medium text-base  ">
                  Complete your verification code:
                </p>
              </div>
              <div className="self-center items-center">
                <FormControl sx={{ width: "15ch" }}>
                  <OutlinedInput
                    color="info"
                    focused
                    onChange={(e) => setVerifCode(e.target.value)}
                  />
                </FormControl>
              </div>
              <button
                onClick={() => handleGmbVerification()}
                disabled={!verifCode}
                className=" bg-[#00ACC1] mx-auto w-24 h-12 flex items-center justify-center elevation-1 disabled:opacity-50  rounded-md text-white  mt-6">
                {isLoading ? <CircularProgress color="inherit" size={20} /> : "NEXT"}
              </button>
            </div>
          }

          {CompletedModal &&
            <div className={` flex flex-col gap-6 mt-6`} >
              <div className="self-center">
                <p className="text-black font-medium text-base  ">
                  Congratulation, your GMB profile is successfully verified!
                </p>
              </div>
              <button
                onClick={() => { handleClose() }}
                className=" bg-[#00ACC1] w-24 elevation-1 mx-auto rounded-md text-white p-2 mt-6">
                DONE
              </button>
            </div>
          }
        </Box>
      </Modal>
    </div >
  );
};
export default VerificationModal;
