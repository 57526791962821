import React from 'react';
import './NewsCustomLink.css';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const NewsCustomLink = ({ to, children }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (

    <Link
      to={to}
      className={
        match
          ? 'NewsCustomLink NewsCustomLink--active'
          : 'NewsCustomLink'
      }
    >

      {children}

    </Link>

  );
};

export default NewsCustomLink;
