import React from "react";
import { Box } from "@mui/material";
import { LinearProgressCustom } from "../OnProgressCampaign/AllProgressBars";
import { Goal } from "../../../../Utility/marketingHelpers";
import "./ChooseCampCard.css";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const NO_PARAMS_MESSAGE =
  "Crea una campagna di questo tipo per avere dei dati di riferimento futuri";

const ChooseCampCard = ({ campaign, goOnGoal, disabled, flags }) => {
  const [buttonHovered, SetHover] = React.useState(false);

  const {
    firstparams,
    firstparamsLabel,
    secondparams,
    secondparamsLabel,
    color
  } = campaign;
  const isCampaignType = (goalValue) => campaign.value === goalValue;
  if (Goal.StaffSearch === campaign.value && !flags.marketingCampaignRecruitment) return false;
  return (
    <>
      <div className="flex flex-col h-[240px] py-3 w-full justify-between items-center rounded-md elevation-1 gap-y-2">
        <img
          alt={campaign?.description || campaign?.name}
          src={campaign?.icon}
          className="self-center bg-cover bg-center h-20 w-20 mt-2"
        />

        <p
          className="text-center text-xl font-semibold px-4 leading-[26px]"
          style={{ color: color }}
        >
          {campaign?.name}
        </p>

        <p className="flex w-full px-6 text-center text-base font-light text-black xsm:px-2 justify-center">
          {campaign?.description}
        </p>
        {/*
        {isCampaignType(Goal.Branding) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (

              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali Clienti aggiunti"}
                  </p>

                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>

                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        {/*
        {isCampaignType(Goal.Interactions) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Interazioni Social"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        {/*
        {isCampaignType(Goal.Promotions) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Clic sulla promozione"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        {/*
        {isCampaignType(Goal.Delivery) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (

              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel ||
                      "Visualizzazione Pagina Delivery "}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
                    */}
        {/*
        {isCampaignType(Goal.Reservation) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (

              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Visualizzazione Pagina Prenotazioni"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        {/*
        {isCampaignType(Goal.MenuViews) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (

              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Visualizzazione menù"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        {/*
        {isCampaignType(Goal.LocalVisits) ? (
          <div className="w-full h-[100px] flex flex-col justify-center items-center gap-y-4 px-2">
            {firstparams === 0 && secondparams === 0 && (
              <div className="fontfamily flex w-full gap-x-2">
                {NO_PARAMS_MESSAGE}
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel || "Potenziali clienti raggiunti"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {firstparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Potenziali visite al locale"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal  pr-2">
                    {secondparams || "--"}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}
            */}
        <button
          onMouseEnter={() => SetHover(true)}
          onMouseLeave={() => SetHover(false)}
          style={{
            backgroundColor: buttonHovered ? color : "white",
            borderColor: color,
            borderWidth: 2,
            color: buttonHovered ? "white" : color,
          }}
          className="w-[168px] h-[31px] mx-auto bg-white text-base px-4 justify-center items-center font-normal rounded-md "
          disabled={disabled}
          onClick={() => goOnGoal(campaign)}
        >
          Scegli
        </button>

      </div>
    </>
  );
};

export default withLDConsumer()(ChooseCampCard);

