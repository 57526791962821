import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import SocNavigation from "../SocNavigation/SocNavigation";
import { toast } from "react-toastify";
import SocialManagerHeaderLottie from "../../../../Assets/Lottie/Social-Media.json";
import { baseUrl } from "../../../../Utility/Api/Urls";
import { getUserToken } from "../../../../Utility/getUserData";
import "./Social-Manager.css";
import MobileCalendar from "./MobileCalendar";
import format from "date-fns/format";
import { it } from "date-fns/locale";
import SocialPostCARD from "./SocialPostCARD";
import AnalyticsPlugin from "../../../../Reusable/Analytics-Plugin/AnalyticsPlugin";
import { UserContext } from "../../../../Contexts/UserContext";
import ModifyPopUp from "./ModifyPopUp";
import { getAuthConfig } from "../../../../Utility/Api/auth";

// import Editor from "./Editor"
let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner"
    )
  ).default;
}

export default function Socialmanager() {
  const [SocialPosts, SetSocialPosts] = useState([]);
  const [PostsToShow, SetPosts] = React.useState([]);
  const [instaPosts, setInstaPosts] = React.useState([]);
  const [trigger, setTrigger] = React.useState(false);
  const [value, onChange] = React.useState(new Date());
  const theItalianFormat = format(new Date(value), "dd/MM/yyyy", {
    locale: it,
  });
  const { openEdit, setOpenEdit, setCurrentSocialItem, currentSocialItem } =
    useContext(UserContext);
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const token = getUserToken();

  function handleFilterPosts(date) {
    onChange(date);
    const events = SocialPosts.filter(
      (item) =>
        format(new Date(item.date), "dd MMMM yyyy") ===
        format(new Date(date), "dd MMMM yyyy"),
    );
    SetPosts(events);
  }
  async function getSchedules() {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const FbPosts = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
          process.env.REACT_APP_DASHBOARD_API_PATH
        }/socials/fb/schedules`,
        config,
      );
      const InstaPosts = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
          process.env.REACT_APP_DASHBOARD_API_PATH
        }/socials/instagram/schedule`,
        config,
      );

      const InstagramOnlyPosts = InstaPosts?.data
        ?.filter((item) => !item?.ref?.body?.parentId)
        .map((item) => ({ ...item, InstaButNotFB: true }));
      const CommonInstaPostsParentIds = InstaPosts?.data
        ?.filter((item) => item?.ref?.body?.parentId)
        .map((item) => item?.ref?.body?.parentId);

      const CommonPosts = FbPosts.data.map((item) => ({
        ...item,
        Insta: CommonInstaPostsParentIds.includes(item?._id),
        instaPostId:
          InstaPosts.data.find(
            (instaPost) => instaPost.ref.body.parentId === item._id,
          )?.ref._id || null,
        facebookPostId: item?.ref?._id,
      }));

      const AllThePosts = CommonPosts.concat(InstagramOnlyPosts);
      RetrievePosts(AllThePosts);
      setInstaPosts(InstaPosts);
    } catch (error) {
      console.log(error);
    }
  }
  function RetrievePosts(Allposts) {
    const NewForm = Allposts.map((item) => ({
      ...item,
      _id: item?._id,
      date: new Date(item.at * 1000),
      message: item?.ref?.body?.message || item?.ref?.body?.caption,
      img: item?.ref?.body?.photoUrl || item?.ref?.body?.image_url,
      createdAT: new Date(item.at * 1000),
    }));

    SetSocialPosts([...NewForm]);

    const events = NewForm.filter(
      (item) =>
        format(new Date(item.date), "dd MMMM yyyy") ===
        format(new Date(), "dd MMMM yyyy"),
    );
    SetPosts(events);
  }

  function findObjectByParentId(arr, parentId) {
    for (const obj of arr) {
      if (obj.ref && obj.ref.body && obj.ref.body.parentId === parentId) {
        return obj;
      }
    }
    return null; // Return null if the object with the given parentId is not found in the array.
  }

  async function DeleteSchedule(item) {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      if (item?.isDone) {
        const userDataString = localStorage.getItem("userData");
        const userData = JSON.parse(userDataString);

        const facebookPageId =
          userData.socialAccounts.facebookPageResourceIdentifier;
        const facebookAccessToken =
          userData.socialAccounts.facebookPageAuthToken;

        const itemDate = new Date(item.at * 1000);
        const formattedItemDate = itemDate.toISOString().slice(0, 16);

        fetch(
          `https://graph.facebook.com/v18.0/${facebookPageId}/feed?access_token=${facebookAccessToken}`,
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            const matchedItem = data.data.find((post) => {
              const postDate = new Date(post.created_time);
              const formattedPostDate = postDate.toISOString().slice(0, 16);
              return formattedPostDate === formattedItemDate;
            });

            const matchedItemId = matchedItem ? matchedItem.id : null;
            if (matchedItemId) {
              return fetch(
                `https://graph.facebook.com/v18.0/${matchedItemId}?access_token=${facebookAccessToken}`,
                {
                  method: "DELETE",
                },
              );
            } else {
              console.log("No matched item found");
            }
            console.log("Matched Item ID:", matchedItemId);
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error,
            );
          });
      }

      const instapostid = findObjectByParentId(instaPosts?.data, item._id)?._id;
      const deleteResponse = await axios.delete(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
          process.env.REACT_APP_DASHBOARD_API_PATH
        }/socials/fb/schedules/${item._id}`,
        config,
      );

      if (instapostid) {
        const deleteinstaifexist = await axios.delete(
          `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
            process.env.REACT_APP_DASHBOARD_API_PATH
          }/socials/instagram/${instapostid}`,
          config,
        );
      }

      if (deleteResponse.status === 200) {
        toast.info("Il tuo post è stato cancellato con successo");

        const updatedSocialPosts = SocialPosts.filter(
          (e) => e._id !== item?._id,
        );
        SetSocialPosts(updatedSocialPosts);

        const updatedPostsToShow = PostsToShow.filter(
          (e) => e._id !== item?._id,
        );
        SetPosts(updatedPostsToShow);
      }
    } catch (error) {
      console.error("Error deleting schedule:", error);
    }
  }

  async function ModifySocailMediapost(item, NewDesc) {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      if (item?.isDone) {
        const userDataString = localStorage.getItem("userData");
        const userData = JSON.parse(userDataString);

        const facebookPageId =
          userData.socialAccounts.facebookPageResourceIdentifier;
        const facebookAccessToken =
          userData.socialAccounts.facebookPageAuthToken;

        const itemDate = new Date(item.at * 1000);
        const formattedItemDate = itemDate.toISOString().slice(0, 16);
        let metaPayload = {
          message: NewDesc,
        };
        fetch(
          `https://graph.facebook.com/v18.0/${facebookPageId}/feed?access_token=${facebookAccessToken}`,
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            const matchedItem = data.data.find((post) => {
              const postDate = new Date(post.created_time);
              const formattedPostDate = postDate.toISOString().slice(0, 16);
              return formattedPostDate === formattedItemDate;
            });

            const matchedItemId = matchedItem ? matchedItem.id : null;
            if (matchedItemId) {
              return axios.post(
                `https://graph.facebook.com/v18.0/${matchedItemId}?access_token=${facebookAccessToken}`,
                metaPayload,
              );
            } else {
              console.log("No matched item found");
            }
            console.log("Matched Item ID:", matchedItemId);
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error,
            );
          });
      }
      if (item?.instaPostId) {
        const instapost = findObjectByParentId(instaPosts?.data, item._id);
        let data = {
          body: {
            caption: NewDesc,
            image_url: instapost?.ref?.body?.image_url,
            parentId: instapost?.ref?.body?.parentId,
          },
        };

        await axios.put(
          `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
            process.env.REACT_APP_DASHBOARD_API_PATH
          }/socials/update-post/${item?.instaPostId}`,
          { data },
          getAuthConfig(),
        );
      }

      if (item?.facebookPostId) {
        let data = {
          body: {
            message: NewDesc,
            photoUrl: item?.ref?.body?.photoUrl,
            user: item?.ref?.body?.user,
          },
        };

        await axios.put(
          `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
            process.env.REACT_APP_DASHBOARD_API_PATH
          }/socials/update-post/${item?.facebookPostId}`,
          { data },
          getAuthConfig(),
        );
      }
      setTrigger(!trigger);
      toast.info("il post è stato aggiornato con successo");
    } catch (error) {
      console.error("Error deleting schedule:", error);
    }
  }
  useEffect(() => {
    getSchedules();
  }, [trigger]);

  return (
    <div
      id="SocialCalendarPage"
      className="flex flex-col w-full justify-center content-center items-center">
      <div className="w-[500px]">
        {openEdit && (
          <ModifyPopUp
            item={currentSocialItem}
            inputData={currentSocialItem?.message || ""}
            onDecline={handleCloseEdit}
            ModifySocailMediapost={ModifySocailMediapost}
          />
        )}
      </div>
      <div className="flex flex-col w-full mx-auto ">
        <AnalyticsPlugin
          section1={true}
          section2={true}
          section3={true}
          section4={false}
          section5={false}
          section6={false}
          SectionName="Analytics generali"
          AnalyticsName1="Contenuto del mese"
          AnalyticsData1={56}
          Analyticspercentage1={31.5 + " %"}
          AnalyticsName2="Impressioni totali"
          AnalyticsData2={20}
          Analyticspercentage2={2.5 + " %"}
          AnalyticsName3="Interazioni totali"
          AnalyticsData3="12 min"
          Analyticspercentage3="-5 min"
          AnalyticsName4="Tempo medio per guardare il Menù"
          AnalyticsData4="12 min"
          Analyticspercentage4="30"
          AnalyticsName5="Tempo medio per guardare il Menù"
          AnalyticsData5="12 min"
          Analyticspercentage5="30"
          AnalyticsName6="Tempo medio per guardare il Menù"
          AnalyticsData6="12 min"
          Analyticspercentage6="30"
          text="rispetto al mese precedente"
          imgOk={true}
        />
        <InformationTopBanner
          pagetype="Sezione"
          sectionname="Social Media"
          description={
            process.env.REACT_APP_PROJECT === "Unifarco"
              ? "La tua farmacia"
              : "All’interno di questa sezione puoi creare, gestire, modificare i post social già pubblicati o programmati per la pubblicazione, vedere i commenti e le recensioni lasciate dai tuoi clienti e rispondere alle loro domande."
          }
          sectionicon={SocialManagerHeaderLottie}
        />

        <div className=" p-5  ">
          <SocNavigation />
        </div>

        <div className="flex w-full justify-center content-center items-center px-4 mt-8 sm:px-0 xsm:px-0 md:px-0">
          <div className="w-full mb-16 mx-auto">
            <div className="flex flex-row px-4 pt-4 w-full sm:flex-col xsm:flex-col md:flex-col gap-y-4 sm:px-0 xsm:px-0 md:px-0">
              <div className="w-1/3 px-2 sm:w-full sxm:w-full md:w-full">
                <MobileCalendar
                  SocialPosts={SocialPosts}
                  handleFilterPosts={handleFilterPosts}
                  value={value}
                />
              </div>
              <div className="w-full flex flex-col items-center elevation-1 gap-y-4 py-4 rounded-lg sm:w-full sxm:w-full md:w-full sm:px-0 xsm:px-0 md:px-0">
                {PostsToShow.filter((item) => !item.isDone).length !== 0 && (
                  <div className=" flex flex-row justify-between border-b-[3px] border-[#114653] w-full self-start pb-2 pl-2 sm:w-full sxm:w-full md:w-full">
                    <p className="capitalize text-[#114653] text-lg ">
                      Post Programmati {theItalianFormat}
                    </p>
                    <div
                      className={`w-8 h-8 bg-tertiarybtn rounded-md elevation-1 flex items-center justify-center`}>
                      <p className={`font-semibold text-white text-xs `}>
                        {PostsToShow?.filter((item) => !item.isDone)?.length}
                      </p>
                    </div>
                  </div>
                )}

                {PostsToShow.filter((item) => !item.isDone).length !== 0 && (
                  <div className="SocialMediaGridCards w-full  gap-4">
                    {PostsToShow?.filter((item) => !item.isDone)?.map(
                      (item, index) => (
                        <SocialPostCARD
                          key={index}
                          item={item}
                          DeleteSchedule={DeleteSchedule}
                        />
                      ),
                    )}
                  </div>
                )}

                {PostsToShow?.filter((item) => item.isDone).length !== 0 && (
                  <div className=" flex flex-row justify-between border-b-[3px] border-[#114653] w-full self-start pb-2 pl-2 sm:w-full sxm:w-full md:w-full">
                    <p className="capitalize text-[#114653] text-lg ">
                      Post Pubblicati
                    </p>
                    <div
                      className={`w-8 h-8 bg-tertiarybtn rounded-md elevation-1 flex items-center justify-center`}>
                      <p className={`font-semibold text-white text-xs `}>
                        {PostsToShow?.filter((item) => item.isDone)?.length}
                      </p>
                    </div>
                  </div>
                )}

                {PostsToShow?.filter((item) => item.isDone)?.length !== 0 && (
                  <div className="SocialMediaGridCards w-full  gap-4">
                    {PostsToShow?.filter((item) => item.isDone)?.map(
                      (item, index) => (
                        <SocialPostCARD
                          key={index}
                          item={item}
                          DeleteSchedule={DeleteSchedule}
                        />
                      ),
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
