import { useEffect, useState } from "react";
import useServiceCall from "./useServiceCall";

/**
 * Custom hook for wrapping an API service resource with a loader, toastify, and some callbacks.
 * @param apiCall API promise
 * @param defaultValue Default value to use for data
 * @param callCondition Predicate function to decide if api call should be executed
 * @param deps Dependencies of the useEffect used for the api call
 * @param mapResponse Function to use to map response data to desired state.
 * @param onPerformCall Fuction to run before api call.
 * @param onSuccess Fuction to run if api call succeeds.
 * @param onError Fuction to run if api call fails. Displays toast error by default.
 * @param getErrorMessage Fuction to run to extract error message from thrown error.
 * @param shouldShowToastError Boolean for displaying toast error message
 * @param promiseToastOptions Activate promise-controlled toast with pending/success/error messages when api call is performed.
 * @param automatic Call the api on first load + every time deps change
 * @returns [data, setData, getData, renderLoader, isLoading]
 */
export default function useServiceResource({
  apiCall,
  defaultValue = null,
  callCondition = () => true,
  deps = [],
  mapResponse,
  onPerformCall,
  onSuccess,
  onError,
  getErrorMessage,
  shouldShowToastError = true,
  promiseToastOptions,
  automatic = true
}) {
  const [data, setData] = useState(defaultValue);
  const [performCall, renderLoader, isLoading] = useServiceCall({
    apiCall,
    mapResponse,
    onPerformCall,
    onSuccess: returnedData => {
      setData(returnedData);
      if (onSuccess) onSuccess(returnedData);
    },
    onError,
    getErrorMessage,
    shouldShowToastError,
    promiseToastOptions
  });

  useEffect(() => {
    const abortController = new AbortController();
    if (automatic && callCondition()) performCall();
    return () => abortController.abort();
  }, [...deps]);

  return [data, setData, performCall, renderLoader, isLoading];
}
