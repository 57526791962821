import React, { useContext } from "react";
import ChartDown from "./Images/ChartRed.png";
import ChartUp from "./Images/ChartGreen.png";
import Negative from "./Icons/ChartDown.svg";
import Positive from "./Icons/ChartUp.svg";
import "./ratingstars.css";
import { ResultsCalendarContext } from "../../Contexts/ResultsCalendarContext";
function ResultsSmallCard({ title, value, percentage, chart, rating }) {
    const { type } = useContext(ResultsCalendarContext);
    return (
        <div className={`p-6 border rounded-lg mt-10 w-full
        ${"Recensioni" === title && "bg-[#fee4e2]"}
        ${"Visite Scheda Google" === title && "bg-[#ebe9fe]"}
        ${"Visite al Sito" === title && "bg-[#ffebd6]"}
        `}>
            <h2 className="text-2xl w-full">
                {title}
            </h2>
            <div className="flex justify-between ">
                <div>
                    <div className="flex mt-6">
                        <h3 className="text-3xl">
                        {value}
                        </h3>
                        {"Recensioni" === title &&
                            <>
                                <div className="flex gap-x-2 ml-4">
                                    <span className={`star text-3xl ${rating >= 1 ? "full" : rating > 0.5 ? "half" : "empty"}`}></span>
                                    <span className={`star text-3xl ${rating >= 2 ? "full" : rating > 1.5 ? "half" : "empty"}`}></span>
                                    <span className={`star text-3xl ${rating >= 3 ? "full" : rating > 2.5 ? "half" : "empty"}`}></span>
                                    <span className={`star text-3xl ${rating >= 4 ? "full" : rating > 3.5 ? "half" : "empty"}`}></span>
                                    <span className={`star text-3xl ${rating >= 5 ? "full" : rating > 4.5 ? "half" : "empty"}`}></span>
                                </div>
                                <span className="font-bold text-2xl flex items-center ml-2">
                                    <span>
                                        (
                                    </span>
                                    <span>
                                        {rating}
                                    </span>
                                    <span>
                                        )
                                    </span>
                                </span>
                            </>
                        }
                    </div>
                    <div className="flex mt-4 items-center xsm:flex-col xsm:items-start sm:flex-col sm:items-start md:flex-col md:items-start xmd:flex-col xmd:items-start lg:flex-col lg:items-start">
                        <div className="flex">
                            {0 > percentage ? (
                                <img src={Negative}>
                                </img>
                            ) : (
                                <img src={Positive}>
                                </img>
                            )}
                            <span className={`ml-1 font-bold ${0 > percentage ? "text-[#f04438]" : "text-[#17b26a]"}`}>
                            {percentage}%
                            </span>
                        </div>
                        {"single" === type ? (
                            <span className="xl:ml-3 2xl:ml-2 text-[#667085]">
                                vs mese scorso
                            </span>
                        ) : (
                            <span className="xl:ml-3 2xl:ml-2 text-[#667085]">
                                vs periodo scorso
                            </span>
                        )}
                    </div>
                </div>
                {chart &&
                    <>
                        {0 > percentage ? (
                        <div className="flex mt-auto ml-5">
                            <img src={ChartDown}>
                            </img>
                        </div>
                    ) : (
                        <div className="flex mt-auto ml-5">
                            <img src={ChartUp}>
                            </img>
                        </div>
                    )}
                </>
                }
            </div>
        </div>
    )
}
export default ResultsSmallCard;