const recursiveFillSetter = (object, color) => {
    if (object.fill) {
        object.set({ fill: color });
    }

    if (object._objects) {
        object._objects.forEach((child) => recursiveFillSetter(child, color));
    }
};

export default recursiveFillSetter;
