import React from "react";

import "./MailAutomation.css";

function MailAutomationcard({ card }) {

  return (

      <div className="w-full h-max p-0 rounded-t-md">
        <div className="h-full flex flex-col w-full gap-y-4 items-center">
          <div className="w-[135px] h-[135px] flex items-center mt-5 justify-center">
            <img src={card?.icon} alt=""/>
          </div>

          <div className="flex flex-col justify-start items-center py-4">
            <p className="pop600 text-[22px] text-newslettertextcolour ">
              {card?.name}
            </p>
            <p className="px-10 py-2 font-normal text-black">{card?.description}</p>
          </div>
        </div>
      </div>

  );
}

export default MailAutomationcard;
