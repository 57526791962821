import React from 'react';
import Lottie from "lottie-react";
import '../../Informational-Top-Banner.css';

const RCInformationtopbanner = ( { pagetype, sectionname, description, sectionicon } ) => {

  return (

    <div className="flex justify-center items-center">
      
      {/* Desktop Version */}

      <div className="flex flex-row w-[95%] h-max elevation-1 rounded-b-md bg-primarybtn bg-opacity-[15%] xsm:hidden sm:hidden">

        <div className="flex flex-col w-[290px] h-40 place-content-center place-items-center">

          <div className="bg-primarybtn w-max px-6 -ml-12 rotate-[-8deg] elevation-1 rounded-md">

            <p className="headerdesktoppagetypetext">{pagetype}</p>

          </div>

          <div className="flex bg-white w-max h-12 px-6 place-content-center place-items-center elevation-1 rounded-md">

            <p className="headerdesktopsectionnametext">{sectionname}</p>

          </div>

        </div>

        <div className="flex flex-row place-content-center place-items-center w-8/12 h-40 px-12">  

          <p className="headerdesktopdescriptiontext flex text-left justify-center content-center break-words"> {description} </p>

        </div>

        <div className="flex w-2/12 h-40 mr-12 xmd:mr-8 md:hidden xmd:hidden">

          <div className="flex w-40 h-40 mx-auto justify-center items-center">

            <Lottie animationData={sectionicon} loop={true} />

          </div> 

        </div>

      </div>

    </div>

  );
};

export default RCInformationtopbanner;