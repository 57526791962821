import React, { createContext, useState } from 'react';

export const SocialMediaContext = createContext({});

const SocialMediaProvider = ({ children }) => {
    const [hashtags, setHashtags] = useState([]);

    const [image, setImage] = useState();
    const [imageUrl, setImageUrl] = useState();

    const [json, setJson] = useState(null);
    const [html, setHtml] = useState(null);

    const [titlePost, setTitlePost] = useState('');
    const [description, setDescription] = useState('');

    const [socials, setSocials] = useState({
        facebook: false,
        instagram: false,
        gmb: false,
    });
    const setSocial = (socialData) => setSocials({ ...socials, ...socialData });

    const contextData = {
        image,
        setImage,
        imageUrl,
        setImageUrl,
        titlePost,
        setTitlePost,
        description,
        setDescription,
        json,
        setJson,
        html,
        setHtml,
        hashtags,
        setHashtags,
        socials,
        setSocial,
    };

    return (
        <SocialMediaContext.Provider value={contextData}>
            {children}
        </SocialMediaContext.Provider>
    );
};

export default SocialMediaProvider;
