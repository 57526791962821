import React, { useState } from "react";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import { cardsData } from "./Helpers/CardsData";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import Backbutton from "../../../../Reusable/Back-Button/Back-Button";
// import {
//   FormControl,
//   MenuItem,
//   Select,
// } from "@mui/material";
import MailAutomationcard from "./MailAutomationCard";
import SingleEmailAutomation from "./SingleEmailAutomation";
import "./MailAutomation.css";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner"
    )
  ).default;
}

function MailAutomationMain() {
  const [selectedRadio, setSelectedRadio] = useState(cardsData[0])
  // const styles = {
  //   formControlLabel: {
  //     width: "50%",
  //     color: "#FFA563",
  //     span: {
  //       color: "#FFA563",
  //     },
  //     padding: "10px",
  //   },
  //   RadioGroup: {
  //     flexDirection: "row",
  //     "@media (max-width: 834px)": {
  //       display: "none",
  //     },
  //   },
  //   Select: {
  //     marginBottom: "20px",
  //     "@media (min-width: 834px)": {
  //       display: "none",
  //     },
  //     "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
  //       backgroundColor: "#FFA563",
  //     },
  //   },
  // };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };
  return (
    <div className="w-full mx-auto ">
      <InformationTopBanner
        pagetype="Mail"
        sectionname="Automatiche"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "Da questa sotto sezione puoi gestire tutte le Mail Automatiche che hai creato e che sono attive, con la possibilità di eliminarle."
        }
        sectionicon={NewsletterHeaderLottie}
      />

      <div className="flex w-1/4 ml-16 mt-6 xsm:w-full sm:w-full md:w-full xsm:ml-2 sm:ml-2 md:ml-0">
        <Backbutton />
      </div>

      <ScrollToTop />
      <div className="flex flex-row justify-evenly mt-8 mx-auto h-max gap-x-4 rounded-md w-[95%] xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse">
        <div className="flex flex-col w-[47%] justify-center gap-y-8 bg-white rounded-md elevation-1 xsm:w-full sm:w-full md:w-full">
          <MailAutomationcard
            card={cardsData.find((c) => c.name === selectedRadio.name)}
          />
        </div>
        <div className="grid grid-cols-2 gap-3 w-1/2 h-full py-8 xsm:w-full sm:w-full md:w-full" >
          {cardsData?.map(campaign => (
            <label
              onClick={() => { handleRadioChange(campaign) }}
              className={`border border-newsletterbtn rounded-lg ${campaign.name === selectedRadio.name ? "bg-newsletterbtn text-white" : "bg-transparent text-newsletterbtn"} items-center justify-center flex py-2 px-6 `}>
              {campaign.name}
            </label>
          ))}
        </div>
        {/* <FormControl className="w-[60%]">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="buyer-personas"
            value={selectedRadio}
            onChange={handleRadioChange}
            sx={styles.RadioGroup}
          >
            {cardsData?.map((card, index) => (
              <FormControlLabel
                key={index}
                value={card.name}
                control={<Radio />}
                label={card.name}
                sx={styles.formControlLabel}
              />
            ))}
          </RadioGroup>
        </FormControl> */}

      </div>
      <SingleEmailAutomation
        card={cardsData.find((c) => c.name === selectedRadio.name)}
      />
    </div>
  );
}

export default MailAutomationMain;
