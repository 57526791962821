import axios from "axios";
import { toast } from "react-toastify";
import { getAuthConfig } from "../Utility/Api/auth";
import { baseUrl } from "../Utility/Api/Urls";
import { getUserData, getUserId } from "../Utility/getUserData";

const DEFAULT_ERROR = "C'è stato un errore. Riprova o ricarica la pagina.";
const BASE_ENDPOINT = `https://${
  process.env.REACT_APP_DASHBOARD_PREFIX
}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;

/**
 * Retrieve logo of the restaurant
 * @returns Base64 Image string
 */
export async function getLogo() {
  try {
    const info = await getRestaurantInfo();
    if (!info) return;

    return info.theme_photos?.logo || "";
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieve info of the restaurant
 */
export async function getRestaurantInfo() {
  try {
    const url = `https://${getUserId()}.${
      process.env.REACT_APP_THEMES_PREFIX
    }${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/restaurant-info`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data || data?.length < 1) throw new Error("No data received.");

    return data[0];
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieve pharmacy information
 * @returns Pharmacy information
 */
export async function getPharmacyData() {
  try {
    const userData = getUserData();
    const url = `${BASE_ENDPOINT}/pharmacies/me`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return {
      website: `www.${data.domain}`,
      contact: userData?.phone,
      logoUrl: data.logo,
      data:data
    };
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

/**
 * Retrieve location of the restaurant
 * @returns Pharmacy information
 */
export async function getCityAndCountry() {
  try {
    const url = `https://${getUserId()}.${
      process.env.REACT_APP_THEMES_PREFIX
    }${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/restaurant-info`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data || data?.length < 1) throw new Error("No data received.");

    const address = data.formatted_address;

    // try to extract city and country otherwise just return the entire address
    try {
      const extractedLocation = address
        .split(",")
        .slice(-2)
        .map(v => v.replaceAll(/[0-9]/gi, "").trim())
        .join(", ");
  
      return extractedLocation;
    } catch (error) {
      return address;
    }
  } catch (error) {
    console.error(error);
  }
}
