import React, { useState } from "react";
import img from "../../../../Assets/Images/MarketingCardImage.png"
import { Tooltip } from "@mui/material";
import format from 'date-fns/format'
function OnProgressCampaignCard({
    campaign,
    onStop,
    onModifyClick,
}) {
    const handleModifyClick = () => onModifyClick(campaign);
    const handleStopClick = () => onStop(campaign);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);



    return (
        <div className='w-[350px] h-fit rounded-md elevation-1 mx-auto flex flex-col  text-left gap-y-4 bg-white' >
            <Tooltip title={campaign.name} placement="bottom-end" arrow>
                <div
                    className="h-[7%] bg-[#6392FF26] rounded-t-md flex items-center justify-center" >
                    <p className="campaignnamespace text-marketingtextcolour text-xl">{campaign.name}</p>
                </div>
            </Tooltip>
            <p className='campaigndescriptionspace  pl-1 -my-2 text-tertiarytextcolour font-semibold'>
                Dal  <span className="font-normal"> {campaign.startingDate} </span>
                al <span className="font-normal"> {campaign.endingDate} </span>
            </p>

            <Tooltip title="Clicca per vedere l'immagine" placement="bottom-end" arrow>

                <div onClick={() => setIsModalOpen2(true)} className='flex justify-self-center items-start w-[350px] h-max hover:cursor-pointer' >

                    <img src={campaign.image || img} alt="" className='w-full h-[170px] object-cover object-center overflow-hidden' />

                </div>
            </Tooltip>

            {isModalOpen2 && (
                <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-md p-4 w-1/2 flex flex-col justify-center items-center gap-4">

                        <img src={campaign.image || img} alt="" className='w-full h-full object-cover object-center overflow-hidden' />

                        <button
                            className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig"
                            onClick={() => setIsModalOpen2(false)}
                        >
                            Chiudi
                        </button>
                    </div>
                </div>
            )}

            <div className="flex flex-col gap-y-1 pl-2 pt-2  rounded-t-md bg-[#f5f5f5f5] pb-4">

                <div className="relative flex bg-gray-400 bg-opacity-10">

                    {isModalOpen && (
                        <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                            <div className="bg-white rounded-md p-4 w-1/2 flex flex-col justify-center items-center">
                                <p className="text-tertiarytextcolour text-xl font-semibold mb-2">Dati Campagna</p>
                                <p className="text-tertiarytextcolour mb-4 text-left">In questa sezione, che sarà disponibile a breve, potrai visualizzare i dati relativi alla Campagna pubblicitaria in corso. In questo modo, potrai avere una visione globale sul suo andamento</p>
                                <button
                                    className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Chiudi
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="absolute inset-0 flex flex-row items-center justify-center gap-4 z-10">

                        <div className='flex'>

                            <p className='text-tertiarytextcolour text-base'>Questa Sezione sarà <br /> disponibile a breve</p>

                        </div>

                        <div className='flex'>

                            <button onClick={() => setIsModalOpen(true)} className='w-40 h-12 rounded-md elevation-1 text-white bg-tertiarybtn hover:bg-tertiarybtnhover active:bg-primarybtn active:shadow-innerbig'>Scopri di più</button>

                        </div>

                    </div>

                    <div className="flex flex-col blur-[3px] cursor-default">

                        <p className='campaigndescriptionspace  text-tertiarytextcolour font-semibold'>
                            Copertura:   <span className="font-normal"> 1000 persone raggiunte  </span>
                        </p>
                        <p className='campaigndescriptionspace   text-tertiarytextcolour font-semibold'>
                            Risultati:   <span className="font-normal"> 3000 interazioni  </span>
                        </p>
                        <p className='campaigndescriptionspace   text-tertiarytextcolour font-semibold'>
                            Budget:  <span className="font-normal"> € 30,00 </span>
                        </p>
                        <p className='campaigndescriptionspace  text-tertiarytextcolour font-semibold'>
                            Performance Campagna:    <span className="font-normal"> Poco Bene </span>
                        </p>

                    </div>

                </div>

                <div className='flex flex-row w-full mt-2 justify-center'  >
                    {/*
                    <button
                        className='w-[47%] bg-marketingbtn h-fit text-sm py-2 text-white border-2 border-marketingbtn rounded-md px-4 uppercase' >
                        Mostra Risultati
                    </button>
                    */}

                    {campaign.status === "in_progress" ? (

                        <div className="text-deletetextcolour mt-4">
                            <p className="text-deletetextcolour">
                                La campagna è in fase di approvazione, potrai disattivarla una volta che sarà attiva
                            </p>
                        </div>

                    ) : (

                        <button
                            onClick={handleStopClick}
                            className={`w-[47%] bg-deletetextcolour h-fit text-sm py-2 text-white rounded-md uppercase ${campaign.status === "in_progress" ? "" : ""}`}
                            disabled={campaign.status === "in_progress"} >
                            Disattiva Campagna
                        </button>
                    )

                    }
                </div>
            </div>

        </div>
    )
}

export default OnProgressCampaignCard