import React from "react"
export default function OtherPersonaCampaignCard({ persona, goal, location, onSelect, selected }) {
  const { label, ageRange, otherParams, color, description } = persona;

  const imageStyles = persona.styles.circularImageDiv;
  const { icon, name: goalName } = goal;

  const iconStyles = {
    background: `url('${icon}') no-repeat`,
    width: "185px",
    height: "185px",
    backgroundSize: "cover",
  };
  const [toSelect, SetSelect] = React.useState(null)

  return (
    <div className="buyerpersonacard w-[865px] h-[300px] md:w-full md:h-full sm:w-full sm:h-full xsm:w-full xsm:h-full p-0 relative overflow-hidden elevation-1">
      <div className="w-full h-full flex flex-row gap-x-5">
        {/* IMAGE */}
        <div className="h-full w-1/4 flex flex-col items-center">
          <div
            className="w-[200px] h-[200px] rounded-full m-auto"
            style={imageStyles}
          ></div>
        </div>

        {/* ALL TEXT */}
        <div className="w-3/4 flex flex-col gap-y-1 pt-5">
          <p
            className="font-semibold text-xl text-left uppercase"
            style={{ color }}
          >
            tipologia campagna: {goalName}
          </p>

          <p className="font-semibold text-2xl text-left">{label}</p>
          <div className="flex flex-row">
            <div className="w-3/4">

              <p className="font-normal text-xl text-[#2E2E2E] text-left mb-4">
                {description}
              </p>

              <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                Età: <span className="font-normal"> {ageRange}</span>
              </p>

              {location && (
                <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                  Luogo: <span className="font-normal"> {location} </span>
                </p>
              )}

              {otherParams?.map(p => (
                <p
                  className="font-semibold text-xl text-[#2E2E2E] text-left"
                  key={p.value}
                >
                  {p.label}: <span className="font-normal">{p.value}</span>
                </p>
              ))}
            </div>
            <div className="absolute top-0 right-0">
              <button
                onClick={() => {
                  SetSelect(selected?.id)
                  onSelect(persona)
                }}
                className={`${toSelect === persona?.id ? "text-white bg-marketingbtn border-2 border-marketingbtn elevation-1" : "bg-white text-marketingtextcolour"} w-40 h-12 rounded-t-md rounded-bl-md border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:text-white font-semibold active:border-white active:shadow-innerbig`}>
                {toSelect === persona?.id ? "Scelto" : "Scegli"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="absolute bottom-[-15%] right-[-3%]"
        style={iconStyles}
      ></div>
    </div>
  );
}
