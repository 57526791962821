import React, { useContext, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { ResultsCalendarContext } from "../../Contexts/ResultsCalendarContext";
import Check from "./Icons/check.svg";

function ResultsCalendar({ close }) {
  const {
    selectedMonths,
    setMonths,
    months,
    type,
    setType,
    setMonthsForResults,
    monthsForResults,
    years,
    fetchData,
  } = useContext(ResultsCalendarContext);

  const [fromMonthToShow, setFromMonthToShow] = useState(selectedMonths.from);
  const [toMonthToShow, setToMonthToShow] = useState(selectedMonths.to);
  const [fromMonthNumberForResults, setFromMonthNumberForResults] = useState(
    monthsForResults[0],
  );
  const [toMonthNumberForResults, setToMonthNumberForResults] = useState(
    monthsForResults.slice(-1)[0],
  );
  const [typeToShow, setTypeToShow] = useState(type);
  const [selectedYearSingle, setSelectedYearSingle] = useState(
    new Date().getFullYear(),
  );
  const [selectedYearMultiple, setSelectedYearMultiple] = useState(
    new Date().getFullYear(),
  );

  const onSave = () => {
    if (typeToShow === "single") {
      const startMonthYear = `${
        months.findIndex((month) => month.name === fromMonthToShow) + 1
      }-${selectedYearSingle}`;
      const endMonthYear = `${
        months.findIndex((month) => month.name === fromMonthToShow) + 1
      }-${selectedYearSingle}`;

      setMonthsForResults([startMonthYear, endMonthYear]);
      setMonths({ from: fromMonthToShow, to: fromMonthToShow });
    } else {
      const startMonthYear = `${
        months.findIndex((month) => month.name === fromMonthToShow) + 1
      }-${selectedYearSingle}`;
      const endMonthYear = `${
        months.findIndex((month) => month.name === toMonthToShow) + 1
      }-${selectedYearMultiple}`;

      setMonthsForResults([startMonthYear, endMonthYear]);
      setMonths({ from: fromMonthToShow, to: toMonthToShow });
    }
    close(false);
  };

  return (
    <div className="p-4 absolute border right-0 flex-col top-[50px] rounded-md z-10 flex bg-white gap-x-4">
      <div className="">
        <label>
          <div className="flex flex-row">
            <div className="w-full flex">
              <input
                type="radio"
                name="month"
                checked={typeToShow === "single"}
                onChange={() => {
                  setTypeToShow("single");
                }}></input>
              <span className="ml-2">Singolo mese</span>
            </div>
            <div className="w-full flex">
              <input
                type="radio"
                name="month"
                checked={typeToShow === "multiple"}
                onChange={() => setTypeToShow("multiple")}></input>
              <span className="ml-2">Mesi multipli</span>
            </div>
          </div>
        </label>
        <div className="relative mt-4 flex flex-row gap-4">
          <div>
            <div>{typeToShow === "single" ? "Mese" : "Da"}</div>
            <select
              className="py-2 px-3 rounded-md border mt-2 w-[130px]"
              value={fromMonthToShow}
              onChange={(e) => setFromMonthToShow(e.target.value)}>
              {months.map((month, index) => (
                <option key={index} value={month.name}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div>Anno</div>
            <select
              className="py-2 px-3 rounded-md border mt-2 w-[130px]"
              value={selectedYearSingle}
              onChange={(e) => setSelectedYearSingle(e.target.value)}>
              {years.map((year, index) => (
                <option key={index} value={year.value}>
                  {year.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {typeToShow === "multiple" && (
        <div className="mt-4">
          <div className="relative flex flex-row gap-4">
            <div>
              <div>A</div>
              <select
                className="py-2 px-3 rounded-md border mt-2 w-[130px]"
                value={toMonthToShow}
                onChange={(e) => setToMonthToShow(e.target.value)}>
                {months.map((month, index) => (
                  <option key={index} value={month.name}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div>Anno</div>
              <select
                className="py-2 px-3 rounded-md border mt-2 w-[130px]"
                value={selectedYearMultiple}
                onChange={(e) => setSelectedYearMultiple(e.target.value)}>
                {years.map((year, index) => (
                  <option key={index} value={year.value}>
                    {year.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-row justify-center items-center gap-4">
        <div className="flex mt-6">
          <button
            className="py-2 px-4 border rounded-md font-bold bg-[#00b27a] text-white"
            onClick={onSave}>
            Salva
          </button>
        </div>
        <div className="flex mt-6">
          <button
            className="py-2 px-4 border rounded-md font-bold text-[#344054]"
            onClick={() => close(false)}>
            Indietro
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResultsCalendar;
