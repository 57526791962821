import React from "react";
import "./WebSiteThemeNavigation.css";
import WebSiteThemeMobileNavigation from "./WebSiteThemeMobileNavigation/WebSiteThemeMobileNavigation";
import WebSiteThemeCustomLink from "./CustomLink/WebSiteThemeCustomLink";

const WebSiteThemeNavigation = () => {

  
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="flex xmd:hidden lg:hidden xl:hidden 2xl:hidden">
        <WebSiteThemeMobileNavigation />
      </div>

      <div className="flex flex-col justify-center items-center xsm:hidden sm:hidden md:hidden">
        <nav className="SocNavigation__list flex w-full">
          <ul className="SocNavigation__list">
            <li>
              <WebSiteThemeCustomLink to="/website/theme">
                Sito
              </WebSiteThemeCustomLink>
            </li>

            {/* <li>
              <WebSiteThemeCustomLink to="/website/Visitatore">
                Visitatore
              </WebSiteThemeCustomLink>
            </li> */}
            
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default WebSiteThemeNavigation;
