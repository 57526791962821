import React, { useContext, useEffect, useState } from "react";
import DashNavigation from "../DashNavigation/resultsnavigation";
import ResultsBigCard from "../../../../Reusable/Results-Cards/resultsbigcard";
import ResultsCalendarButton from "../../../../Reusable/Results-Calendar/resulstscalendarbutton";
import ResultsSmallCard from "../../../../Reusable/Results-Cards/resultssmallcard";
import { ResultsCalendarContext } from "../../../../Contexts/ResultsCalendarContext";
function IndexRisultati() {
    const { dataPerMonth } = useContext(ResultsCalendarContext);
    const whiteCardsData = [
        {
            'title': "Delivery e Asporto",
            'value': dataPerMonth.orders,
            'percentage': dataPerMonth.ordersPercentage
        },
        {
            'title': "Prenotazioni",
            'value': dataPerMonth.bookings,
            'percentage': dataPerMonth.bookingsPercentage
        },
        {
            'title': "Clienti",
            'value': dataPerMonth.customers,
            'percentage': dataPerMonth.customersPercentage
        }
    ]
    const bigCardsData = [
        {
            'title': "Risultati Social",
            'data': [
                {
                    'value': 100,
                    'title2': "Utenti raggiunti",
                    'percentage': 50
                },
                {
                    'value': 100,
                    'title2': "Interazioni",
                    'percentage': 50
                },
                {
                    'value': 100,
                    'title2': "Visite",
                    'percentage': 50
                }
            ]
        },
        {
            'title': "Azioni Scheda Google",
            'data': [
                {
                    'value': dataPerMonth.gmbPhoneClicks,
                    'title2': "Chiamate",
                    'percentage': dataPerMonth.gmbPhoneClicksPercentage
                },
                {
                    'value': dataPerMonth.gmbDirectionRequested,
                    'title2': "Indicazioni stradali",
                    'percentage': dataPerMonth.gmbDirectionRequestedPercentage
                },
                {
                    'value': dataPerMonth.traficsWebMenuPage,
                    'title2': "Visualizzazioni al menu",
                    'percentage': 50
                }
            ]
        }
    ]
    const colouredCardsData = [
        {
            'title': "Recensioni",
            'value': 245,
            'percentage': 50,
            'rating': dataPerMonth.gmbAverageRating
        },
        {
            'title': "Visite Scheda Google",
            'value': dataPerMonth.traficsGmb,
            'percentage': dataPerMonth.traficGmbPercentage
        },
        {
            'title': "Visite al Sito",
            'value': dataPerMonth.traficsWeb,
            'percentage': dataPerMonth.traficWebPercentage
        }
    ];
  return (
      <div className="xsm:px-4 sm:px-4 md:px-4 w-[95%] mt-2 mx-auto">
          <div className="w-full flex">
              <DashNavigation />
          </div>
          <div className="w-full mt-8">
              <div className="flex justify-between items-center">
                  <h1 className="text-3xl font-bold">
                      I tuoi risultati
                  </h1>
                  <div className="xsm:hidden sm:hidden md:hidden">
                  <ResultsCalendarButton />
                  </div>
              </div>
              <p className="mt-1">
                  Tieni traccia dei tuoi risultati generali.
              </p>
              <div className="xsm:block sm:block md:block hidden mt-4">
                  <ResultsCalendarButton />
              </div>
          </div>
          <div className="xmd:flex xmd:flex-col">
              <div className="xsm:block sm:block md:block flex gap-x-5">
                  {bigCardsData.map((card, index) => (
                      <ResultsBigCard title={card.title} data={card.data} index={index} />
                  ))}
              </div>
              <div className="xmd:flex gap-x-5">
                  <div className="xsm:block sm:block md:block xmd:flex-col xmd:w-[49%] flex w-full gap-x-14">
                  {colouredCardsData.map((card, index) => (
                      <ResultsSmallCard title={card.title} value={card.value} percentage={card.percentage} rating={card.rating} />
                  ))}
                  </div>
                  <div className="xsm:block sm:block md:block xmd:flex-col xmd:w-[49%] flex w-full gap-x-14">
                      {whiteCardsData.map((card, index) => (
                          <ResultsSmallCard title={card.title} value={card.value} percentage={card.percentage} chart={true} />
                      ))}
                  </div>
              </div>
          </div>
    </div>
  )
}
export default IndexRisultati;
