import React, { useEffect, useState } from 'react';
import { campaigns } from './Utils';
export default function SuggestedGoalsContent({ SetCampName, campaign }) {
    const currentPlan = localStorage.getItem('currentPlan');

    const [currentCampaigns, setCurrentCampaigns] = useState(campaigns);

    useEffect(() => {
        if (currentPlan === 'nodelivery') {
            setCurrentCampaigns((prevCampaigns) =>
                prevCampaigns.filter((campaign) => campaign.name !== 'Delivery')
            );
        } else if (currentPlan === 'nobooking') {
            setCurrentCampaigns((prevCampaigns) =>
                prevCampaigns.filter(
                    (campaign) => campaign.name !== 'Prenotazioni'
                )
            );
        } else if (currentPlan === 'none') {
            setCurrentCampaigns((prevCampaigns) =>
                prevCampaigns.filter(
                    (campaign) =>
                        campaign.name !== 'Delivery' &&
                        campaign.name !== 'Prenotazioni'
                )
            );
        } else {
            setCurrentCampaigns(campaigns);
        }
    }, [currentPlan, campaigns]);

    return (
        <div className='w-full flex flex-row h-fit gap-x-8 w-[95%] mx-auto xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse'>
            <div className='flex flex-col items-center gap-y-3 w-1/2 elevation-2 h-full justify-center xsm:w-full sm:w-full md:w-full p-2'>
                {currentCampaigns
                    ?.filter((item) => item.value === campaign)
                    ?.map((item, index) => (
                        <div
                            key={index}
                            className='flex flex-col items-center justify-center gap-y-3'
                        >
                            <img
                                alt={item.description || item.name}
                                src={item?.icon}
                            />
                            <p className='font-semibold text-lg text-[#E9606F] text-left'>
                                {item.name}
                            </p>
                            <p className='font-normal text-[#000] px-8 text-center'>
                                {item.description}
                            </p>
                        </div>
                    ))}
            </div>

            <div className='grid grid-cols-2 gap-3 w-1/2 h-full xsm:w-full sm:w-full md:w-full xsm:mb-4 sm:mb-4 md:mb-4'>
                {currentCampaigns?.map((item, index) => (
                    <button
                        key={index}
                        onClick={(e) => {
                            SetCampName(item.value);
                        }}
                        className={`border border-[#E9606F]
              rounded-lg ${
                  item.value === campaign
                      ? ' bg-[#E9606F] text-white '
                      : ' bg-transparent text-[#E9606F] '
              }
              items-center justify-center flex  px-6 h-fit py-6 hover:cursor-pointer `}
                    >
                        {item.name}
                    </button>
                ))}
            </div>
        </div>
    );
}
