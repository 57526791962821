import React, { useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Search from './icon/search.svg';
import { ButtonProva } from '../../../../Reusable/Button-NEW/ButtonProva';
import './fonts.css';
import { addText } from '../helpers/layerCreator';

export const TextSection = ({
    commonProps,
    setTextIsSelected,
    setTextOpacities,
    textOpacities,
    setSelectedFontColor,
    setFontSize,
    selectedFont,
    setSelectedFont,
    setSelectedFontFamily,
    setCanvaUnactive,
}) => {
    const fontList = [
        'Abril Fatface',
        'Playfair Display',
        'Bebas Neue',
        'Caveat Brush',
        'Signika',
        'Dancing Script',
        'Pacifico',
        'Amatic SC',
        'Itim',
        'Londrina Shadow',
        'Arimo',
        'Poppins',
        'Tinos',
    ];
    const [searchedFont, setSearchedFont] = useState('');
    const filteredFonts = fontList
        .filter((font) =>
            font.toLowerCase().includes(searchedFont.toLowerCase())
        )
        .sort();
    const handleInputChange = (event) => {
        setSearchedFont(event.target.value);
    };
    const [displayedFont, setDisplayedFont] = useState(null);

    const handleAddText = (fontFamily) => {
        addText(null, commonProps, {
            fontFamily,
            setTextIsSelected,
            textOpacities,
            setTextOpacities,
            selectedFont,
            setSelectedFont,
        });
    };

    return (
        <div id='text-section'>
            <div className='bg-white flex items-center rounded overflow-hidden'>
                <img
                    src={Search}
                    alt='Search'
                    className='ml-2'
                ></img>
                <input
                    type='text'
                    placeholder='Cerca un carattere tipografico'
                    value={searchedFont}
                    onChange={handleInputChange}
                    className='p-2 w-full'
                ></input>
            </div>
            <div className='mt-6'>
                <ButtonProva
                    text='Aggiungi un testo'
                    textcolor='white'
                    bgcolor='[#00b27a]'
                    Icon={AddCircleOutlineOutlinedIcon}
                    event={() => {
                        handleAddText('poppins');
                        setFontSize(30);
                        setSelectedFontColor('black');
                    }}
                />
            </div>
            <h6 className='mt-8'>Oppure scegli un carattere tipografico</h6>
            <ul className='ml-2 mt-6'>
                {filteredFonts.map((font) => (
                    <li
                        key={font}
                        className={`
                    ${font === 'Abril Fatface' ? 'abril' : ''}
                    ${font === 'Playfair Display' ? 'playfair' : ''}
                    ${font === 'Bebas Neue' ? 'bebas' : ''}
                    ${font === 'Caveat Brush' ? 'caveat' : ''}
                    ${font === 'Signika' ? 'signika' : ''}
                    ${font === 'Dancing Script' ? 'dancing' : ''}
                    ${font === 'Pacifico' ? 'pacifico' : ''}
                    ${font === 'Amatic SC' ? 'amatic' : ''}
                    ${font === 'Itim' ? 'itim' : ''}
                    ${font === 'Londrina Shadow' ? 'londrina' : ''}
                    ${font === 'Arimo' ? 'arimo' : ''}
                    ${font === 'Poppins' ? 'poppins' : ''}
                    ${font === 'Tinos' ? 'tinos' : ''}
                    mt-2 text-lg`}
                    >
                        <button
                            onClick={(event) => {
                                setSelectedFontFamily(font);
                                setDisplayedFont(font);
                                handleAddText(font);
                                setSelectedFontColor('black');
                                setCanvaUnactive(false);
                                setFontSize(30);
                                event.stopPropagation();
                            }}
                            className={`${
                                font === displayedFont && 'bg-white rounded'
                            } pl-3 py-1 w-[50%] text-start`}
                        >
                            {font}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
