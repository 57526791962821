import React from 'react';
import circle from './icon/ellipse.svg';
import square from './icon/square.svg';
import rectangle from './icon/rectangle.svg';
import pillRectangle from './icon/pillRectangle.svg';
import radiusRectangle from './icon/radiusRectangle.svg';
import restaurant from './icon/restaurant.svg';
import location_on from './icon/location_on.svg';
import call from './icon/call.svg';
import eco from './icon/eco.svg';
import star from './icon/star.svg';
import award_star from './icon/award_star.svg';
import facebook from './icon/facebook.svg';
import whatsapp from './icon/whatsapp.svg';
import { addElementFromEditor } from '../helpers/layerCreator';

const icons = [
    { icon: square, name: 'square' },
    { icon: circle, name: 'circle' },
    { icon: rectangle, name: 'rectangle' },
    { icon: pillRectangle, name: 'pillRectangle' },
    { icon: radiusRectangle, name: 'radiusRectangle' },
    { icon: whatsapp, name: 'whatsapp' },
    { icon: facebook, name: 'facebook' },
    { icon: call, name: 'call' },
    { icon: location_on, name: 'location_on' },
    { icon: restaurant, name: 'restaurant' },
    { icon: eco, name: 'eco' },
    { icon: star, name: 'star' },
    { icon: award_star, name: 'award_star' },
];
export const ElementSection = ({
    commonProps,
    setElementIsSelected,
    setElementOpacities,
    elementOpacities,
    setElementBorderWidth,
    elementBorderWidth,
    setElementColor,
    setElementBorderColor,
    setSelectedElementBorderColor,
}) => {
    return (
        <div
            className='flex gap-x-6 flex-wrap gap-y-5'
            id='element-section'
        >
            {icons.map((icon) => (
                <button
                    key={icon.name}
                    onClick={() => {
                        addElementFromEditor(
                            icon.icon,
                            commonProps,
                            setElementIsSelected,
                            setElementOpacities,
                            setElementBorderWidth,
                            setElementColor,
                            setSelectedElementBorderColor,
                            setElementBorderColor
                        );
                    }}
                >
                    <img
                        src={icon.icon}
                        alt={icon.name}
                    />
                </button>
            ))}
        </div>
    );
};
