import Joi from "joi";

const validationSchema = Joi.object({
  billingAddress: Joi.string().min(1).label("Billing Address").required(),

  fiscalCode: Joi.string()
    .regex(
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    )
    .label("Fiscal Code")
    .required()
    .messages({ "string.pattern.base": "Invalid fiscal code." }),

  invoicingEndpoint: Joi.alternatives()
    .try(
      Joi.string().regex(
        /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/i
      ),
      Joi.string().min(1)
    )
    .label("Invoicing Endpoint")
    .required()
    .messages({ "string.pattern.base": "Invalid invoicing endpoint." }),

  vatNumber: Joi.string()
    .regex(/^[0-9]{11}$/)
    .label("VAT Number")
    .required()
    .messages({ "string.pattern.base": "Invalid VAT number." }),

  name: Joi.string().min(1).label("Name").required(),
  lastname: Joi.string().min(1).label("Last Name").required(),
  socialReason: Joi.string().min(1).label("Social Reason").required(),
});

export default validationSchema;
