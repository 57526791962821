import React from 'react';
import Trashicon from '../../../../../Assets/Icons/Trash_light.svg';
import './Draftewslettercards.css';
import Pencilicon from "../../../../../Assets/Icons/Pencil_light.svg";
import format from 'date-fns/format';
import icon1 from '../../../../../Assets/Icons/icn.png';
import icon2 from '../../../../../Assets/Icons/icn2.png';
import icon3 from '../../../../../Assets/Icons/newspaper.png';
import { it } from 'date-fns/locale';
const Draftewslettercards = ({ newsletter, onEdit, onRemove }) => {

  return (

    <div className="rounded-md elevation-1 bg-white flex flex-col w-[70%] h-[180px]">
      <div className='h-[20%] bg-[#FFA56326] w-full gap-x-2 rounded-t-md flex items-center justify-center' >
        <p className='font-semibold text-newsletterbtn'>Notorietà</p>
        <img src={icon1} alt="" className='w-6 h-6' />
      </div>

      <div className='flex flex-col w-full h-[40%] p-2 flex '>

        <p className=" text-newslettertextcolour text-[18px] font-semibold text-left "> Oggetto: <span className='font-normal text-[18px] text-black'>{newsletter.subject} </span>  </p>
        <p className="  text-newslettertextcolour font-semibold text-[18px]  text-left">  Liste Coinvolte: <span className='font-normal text-[18px] text-black'>  {newsletter?.mailingListIds?.join(" ,")}</span></p>

      </div>
      <div className=" w-full mx-auto h-[20%] rounded-md p-1 relative mt-2">

        {/* <img src={newsletter.imageString} alt="" className="w-full h-full rounded-md object-cover  "></img> */}
        <div className='flex flex-row gap-x-2 flex items-center justify-center' >

          <button onClick={() => onRemove(newsletter.id)} className="flex  w-[35px] h-[35px] items-center elevation-1 rounded-md text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:border-white active:shadow-innerbig"><img src={Trashicon} alt="" className="mx-auto" /></button>
          <button onClick={() => onEdit(newsletter)} className="flex items-center justify-center w-[60%] h-[35px] items-center elevation-1 rounded-md text-white bg-[#00af78] border-2 border-[#00af78] hover:bg-[#00875c] hover:border-[#00875c] active:border-white active:shadow-innerbig"><img src={Pencilicon} alt="" className="flex items-center justify-center mr-2" />Modifica</button>
        </div>

      </div>


    </div>
  )
}

export default Draftewslettercards;
