import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import { getAuthConfig } from "../../../../Utility/Api/auth";
import ThemeList from "./ThemeList";
import ThemeCard from "../ThemeCard/ThemeCard";
import "./Themes.css";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserData } from "../../../../Utility/getUserData";
import { baseUrl } from "../../../../Utility/Api/Urls";
import CircularProgress from "@mui/material/CircularProgress";
import OtherThemeModal from "../ThemeCard/OtherThemeModal"
import LogoChange from "./LogoChange";
import FaviconChange from "./FaviconChange";
import { RestaurantInfoContext } from "../../../../Contexts/RestaurantInfoContext";
import AnalyticsPlugin from "../../../../Reusable/Analytics-Plugin/AnalyticsPlugin";

import ThemeHeaderLottie from "../../../../Assets/Lottie/Theme.json";
import "../../../../Reusable/CSS/Page-Layout.css";
import WebSiteThemeNavigation from "../WebSiteThemeNavigation/WebSiteThemeNavigation";
import Suggestions from "./Suggestions";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner"
    )
  ).default;
}

function SitoPage() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [theme, setTheme] = React.useState({});
  const [TheUserData, SetUserData] = React.useState();
  const [isLoading, SetLoading] = React.useState(true);

  const moreDetailsTheme = (theme) => {
    handleOpen();
    setTheme({ ...theme });
  };

  const themeChoicedDirectly = (theme) => {
    SetUserData({ ...TheUserData, themeId: theme.id });
    PatchUserInfo(theme.id);
    handleClose()
  };

  const url = `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/user`;
  const userData = getUserData();
  const { token } = userData;

  async function GetUserInfo() {
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      SetUserData(res.data);
    } catch (error) {
      toast.error("Errore nel caricamento dei dati");
    }
    SetLoading(false);
  }

  async function PatchUserInfo(newThemeID) {
    const body = { data: { themeId: newThemeID } };
    try {
      const res = await axios.patch(url, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    GetUserInfo();
  }, []);

  const [isLoading2, SetLoading2] = React.useState(false);

  async function handleRedirectToTheWebSite() {
    SetLoading2(true);
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/pharmacies/url`,
        getAuthConfig()
      );
      window
        .open(`//www.${res.data.url}/customize-theme?token=${token}`, "_blank")
        .focus();
    } catch (error) {
      console.error(error);
    }
    SetLoading2(false);
  }

  const { RestaurantInfo, PatchNewInfo, SetResData } = React.useContext(
    RestaurantInfoContext
  );
  const [themesToShow, SetThemes] = React.useState(ThemeList);

  const selectedTheme = themesToShow.find(
    (theme) => theme.id === TheUserData?.themeId
  );
  const otherThemes = themesToShow.filter((theme) => theme.id !== TheUserData?.themeId);
  return (
    <>
      <AnalyticsPlugin

        section1={true}
        section2={true}
        section3={true}
        section4={false}
        section5={false}
        section6={false}
        SectionName="Analytics generali"
        AnalyticsName1="Impressioni totali"
        AnalyticsData1={56}
        Analyticspercentage1={31.5 + " %"}
        AnalyticsName2="Pagina più vista"
        AnalyticsData2={20}
        Analyticspercentage2={2.5 + " %"}
        AnalyticsName3="Punteggio medio"
        AnalyticsData3="12 min"
        Analyticspercentage3="-5 min"
        AnalyticsName4="Tempo medio per guardare il Menù"
        AnalyticsData4="12 min"
        Analyticspercentage4="30"
        AnalyticsName5="Tempo medio per guardare il Menù"
        AnalyticsData5="12 min"
        Analyticspercentage5="30"
        AnalyticsName6="Tempo medio per guardare il Menù"
        AnalyticsData6="12 min"
        Analyticspercentage6="30"
        text="rispetto al mese precedente"
        imgOk={false}

      />
      <InformationTopBanner
        pagetype="Sito"
        sectionname="Web"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "In questa sezione puoi caricare e modificare logo e icona del tuo sito web. Puoi anche scegliere il tema e modificarne immagini e testo sulla base dei suggerimenti"
        }
        sectionicon={ThemeHeaderLottie}
      />
      <OtherThemeModal
        open={open}
        handleClose={handleClose}
        isLoading2={isLoading2}
        otherThemes={otherThemes}
        TheUserData={TheUserData}
        themeChoicedDirectly={themeChoicedDirectly}
        handleRedirectToTheWebSite={handleRedirectToTheWebSite}
      />

      <div className="pagelayout w-full">
        <div className=" p-5 ">
          <WebSiteThemeNavigation />
        </div>
        <div className="flex pl-8 flex-col w-full mt-12 mb-7 md:pl-0">
          <p className="font-semibold text-[#114653] text-left text-xl bg-[#fc637126] mr-[30px] p-5 rounded-md md:ml-8">
            Logo e Icona Sito Web
          </p>
          <div className="flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-x-4 justify-center items-center md:items-center">
            <LogoChange
              RestaurantInfo={RestaurantInfo}
              PatchNewInfo={PatchNewInfo}
              SetResData={SetResData}
            />
          </div>
        </div>

        <div className="flex flex-col w-[95%] gap-y-7 mx-auto justify-between  mb-16 mt-6  ">
          <h1 className="font-semibold text-[#114653] text-left text-xl bg-[#fc637126] p-5 rounded-md">
            Seleziona il tema del tuo Sito Web
          </h1>
        </div>

        <div className=" flex flex-row w-[95%] mx-auto justify-between gap-4 xsm:flex-col md:flex-col xmd:flex-col sm:flex-col xmd:items-center md:items-center">
          {isLoading && (
            <div className="col-span-3 mt-20">
              <CircularProgress color="success" />
            </div>
          )}
          {!isLoading && selectedTheme && (
            <ThemeCard
              id={selectedTheme.id}
              title={selectedTheme.title}
              image={selectedTheme.image}
              description={selectedTheme.description}
              longDescription={selectedTheme.longDescription}
              isSelect={true}
              onSelect={moreDetailsTheme}
              theme={selectedTheme}
              handleThemeChosing={themeChoicedDirectly}
              SetUserData={SetUserData}
              handleRedirectToTheWebSite={handleRedirectToTheWebSite}
            />
          )}
          <Suggestions />
        </div>
      </div>
    </>
  );
}

export default SitoPage;
