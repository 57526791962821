import React from "react";
import Dialog from "@mui/material/Dialog";
import "./OrderDetailsPopUp.css";
import "./ModalOrderDetailsPopup.css";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
export default function AlertDialog({
  handleCloseOrderModal,
  ModalState,
  products
}) {





  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottom: 0,
      color: "#000",
      backgroundColor: "#00B27A26",
      height: "100%"
    },
    [`&.${tableCellClasses.body}`]: {},
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {},
    "&:nth-of-type(even)": {},
    // hide last border
    "td, th": {
      borderBottom: 0,
    },
  }));


  // const getOrderTotal = () =>
  //   Math.round(
  //     products?.reduce(
  //       (total, current) => total + current?.price,
  //       0
  //     ) * 100
  //   ) / 100;

  // console.log(products)













  return (
    <div id="morebutton">


      <Dialog open={ModalState} maxWidth={false} onClose={handleCloseOrderModal}>

        <div className="p-8 sm:p-2 xsm:p-2 md:p-2 detailsrow relative ">
          <button onClick={() => handleCloseOrderModal()} className="absolute top-0 right-0 m-3">
            <AiOutlineCloseCircle size={30} color="#00B27A" />
          </button>




          <h1 className="text-xl text-primarytextcolour font-bold text-center self-center mb-8 ">
            Dettagli dell'ordine
          </h1>

          <div className="OrderDetailsTable sm:hidden xsm:hidden md:hidden">

            <Table sx={{ minWidth: 710 }}>

              <TableHead className="OrderDetailsTable">

                <TableRow style={{ height: 50, alignItems: "center", justifyItems: "center", display: "flex" }} className="flex flex-row  items-center justify-center w-full h-full elevation-1 rounded-md">

                  <StyledTableCell style={{ fontSize: "18px", fontWeight: "bold", width: 460, textAlign: "left", color: "#114653" }}>Nome prodotto</StyledTableCell>
                  <StyledTableCell style={{ fontSize: "18px", fontWeight: "bold", width: 250, textAlign: "center", color: "#114653" }} >Quantità</StyledTableCell>
                  {/* <StyledTableCell style={{ fontSize: "18px", fontWeight: "bold", width: 200, textAlign: "center" }}>Totale</StyledTableCell> */}

                </TableRow>

              </TableHead>

              <TableBody>
                {products?.map(product => {

                  return (
                    <StyledTableRow key={product?._id} className=" mb-3 mt-1  rounded-md elevation-1 py-3 bg-white flex items-center " >
                      <StyledTableCell style={{ fontSize: "15px", fontWeight: "bold", width: 460 }} >
                        <div className="flex flex-col gap-y-1" >
                          <p className="text-primarytextcolour font-bold uppercase text-lg">{product?._id?.title}</p>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 250, color: "#114653"}} >
                        {product.quantity}x
                      </StyledTableCell>
                      {/* <StyledTableCell align="center" style={{ fontSize: "18px", fontWeight: "bold", width: 200 }} >
                        {product.price} €
                      </StyledTableCell> */}
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
          <div className="lg:hidden xl:hidden xmd:hidden">

            {products?.map(productData => {
              const product = productData?._id;
              const quantity = productData.quantity;
              return (
                <div className="w-[95%] mx-auto p-2 flex flex-col gap-y-2 rounded-md elevation-1 bg-white h-fit my-4" >
                  <div className="flex flex-row justify-between gap-y-1" >
                    <p className="text-primarytextcolour font-bold uppercase text-base">{product?.title}</p>
                    <p className="text-sm text-black font-normal">X {quantity} </p>
                  </div>
                  {/* <div className="flex flex-row justify-between items-center gap-y-1" >
                    <p className="text-black font-bold uppercase text-[10px]">Totale</p>
                    <p className="text-sm text-black font-normal">   {product.price * quantity} € </p>
                  </div> */}
                </div>
              )
            })}
          </div>


          {/* <div className="bg-[#ffd792] flex flex-row justify-between items-center w-full h-10 px-6 rounded-md elevation-1 mt-1 mb-3" >
            <p className="text-black font-bold tracking-wide ">Totale</p>
            <p className="text-black font-bold ">{getOrderTotal()} €</p>
          </div> */}
          {/* <div className="flex flex-col gap-y-1 pl-3 mb-10" >
            <p className="text-black font-normal text-sm">Orario di consegna:  <span className="ml-10 sm:ml-1 xsm:ml-1 md:ml-1 font-semibold "> {order?.deliveryTime}</span></p>
            <p className="text-black font-normal text-sm">Metodo di Pagamento:  <span className="ml-4 sm:ml-1 xsm:ml-1 md:ml-1 font-semibold capitalize "> {order?.paymentMethod}</span></p>
            {order?.forDelivery && <p className="text-black font-normal text-sm">Indirizzo: <span className="ml-28 sm:ml-1 xsm:ml-1 md:ml-1 font-semibold ">{order?.billingAddress}</span> </p>}
            {order?.forDelivery && <p className="text-black font-normal text-sm">Ordine Inviato: <span className="ml-20 sm:ml-1 xsm:ml-1 md:ml-1 font-semibold ">{order?.deliveryStatus === "incoming" ? "Si" : "No"}</span> </p>}
          </div> */}

          {/* <AddressBox order={order} /> */}


        </div>
      </Dialog>
    </div>
  );
}
