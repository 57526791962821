import React, { useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";

import '../../../../Reusable/CSS/Page-Layout.css';
import MarNavigation from "../MarNavigation/MarNavigation";
import MarketingHeaderLottie from "../../../../Assets/Lottie/Marketing.json";
import CompletedCampaignCard from "./CompletedCampaignCard";
import * as service from "../../../../apis/campaignService";
import { useState } from "react";
import Loader from "../../../../Reusable/Loader/Loader";
// import { MarketingCampaignContext } from "../../../../Contexts/MarketingCampaignContext";
// import AddIcon from "../../../../Assets/Icons/add-icon.svg";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner')).default;
}


function Ongoingcampaign() {
  // const { handleExternalBuyerPersonaSet } = useContext(
  //   MarketingCampaignContext
  // );

  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: {  max: 4000, min: 1400 },
  //     items: 2,
  //   },
  //   desktop: {
  //     breakpoint: { max: 1399, min: 1100 },
  //     items: 1,
  //   },
  //   desktopSmall: {
  //     breakpoint: { max: 1099, min: 835 },
  //     items: 1,
  //   },
  //   tablet: {
  //     breakpoint: { max: 834, min: 427 },
  //     items: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 426, min: 0 },
  //     items: 1,
  //   },
  // };  

  // const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState();

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await service.getEndedCampaigns();
      data && setCampaigns(data);
      setIsLoading(false);
    })();
  }, []);

  // const goToMarketingCreation = () => {
  //   handleExternalBuyerPersonaSet(null);
  //   navigate("/marketing/create-campaigns");
  // };

  return (


    <div className="flex flex-col w-full">

      <InformationTopBanner

        pagetype="Sezione"
        sectionname="Pubblicità"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'All’interno dei questa pagina della sezione Pubblicità puoi visualizzare tutte le tue campagne marketing completate e i dati relativi ad esse o creare una nuova campagna.'}
        sectionicon={MarketingHeaderLottie}

      />

      <div className=" p-5  ">

        <MarNavigation />

      </div>

      <div className="flex flex-col w-full justify-center content-center items-center mt-4">

        <div className="flex flex-col w-[95%]">

          {isLoading && <Loader />}

          {!isLoading && campaigns?.length === 0 && (
            <p>Non ci sono campagne completate.</p>
          )}

          <div className="GridMarketingCompletedCards px-12 py-8 gap-x-4 gap-y-12 w-[95%] mx-auto mb-4 xsm:px-0 sm:px-0 md:px-4">

            {campaigns?.map(campaign => (
              <div className="py-4 mx-2" key={campaign.id || campaign.campaign_id}>
                <CompletedCampaignCard campaign={campaign} />
              </div>
            ))}

          </div>
        </div>
      </div>

    </div>
  );
}

// const ButtonGroup = ({ next, previous, goToSlide, renderLoader, ...rest }) => {
//   const {
//     carouselState: { totalItems, slidesToShow, currentSlide },
//   } = rest;

//   return (
//     <div className="pagelayout">
//       <button
//         className="absolute right-0 top-[150px] ml-4 hover:bg-gray-100 rounded-full border elevation-1 p-2"
//         onClick={() => next()}
//         disabled={totalItems - slidesToShow - currentSlide <= 0}
//       >

//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="28"
//           height="28"
//           viewBox="0 0 24 24"
//           fill="none"
//           stroke="#173659"
//           strokeWidth="3"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         >
//           <path d="M9 18l6-6-6-6" />
//         </svg>

//       </button>

//       <button
//         className="absolute left-0 top-[150px] mr-4 hover:bg-gray-100 rounded-full border elevation-1 p-2 "
//         onClick={() => previous()}
//         disabled={currentSlide === 0}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="28"
//           height="28"
//           viewBox="0 0 24 24"
//           fill="none"
//           stroke="#173659"
//           strokeWidth="3"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         >
//           <path d="M15 18l-6-6 6-6" />
//         </svg>
//       </button>
//     </div>
//   );
// };

export default Ongoingcampaign;