import React from "react";
import ErrorHeaderIcon from "../../../Assets/Lottie/404-Cat-Error.json";
import feedthecat from "../../../Assets/Images/feed-the-cat.jpg";
import Backbutton from "../../../Reusable/Back-Button/Back-Button";
import ScrollToTop from '../../../Reusable/ScrollToTop';
import '../../../Reusable/CSS/Page-Layout.css';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../Reusable/Informational-Top-Banner/RC/1/Informational-Top-Banner')).default;
}


function Errornotfound() {

  return (

    <div className="pagelayout flex flex-col place-content-center place-items-center">

      <div className="flex flex-col w-full">

        <InformationTopBanner

        pagetype="Pagina"
        sectionname="404"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'Pagina non trovata'}
        sectionicon={ErrorHeaderIcon}

        />

      <ScrollToTop />

        <div className="ml-9 py-6">

          <Backbutton />

        </div>

        <div className="flex flex-col w-full px-12">

          <h3> Questa pagina è stata spostata <br/> per fare spazio ad esigenze maggiori </h3>

            <div className="flex w-full h-full mx-auto place-content-center place-items-center">

              <img src={feedthecat} alt="" className="w-max h-max"></img>

            </div>

        </div>

      </div>

    </div>

  );
}

export default Errornotfound;
