/**
 * Get random integer in range [min, max) i.e. minimum is included and maximum is not included
 * @param {Number} min Minimum
 * @param {Number} max Maximum
 * @returns Random integer in range [min, max)
 */
export function getRandomNumber(min, max) {
  if (!Number.isSafeInteger(min) || !Number.isSafeInteger(max)) {
    console.error(
      `Invalid parameter combination (min ${min} and max ${max}] passed to getRandomNumber in Utility/random.js`
    );
    return null;
  }

  return Math.floor(Math.random() * (max - min) + min);
}

/**
 * Get random element from an array
 * @param {Array} array Array
 * @returns Random element from the array
 */
export function getRandomElement(array) {
  if (!Array.isArray(array)) {
    console.error(
      `Invalid parameter array ${array} passed to getRandomElement in Utility/random.js`
    );
    return null;
  }

  return array[getRandomNumber(0, array.length)];
}
