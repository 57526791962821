import React from "react";
import "./UseTimetables.css";

import Trashicon from "../../../../../../Assets/Icons/Trash-Light-Red.svg";

const Tabellaorari = ({
  localWorkingHoursData,
  setLocalWorkingHoursData,
  opening,
  day,
  hanleIsChanged
}) => {



  const addWorkingSession = () => {
    hanleIsChanged()
    if (localWorkingHoursData.filter((e) => e.dayName === day).length > 0)
      setLocalWorkingHoursData(
        localWorkingHoursData.map((e) =>
          e.dayName === day
            ? {
              ...e,
              opening: [
                ...e.opening,
                {
                  openTime: { hours: 0, minutes: 0 },
                  closeTime: { hours: 0, minutes: 0 },
                },
              ],
            }
            : { ...e }
        )
      );
  };

  const handleChangeHoursTime = (event, idx) => {
    hanleIsChanged()
    setLocalWorkingHoursData(
      localWorkingHoursData.map((element) =>
        element.dayName === day
          ? {
            ...element,
            opening: element.opening.map((e, i) =>
              i === idx
                ? {
                  ...e,
                  [event.target.name]: {
                    ...e[event.target.name],
                    hours: event.target.value,
                  },
                }
                : e
            ),
          }
          : element
      )
    );
  };
  const handleChangeMinutes = (event, idx) => {
    hanleIsChanged()
    setLocalWorkingHoursData(
      localWorkingHoursData.map((element) =>
        element.dayName === day
          ? {
            ...element,
            opening: element.opening.map((e, i) =>
              i === idx
                ? {
                  ...e,
                  [event.target.name]: {
                    ...e[event.target.name],
                    minutes: event.target.value,
                  },
                }
                : e
            ),
          }
          : element
      )
    );
  };
  const handleDeleteSession = (idx) => {
    hanleIsChanged()
    setLocalWorkingHoursData(
      localWorkingHoursData.map((element) =>
        element.dayName === day
          ? {
            ...element,
            opening: element.opening.filter((e, i) => i !== idx),
          }
          : element
      )
    );
  };

  return (
    <div className="flex flex-col">
      {opening.length > 0 &&
        opening[0].map((session, idx) => (
          <div
            key={idx}
            className="flex flex-row w-full lg:flex-col xl:flex-col sm:flex-col xsm:flex-col xmd:flex-col  "
          >
            <div className="flex flex-row w-full xsm:flex-col sm:flex-col md:flex-col xmd:flex-col">
              <div className="flex flex-row w-full">
                <div className="flex flex-row w-full my-3 gap-x-4 justify-end items-center pr-4 xsm:pr-0 sm:pr-0 md:pr-0 xmd:pr-0 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col xsm:w-1/2 sm:w-1/2 md:w-1/2 xmd:w-1/2">
                  <div className="flex justify-center items-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <p className="flex text-base font-semibold text-left text-primarybtn xsm:justify-center sm:justify-center md:justify-center xmd:justify-center">
                      Da
                    </p>
                  </div>

                  <div className="flex justify-center items-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <div className="rounded-md elevation-1">
                      <input
                        className="bg-white w-12  h-10 rounded-l-md border-t border-b border-l elevation-1 pl-3"
                        placeholder="00"
                        name="openTime"
                        value={session.openTime.hours}
                        onChange={(event) => handleChangeHoursTime(event, idx)}
                      />
                    </div>

                    <div className="rounded-md elevation-1">
                      <input
                        className="bg-white w-12  h-10  rounded-r-md border elevation-1 pl-3"
                        placeholder="00"
                        name="openTime"
                        value={session.openTime.minutes ? session.openTime.minutes : ''}
                        onChange={(event) => handleChangeMinutes(event, idx)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full my-3 gap-x-4 justify-end items-center pr-4 xsm:pr-0 sm:pr-0 md:pr-0 xmd:pr-0 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col xsm:w-1/2 sm:w-1/2 md:w-1/2 xmd:w-1/2">
                  <div className="flex justify-center items-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <p className="flex text-base font-semibold text-left text-primarybtn xsm:justify-center sm:justify-center md:justify-center xmd:justify-center">
                      A
                    </p>
                  </div>

                  <div className="flex justify-center items-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <div className="rounded-md elevation-1">
                      <input
                        className="bg-white w-12  h-10 rounded-l-md border-t border-b border-l elevation-1 pl-3"
                        placeholder="00"
                        value={session.closeTime.hours}
                        name="closeTime"
                        onChange={(event) => handleChangeHoursTime(event, idx)}
                      />
                    </div>

                    <div className="rounded-md elevation-1">
                      <input
                        className="bg-white w-12 h-10 rounded-r-md border elevation-1 pl-3"
                        placeholder="00"
                        name="closeTime"
                        value={session.closeTime.minutes ? session.closeTime.minutes : ''}
                        onChange={(event) => handleChangeMinutes(event, idx)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center w-full lg:w-1/3 xl:w-1/3">
                <button
                  onClick={() => handleDeleteSession(idx)}
                  className="flex justify-center items-center w-[40px] h-[40px] bg-white elevation-1 active:shadow-innerbig rounded-md hover:ring-2 hover:ring-deletebtnhover active:ring-white"
                >
                  <img
                    src={Trashicon}
                    alt=""
                    className="mx-auto h-10 w-10 p-1"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}

      <button
        className="w-40 h-10 self-end elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig rounded-md"
        type="button"
        onClick={addWorkingSession}
      >
        Aggiungi
      </button>
    </div>
  );
};

export default Tabellaorari;
