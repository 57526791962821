import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../Utility/Api/Urls";
import { getUserData } from "../Utility/getUserData";
import { getRandomElement } from "../Utility/random";
import { getAuthConfig } from "../Utility/Api/auth";
import * as helpers from "../Utility/marketingHelpers";

const DEFAULT_ERROR = "C'è stato un errore. Riprova o ricarica la pagina.";

/**
 * Retrieves buyer personas
 * @returns Buyer persona array
 */
export const getBuyerPersonas = async () => {
  try {
    const userData = getUserData();
    const query = `?country=${userData?.nation || "Italy"}`;
    const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
      process.env.REACT_APP_DASHBOARD_API_PATH
    }/marketing/buyerPersona${query}`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    const mappedData = helpers.mapToBuyerPersonaArray(data);
    return mappedData;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
};

/**
 * Get Suggested buyer persona
 * @returns suggested buyer persona
 */
export const getSuggestedBuyerPersona = async () => {
  const data = await getBuyerPersonas();
  return data ? getRandomElement(data) : null;
};

/**
 * Retrieves other personas
 * @returns other personas array
 */
export const getOtherPersonas = async () => {
  try {
    const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
      process.env.REACT_APP_DASHBOARD_API_PATH
    }/buyer-personas?limit=100`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    const mappedData = helpers.getOtherPersonas(data);
    return mappedData;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
};

/*export const getBuyerPersonas = async () => {
  try {
    const userData = getUserData();
    const body = { user_id: userData._id };
    const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
      process.env.REACT_APP_DASHBOARD_API_PATH
    }/marketing/buyerPersonaDescription`;

    const { data } = await axios.post(url, body, getAuthConfig());
    console.log(data);

    if (!data) throw new Error("No data received.");

    const mappedData = helpers.mapToBuyerPersonaArray(data);
    return mappedData;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
};
*/
