// Set user data in local storage with received data
// Note: overwrites old data in local storage
export default function setUserData(newData) {
  try {
    localStorage.setItem("userData", JSON.stringify(newData));
    
    if (newData.token)
      localStorage.setItem("userToken", JSON.stringify(newData.token));
    
    return newData;
  } catch (error) {
    console.error(error);  
  }

  return null;
}