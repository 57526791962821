import { Fade } from "@mui/material";
import React from "react";
import { useState } from "react";

import RightArrowBuyer from "../../../../Assets/Icons/Round-Arrow-Right.svg";
import RightArrowWhite from "../../../../Assets/Icons/Round-Arrow-Right-White.svg";
import CloseIcon from "../../../../Assets/Icons/x-pink.svg";
import CloseIconHovered from "../../../../Assets/Icons/CloseIconHovered.svg";

const INTERESTS_COUNT = 3;
const FADE_TIME_MS = 400;

function TypePersonaCard({ data, onGoToCampaignClick }) {
  const {
    ageInfo,
    gender,
    location,
    googleInterests,
    facebookInterests,
    usesMobile,
    usesTablet,
    usesDesktop,
    image,
  } = data.displayData;

  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleGoToCampaignClick = () => onGoToCampaignClick(data);
  const [ImageHoverd, SetHovered] = useState(false)

  return (
    <>

      {/* DESKTOP VERSION */}

      <div
        className="w-[30em] h-[30em] rounded-md xsm:hidden sm:hidden md:hidden"
      >
        {/* STARING PAGE */}

        {!openInfo && (

          <Fade in={!openInfo} appear timeout={FADE_TIME_MS}>

            <div className="w-full h-full flex flex-col">

              <div className="w-full h-full overflow-hidden">
              
                <img src={image} alt="" className="w-full h-full object-cover object-top" />

              </div>

              <div className="w-full flex flex-row justify-between py-10 px-8 md:flex-col sm:flex-col xsm:flex-col">

                <div className="w-auto">

                  {ageInfo && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Età: <span className="font-normal"> {ageInfo}</span>
                    </p>
                  )}
                  {gender && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Genere: <span className="font-normal"> {gender} </span>
                    </p>
                  )}
                  {location && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Luogo: <span className="font-normal"> {location} </span>
                    </p>
                  )}

                </div>

                  <button onMouseEnter={() => SetHovered(true)} onMouseLeave={() => SetHovered(false)} onClick={() => { handleOpenInfo(); SetHovered(false);}} className="w-max flex flex-row justify-center items-center gap-x-4 px-4 bg-white text-black hover:bg-buyerpersonabtnhover hover:text-white active:shadow-innerbig elevation-1 elevation-1 rounded-md xsm:w-full sm:w-full md:w-full">

                    <div className="flex">

                      Scopri i dettagli

                    </div>

                    <div className="flex">

                      <img
                        src={ImageHoverd ? RightArrowWhite : RightArrowBuyer}
                        alt="Arrow pointing to the right."
                        className="m-auto h-10 w-10"
                      />

                    </div>

                  </button>

              </div>

            </div>

          </Fade>

        )}

        {/* SECOND PAGE */}
        {openInfo && (
          <Fade in={openInfo} appear timeout={FADE_TIME_MS}>

            <div className="w-full h-full">

              <div className="flex flex-col w-full h-full justify-between items-start">

                {/* Arrow */}

                <div onClick={() => { handleCloseInfo(); SetHovered(false);}} onMouseEnter={() => SetHovered(true)} onMouseLeave={() => SetHovered(false)} className="w-full px-4 cursor-pointer">

                  <div className="flex justify-end">

                    <img
                      src={ImageHoverd ? CloseIconHovered : CloseIcon}
                      alt="Close Button."
                      className="h-10 w-10"
                    />

                  </div>

                </div>

                <div className="flex flex-col w-full h-full gap-y-2 place-self-start">

                  {/* GOOGLE INTERESTS */}
                  {googleInterests?.length > 0 && (
                    <div className="w-full text-xl text-left border-b-2 border-b-[#8E8E8E]">
                      <p className="font-semibold text-[#2E2E2E] mb-2">
                        Interessi Google
                      </p>
                      {googleInterests?.slice(0, INTERESTS_COUNT).map(gi => (
                        <p
                          key={gi}
                          className="font-normal text-lg text-left text-[#2E2E2E]"
                        >
                          {gi}
                        </p>
                      ))}
                    </div>
                  )}

                  {/* FACEBOOK INTERESTS */}
                  {facebookInterests?.length > 0 && (
                    <div className="w-[90%] ml-4 text-xl text-left border-b-2 border-b-[#8E8E8E]">
                      <p className="font-semibold text-[#2E2E2E] mb-2">
                        Interessi Facebook
                      </p>
                      {facebookInterests?.slice(0, INTERESTS_COUNT).map(fi => (
                        <p
                          key={fi}
                          className="font-normal text-lg text-left text-[#2E2E2E]"
                        >
                          {fi}
                        </p>
                      ))}
                    </div>
                  )}

                  {/* DEVICES */}
                  {(usesDesktop || usesMobile || usesTablet) && (
                    <div className="text-xl text-left px-4">
                      <p className="font-semibold text-[#2E2E2E]">Dispositivi</p>
                      {usesDesktop && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Desktop
                        </p>
                      )}
                      {usesMobile && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Mobile
                        </p>
                      )}
                      {usesTablet && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Tablet
                        </p>
                      )}
                    </div>
                  )}

                </div>

                <div className="flex w-full items-end justify-end place-content-end self-end">

                  <button onClick={handleGoToCampaignClick} className="w-full h-12 px-4 flex flex-row justify-center items-center bg-white text-black hover:bg-buyerpersonabtnhover hover:text-white active:shadow-innerbig rounded-b-xl border-t-2 border-buyerpersonabtn active:border-white">

                    Avvia Campagna Marketing con questo Cliente Tipo

                  </button>
                  
                </div>

              </div>

            </div>

          </Fade>

        )}

      </div>

      {/* MOBILE VERSION */}

      <div
        className="w-full h-full rounded-md xmd:hidden lg:hidden xl:hidden"
      >
        {/* STARING PAGE */}

        {!openInfo && (

          <Fade in={!openInfo} appear timeout={FADE_TIME_MS}>

            <div className="w-full h-full flex flex-col">

              <div className="w-full h-full overflow-hidden">
                <img src={image} alt="" className="w-full h-full object-cover object-top" />
              </div>

              <div className="w-full flex flex-row justify-between pt-6 pb-4 md:flex-col sm:flex-col xsm:flex-col">

                <div className="w-auto px-8 pb-4">

                  {ageInfo && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Età: <span className="font-normal"> {ageInfo}</span>
                    </p>
                  )}
                  {gender && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Genere: <span className="font-normal"> {gender} </span>
                    </p>
                  )}
                  {location && (
                    <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                      Luogo: <span className="font-normal"> {location} </span>
                    </p>
                  )}

                </div>

                  <button onMouseEnter={() => SetHovered(true)} onMouseLeave={() => SetHovered(false)} onClick={() => { handleOpenInfo(); SetHovered(false);}} className="w-max h-12 flex flex-row justify-center items-center gap-x-4 px-4 text-sm bg-white text-black border-t-2 border-buyerpersonabtn hover:bg-buyerpersonabtnhover hover:text-white active:shadow-innerbig elevation-1 elevation-1 rounded-b-xl xsm:w-full sm:w-full md:w-full">

                    <div className="flex">

                      Scopri i dettagli

                    </div>

                    <div className="flex">

                      <img
                        src={ImageHoverd ? RightArrowWhite : RightArrowBuyer}
                        alt="Arrow pointing to the right."
                        className="m-auto h-6 w-6"
                      />

                    </div>

                  </button>

              </div>

            </div>

          </Fade>

        )}

        {/* SECOND PAGE */}
        {openInfo && (
          <Fade in={openInfo} appear timeout={FADE_TIME_MS}>

            <div className="w-full h-full">

              <div className="flex flex-col w-full h-full justify-between items-start">

                {/* Arrow */}

                <div onClick={() => { handleCloseInfo(); SetHovered(false);}} onMouseEnter={() => SetHovered(true)} onMouseLeave={() => SetHovered(false)} className="w-full px-4 cursor-pointer">

                  <div className="flex justify-end">

                    <img
                      src={ImageHoverd ? CloseIconHovered : CloseIcon}
                      alt="Close Button."
                      className="h-6 w-6"
                    />

                  </div>

                </div>

                <div className="flex flex-col w-full h-full gap-y-2 place-self-start">

                  {/* GOOGLE INTERESTS */}
                  {googleInterests?.length > 0 && (
                    <div className="w-full text-xl text-left border-b-2 border-b-[#8E8E8E]">
                      <p className="font-semibold text-[#2E2E2E] mb-2">
                        Interessi Google
                      </p>
                      {googleInterests?.slice(0, INTERESTS_COUNT).map(gi => (
                        <p
                          key={gi}
                          className="font-normal text-lg text-left text-[#2E2E2E]"
                        >
                          {gi}
                        </p>
                      ))}
                    </div>
                  )}

                  {/* FACEBOOK INTERESTS */}
                  {facebookInterests?.length > 0 && (
                    <div className="w-[90%] ml-4 text-xl text-left border-b-2 border-b-[#8E8E8E]">
                      <p className="font-semibold text-[#2E2E2E] mb-2">
                        Interessi Facebook
                      </p>
                      {facebookInterests?.slice(0, INTERESTS_COUNT).map(fi => (
                        <p
                          key={fi}
                          className="font-normal text-lg text-left text-[#2E2E2E]"
                        >
                          {fi}
                        </p>
                      ))}
                    </div>
                  )}

                  {/* DEVICES */}
                  {(usesDesktop || usesMobile || usesTablet) && (
                    <div className="text-xl text-left px-4">
                      <p className="font-semibold text-[#2E2E2E]">Dispositivi</p>
                      {usesDesktop && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Desktop
                        </p>
                      )}
                      {usesMobile && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Mobile
                        </p>
                      )}
                      {usesTablet && (
                        <p className="font-normal text-lg text-left text-[#2E2E2E]">
                          Tablet
                        </p>
                      )}
                    </div>
                  )}

                </div>

                <div className="flex w-full items-end justify-end place-content-end self-end">

                  <button onClick={handleGoToCampaignClick} className="w-full h-12 px-4 flex flex-row justify-center items-center bg-white text-black text-sm hover:bg-buyerpersonabtnhover hover:text-white active:shadow-innerbig rounded-b-xl border-t-2 border-buyerpersonabtn active:border-white">

                    Avvia Campagna Marketing con questo Cliente Tipo

                  </button>
                  
                </div>

              </div>

            </div>

          </Fade>

        )}

      </div>

    </>

  );
}

export default TypePersonaCard;
