import React, { useEffect, useState } from "react";
import { mapToLocalWorkingHours } from "./Utility";
import { getUserData } from "../../../../../Utility/getUserData";
import { useNavigate } from 'react-router-dom';
import * as LocationService from "./Utility"
import "./FormUserInfo.css";
import Subcategory from "./subcategory.js";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import UseGoogleAutoComplete from "./UseGoogleAutoComplete/UseGoogleAutoComplete";
import PrimaryCategory from "./PrimaryCategory";
import EditHoursModal from "./EditHoursModal";

function RestaurantInfo() {
    const navigate = useNavigate();
    const userData = getUserData();


    //in case of errors set to true
    const [errors, setErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    //temporary storage for patch data
    const [patchData, setPatchData] = useState();
    const [patchData1, setPatchData1] = useState();
    const [localWorkingHoursData, setLocalWorkingHoursData] = useState();
    const [isChanged, SetChanged] = React.useState(false)

    async function getLocationData() {
        try {
            const res = await LocationService.httpGetLocationDetails()
            setLocalWorkingHoursData(mapToLocalWorkingHours(res.data?.locationData.regularHours.periods));
            setPatchData(LocationService.handleResponseFormat(res))
            setPatchData1(res.data.locationData)
        } catch (error) {
            console.error("error", error);
            setErrors(true);
            toast.error("Errore nel salvataggio dei dati. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto")
        }
        setIsLoading(false);
    }


    useEffect(() => {
        if (!userData?.socialAccounts?.gmbLocationResourceIdentifier) {
            return navigate("/dashboard/account/general-settings");
        }

        getLocationData()
    }, []);

    //patch request
    async function handleSubmit(e) {
        SetChanged(false)
        e.preventDefault()
        try {
            await LocationService.handleRequestChange(localWorkingHoursData, patchData, patchData1)
            await LocationService.httpChangeOnFB(localWorkingHoursData)
            toast.success("Modifiche inviate in revisione.");
        } catch (error) {
            console.error(error);
            toast.error("Hai già una modifica in revisione, contatta l'assistenza per maggiori informazioni.");
        }
    };
    const hanleIsChanged = () => !isChanged && SetChanged(true)
    const [open, SetOpen] = React.useState(false)
    const handleOpen = () => SetOpen(true)
    const handleClose = () => SetOpen(false)
    const preventDefault = (e) => e.preventDefault();
    return (
        <div>
            {open &&
                <EditHoursModal
                    open={open}
                    handleClose={handleClose}
                    setLocalWorkingHoursData={setLocalWorkingHoursData}
                    localWorkingHoursData={localWorkingHoursData}
                    hanleIsChanged={hanleIsChanged}
                />}
            {!errors && !isLoading &&

                <form onSubmit={preventDefault} id="informazioni-user-form">
                    <div className="w-full  elevation-1 px-2 rounded-md py-4 " >


                        <p className="font-semibold text-left text-xl text-[#00B27A] col-span-4">
                            Informazioni Ristorante
                        </p>
                        <div className="w-full grid grid-cols-3 gap-5 py-2 xsm:p-4 sm:p-4 md:p-3 my-3 xsm:flex xsm:flex-col sm:flex sm:flex-col md:grid  md:grid-cols-2 xmd:grid  xmd:grid-cols-2">
                            <div className="w-full flex flex-col " >
                                <div className="flex flex-row items-center">
                                    <label className="font-semibold text-left">
                                        Nome Attività
                                    </label>
                                    <Tooltip title="Una qualsiasi evenutale modifica a questo parametro potrebbe avere ripercusioni sulla SEO." placement="bottom-start" arrow>
                                        <IconButton>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <input
                                    className="bg-[#F5F5F5] px-4 py-3 rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                                    type="text"
                                    placeholder="Nome Attività"
                                    name="nameUser"
                                    onChange={(e) => {
                                        setPatchData1({ ...patchData1, name: e.target.value })
                                        hanleIsChanged()
                                    }
                                    }
                                    value={patchData1.name}
                                />
                            </div>


                            <div className="w-full flex flex-col  ">
                                <div className="flex flex-row items-center ">
                                    <label className="font-semibold text-left">
                                        Categoria Primaria
                                    </label>
                                    <Tooltip title="Una qualsiasi evenutale modifica a questo parametro potrebbe avere ripercusioni sulla SEO." placement="bottom-start" arrow>
                                        <IconButton>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <PrimaryCategory
                                    patchData={patchData1}
                                    setPatchData={setPatchData1}
                                    hanleIsChanged={hanleIsChanged}
                                />
                            </div>

                            <div className="w-full flex flex-col  ">
                                <div className="flex flex-row items-center ">
                                    <label className="font-semibold text-left ">
                                        Categoria Secondaria
                                    </label>
                                    <div>
                                        <Tooltip title="Una qualsiasi evenutale modifica a questo parametro potrebbe avere ripercusioni sulla SEO." placement="bottom-start" arrow>
                                            <IconButton>
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Subcategory patchData={patchData} setPatchData={setPatchData} hanleIsChanged={hanleIsChanged} />
                            </div>


                            <UseGoogleAutoComplete
                                hanleIsChanged={hanleIsChanged}
                                patchData={patchData}
                                setPatchData={setPatchData}
                            />




                            <div className="w-full flex flex-col gap-y-2  ">
                                <label className="font-semibold text-left">
                                    Numero di Telefono
                                </label>
                                <input
                                    className="bg-[#F5F5F5] px-4 py-3 rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                                    type="text"
                                    placeholder="Il tuo numero di telefono"
                                    name="TelephoneNumber"
                                    onChange={(e) => {
                                        setPatchData({
                                            ...patchData,
                                            phoneNumber: e.target.value,
                                        });
                                        hanleIsChanged()
                                    }}
                                    value={patchData.phoneNumber}
                                />
                            </div>


                            <div className="w-full flex flex-col gap-y-2  ">
                                <label className="font-semibold text-left">
                                    Numero di Cellulare (WhatsApp)
                                </label>
                                <input
                                    className="bg-[#F5F5F5] px-4 py-3 rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                                    type="text"
                                    placeholder="Il tuo numero di cellulare"
                                    name="MobileNumber"
                                    onChange={(e) => {
                                        hanleIsChanged()
                                    }}
                                />
                            </div>




                            <div className="w-full flex flex-col  col-span-2 gap-y-2  " >
                                <label className="font-semibold text-left">
                                    Descrizione
                                </label>
                                <input
                                    className="bg-[#F5F5F5] px-4 py-3 resize-none w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                                    type="text"
                                    placeholder="Descrivi la tua Attività"
                                    name="Description"
                                    onChange={(e) => {
                                        setPatchData({
                                            ...patchData,
                                            description: e.target.value,
                                        });
                                        hanleIsChanged()
                                    }}
                                    value={patchData.description}
                                />

                            </div>
                        </div>



                        <div >
                            <p className="font-semibold text-left text-xl text-[#00B27A] col-span-4">
                                Gestisci Orari
                            </p>


                            <div className="grid grid-cols-3 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xmd:grid-cols-2  gap-5 py-3 xsm:p-4 sm:p-4 md:p-3 ">

                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Lunedì</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[0]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Martedì</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[1]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Mercoledì</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[2]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Giovedì</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[3]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Venerdì</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[4]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Sabato</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[5]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>
                                <div className="w-full flex bg-[#F5F5F5] flex-row rounded-md items-center px-2 justify-between h-12 ">
                                    <p className=" text-black font-semibold">Domenica</p>
                                    <div className="flex flex-row divide-x-2 divide-black ">
                                        {localWorkingHoursData[6]?.opening?.map((item) => (
                                            <p className="px-2 text-black font-normal">{item?.openTime.hours.toString().length === 1 ? `0${item?.openTime.hours}` : item?.openTime.hours}:{item?.openTime.minutes || "00"} - {item?.closeTime.hours}:{item?.closeTime.minutes || "00"}</p>

                                        ))}
                                    </div>
                                </div>

                            </div>


                            <button onClick={() => handleOpen()} className="w-[300px] py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig ">
                                Modifica Orari
                            </button>
                        </div>
                    </div>


                    {isChanged &&
                        <div className="mt-4 flex justify-center">
                            <button
                                onClick={handleSubmit}
                                className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white"
                            >
                                Salva
                            </button>
                        </div>}
                </form>
            }
            {isLoading &&
                <div className="mt-32">
                    <CircularProgress />
                </div>

            }


        </div>
    )
}

export default RestaurantInfo
