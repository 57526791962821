import BrandingIcon from '../../../../Assets/Icons/Marketing-Goals/branding.svg';
import InteractionsIcon from '../../../../Assets/Icons/Marketing-Goals/interactions.svg';
import ConversionIcon from '../../../../Assets/Icons/Marketing-Goals/conversion.svg';
import VideoViewsIcon from '../../../../Assets/Icons/Marketing-Goals/video-views.svg';
import LeadGenerationIcon from '../../../../Assets/Icons/Marketing-Goals/lead-generation.svg';
import AppDownloadIcon from '../../../../Assets/Icons/Marketing-Goals/app-download.svg';
import DiscountsIcon from '../../../../Assets/Icons/Marketing-Goals/discounts.svg';
import SaleOfProductsIcon from '../../../../Assets/Icons/Marketing-Goals/sale-of-products.svg';
import OnlineTrafficIcon from '../../../../Assets/Icons/Marketing-Goals/online-traffic.svg';
import OfflineTrafficIcon from '../../../../Assets/Icons/Marketing-Goals/offline-traffic.svg';
import EventsIcon from '../../../../Assets/Icons/Marketing-Goals/events.svg';


export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { totalItems, slidesToShow, currentSlide } } = rest;

    return (
        <>
            <button
                className="absolute -right-5 top-[50%]  hover:bg-gray-100 rounded-full border elevation-1 p-2"
                onClick={() => next()}
                disabled={totalItems - slidesToShow - currentSlide <= 0}
            >

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 18l6-6-6-6" />
                </svg>

            </button>

            <button
                className="absolute -left-6 top-[50%]  hover:bg-gray-100 rounded-full border elevation-1 p-2 "
                onClick={() => previous()}
                disabled={currentSlide === 0}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 18l-6-6 6-6" />
                </svg>
            </button>
        </>
    );
};


export const CampaignTypes = [
    {
        name: 'Branding',
        value: 'branding',
        color: "#2ACB91",
        icon: BrandingIcon,
        description: "Aumenta la notorietà del tuo ristorante, attirando clienti che cosi ti ricorderanno",
    },
    {
        name: 'Interactions',
        value: 'interactions',
        color: "#CB2A77",
        icon: InteractionsIcon,
        description: "Raggiungi le persone che interagiranno con il tuo post con Mi piace, commenti, messaggi",
    },
    {
        name: 'Conversion',
        value: 'conversion',
        color: "#FF7325",
        icon: ConversionIcon,
        description: "i tuoi clienti potrannno effetuare download, acquisti, iscrizione o registrazione sull’app",
    },
    {
        name: 'Video Views',
        value: 'video-views',
        color: "#FF5C66",
        icon: VideoViewsIcon,
        description: "Condividi video del tuo ristorante o delle preparazioni con i clienti su Facebook",
    },
    {
        name: 'Lead Generation',
        value: 'lead-generation',
        color: "#2ACB91",
        icon: LeadGenerationIcon,
        description: "Crea annunci che raccolgano informazioni dai clienti interessati al tuo prodotto",
    },
    {
        name: 'App Download',
        value: 'app-download',
        color: "#46D3FF",
        icon: AppDownloadIcon,
        description: "Raggiungi i clienti che interagiranno con la tua app per notizie ed informazioni",
    },
    {
        name: 'Discounts',
        value: 'discounts',
        color: "#FFBC46",
        icon: DiscountsIcon,
        description: "Mostra i tuoi annunci ai clienti che acquisteranno sul tuo sito web con codici sconto",
    },
    {
        name: 'Sale of Products',
        value: 'sale-of-products',
        color: "#FF7325",
        icon: SaleOfProductsIcon,
        description: "Mostra i prodotti dal menu del tuo ristorante per generare vendite",
    },
    {
        name: 'Online Traffic',
        value: 'online-traffic',
        color: "#46D3FF",
        icon: OnlineTrafficIcon,
        description: "Indirizza più clienti sul tuo sito Web o su altre piattaforme per avere più visibilità",
    },
    {
        name: 'Offline Traffic',
        value: 'offline-traffic',
        color: "#2B65BB",
        icon: OfflineTrafficIcon,
        description: "Promuovi la posizione del tuo ristorante ai clienti che si trovano nelle vicinanze",
    },
    {
        name: 'Events',
        value: 'events',
        color: "#FF5C66",
        icon: EventsIcon,
        description: "Mostra i tuoi annunci ai clienti che hanno maggiori probabilità di partecipare al tuo evento",
    },
];

export const buyerPersonas = [
    {
        value: 'creative',
        label: "Creative"
    },
    {
        value: 'business-woman',
        label: "Business Woman"
    },
    {
        value: 'family',
        label: "Family"
    },
    {
        value: 'student',
        label: "Student"
    },
    {
        value: 'cat-lover',
        label: "Cat Lover"
    },
    {
        value: 'custom-audience',
        label: "Custom Audience"
    },
];

export const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1366,
        },
        items: 3,
        partialVisibilityGutter: 10,
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0,
        },
        items: 1,
        partialVisibilityGutter: 30,
    },
    tablet: {
        breakpoint: {
            max: 1366,
            min: 464,
        },
        items: 2,
        partialVisibilityGutter: 30,
    },
};