import { useLocation } from 'react-router';

import { useContext } from 'react';
import { MarketingCampaignContext } from '../Contexts/MarketingCampaignContext';
import { SocialMediaContext } from '../Components/Commons/SocialMedia/Context/SocialMediaContext';

const useSetImage = () => {
    const location = useLocation();
    let context;
    if (location.pathname.includes('marketing')) {
        context = MarketingCampaignContext;
    } else if (location.pathname.includes('social-media')) {
        context = SocialMediaContext;
    }
    const { setImage } = useContext(context);
    return setImage;
};

export default useSetImage;
