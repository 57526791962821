import React from "react";
import { useLocation } from 'react-router-dom';

const GoToButtonTriple = ( { onClick1, onClick2, onClick3, textname1, textname2, textname3, icon1, icon2, icon3 } ) => {

  const location = useLocation()

  return (      
                
    <div className="flex flex-row w-[80%] p-2 justify-center rounded-md elevation-1 gap-2 bg-gray-100 xsm:flex-col sm:flex-col">

      <div className='flex w-full'>

        <button onClick={onClick1} 
          
          className={`
            
            ${location.pathname.includes("dashboard") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("gamification") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("pick-up") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("agenda") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("salesman") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("user-information") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("products") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("delivery") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("reservation") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("seo") && "text-white bg-seobtn border-2 border-seobtn hover:bg-seobtnhover hover:border-seobtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("social-media") && "text-white bg-socialmediabtn border-2 border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("buyer-persona") && "text-white bg-buyerpersonabtn border-2 border-buyerpersonabtn hover:bg-buyerpersonabtnhover hover:border-buyerpersonabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("marketing") && "text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("discount") && "text-white bg-discountbtn border-2 border-discountbtn hover:bg-discountbtnhover hover:border-discountbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("chatbot") && "text-white bg-chatbotbtn border-2 border-chatbotbtn hover:bg-chatbotbtnhover hover:border-chatbotbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("newsletter") && "text-white bg-newsletterbtn border-2 border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("customers") && "text-white bg-customersbtn border-2 border-customersbtn hover:bg-customersbtnhover hover:border-customersbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("fidelity") && "text-white bg-fidelitybtn border-2 border-fidelitybtn hover:bg-fidelitybtnhover hover:border-fidelitybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("theme-settings") && "text-white bg-themesettingsbtn border-2 border-themesettingsbtn hover:bg-themesettingsbtnhover hover:border-themesettingsbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("support") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("singleticket") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("*") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}

            flex w-full h-12 justify-center items-center elevation-1 rounded-md gap-x-4 font-semibold`

          }
        
        >

          <div className="flex">
              
            {textname1}

          </div>

          <div className="flex">
              
            <img src={icon1} alt=""/>

          </div>
              
        </button>

      </div>

      <div className='flex w-full'>

        <button onClick={onClick2} 
          
          className={`
            
            ${location.pathname.includes("dashboard") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("gamification") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("pick-up") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("agenda") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("salesman") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("user-information") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("products") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("delivery") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("reservation") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("seo") && "text-white bg-seobtn border-2 border-seobtn hover:bg-seobtnhover hover:border-seobtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("social-media") && "text-white bg-socialmediabtn border-2 border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("buyer-persona") && "text-white bg-buyerpersonabtn border-2 border-buyerpersonabtn hover:bg-buyerpersonabtnhover hover:border-buyerpersonabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("marketing") && "text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("discount") && "text-white bg-discountbtn border-2 border-discountbtn hover:bg-discountbtnhover hover:border-discountbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("chatbot") && "text-white bg-chatbotbtn border-2 border-chatbotbtn hover:bg-chatbotbtnhover hover:border-chatbotbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("newsletter") && "text-white bg-newsletterbtn border-2 border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("customers") && "text-white bg-customersbtn border-2 border-customersbtn hover:bg-customersbtnhover hover:border-customersbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("fidelity") && "text-white bg-fidelitybtn border-2 border-fidelitybtn hover:bg-fidelitybtnhover hover:border-fidelitybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("theme-settings") && "text-white bg-themesettingsbtn border-2 border-themesettingsbtn hover:bg-themesettingsbtnhover hover:border-themesettingsbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("support") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("singleticket") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("*") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}

            flex w-full h-12 justify-center items-center elevation-1 rounded-md gap-x-4 font-semibold`

          }
        
        >

          <div className="flex">
              
            {textname2}

          </div>

          <div className="flex">
              
            <img src={icon2} alt=""/>

          </div>
              
        </button>

      </div>

      <div className='flex w-full'>

        <button onClick={onClick3} 
          
          className={`
            
            ${location.pathname.includes("dashboard") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("gamification") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("pick-up") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("agenda") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("salesman") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("user-information") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("products") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("delivery") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("reservation") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("seo") && "text-white bg-seobtn border-2 border-seobtn hover:bg-seobtnhover hover:border-seobtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("social-media") && "text-white bg-socialmediabtn border-2 border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("buyer-persona") && "text-white bg-buyerpersonabtn border-2 border-buyerpersonabtn hover:bg-buyerpersonabtnhover hover:border-buyerpersonabtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("marketing") && "text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("discount") && "text-white bg-discountbtn border-2 border-discountbtn hover:bg-discountbtnhover hover:border-discountbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("chatbot") && "text-white bg-chatbotbtn border-2 border-chatbotbtn hover:bg-chatbotbtnhover hover:border-chatbotbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("newsletter") && "text-white bg-newsletterbtn border-2 border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("customers") && "text-white bg-customersbtn border-2 border-customersbtn hover:bg-customersbtnhover hover:border-customersbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("fidelity") && "text-white bg-fidelitybtn border-2 border-fidelitybtn hover:bg-fidelitybtnhover hover:border-fidelitybtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("theme-settings") && "text-white bg-themesettingsbtn border-2 border-themesettingsbtn hover:bg-themesettingsbtnhover hover:border-themesettingsbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("support") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("singleticket") && "text-white bg-supportbtn border-2 border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:border-gray-100 active:shadow-innerbig"}
            ${location.pathname.includes("*") && "text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-gray-100 active:shadow-innerbig"}

            flex w-full h-12 justify-center items-center elevation-1 rounded-md gap-x-4 font-semibold`

          }
        
        >

          <div className="flex">
              
            {textname3}

          </div>

          <div className="flex">
              
            <img src={icon3} alt=""/>

          </div>
              
        </button>

      </div>

    </div>

  )
}

export default GoToButtonTriple