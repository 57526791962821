import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import TimetablesCard from './UseTimetables/TimetablesCard';
function EditHoursModal({ open, handleClose, localWorkingHoursData, setLocalWorkingHoursData ,hanleIsChanged }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth > 1000 ? 700 : "95%",
        height: "90%",
        overflow: "overlay",
        bgcolor: 'background.paper',
        borderTopWidth: 18,
        borderTopColor: "#00B27A",
        boxShadow: 24,
        p: 4,
        borderRadius: 5
    };
    const days = [
        { en: "MONDAY", it: 'Lunedì' },
        { en: "TUESDAY", it: 'Martedì' },
        { en: "WEDNESDAY", it: 'Mercoledì' },
        { en: "THURSDAY", it: 'Giovedì' },
        { en: "FRIDAY", it: 'Venerdì' },
        { en: "SATURDAY", it: 'Sabato' },
        { en: "SUNDAY", it: 'Domenica' },
    ];
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Fade in={open}>
                <Box sx={style}>
                    <div className="w-full">

                        <p className="fon-sans text-xl text-black mb-3 font-semibold ">
                            Orari di Apertura
                        </p>


                        <div className="flex flex-col gap-y-3  w-full mx-auto">
                            {days.map((dayOfTheWeek, idx) => {
                                return <TimetablesCard
                                    key={idx}
                                    hanleIsChanged={hanleIsChanged}
                                    day={dayOfTheWeek.en}
                                    label={dayOfTheWeek.it}
                                    currentIndex={idx}
                                    localWorkingHoursData={localWorkingHoursData}
                                    setLocalWorkingHoursData={setLocalWorkingHoursData}
                                    isWorkDay={
                                        localWorkingHoursData[idx]
                                            ? localWorkingHoursData[idx].isWorkDay
                                            : false
                                    }
                                    opening={localWorkingHoursData
                                        .filter((element) => element.dayName === dayOfTheWeek.en)
                                        .map((e) => e.opening)}
                                />

                            })}
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default EditHoursModal