import React, { useContext } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import { BsTrash, BsInstagram } from "react-icons/bs";
import { SiFacebook } from "react-icons/si";
import format from "date-fns/format";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import editSquare from "../../../../Assets/Svgs/editSquare.svg";
import viewEye from "../../../../Assets/Svgs/viewEye.svg";
import trashBin from "../../../../Assets/Svgs/trashBin.svg";
import dotsButton from "../../../../Assets/Svgs/dotsButton.svg";
import ModifyPopUp from "./ModifyPopUp";
import { UserContext } from "../../../../Contexts/UserContext";
import ViewPopUp from "./ViewPopUp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SocialPostCARD({ item, DeleteSchedule }) {
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);

  const handleClickOpenView = () => {
    handleCloseMenu();
    setOpenView(true);
  };

  const handleClickOpen = () => {
    handleCloseMenu();
    setOpen(true);
  };

  const handleClose = (item) => {
    if (item?._id) {
      DeleteSchedule(item);
    }
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { openEdit, setOpenEdit, setCurrentSocialItem, currentSocialItem } =
    useContext(UserContext);
  const handleClickOpenEdit = () => {
    setCurrentSocialItem(item);
    handleCloseMenu();
    setOpenEdit(true);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col w-[270px] h-fit mx-auto elevation-1 rounded-md bg-white">
        <div className="relative">
          <div className="flex w-full justify-center items-center rounded-t-md elevation-1">
            <img
              src={item?.img}
              alt="socialimg"
              className="w-full h-[190px] rounded-t-md object-cover object-top overflow-hidden"
            />
          </div>
          <div className="absolute top-2 right-2">
            <button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}>
              {" "}
              <img src={dotsButton} className="mr-2" />
            </button>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              TransitionComponent={Fade}>
              <MenuItem onClick={handleClickOpenEdit}>
                <img src={editSquare} className="mr-2" />
                Modifica
              </MenuItem>

              <MenuItem onClick={handleClickOpenView}>
                <img src={viewEye} className="mr-2" />
                Vedi Anteprima
              </MenuItem>
              <MenuItem onClick={handleClickOpen}>
                <img src={trashBin} className="mr-2" />
                Elimina post
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between items-center elevation-1">
          <div className="flex flex-row w-full">
            {!item?.InstaButNotFB && (
              <div
                className={`flex flex-row ${
                  !item?.InstaButNotFB ? "w-full" : "w-1/2"
                }  h-5 p-2 py-5 items-center justify-center gap-x-4 bg-marketingbtn`}>
                <SiFacebook size={20} color="#FFFFFF" className="" />

                <p className="text-white text-[10px]">Facebook</p>
              </div>
            )}

            {(item?.Insta || item?.InstaButNotFB) && (
              <div
                className={`flex flex-row ${
                  item?.Insta || item?.InstaButNotFB ? "w-full" : "w-1/2"
                }  h-5 p-2 py-5 items-center justify-center gap-x-4 bg-socialmediabtn`}>
                <BsInstagram size={20} color="#FFFFFF" className="" />

                <p className="text-white text-[10px]">Instagram</p>
              </div>
            )}
          </div>

          <>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description">
              <div className="mt-2 ml-2 mb-2">
                <svg
                  width="56"
                  height="57"
                  viewBox="0 0 56 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="4"
                    y="4.5"
                    width="48"
                    height="48"
                    rx="24"
                    fill="#FEE4E2"
                  />
                  <rect
                    x="4"
                    y="4.5"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#FEF3F2"
                    stroke-width="8"
                  />
                  <path
                    d="M28 24.5V28.5M28 32.5H28.01M38 28.5C38 34.0228 33.5228 38.5 28 38.5C22.4772 38.5 18 34.0228 18 28.5C18 22.9772 22.4772 18.5 28 18.5C33.5228 18.5 38 22.9772 38 28.5Z"
                    stroke="#D92D20"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <DialogTitle fontSize={25}>{"Elimina post :"}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  fontSize={20}
                  id="alert-dialog-slide-description">
                  Se questo post programmato non è ancora stato creato, non
                  verrà pubblicato su nessuna piattaforma che hai scelto.
                </DialogContentText>
                <br />
                <DialogContentText
                  fontSize={20}
                  id="alert-dialog-slide-description">
                  Se questo post programmato è già stato creato, verrà solo
                  eliminato dalle tue pagine Facebook.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleClose}
                  className="w-full mr-2 py-2 bg-white border border-gray-300 text-black rounded-md hover:bg-gray-100 focus:outline-none font-semibold text-lg">
                  Rifiuta
                </button>

                <button
                  onClick={() => handleClose(item)}
                  className="w-full py-2 bg-[#F04438] text-white rounded-md hover:bg-[#982020] focus:outline-none font-semibold text-lg">
                  Accetta
                </button>
              </DialogActions>
            </Dialog>
          </>

          <>
            <ViewPopUp
              openView={openView}
              setOpenView={setOpenView}
              item={item}
              setOpenEdit={setOpenEdit}
            />
          </>
        </div>
        <div className="flex h-[80px] justify-center items-center">
          <p className="messagetext  whitespace-pre-line text-left px-4">
            {item?.message ? decodeURI(item?.message) : ""}
          </p>
        </div>
        <div className="flex flex-row w-full py-2 items-center justify-center rounded-b-md bg-gray-100">
          <div className="flex flex-row w-full items-center justify-center gap-x-1">
            <BiCalendar size={20} color="#114653" className="" />

            <p className="text-[#114653] text-xs">
              {format(new Date(item.date), "dd/MM/yy")}
            </p>
          </div>

          <div className="flex flex-row w-full items-center justify-center gap-x-1">
            <AiOutlineClockCircle size={20} color="#114653" className="" />

            <p className="text-[#114653] text-xs">
              {format(new Date(item.date), "kk:mm")}
            </p>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default SocialPostCARD;
