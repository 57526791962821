import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Backbutton = ({ resetValues }) => {

  const navigate = useNavigate();

  // colour theme variation

  const location = useLocation()

  return (

    <div className="flex w-full ">

      <div className="flex flex-row w-28 ">

        <button

          onClick={() => {
            navigate(-1);
          }}


          className={`
          
            ${location.pathname.includes("dashboard") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("gamification") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("pick-up") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("agenda") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("salesman") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("user-information") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("products") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("delivery") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("reservation") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("seo") && "text-white bg-seobtn border-seobtn hover:bg-seobtnhover hover:border-seobtn active:shadow-innerbig"}
            ${location.pathname.includes("social-media") && "text-white bg-socialmediabtn border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtn active:shadow-innerbig"}
            ${location.pathname.includes("buyer-persona") && "text-white bg-buyerpersonabtn border-buyerpersonabtn hover:bg-buyerpersonabtnhover hover:border-buyerpersonabtnhover active:shadow-innerbig"}
            ${location.pathname.includes("marketing") && "text-white bg-marketingbtn border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("discount") && "text-white bg-discountbtn border-discountbtn hover:bg-discountbtnhover hover:border-discountbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("chatbot") && "text-white bg-chatbotbtn border-chatbotbtn hover:bg-chatbotbtnhover hover:chatbotbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("newsletter") && "text-white bg-newsletterbtn border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("customers") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("fidelity") && "text-white bg-fidelitybtn border-fidelitybtn hover:bg-fidelitybtnhover hover:border-fidelitybtnhover active:shadow-innerbig"}
            ${location.pathname.includes("theme-settings") && "text-white bg-themesettingsbtn border-themesettingsbtn hover:bg-themesettingsbtnhover hover:border-themesettingsbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("support") && "text-white bg-supportbtn border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("singleticket") && "text-white bg-supportbtn border-supportbtn hover:bg-supportbtnhover hover:border-supportbtnhover active:shadow-innerbig"}
            ${location.pathname.includes("*") && "text-white bg-primarybtn border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig"}

            flex w-full h-12 justify-center items-center rounded-md gap-x-1 border-2 active:border-white`

          }

        >

          <div className="flex">

            <ArrowBackIosIcon />

          </div>

          <div className="flex">

            Indietro

          </div>

        </button>

      </div>

    </div>

  )
}

export default Backbutton