import Dashboardicon from "@mui/icons-material/Dashboard";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Productsicon from "@mui/icons-material/Fastfood";
import Deliveryicon from "@mui/icons-material/LocalShipping";
import ReservationIcon from "@mui/icons-material/TableBar";
import SEOicon from "@mui/icons-material/Tune";
import Socialmediaicon from "@mui/icons-material/Groups";
import BuyerPersonaicon from "@mui/icons-material/PersonSearch";
import Customersicon from "@mui/icons-material/People";
import Marketingicon from "@mui/icons-material/StackedBarChart";
import Newslettericon from "@mui/icons-material/Email";
import ThemeIcon from "@mui/icons-material/Construction";
import Supporticon from "@mui/icons-material/SupportAgent";
import Discounticon from "@mui/icons-material/Discount";
import { BsFillChatDotsFill } from "react-icons/bs";
import DiscountIcon from '@mui/icons-material/Percent';
import LanguageIcon from '@mui/icons-material/Language';

export const tabs = [
  {
    id: 1,
    icon: <Dashboardicon />,
    name: "Account",
    href: "/dashboard/account/results",
  },
  {
    id: 2,
    icon: <Productsicon />,
    name: "Menu",
    href: "/products",
  },
  {
    id: 3,
    icon: <Deliveryicon />,
    name: "Delivery",
    href: "/delivery/pending-orders",
  },
  {
    id: 4,
    icon: <ReservationIcon />,
    name: "Prenotazioni",
    href: "/reservation",
  },
  {
    id: 5,
    icon: <Customersicon />,
    name: "Clienti",
    href: "/customers/all-customers",
  },
  {
    id: 6,
    icon: <LanguageIcon />,
    name: "Sito Web",
    href: "/website/theme",
  },
  {
    id: 7,
    icon: <Socialmediaicon />,
    name: "Social Media",
    href: "/social-media/social-manager",
  },
  {
    id: 8,
    icon: <Marketingicon />,
    name: "Pubblicità",
    href: "/marketing/inprogress-campaigns",
  },
  {
    id: 9,
    icon: <DiscountIcon />,
    name: "Offerte",
    href: "/discounts/all",
  },
  {
    id: 10,
    icon: <Newslettericon />,
    name: "Newsletter",
    href: "/newsletter/completed-newsletter",
  },
];
