import React from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Calendar from "react-calendar";
import "./Social-Manager.css";

import format from "date-fns/format";
import { isBefore, addMonths, subMonths } from "date-fns";

function MobileCalendar({ handleFilterPosts, value, SocialPosts }) {
  const today = new Date();
  const minDate = subMonths(today, 6);
  const maxDate = addMonths(today, 6);

  const isFinished = (eventStart) => isBefore(new Date(`${eventStart}`), new Date());

  const RequiredDateFormat = (date) => {
    const newFormat = format(new Date(date), "dd MMMM yyyy");
    return newFormat;
  };

  const isAtTheSelectedDay = (date1, date2) =>
    RequiredDateFormat(date1) === RequiredDateFormat(date2);

  function handleTilePoint(date) {
    const isHere = SocialPosts.findIndex(
      (item) => isAtTheSelectedDay(item.date, date)
    );
    return isHere === -1 ? false : true;
  }

  const isToday = (date) => isAtTheSelectedDay(new Date(), date);

  function FinishedPostsCheck(date) {
    const isHere = SocialPosts.findIndex(
      (item) => isAtTheSelectedDay(item?.date, date) && isFinished(item?.date)
    );
    return isHere === -1 ? false : true;
  }

  const isActiveTile = (date, value) =>
    RequiredDateFormat(date) === RequiredDateFormat(value);

  return (
    <div
      id="SocialCalendarPage"
      className="w-full mx-auto flex flex-col gap-y-3"
    >
      <Calendar
        type="single"
        defaultView="month"
        locale="it"
        // showFixedNumberOfWeeks={true}
        showNeighboringMonth={false}
        onChange={handleFilterPosts}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        next2Label={null}
        prev2Label={null}
        showWeekend
        tileContent={({ date, view }) =>
          view === "month" && (
            <>
              {handleTilePoint(date) && !isActiveTile(date, value) && (
                <div className="w-full h-[5px] bg-[#114653] rounded-full mx-auto absolute bottom-0 left-0 right-0" />
              )}

              {isToday(date) && (
                <div className="w-[4px] h-[5px] bg-[#114653] rounded-full mx-auto absolute bottom-2 left-0 right-0" />
              )}

              {FinishedPostsCheck(date) && !isActiveTile(date, value) && (
                <div
                  className={`w-full h-[5px] ${
                    isActiveTile(date, value) ? "bg-white" : "bg-[#E9606F]"
                  } rounded-full mx-auto absolute bottom-0 left-0 right-0`}
                />
              )}
            </>
          )
        }
      />
    </div>
  );
}

export default MobileCalendar;
