import React from 'react'

function CampData() {
    return (
        <>
            <div className="w-[95%] h-[474px] mx-auto rounded-md elevation-1 flex flex-col justify-between">
                <div className="flex flex-row w-full divide-x-2 h-[212px]" >
                    <div className="w-1/4 h-full flex flex-col items-center justify-center">
                        <p className="font-semibold text-5xl text-newslettertextcolour ">200</p>
                        <p className="text-black font-normal text-2xl">Aperte</p>
                    </div>
                    <div className="w-1/4 h-full flex flex-col items-center justify-center">
                        <p className="font-semibold text-5xl text-newslettertextcolour ">10</p>
                        <p className="text-black font-normal text-2xl">Cliccate</p>
                    </div>
                    <div className="w-1/4 h-full flex flex-col items-center justify-center">
                        <p className="font-semibold text-5xl text-newslettertextcolour ">3</p>
                        <p className="text-black font-normal text-2xl">Invii Falliti</p>
                    </div>
                    <div className="w-1/4 h-full flex flex-col items-center justify-center">
                        <p className="font-semibold text-5xl text-newslettertextcolour ">2</p>
                        <p className="text-black font-normal text-2xl">Disiscritti</p>
                    </div>

                </div>
                <div className="flex flex-row w-[95%] mx-auto gap-x-8 h-[212px]" >
                    <div className="w-1/2 flex flex-col gap-y-3" >
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" /> Inviate con Successo</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">678<span className="text-[#1ABA80] ml-4">99,7%</span></p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" /> Totali Aperte</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">400</p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" /> Ultima Apertura</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">09/05/22 12:00AM</p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" /> Inoltrata</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">3</p>
                        </div>

                    </div>
                    <div className="w-1/2 flex flex-col gap-y-3" >
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" />Click Unici</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">20%</p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" />Click Totali</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">15</p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" />Ultimo Click</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">04/05/22 10:00AM</p>
                        </div>
                        <div className="flex flex-row justify-between w-full items-center" >
                            <p className="text-black font-normal text-2xl"><div className="w-[16px] h-[16px] rounded-full bg-newsletterbtn mr-2 inline-block" />Abuse Reports</p>
                            <p className="text-newslettertextcolour text-2xl font-normal">1</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default CampData