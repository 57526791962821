import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import MobileNavigation from './DashMobileNavigation/DashMobileNavigation';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

function DashNavigation({ flags }) {

    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (

        <>

            <div className='flex lg:hidden xl:hidden ml-2'>

                <MobileNavigation />

            </div>

            <div className='flex flex-row w-full justify-start items-center py-4 px-10 xsm:hidden sm:hidden md:hidden xmd:hidden' >
                <button
                    onClick={() => navigate("/dashboard/account/results")}
                    className={` ${pathname === "/dashboard/account/results" ? "bg-primarybtn text-white elevation-1 cursor-default" : "text-primarytextcolour bg-white border-2 border-white hover:text-white hover:bg-primarybtnhover hover:border-primarybtnhover hover:elevation-1 active:border-white active:shadow-innerbig"} font-semibold rounded-md w-64 h-12 flex items-center justify-center`}>
                    Risultati
                </button>
                <button
                    onClick={() => navigate("/dashboard/account/general-settings")}
                    className={` ${pathname === "/dashboard/account/general-settings" ? "bg-primarybtn text-white elevation-1 cursor-default" : "text-primarytextcolour bg-white border-2 border-white hover:text-white hover:bg-primarybtnhover hover:border-primarybtnhover hover:elevation-1 active:border-white active:shadow-innerbig"} font-semibold rounded-md w-64 h-12 flex items-center justify-center`}>
                    Impostazioni Generali
                </button>

                {flags.accountSectionSubscription && <button
                    onClick={() => navigate("/dashboard/account/subscription")}
                    disabled={pathname === "/dashboard/account/subscription/invoces" || pathname === "/dashboard/account/subscription/plans"}
                    className={` ${pathname.includes("subscription") ? "bg-primarybtn text-white elevation-1 cursor-default" : "text-primarytextcolour bg-white border-2 border-white hover:text-white hover:bg-primarybtnhover hover:border-primarybtnhover hover:elevation-1 active:border-white active:shadow-innerbig"} font-semibold rounded-md w-64 h-12 flex items-center justify-center`}>
                    Abbonamento
                </button>
                }

                {/* <button
                    onClick={() => navigate("/dashboard/support")}
                    className={` ${pathname === "/dashboard/support" ? "bg-primarybtn text-white elevation-1 cursor-default" : "text-primarytextcolour bg-white border-2 border-white hover:text-white hover:bg-primarybtnhover hover:border-primarybtnhover hover:elevation-1 active:border-white active:shadow-innerbig"} font-semibold rounded-md w-64 h-12 flex items-center justify-center`}>
                    Supporto
                </button> */}


            </div>

        </>

    )
}

export default withLDConsumer()(DashNavigation);