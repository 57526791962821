import DefaultPreview from "../../../Assets/Images/Newletter_Preview.svg";

const dataString = "data:image/jpeg;base64,";

const getNewsletterImageSrc = image => {
  if (typeof image === "string" && image.length > dataString.length)
    return `${dataString}${image}`;
    
  return DefaultPreview;
};

export default getNewsletterImageSrc;