import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import "./MailingChecklist.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function MailingChecklist({ allLists, chosenLists, onListChosen, SetFocus, handleSelectTemplates }) {
  const theme = useTheme();

  const handleChange = (value) => {

    // On autofill we get a stringified value.
    const listNames = typeof value === "string" ? value.split(",") : value;

    onListChosen(listNames.map(ln => allLists.find(l => l.name === ln)));
  };

  const chosenListNames = chosenLists?.map(l => l.name);

  return (
    <div>
      <FormControl sx={{ width: "25em" }}>
        <InputLabel id="demo-multiple-chip-label">Liste Selezionate</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={chosenListNames}
          onChange={(e) => {
            handleChange(e.target.value)
            SetFocus(Math.random())
            handleSelectTemplates()
          }}
          onBlur={() => {
            SetFocus(Math.random())
            handleSelectTemplates()
          }}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Liste Selezionate"
            />
          }
          renderValue={selected => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {allLists.map(item => (
            <MenuItem
              key={item?.name}
              value={item?.name}
              style={getStyles(item?.name, chosenLists, theme)}
            >
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MailingChecklist;
