import React from "react";
import { Outlet } from "react-router-dom";
import '../../../Reusable/CSS/Page-Layout.css';
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import MarketingCampaignProvider from "../../../Contexts/MarketingCampaignContext";
import { getUserData } from "../../../Utility/getUserData";

function Marketingpage() {
  const userData = getUserData();
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!userData?.socialAccounts?.facebookPageResourceIdentifier) {
      navigate("/dashboard/account/general-settings")
      toast.info("Connetti una pagina Facebook")
    }
  }, [])

  return (
    <MarketingCampaignProvider>
      <div className="pagelayout">

        <Outlet />

      </div>
    </MarketingCampaignProvider>

  );
}

export default Marketingpage;
