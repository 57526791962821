import axios from "axios";
// import { toast } from "react-toastify";
import { baseUrl } from "../Utility/Api/Urls";
// import { getUserId } from "../Utility/getUserData";
import { getAuthConfig } from "./../Utility/Api/auth";
import VeganIcon from "../Assets/Icons/Vegan-Icon.svg";
import VegetarianIcon from "../Assets/Icons/Vegetarian-Icon.svg";
import GlutenIcon from "../Assets/Icons/Gluten-Free-Icon.svg";
import { format } from "date-fns";
import moment from "moment";

// const BaseEndpoint = `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`;
const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
  process.env.REACT_APP_DASHBOARD_API_PATH
}`;

export const indicationsData = [
  { label: "Vegano", value: "VEGAN", icon: VeganIcon },
  { label: "Senza Glutine", value: "GLUTEN FREE", icon: GlutenIcon },
  { label: "Vegetariano", value: "VEGETARIAN", icon: VegetarianIcon },
];

export const Allergenis = [
  "Cereali",
  "Crostacei",
  "Uova",
  "Pesce",
  "Arachidi",
  "Soia",
  "Latte",
  "Frutta a guscio",
  "Sedano",
  "Senape",
  "Semi di sesamo",
  "Anidride solforosa e solfiti",
  "Lupino",
  "Molluschi",
];

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const DefaultFirstday = format(firstDay, "yyyy-MM-dd");
const DefaultLastDay = format(lastDay, "yyyy-MM-dd");

export const getBookings = async (dateRange) => {
  const forDayBody = {
    forDay: dateRange,
  };
  const body = {
    start_date: dateRange?.start || DefaultFirstday,
    end_date: dateRange?.end || DefaultLastDay,
  };
  const theUsedBody = typeof dateRange !== "object" ? forDayBody : body;
  try {
    const response = await axios.post(
      `${url}/reservations/lookup`,
      theUsedBody,
      getAuthConfig(),
    );
    return response;
  } catch (e) {
    console.log(
      e.response.data?.message ?? "Spiacente , abbiamo avuto dei problemi",
    );
  }
};

export const getBookingsForYear = async (period) => {
  const body = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date:
      period === "year"
        ? moment().add("year", 1).format("YYYY-MM-DD")
        : period === "month"
        ? moment().add("month", 1).format("YYYY-MM-DD")
        : moment().add("days", 7).format("YYYY-MM-DD"),
  };
  const body2 = {
    start_date: new Date(),
    end_date: new Date(date.getFullYear(), 12, 0),
  };

  try {
    const response = await axios.post(
      `${url}/reservations/lookup`,
      period ? body : body2,
      getAuthConfig(),
    );
    return response;
  } catch (e) {
    console.log(
      e.response.data?.message ?? "Spiacente , abbiamo avuto dei problemi",
    );
  }
};

export async function getDailyBookings(day) {
  try {
    const response = await axios.get(
      `${url}/reservations/bookings?startDate=${day?.start}&endDate=${day?.end}&status=PENDING,CONFIRMED,CUSTOMER_IN_PREMISES`,
      getAuthConfig(),
    );
    // const response = await axios.get(`${url}/reservations/bookings-for-day?day=${day}&status=PENDING,CONFIRMED,CUSTOMER_IN_PREMISES`, getAuthConfig())
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function ChangeBookingStatus(BookingID, BookingStatus, tables) {
  await axios.post(
    `${url}/reservations/booking-status/${BookingID}`,
    {
      status: BookingStatus,
      tables: tables,
    },
    getAuthConfig(),
  );
}

export async function CreateManualBooking(BookingBody) {
  try {
    const res = axios.post(
      `${url}/reservations/create-walkin`,
      BookingBody,
      getAuthConfig(),
    );
    return res;
  } catch (error) {
    return console.log(error.message);
  }
}

export async function lookUpTables() {
  const BookingDate = {
    startDate: "2023-01-01T08:03:40.950Z",
    endDate: "2023-12-31T21:03:40.950Z",
  };
  try {
    const res = axios.post(
      `${url}/reservations/lookup-tables`,
      BookingDate,
      getAuthConfig(),
    );
    return res;
  } catch (error) {
    return console.log(error);
  }
}

export async function updateBookingTables(BookingID, tables) {
  await axios.patch(
    `${url}/reservations/updateBookingTables/${BookingID}`,
    {
      tables: tables,
    },
    getAuthConfig(),
  );
}
