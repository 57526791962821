import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiMenuItem from "@material-ui/core/MenuItem";

const SuccessModal = ({ handleClose, open }) => {

    const MenuItem = withStyles({
        root: {
            justifyContent: "center"
        }
    })(MuiMenuItem);
    const useStyles = makeStyles({


        customOutline: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2B65BB",
                border: "   solid 2px"

            }
        }

    });
    const classes = useStyles();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: 4,
    };



    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="flex flex-col gap-4">
                        <div className="self-center">
                            <p className="text-[#2B65BB] text-4xl font-semibold">Success</p>
                        </div>
                        <div className="self-center my-6">
                            <p className="text-black text-lg text-center  ">You will receive a confirmation email in the next 24 hours to approve</p>
                        </div>
                        <div className="self-center ">
                        <button className=" btn-md--primary w-28" onClick={()=>{handleClose()}} >Close</button>
                        </div>




                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default SuccessModal;
