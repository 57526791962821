import React from 'react';
import img from '../../../../Assets/Images/MarketingCardImage.png';
import HtppIcon from '../../../../Assets/Icons/http-Marketing-Icon.svg';
import BasketIcon from '../../../../Assets/Icons/basket-Marketing-Icon.svg';
import CartIcon from '../../../../Assets/Icons/cart-Marketing-Icon.svg';
import CashIcon from '../../../../Assets/Icons/cash.png';

import {
    LinearProgressGreen,
    LinearProgressPurple,
    LinearProgressRed,
} from '../OnProgressCampaign/AllProgressBars';
import Box from '@mui/material/Box';

function CreateCampCard({ handleOpen }) {
    return (
        <>
            <div className='w-[430px] h-[380px]  rounded-md elevation-1 mx-auto flex flex-col justify-evenly text-left p-3'>
                <div className='flex flex-row'>
                    <p className='text-marketingtextcolour text-xl'>
                        CAMPAGNA 1
                    </p>
                    <button className='text-[#4CAF50] ml-auto border-2 border-[#4CAF50] h-fit py-1 px-8 w-fit rounded-md '>
                        Molto Raccomandato
                    </button>
                </div>
                <div>
                    <p className='text-marketingtextcolour text-lg font-semibold'>
                        Descrizione
                    </p>
                    <p className='text-[#AEAEAE] font-normal'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi scelerisque elit laoreet condimentum imperdiet.
                    </p>
                </div>
                <div className='flex flex-row gap-x-2 divide-x-2 my-2 '>
                    <div className='w-[203px] h-[132px] flex flex-col gap-y-1'>
                        <img
                            src={img}
                            alt=''
                            className='w-full h-full object-cover'
                        />
                    </div>
                    <div className='w-[223px] flex flex-col gap-y-4 px-2'>
                        <div className='flex flex-row gap-x-2'>
                            <div className='w-[50px] h-[40px] mx-auto my-auto'>
                                <img
                                    src={HtppIcon}
                                    alt=''
                                    className='w-full h-full grow'
                                />
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='flex flex-row justify-between w-full'>
                                    <p className='text-xs font-normal text-[#D02A2A]'>
                                        Visualizzazioni Sito
                                    </p>
                                    <p className='text-xs font-normal text-[#D02A2A]'>
                                        {' '}
                                        28%
                                    </p>
                                </div>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgressRed value={35} />
                                </Box>
                            </div>
                        </div>
                        <div className='flex flex-row gap-x-2'>
                            <div className='w-[50px] h-[40px] mx-auto my-auto'>
                                <img
                                    src={BasketIcon}
                                    alt=''
                                    className='w-full h-full object-contain'
                                />
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='flex flex-row justify-between w-full'>
                                    <p className='text-xs font-normal text-[#4B2AD0]'>
                                        Aggiunti al Carrello
                                    </p>
                                    <p className='text-xs font-normal text-[#4B2AD0]'>
                                        {' '}
                                        28%
                                    </p>
                                </div>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgressPurple value={35} />
                                </Box>
                            </div>
                        </div>
                        <div className='flex flex-row gap-x-2'>
                            <div className='w-[50px] h-[40px] mx-auto my-auto'>
                                <img
                                    src={CartIcon}
                                    alt=''
                                    className='w-full h-full object-contain'
                                />
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='flex flex-row justify-between w-full'>
                                    <p className='text-xs font-normal text-[#2AD044]'>
                                        Acquisti
                                    </p>
                                    <p className='text-xs font-normal text-[#2AD044]'>
                                        {' '}
                                        28%
                                    </p>
                                </div>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgressGreen value={35} />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='w-[240px] h-[30px] rounded-md ml-auto flex flex-row mt-1'>
                    <div className='w-[94px] h-full bg-marketingbtn  rounded-l-lg flex flex-row gap-x-2 justify-center items-center pl-1'>
                        <div>
                            <img
                                src={CashIcon}
                                alt=''
                            />
                        </div>
                        <p className='text-white font-semibold text-md'>12 $</p>
                    </div>
                    <div
                        onClick={() => handleOpen()}
                        className='w-full h-full  border-2 border-marketingbtn  rounded-r-lg flex items-center justify-center '
                    >
                        <p className='text-semibold text-marketingtextcolour text-md'>
                            vedi campagna
                        </p>
                    </div>
                </button>
            </div>
        </>
    );
}

export default CreateCampCard;
