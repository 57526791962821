import React, { createContext } from 'react'
import { baseUrl } from '../Utility/Api/Urls';

export const DeliveryContext = createContext();
function DeliveryContextProvider({ children }) {
    const [orders, setOrders] = React.useState({});
    const [DeliveryPendingOrders, SetDeliveryPendingOrders] = React.useState([])
    const [PickUpPendingOrders, SetPickUpPendingOrder] = React.useState([])
    const [isLoading, SetLoading] = React.useState(false)
    const [forReload, SetReload] = React.useState(123)
    const [DeliveryConfirmedOrders, SetDeliveryConfirmedOrders] = React.useState([])
    const [PickUpConfirmedOrders, SetPickUpConfirmedOrders] = React.useState([])
    const [OrdersHisotry, SetOrdersHistory] = React.useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [MinMax, SetMinMax] = React.useState({ from: null, to: null })
    const [isActive, SetActive] = React.useState(0)

    const handleChange = event => {
        let { value, max, name } = event.target;
        value = Math.min(Number(max), Number(value))

        SetMinMax({ ...MinMax, [name]: value === 0 ? null : value })
    }

    function handleTheOrderHours(order) {
        const theHour = parseInt(order?.deliveryTime.slice(0, 2))
        return theHour !== "NaN" ? theHour : false
    }

    function handleFilter() {
        if (MinMax.from === MinMax.to) {
            if (isActive === 0) {
                const res = DeliveryConfirmedOrders.filter((order) => handleTheOrderHours(order) >= MinMax.from)
                SetDeliveryConfirmedOrders(res)
                SetPickUpConfirmedOrders(orders.PickUpConfirmedOrders)
            } else {
                const res = PickUpConfirmedOrders.filter((order) => handleTheOrderHours(order) >= MinMax.from)
                SetPickUpConfirmedOrders(res)
                SetDeliveryConfirmedOrders(orders.DeliveryConfirmedOrders)
            }
        } else if (MinMax.from < MinMax.to) {
            if (isActive === 0) {
                const res = DeliveryConfirmedOrders.filter((order) => handleTheOrderHours(order) >= MinMax.from && handleTheOrderHours(order) <= MinMax.to)
                SetDeliveryConfirmedOrders(res)
                SetPickUpConfirmedOrders(orders.PickUpConfirmedOrders)
            } else {
                const res = PickUpConfirmedOrders.filter((order) => handleTheOrderHours(order) >= MinMax.from && handleTheOrderHours(order) <= MinMax.to)
                SetPickUpConfirmedOrders(res)
                SetDeliveryConfirmedOrders(orders.DeliveryConfirmedOrders)
            }
        }

        if (MinMax.from === 0 || MinMax.to === 0 || MinMax.from === null || MinMax.to === null) {
            SetDeliveryConfirmedOrders(orders.DeliveryConfirmedOrders)
            SetPickUpConfirmedOrders(orders.PickUpConfirmedOrders)
        }
        setAnchorEl(null);
        SetMinMax(null)
    }

    const value = {
        orders,
        SetMinMax,
        isLoading,
        SetDeliveryConfirmedOrders,
        SetPickUpConfirmedOrders,
        setAnchorEl,
        anchorEl,
        SetReload,
        DeliveryPendingOrders,
        PickUpPendingOrders,
        MinMax,
        handleChange,
        handleFilter,
        isActive,
        DeliveryConfirmedOrders,
        PickUpConfirmedOrders,
        SetActive,
        OrdersHisotry,
        SetLoading,
        SetDeliveryPendingOrders,
        SetPickUpPendingOrder,
        SetOrdersHistory,
        OrdersHisotry,
        setOrders,

    }

    return <DeliveryContext.Provider value={value}>{children}</DeliveryContext.Provider>;
}

export default DeliveryContextProvider