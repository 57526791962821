import React, { useEffect, useState } from 'react'
import MarketingHeaderLottie from "../../../../../Assets/Lottie/Marketing.json";
import BackArrow from '../../../../../Assets/Icons/BackArrow.svg';
import { useLocation, useNavigate } from "react-router-dom"
import Carousel from "react-multi-carousel";
import TemplateCard from './TemplateCard';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner')).default;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { totalItems, slidesToShow, currentSlide } } = rest;

    return (
        <>
            <button
                className="absolute -right-4 left-0 ml-auto w-fit  top-24 bottom-0 my-auto h-fit  hover:bg-gray-100 rounded-full border elevation-1 p-2"
                onClick={() => next()}
                disabled={totalItems - slidesToShow - currentSlide <= 0}
            >

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 18l6-6-6-6" />
                </svg>

            </button>

            <button
                className="absolute -left-4 right-0 mr-auto w-fit  top-24 bottom-0 my-auto h-fit  hover:bg-gray-100 rounded-full border elevation-1 p-2 "
                onClick={() => previous()}
                disabled={currentSlide === 0}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#173659"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 18l-6-6 6-6" />
                </svg>
            </button>
        </>
    );
};

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1366,
        },
        items: 4,
        partialVisibilityGutter: 10,
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0,
        },
        items: 1,
        partialVisibilityGutter: 30,
    },
    tablet: {
        breakpoint: {
            max: 1366,
            min: 464,
        },
        items: 2,
        partialVisibilityGutter: 30,
    },
};


const loadTemplateData = searchPhrases => {
    const includesPhrases = (originalKey, searchPhrases) => {
      const key = originalKey.toLocaleLowerCase();

      return searchPhrases
        .map(phrase => key.includes(phrase.toLocaleLowerCase()))
        .every(v => v);
    };
    const loadImage = (fileName, extension) => {
      try {
        return require(`../../../../../Assets/Marketing-Templates/${fileName}.${extension}`);
      } catch (error) {
        return null;
      }
    };
    const importAllTemplates = req =>
      req.keys().map(key => {
        if (!includesPhrases(key, searchPhrases)) return null;

        const fileName = key.slice(2);
        const fileNameWithoutExt = fileName.replace(/\.json/gi, "");
        const name = fileNameWithoutExt.replaceAll(/-|_/gi, " ");
        let image = loadImage(fileNameWithoutExt, "jpg") || loadImage(fileNameWithoutExt, "png");

        return {
          json: req(key),
          fileName,
          name,
          image,
        };
      });

    // actual function starts here
    const data = importAllTemplates(
      require.context(
        "../../../../../Assets/Marketing-Templates", 
        false, // do not search in subfolders (there are no subfolders)
        /\.json$/
      )
    );
    return data.filter(d => d);
  };

function ChooseTemplate() {
    const navigate = useNavigate();
    const { state: { goalData, buyerPersonaData } } = useLocation();

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const data = loadTemplateData([goalData.value, buyerPersonaData.value]);
        setTemplates(data);
    }, [goalData]);

    const handleTemplateSelection = template => navigate(
        "/marketing/create-campaigns/camp-personlization",
        {
            state: {
                goalData,
                buyerPersonaData,
                templateData: template
            }
        });

    return (
        <>
            <div className="flex flex-col" >

                <InformationTopBanner

                pagetype="Sezione"
                sectionname="Pubblicità"
                description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'Il tuo ristorante'}
                sectionicon={MarketingHeaderLottie}

                />

                <button
                    className="bg-[#3366BB]  py-2 my-3 px-8 h-fit rounded-md mr-auto ml-5 text-white flex flex-row items-center gap-x-1"
                    onClick={() => navigate("/marketing/create-campaigns")}>
                    <img src={BackArrow} alt="Back Arrow" /> Back
                </button>

                <div className="relative px-5 mx-auto w-[95%] self-center">

                    <p className="text-textcolour text-2xl text-left p-4 font-semibold">Template Suggeriti</p>
                    <p className='text-[#AEAEAE] text-left font-normal px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>
                    <Carousel
                        additionalTransfrom={0}
                        // autoPlay is bugged, for now, we are putting high autoPlaySpeed
                        // https://github.com/YIZHUANG/react-multi-carousel/issues/261
                        autoPlay={false}
                        autoPlaySpeed={9999999999}
                        arrows={false}
                        centerMode={false}
                        draggable={false}
                        infinite={false}
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        responsive={responsive}
                        focusOnSelect={false}
                        slidesToSlide={1}

                    >

                        {templates?.length > 0 && templates.map(template => (
                            <div className='py-10' key={template?.id}>
                                <TemplateCard key={template.name} template={template} onSelect={handleTemplateSelection} />
                            </div>
                        ))}

                    </Carousel>

                </div>

            </div>

        </>
    )
}

export default ChooseTemplate
