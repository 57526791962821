import { useEffect, useState } from "react";
import * as service from "../apis/templateService";

export function useSocialTemplates({ goal, deps, onLoad } = {}) {
  return useTemplates(service.Type.Social, { goal, deps, onLoad });
}

export function useMarketingTemplates({ goal, persona, deps, onLoad } = {}) {
  return useTemplates(service.Type.Marketing, {
    goal,
    persona,
    deps,
    onLoad,
  });
}

export function useNewsletterTemplates({ goal, deps, onLoad } = {}) {
  return useTemplates(service.Type.Newsletter, { goal, deps, onLoad });
}

export function useAutoMailTemplates({ goal, deps, onLoad } = {}) {
  return useTemplates(service.Type.AutoMail, { goal, deps, onLoad });
}

const typeFunctionMap = {
  [service.Type.Social]: service.getSocialTemplates,
  [service.Type.Marketing]: service.getMarketingTemplates,
  [service.Type.Newsletter]: service.getNewslettersTemplates,
  [service.Type.AutoMail]: service.getAutoMailTemplates,
};

function useTemplates(type, { goal, persona, deps, onLoad } = {}) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const templates = await typeFunctionMap[type](goal, persona);
      if (templates) {
        setTemplates(templates);
        onLoad && onLoad(templates);
      }

      setIsLoading(false);
    })();
  }, deps || []);

  return [templates, isLoading];
}
