import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from '../../../../../Reusable/ScrollToTop';
import getNewsletterImageSrc from "../../getNewsletterImageSrc";
import Pencilicon from "../../../../../Assets/Icons/Pencil_light.svg";
import Trashicon from "../../../../../Assets/Icons/Trash_light.svg";
import * as newslettersService from "../../newslettersService";
import useServiceCall from "../../../../../Hooks/useServiceCall";
import useServiceResource from "../../../../../Hooks/useServiceResource";
import DeleteAlertButton from "../../../../../Reusable/DeleteAlert/DeleteAlertButton";
import DemograficiData from "./DemograficiData";
import BackArrow from "../../../../../Assets/Icons/BackArrow.svg";
import NewsletterHeaderLottie from "../../../../../Assets/Icons/Newsletter-Section-Icon.svg";
import CampData from "./CampData";
import '../../../../../Reusable/CSS/Page-Layout.css';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner')).default;
}

function Newsletterreview() {

  const navigate = useNavigate();
  const { id } = useParams();

  // Main data
  const [data, setData, getData, renderGETLoader, isLoading] =
    useServiceResource({
      apiCall: () => newslettersService.getNewsletter(id),
      deps: [id],
    });

  const handleEdit = () =>
    navigate(`/newsletter/create-a-new-mail`, { state: data });

  // Removing a newsletter
  const [deleteNewsletter, renderDeleteLoader, isDeleting] = useServiceCall({
    apiCall: newslettersService.deleteNewsletter,
    onSuccess: () => navigate("/newsletter/completed-newsletter")
  });

  const handleDeleteConfirm = () => deleteNewsletter(id);
  const [isActive, SetActive] = React.useState("1")
  return (
    <div className="pagelayout">

      <InformationTopBanner

        pagetype="Sezione"
        sectionname="Newsletter"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'Il tuo ristorante'}
        sectionicon={NewsletterHeaderLottie}

      />

      <ScrollToTop />
      <div className="flex flex-col gap-y-8 h-fit w-full my-8" >
        <button onClick={() => navigate(-1)} className="bg-newsletterbtn w-[99px] mt-4 h-[38px] rounded-md flex flex-row items-center gap-x-1 text-white justify-center "><img src={BackArrow} alt="Back Arrow" /> Back</button>

        <div className="flex flex-row gap-x-6 bg-[#F8F8F8] rounded-md p-5 w-fit mx-auto" >
          <button className="w-[350.5px] h-[33px] rounded-md bg-newsletterbtn text-white" > Crea Nuova Email </button>
        </div>
        <div className="flex flex-row w-fit mx-auto">
          <button onClick={() => SetActive("1")} className={`${isActive === "1" ? "border-newslettertextcolour text-newslettertextcolour" : "text-[#AEAEAE] border-[#AEAEAE]"} px-12 border-b-2 font-semibold text-xl`}>Dati</button>
          <button onClick={() => SetActive("2")} className={`${isActive === "2" ? "border-newslettertextcolour text-newslettertextcolour" : "text-[#AEAEAE] border-[#AEAEAE]"} px-12 border-b-2 font-semibold text-xl`}>Email</button>
        </div>
        <div className="flex flex-row gap-x-6  " >

          <button onClick={() => navigate("/newsletter/seeAllEmails")} className="bg-white  text-newsletterbtn elevation-1  mr-auto ml-[3%] p-2 w-[250.5px] h-[48px] rounded-md"  >Vedi Tutte le Email</button>
          <button className="bg-newsletterbtn  text-white p-2 w-[250.5px] h-[48px] rounded-md" >Vedi/Aggiungi Mailing List</button>
          <button className="bg-white border-2 border-newslettertextcolour  text-newsletterbtn mr-[3%] p-2 w-[250.5px] h-[48px] rounded-md"  >Contatta Assistente Digitale</button>
        </div>
      </div>


      {renderGETLoader()}
      {!isLoading && isActive === "1" &&
        <>
          <div className="flex flex-col gap-y-12">

            <CampData />
            <DemograficiData />
          </div>
        </>
      }
      {!id && isActive === "2" && <p>Campagna Is invalido.</p>}

      {!isLoading && !data && id && isActive === "2" && <p>Campagna non trovata.</p>}

      {data && isActive === "2" && (
        <div className=" bg-whitebg p-12 m-auto w-11/12 rounded-border1 ">
          <div className="m-auto my-5  flex flex-row ">
            <div className="m-auto w-3/5 items-center flex flex-col">
              <div className="w-10/12 h-52 xl:h-60 mb-5">
                <img
                  src={getNewsletterImageSrc(data.email.image)}
                  className="w-full h-60 rounded-md grow object-scale-down"
                  alt="Preview of newsletter email."
                />
              </div>

              <div className="w-10/12 mt-5  ">
                <h1 className=" headerfont text-left text-3xl  font-semibold">
                  {data.email.notes}
                </h1>

                <p className="text-left font-semibold text-xl">
                  {data.email.subject}
                </p>

                <div className="text-left text-base font-normal mt-2">
                  <p className="text-left font-semibold text-xl">
                    Liste dell'email:
                  </p>
                  <ul>
                    {data.mailingLists.map(mailingList => (
                      <li key={mailingList.id}>
                        <ul>
                          <li>{mailingList.name}</li>
                          <li>
                            {mailingList.customers && (
                              <ul>
                                {mailingList.customers.map(c => (
                                  <li key={c.id}>
                                    {c.name} - {c.email}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="flex justify-center align-center py-3">
                  {!data.isCompleted && (
                    <button
                      className="bg-newsletterbtn hover:bg-newsletterbtnhover text-white mt-3 p-1 px-2 xsm:w-full sm:w-full md:w-11/12 rounded-md grow-0 w-min-content mx-2 center buttonblue"
                      onClick={handleEdit}
                    >
                      <img src={Pencilicon} alt="" className="mx-auto" />
                    </button>
                  )}

                  <DeleteAlertButton
                    message="Questa campagna verrà eliminata definitivamente!"
                    onConfirm={handleDeleteConfirm}
                    renderButton={props => (
                      <button
                        className="bg-newsletterbtn hover:bg-newsletterbtnhover text-white mt-3 p-1 px-2 xsm:w-full sm:w-full md:w-11/12 rounded-md grow-0 w-min-content mx-2 center buttonred"
                        disabled={isDeleting}
                        {...props}
                      >
                        <img src={Trashicon} alt="" className="mx-auto" />
                      </button>
                    )}
                  />
                </div>

                {renderDeleteLoader()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Newsletterreview;
