import axios from "axios";
import { getAuthConfig } from "./Api/auth";
import { baseUrl } from "./Api/Urls";

const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/uploader/uploadImage`;


export default async function UploadImageToCDN(file) {
    let data = new FormData();
    data.append('file', file);
    try {
        const res = await axios.post(`${url}`, data, getAuthConfig())
        return res.data
    } catch (error) {
        throw console.log(error)
    }
}