import React from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Calendar from "react-calendar";
import format from "date-fns/format";

function NewsLetterCalendar({ handleFilterPosts, AllNewsLetters, SelectedDate }) {


    const RequiredDateFormat = (date) => {
        const newFormat = format(new Date(date), "dd MMMM yyyy")
        return newFormat
    }
    const isAtTheSelectedDay = (date1, date2) => RequiredDateFormat(date1) === RequiredDateFormat(date2)

    function handleTilePoint(date) {
        const isHere = AllNewsLetters?.findIndex((item) => isAtTheSelectedDay(item.dateOfPublish, date))
        return isHere === -1 ? false : true;
    }
    const isToday = (date) => isAtTheSelectedDay(new Date(), date)





    const isActiveTile = (date, value) => RequiredDateFormat(date) === RequiredDateFormat(value)

    return (
        <div id="newsletter" className="w-full mx-auto flex flex-col gap-y-3">
            <Calendar
                type="single"
                defaultView="month"
                locale="it"
                // showFixedNumberOfWeeks={true}
                showNeighboringMonth={false}
                onChange={handleFilterPosts}
                value={SelectedDate}
                next2Label={null}
                prev2Label={null}
                showWeekend
                tileContent={({ date, view }) =>
                    view === "month" &&
                    <>
                        {handleTilePoint(date) && !isActiveTile(date, SelectedDate) &&
                            <div className="w-full h-[5px] bg-[#EFA461] rounded-full mx-auto absolute bottom-0 left-0 right-0 " />}

                        {isToday(date) &&
                            <div className="w-[4px] h-[5px] bg-[#114653] rounded-full mx-auto absolute bottom-2 left-0 right-0" />}


                    </>
                }
            />
        </div>
    )
}

export default NewsLetterCalendar