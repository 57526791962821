import React, { useEffect, useState } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import { getUserToken } from "../../../../../../Utility/getUserData";

export default function UseGoogleAutoComplete({ patchData, setPatchData, formLabel = "Indirizzo", hanleIsChanged }) {
  const [predictions, setPredicitons] = useState([]);


  const GoogleUpdateLocation = (search) => {
    const token = getUserToken();

    const config = { headers: { Authorization: `Bearer ${token}` } };
    axios.get(`https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/googleMaps/getAutocompleteResults?query=${search}`, config)
      .then((response) => {
        setPredicitons(response.data?.predictions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GoogleUpdateLocation(patchData.address[0]);
  }, []);

  const handleChange = (evt, newValue) => {
    hanleIsChanged()
    GoogleUpdateLocation(evt.target.value);
    setPatchData({
      ...patchData,
      address: [newValue],
    });
  };

  return (
    <div className="flex flex-col w-full pt-1 gap-y-1">
      <label className="font-semibold text-left ">
        {formLabel}
      </label>
      <Autocomplete
        disablePortal
        id="google-auto-complete"
        options={predictions.map((prediction) => prediction.description)}
        className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
        freeSolo
        name="address"
        onChange={(e, newVal) => handleChange(e, newVal)}
        value={patchData.address[0]}
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            onChange={(evt) => { GoogleUpdateLocation(evt.target.value); }}
          />
        )}
      />
    </div>

  );
}
