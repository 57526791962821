import React from "react";
import { Outlet } from 'react-router-dom';
import ScrollToTop from "../../../Reusable/ScrollToTop";
import '../../../Reusable/CSS/Page-Layout.css';
import './Newsletter.css';

function Newsletter() {


  return (

    <div className='pagelayout'>

      <ScrollToTop />

      <Outlet />

    </div>

  );
}

export default Newsletter;
