import React, { useState } from 'react';
import ColorPickerImg from "./img/colorpicker.svg"

export const ColorPicker = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="w-[50px] h-[50px] relative"
            onClick={() => props.handleSectionChange("CanvaColorPalette")}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <button>
                <img src={ColorPickerImg} alt="ColorPicker" className='w-full'>

                </img>
            </button>
            {isHovered &&
                <div className='absolute min-w-max z-20 bottom-[-45px] left-[-32px] bg-black rounded px-2 py-1'>
                    <span className='text-white'>
                        Colore sfondo
                    </span>
                </div>
            }
        </div>
    )
}