import axios from "axios";
import { getAuthConfig } from "../Utility/Api/auth";
import { baseUrl } from "../Utility/Api/Urls";


const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH
  }/seo`;


export async function customerAction(timeFrame) {
  try {
    const res = await axios.get(`${url}/customerAction/${timeFrame}`, getAuthConfig())

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;

  }

}

export const getKeyWordsDetails = async (webSiteName, from, until) => {
  try {
    const res = await axios.get(`${url}/getSeoDataPerKeyword/${from}/${until}/${webSiteName}`, getAuthConfig())

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};


export const getWebSiteDetails = async (webSiteName, from, until) => {
  try {
    const res = await axios.get(`${url}/getSeoDataPerPage/${from}/${until}/${webSiteName}`, getAuthConfig())

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
