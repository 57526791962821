import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { AiOutlineCloseCircle } from "react-icons/ai"
function CreateCustomerModal({ customerCreationModal, handleCloseCreationModal, creatCustomer }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: window.innerWidth < 700 ? "95%" : 500,
        bgcolor: "background.paper",
        borderRadius: 5,
        boxShadow: 2,
        p: 4,
    };
    const [newCustomerInfo, SetCustomerInfo] = React.useState({})

    const [Error, SetError] = React.useState("")
    function handleChange(e) {
        SetCustomerInfo({ ...newCustomerInfo, [e.target.name]: e.target.value })
        if (Error === "Il numero deve essere di 9 cifre" && e.target.name === "phoneNumber" && e.target.value.length >= 9) {
            SetError("")
        }
    }

    function handleCreateCustomer(e, newCustomerInfoo) {
        e.preventDefault();
        if (!newCustomerInfo?.firstName || !newCustomerInfo?.lastName || !newCustomerInfo?.email || !newCustomerInfo?.phoneNumber) {
            return SetError("Riempi tutti i campi")
        }
        SetError("")
        creatCustomer(newCustomerInfoo)
    }
    return (
        <Modal
            open={customerCreationModal}
            disableScrollLock
            onClose={handleCloseCreationModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            id="tableModal"
        >
            <Box sx={style}>

                <form onSubmit={(e) => handleCreateCustomer(e, newCustomerInfo)} className="flex flex-col gap-3">

                    <div className="w-full flex">
                        <div className="mb-5 w-full px-5">
                            <p className="text-primarytextcolour text-2xl mb-4">
                                Nome
                            </p>
                            <input
                                required
                                minLength={4}
                                onChange={handleChange}
                                type="text"
                                className='w-full rounded-md border-primarybtn h-12 focus:outline-none p-3 border-2'
                                name="firstName"
                                value={newCustomerInfo?.firstName}
                                placeholder="Es: Giacomo"
                            />

                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="mb-5 w-full px-5">
                            <p className="text-primarytextcolour text-2xl mb-4">
                                Cognome
                            </p>
                            <input
                                minLength={4}
                                required
                                onChange={handleChange}
                                type="text"
                                className='w-full rounded-md border-primarybtn h-12 focus:outline-none p-3 border-2'
                                name="lastName"
                                value={newCustomerInfo?.lastName}
                                placeholder="Es: Di Giacomo"
                            />

                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="mb-5 w-full px-5">
                            <p className="text-primarytextcolour text-2xl mb-4">
                                Email
                            </p>
                            <input
                                required
                                onChange={handleChange}
                                type="email"
                                className='w-full rounded-md border-primarybtn h-12 focus:outline-none p-3 border-2'
                                name="email"
                                value={newCustomerInfo?.email}
                                placeholder="Es: giacomo@gmail.com"
                            />

                        </div>
                    </div>
                    <div className="w-full flex">
                        <div className="mb-5 w-full px-5">
                            <p className="text-primarytextcolour text-2xl mb-4">
                                Numero di Telefono
                            </p>
                            <input
                                required
                                onChange={handleChange}
                                type="number"
                                className='w-full rounded-md border-primarybtn h-12 focus:outline-none p-3 border-2'
                                name="phoneNumber"
                                value={newCustomerInfo?.phoneNumber}
                                onBlur={(e) => e.target.value.length < 9 && SetError("Il numero deve essere di 9 cifre")}
                                placeholder="Es: 0123456789"
                            />

                        </div>
                    </div>
                    <p className='text-center font-normal text-red-500 text-sm -mt-3 mb-1' >{Error}</p>
                    <div className='flex flex-row justify-center items-center gap-x-4'>

                        <button
                            type='submit'
                            className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig"
                            onClick={(e) => handleCreateCustomer(e, newCustomerInfo)}
                        >
                            Crea Cliente
                        </button>

                        <button
                            type='submit'
                            className="w-40 h-12 rounded-md elevation-1 text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:border-white active:shadow-innerbig"
                            onClick={handleCloseCreationModal}
                        >
                            Annulla
                        </button>

                    </div>

                </form>

            </Box>
        </Modal>
    )
}

export default CreateCustomerModal