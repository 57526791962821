import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../../Reusable/ScrollToTop";
import '../../../Reusable/CSS/Page-Layout.css';

function Themepage() {
  return (
   
    <div className='pagelayout'>

      <ScrollToTop />

      <Outlet />

    </div>
  );
}

export default Themepage;
