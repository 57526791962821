import React, { useState } from "react";
import Calendar from "./Icons/calendar.svg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ResultsCalendar from "./resultscalendar";

function ResultsCalendarButton() {
    const [calendarOpen, setCalendarOpen] = useState(false);
    return (
        <div className="relative">
            <button className="border rounded-md px-4 py-2 flex gap-x-3" onClick={() => setCalendarOpen(!calendarOpen)}>
                <div className="flex items-center gap-x-2">
                    <img src={Calendar} />
                    <span>
                        Filtra  mese
                    </span>
                </div>
                {!calendarOpen &&
                    <KeyboardArrowDownRoundedIcon />
                }
                {calendarOpen &&
                    <KeyboardArrowUpRoundedIcon />
                }
            </button>
            {calendarOpen &&
                <ResultsCalendar close={setCalendarOpen} />
            }
        </div>
    )
}

export default ResultsCalendarButton;
