const template = {
  page: {
    body: {
      container: { style: { "background-color": "#e2dede" } },
      content: {
        computedStyle: {
          linkColor: "#FF819C",
          messageBackgroundColor: "transparent",
          messageWidth: "400px",
          messageWidthMobile: "250px",
        },
        style: {
          color: "#000000",
          "font-family":
            "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
        },
      },
      type: "mailup-bee-page-properties",
      webFonts: [
        {
          fontFamily:
            "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
          name: "Montserrat",
          url: "https://fonts.googleapis.com/css?family=Montserrat",
        },
        {
          family: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontName: "Helvetica",
          name: "Helvetica",
        },
        {
          family: "'Lato', Tahoma, Verdana, Segoe, sans-serif",
          fontFamily: "'Lato', Tahoma, Verdana, Segoe, sans-serif",
          fontName: "Lato",
          name: "Lato",
          url: "https://fonts.googleapis.com/css?family=Lato",
        },
        {
          family: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
          fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
          fontName: "Roboto",
          name: "Roboto",
          url: "https://fonts.googleapis.com/css?family=Roboto",
        },
        {
          family: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontFamily:
            "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontName: "Open Sans",
          name: "Open Sans",
          url: "https://fonts.googleapis.com/css?family=Open+Sans",
        },
        {
          family: "Verdana, Geneva, sans-serif",
          fontFamily: "Verdana, Geneva, sans-serif",
          fontName: "Verdana",
          name: "Verdana",
        },
        {
          family: "Tahoma, Verdana, Segoe, sans-serif",
          fontFamily: "Tahoma, Verdana, Segoe, sans-serif",
          fontName: "Tahoma",
          name: "Tahoma",
        },
        {
          family:
            "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          fontFamily:
            "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif",
          fontName: "ヒラギノ角ゴ Pro W3",
          name: "ヒラギノ角ゴ Pro W3",
        },
        {
          fontFamily: "'Akaya Telivigala', arial",
          name: "Akaya Telivigala",
          url: "https://fonts.googleapis.com/css?family=Akaya+Telivigala",
        },
      ],
    },
    description: "BF-ecommerce-template",
    rows: [
      {
        container: {
          style: {
            "background-color": "transparent",
            "background-image": "none",
            "background-repeat": "no-repeat",
            "background-position": "top left",
          },
        },
        content: {
          style: {
            "background-color": "transparent",
            color: "#000000",
            width: "800px",
            "background-image": "none",
            "background-repeat": "no-repeat",
            "background-position": "top left",
          },
          computedStyle: {
            rowColStackOnMobile: true,
            rowReverseColStackOnMobile: false,
          },
        },
        columns: [
          {
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "0px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "0px",
            },
            modules: [
              {
                type: "mailup-bee-newsletter-modules-image",
                descriptor: {
                  image: {
                    alt: "",
                    src: "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/album_1.jpg",
                    href: "",
                    width: "1743px",
                    height: "1744px",
                  },
                  style: {
                    width: "100%",
                    "padding-top": "0px",
                    "padding-right": "0px",
                    "padding-bottom": "0px",
                    "padding-left": "0px",
                  },
                  computedStyle: {
                    class: "center autowidth",
                    width: "200px",
                    hideContentOnMobile: false,
                  },
                },
                uuid: "50faff8e-b6a0-4332-b086-3f3bde8adb38",
              },
            ],
            "grid-columns": 6,
            uuid: "d52c7d26-cf12-4d93-a251-290c9b4ac4be",
          },
          {
            style: {
              "background-color": "transparent",
              "border-bottom": "0px solid transparent",
              "border-left": "0px solid transparent",
              "border-right": "0px solid transparent",
              "border-top": "0px solid transparent",
              "padding-bottom": "0px",
              "padding-left": "0px",
              "padding-right": "0px",
              "padding-top": "0px",
            },
            modules: [
              {
                type: "mailup-bee-newsletter-modules-heading",
                descriptor: {
                  heading: {
                    title: "h3",
                    text: "<strong>NEW RELEASE</strong>",
                    style: {
                      color: "#aaaaaa",
                      "font-size": "10px",
                      "font-family":
                        "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
                      "link-color": "#fefefe",
                      "line-height": "120%",
                      "text-align": "left",
                      direction: "ltr",
                      "font-weight": "normal",
                      "letter-spacing": "0px",
                    },
                  },
                  style: {
                    width: "100%",
                    "text-align": "center",
                    "padding-top": "5px",
                    "padding-right": "10px",
                    "padding-bottom": "0px",
                    "padding-left": "10px",
                  },
                  computedStyle: { width: 52, height: 42 },
                },
                uuid: "a07fa982-5167-456d-b9d6-866cb3b21b04",
              },
              {
                type: "mailup-bee-newsletter-modules-divider",
                descriptor: {
                  divider: {
                    style: { "border-top": "1px solid #AAAAAA", width: "100%" },
                  },
                  style: {
                    "padding-top": "0px",
                    "padding-right": "10px",
                    "padding-bottom": "5px",
                    "padding-left": "10px",
                  },
                  computedStyle: {
                    align: "center",
                    hideContentOnMobile: false,
                  },
                },
                uuid: "124a0f88-e13c-482f-a22e-2951e24b2cc8",
              },
              {
                type: "mailup-bee-newsletter-modules-icons",
                descriptor: {
                  iconsList: {
                    icons: [
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/play-round.png",
                        textPosition: "right",
                        text: "Random Times",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "256px",
                        height: "256px",
                      },
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/play-round.png",
                        textPosition: "right",
                        text: "Handmade Justice",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "256px",
                        height: "256px",
                      },
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/play-round.png",
                        textPosition: "right",
                        text: "Bad Choices",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "256px",
                        height: "256px",
                      },
                    ],
                  },
                  style: {
                    color: "#ffffff",
                    "text-align": "left",
                    "padding-top": "0px",
                    "padding-right": "5px",
                    "padding-bottom": "0px",
                    "padding-left": "5px",
                    "font-family": "inherit",
                    "font-size": "11px",
                  },
                  computedStyle: {
                    hideContentOnMobile: false,
                    hideContentOnDesktop: false,
                    itemSpacing: "0px",
                    iconSpacing: {
                      "padding-top": "5px",
                      "padding-right": "5px",
                      "padding-bottom": "5px",
                      "padding-left": "5px",
                    },
                    iconHeight: "16px",
                  },
                },
                uuid: "aa7e1acd-b042-40a1-95f8-57c527929f02",
              },
              {
                type: "mailup-bee-newsletter-modules-divider",
                descriptor: {
                  divider: {
                    style: { "border-top": "1px solid #AAAAAA", width: "100%" },
                  },
                  style: {
                    "padding-top": "5px",
                    "padding-right": "10px",
                    "padding-bottom": "0px",
                    "padding-left": "10px",
                  },
                  computedStyle: {
                    align: "center",
                    hideContentOnMobile: false,
                  },
                },
                uuid: "f2581fb1-33f2-4ab1-b912-62c9d9d3ef90",
              },
              {
                type: "mailup-bee-newsletter-modules-icons",
                descriptor: {
                  iconsList: {
                    icons: [
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/c3006d25-09a1-46c5-b6ba-f3ef32039d5b.png",
                        textPosition: "left",
                        text: "AVAILABLE IN",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "122px",
                        height: "112px",
                      },
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/amazon.png",
                        textPosition: "right",
                        text: "",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "122px",
                        height: "122px",
                      },
                      {
                        image:
                          "https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4126/apple.png",
                        textPosition: "right",
                        text: "",
                        alt: "",
                        title: "",
                        href: "",
                        target: "_self",
                        width: "122px",
                        height: "122px",
                      },
                    ],
                  },
                  style: {
                    color: "#aaaaaa",
                    "text-align": "left",
                    "padding-top": "0px",
                    "padding-right": "10px",
                    "padding-bottom": "0px",
                    "padding-left": "10px",
                    "font-family": "inherit",
                    "font-size": "10px",
                    "letter-spacing": "0px",
                  },
                  computedStyle: {
                    hideContentOnMobile: false,
                    hideContentOnDesktop: false,
                    itemSpacing: "0px",
                    iconSpacing: {
                      "padding-top": "5px",
                      "padding-right": "0px",
                      "padding-bottom": "5px",
                      "padding-left": "5px",
                    },
                    iconHeight: "16px",
                    itemsSpacing: "0px",
                  },
                },
                uuid: "9e879112-7a55-418a-a6d6-67d482457600",
              },
              {
                type: "mailup-bee-newsletter-modules-button",
                descriptor: {
                  button: {
                    label:
                      '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 24px;" data-mce-style="font-size: 12px; line-height: 24px;"><p style="font-size: 16px; line-height: 32px; word-break: break-word;" data-mce-style="font-size: 16px; line-height: 32px;"><span style="font-size: 12px; line-height: 24px;" data-mce-style="font-size: 12px; line-height: 24px;">Order Now →</span></p></div>',
                    href: "",
                    style: {
                      "font-family": "inherit",
                      "background-color": "#af0022",
                      "border-radius": "4px",
                      "border-top": "0px solid transparent",
                      "border-right": "0px solid transparent",
                      "border-bottom": "0px solid transparent",
                      "border-left": "0px solid transparent",
                      color: "#ffffff",
                      "line-height": "200%",
                      "padding-top": "5px",
                      "padding-right": "20px",
                      "padding-bottom": "5px",
                      "padding-left": "20px",
                      width: "auto",
                      "max-width": "100%",
                    },
                  },
                  style: {
                    "text-align": "center",
                    "padding-top": "10px",
                    "padding-right": "10px",
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                  },
                  computedStyle: {
                    width: 120,
                    height: 42,
                    hideContentOnMobile: false,
                  },
                },
                uuid: "6ee7edbb-dbe7-4f14-8f5b-a52c26b8e842",
              },
            ],
            "grid-columns": 6,
            uuid: "de8928e6-8edf-4a12-b4a6-bfd8e8f155db",
          },
        ],
        type: "two-columns-empty",
        uuid: "d271fd20-3c23-488c-8d10-1bad582cd503",
      },
    ],
    template: { name: "template-base", type: "basic", version: "2.0.0" },
    title: "BF-ecommerce-template",
  },
  comments: {},
};
export default template;
