import React, { useState } from "react";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useServiceCall from "../../../../Hooks/useServiceCall";
import Loader from "../../../../Reusable/Loader/Loader";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import * as newslettersService from "../newslettersService";
import {
  mailingListCreationSchema,
  mailingListUpdateSchema,
} from "../validationSchemas";
import "./Mailing-List.css";
import { Backdrop, Tooltip } from "@mui/material";
import Trashicon from "../../../../Assets/Icons/Trash_light.svg";
import * as ThemeCustomerAPI from "../../../../Utility/Api/ThemesCustomerAPI";
import { getUserData } from "../../../../Utility/getUserData";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const createEmptyCustomer = () => "";

const mapToViewModel = (dbCustomers) =>
  !dbCustomers ? [""] : dbCustomers.map((e) => e.email);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 1000 ? 600 : "95%",
  bgcolor: "background.paper",
  borderTopWidth: 18,
  borderTopColor: "#FFA563",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  height: "fit-content",
};

export default function MailingListForm({
  existingList,
  subscribedCustomers,
  onCancelled,
  onFinished,
  isCreatingList,
}) {
  const [formData, setFormData] = useState({
    mailingListName: existingList?.name || "",
    customers: [],
  });

  const [isTouched, setIsTouched] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleListNameChanged = ({ target: input }) => {
    const updatedData = { ...formData };
    updatedData.mailingListName = input.value;
    setFormData(updatedData);
  };

  const handleCustomerRemoved = (index) => {
    let updatedData = { ...formData };

    updatedData.customers = [...updatedData.customers];
    updatedData.customers.splice(index, 1);

    if (updatedData.customers.length === 0)
      updatedData.customers.push(createEmptyCustomer());

    setFormData(updatedData);
  };

  const handleCustomerChanged = (index, email) => {
    const updatedData = { ...formData };
    updatedData.customers = [...formData.customers];

    updatedData.customers[index] = email;
    setFormData(updatedData);
  };

  const validate = () => {
    const schema = existingList
      ? mailingListUpdateSchema
      : mailingListCreationSchema;
    const { error } = schema.validate(formData);
    return error;
  };

  const handleCancel = () => {
    setIsTouched(false);
    onCancelled();
  };

  const validationError = validate();

  const [isCreating, setIsCreating] = useState();

  const handleCreate = async () => {
    setIsTouched(true);
    if (validationError) return;
    setIsCreating(true);
    const userData = getUserData();
    const data = await newslettersService.createMailingList({
      name: formData.mailingListName,
      customers: selectedEmails,
      email: userData?.email,
      senderName: userData.name + " " + userData.lastName,
      contactName: userData?.phone,
    });

    
    if (data) {
      onFinished(data);
      toast.success("Lista aggiunta.");
    }
    setIsCreating(false);
  };

  const [updateName, renderNameLoader, isUpdatingName] = useServiceCall({
    apiCall: newslettersService.updateMailingList,
  });
  const [addCustomers, renderAddLoader, isAddingCustomers] = useServiceCall({
    apiCall: newslettersService.addCustomersToMailingList,
  });

  const handleUpdate = async () => {
    if (validationError) return;

    if (existingList.name !== formData.mailingListName) {
      await updateName(existingList.id, { name: formData.mailingListName });
    }

    if (formData.customers.length > 0) {
      const customers = formData.customers
        .map((email) => {
          const systemData = customersList.find((sc) => sc.email === email);
          if (!systemData) return null;

          return {
            email,
            firstName: systemData.firstName || "Cliente",
            lastName: systemData.lastName || "Senza Nome",
          };
        })
        .filter(Boolean);
      await addCustomers(existingList.id, customers);
    }

    onFinished();
  };

  const renderError = () => {
    return isTouched && validationError ? (
      <p className="text-red-600">
        {validationError.message.replace(/"/gi, "")}
      </p>
    ) : null;
  };

  const isLoading = isCreating || isUpdatingName || isAddingCustomers;
  const [customersList, setCustomersList] = useState([]);
  async function getAllCustomers() {
    try {
      const all = await ThemeCustomerAPI.list();
      setCustomersList(all.data);
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    getAllCustomers();
  }, []);

  const customersSubscribed = customersList.filter(
    (customer) =>
      customer.tags &&
      customer.tags.includes("ISCRITTO ALLA NEWSLETTER") &&
      customer.email,
  );

  const handleSelectionChange = (event, selectedOptions) => {
    const formattedCustomers = selectedOptions.map((option) => ({
      email: option.email,
      firstName: option.firstName || "",
      lastName: option.lastName || "",
    }));
    setSelectedEmails(formattedCustomers);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      disableScrollLock
      open={isCreatingList}
      onClose={handleCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isCreatingList}>
        <Box sx={style}>
          <p className="font-semibold text-center text-xl text-newsletterbtn -mt-2 mb-2 ">
            {existingList ? "Modifica" : "Crea"} una Lista
          </p>

          <div className="flex flex-col w-full text-lg gap-3 align-center justify-center">
            <div className="flex flex-col gap-2 flex-initial text-lg mb-2">
              <label className="font-semibold text-left text-newsletterbtn ">
                Nome della Lista
              </label>
              <input
                className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none "
                placeholder="Nome della Lista"
                type="text"
                autoComplete="off"
                id="mailingListName"
                value={formData?.mailingListName}
                onChange={handleListNameChanged}
              />
            </div>

            <div className="flex flex-col  text-lg mt-2">
              <p className="font-semibold text-left text-newsletterbtn">
                Aggiungi Clienti
              </p>
              <div
                id="newsletter"
                className="flex gap-2 text-lg w-full justify-center items-center mt-6 ">
                <Autocomplete
                  options={
                    Array.isArray(customersSubscribed)
                      ? customersSubscribed
                      : []
                  }
                  multiple
                  limitTags={1}
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.email}
                  onChange={handleSelectionChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.email}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Checkboxes"
                      placeholder="Favorites"
                    />
                  )}
                />
              </div>

              <div className="self-center justify-center text-red-600 my-2">
                {renderError()}
              </div>

              <div className="flex gap-5 w-full self-center justify-center ">
                {existingList && (
                  <button
                    className="bg-newsletterbtn w-1/2 hover:bg-newsletterbtnhover text-whitep-1 px-3 rounded-md py-1 text-white"
                    onClick={handleUpdate}
                    disabled={isUpdatingName || isAddingCustomers}>
                    {renderNameLoader() || renderAddLoader() || "Modifica"}
                  </button>
                )}
                {!existingList && (
                  <button
                    className="bg-newsletterbtn w-1/2 hover:bg-newsletterbtnhover text-whitep-1 px-3 rounded-md  py-1 text-white"
                    onClick={handleCreate}
                    disabled={isCreating}>
                    {isCreating ? <Loader /> : "Crea"}
                  </button>
                )}
                <button
                  className="text-[#C2222B] bg-[#fff] border-2 border-[#C2222B] hover:bg-[#C2222B] hover:text-white w-1/2 px-3 rounded-md py-1 "
                  onClick={handleCancel}
                  disabled={isLoading}>
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
