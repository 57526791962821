import { BrowserRouter } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import combinedReducers from "./Redux/combinedReducers";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import "./App.css";
import { useCallback, useState } from "react";
import AuthUserGetter from "./Components/Commons/Auth/AuthUserGetter";
import UserContextProvider from "./Contexts/UserContext";
import ReservationContextProvider from "./Contexts/ReservationContext";
import { getUserData } from "./Utility/getUserData";
import { baseUrl } from "./Utility/Api/Urls";
import axios from "axios";
import { getAuthConfig } from "./Utility/Api/auth";
import {
  fetchDeliverySettings,
  updateDelivery,
} from "./apis/DeliverySettingsApi";
import DiscountsContextProvider from "./Contexts/DiscountsContext";
import RestaurantInfoContextProvider from "./Contexts/RestaurantInfoContext";
import DeliveryContextProvider from "./Contexts/DeliveryContext";
import MarketingCampaignContext from "./Contexts/MarketingCampaignContext";
import { ResultsCalendarProvider } from "./Contexts/ResultsCalendarContext";
import "animate.css";
import { Nav } from "./Navigation/RC/Nav";

const store = configureStore({
  reducer: combinedReducers,
  createAsyncThunk: true,
});

function App() {
  const [isLoggedIn, setIsloggedIn] = useState(false);
  const handleLogin = useCallback(() => setIsloggedIn(true), []);
  const userData = getUserData();
  const theUserHasNoPaymentMethodConnected =
    !userData?.paymentAccounts?.stripeAccount?.stripeID;
  const url = `https://${userData?._id}.${
    process.env.REACT_APP_THEMES_PREFIX
  }${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`;

  async function handleReservationSettings() {
    try {
      const res = await axios.get(`${url}/reservation/settings`);
      const res2 = await fetchDeliverySettings();
      const deliverySettings = res2?.data[0];
      const res3 = await axios.get(
        `${url}/booking/businessStatus`,
        getAuthConfig(),
      );
      const isOperating = res3?.data?.operational;
      if (!isOperating) {
        const DeliveryPatchBody = {
          ...deliverySettings,
          isDeliveryAvailable: false,
        };
        await updateDelivery(deliverySettings?._id, DeliveryPatchBody);
      }

      if (theUserHasNoPaymentMethodConnected) {
        const DeliveryPatchBody = { ...deliverySettings, creditCard: false };
        await updateDelivery(deliverySettings?._id, DeliveryPatchBody);
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    handleReservationSettings();
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <DeliveryContextProvider>
            <ReservationContextProvider>
              <UserContextProvider>
                <AuthUserGetter
                  isLoggedIn={isLoggedIn}
                  onLoginSuccessfull={handleLogin}
                />
                <MarketingCampaignContext>
                  <RestaurantInfoContextProvider>
                    <DiscountsContextProvider>
                      <ResultsCalendarProvider>
                        <div className="App">{isLoggedIn && <Nav />}</div>
                        <ToastContainer theme="colored" />
                      </ResultsCalendarProvider>
                    </DiscountsContextProvider>
                  </RestaurantInfoContextProvider>
                </MarketingCampaignContext>
              </UserContextProvider>
            </ReservationContextProvider>
          </DeliveryContextProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default withLDProvider({
  clientSideID: "64ec5f42b1173112ef5c7efa",
  context: {
    kind: "user",
    key: "dev_user",
    name: "Developer user",
    email: "development@aigot.com",
  },
})(App);
