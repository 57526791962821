import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import itLocale from "date-fns/locale/it";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import "./ScheduleCamp.css";

const useStyles = makeStyles({
  input: {
    width: "140px",
    height: "52px",

    "& input + fieldset": {
      border: "none",
    },
  },
});

function ScheduleCamp({ onPublish, disabled }) {
  const classes = useStyles();

  const getMinDate = () => new Date(Date.now() + 1000 * 5 * 60); // after 5 minutes

  const [date, setDate] = React.useState(Date.now() + 1000 * 6 * 60); // default starting time is after 6 minutes to give time for user to revise data

  const [error, setError] = React.useState();

  useEffect(() => {
    if (new Date(date) < getMinDate()) 
        setError("La data scelta deve'essere dopo almeno 5 minuti.");
    else
        setError(null);
  }, [date]);

  return (
    <>
      <div className="newsletterscheduler flex flex-col bg-white p-5 gap-y-3 rounded-md w-[550px] elevation-1">
        <div className="flex flex-row">
          <div className="p-3">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={itLocale}
            >
              <Box sx={{ width: 300, height: 350, overflow: "hidden" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  disablePast={true}
                  minDate={getMinDate()}
                  value={date}
                  onChange={newValue => setDate(newValue)}
                  renderInput={params => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </div>
          <div className="flex flex-col items-center p-3">
            <div>
              <p className=" text-newslettertextcolour font-semibold p-2 text-md ">
                Giorno Suggerito
              </p>
              <p className="bg-[#F2F2F2] text-[#B0ADAD] rounded-md py-3 px-7 text-center">
                Mer
              </p>
            </div>
            <div>
              <p className=" text-newslettertextcolour font-semibold p-2 text-md ">
                Orario Suggerito
              </p>
              <p className="bg-[#F2F2F2] text-[#B0ADAD] rounded-md py-3 px-7 text-center">
                19:30
              </p>
            </div>

            <div>
              <p className=" text-newslettertextcolour font-semibold p-2 text-md ">
                Seleziona un orario
              </p>

              <div className="flex justify-center newsletterscheduler">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={itLocale}
                >
                  <MobileTimePicker
                    value={date}
                    disablePast={true}
                    minDate={getMinDate()}
                    onChange={newValue => setDate(newValue)}
                    renderInput={params => (
                      <TextField
                        className={classes.input}
                        style={{
                          backgroundColor: "#F2F2F2",
                          borderRadius: "1.5rem",
                          textAlignLast: "center",
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <div className="text-red-600 w-full text-center text-md">
            {error}
          </div>
        )}
        <button
          onClick={() => onPublish(date)}
          className="px-12 py-2 font-semibold text-white rounded-md bg-newsletterbtn w-fit mx-auto  tracking-wide"
          disabled={disabled || error}
        >
          Pubblica
        </button>
      </div>
    </>
  );
}

export default ScheduleCamp;
