import axios from "axios"
import { baseUrl } from "../Utility/Api/Urls"

export const tripadvisorSearchRestaurant = async restaurantName => {
  try {
    const token = JSON.parse(localStorage.getItem("userToken"))
    const response = await axios({
      url: `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/tripadvisor/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({ searchQuery: restaurantName }),
    })
    const { data: restaurants } = response?.data

    // check restaurants length
    if (!restaurants?.length)
      throw new Error("C'è qualcosa che non va, riprova.")

    return restaurants
  } catch (e) {
    throw e
  }
}
