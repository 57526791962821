import React from "react";
import { Switch, Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import "./UseTimetables.css";
import Tabellaorari from "./Tabellaorari";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#00B27A",
    '&:hover': {
      backgroundColor: alpha("#00B27A", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#00B27A",
  },
}));

const TimetablesCard = ({
  currentIndex,
  day,
  label,
  localWorkingHoursData,
  setLocalWorkingHoursData,
  opening,
  isWorkDay,
  hanleIsChanged
}) => {
  // console.log(opening[0].dayName);
  // console.log(opening[0].map((e) => e));
  return (

    <div key={currentIndex}
      className="flex flex-row bg-white w-full px-5 py-3  elevation-1 justify-between items-start">

      <div className="flex flex-row gap-x-3 items-center" >

        <Tooltip title={!isWorkDay ? "Riattiva l'orario di lavoro" : "Disattiva l'orario di lavoro"} placement="bottom-end" arrow>

          <GreenSwitch
            checked={isWorkDay}
            color='info'
            onChange={() => {
              setLocalWorkingHoursData(
                localWorkingHoursData.map((element) =>
                  element.dayName === day
                    ? { ...element, isWorkDay: !element.isWorkDay }
                    : element
                )
              )
              hanleIsChanged()
            }
            }
          />

        </Tooltip>



        <p className="text-lg font-semibold text-primarybtn">{label}</p>
      </div>


      <div className="w-[60%]">

        <Tabellaorari
          localWorkingHoursData={localWorkingHoursData}
          setLocalWorkingHoursData={setLocalWorkingHoursData}
          opening={opening}
          day={day}
          hanleIsChanged={hanleIsChanged}
        />
      </div>
    </div>


  );
};

export default TimetablesCard;
