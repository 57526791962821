import { useContext, useState } from "react";
import PostView from "./PostView";
import PostDateComponent from "./PostDateComponent";
import { SocialMediaContext } from "../../Context/SocialMediaContext";
import { toast } from "react-toastify";
import Loader from "../../../../../Reusable/Loader/Loader";
import { useNavigate } from "react-router";
import * as imageHostingService from "../../../../../apis/imageHostingService";
import format from "date-fns/format";
import { SaveDraftButton } from "../Utils";
import { postToFacebook, postToInstagram } from "../../../../../apis/socialMediaApi";
export default function SchedulaModal({ handleSaveDraftClick, isSavingDraft }) {
  const navigate = useNavigate();


  const { image, description, hashtags, socials, } = useContext(SocialMediaContext);
  const now = format(new Date(), "HH:mm")
  const [date, setDate] = useState(new Date());
  const [hours, SetHours] = useState(now)
  const [isPosting, setIsPosting] = useState(false);

// Calculate the user entered time in seconds.
let userTime = new Date(new Date(new Date(date).setHours(hours.slice(0, 2))).setMinutes(hours.slice(3, 5))).getTime() / 1000;

// If the user time is in the past, use current time plus 300 seconds, else use the user entered time.
let time = userTime < Math.floor(Date.now() / 1000) ? Math.floor(Date.now() / 1000) + 300 : userTime;
const handlePostPublish = async () => {
  try {
    setIsPosting(true);
    const url = await imageHostingService.hostBase64Image(image);
    const { schedule } = socials.facebook && await postToFacebook(url, time, description, hashtags)
    socials.instagram && await postToInstagram(url, time, description, schedule?.id,hashtags)

    setIsPosting(false);
    navigate('/social-media/social-manager');
  } catch (error) {
    toast.error("Errore, riprova.");
    console.error(error);
    setIsPosting(false);

  }
};

  const noSocialAreSelected = Object.values(socials).every(v => !v);
  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-row xsm:flex-col sm:flex-col md:flex-col xmd:flex-col gap-x-8 mt-10 mx-auto w-fit xsm:w-[280px] sm:w-[300px]">
        <PostView />
        <PostDateComponent date={date} setDate={setDate} SetHours={SetHours} hours={hours} />
      </div>
      <div className="flex flex-row w-max mx-auto md:w-full sm:w-full xsm:w-full justify-items-end align-items-end gap-x-4">
        <SaveDraftButton onClick={handleSaveDraftClick} isLoading={isSavingDraft} />
        <button className={`w-fit mx-auto text-white text-lg py-2 px-8 font-normal rounded-md ${noSocialAreSelected ? "bg-[#a1a29d]" : "bg-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover"}`}
            disabled={noSocialAreSelected || isPosting}
            onClick={handlePostPublish}
          >
            {isPosting ? <Loader /> : 'Pubblica/Programma il Post'}
        </button>
      </div>
    </div>

  );
}
