import * as React from 'react';
import { useNavigate, useLocation } from "react-router";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDownMenu from '../../../../../Assets/Icons/ArrowDownMenu.svg'

function WebSiteThemeMobileNavigation() {

  const navigate = useNavigate();

  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [CurrentPage, SetPage] = React.useState('Sito')

  // that component is repeated in every file so on every page change the state is reseted so we have to make the state depends on the pathname 
  React.useEffect(() => {
    if (pathname === "/website/Sito") return SetPage("Sito")
    if (pathname === "/website/Visitatore") return SetPage("Visitatore")
    
  }, [pathname])


  function handleNavigate(href) {
    handleClose()
    navigate(href);
  }
  return (

    <div >

      <button
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="flex flex-row w-full h-10 justify-center elevation-1 text-white border-2 border-[E9606F] bg-[#E9606F]   active:border-white active:shadow-innerbig rounded-md place-items-center gap-x-2 px-4 mx-auto my-4"
      >

        <p className="flex buttontext">{CurrentPage}</p>

        <img src={ArrowDownMenu} alt="" className="flex w-4 h-4"></img>

      </button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >

        <MenuItem onClick={() => handleNavigate("/website/theme")}>Sito</MenuItem>
        <MenuItem onClick={() => handleNavigate("/website/Visitatore")}>Visitatore</MenuItem>


      </Menu>

    </div>

  );
}

export default WebSiteThemeMobileNavigation