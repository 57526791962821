import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { ReservationContextProvider } from "../../Contexts/ReservationContext";
import { DeliveryContext } from "../../Contexts/DeliveryContext";
import { getUserData } from "../../Utility/getUserData";
import { baseUrl } from "../../Utility/Api/Urls";
import jwt_decode from "jwt-decode";

function NavButton({ index, item, value, flags }) {
  const navigate = useNavigate();
  const [isHover, setIsHover] = React.useState(false);
  const userData = getUserData();

  const isTokenExpired = (token) => {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  };

  const checkTokenValidity = (token) => {
    if (isTokenExpired(token)) {
      localStorage.clear();
      window.location.href = `https://${
        process.env.REACT_APP_LANDING_PREFIX
      }${baseUrl()}/login`;
    }
  };

  useEffect(() => {
    checkTokenValidity(userData?.token);
  }, [userData, window.location]);

  const { AllBookings, PendingEvents, NonPendingEvents, eventAppointment } =
    React.useContext(ReservationContextProvider);
  const { DeliveryPendingOrders, PickUpPendingOrders } =
    React.useContext(DeliveryContext);

  let totalOrders = DeliveryPendingOrders.length + PickUpPendingOrders.length;
  const BookingsCount = eventAppointment.filter((item) => item?.status === 0);
  let totalBookingsCount = BookingsCount?.length;

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  if ("graphic-editor" === item.label && !flags.graphicEditor) return false;
  return (
    <button
      key={item.label}
      onClick={() => {
        item.disabled
          ? toast.info("Connetti una pagina Facebook")
          : navigate(item.pathname);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor:
          value === index + 1 || isHover ? item.color : "#F6F6F6",
        borderLeftWidth: 5,
        borderLeftColor: item.color,
      }}
      className={`flex  flex-row gap-x-4 w-full elevation-1 hover:cursor-pointer items-center pl-4 mb-1 min-h-[55px] active:shadow-innerbig ${
        item.disabled && "hover:cursor-not-allowed opacity-50"
      }`}>
      <p
        style={{ color: value === index + 1 || isHover ? "#fff" : "#AEAEAE" }}
        className="text-blue-400">
        {item.icon}
      </p>
      <p
        className={`${
          value === index + 1 || isHover ? "text-white" : "text-[#AEAEAE]"
        }  text-[1em]  fontSansInCss font-[400]  uppercase`}>
        {item.label}
      </p>

      <div className="flex w-full h-full justify-end">
        {item?.pending && (
          <div
            className={`flex w-[30px] h-[55px] items-center justify-center elevation-1 bg-tertiarybtn`}>
            <p
              className={`flex w-full h-full justify-center items-center font-semibold text-md text-white`}>
              {item?.pending === 3 ? totalOrders : totalBookingsCount}
            </p>
          </div>
        )}
      </div>
    </button>
  );
}

export default withLDConsumer()(NavButton);
