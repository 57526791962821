import React, { useState } from "react";
import "./modalContent.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./BuyerPersonaCard.css";
import axios from "axios";
import { toast } from "react-toastify";

import Avatar from "@mui/material/Avatar";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
// import { BiNote, BiPencil } from 'react-icons/bi';
// import { ImLocation } from 'react-icons/im';
// import { FiPhoneCall } from 'react-icons/fi';
// import { BsPersonLinesFill } from 'react-icons/bs';
// import { AiOutlineTag } from 'react-icons/ai';
// import AltreInfo from './AlterInfo/AltreInfo';
import useWindowSize from "../../../../Reusable/WindowSize";
import Delivery from "./Delivery/Delivery";
import Prenotazioni from "./Prenotazioni/Prenotazioni";
import { AiOutlineCloseCircle } from "react-icons/ai";
import user from "../../../../Assets/Icons/user.png";
import { BsTelephoneFill, BsFillPencilFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  AiOutlineCopy,
  AiFillTag,
  AiOutlineMinusCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserToken } from "../../../../Utility/getUserData";

function ModalContent({ open, handleClose, Data, customer }) {
  const [openTab, setOpenTab] = React.useState(1);
  const { firstName, lastName, _id, address, dob, phoneNumber, email, tags } =
    Data;
  const [Updated, SetUpdate] = React.useState(false);
  const [NewData, SetNewData] = React.useState({
    email: email ?? "",
    address: "",
    phoneNumber: phoneNumber ?? "",
    tag: "",
    tags: tags.length > 0 ? [...tags] : [],
  });
  const [isInputsDisabled, setIsInputsDisabled] = useState(true);
  const [updatedCustomer, setUpdatedCustomer] = useState({});
  const currentPlan = localStorage.getItem("currentPlan");

  const Width = useWindowSize();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: Width < 1200 ? "95%" : "1300px",
    height: Width < 800 ? "95%" : 740,
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
  };

  // active inputs
  const activeInputsHandler = () => {
    setIsInputsDisabled((prev) => !prev);
  };

  const changeFieldValue = (e) => {
    const { name, value } = e.target;

    SetNewData((prev) => ({
      ...prev,
      [name]: value,
      firstName: name === "firstName" ? value : prev.firstName,
      lastName: name === "lastName" ? value : prev.lastName,
    }));
  };

  // save tag
  const saveTagIntoTagsArray = () => {
    SetNewData((prev) => {
      return {
        ...prev,
        tags: [...NewData.tags, NewData.tag],
        tag: "",
      };
    });
  };

  // delete tag
  const deleteTagHandler = (index) => {
    const { tags } = NewData;
    const copyTags = [...tags];

    // delete tag by index
    copyTags.splice(index, 1);
    SetNewData((prev) => {
      return {
        ...prev,
        tags: [...copyTags],
      };
    });
  };

  const updateCustomer = async () => {
    try {
      const response = await axios.patch(
        `https://dashboard.develop.rc.aigotsrl-dev.com/api/systemCustomers/${Data.id}`,
        {
          firstName: Data.firstName,
          lastName: Data.lastName,
          email: NewData.email,
          phoneNumber: NewData.phoneNumber,
          dateofbirth: NewData.dob,
          gender: "GENDERLESS",
        },
        {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );
      toast.success("Cliente aggiornato con successo");
      handleClose();
    } catch (error) {
      toast.error("Errore durante l'aggiornamento del cliente");
    }
  };

  const handleEdit = () => {
    if (Updated) {
      updateCustomer();
    } else {
      // Enable the inputs for editing
      SetUpdate(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="flex flex-row p-5 gap-5">
            <div className="buyerpersonacard w-[30%] h-max p-0 rounded-t-md  bg-white rounded-md  elevation-1 xsm:w-full sm:w-full md:w-full ">
              <div className="buyerpersonacard__page1 h-full flex flex-col w-full gap-y-4 items-center">
                <div className=" overflow-hidden rounded-t-md m-auto p-3 mt-6">
                  <img
                    src={user}
                    alt=""
                    className="rounded-full w-[150px] h-[150px]"
                  />
                </div>

                <p className="pop600 text-[22px] text-left border-primarytextcolour pl-3">
                  {firstName} {lastName}
                </p>

                <div className="ModalContent__wrapperInput flex flex-1 relative mb-4">
                  <div className="flex flex-col flex-1 relative">
                    {NewData.tags.length > 0 && (
                      <ul className="mt-2 flex flex-row items-center gap-x-2">
                        {NewData.tags.map((tag, index) => {
                          return (
                            <li key={tag} className="ModalContent__tag">
                              {`#${tag}`}

                              {/*
                                <AiOutlineMinusCircle className="ModalContent__tag-icon" onClick={() => deleteTagHandler(index)} />
                                */}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>

                {/* USER INFO */}

                <div className="flex flex-col w-[95%] gap-3 pb-5">
                  <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d] rounded-md">
                    <p className="pop400 text-left text-black">Email</p>
                    <input
                      type="text"
                      name="email"
                      value={NewData.email || email}
                      className="pop400 text-left text-black outline-none"
                      disabled={!Updated}
                      onChange={changeFieldValue}
                    />
                  </div>

                  <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d] rounded-md">
                    <p className="pop400 text-left text-black">Telefono</p>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={NewData.phoneNumber || phoneNumber}
                      className="pop400 text-left text-black outline-none"
                      disabled={!Updated}
                      onChange={changeFieldValue}
                    />
                  </div>

                  <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d] rounded-md">
                    <div className="flex flex-col w-full">
                      <p className="pop400 text-left text-black">
                        Data di nascita
                      </p>
                    </div>
                    <div className="flex flex-col w-full">
                      <DatePicker
                        selected={NewData.dob || ""}
                        onChange={(date) =>
                          SetNewData({ ...NewData, dob: date })
                        }
                        className="pop400 text-left text-black outline-none"
                        disabled={!Updated}
                      />
                    </div>
                  </div>

                  <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d] rounded-md">
                    <p className="pop400 text-left text-black">Indirizzo</p>
                    <input
                      type="text"
                      name="address"
                      value={NewData.address || address}
                      className="pop400 text-left text-black outline-none"
                      disabled={!Updated}
                      onChange={changeFieldValue}
                    />
                  </div>

                  <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d] rounded-md">
                    <p className="pop400 text-left text-black">Newsletter</p>
                    <input
                      type="text"
                      name="newsletter"
                      value={NewData.newsletter || ""}
                      className="pop400 text-left text-black outline-none"
                      disabled={!Updated}
                      onChange={changeFieldValue}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col b w-[70%] bg-white rounded-md  elevation-1 xsm:w-full sm:w-full md:w-full">
              <div className="flex flex-row my-6 m-auto gap-5 ">


              {currentPlan === "nodelivery" || currentPlan === "none" ? (
        <></>
      ) : (
          <button
          onClick={() => setOpenTab(1)}
          className={`${
            openTab === 1
              ? "text-primarytextcolour underline underline-offset-2"
              : " text-[#989898]"
          }  p-3  `}
        >
          Delivery
        </button>
      )}

{currentPlan === "nobooking" || currentPlan === "none" ? (
        <></>
      ) : (
          <button
          onClick={() => setOpenTab(2)}
          className={`${
            openTab === 2
              ? "text-primarytextcolour underline underline-offset-2 "
              : "border-[#989898] text-[#989898]"
          }  p-3   `}
        >
          Prenotazioni
        </button>
      )}



                <button
                  onClick={() => setOpenTab(3)}
                  className={`${
                    openTab === 3
                      ? "text-primarytextcolour underline underline-offset-2 "
                      : " text-[#989898]"
                  } p-3   `}
                >
                  Altre informazioni
                </button>
                <button
                  onClick={() => setOpenTab(4)}
                  className={`${
                    openTab === 4
                      ? "text-primarytextcolour underline underline-offset-2 "
                      : " text-[#989898]"
                  } p-3    `}
                >
                  Altre informazioni
                </button>
              </div>
              <div className="w-full my-6">
                {openTab === 1 && <Delivery customer={Data} />}
                {openTab === 2 && <Prenotazioni customer={Data} />}
                {openTab === 3 && <Delivery customer={Data} />}
                {openTab === 4 && <Prenotazioni customer={Data} />}
              </div>
              {/* put an edit button on the corner of this */}
              <div className="flex flex-row justify-end items-center gap-5 p-5">
                <button
                  onClick={handleEdit}
                  className="bg-[#F2F2F2] text-primarytextcolour pop600 text-[14px] px-5 py-2 rounded-md"
                >
                  Modifica
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalContent;
