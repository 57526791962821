import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import SleepingCat from "../../../../../../Assets/Lottie/Sleeping-Cat.json";
import './Stripe-Success.css';


function Stripesuccess() {

  const navigate = useNavigate();

  const goToPayments = () => {
    navigate("/dashboard/account/general-settings", { state: { stripeConnection: true } });
  };

  return (

    <div className="flex flex-col w-full gap-y-4 justify-center content-center items-center my-auto mt-20">

      <h1 className="flex  justify-center items-center">CONGRATULAZIONI</h1>

      <h4 className="flex  justify-center items-center">Il collegamento a Stripe è andato a buon fine!</h4>

      <div className="flex w-full h-full mx-auto justify-center items-center">

        <Lottie animationData={SleepingCat} loop={true} />

      </div> 

      <button onClick={goToPayments} className="flex justify-center items-center w-96 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig">
      
        Torna alla Dashboard
        
      </button>

    </div>

  );
}

export default Stripesuccess;
