import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./DeleteAlert.css";

const DeleteAlert = ({ isOpen, message, onClose, onConfirm, onReject, subTitle, disabled }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (!isOpen) return null;

  return (
    <div className="w-auto absolute top-0 bottom-0 right-0 left-0 mx-auto my-auto self-suto z-50 ">
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-primarytextcolour">
          {message || "Questo articolo verrà eliminato definitivamente!"}
        </DialogTitle>

        <DialogContent>
          <div className="flex flex-col items-center gap-y-1">
            <p>{subTitle || "Sei sicuro di voler procedere?"}</p>
          </div>
        </DialogContent>

        <div className="flex place-content-center place-items-center">
          <DialogActions>

            <button type="submit" className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig" onClick={(e) => onConfirm(e)} disabled={disabled}>

              CONFERMA

            </button>

            <button type="submit" className="w-40 h-12 rounded-md elevation-1 text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:border-white active:shadow-innerbig" onClick={onReject}>

              ANNULLA

            </button>

          </DialogActions>

        </div>

      </Dialog>

    </div>

  );
};

export default DeleteAlert;
