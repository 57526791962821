import React from 'react';
import SocNavigation from '../SocNavigation/SocNavigation';
import './Questions-and-Answers.css';
import Questionsanswers from "./Questions-Answers";
import SocialManagerHeaderLottie from "../../../../Assets/Lottie/Social-Media.json";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner')).default;
}

function Questionsandanswers() {

  return (

    <div className="flex flex-col">

      <InformationTopBanner

        pagetype="Sezione"
        sectionname="Social Media"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'All’interno di questa pagina della sezione Social Media puoi rispondere alle domande fatte dai tuoi clienti o visualizzare le conversazioni passate.'}
        sectionicon={SocialManagerHeaderLottie}

      />

    <div className='flex w-full justify-center content-center items-center'>

      <div className="flex flex-col w-full mx-auto">
      
        <div className=" p-5  ">

          <SocNavigation />

        </div>

        <div className='px-5'>

        <Questionsanswers />

        </div>

      </div>

    </div>

    </div>

  );
}

export default Questionsandanswers;
