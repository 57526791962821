import axios from "axios";
// import { toast } from "react-toastify";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "./../Utility/Api/auth";


const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`


export async function getAllDiscounts() {
    try {
        const res = await axios.get(`${url}/discounts`, getAuthConfig())
        return res.data.data
    } catch (error) {
        return console.log(error)
    }
}


export async function CreateDiscount(discountbody) {
    try {
        const res = await axios.post(`${url}/discounts`, discountbody, getAuthConfig())
        return res
    } catch (error) {
        throw console.log(error)
    }
}


export async function DeleteDiscount(id) {
    try {
        const res = await axios.delete(`${url}/discounts/${id}`, getAuthConfig())
        return res
    } catch (error) {
        throw console.log(error)
    }
}


export async function httpEditDiscount(discountbody) {
    try {
        const res = await axios.patch(`${url}/discounts/${discountbody?._id}`, discountbody, getAuthConfig())
        return res
    } catch (error) {
        throw console.log(error)
    }
}


export async function GetSingleDiscount(id) {
    try {
        const res = await axios.get(`${url}/discounts/${id}`, getAuthConfig())
        return res
    } catch (error) {
        throw console.log(error)
    }
}