import React from 'react';
import { Outlet } from 'react-router-dom';
import ScrollToTop from '../../../Reusable/ScrollToTop';
import '../../../Reusable/CSS/Page-Layout.css';

function BuyerPersona() {

  return (

    <div className='w-full pb-[5rem]'>

      <ScrollToTop />

      <Outlet />

    </div>

  );
}

export default BuyerPersona;
