/**
 * Places the favorite templates at the start of the collection.
 *
 * Doesn't mutate.
 *
 * @param {Array} templates Templates to sort
 * @param {Array} favorites array of ids of the favorites
 */
export const getSortedTemplates = (templates, favorites) => {
  if (!favorites || favorites.length === 0) return templates;

  const sorted = [...templates];
  sorted?.sort((a, b) => favorites?._id?.includes(b._id) - favorites?._id?.includes(a._id));
  return sorted;
};
