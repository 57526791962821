import { useContext, useRef } from "react";
import { useEffect } from "react";
import { createContext, useState } from "react";
import { io } from "socket.io-client";
import chatbotApi from "../apis/chatbotApi";
import { getUserData } from "../Utility/getUserData";
import setUserData from "../Utility/setUserData";
import { DeliveryContext } from "./DeliveryContext";
import { getAuthConfig } from "../Utility/Api/auth";
import axios from "axios";
import { baseUrl } from "../Utility/Api/Urls";
import { ReservationContextProvider } from "./ReservationContext";

export const UserContext = createContext({
  user: null,
  setUser: () => {},
});

export default function UserContextProvider({ children }) {
  const {
    SetDeliveryConfirmedOrders,
    SetPickUpConfirmedOrders,
    SetLoading,
    SetDeliveryPendingOrders,
    SetPickUpPendingOrder,
    SetOrdersHistory,
    setOrders,
  } = useContext(DeliveryContext);
  const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
    process.env.REACT_APP_DASHBOARD_API_PATH
  }/orders?filter=confirmed`;
  const url2 = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
    process.env.REACT_APP_DASHBOARD_API_PATH
  }/orders?filter=pending`;
  const url3 = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
    process.env.REACT_APP_DASHBOARD_API_PATH
  }/orders?filter=history`;

  const [triggerRefreshOrders, setTriggerRefreshOrders] = useState(false);
  const [user, setUser] = useState(getUserData());
  const setData = (data) => {
    setUserData(data);
    setUser(data);
  };
  const socket = useRef();
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [user1, setUser1] = useState(
    JSON.parse(localStorage.getItem("userData")),
  );
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [incomingMessages, setIncomingMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [id, setId] = useState("");
  const [chat, setChat] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentSocialItem, setCurrentSocialItem] = useState("");

  // save online users
  const saveOnlineUsers = (users) => setOnlineUsers(users);
  useEffect(() => {
    if (!socket.current) return;

    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket?.current?.setMaxListeners(15);
    socket.current.emit("new-user-add", user._id);
    socket.current.on("get-users", saveOnlineUsers);

    return () => {
      socket.current.off("get-users", saveOnlineUsers);
    };
  }, [user1]);
  // Send Message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);
  // save received messages
  const saveReceivedMessages = (data) => {
    if (data) {
      setTrigger(!trigger);
    }
    setReceivedMessage(data);
    setMessages((prevMessages) => [...prevMessages, data]);
  };
  // Get the message from socket server
  useEffect(() => {
    if (!socket.current) return;

    socket.current?.on("recieve-message", saveReceivedMessages);
    return () => {
      socket.current.off("recieve-message", saveReceivedMessages);
    };
  }, [trigger, messages, receivedMessage]);
  // save messages
  const saveMessages = (messages) => {
    setReceivedMessage(messages);
    // Update messages state variable with new message
    setMessages((prevMessages) => [...prevMessages, messages]);
  };
  useEffect(() => {
    if (!socket.current) return;

    // Fetch messages from server on initial render
    const fetchMessages = async () => {
      try {
        if (id) {
          const { data } = await chatbotApi.getMessages(id);
          setMessages(data);
        }
        // console.log(data, "fct 1")
      } catch (error) {
        console.log(error);
      }
    };
    // Handle incoming messages
    socket.current.on("recieve-message", saveMessages);
    fetchMessages();
    return () => {
      socket.current.off("recieve-message", saveMessages);
    };
  }, [trigger, chat, receivedMessage]);

  ///////////////orders section/////////////////////

  function toggleTrigger() {
    setTriggerRefreshOrders(!triggerRefreshOrders);
  }

  const getAllOrders = async () => {
    SetLoading(true);
    const Confirmed = await axios.get(url, getAuthConfig());
    const Pending = await axios.get(url2, getAuthConfig());
    const History = await axios.get(url3, getAuthConfig());
    const DeliveryPendingOrders = Pending.data.orders.filter(
      (order) => order?.forDelivery,
    );
    SetOrdersHistory(History.data.orders);
    const DeliveryConfirmedOrders = Confirmed.data.orders.filter(
      (order) => order?.forDelivery,
    );
    const PickUpPendingOrders = Pending.data.orders.filter(
      (order) => !order?.forDelivery,
    );
    const PickUpConfirmedOrders = Confirmed.data.orders.filter(
      (order) => !order?.forDelivery,
    );
    SetDeliveryPendingOrders(DeliveryPendingOrders);
    SetDeliveryConfirmedOrders(DeliveryConfirmedOrders);
    SetPickUpPendingOrder(PickUpPendingOrders);
    SetPickUpConfirmedOrders(PickUpConfirmedOrders);
    setOrders({
      DeliveryPendingOrders,
      DeliveryConfirmedOrders,
      PickUpPendingOrders,
      PickUpConfirmedOrders,
      AllOrder: Pending.data.orders.concat(Confirmed.data.orders),
    });
    SetLoading(false);
  };

  useEffect(() => {
    getAllOrders();
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("recieve-orders", (data) => {
      getAllOrders();
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  ///////////////////////////////////////////////
  ////////////////bookings section////////////////
  const { GETbookings } = useContext(ReservationContextProvider);

  useEffect(() => {
    GETbookings();
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("recieve-bookings", (data) => {
      GETbookings();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  ////////////////////////////////////////////

  const UserContextValue = {
    user,
    setUser: setData,
    setSendMessage,
    sendMessage,
    setReceivedMessage,
    receivedMessage,
    setIncomingMessages,
    setMessages,
    messages,
    setId,
    setChat,
    setPharmacyName,
    pharmacyName,
    getAllOrders,
    toggleTrigger,
    triggerRefreshOrders,
    openEdit,
    setOpenEdit,
    setCurrentSocialItem,
    currentSocialItem,
  };
  return (
    <UserContext.Provider value={UserContextValue}>
      {children}
    </UserContext.Provider>
  );
}
