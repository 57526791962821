import React from "react";

export const ButtonProva = ({ text, bordercolor, textcolor, bgcolor, Icon, event }) => {

    return (

        <button className={`py-2 rounded-lg pl-4 border border-${bordercolor} pop600 text-${textcolor} bg-${bgcolor}`}
            onClick={event}>
            <div className="flex items-center justify-center">
                <Icon />
                <span className="pr-4 ml-1">
                    {text}
                </span>
            </div>
        </button>

    );
}