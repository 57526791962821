import { combineReducers } from "redux"

import { localSeoReducer } from "./localSeoReducer"
import { generalSeoReducer } from "./generalSeoReducer"
// import { editorReducer } from "./reducer/editorReducer"

export default combineReducers({
  localSeoReducer,
  generalSeoReducer,
})
