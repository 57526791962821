import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { GrClose } from "react-icons/gr"
import { RiCloseCircleLine } from "react-icons/ri"
import "./Account.css"
function CancellaAbbonamentoModal({ SetCancellaModal, CancellaAbbonamentoModalState }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth > 1000 ? 600 : "95%",
        bgcolor: 'background.paper',
        borderTopWidth: 18,
        borderTopColor: "#00B27A",
        boxShadow: 5,
        p: 4,
        borderRadius: 5,
        height: window.innerWidth < 550 ? "95%" : "fit-content",
        overflowY: window.innerWidth < 550 && "scroll"
    };


    const [IsFirstStep, SetFirstStep] = React.useState(true)

    const [theReson, SetReason] = React.useState()
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableScrollLock
            open={CancellaAbbonamentoModalState}
            onClose={() => SetCancellaModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={CancellaAbbonamentoModalState}>

                <Box sx={style}>

                    <div className='flex flex-col justify-center items-center gap-8'>





                        <button onClick={() => SetCancellaModal(false)} className="absolute top-0 right-0 m-5">
                            <GrClose size={25} color="#00B27A" />
                        </button>


                        {IsFirstStep &&
                            <div className='flex flex-col w-full gap-4'>
                                <p className=" font-semibold text-xl text-primarytextcolour text-center">Cancella Abbonamento</p>



                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">Ciao Francesco, questa è l’area dal quale puoi annullare il tuo abbonamento</p>



                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">
                                    tuo abbonamento resterà attivo fino al <span className="font-semibold"> 28/12/2022</span>. Se interrompi oggi il tuo abbonamento,
                                    ti ricordiamo che in quella data perderai accesso a:</p>


                                <div className='flex flex-col gap-y-3 mt-3' >

                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black sm:text-sm xsm:text-xs ">
                                            Dashboard con i risultati di tutte le tue attività digitali</p>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Dati delle campagne marketing che hai avviato </p>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Gestione tavoli e consegne integrata direttamente
                                            con il tuo sito web</p>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Gestionale con i dati di tutti i tuoi clienti, compresi di allergie e piatti preferiti</p>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Budget marketing incluso in tutti i nostri piani</p>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <RiCloseCircleLine color="#C25039" size={25} />
                                        <p className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Sito web, dominio e hosting: scopri come noleggiarli separatamente</p>

                                    </div>
                                </div>
                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">
                                    Sei sicuro di volerti cancellare? Alternativamente potresti
                                    <span className="font-semibold block underline">cambiare piano </span>
                                </p>



                            </div>
                        }







                        {!IsFirstStep &&
                            <div className='flex flex-col w-full gap-4'>
                                <p className=" font-semibold text-xl text-primarytextcolour text-center">Cancella Abbonamento</p>



                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">
                                    Ok Francesco, prima di lasciarti andare puoi condividere con
                                    noi la ragione principale che ti ha spinto a volerti disiscrivere?</p>



                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">
                                    La ragione principale per cui mi sto cancellando è:</p>


                                <div id="RadiosCancella" className='flex flex-col gap-y-3 mt-3' >

                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="one" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="one" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Dashboard con i risultati di tutte le tue attività digitali</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="two" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="two" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Non ho tempo per seguire Restaurant Club </label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="three" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="three" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Non ottengo valore dai contenuti</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="four" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="four" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Il prezzo è troppo alto per me</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="five" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="five" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Ho problemi con la piattaforma</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="six" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="six" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Ho problemi con l’app</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="seven" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="seven" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Ho sostituito Restaurant Club con un altro servizio</label>

                                    </div>
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input id="eight" onClick={(e) => SetReason(e.target.value)} value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <label for="eight" className="  font-semibold text-black  sm:text-sm xsm:text-xs">
                                            Altro</label>

                                    </div>
                                </div>
                                <p className="font-base text-center font-normal text-black  sm:text-sm xsm:text-xs">
                                    Per favore dicci come potremmo migliorare Restaurant Club
                                </p>


                                <input className="w-full h-[150px] bg-[#F6F6F6] rounded-lg resize-none" type="textarea" />
                            </div>
                        }

                        <div className='flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-4'>


                            <button
                                onClick={() => SetFirstStep(false)}
                                className={` ${IsFirstStep ? "w-40" : "w-fit px-2"}  h-10 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white`}>
                                {IsFirstStep ? "Salva " : "Invia e Cancella il mio abbonamento"}
                            </button>



                            <button onClick={() => SetCancellaModal(false)} className="w-40 h-10 rounded-md  text-deletebtn bg-white border-2 border-deletebtn hover:text-white hover:bg-deletebtnhover hover:border-deletebtnhover active:shadow-innerbig active:border-white">
                                Annulla
                            </button>


                        </div>

                    </div>

                </Box>

            </Fade>

        </Modal>
    )
}

export default CancellaAbbonamentoModal