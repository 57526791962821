import React from 'react'
import DeliveryCard from './DeliveryCard'
import * as ThemeCustomerAPI from "../../../../../Utility/Api/ThemesCustomerAPI";
function Delivery({ customer }) {

    const [customerOrders, SetOrders] = React.useState([])

    async function getCustomerOrders() {
        try {
            const res = await ThemeCustomerAPI.getOrdersForCustomer(customer.email)
            SetOrders(res.data.orders)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => { getCustomerOrders() }, [])
    return (
        <>

            <div className=" p-3 px-6 grid grid-cols-2 gap-2" >
            {customerOrders.length === 0 && <p className="text-lg text-black font-semibold mx-auto w-fit self-center">Nessun Ordine Effettuato</p>}
                {customerOrders?.map((item, index) => {
                    return (
                        <DeliveryCard Data={item} index={index} />
                    )
                })}
            </div>
        </>
    )
}

export default Delivery
