import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export default function PrimaryCategory({ patchData, setPatchData, hanleIsChanged }) {
  const primaryCategory = [
    {
      primaryCategory: "gcid:restaurant",
      primaryCategoryDisplayName: "Ristorante Italiano",
    },
    {
      primaryCategory: "gcid:pizza_restaurant",
      primaryCategoryDisplayName: "Pizzeria",
    },
    {
      primaryCategory: "gcid:chinese_restaurant",
      primaryCategoryDisplayName: "Ristorante Cinese",
    },
    {
      primaryCategory: "gcid:arab_restaurant",
      primaryCategoryDisplayName: "Ristorante Arabo",
    },
  ];
  return (
    <div className="flex flex-row ">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={primaryCategory.map(
          (primary) => primary.primaryCategoryDisplayName
        )}
        className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
        freeSolo
        name="primaryCategory"
        // disabled={!isLocation}
        onChange={(e, newval) => {
          hanleIsChanged()
          setPatchData({
            ...patchData,
            primaryCategory: primaryCategory?.find(
              (val) => val?.primaryCategoryDisplayName === newval
            ),
          });
        }}
        value={patchData?.primaryCategoryDisplayName}
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </div>
  );
}
