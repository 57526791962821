import { useEffect, useState } from "react";
import * as service from "../apis/templateService";
import { getUserData } from "../Utility/getUserData";

export default function useFavoriteTemplates({ deps, onLoad } = {}) {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      let favoritesTemplates = await service.getFavoriteTemplates();

      // if server call fails, try to get them from localStorage
      if (!favoritesTemplates) {
        try {
          favoritesTemplates = getUserData()?.favoriteTemplates || [];
        } catch (error) {}
      }

      if (favoritesTemplates) {
        setFavorites(favoritesTemplates);
        onLoad && onLoad(favoritesTemplates);
      }

      setIsLoading(false);
    })();
  }, deps || []);

  return [favorites, isLoading];
}
