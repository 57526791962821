import { useContext } from "react";

import SaveTimeIcon from "../../../../../../Assets/Icons/save-time.svg";
import DurationIcon from "../../../../../../Assets/Icons/carbon-time.svg";
import SpesaIcon from "../../../../../../Assets/Icons/spesa.svg";
import formatCurrency from "../../../../../../Utility/formatCurrency";
import { MarketingCampaignContext } from "../../../../../../Contexts/MarketingCampaignContext";

export default function MarketingSuggestionDisplay() {
  const {
    number,
    campaign,
    isSuggestedCampaign,
    packages,
    selectedPackage,
    suggestedNumberOfDays,
    numberOfDays,
  } = useContext(MarketingCampaignContext);
  // if (!Number.isInteger(selectedPackage)) return <></>;
  const totalBudget = formatCurrency(
    isSuggestedCampaign
      ? campaign.total_spend
      : numberOfDays * (number || packages[selectedPackage]?.budget),
  );
  return (
    <div className="flex flex-row items-center w-full justify-center gap-x-20">
      {/* Durata Suggerita */}
      <div className="flex flex-row items-center gap-x-5 justify-between">
        <img className="h-10 w-10 m-auto" src={SaveTimeIcon} alt="" />

        <p className="text-black font-normal text-xl text-left">
          <span className="text-bold font-semibold">Durata Suggerita:</span>{" "}
          {suggestedNumberOfDays}{" "}
          {suggestedNumberOfDays === 1 ? "giorno" : "giorni"}
        </p>
      </div>

      {/* Durata Attuale */}
      <div className="flex flex-row items-center gap-x-5 justify-between">
        <img className="h-10 w-10 m-auto" src={DurationIcon} alt="" />

        <p className="text-black font-normal text-xl text-left">
          <span className="text-bold font-semibold">Durata Attuale:</span>{" "}
          {numberOfDays} {numberOfDays === 1 ? "giorno" : "giorni"}
        </p>
      </div>

      {/* Spesa Totale */}
      <div className="flex flex-row items-center gap-x-5 justify-between">
        <img className="h-10 w-10 m-auto" src={SpesaIcon} alt="" />

        <p className="text-black font-normal text-xl text-left">
          <span className="text-bold font-semibold">Spesa Totale:</span>{" "}
          {totalBudget}
        </p>
      </div>
    </div>
  );
}
