import React from 'react';
import { useNavigate } from 'react-router';
import './MarNavigation.css';
import MarCustomLink from './CustomLink/MarCustomLink';
import MobileNavigation from './MarketingMobileNavigation/MarketingMobileNavigation';
import GoToButtonSingle from '../../../../Reusable/goToButton/GoToButtonSingle';
import AddIcon from "../../../../Assets/Icons/add-icon.svg";

const MarNavigation = () => {

  const navigate = useNavigate();

  const goToCreateCampaign = () => {
      navigate("/marketing/create-campaigns");
  };

  return (

    <div className='flex flex-col w-full justify-center items-center'>

      <div className='flex xmd:hidden lg:hidden xl:hidden 2xl:hidden'>

        <MobileNavigation />

      </div>

      <div className='flex w-full justify-center items-center mb-8'>

        <GoToButtonSingle onClick1={goToCreateCampaign} textname1="Crea una Nuova Campagna" icon1={AddIcon}/>

      </div>

      <div className='flex flex-col justify-center items-center xsm:hidden sm:hidden md:hidden'>

        <nav className='MarNavigation__list flex w-full'>

          <ul className='MarNavigation__list'>

            <li>
              <MarCustomLink to='/marketing/inprogress-campaigns'>Campagne in Corso</MarCustomLink>
            </li>

            <li>
              <MarCustomLink to='/marketing/completed-campaigns'>Campagne Completate</MarCustomLink>
            </li>

          </ul>

        </nav>

      </div>

    </div>

  );
};

export default MarNavigation;
