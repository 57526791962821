import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../Reusable/Loader/Loader";

const displayToastError = error => toast.error(error);

const createPromiseToast = promiseToast =>
  toast.loading(promiseToast.pending || "In corso...");

const updatePromiseToastToSuccess = (id, promiseToastOptions) =>
  toast.update(id, {
    render: promiseToastOptions.success || "Finito!",
    type: "success",
    isLoading: false,
    autoClose: true,
  });

const updatePromiseToastToError = (id, promiseToastOptions, message) =>
  toast.update(id, {
    render: promiseToastOptions.error || message || "Errore.",
    type: "error",
    isLoading: false,
    autoClose: true,
  });

const extractError = error =>
  error.response?.data?.error ||
  error.response?.data?.Error ||
  error.response?.data?.message ||
  error.message;

/**
 * Custom hook for wrapping an API service call with a loader, toastify, and some callbacks.
 * @param apiCall API promise
 * @param mapResponse Function to use to map response data to desired state.
 * @param onPerformCall Fuction to run before api call.
 * @param onSuccess Fuction to run if api call succeeds.
 * @param onError Fuction to run if api call fails. Displays toast error by default.
 * @param getErrorMessage Fuction to run to extract error message from thrown error.
 * @param shouldShowToastError Boolean for displaying toast error message
 * @param promiseToastOptions Activate promise-controlled toast with pending/success/error messages when api call is performed.
 * @returns [performCall, renderLoader, isLoading]
 */
export default function useServiceCall({
  apiCall,
  mapResponse = res => res,
  onPerformCall,
  onSuccess,
  onError,
  getErrorMessage = extractError,
  shouldShowToastError = true,
  promiseToastOptions,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const performCall = async (...args) => {
    if (onPerformCall) onPerformCall(...args);

    const promiseToastId = promiseToastOptions
      ? createPromiseToast(promiseToastOptions)
      : null;

    setIsLoading(true);

    try {
      const data = await apiCall(...args);
      if (!data || data.error || data.Error)
        throw new Error(data?.error || data?.Error || "No data received.");

      if (promiseToastId)
        updatePromiseToastToSuccess(promiseToastId, promiseToastOptions);

      const extractedData = mapResponse(data);
      if (onSuccess) onSuccess(extractedData);

      setIsLoading(false);
      return extractedData;
    } catch (error) {
      const message = getErrorMessage(error);

      if (promiseToastId)
        updatePromiseToastToError(promiseToastId, promiseToastOptions, message);
      else if (shouldShowToastError) displayToastError(message);

      if (onError) onError(message);

      setIsLoading(false);
      return null;
    }
  };

  const renderLoader = useCallback(
    props => (isLoading ? <Loader {...props} /> : null),
    [isLoading]
  );

  return [performCall, renderLoader, isLoading];
}
