import Arabian01 from "../../../../Assets/Images/Arabian-01.webp";
import Chinese01 from "../../../../Assets/Images/Chinese-01.webp";
import Chinese02 from "../../../../Assets/Images/Chinese-02.webp";
import Pizza01 from "../../../../Assets/Images/Pizza-01.webp";
import Pizza02 from "../../../../Assets/Images/Pizza-02.webp";
import Italian01 from "../../../../Assets/Images/Italian-01.webp";
import Italian02 from "../../../../Assets/Images/Italian-02.webp";
import Sweet01 from "../../../../Assets/Images/Sweet-01.webp";

const ThemeList = [
  {
    id: "Italian-01",
    image: Italian01,
    title: 'Tema Italiano 1',
    description: 'Un tema classico, elegante e raffinato per trasmettere la cura nella realizzazione dei tuoi piatti.',
    longDescription:
      "Un tema classico, elegante e raffinato per trasmettere la cura nella realizzazione dei tuoi piatti.",
  },
  {
    id: "Italian-02",
    image: Italian02,
    title: 'Tema Italiano 2',
    description: 'Un tema moderno, semplice e dal design pulito per esprimere in modo chiaro lo stile inconfondibile della tua cucina.',
    longDescription:
      "Un tema moderno, semplice e dal design pulito per esprimere in modo chiaro lo stile inconfondibile della tua cucina.",
  }
  ,
  {
    id: "Pizza-01",
    image: Pizza01,
    title: "Tema Pizza 1",
    description: "Un tema moderno e accattivante per una pizzeria che vuol far parlare di se.",
    longDescription:
      "Un tema moderno e accattivante per una pizzeria che vuol far parlare di se.",

  },

  {
    id: "Pizza-02",
    image: Pizza02,
    title: 'Tema Pizza 2',
    description: 'Un tema moderno caratterizzato da colori scuri e un carattere enigmatico. Come le tue pizze gourmet.',
    longDescription:
      "Un tema moderno caratterizzato da colori scuri e un carattere enigmatico. Come le tue pizze gourmet.",

    // this is for fitering the templates for the logo in the logo generator page component
    logoTemplateSearchValue: 'Chinese01',
    logoTemplateLabel: 'Logo Cinese',
  },
  {
    id: "Chinese-01",
    image: Chinese01,
    title: "Tema Cinese 1",
    description: "Un tema moderno per trasmettere tutta la creatività e innovazione della tua cucina.",
    longDescription:
      "Un tema moderno per trasmettere tutta la creatività e innovazione della tua cucina.",

  },
  {
    id: "Chinese-02",
    image: Chinese02,
    title: "Tema Cinese 2",
    description: "Un tema dai colori scuri ed eleganti per mostrare tutta la cura nella realizzazione dei tuoi piatti.",
    longDescription:
      "Un tema dai colori scuri ed eleganti per mostrare tutta la cura nella realizzazione dei tuoi piatti.",

  }
  ,
  {
    id: "Arabian-01",
    image: Arabian01,
    title: "Tema Arabo 1",
    description: "Un tema raffinato, elegante ma sempre attuale, come i sapori della vera cucina araba.",
    longDescription:
      "Un tema raffinato, elegante ma sempre attuale, come i sapori della vera cucina araba.",

  }
  ,
  {
    id: "Sweet-01",
    image: Sweet01,
    title: "Tema Dolci 1",
    description: "Un tema caratterizzato da un design dolce, pulito e dalla presenza di elementi di colore rosso.",
    longDescription:
      "Un tema caratterizzato da un design dolce, pulito e dalla presenza di elementi di colore rosso: come quello delle fragole o dei lamponi che usi per aggiungere un tocco di eleganza alle tue torte. I tuoi visitatori rimarranno facilmente concentrati sui tuoi prodotti.",

  }
  ,



];

export default ThemeList;


