const advancedPermissions = {
  tabs: {
    rows: {
      show: true,
      locked: false,
    },
    settings: {
      show: true,
      locked: false,
    },
    content: {
      show: true,
      locked: false,
    },
  },

  rows: {
    behaviors: {
      canSelect: true,
      canAdd: false,
      canViewSidebar: false,
      canClone: false,
      canMove: false,
      canDelete: true,
    },
  },

  columns: {
    behaviors: {
      canViewSidebar: false,
      canResize: true,
      canAdd: true,
      canDelete: true,
    },
  },

  content: {
    dynamic: {
      behaviors: {
        canSelect: true,
        canAdd: false,
        canViewSidebar: false,
        canClone: false,
        canMove: false,
        canDelete: false,
      },

      padding: {
        show: true,
        locked: false,
      },
      rows: {
        behaviors: {
          canSelect: true,
          canAdd: false,
          canViewSidebar: false,
          canClone: false,
          canMove: false,
          canDelete: true,
        },
        backgroundColorRow: {
          show: true,
          locked: false,
        },
        backgroundColorContent: {
          show: true,
          locked: false,
        },
        doNotStackOnMobile: {
          show: true,
          locked: false,
        },
        backgroundImage: {
          show: true,
          locked: false,
        },
        backgroundVideo: {
          show: true,
          locked: false,
        },
        displayConditions: {
          show: true,
          locked: false,
        },
        columnTabs: {
          show: true,
          locked: false,
        },
        contentBorder: {
          show: true,
          locked: false,
        },
        roundedCorners: {
          show: true,
          locked: false,
        },
        verticalAlign: {
          show: true,
          locked: false,
        },
      },

      columns: {
        behaviors: {
          canViewSidebar: true,
          canResize: true,
          canAdd: true,
          canDelete: true,
        },

        backgroundImage: {
          show: true,
          locked: false,
        },
      },
    },

    video: {
      behaviors: {
        canSelect: true,
        canAdd: false,
        canViewSidebar: false,
        canClone: false,
        canMove: false,
        canDelete: false,
      },

      padding: {
        show: true,
        locked: false,
      },
    },
  },
};

export default advancedPermissions;
