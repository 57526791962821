import React from 'react'
import Gmb from "./Gmb/Gmb"
import Facebook from "./Facebook/Facebook"
import Tripadvisor from "./tripadvisor/tripadvisor"
import Tilby from "./Tilby/Tilby"

function IndexSocialAccounts() {
    return (
        <>
            <Gmb />
            <Facebook />
            <Tripadvisor />
            <Tilby />

        </>

    )
}

export default IndexSocialAccounts