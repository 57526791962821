import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Routing from "../Routes";
import "./Navbartop.css";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { tabs } from "./MobileTabs"
import MenuIcon from '@mui/icons-material/Menu';
import UserLogout from "./Logout";

function Navbartoprc() {

  const location = useLocation();
  const [value, setValue] = useState(0);

  let path = location.pathname;
  const currentTab = () => {
    tabs.map((item, index) => { if (item.href === path) setValue(index) })
  };


  useEffect(() => {
    currentTab()
  }, [location.pathname])

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };


  const navigate = useNavigate()
  return (


    <>

      <div className="fixed w-fit h-fit bottom-5 right-5 z-50">
{/* to hide it from the mobile view of the theme edit */}
        {!path.includes("/theme-settings/Italian-1") &&
          <button className=" w-10 p-2 flex items-center justify-center h-10 rounded-full bg-primarybtn" onClick={toggleDrawer(true)}>
            <MenuIcon style={{ color: "white" }} />
          </button>}
      </div>
      <div className="z-50">

        <Drawer
          anchor={"left"}
          open={state}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {tabs.map((tab, index) => (
                <ListItem style={{ background: (value === index && "#00B27A") }} onClick={() => navigate(tab.href)} button key={tab.id}>
                  <ListItemIcon style={{ color: (value === index ? "white" : "#AEAEAE") }}>
                    {tab.icon}
                  </ListItemIcon>
                  <p className={`${value === index ? "font-normal text-white" : "font-normal text-[#AEAEAE]"}`} > {tab.name} </p>
                </ListItem>
              ))}
            </List>

            <div className='w-full mt-4'>

              <button onClick={UserLogout} className="w-full h-[55px] text-deletebtn bg-transparent border-y-2 border-deletebtn hover:text-white hover:bg-deletebtnhover hover:border-deletebtnhover hover:elevation-1 active:border-white active:shadow-innerbig">

                Logout

              </button>

            </div>

          </Box>
        </Drawer>
      </div>




      <div className="w-full">

        <Routing />

      </div>

    </>



  );
}

export default Navbartoprc;
