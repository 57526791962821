export const holidays = [
  {
    name: "Capodanno",
    date: "1/1",
  },
  {
    name: "Epifania",
    date: "6/1",
  },
  {
    name: "san valentino",
    date: "14/2",
  },
  {
    name: "festa dei single",
    date: "15/2",
  },
  {
    name: "festa della donna",
    date: "8/3",
  },
  {
    name: "festa del papà",
    date: "19/3",
  },
  {
    name: "festa della mamma",
    date: "14/5",
  },
  {
    name: "festa della repubblica",
    date: "2/6",
  },
  {
    name: "halloween",
    date: "31/10",
  },
  {
    name: "Natale di Gesù",
    date: "25/12",
  },
];
