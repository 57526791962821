import React from 'react';
import './BuyCustomLink.css';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const BuyCustomLink = ({ to, children }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (

    <Link
      to={to}
      className={
        match
          ? 'BuyCustomLink BuyCustomLink--active'
          : 'BuyCustomLink'
      }
    >

      {children}

    </Link>

  );
};

export default BuyCustomLink;
