import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteAlert from "../../../../../Reusable/DeleteAlert/DeleteAlert";
import "./Draftnewsletterlist.css";
import { getCampaignWithValue } from "../../newsletterCampaigns";
import UseNewsletter from "../../UseNewsletter";
import Draftewslettercards from "../Cards/Draftewslettercards";
import NewsLetterCalendar from "../../CompletedNewsletter/Completed Newsletter List/NewsLetterCalendar";
import { format } from "date-fns";
import { CircularProgress } from "@mui/material";

function Draftnewsletterlist() {
  // screen size management
  const { handleDeleteConfirm, newsLetterToShow, SetnewslettersToShow, AllNewsLetters, SetDate, isAlertOpen, handleAlertClose, handleOpenDeleteRequest, SelectedDate, isLoading } = UseNewsletter("draft")


  const navigate = useNavigate();
  const handleEditClick = template => {
    const campaign = getCampaignWithValue(template.goal);
    navigate(`${campaign.route}?draftId=${template._id}`, {
      state: { campaign },
    });
  }

  function handleFilterPosts(date) {
    SetDate(date)
    const events = AllNewsLetters.filter((item) => format(new Date(item.dateOfPublish), "dd MMMM yyyy") === format(new Date(date), "dd MMMM yyyy"));
    SetnewslettersToShow(events);
  }

  return (
    <div id="newsletter" className="flex flex-col items-center mt-8">
      <div className="flex flex-row gap-x-8 w-full min-w-full" >
        <div className="w-1/3 min-w-[25%]">
          <NewsLetterCalendar
            handleFilterPosts={handleFilterPosts}
            AllNewsLetters={AllNewsLetters}
            SelectedDate={SelectedDate}
          />
        </div>
        <div className="w-2/3 grid grid-cols-3 gap-5 p-3 rounded-md elevation-1" >
          {isLoading && <div className="col-span-3 flex flex-col items-center justify-center gap-y-2 mt-8" >
            <CircularProgress />
            <p className="text-black font-normal text-center" >Caricamento...</p>
          </div>
          }
          {!isLoading && newsLetterToShow.length !== 0 && newsLetterToShow?.map((newsletter) => (
            <Draftewslettercards
              key={newsletter._id}
              newsletter={newsletter}
              onEdit={handleEditClick}
              onRemove={handleOpenDeleteRequest}
            />
          ))}
          {!isLoading && newsLetterToShow.length === 0 &&
            <div className="col-span-3 flex flex-col items-center justify-center gap-y-2 mt-8" >
              <p className="text-black font-normal text-center" >Nessuna newsletter creata per questo giorno</p>
            </div>
          }
        </div>
      </div>
      <DeleteAlert
        isOpen={isAlertOpen}
        onConfirm={handleDeleteConfirm}
        onClose={handleAlertClose}
        onReject={handleAlertClose}
        message="Questa bozza verrà eliminata definitivamente!"
      />
    </div>
  );
}

export default Draftnewsletterlist;
