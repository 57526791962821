import React from "react";
import Navbarleft from "./Navbarleft";
import Navbartop from "./Navbartop";
import useWindowSize from '../../Hooks/useWindowSize';

export const Nav = () => {
  const { windowSize } = useWindowSize();
  const isWideScreen = windowSize.width >= 835;

  return (
    <div>
      {isWideScreen && (
        <div className="xsm:hidden sm:hidden md:hidden">
          <Navbarleft />
        </div>
      )}
      {!isWideScreen && (
        <div className="xmd:hidden lg:hidden xl:hidden">
          <Navbartop />
        </div>
      )}
    </div>
  );
}
