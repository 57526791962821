import React from 'react';
import { useNavigate } from 'react-router-dom';
import MarNavigation from '../MarNavigation/MarNavigation';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import MarketingHeaderLottie from "../../../../Assets/Lottie/Marketing.json";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner')).default;
}


function RisultatiCampagna() {


  const navigate = useNavigate();

  const goToMarketingType = () => {
    navigate("/marketing/create-campaigns");
  };




    return (

      <div className="flex flex-col w-full">

      <InformationTopBanner
  
      pagetype="Sezione"
      sectionname="Pubblicità"
      description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'Scegli il target, la descrizione, il budget e la durata della tua campagna pubblicitaria'}
      sectionicon={MarketingHeaderLottie}
  
      />

        <div className="flex flex-col w-full justify-center content-center items-center mt-4">

        <div className="flex flex-row justify-center rounded-md p-2 mb-4 mx-auto w-1/2 bg-gray-100">

          <button className="bg-marketingbtn hover:bg-marketingbtnhover text-white  m-auto w-full px-2 py-4 xsm:w-full sm:w-full md:w-11/12  rounded-md" onClick={goToMarketingType}><p className="buttontext w-full">Crea una Nuova Campagna</p></button>

        </div>

                <div className="flex flex-end">
                    <MarNavigation />
                </div>
                <p className='text-left text-marketingtextcolour font-semibold p-4 text-3xl  pl-7 '  >Risultati Campagna Marketing</p>

                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FourthSection />

            </div>
        </div>
    )
}

export default RisultatiCampagna
