import React from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import MobileNavigation from './DashMobileNavigation/DashMobileNavigation';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
function DashNavigation({ flags }) {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <>
            <div className='flex lg:hidden xl:hidden 2xl:hidden ml-2'>
                <MobileNavigation />
            </div>
            <div className='flex flex-row justify-start items-center mt-4 xsm:hidden sm:hidden md:hidden xmd:hidden border-b-[1px]'>
                <button
                    onClick={() => navigate("/dashboard/account/results")}
                    className={` ${pathname === "/dashboard/account/results" ? "text-primarybtn border-b-[2px] border-primarybtn font-semibold" : "text-[#667085]"} py-4 px-7 flex items-center justify-center`}>
                    Risultati
                </button>
                <button
                    onClick={() => navigate("/dashboard/account/general-settings")}
                    className={` ${pathname === "/dashboard/account/general-settings" ? "text-primarybtn border-b-[2px] border-primarybtn font-semibold" : "text-[#667085]"} py-4 px-7 flex items-center justify-center`}>
                    Impostazioni Generali
                </button>
                {flags.accountSectionSubscription && <button
                    onClick={() => navigate("/dashboard/account/subscription")}
                    disabled={pathname === "/dashboard/account/subscription/invoces" || pathname === "/dashboard/account/subscription/plans"}
                    className={` ${pathname.includes("subscription") ? "text-primarybtn border-b-[2px] border-primarybtn font-semibold" : "text-[#667085]"} py-4 px-7 flex items-center justify-center`}>
                    Abbonamento
                </button>
                }
            </div>
        </>
    )
}
export default withLDConsumer()(DashNavigation);
