import { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import formatCurrency from "../../../../Utility/formatCurrency";
import EmptyModal from "./../../../../Reusable/EmptyModal/EmptyModal";
import SpesaIcon from "../../../../Assets/Icons/spesa.svg";
import Loader from "../../../../Reusable/Loader/Loader";
import Backdrop from "./../../../../Reusable/Backdrop/Backdrop";
import * as campaignService from "../../../../apis/campaignService";
import * as helpers from "../../../../Utility/marketingHelpers";
import { toast } from "react-toastify";

// dealing with snake case keys in generating diff object
const commonKeys = ["copy", "budget"];

// get object with differences
const getDiff = (original, form) => {
  let updates = commonKeys.reduce(
    (totalDiff, key) => ({
      ...totalDiff,
      [key]: original[key] !== form[key] ? form[key] : null,
    }),
    {}
  );

  updates.endDate = !helpers.areSameDate(original.end_date, form.endDate)
    ? form.endDate
    : null;

  return updates;
};

export default function ModifyCampaignPopup({ campaignId, onClose }) {
  const [formData, setFormData] = useState(null);
  const [isTouched, setIsTouched] = useState(false);
  const [isModifying, setIsModifying] = useState(false);

  let budgets = useRef();
  let campaign = useRef();

  const updates =
    campaign.current && formData ? getDiff(campaign.current, formData) : null;
  const validationError = isTouched
    ? helpers.validateModification(updates)
    : null;
  const totalBudget = !!formData?.budget
    ? helpers.calculateTotalBudget(formData.budget, formData.endDate)
    : null;
console.log(updates) //TODO:REMOVE
  // get campaign data and fill form
  useEffect(() => {
    (async () => {
      const data = await campaignService.getCampaign(campaignId);
      if (!data) return;

      campaign.current = data;
      budgets.current = Object.values(data.suggested_budgets).map(
        b => b.budget
      );
      setFormData({
        copy: data?.copy || "",
        endDate: new Date(data?.end_date || Date.now()),
        budget: data?.budget || "0",
      });
    })();
  }, [campaignId]);

  const handleFormChange = ({ target: { name, value } }) => {
    setIsTouched(true);
    setFormData(oldData => ({ ...oldData, [name]: value }));
  };

  const handleModify = async () => {
    setIsModifying(true);
    const data = await campaignService.modifyCampaign(campaignId, updates);
    data && toast.success("La campagna è stata modificato con successo.");
    setIsModifying(false);
    onClose();
  };

  return (
    <Backdrop animation="Backdrop--entered" style={{ zIndex: "1" }}>
      <EmptyModal
        mainDivClasses="overflow-scroll w-[650px] h-max h-min-[650px] p-8 pb-0"
        showHeader={false}
      >
        {!formData && <Loader className="w-full h-full m-auto" />}

        {/* FORM */}
        {formData && (
          <div className="w-full h-full flex flex-col gap-y-8 justify-between">
            {/* ROW #1 - DESCRIPTION */}
            <div className="w-full">
              <p className="text-left font-semibold text-marketingtextcolour text-2xl">
                Descrizione
              </p>

              <textarea
                className="w-full mr-auto text-left h-[210px] resize-none rounded-md text-2xl border-2 border-marketingbtn text-marketingtextcolour bg-transparent p-4 focus:outline-none placeholder:text-marketingtextcolour"
                name="copy"
                value={formData?.copy}
                onChange={handleFormChange}
              />
            </div>

            {/* ROW #2 - END DATE + BUDGET */}
            <div className="w-full flex flex-row">
              <div className="w-1/2 flex flex-col gap-y-3">
                <p className="text-left font-semibold text-marketingtextcolour text-2xl">
                  Data Fine
                </p>
                <TextField
                  onChange={handleFormChange}
                  id="datetime-local"
                  type="datetime-local"
                  name="endDate"
                  value={helpers.createFormattedDate(formData?.endDate)}
                  sx={{ width: 250 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="w-1/2 flex flex-col gap-y-3">
                <p className="text-left font-semibold text-marketingtextcolour text-2xl">
                  Scegli Budget
                </p>
                <Select
                  name="budget"
                  value={formData.budget}
                  onChange={handleFormChange}
                >
                  {budgets?.current?.map(b => (
                    <MenuItem key={b} value={b}>
                      {formatCurrency(b)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {/* ROW #3 - TOTAL BUDGET */}
            {totalBudget && (
              <div className="w-full flex flex-row gap-x-5">
                <img className="h-10 w-10 mx-2" src={SpesaIcon} alt="" />
                <p className="font-semibold text-xl text-[#3A3A3A] my-auto">
                  Spesa Totale
                </p>
                <p className="font-normal text-xl text-[#3A3A3A] ml-auto">
                  {formatCurrency(totalBudget)}
                </p>
              </div>
            )}

            {/* ROW #4 - BUTTONS */}
            <div className="flex flex-row">
              <button
                className="bg-white hover:bg-marketingbtn text-marketingtextcolour hover:text-white rounded-md w-fit h-10 px-4 py-2 mx-auto border border-marketingbtn"
                disabled={isModifying}
                onClick={onClose}
              >
                Torna Indietro
              </button>
              <button
                className="bg-marketingbtn rounded-md w-fit h-10 px-4 py-2 mx-auto text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover elevation-1"
                disabled={isModifying || validationError || !isTouched}
                onClick={handleModify}
              >
                {isModifying ? <Loader /> : "Conferma"}
              </button>
            </div>

            {/* ROW #5 - ERROR MESSAGE */}
            {validationError && (
              <div className="w-full">
                <p className="text-red-600 text-center">{validationError}</p>
              </div>
            )}
          </div>
        )}
      </EmptyModal>
    </Backdrop>
  );
}
