import React, { useState } from 'react';
import SuggestedTemplates from '../../SocialMedia/CreatePost/SuggestedTemplates';
import GraphicEditorPortal from '../GraphicEditorPortal/GraphicEditorPortal';
import GraphicEditor from '../graphiceditor';

const TemplatesEditorWrapper = ({
    service,
    campaign,
    sectionColor,
}) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [templateAction, setTemplateAction] = useState(null);
    return (
        <div>
            {campaign && (
                <SuggestedTemplates
                    sectionColor={sectionColor}
                    campaign={campaign}
                    setIsEditorOpen={setIsEditorOpen}
                    setSelectedTemplateId={setSelectedTemplateId}
                    setTemplateAction={setTemplateAction}
                    service={service}
                />
            )}
            {isEditorOpen && (
                <GraphicEditorPortal show={isEditorOpen}>
                    <GraphicEditor
                        setIsEditorOpen={setIsEditorOpen}
                        templateId={selectedTemplateId}
                        action={templateAction}
                        section={sectionColor}
                        objective={campaign}
                    />
                </GraphicEditorPortal>
            )}
        </div>
    );
};

export default TemplatesEditorWrapper;
