import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import PersonPhoto from "../../../../Assets/Icons/MarketingPerson.png";
import BeeComponent from "../../../../Reusable/BeePlugin/BeePlugin";
import HtppIcon from "../../../../Assets/Icons/http-Marketing-Icon.svg";
import BasketIcon from "../../../../Assets/Icons/basket-Marketing-Icon.svg";
import CartIcon from "../../../../Assets/Icons/cart-Marketing-Icon.svg";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { LinearProgressGreen, LinearProgressPurple, LinearProgressRed } from "../OnProgressCampaign/AllProgressBars"
import StarIcon from "../../../../Assets/Icons/goldenStar.png";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    height: 800,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};

function CampModal({ open, handleClose }) {
    const [setImage] = React.useState("");
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="flex flex-col gap-y-8">

                        <div className="flex flex-row w-full justify-evenly divide-x-2 items-center">

                            <div className="m-5  w-fit h-fit rounded-full border-t-[12px] border-t-[#FFA425] border-r-[12px] border-r-[#E44825] rotate-[-45deg] border-b-[12px] border-b-[#BE4E9F] border-l-[12px] border-l-[#78BE40]">
                                <img src={PersonPhoto} alt="" className="rotate-[45deg]" />

                            </div>
                            <div className='flex flex-col px-3 text-left' >
                                <p className="font-semibod text-2xl  tracking-wide text-[#FFA425] " >Profilo Utente</p>
                                <p className="font-semibold text-xl  tracking-wide text-black " >Nome: <span className="font-normal">Utente Medio</span> </p>
                                <p className="font-semibold text-xl  tracking-wide text-black " >Età: <span className="font-normal">18-25</span> </p>
                                <p className="font-semibold text-xl  tracking-wide text-black" >Genere: <span className="font-normal">Uomo</span> </p>
                            </div>

                            <div className="flex flex-col px-3 text-left">
                                <p className="text-[#BE4E9F] text-2xl  font-semibold ">Social</p>
                                <p className="text-xl text-black  font-normal">Facebook</p>
                                <p className="text-xl text-black  font-normal">Instagram</p>
                                <p className="text-xl text-black  font-normal">Sito Web  </p>
                            </div>

                            <div className="flex flex-col px-3 text-left">
                                <p className="text-[#78BE40] text-2xl font-semibold ">Device</p>
                                <p className="text-xl text-black font-normal">Telefono</p>
                                <p className="text-xl text-black font-normal">Tablet</p>
                                <p className="text-xl text-black font-normal opacity-0">Tablet</p>
                            </div>


                            <div className="flex flex-col px-2 text-left">
                                <p className="text-[#E44825] text-2xl font-semibold ">Interessi</p>
                                <p className="text-xl text-black font-normal">Viaggi</p>
                                <p className="text-xl text-black font-normal">Shopping</p>
                                <p className="text-xl text-black font-normal">Creatività</p>
                            </div>
                        </div>



                        <div className="flex flex-row justify-between" >

                            <div className="flex flex-col gap-y-6 " >
                                <p className="text-left font-semibold text-marketingtextcolour text-2xl">Lista Coinvolta</p>
                                <div className="flex flex-row gap-x-2 items-center justify-center h-[50px] w-[308px] rounded-md border-2 border-[#FFA425]" >
                                    <div className="p-1 border-4 border-[#FFA425] rounded-full" >
                                        <img src={StarIcon} alt="" />
                                    </div>
                                    <p className="font-normal text-[#FFA425] " >Lista Lorem Ipsum</p>
                                </div>

                            </div>
                            <div className="flex flex-col gap-y-6 " >
                                <p className="text-left font-semibold text-marketingtextcolour text-2xl">Call To Action</p>
                                <select aria-readonly className="w-[308px] h-[50px] rounded-md text-2xl border-2 border-marketingbtn text-marketingtextcolour bg-transparent px-4 focus:outline-none">
                                    <option>Scopri di più</option >
                                    {/* <option>Scopri di più</option > */}
                                </select>

                            </div>

                            <div className="flex flex-col gap-y-4 " >
                                <p className="text-left font-semibold text-marketingtextcolour text-2xl">Tipo di Pacchetto</p>
                                <button className="w-[308px] h-[50px] text-white rounded-md bg-marketingbtn font-semibold text-lg">200 €</button>

                            </div>
                        </div>












                        <div className='w-full flex flex-col gap-y-4 ' >
                            <p className="text-left font-semibold text-marketingtextcolour text-2xl">Risultati Attesi</p>

                            <div className="flex flex-row gap-x-2" >
                                <div className='w-[60px] h-[50px] mx-auto my-auto'>
                                    <img src={HtppIcon} alt="" className='w-full h-full grow' />
                                </div>
                                <div className="flex flex-col w-full" >

                                    <div className='flex flex-row justify-between w-full' >
                                        <p className='text-md font-normal text-[#D02A2A]'>Visualizzazioni Sito</p>
                                        <p className='text-md font-normal text-[#D02A2A]'> 28%</p>
                                    </div>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressRed value={35} />
                                    </Box>
                                </div>

                            </div>
                            <div className="flex flex-row gap-x-2" >
                                <div className='w-[60px] h-[50px] mx-auto my-auto'>
                                    <img src={BasketIcon} alt="" className='w-full h-full object-contain' />
                                </div>
                                <div className="flex flex-col w-full" >

                                    <div className='flex flex-row justify-between w-full' >
                                        <p className='text-md font-normal text-[#4B2AD0]'>Aggiunti al Carrello</p>
                                        <p className='text-md font-normal text-[#4B2AD0]'> 28%</p>
                                    </div>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressPurple value={35} />
                                    </Box>
                                </div>

                            </div>
                            <div className="flex flex-row gap-x-2" >
                                <div className='w-[60px] h-[50px] mx-auto my-auto'>
                                    <img src={CartIcon} alt="" className='w-full h-full object-contain' />
                                </div>
                                <div className="flex flex-col w-full" >

                                    <div className='flex flex-row justify-between w-full' >
                                        <p className='text-md font-normal text-[#2AD044]'>Acquisti</p>
                                        <p className='text-md font-normal text-[#2AD044]'> 28%</p>
                                    </div>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressGreen value={35} />
                                    </Box>
                                </div>

                            </div>

                        </div>

                        <div className="flex flex-col gap-y-2" >
                            <p className="text-left font-semibold text-marketingtextcolour text-2xl">Descrizione</p>
                            <textarea placeholder='Type something...' className="w-full mr-auto text-left h-[160px] resize-none rounded-md text-2xl border-2 border-marketingbtn text-marketingtextcolour bg-transparent p-4 focus:outline-none placeholder:text-marketingtextcolour" />
                        </div>




                        <div className="w-full mx-auto  bg-[#F6F6F6] rounded-md ">
                            <BeeComponent setImage={setImage} />
                        </div>
                        <button onClick={() => handleClose()} className="w-[341px] h-[45px] bg-marketingbtn rounded-md text-white font-semibold text-lg mx-auto" >Avvia Campagna</button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default CampModal
