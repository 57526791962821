import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    maxHeight: "95%",
    overflow:'scroll',
};
function Modall({ open, handleClose, Pages, handlePageChoose }) {
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <p className='text-3xl text-primarytextcolour text-left mb-6'>Collega la tua pagina Facebook</p>
                        {Pages.map((item) => {
                            return (
                                <>
                                    <div className='flex flex-row justify-between px-4' >
                                        <p className='text-black font-normal w-fit tracking-wide  my-1 text-xl '>{item.name}</p>
                                        <button className='w-fit py-1 px-6 font-normal rounded-md bg-[#3366BB] text-white h-fit' onClick={() => {
                                            handlePageChoose(item.id)
                                            handleClose()
                                        }}>Scegli</button>

                                    </div>
                                </>
                            )
                        })}
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default Modall