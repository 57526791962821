import React from 'react';
import SocialMediaProvider from '../Context/SocialMediaContext';
import ScrollToTop from '../../../../Reusable/ScrollToTop';
import Step2 from './index-update-post';
import SocialMediaHeaderLottie from '../../../../Assets/Lottie/Social-Media.json';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
    InformationTopBanner = (
        await import(
            '../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner'
        )
    ).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
    InformationTopBanner = (
        await import(
            '../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner'
        )
    ).default;
}
function UpdateCampaign() {
    return (
        <>
            <SocialMediaProvider>
                <InformationTopBanner
                    pagetype='Social'
                    sectionname='Media'
                    description={"Crea i post per i tuoi social, scrivi o modifica il testo suggerito per la descrizione del tuo post, scegli gli hashtag da utilizzare fra quelli suggeriti e personalizza la grafica che hai scelto."}
                    sectionicon={SocialMediaHeaderLottie}
                />

                <ScrollToTop />

                <div className='bg-white content-center mx-auto w-[95%] bg-[#F8F8F8] rounded-md '>
                    <div className='mt-4 w-full min-h-[500px]'>
                        <Step2 />
                    </div>
                </div>
            </SocialMediaProvider>
        </>
    );
}

export default UpdateCampaign;
