import { useContext, useEffect } from "react";
import moment from "moment";

import BudgetSelection from "../../../../../../Reusable/MarketingCreateCampaign/BudgetSelection";
import * as helpers from "../../../../../../Utility/marketingHelpers";
import * as campaignService from "../../../../../../apis/campaignService";
import { MarketingCampaignContext } from "../../../../../../Contexts/MarketingCampaignContext";

const DEFAULT_PACKAGES = [{ budget: 5 }, { budget: 10 }, { budget: 15 }];

export default function MarketingBudget() {
  const {
    campaign,
    isSuggestedCampaign,
    packages,
    selectedPackage,
    handlePackagesSet,
    handlePackagesLoaded,
    handlePackageSelected,
    handleDurationSet,
  } = useContext(MarketingCampaignContext);

  useEffect(() => {
    (async () => {
      const estimates = await campaignService.getBudgetEstimates(
        campaign.value
      );
      handlePackagesLoaded(estimates?.data);

      if (isSuggestedCampaign)
        handlePackagesSet([
          {
            budget: campaign?.budget,
            estimate: campaign?.estimate,
          },
        ]);
      else handlePackagesSet(estimates?.viewData || DEFAULT_PACKAGES);

      handlePackageSelected(0);
    })();
  }, [campaign]);

  // get suggested budget
  useEffect(() => {
    (async () => {
      if (packages?.length < 1) return;

      if (isSuggestedCampaign) {
        const duration = Number(campaign.duration);
        const endDate = helpers.createFormattedDate(
          moment(Date.now()).add(duration, "day").toLocaleString()
        );
        handleDurationSet(duration, campaign.duration, endDate);
        return;
      }

      const data = await campaignService.getSuggestedBudget(campaign.value);
      if (!data) return;

      const days = helpers.getNumberOfDaysBetween(Date.now(), data.endTime);
      handlePackageSelected(
        packages.findIndex(p => Number(p.budget) === Number(data.budget))
      );
      handleDurationSet(days, days, helpers.createFormattedDate(data.endTime));
    })();
  }, [campaign, packages]);

  if (selectedPackage === null) return <></>;

  return (
    <BudgetSelection
      packages={packages}
      selectedPackage={selectedPackage}
      onPackageSelected={handlePackageSelected}
    />
  );
}
