import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../../../../../Utility/getUserData";
import setUserData from "../../../../../../Utility/setUserData";
import Lottie from "lottie-react";
import BlackCat from "../../../../../../Assets/Lottie/Black-Cat.json";
import './Stripe-Failure.css';


function Stripefailure() {

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const userData = getUserData();
      userData.paymentAccounts.stripeAccount.onBoarded = false;
      setUserData(userData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const goToPayments = () => {
    navigate("/dashboard/account/general-settings", { state: { stripeConnection: false } });
  };
  
  return (
    
    <div className="flex flex-col w-full gap-y-4 justify-center content-center items-center my-auto mt-20">

      <h1 className="flex  justify-center items-center">OH NO!</h1>

      <h4 className="flex  justify-center items-center">Qualcosa è andato storto, riprova!</h4>

      <div className="flex w-96 h-96 mx-auto justify-center items-center">

        <Lottie animationData={BlackCat} loop={true} />

      </div> 

      <button onClick={goToPayments} className="flex justify-center items-center w-96 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig">
      
        Torna alla Dashboard
        
      </button>

    </div>

  );
}

export default Stripefailure;
