import React from 'react'
import { Outlet } from "react-router-dom";
import CustomersHeaderLottie from "../../../Assets/Lottie/Customers.json";
import ScrollToTop from '../../../Reusable/ScrollToTop';
import '../../../Reusable/CSS/Page-Layout.css';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../Reusable/Informational-Top-Banner/RC/1/Informational-Top-Banner')).default;
}

function CustomerHistoryIndex() {

    return (

        <>

            <div className="pagelayout">

                <InformationTopBanner

                pagetype="Lista"
                sectionname="Clienti"
                description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'In questa sezione puoi trovare tutti i clienti che hanno già acquistato da te e ricordare le loro caratteristiche principali.'}
                sectionicon={CustomersHeaderLottie}

                />

                <ScrollToTop />

                <Outlet />

            </div>
        </>
    )
}

export default CustomerHistoryIndex
