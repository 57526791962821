import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";

import "react-multi-carousel/lib/styles.css";
import MarketingHeaderLottie from "../../../../Assets/Lottie/Marketing.json";
import { ButtonGroup } from "./Imports";
import CreateCampCard from "./CreateCampCard/CreateCampCard";
import ChooseCampCard from "./ChooseCampCard";
import CreditModal from "./CreditModal";
import Backbutton from "../../../../Reusable/Back-Button/Back-Button";
import Loader from "../../../../Reusable/Loader/Loader";

import * as campaignService from "../../../../apis/campaignService";
import * as creditService from "../../../../apis/creditService";
import { campaigns } from "../../../../Utility/marketingHelpers";
import "./CreateCampaign.css";
import { MarketingCampaignContext } from "../../../../Contexts/MarketingCampaignContext";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner"
    )
  ).default;
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1399, min: 1100 },
    items: 1,
  },
  desktopSmall: {
    breakpoint: { max: 1099, min: 835 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 834, min: 427 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 426, min: 0 },
    items: 1,
  },
};

function CreateCampaign() {
  const currentPlan = localStorage.getItem("currentPlan");
  const { state: buyerPersona } = useLocation();
  const {
    handleCampaignSet,
    handleExternalBuyerPersonaSet,
    setCurrentBalance,
  } = useContext(MarketingCampaignContext);

  // if we receeive external buyer persona (from buyer persona section), add it to marketing context
  useEffect(() => {
    if (buyerPersona) handleExternalBuyerPersonaSet(buyerPersona);
  }, [buyerPersona]);

  const [campaignsList, setCampaignsList] = useState(campaigns);
  const [suggestedCampaigns, setSuggestedCampaigns] = useState([]);

  const [currentCampaigns, setCurrentCampaigns] = useState(campaignsList);
  const [currentsuggestedCampaigns, setCurrentsuggestedCampaigns] =
    useState(suggestedCampaigns);
  const [isLoading, setIsLoading] = useState(
    false || currentsuggestedCampaigns?.length === 0,
  );

  // load the balance
  const [balance, setBalance] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        let result = await creditService.getAccountBalance();
        setCurrentBalance(Number.parseInt(result));
        if (Number.parseInt(result)) setBalance(result);
        else
          throw new Error(
            "Non è possibile ottenere il credito, riprova o ricarica la pagina.",
          );
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    })();
  }, []);

  // handles the goal selected for creting the campaign manually from scratch
  const navigate = useNavigate();

  // gets the estimates for each goal
  useEffect(() => {
    (async () => {
      const newGoals = await campaignService.getGoalsEstimates(campaignsList);
      if (newGoals) return setCampaignsList(newGoals);
    })();
  }, []);

  // get suggested campaigns from data science
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const campaignData = await campaignService.getSuggestedCampaigns();
      if (!campaignData) return setIsLoading(false);

      setSuggestedCampaigns(campaignData);
      setCurrentsuggestedCampaigns(campaignData);
      setIsLoading(false);
    })();
  }, []);

  const handleGoalSelect = (campaign) => {
    handleCampaignSet(campaign);
    navigate(campaign?.route, { state: buyerPersona });
  };

  const hasEnoughBalance = (campaignCost) =>
    balance !== null && balance !== 0 && balance >= Number(campaignCost);

  const [itemToRemove, setItemToRemove] = useState([]);

  useEffect(() => {
    if (currentPlan === "nodelivery") {
      const filteredCampaigns = currentCampaigns.filter(
        (campaign) => campaign.name !== "Delivery",
      );
      setCurrentCampaigns(filteredCampaigns);

      const filteredSuggestedCampaigns = suggestedCampaigns?.filter(
        (campaign) => campaign.name !== "Delivery",
      );

      setCurrentsuggestedCampaigns(filteredSuggestedCampaigns);
    } else if (currentPlan === "nobooking") {
      const filteredCampaigns = currentCampaigns.filter(
        (campaign) => campaign.name !== "Prenotazioni",
      );
      setCurrentCampaigns(filteredCampaigns);

      const filteredSuggestedCampaigns = suggestedCampaigns?.filter(
        (campaign) => campaign.name !== "Prenotazioni",
      );
      setCurrentsuggestedCampaigns(filteredSuggestedCampaigns);
    } else if (currentPlan === "none") {
      const filteredCampaigns = currentCampaigns.filter(
        (campaign) =>
          campaign.name !== "Delivery" && campaign.name !== "Prenotazioni",
      );
      setCurrentCampaigns(filteredCampaigns);

      const filteredSuggestedCampaigns = suggestedCampaigns.filter(
        (campaign) =>
          campaign.name !== "Delivery" && campaign.name !== "Prenotazioni",
      );
      setCurrentsuggestedCampaigns(filteredSuggestedCampaigns);
    } else {
      setCurrentCampaigns(campaignsList);
    }
  }, [currentPlan, suggestedCampaigns]);

  return (
    <div className="flex flex-col w-full">
      <InformationTopBanner
        pagetype="Sezione"
        sectionname="Pubblicità"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "All’interno di questa pagina della sezione Pubblicità puoi creare le tue nuove campagne pubblicitarie online."
        }
        sectionicon={MarketingHeaderLottie}
      />

      <div className="flex flex-col gap-y-3 w-[95%] mx-auto justify-center content-center items-center mt-4">
        <div className="self-start ">
          <Backbutton />
        </div>

        <CreditModal balance={balance} />

        {buyerPersona && (
          <div className="text-marketingtextcolour font-semibold text-xl w-full text-center py-2">
            Scegli l’obiettivo della campagna per il cliente tipo:{" "}
            {buyerPersona?.label}
          </div>
        )}

        <div className="relative px-6 w-full self-center mt-5">
          <p className="text-marketingtextcolour text-2xl text-left  font-semibold">
            Campagne Suggerite
          </p>
          <p className="text-[#AEAEAE] text-left font-normal px-1">
            Scegli fra le campagne consigliate per ottenere il massimo dei
            risultati dalla tua pubblicità online.
          </p>

          {isLoading && <Loader />}

          {!isLoading &&
            currentsuggestedCampaigns.length > 0 &&
            currentsuggestedCampaigns?.filter(
              (campaign) => !itemToRemove.includes(campaign),
            )?.length === 0 && (
              <p className="text-center font-normal p-1">
                Non ci sono campagne suggerite.
              </p>
            )}

          {/* <div className='grid grid-cols-3 px-6 gap-5 w-[95%] mx-auto mb-4 xsm:px-0 sm:px-0 md:px-4 xsm:grid-cols-1 sm:grid-cols-1'>
                        {currentsuggestedCampaigns.length > 0 &&
                            currentsuggestedCampaigns
                                ?.filter(
                                    (campaign) =>
                                        !itemToRemove.includes(campaign)
                                )
                                .map((campaign, index) => (
                                    <div
                                        className='py-6'
                                        key={`${campaign.campaign_id}-${index}`}
                                    >
                                        <CreateCampCard
                                            campaign={campaign}
                                            onSelect={handleGoalSelect}
                                            disabled={
                                                !hasEnoughBalance(
                                                    campaign.budget
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                    </div> */}

          {currentsuggestedCampaigns.length > 0 &&
            currentsuggestedCampaigns?.filter(
              (campaign) => !itemToRemove.includes(campaign),
            )?.length > 0 && (
              <Carousel
                // additionalTransfrom={0}
                autoPlay={false}
                autoPlaySpeed={9999999999}
                arrows={false}
                centerMode={false}
                draggable={false}
                infinite={false}
                minimumTouchDrag={80}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
                responsive={responsive}
                focusOnSelect={false}
                slidesToSlide={1}>
                {currentsuggestedCampaigns.length > 0 &&
                  currentsuggestedCampaigns
                    ?.filter((campaign) => !itemToRemove.includes(campaign))
                    .map((campaign, index) => (
                      <div
                        className="py-6"
                        key={`${campaign.campaign_id}-${index}`}>
                        <CreateCampCard
                          campaign={campaign}
                          onSelect={handleGoalSelect}
                          disabled={!hasEnoughBalance(campaign.budget)}
                        />
                      </div>
                    ))}
              </Carousel>
            )}
        </div>

        <div className="flex flex-col w-full px-4">
          <div className="flex">
            <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold">
              Scegli una Campagna tra le altre
            </p>
          </div>

          <div className="flex">
            <p className="text-[#AEAEAE] text-left font-normal px-5">
              Crea una campagna in totale autonomia fra le tipologie
              disponibili.
            </p>
          </div>

          <div className="grid grid-cols-1 xmd:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  px-6 gap-5 w-[95%] mx-auto mb-4 xsm:px-0 sm:px-0 md:px-4">
            {currentCampaigns.length > 0 &&
              currentCampaigns?.map((campaign) => (
                <div className="py-10 xsm:py-2 sm:py-2" key={campaign.value}>
                  <ChooseCampCard
                    campaign={campaign}
                    goOnGoal={handleGoalSelect}
                    disabled={!hasEnoughBalance(0)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCampaign;
