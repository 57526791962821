import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import data from "./Data"

function SecondSection() {
    return (
        <>
            <div className="w-[90%] h-[569px]  mx-auto rounded-md border elevation-1 flex flex-col" >
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold">Persone Raggiunte</p>
                <p className='text-[#AEAEAE] text-left font-normal px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>
                <div className='w-full h-3/4 relative'>
                    <div className='absolute my-8 ml-6' >
                        <p className='text-[80px] text-marketingtextcolour font-semibold'>122,4k</p>
                        <p className='text-[50px] text-[#EE1C1C] font-semibold'>-300</p>

                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >

                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend iconType="rect" iconSize={40} align="left" verticalAlign="bottom" layout='vertical' width={250} />
                            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    )
}

export default SecondSection
