import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';
import Caticon from '../../Assets/Images/istockphoto-1171786505-170667a.jpg';
import Routing from '../Routes';
import UserLogout from './Logout';
import './Navbarleft.css';
import Dashboardicon from '@mui/icons-material/Dashboard';
import Productsicon from '@mui/icons-material/Fastfood';
import Deliveryicon from '@mui/icons-material/LocalShipping';
import ReservationIcon from '@mui/icons-material/TableBar';
import DiscountIcon from '@mui/icons-material/Percent';
import SEOicon from '@mui/icons-material/Tune';
import Socialmediaicon from '@mui/icons-material/Groups';
// import BuyerPersonaicon from '@mui/icons-material/PersonSearch';
import Customersicon from '@mui/icons-material/People';
import Marketingicon from '@mui/icons-material/StackedBarChart';
import Newslettericon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/WhatsApp';
import Supporticon from '@mui/icons-material/SupportAgent';
import { getUserData } from '../../Utility/getUserData';
import NavButton from './NavButton';
import { ReservationContextProvider } from '../../Contexts/ReservationContext';
import { RestaurantInfoContext } from '../../Contexts/RestaurantInfoContext';
import { DeliveryContext } from '../../Contexts/DeliveryContext';
import LanguageIcon from '@mui/icons-material/Language';
import Fidelityicon from '@mui/icons-material/PaymentSharp';
import ReviewsIcon from '@mui/icons-material/Reviews';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { UserContext } from '../../Contexts/UserContext';

function Navbarleftrc() {
    const location = useLocation();
    const [value, setValue] = useState(0);
    const userData = getUserData();

    const currentPlan = localStorage.getItem('currentPlan');


    const initialList = [
        {
            icon: <Dashboardicon className='icon' />,
            label: 'Account',
            pathname: '/dashboard/account/results',
            root: '/',
            color: '#00B27A',
        },
        {
            icon: <Productsicon />,
            label: 'Menu',
            pathname: '/products',
            root: 'products',
            color: '#00B27A',
        },
        {
            icon: <Deliveryicon />,
            label: 'Delivery',
            pathname: '/delivery/pending-orders',
            root: 'delivery',
            color: '#00B27A',
            pending: 3,
        },
        {
            icon: <ReservationIcon />,
            label: 'Prenotazioni',
            pathname: '/reservation',
            root: 'reservation',
            color: '#00B27A',
            pending: 4,
        },
        {
            icon: <Customersicon />,
            label: 'Clienti',
            pathname: '/customers/all-customers',
            root: 'customers',
            color: '#00B27A',
        },
        {
            icon: <LanguageIcon />,
            label: 'Sito web',
            pathname: '/website/theme',
            root: 'website',
            color: '#FC6371',
        },
        {
            icon: <Socialmediaicon />,
            label: 'Social Media',
            pathname: '/social-media/social-manager',
            root: 'social-media',
            color: '#FC6371',
            disabled: !userData?.socialAccounts?.facebookPageResourceIdentifier,
        },
        // {
        //   icon: <TrendingUpIcon />,
        //   label: "Concorrenza",
        //   pathname: "/competition/trend",
        //   root: "competition",
        //   color: "#FC6371",
        // },
        {
            icon: <Marketingicon />,
            label: 'Pubblicità',
            pathname: '/marketing/inprogress-campaigns',
            root: 'marketing',
            color: '#6392FF',
            disabled: !userData?.socialAccounts?.facebookPageResourceIdentifier,
        },
        // {
        //   icon: <DiscountIcon />,
        //   label: "Offerte",
        //   pathname: "/discounts/all",
        //   root: "discounts",
        //   color: "#6392FF",
        // },
        {
            icon: <Newslettericon />,
            label: 'Newsletter',
            pathname: '/newsletter/completed-newsletter',
            root: 'newsletter',
            color: '#FFA563',
        },
        // {
        //   icon: <ReviewsIcon />,
        //   label: "Recensioni",
        //   pathname: "/reviews/to-reply",
        //   root: "reviews",
        //   color: "#FFA563",
        // },
        // {
        //   icon: <Fidelityicon />,
        //   label: "Fidelity",
        //   pathname: "/fidelity",
        //   root: "fidelity",
        //   color: "#FFA563",
        // },

        {
            icon: <Fidelityicon />,
            label: "graphic-editor",
            pathname: "/graphic-editor",
            root: "graphic-editor",
            color: "#FFA563",
        }
    ];

    const [sidebarItems, setSidebarItems] = useState(initialList);

    useEffect(() => {
        if (currentPlan && currentPlan === 'nodelivery') {
            setSidebarItems((prevItems) =>
                prevItems.filter((item) => item.label !== 'Delivery')
            );
        } else if (currentPlan && currentPlan === 'nobooking') {
            setSidebarItems((prevItems) =>
                prevItems.filter((item) => item.label !== 'Prenotazioni')
            );
        } else if (currentPlan && currentPlan === 'none') {
            setSidebarItems((prevItems) =>
                prevItems.filter(
                    (item) =>
                        item.label !== 'Delivery' &&
                        item.label !== 'Prenotazioni'
                )
            );
        }
    }, [currentPlan]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let path = location.pathname;
    const currentTab = () => {
        sidebarItems?.map((item, index) => {
            if (path.includes(item.root)) setValue(index + 1);
        });
    };

    useEffect(() => {
        currentTab();
    }, [location.pathname]);

    const { RestaurantInfoFULL, RestaurantInfo } = React.useContext(
        RestaurantInfoContext
    );

    const isInsideConfirmedReservations =
        location.pathname === '/reservation/confirmed' ||
        location.pathname === '/reservation/tables';

    const isInEditor = location.pathname === '/graphic-editor';
    return (
        <Box
            sx={{ bgcolor: '#FFFFFF', padding: 0 }}
            className={`navbarleft flex  justify-start items-start w-full mx-auto elevation-1 flex-row  `}
        >
            <Tabs
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                className='transition-all	duration-1000 '
                id={
                    isInsideConfirmedReservations || isInEditor
                        ? 'SideNavNoWidth'
                        : 'SideNav'
                }
            >
                <div className='flex flex-col justify-center content-end items-center my-8 gap-y-4 h-max'>
                    <div className='flex justify-center items-center max-w-[80px] max-h-[80px] rounded-md elevation-1 overflow-hidden'>
                        <img
                            src={RestaurantInfo?.logo || Caticon}
                            alt=''
                            className='bg-transparent object-contain w-full h-full rounded-md overflow-hidden'
                        />
                    </div>

                    <div className='flex flex-col'>
                        <p className='avatarp my-auto'>
                            {RestaurantInfoFULL?.name}
                        </p>
                    </div>
                </div>

                {sidebarItems?.map((item, index) => {
                    return (
                        <NavButton
                            item={item}
                            index={index}
                            key={index}
                            value={value}
                        />
                    );
                })}

                <UserLogout />
            </Tabs>

            <div
                className={`${isInsideConfirmedReservations
                    ? 'FullBodyWidth'
                    : 'bodyWidth'
                    } min-w-0 transition-all	duration-1000 `}
            >
                <Routing />
            </div>
        </Box>
    );
}

export default Navbarleftrc;
