import React from "react";
import "./ThemeCard.css";
import Card from "./Card";
// import Check from '../../../../../Assets/Icons/check.png';
import { CircularProgress } from "@mui/material";
const ThemeCard = ({
  theme,
  id,
  image,
  title,
  description,
  longDescription,
  isSelect,
  onSelect,
  handleThemeChosing,
  handleRedirectToTheWebSite,
}) => {
  // select theme
  const selectThemeHandler = () => {
    onSelect({ id, image, title, description, longDescription });
  };
  const [isLoading, SetLoading] = React.useState(false);

  async function goToTheme() {
    SetLoading(true);
    try {
      await handleRedirectToTheWebSite();
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }

  return (
    <Card className="ThemeCard w-[400px] sm:w-[95%] xsm:w-[95%] mb-6 relative">
      {isSelect && (
        <div className="absolute top-0 w-full h-10 rounded-t-[8px] bg-themesettingsbtn text-white font-semibold flex items-center justify-center">
          Tema Selezionato
        </div>
      )}

      <img
        className="ThemeCard__image object-cover h-[200px] w-full "
        src={image}
        alt={title}
      />

      <div className="ThemeCard__body">
        <div className="flex justify-start items-start">
          <h1 className="ThemeCard__body-title">{title}</h1>
        </div>

        {/*

        {isSelect && (
          <div className='flex flex-row items-center justify-between sm:flex-col xsm:flex-col gap-y-2 xsm:items-start sm:items-start'>

            <div className='flex justify-start items-start'>

              <h1 className='ThemeCard__body-title'>{title}</h1>

            </div>

            <div className='ThemeCard__selected-wrapper'>
              <img className='ThemeCard__wrapper-image' src={Check} alt='Theme choiced' />
              <p className='ThemeCard__wrapper-text'>Tema selezionato</p>
            </div>
          </div>
        )}
        {!isSelect &&

          <div className='flex justify-start items-start'>

            <h1 className='ThemeCard__body-title'>{title}</h1>

          </div>
        }

        */}

        <div className="flex justify-center items-start h-fit">
          <p className="ThemeCard__body-description text-left">{description}</p>
        </div>

        <div className="flex flex-row justify-between  w-full gap-x-2 mt-4">
          {isSelect && (
            <div className="flex w-full justify-center items-center">
              <button
                onClick={() => selectThemeHandler()}
                className="w-40 sm:w-28 xsm:w-28 sm:text-[12px] xsm:text-[12px] h-10 border-2 border-themesettingsbtn bg-transparent text-themesettingstextcolour rounded-md hover:bg-themesettingsbtnhover hover:border-themesettingsbtnhover active:border-white hover:text-white hover:elevation-1 active:shadow-innerbig"
              >
                Cambia Tema
              </button>
            </div>
          )}

          {!isSelect && (
            <div className="flex w-full justify-center items-center">
              <button
                onClick={() => handleThemeChosing(theme)}
                className="w-40 h-10 bg-themesettingsbtn text-white rounded-md hover:bg-themesettingsbtnhover elevation-1 active:shadow-innerbig"
              >
                Scegli Tema
              </button>
            </div>
          )}

          {isSelect && (
            <div className="flex w-full justify-center items-center">
              <button
                onClick={() => goToTheme()}
                className="w-40 sm:w-28 xsm:w-28 sm:text-[12px] xsm:text-[12px] h-10 bg-themesettingsbtn text-white rounded-md hover:bg-themesettingsbtnhover elevation-1 active:shadow-innerbig"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Modifica Tema"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ThemeCard;
