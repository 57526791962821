import React from 'react'
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import data from "./Data"

function FirstSection() {
    const [isActive, SetActive] = React.useState("1")
    return (
        <>
            <div className="w-[90%] mx-auto  rounded-md  elevation-1 border flex flex-row xsm:flex-col   sm:flex-col   md:flex-col   xmd:flex-col" >
                <div className="flex flex-row  xsm:flex-col   sm:flex-col   md:flex-col   xmd:flex-col w-[40%]  xsm:w-full   sm:w-full    md:w-full    xmd:w-full gap-x-2">


                    <div className='flex flex-col xsm:flex-row  sm:flex-row  md:flex-row  xmd:flex-row  h-full w-[50%] xsm:w-full  sm:w-full md:w-full  xmd:w-full  ' >
                        <button onClick={() => SetActive("1")} className={`${isActive === "1" ? "border-marketingbtn " : "border-[#E6E6E6] "} h-1/3 xsm:h-20  sm:h-20  md:h-20  xmd:h-20 w-full border-r-4 xsm:border-r-0 sm:border-r-0 md:border-r-0  xmd:border-r-0 xsm:border-b-4 sm:border-b-4 md:border-b-4  xmd:border-b-4 text-marketingtextcolour  text-xl xsm:text-base sm:text-base  font-semibold`} >Visualizzazioni Sito</button>
                        <button onClick={() => SetActive("2")} className={`${isActive === "2" ? "border-marketingbtn " : "border-[#E6E6E6] "} h-1/3 xsm:h-20  sm:h-20  md:h-20  xmd:h-20 w-full border-r-4 xsm:border-r-0 sm:border-r-0 md:border-r-0  xmd:border-r-0 xsm:border-b-4 sm:border-b-4 md:border-b-4  xmd:border-b-4 text-marketingtextcolour  text-xl xsm:text-base sm:text-base  font-semibold`} >Aggiunti al Carrello</button>
                        <button onClick={() => SetActive("3")} className={`${isActive === "3" ? "border-marketingbtn " : "border-[#E6E6E6] "} h-1/3 xsm:h-20  sm:h-20  md:h-20  xmd:h-20 w-full border-r-4 xsm:border-r-0 sm:border-r-0 md:border-r-0  xmd:border-r-0 xsm:border-b-4 sm:border-b-4 md:border-b-4  xmd:border-b-4 text-marketingtextcolour  text-xl xsm:text-base sm:text-base font-semibold`} >Acquisti</button>

                    </div>


                    <div className="flex flex-col w-[50%] xsm:w-full   sm:w-full    md:w-full    xmd:w-full     h-full " >

                        <div className='flex flex-col h-1/3 border-b-2' >
                            <p className='text-[80px] text-marketingtextcolour font-semibold'>64,3</p>
                            <p className='text-[50px] text-[#2ACB91] font-semibold'>+12,3</p>

                        </div>
                        <div className='flex flex-col h-2/3 p-5 ' >
                            <p className='text-left font-normal text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>

                        </div>

                    </div>
                </div>

                <div className="w-[60%] xsm:w-full   sm:w-full    md:w-full    xmd:w-full    h-[500px]" >
                    <ResponsiveContainer>
                        <AreaChart

                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 30,
                                bottom: 10
                            }}
                        >
                            <Legend iconType="rect" iconSize={40} align="right" verticalAlign="top" height={160} margin={{ top: 10, left: 0, right: 0, bottom: 0 }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Area
                                type="monotone"
                                dataKey="uv"
                                stackId="1"
                                stroke="#A8D3B4"
                                fill="#A8D3B4"
                            />
                            <Area
                                type="monotone"
                                dataKey="pv"
                                stackId="1"
                                stroke="#9997D9"
                                fill="#9997D9"
                            />

                        </AreaChart>
                    </ResponsiveContainer>
                </div>

            </div>
        </>
    )
}

export default FirstSection
