import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import { getUserData } from "../../../../../../Utility/getUserData";
import { toast } from "react-toastify";
import Loader from "../../../../../../Reusable/Loader/Loader";
import setUserData from "../../../../../../Utility/setUserData";
import Modall from "./Modal";
import Modal2 from "./Modal2";
import DeleteAlertButton from "../../../../../../Reusable/DeleteAlert/DeleteAlertButton";
import FacebookService from "../services/FacebookService";
import InstagramService from "../services/InstagramService";
import FacebookIcon from "../../../../../../Assets/Icons/FBicon.png"
import InstaIcon from "../../../../../../Assets/Icons/InstaIcon.png"

export default function Facebook() {
  const userData = getUserData();
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [facebookPages, SetFacebookPages] = useState([]);
  const [instagramPages, SetInstagramPages] = useState([]);
  const [fbTokenn, SetfbToken] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let scope = "pages_show_list,pages_read_engagement,pages_manage_posts,pages_manage_ads,business_management,instagram_basic,instagram_content_publish";
  if (process.env.REACT_APP_NODE_ENV === "develop") {
    const devScopes = ",read_insights,instagram_manage_insights";
    scope += devScopes;
  }
  // Check Connection when component loads
  useEffect(() => {
    const hasFBToken = userData?.socialAccounts?.facebookPageAuthToken;
    setIsConnected(!!hasFBToken);
    setIsLoading(false);
  }, []);

  const handleLoginAsync = async (mainResponse) => {
    setIsLoading(true);

    try {
      console.log("token", mainResponse);
      const { data } = await FacebookService.getFBToken(mainResponse);

      if (!data?.token) {
        throw new Error("incorrect FB Token");
      }

      console.log("token data", data);
      const FBPages = await FacebookService.getFacebookePages(mainResponse);

      console.log("token data", FBPages);
      if (!FBPages || FBPages.length < 1) {
        throw new Error("Non hai una pagina da connettere.");
      }

      SetfbToken(data.token);
      SetFacebookPages(FBPages);
      handleOpen();
    } catch (error) {
      toast.error("Errore nel login");
    } finally {
      setIsLoading(false);
    }
  };


  const handlePageChoose = async (id) => {
    const SUCCESS_MESSAGE = "La tua pagina Facebook è stata collegata con successo";
    const FAILURE_MESSAGE = "A Problem With Facebook Page";

    try {
      const data = await FacebookService.handlePageChoose(id, fbTokenn);
      if (!data) {
        throw new Error("not found");
      }

      const result = await FacebookService.FacebookPageToken(fbTokenn);
      if (!result) {
        throw new Error("result.Error");
      }

      const { socialAccounts } = userData;
      socialAccounts.facebookAuthToken = fbTokenn;
      socialAccounts.facebookPageAuthToken = data.pageToken;
      socialAccounts.facebookPageResourceIdentifier = id;

      setUserData({ ...userData, socialAccounts });
      setIsConnected(true);

      const instagram = await InstagramService.LinkedAccounts();
      SetInstagramPages(instagram?.data?.data || []);

      if (instagram?.data?.data?.length > 0) {
        setOpen2(true);
      }

      toast.success(SUCCESS_MESSAGE);
    } catch (error) {
      toast.warn(FAILURE_MESSAGE);
    }
  };


  async function handleInstagramChoose(id) {
    const { socialAccounts } = userData;
    const SUCCESS_MESSAGE = "La tua pagina Instagram è stata connessa con successo";
    const FAILURE_MESSAGE = "C'è un problema con la pagina Instagram collegata";

    try {
      const result2 = await InstagramService.InstagramBuisnessAccount();
      if (result2.data.message === "business account linked") {
        socialAccounts.instagramAccountResourceIdentifier = id;
        setUserData({ ...userData, socialAccounts });
        toast.success(SUCCESS_MESSAGE);
        setOpen2(false);
        return;
      }

      toast.success(SUCCESS_MESSAGE);
      setOpen2(false);
    } catch (error) {
      toast.info(FAILURE_MESSAGE);
      setOpen2(false);

    }

    setOpen2(false);

    
  }


  const handleLogout = async () => {
    setIsLoading(true);

    try {
      const data = await FacebookService.Logout();

      if (!data || data.Error) {
        throw new Error(data?.Error || "Unknown error occurred during logout");
      }

      const { socialAccounts } = userData;
      socialAccounts.facebookAuthToken = null;
      socialAccounts.facebookPageAuthToken = null;
      socialAccounts.facebookPageResourceIdentifier = null;
      socialAccounts.instagramBusinessAccountId = null;

      setUserData({ ...userData, socialAccounts });
      setIsConnected(false);
      toast.info("Logout. Ti sei disconnesso da Facebook/Instagram.");
      window.FB.logout();
    } catch (error) {
      console.error(error);
      toast.error(
        "Errore nel Logout. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const facebookResponseHandler = (response) => {
    if (response.accessToken) {
      handleLoginAsync(response);
    } else {
      setIsConnected(false);
      toast.error(
        "Errore nella connessione. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
      );
    }
  };


  const ConnectButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn hover:bg-primarybtnhover active:shadow-innerbig";

  const RedButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-deletebtn hover:bg-deletebtnhover active:shadow-innerbig";

  return (
    <div className="bg-gradient-to-r from-[#AA40A4] to-[#4267B2] rounded-md  py-3 h-fit items-center flex flex-row justify-between px-4 ">
      <Modall
        handleClose={handleClose}
        open={open}
        Pages={facebookPages}
        handlePageChoose={handlePageChoose}
      />
      <Modal2
        handleClose={handleClose}
        open={open2}
        Pages={instagramPages}
        handlePageChoose={handleInstagramChoose}
      />


      <div className="flex flex-row gap-x-4 items-center">
        <div className="flex flex-row gap-x-1 items-center justify-center">

          <img src={FacebookIcon} alt="" />
          <img src={InstaIcon} alt="" />
        </div>
        <p className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px] ">
          Facebook e Instagram
        </p>
      </div>

      {!isConnected ? (
        <FacebookLogin
          appId="720190449069501"
          autoLoad={false}
          authLoad={true}
          reauthenticate={true}
          fields="name,email,picture,accounts"
          scope={`${scope}`}
          callback={facebookResponseHandler}
          textButton="Connetti"
          cssClass={`${ConnectButton}`}
        />
      ) : (
        <DeleteAlertButton
          message="Sei sicuro?"
          subTitle="Questo ti farà disconnettere"
          onConfirm={() => handleLogout()}
          renderButton={(props) => (
            <button {...props} className={RedButton}>
              Disconnetti
            </button>
          )}
        />
      )}
      {/* <button className={`${isConnected ? RedButton : DisabledButton}  `} disabled={!isConnected} onClick={() => handleLogout()} > Disconnetti </button> */}
      {isLoading && (
        <div className="flex items-center   w-36 absolute bottom-0 right-0 m-4">
          <Loader />
        </div>
      )}
    </div>
  );
}
