import { CircularProgress } from "@mui/material";
import React from "react";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import Caticon from "../../../../Assets/Images/istockphoto-1171786505-170667a.jpg";
import UploadImageToCDN from "../../../../Utility/UploadImage";
function LogoChange({ PatchNewInfo, SetResData, RestaurantInfo }) {
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [images2, setImages2] = React.useState();

  async function handleChangeImage() {
    setLoading(true);
    try {
      SetResData({ ...RestaurantInfo, logo: images2 ,  favicon: images2  });
      await PatchNewInfo({ logo: images2, favicon: images2 })
      toast.success("Dati salvati con successo");
      setImages([]);
    } catch (error) {
      toast.error("");
    }
    setLoading(false);
  }

  const onChange = async (imageList) => {
    setImages(imageList);
    setLoading(true);
    try {
      const imageLink = await UploadImageToCDN(imageList[0].file);
      setImages2(imageLink);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-col gap-y-3 self-start w-1/3 sm:w-[90%] xsm:w-[90%] md:w-[70%] md:mx-auto xmd:w-[50%] items-center gap-x-4">
      <ImageUploading
        value={images}
        multiple={false}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload }) => (
          <div className="flex flex-col h-fit w-full my-5 bg-white elevation-1 rounded-md items-center justify-center">
            <div className="flex flex-col  p-5 justify-center items-center">
              <p className="font-normal text-black text-left">
                <span className="font-semibold mb-1 block text-themesettingsbtn">
                  Carica il tuo logo
                </span>
              </p>
            </div>
            <div
              onClick={onImageUpload}
              className="w-[40%] min-w-[40%] h-full hover:cursor-pointer rounded-md flex items-center justify-center bg-white elevation-1 hover:ring-2 hover:ring-themesettingsbtnhover active:shadow-innerbig active:ring-[#eeeeee] overflow-hidden"
            >
              {(!imageList || imageList.length === 0) && (
                <img
                  src={RestaurantInfo?.logo || Caticon}
                  alt=""
                  className="w-full bg-transparent overflow-hidden "
                />
              )}
              {imageList.map((image, index) => (
                <div key={index} className="">
                  <img
                    src={image["data_url"]}
                    alt=""
                    className="w-full  bg-transparent overflow-hidden"
                  />
                </div>
              ))}
            </div>
            
            <div className='flex flex-col justify-center items-center py-4'>
              <p className='font-normal text-black text-left'> Formato dell'immagine suggerita:</p>
              <p className='font-normal text-black text-left'> 512x512px oppure 256x512px</p>
            </div>
            
          </div>
        )}
      </ImageUploading>

      {images && images?.length > 0 && (
        <button
          className="w-40 h-12 bg-themesettingsbtn text-white border-2 border-themesettingsbtn hover:border-themesettingsbtnhover hover:bg-themesettingsbtnhover active:shadow-innerbig active:border-[#eeeeee] elevation-1 rounded-md "
          onClick={() => {
            handleChangeImage();
          }}
          type={"reset"}
          disabled={loading}
        >
          {loading ? <CircularProgress color="inherit" /> : "Salva logo"}
        </button>
      )}
    </div>
  );
}

export default LogoChange;
