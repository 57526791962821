import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import itLocale from 'date-fns/locale/it';
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Box from "@mui/material/Box";
import TimeInput from "react-time-picker-input";
import "../Index-Create-Post.css";


export default function PostDateComponent({ date, setDate, hours, SetHours }) {
  // const classes = useStyles();

  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6);

  return (
    <>
      <div className="flex flex-col bg-white p-5 xsm:p-0 sm:p-0 xsm:py-2 sm:py-2 md:my-12 xmd:my-12 rounded-md w-[550px] sm:w-full xsm:w-full sm:my-6">
        <p className="text-socialmediatextcolour font-semibold text-2xl">
          Programma il tuo Post
        </p>

        <div className="flex flex-row xsm:flex-col sm:flex-col">
          <div className="p-3 xsm:p-0 sm:p-0">
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
              <Box sx={{ width: 300, height: 350, overflow: "hidden" }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={date}
                  minDate={new Date(Date.now())}
                  maxDate={maxDate}
                  onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </div>
          <div className="flex flex-col items-center p-3">
            <div>
              <p className="text-socialmediatextcolour font-semibold p-2 text-md">
                Giorno Suggerito
              </p>
              <p className="bg-[#F2F2F2] text-[#B0ADAD] rounded-md py-3 px-7 text-center">
                Mer
              </p>
            </div>
            <div>
              <p className="text-socialmediatextcolour font-semibold p-2 text-md">
                Orario Suggerito
              </p>
              <p className="bg-[#F2F2F2] text-[#B0ADAD] rounded-md py-3 px-7 text-center">
                19:30
              </p>
            </div>

            <div id="hourComponent">
              <p className="text-socialmediatextcolour font-semibold p-2 text-md">
                Seleziona un orario
              </p>

              <TimeInput
                value={hours}
                hour12Format={false}
                onChange={(dateString) => SetHours(dateString)}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
