import React from "react";
import Viewicon from "../../../../../Assets/Icons/View1_light.svg";
import format from "date-fns/format";
import Trashicon from "../../../../../Assets/Icons/Trash_light.svg";
import "./Completednewslettercards.css";
import { it } from "date-fns/locale";
import icon1 from "../../../../../Assets/Icons/icn.png";
import newsletterCampaigns from "../../newsletterCampaigns";

const Completednewslettercards = ({
  id,
  image,
  title,
  goal,
  date,
  onView,
  onRemove,
  item,
  mailingListsNames,
}) => {
  function translateValueToItalian(englishValue) {
    const campaign = newsletterCampaigns.find(
      (campaign) => campaign.value === englishValue,
    );

    return campaign ? campaign.name : englishValue;
  }

  return (
    <div className="rounded-md elevation-1 bg-white flex flex-col w-full h-[330px]">
      <div className="h-[15%] bg-[#ffebd6] w-full gap-x-2 rounded-t-md flex items-center justify-center">
        <p className="font-semibold text-newsletterbtn text-lg">
          {translateValueToItalian(goal)}
        </p>
      </div>
      <div className=" w-full mx-auto h-[50%] rounded-md p-1 relative ">
        <img
          src={image}
          alt=""
          className="w-full h-full rounded-md object-cover  "></img>

        <button
          onClick={() => onRemove(id)}
          className="flex absolute top-0 right-0 m-3 w-[35px] h-[35px] items-center elevation-1 rounded-md text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:border-white active:shadow-innerbig">
          <img src={Trashicon} alt="" className="mx-auto" />
        </button>
      </div>

      <div className="w-full p-2">
        <div className="text-newslettertextcolour font-bold text-left">
          {" "}
          Nome:
          <span className="text-black ml-2 font-normal">{item.notes} </span>
        </div>
        <div className="text-newslettertextcolour font-bold text-left">
          {" "}
          Liste Coinvolte:
          <span className="text-black ml-2 font-normal">
            {" "}
            {mailingListsNames}{" "}
          </span>
        </div>
      </div>

      <div className="h-[15%] bg-[#F5F5F5] w-full gap-x-2 relative rounded-t-md flex items-center justify-center">
        {format(new Date(date), "dd MMMM uuu '-' HH:mm", { locale: it })}
      </div>
    </div>
  );
};

export default Completednewslettercards;
