import React from "react";
import BuyerPersonaHeaderLottie from "../../../../Assets/Lottie/Buyer-Persona.json";
import "../../../../Reusable/CSS/Page-Layout.css";
import WebSiteThemeNavigation from "../WebSiteThemeNavigation/WebSiteThemeNavigation";
import VisitatoreCard from "./VisitatoreCard";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner"
    )
  ).default;
}

function VisitatorePage() {  
 
  return (
    <>
      <InformationTopBanner
        pagetype="Lista"
        sectionname="Clienti"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "In questa sezione puoi vedere tutti i clienti che hanno visitato il tuo locale per analizzare le loro caratteristiche principali e preferenze"
        }
        sectionicon={BuyerPersonaHeaderLottie}
      />

      <div className="pagelayout w-full">
        <div className=" p-5 ">
          <WebSiteThemeNavigation />
        </div>
        <div className="flex px-8 flex-col w-full mt-12 mb-7">
          <p className="font-semibold text-[#114653] text-left text-xl bg-[#fc637126] p-5 rounded-md">
          Il tuo potenziale cliente
          </p>
          
          <div className="flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-x-4 justify-center items-center md:items-center mt-10">
           <VisitatoreCard/>
          </div>
        </div>

      </div>
    </>
  );
}

export default VisitatorePage;
