import Trash from "./trash.svg";
export const UploadSection = ({
  uploadedFiles,
  setUploadedFiles,
  commonProps,
  setImageIsSelected,
  setImageOpacities,
  imageOpacities,
  myUploads,
  setMyUploads,
  deleteUpload,
}) => {
  const deleteElementAtIndex = (indexToDelete, oldArray) => {
    return oldArray.filter((_, index) => index !== indexToDelete);
  };
  const addLogoToCanvas = (logo) => {
    const uniqueId = commonProps.generateUniqueId();
    if (commonProps.canvas) {
      commonProps.fabric.Image.fromURL(logo, function (img) {
        const scaleFactorX = 70 / img.width;
        const scaleFactorY = 70 / img.height;
        img.set({
          left: 30,
          top: 70,
          angle: 0,
          padding: 10,
          cornersize: 10,
          opacity: 1.0,
          hasRotatingPoint: true,
          id: uniqueId,
          scaleX: scaleFactorX,
          scaleY: scaleFactorY,
        });
        commonProps.canvas.add(img);
        commonProps.canvas.setActiveObject(img);
        setImageIsSelected(true);
        commonProps.canvas.renderAll();
        const newLayer = {
          id: uniqueId,
          type: "image",
          src: logo,
          object: img,
        };
        setImageOpacities({
          ...imageOpacities,
          [newLayer.id]: 1.0,
        });
        commonProps.setLayers([newLayer, ...commonProps.layers]);
      },
      {crossOrigin: "Anonymous"}
      );
    }
  };
  return (
    <div id="upload-section">
      {uploadedFiles?.length > 0 && (
        <div>
          <h6 className="">Utilizzati in questo template</h6>
          <div className="flex flex-wrap my-2">
            {uploadedFiles.map((file, index) => (
              <button
                key={index}
                className="relative w-[80px] h-[80px] mt-4 mr-2"
                onClick={() => addLogoToCanvas(file)}
              >
                <div className="w-full h-full overflow-hidden rounded-sm border-[1px] border-black">
                  <img
                    src={file}
                    className="w-full h-full"
                    alt={`img ${index}`}
                  ></img>
                </div>
                <div className="absolute top-0 right-0">
                  <img
                    src={Trash}
                    alt="Trash"
                    onClick={(event) => {
                      event.stopPropagation();
                      setUploadedFiles(
                        deleteElementAtIndex(index, uploadedFiles)
                      );
                    }}
                  ></img>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
      {myUploads?.length > 0 && (
        <div>
          <h6 className="">Caricamenti precedenti</h6>
          <div className="flex flex-wrap my-2">
            {myUploads.map((file, index) => (
              <button
                key={index}
                className="relative w-[80px] h-[80px] mt-4 mr-2"
                onClick={() => addLogoToCanvas(file.url)}
              >
                <div className="w-full h-full overflow-hidden rounded-sm border-[1px] border-black">
                  <img
                    src={file.url}
                    className="w-full h-full"
                    alt={`img ${index}`}
                  ></img>
                </div>
                <div className="absolute top-0 right-0">
                  <img
                    src={Trash}
                    alt="Trash"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteUpload(file._id);
                      setMyUploads(deleteElementAtIndex(index, myUploads));
                    }}
                  ></img>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
