import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { uploadCSV } from "../../../Utility/Api/ThemesCustomerAPI";
import { useDropzone } from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload";
import esempioCSV from "../../../Assets/CustomersDoc/csv_esempio.csv";
import DownloadIcon from "@mui/icons-material/Download";
import CSVReader from "react-csv-reader";
import { InboxOutlined } from "@mui/icons-material";
import Papa from "papaparse";

function UploadCSVComponent() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type === "text/csv") {
      setUploadedFile(file);
      setFilePreview(URL.createObjectURL(file));
      setError("");
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;
        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: (parsedData) => {
            onFileLoaded(parsedData.data, file.name, file);
          },
          error: (error) => {
            console.error("CSV parsing error:", error);
            setError("Errore nel parsing del file CSV");
          },
        });
      };
      reader.readAsText(file);
    } else {
      setError("Sono accettati solo file CSV");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const openModal = () => {
    setModalIsOpen(true);
  };
  const onFileLoaded = (data, fileInfo, originalFile) => {
    const file = originalFile;
    if (file && file.type === "text/csv") {
      setUploadedFile(file);
      setFilePreview(URL.createObjectURL(file));
      setError("");
      setFileName(file.name);
      setUploadedFileData(data);
    } else {
      setError("Sono accettati solo file CSV");
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setFilePreview(null);
    setUploadedFile(null);
    setError("");
  };

  const handleConfirm = async () => {
    if (uploadedFileData) {
      try {
        const response = await uploadCSV(uploadedFileData);
        console.log("File caricato con successo:", response.data);
        closeModal();
      } catch (error) {
        console.error("Errore durante il caricamento del file:", error);
      }
    }
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = esempioCSV;
    downloadLink.setAttribute("download", "esempio.csv");
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const [fileInputKey, setFileInputKey] = useState(Date.now());
  return (
    <div>
      <button
        className="h-10 rounded-md text-[#344054] font-medium textfont border-2 px-2 pr-4"
        onClick={openModal}>
        <UploadIcon className="m-1" /> Importa clienti
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Carica CSV"
        className="w-[30%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 bg-white rounded-md border shadow-sm"
        onClick={(e) => e.stopPropagation()} // Add onClick event handler to prevent event propagation
      >
        <div className="flex flex-col gap-3">
          <p className="text-2xl font-medium font-semibold textfont">
            Carica Lista Clienti
          </p>
          <p className="text-base text-[#667085] textfont ">
            {" "}
            Fai attenzione nel rispettare le colonne e le informazioni da noi
            richieste.
          </p>
        </div>

        {filePreview ? (
          <div>
            <div className=" m-6">
              <p className="text-base textfont"> {fileName}</p>
            </div>

            <div className="w-full flex flex-row gap-3">
              <button
                onClick={closeModal}
                className="w-full h-10 px-4  rounded-md textfont  border-2 border sm:-w-full xsm:w-full">
                Annulla
              </button>
              <button
                onClick={handleConfirm}
                className=" w-full h-10 px-4  rounded-md text-white textfont bg-primarybtn border-2 border-primarybtn
                               hover:bg-primarybtnhover hover:border-primarybtnhover active:border-primarybtnhover active:shadow-innerbig sm:-w-full xsm:w-full">
                Salva
              </button>
            </div>
          </div>
        ) : (
          <>
            <div {...getRootProps()} style={dropzoneStyles}>
              <label
                className="d-flex justify-content-center align-items-center btn flex flex-col items-center justify-center p-4 border-dashed border-2 border-primarybtnhover-300 rounded-md"
                style={{
                  height: "fit-content",
                  color: "#00BB7A",
                  borderColor: "#00BB7A",
                }}>
                <InboxOutlined style={{ fontSize: "30px" }} />
                Fare clic o trascinare il file CSV in questa area per caricarlo
                <CSVReader
                  inputId="CSVReader"
                  key={fileInputKey}
                  inputStyle={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "50%",
                    width: "50%",
                    cursor: "pointer",
                    opacity: 0,
                  }}
                  onFileLoaded={(data, fileInfo, originalFile) => {
                    onFileLoaded(data, fileInfo, originalFile);
                  }}
                  parserOptions={{ header: true, skipEmptyLines: true }}
                />
              </label>
            </div>
          </>
        )}

        <div className="w-full flex flex-row my-3 mt-6">
          <div className="w-2/3 flex flex-col gap-2">
            <p className="text-xl font-medium  textfont">Il nostro modello</p>
            <p className="text-sm text-[#667085] textfont ">
              {" "}
              Scarica qui il nostro modello già preimpostato per te.
            </p>
          </div>
          <div className="flex w-1/3 justify-end ">
            <button
              onClick={(e) => handleDownload(e)}
              className="my-5 h-10 px-4 bg-[#EBFFF9] text-[#008F62] rounded-md textfont">
              <DownloadIcon />
              Scarica
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const dropzoneStyles = {
  border: "1px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  width: "100%",
  height: "175px",
  margin: "20px 0px",
};

const previewStyles = {
  maxWidth: "50%",
  marginBottom: "20px",
};

export default UploadCSVComponent;
