export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { totalItems, slidesToShow, currentSlide },
  } = rest;

  return (
    <>
      <button
        className="absolute -right-5 top-[50%]  hover:bg-gray-100 rounded-full border elevation-1 p-2"
        onClick={() => next()}
        disabled={totalItems - slidesToShow - currentSlide <= 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#173659"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </button>

      <button
        className="absolute -left-6 top-[50%]  hover:bg-gray-100 rounded-full border elevation-1 p-2 "
        onClick={() => previous()}
        disabled={currentSlide === 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#173659"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M15 18l-6-6 6-6" />
        </svg>
      </button>
    </>
  );
};

export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1366,
    },
    items: 3,
    partialVisibilityGutter: 10,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1366,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};
