import axios from "axios";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import { getAuthConfig } from "../../../../../../Utility/Api/auth";

const base = `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${
  process.env.REACT_APP_AUTH_API_PATH
}`;

const InstagramService = {
  async LinkedAccounts() {
    return await axios.get(
      `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/socials/instagram/linked-accounts`,
      getAuthConfig()
    );
  },

  async InstagramBuisnessAccount() {
    try {
      const response = await Promise.race([
        axios.get(`${base}/socials/instagram/business-account`, getAuthConfig()),
        new Promise((resolve, reject) => {
          setTimeout(() => reject(new Error('Request timeout')), 5000);
        })
      ]);
      return response;
    } catch (error) {
      // Handle the error (e.g., timeout error)
      console.error(error);
      throw error; // Re-throw the error if necessary
    }
  },
};

export default InstagramService;
