import React, { useState } from "react";
import axios from "axios";
import tilby from "../../../../../../Assets/Icons/tilby.svg";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import { getUserData } from "../../../../../../Utility/getUserData";
const Tilby = () => {
  const getAuthConfig = () => ({
    headers: { authorization: `Bearer ${getUserData().token}` },
  });
  const [showForm, setShowForm] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH
    }`;
  const data = { tilbyKey: "6gUbPLWLjfLiEVTv5zPTT4H1H0NP8km6yXghwxS1" };
  const handleConnect = async () => {
    try {
      const res = axios.put(`${url}/tilby/tilbyKey`, data, getAuthConfig());
      console.log(res);
      if (res?.data && isConnect === "Disconetti") setIsConnect("Connetti");
    } catch (error) {
      console.error(error.message);
    }
  };
  const ConnectButton =
    "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn hover:bg-primarybtnhover active:shadow-innerbig";

  const RedButton =
    "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-deletebtn hover:bg-deletebtnhover active:shadow-innerbig";

  return (
    <>
      {!showForm && (
        <div className=" py-2 h-fit items-center bg-gradient-to-r from-[#333259] to-[#2B65BB] w-full rounded-md flex flex-row justify-between px-4 ">
          <div className="flex flex-row gap-x-4  items-center">
            <img src={tilby} alt="" />
            <h1 className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px] ">
              Tilby
            </h1>
          </div>

          <button
            className={isConnect ? RedButton : ConnectButton}
            onClick={handleConnect}
          // onClick={() => {
          //   if (isConnect) {
          //     disconnectTripadvisor()
          //   } else {
          //     setShowForm(prev => !prev)
          //   }
          // }}
          >
            {isConnect ? "Disconetti" : "Connetti"}
          </button>
        </div>
      )}
    </>
  );
};

export default Tilby;
