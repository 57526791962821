import React, { Component } from 'react';
import { baseUrl } from '../../Utility/Api/Urls';

export default class UserLogout extends Component {

  logout = () => {
    localStorage.clear();
    window.location.href = `https://${process.env.REACT_APP_LANDING_PREFIX}${baseUrl()}/login`;
  }

  render() {

    return (

      <div className='w-full mt-8'>

        <button onClick={this.logout} className="w-full h-[55px] text-deletebtn bg-transparent border-y-2 border-deletebtn hover:text-white hover:bg-deletebtnhover hover:border-deletebtnhover hover:elevation-1 active:border-white active:shadow-innerbig">

          Esci

        </button>

      </div>

    )
  }
}
