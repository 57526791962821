import React, { useEffect, useState } from "react";
import { getBuyerPersonas } from "../../../../apis/buyerPersonaService";
import Loader from "../../../../Reusable/Loader/Loader";
import Delivery from "../../../../Assets/Icons/deliveryimg.png";
import Reservation from "../../../../Assets/Icons/reservationimg.png";
import { Slider, ThemeProvider, createTheme } from "@mui/material";
import "./Visitatore.css";
function VisitatoreCard() {
  const [personas, setPersonas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayData, setDisplayData] = useState(null);

  useEffect(() => {
    if (!isLoading && personas && personas[0].displayData) {
      const {
        ageInfo,
        gender,
        location,
        googleInterests,
        facebookInterests,
        usesMobile,
        usesTablet,
        usesDesktop,
        image,
      } = personas[0].displayData;
      setDisplayData({
        ageInfo,
        gender,
        location,
        googleInterests,
        facebookInterests,
        usesMobile,
        usesTablet,
        usesDesktop,
        image,
      });
    }
  }, [isLoading, personas]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const personasData = await getBuyerPersonas();
        setPersonas(personasData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  // const handleSliderChange = event => {
  //  console.log("fsfs")
  // }
  const theme = createTheme({
    palette: {
      custom: {
        main: "#FC6363",
      },
    },
  });
  const marks = [
    {
      value: 0,
      label: "Poco Utilizzato",
    },

    {
      value: 100,
      label: "Molto Utilizzato",
    },
  ];

  return (
    <>
      {isLoading && <Loader />}

      <div className=" flex flex-row w-full h-max p-0 rounded-t-md gap-y-4 justify-between xsm:flex-col sm:flex-col md:flex-col xmd:flex-col ">
        {!isLoading && displayData && (
          <div className=" w-[58%] h-full flex flex-row w-full gap-y-4 xsm:flex-col xsm:w-full sm:flex-col sm:w-full md:flex-col md:w-full xmd:w-full">
            <div className="w-[40%] overflow-hidden rounded-t-md xsm:w-full sm:w-full md:w-full xmd:w-full">
              <img
                src={displayData.image}
                alt=""
                className="w-full h-[350px] object-cover"
              />
            </div>

            <div className="flex flex-col pl-3 w-[60%] h-full gap-y-3 xsm:w-full sm:w-full md:w-full xmd:w-full">
              <p className="pop600 text-[22px] text-left text-themesettingsbtn ">
                {personas[0].label}
              </p>

              <p className="text-[20px] text-left text-themesettingsbtn ">
                Età :{" "}
                <span className="pop400 text-left text-black">
                  {displayData.ageInfo}
                </span>
              </p>

              <p className="text-[20px] text-left text-themesettingsbtn">
                Genere :{" "}
                <span className="pop400 text-left text-black">
                  {displayData.gender}
                </span>
              </p>

              <p className="text-[20px] text-left text-themesettingsbtn">
                Bio :{" "}
                <span className="pop400 text-left text-black">
                  Persone che quotidianamente si spostano in un’altra città per
                  raggiungere il loro luogo di studio o lavoro.
                </span>
              </p>
              <p className="text-[20px] text-left text-themesettingsbtn">
                Comportamenti :{" "}
                <span className="pop400 text-left text-black">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et lorem augue.
                </span>
              </p>
            </div>
          </div>
        )}
        <div className=" w-[38%] h-full flex flex-col w-full gap-y-4 pl-3 xsm:flex-col xsm:w-full sm:flex-col sm:w-full md:flex-col md:w-full xmd:flex-col xmd:w-full">
          <div className="flex flex-col p-4 w-full h-full bg-[#fc637126]">
            <p className="pop600 text-[20px] text-left text-themesettingsbtn ">
              Piatti più scelti
            </p>
            <p className="text-[18px] pl-3 text-left text-black ">
              &#8226; Pizza 4 Formaggi
            </p>
            <p className="text-[18px] pl-3 text-left text-black ">
              &#8226; Pasta alla carbonara
            </p>
            <p className="text-[18px] pl-3 text-left text-black ">
              &#8226; Cotoletta alla Milanese
            </p>
          </div>
          <div className="flex flex-col w-full h-full ">
            <div className="flex flex-row items-center xsm:flex-col xsm:w-full sm:flex-col sm:w-full md:flex-col md:w-full xmd:flex-col xmd:w-full">
              {" "}
              <div className="flex flex-col w-[100px] justify-center items-center">
                <img
                  src={Delivery}
                  alt="delivery"
                  className="w-[60px] h-[60px]  object-cover"
                />
                <p className="text-themesettingsbtn">Delivery</p>
              </div>
              <ThemeProvider theme={theme}>
                <Slider
                  color="custom"
                  aria-label="Custom marks"
                  defaultValue={60}
                  step={10}
                  valueLabelDisplay="auto"
                  marks={marks}
                  // onChange={handleSliderChange}
                />
              </ThemeProvider>
            </div>
            <div className="flex flex-row items-center xsm:flex-col xsm:w-full sm:flex-col sm:w-full md:flex-col md:w-full xmd:flex-col xmd:w-full">
              {" "}
              <div className="flex flex-col justify-center items-center w-[85px]">
                <img
                  src={Reservation}
                  alt="reservation"
                  className="w-[60px] h-[60px] object-cover"
                />
                <p className="text-themesettingsbtn">Prenotazione</p>
              </div>
              <ThemeProvider theme={theme}>
                <Slider
                  color="custom"
                  aria-label="Custom marks"
                  defaultValue={20}
                  step={10}
                  valueLabelDisplay="auto"
                  marks={marks}
                  // onChange={handleSliderChange}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VisitatoreCard;
