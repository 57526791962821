import React from 'react';
import './BuyNavigation.css';
import BuyCustomLink from './CustomLink/BuyCustomLink';

const BuyNavigation = () => {

  return (

    <nav className='BuyNavigation flex w-full'>

      <ul className='BuyNavigation__list'>

        <li>
          <BuyCustomLink to='/buyer-persona/client-type'>Cliente Tipo</BuyCustomLink>
        </li>

        <li>
          <BuyCustomLink to='/buyer-persona/other-clients-types'>Altri Tipi</BuyCustomLink>
        </li>

      </ul>

    </nav>

  );
};

export default BuyNavigation;
