import React, { useState, useEffect } from "react";
import { getKeyWordsDetails, getWebSiteDetails } from "../../../../apis/generalSeoApi";
import chartError from "../../../../Assets/Icons/chartError.png";
import chartError2 from "../../../../Assets/Icons/chartError2.png";
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import { CircularProgress } from "@mui/material";
function PagesDatas({ webSiteName }) {
  const [error, setError] = useState("");
  const [isActive, SetActive] = React.useState(1)

  const [KeyWordsData, SetKeyWords] = React.useState([])
  const [timeFrame, setTimeFrame] = useState(7);
  const handleChange = (e) => setTimeFrame(e.target.value)

  const today = format(new Date(), "yyy-MM-dd")
  const theEndDate = format(sub(new Date(today), { days: timeFrame }), "yyy-MM-dd")

  const [isLoading, SetLoading] = React.useState(false)
  const [WebsiteData, SetWebData] = React.useState([])


  const getWebsiteDetails = async () => {
    SetLoading(true)
    try {
      const res = await getWebSiteDetails(webSiteName, theEndDate, today)
      if ("Error" in res) return setError("Nessun dato disponibile")
      setError(null)
      SetWebData(res.data);
    } catch (error) {
      console.log(error)
    }
    SetLoading(false)
  };

  async function getKewWords() {
    SetLoading(true)
    try {
      const data = await getKeyWordsDetails(webSiteName, theEndDate, today)
      if ("Error" in data) return setError("Nessun dato disponibile");
      setError(null)
      SetKeyWords(data.data)
    } catch (e) {
      setError(e.response);
    }
    SetLoading(false)
  }


  useEffect(() => {

    isActive === 1 && webSiteName && getKewWords()
  }, [timeFrame, isActive, webSiteName]);

  useEffect(() => {

    isActive === 2 && webSiteName && getWebsiteDetails()
  }, [timeFrame, isActive, webSiteName]);




  // if data doesn't exist


  return (
    <>
      <div className='w-[95%] mx-auto flex flex-col elevation-1 border p-8 relative'>
        <div className=' pb-4 xmd:flex lg:flex xl:flex xmd:flex-row lg:flex-row xl:flex-row  '>
          <div className=' xsm:w-full sm:w-full md:w-full w-3/4 '>
            <p className='text-seobtn font-semibold text-3xl text-left mb-2 '>
              Dettagli delle ricerche{" "}
              <span className='block font-semibold text-2xl'>
                Termini di ricerca che hanno mostrato il profilo della tua{" "}
                <br /> attività nei relativi risultati{" "}
              </span>
            </p>
          </div>
          <div className='w-1/4  xsm:w-full sm:w-full md:w-full justify-end flex xsm:justify-center sm:justify-center md:justify-center '>
            <select
              onChange={handleChange}
              value={timeFrame}
              className='w-[183px] h-[55px] rounded-md border elevation-1 bg-[#F8F8F8] focus:outline-none pl-2'
            >
              <option value={7}>1 Settimana</option>
              <option value={30}>1 Mese</option>

            </select>
          </div>
        </div>

        <div className="flex flex-row w-[80%] mx-auto items-center self-center" >
          <button
            onClick={(e) => {
              SetActive(1)
              setTimeFrame(7)
            }}
            className={` ${isActive === 1 ? "text-[#E9606F] border-[#E9606F] " : "text-gray-400 border-gray-400"}  border-b-2 pb-2 w-1/2`}
          >
            Termini di Ricerca Google
          </button>
          <button
            onClick={(e) => {
              SetActive(2)
              setTimeFrame(7)
            }}
            className={` ${isActive === 2 ? "text-[#E9606F] border-[#E9606F] " : "text-gray-400 border-gray-400"}  border-b-2 pb-2 w-1/2`}
          >
            Risultati per Link
          </button>

        </div>
        {isLoading &&
          <div className="mx-auto my-auto self-center w-full h-full items-center justify-center flex py-16  h-[357px]">
            <CircularProgress color="inherit" />
          </div>
        }



        {!isLoading && error && error !== "Nessun dato disponibile" &&
          <div className='FirstSection__container mx-auto mt-5'>
            <img src={chartError} alt='error' className="mx-auto" />
            <h1 className='FirstSection__title'>Ooops!</h1>
            <p className='FirstSection__text'>Qualcosa è andato storto</p>
          </div>
        }

        {!isLoading && error === "Nessun dato disponibile" &&
          <div className='FirstSection__container'>
            <img src={chartError2} alt='error' />
            <h1 className='FirstSection__title'>Ooops!</h1>
            <p className='FirstSection__text'>
              Al momento non ci sono dati da poter visualizzare
            </p>
          </div>
        }
        {KeyWordsData && isActive === 1 && !isLoading && !error &&
          <table className='w-full separte border-collapse table-auto h-[357px] overflow-hidden  mt-4'>
            <thead className='bg-seobtn h-[44px] text-white rounded-md divide-x-2 text-lg font-semibold '>
              <th></th>
              <th>Parole Chiave</th>
              <th>Risultati</th>
            </thead>
            <tbody className='max-h-[300px]  overflow-y-scroll '>
              {KeyWordsData &&
                KeyWordsData?.map(({ keyword, ranking_position }, index) => {
                  return (
                    <tr
                      key={index}
                      className='h-[77px] bg-[#FAFAFA] my-1 text-xl'
                    >
                      <td className='text-seobtn font-semibold'>
                        {index + 1}.
                      </td>
                      <td className='text-seobtn font-semibold'>
                        {keyword}
                      </td>
                      <td className='text-seobtn font-semibold'>
                        {ranking_position}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

        }
        {WebsiteData && isActive === 2 && !isLoading && !error &&
          <table className='w-full separte border-collapse table-auto h-[357px] overflow-hidden  mt-4'>
            <thead className='bg-seobtn h-[44px] text-white rounded-md divide-x-2 text-lg font-semibold '>
              <th>Pagina del Sito</th>
              <th>Click</th>
              <th>Tasso di Click</th>
              <th>Visualizzazioni</th>
              <th>Posizione</th>
            </thead>
            <tbody className='max-h-[300px]  overflow-y-scroll '>
              {KeyWordsData &&

                WebsiteData.map((item) => (
                  <tr
                    className='h-f-t bg-[#FAFAFA] my-1 text-xl'
                  >
                    <td className='text-seobtn font-semibold'>
                      {item?.page}
                    </td>
                    <td className='text-seobtn font-semibold'>
                      {item?.Click}
                    </td>
                    <td className='text-seobtn font-semibold'>
                      {item?.ctr}
                    </td>
                    <td className='text-seobtn font-semibold'>
                      {item?.Visualizzazioni}
                    </td>
                    <td className='text-seobtn font-semibold'>
                      {item?.Posizione.toFixed(1)}
                    </td>
                  </tr>
                ))

              }
            </tbody>
          </table>

        }
      </div>
    </>
  );
}

export default PagesDatas;
