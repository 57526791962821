import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../../Reusable/ScrollToTop';
import SeoHeaderLottie from "../../../Assets/Lottie/SEO.json";
import '../../../Reusable/CSS/Page-Layout.css';
import "./Seo.css";
import PagesDatas from './Tabs/PagesDatas';
import AnalyticsLineChart from './Charts/AnalyticsLineChart';
import axios from 'axios';
import { getUserData } from '../../../Utility/getUserData';
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import { baseUrl } from '../../../Utility/Api/Urls';
import CircularProgress from '@mui/material/CircularProgress';
import { getAuthConfig } from '../../../Utility/Api/auth';
import { Tooltip } from '@mui/material';
let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import('../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import('../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner')).default;
}

function Seo() {
  const [data, setData] = useState([])
  const [timeFrame, setTimeFrame] = useState(7)
  const handleChange = (e) => setTimeFrame(e.target.value)
  const today = format(new Date(), "yyy-MM-dd")
  const theEndDate = format(sub(new Date(today), { days: timeFrame, }), "yyy-MM-dd")
  const [isLoading, SetLoading] = React.useState(false)
  const [webSiteName, SetWebSiteName] = React.useState(null)
  async function handleRedirectToTheWebSite() {
    try {
      const res = await axios.get(`https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/pharmacies/url`, getAuthConfig())
      SetWebSiteName(res.data.url)
      fetchDataForChart(res.data.url);
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    handleRedirectToTheWebSite()
  }, [])

  const fetchDataForChart = async (url) => {
    SetLoading(true)
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/seo/getSeoData/${theEndDate}/${today}/${webSiteName || url}`,
        { headers: { Authorization: `Bearer ${getUserData()?.token}` } }
      );
      setData(res.data);
    } catch (error) {
      console.log(error)
    }
    SetLoading(false)
  };


  useEffect(() => {
    webSiteName && fetchDataForChart();
  }, [timeFrame]);


  return (

    <div className='flex flex-col'>

      <InformationTopBanner

        pagetype="Ricerca"
        sectionname="Google"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'Quanto sei conosciuto online? Scopri in che modo i tuoi clienti cercano e trovano la tua attività online e che azioni compiono sulla tua scheda Google.' : 'Quanto sei conosciuto online? Scopri in che modo i tuoi clienti cercano e trovano la tua attività online e che azioni compiono sulla tua scheda Google.'}
        sectionicon={SeoHeaderLottie}

      />

      <ScrollToTop />

      <div className="Seopage">

        <div className='flex flex-col w-full justify-center items-center gap-y-4 mt-4'>

          <div className="chartcontainerseo1 p-2 rounded-md bg-gray-100 elevation-1">

            <div className="chartcontainerseo2 p-2 rounded-md bg-white">

              <div className="flex flex-row justify-between px-4 py-4">

                <h4 className="flex titleh text-seotextcolour">Attività sul tuo sito</h4>
                <div className='w-1/4  xsm:w-full sm:w-full md:w-full justify-end flex xsm:justify-center sm:justify-center md:justify-center '>
                  <select
                    onChange={handleChange}
                    className='w-[183px] h-[55px] rounded-md border elevation-1 bg-[#F8F8F8] focus:outline-none pl-2'
                  >
                    <option value={7}>1 Settimana</option>
                    <option value={30}>1 Mese</option>
                  </select>
                </div>
              </div>
              {isLoading ?
                <div className="mx-auto my-auto self-center w-full h-full items-center justify-center flex pb-16">
                  <CircularProgress color="inherit" />
                </div>
                :
                <>
                  <AnalyticsLineChart data={data} />
                  <div className='flex flex-row gap-x-4 pb-4 w-fit mx-auto items-center' >
                    <Tooltip title="Click su sito web" placement="bottom-end" arrow>
                      <div className='flex flex-row gap-x-1 items-center ' >
                        <div className='rounded-full w-3 h-3 bg-[#00B17A]' />
                        <p className='text-black font-normal '>Click</p>
                      </div>
                    </Tooltip>
                    <Tooltip title="Visualizzazioni su Google del Sito" placement="bottom-end" arrow>
                      <div className='flex flex-row gap-x-1 items-center' >
                        <div className='rounded-full w-3 h-3 bg-[#6392FF]' />
                        <p className='text-black font-normal'>Visualizzazioni</p>
                      </div>
                    </Tooltip>
                    <Tooltip title="Percentuale di Click sul sito rispetto alle visualizzazioni" placement="bottom-end" arrow>
                      <div className='flex flex-row gap-x-1 items-center' >
                        <div className='rounded-full w-3 h-3 bg-[#FFA563]' />
                        <p className='text-black font-normal'>CTR</p>
                      </div>
                    </Tooltip>
                    <Tooltip title="Posizione nella classifica di Google" placement="bottom-end" arrow>
                      <div className='flex flex-row gap-x-1 items-center' >
                        <div className='rounded-full w-3 h-3 bg-[#FC6371]' />
                        <p className='text-black font-normal'>Posizione</p>
                      </div>
                    </Tooltip>
                  </div>
                </>
              }
            </div>

          </div>

          <div className='flex w-full'>

            <PagesDatas webSiteName={webSiteName} />

          </div>

        </div>

      </div>

    </div>

  )
}

export default Seo
