import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { RestaurantInfoContext } from "../../../../Contexts/RestaurantInfoContext";
import { UserContext } from "../../../../Contexts/UserContext";
import { useContext } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ViewPopUp({
  openView,
  setOpenView,
  item,
  setOpenEdit,
}) {
  const { RestaurantInfoFULL, RestaurantInfo } = useContext(
    RestaurantInfoContext,
  );
  const { setCurrentSocialItem, currentSocialItem } = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenView(false);
  };

  const handleOpenEdit = () => {
    setCurrentSocialItem(item);
    setOpenView(false);
    setOpenEdit(true);
  };

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };

  const date = new Date(item?.date).toLocaleDateString("it-IT", dateOptions);
  const hour = new Date(item?.date).toLocaleTimeString("it-IT", timeOptions);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openView}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <div className="flex flex-row gap-4">
            <div className="flex justify-center items-center max-w-[80px] max-h-[80px] rounded-full overflow-hidden shadow-lg">
              <img
                src={RestaurantInfo?.logo || Caticon}
                alt=""
                className="bg-transparent object-fit"
              />
            </div>
            <div className="flex flex-col">
              <p className=" my-auto font-bold ">{RestaurantInfoFULL?.name}</p>
              <div className="text-gray-500 text-sm">
                Data: {date}, Ora: {hour}
              </div>
            </div>

            <div className="flex flex-row ">
              {!item?.InstaButNotFB && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="12"
                    cy="12"
                    r="10.5"
                    fill="url(#paint0_linear_291_37747)"
                  />
                  <path
                    d="M15.9103 15.2112L16.3767 12.2476H13.4589V10.3252C13.4589 9.51428 13.8657 8.7233 15.1726 8.7233H16.5V6.20024C16.5 6.20024 15.2959 6 14.1452 6C11.7411 6 10.1712 7.4197 10.1712 9.98883V12.2476H7.5V15.2112H10.1712V22.3759C10.7075 22.458 11.2562 22.5 11.8151 22.5C12.374 22.5 12.9226 22.458 13.4589 22.3759V15.2112H15.9103Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_291_37747"
                      x1="12"
                      y1="1.5"
                      x2="12"
                      y2="22.4377"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#18ACFE" />
                      <stop offset="1" stop-color="#0163E0" />
                    </linearGradient>
                  </defs>
                </svg>
              )}

              {(item?.Insta || item?.InstaButNotFB) && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="8"
                    fill="url(#paint0_radial_291_37748)"
                  />
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="8"
                    fill="url(#paint1_radial_291_37748)"
                  />
                  <rect
                    x="1.5"
                    y="1.5"
                    width="21"
                    height="21"
                    rx="8"
                    fill="url(#paint2_radial_291_37748)"
                  />
                  <path
                    d="M17.25 7.875C17.25 8.49632 16.7463 9 16.125 9C15.5037 9 15 8.49632 15 7.875C15 7.25368 15.5037 6.75 16.125 6.75C16.7463 6.75 17.25 7.25368 17.25 7.875Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75ZM12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.5 11.7C4.5 9.17976 4.5 7.91965 4.99047 6.95704C5.4219 6.11031 6.11031 5.4219 6.95704 4.99047C7.91965 4.5 9.17976 4.5 11.7 4.5H12.3C14.8202 4.5 16.0804 4.5 17.043 4.99047C17.8897 5.4219 18.5781 6.11031 19.0095 6.95704C19.5 7.91965 19.5 9.17976 19.5 11.7V12.3C19.5 14.8202 19.5 16.0804 19.0095 17.043C18.5781 17.8897 17.8897 18.5781 17.043 19.0095C16.0804 19.5 14.8202 19.5 12.3 19.5H11.7C9.17976 19.5 7.91965 19.5 6.95704 19.0095C6.11031 18.5781 5.4219 17.8897 4.99047 17.043C4.5 16.0804 4.5 14.8202 4.5 12.3V11.7ZM11.7 6H12.3C13.5849 6 14.4583 6.00117 15.1334 6.05633C15.7911 6.11006 16.1274 6.20745 16.362 6.32698C16.9265 6.6146 17.3854 7.07354 17.673 7.63803C17.7926 7.87263 17.8899 8.20893 17.9437 8.86656C17.9988 9.54169 18 10.4151 18 11.7V12.3C18 13.5849 17.9988 14.4583 17.9437 15.1334C17.8899 15.7911 17.7926 16.1274 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C16.1274 17.7926 15.7911 17.8899 15.1334 17.9437C14.4583 17.9988 13.5849 18 12.3 18H11.7C10.4151 18 9.54169 17.9988 8.86656 17.9437C8.20893 17.8899 7.87263 17.7926 7.63803 17.673C7.07354 17.3854 6.6146 16.9265 6.32698 16.362C6.20745 16.1274 6.11006 15.7911 6.05633 15.1334C6.00117 14.4583 6 13.5849 6 12.3V11.7C6 10.4151 6.00117 9.54169 6.05633 8.86656C6.11006 8.20893 6.20745 7.87263 6.32698 7.63803C6.6146 7.07354 7.07354 6.6146 7.63803 6.32698C7.87263 6.20745 8.20893 6.11006 8.86656 6.05633C9.54169 6.00117 10.4151 6 11.7 6Z"
                    fill="white"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_291_37748"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9 17.25) rotate(-55.3758) scale(19.1397)">
                      <stop stop-color="#B13589" />
                      <stop offset="0.79309" stop-color="#C62F94" />
                      <stop offset="1" stop-color="#8A3AC8" />
                    </radialGradient>
                    <radialGradient
                      id="paint1_radial_291_37748"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.25 23.25) rotate(-65.1363) scale(16.9457)">
                      <stop stop-color="#E0E8B7" />
                      <stop offset="0.444662" stop-color="#FB8A2E" />
                      <stop offset="0.71474" stop-color="#E2425C" />
                      <stop offset="1" stop-color="#E2425C" stop-opacity="0" />
                    </radialGradient>
                    <radialGradient
                      id="paint2_radial_291_37748"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(0.375001 2.25) rotate(-8.1301) scale(29.1682 6.23877)">
                      <stop offset="0.156701" stop-color="#406ADC" />
                      <stop offset="0.467799" stop-color="#6A45BE" />
                      <stop offset="1" stop-color="#6A45BE" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </div>
          </div>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {item?.ref?.body?.message ? item?.ref?.body?.message : ""}
          </Typography>

          <div className="mt-4 mb-8">
            <img src={item?.img ? item?.img : ""} alt="Your Image" />
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="w-full mr-2 py-2 bg-white border border-gray-300 text-black rounded-md hover:bg-gray-100 focus:outline-none font-semibold text-lg">
            Indietro
          </button>

          <button
            onClick={() => handleOpenEdit()}
            className="w-full py-2  bg-[#00b27a] text-white rounded-md hover:bg-[#00b2479d] focus:outline-none font-semibold text-lg">
            Modifica
          </button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
