import React from "react";
import Allineate from "./ImageSection/icon/allineate.svg";
import Center1 from "./ImageSection/icon/centro.svg";
import Center2 from "./ImageSection/icon/centro2.svg";
import Chevron from "./ImageSection/icon/chevron-right.svg";
import Copy from "./ImageSection/icon/copy.svg";
import Duplicate from "./ImageSection/icon/duplicate.svg";
import High from "./ImageSection/icon/alto.svg";
import Layers from "./ImageSection/icon/layers.svg";
import Left from "./ImageSection/icon/sinistra.svg";
import Low from "./ImageSection/icon/basso.svg";
import Paste from "./ImageSection/icon/paste.svg";
import Right from "./ImageSection/icon/destra.svg";
import Trash from "./ImageSection/icon/trash.svg";
import Up from "./ImageSection/icon/up.svg";
import Down from "./ImageSection/icon/down.svg";
import bringToFront from "./ImageSection/icon/bringToFront.svg";
import sendToBack from "./ImageSection/icon/sendToBack.svg";

export const ThreePointsMenu = ({ canvas, copiedObject, setCopiedObject, positionThreePointsMenu, setHoveredButtonIndex, setLayers, layers, setOpenedThreePointsMenu, openThreePointsMenu, deleteObject, hoveredButtonIndex, setSelectedSection, regenerateLayers, generateUniqueId }) => {
    const functionList = [
        {
            'icon': Copy,
            'name': "Copia"
        },
        {
            'icon': Paste,
            'name': "Incolla"
        },
        {
            'icon': Duplicate,
            'name': "Duplica"
        },
        {
            'icon': Trash,
            'name': "Elimina"
        },
        {
            'icon': Layers,
            'name': "Livelli",
            'next': Chevron
        },
        {
            'icon': Allineate,
            'name': "Allinea",
            'next': Chevron
        },
    ];
    const layerList = [
        {
            'icon': bringToFront,
            'name': "Portare in primo piano",
            'action': () => handleBringToFrontClick()
        },
        {
            'icon': Up,
            'name': "Porta in alto",
            'action': () => handleBringForwardClick()
        },
        {
            'icon': Down,
            'name': "Porta in basso",
            'action': () => handleSendBackwardsClick()
        },
        {
            'icon': sendToBack,
            'name': "Mandare indietro",
            'action': () => handleSendToBackClick()
        },
        {
            'icon': Layers,
            'name': "Mostra livelli",
            'action': () => handleDisplayLayersClick()
        },
    ];
    const allineateList = [
        {
          icon: High,
          name: "Alto",
          action: () => alignTop()
        },
        {
          icon: Center1,
          name: "Centro",
          action: () => alignCenterVertically()
        },
        {
          icon: Low,
          name: "Basso",
          action: () => alignBottom()
        },
        {
          icon: Left,
          name: "Sinistra",
          action: () => alignLeft()
        },
        {
          icon: Center2,
          name: "Centro",
          action: () => alignCenterHorizontally()
        },
        {
          icon: Right,
          name: "Destra",
          action: () => alignRight()
        },
      ];
    
    const alignTop = () => {
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects.length > 1)
        {
            let minTop = activeObjects[0].top
            activeObjects.map((activeObject) => {
                if (minTop > activeObject.top)
                    minTop=activeObject.top
            })
            activeObjects.map((activeObject) => {
                activeObject.set({
                    top: minTop,
                });
            })
        }
        else
          activeObjects[0].set({
            top: 0,
          });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }
    
    const alignCenterVertically = () => {
        const activeObjects = canvas.getActiveObjects();
        let centerY = canvas.height / 2;
        if (activeObjects.length > 1)
        {
            let maxHeight = activeObjects[0].height * activeObjects[0].scaleY
            let maxHeightElementMiddle = activeObjects[0].top + ((activeObjects[0].height * activeObjects[0].scaleY) / 2)
            activeObjects.map((activeObject) => {
                if (maxHeight < (activeObject.height * activeObject.scaleY))
                    {
                        maxHeight = activeObject.height * activeObject.scaleY;
                        maxHeightElementMiddle = activeObject.top + ((activeObject.height * activeObject.scaleY) / 2)
                    }
            })
            activeObjects.map((activeObject)=>{
                activeObject.set({
                    top: maxHeightElementMiddle - (( activeObject.height * activeObject.scaleY) / 2),
                });
            })
        }
        else
          activeObjects[0].set({
            top: centerY - (activeObjects[0].height * activeObjects[0].scaleY) / 2,
          });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }

    const alignBottom = () => {
        const activeObjects = canvas.getActiveObjects();
        let canvasHeight = canvas.height;
        if (activeObjects.length > 1)
        {
            let maxTop = activeObjects[0].top + activeObjects[0].height * activeObjects[0].scaleY
            activeObjects.map((activeObject) => {
                if (maxTop < (activeObject.top + activeObject.height * activeObject.scaleY))
                    maxTop = activeObject.top + activeObject.height * activeObject.scaleY
            })
            activeObjects.map((activeObject) => {
                activeObject.set({
                    top: maxTop - activeObject.height * activeObject.scaleY,
                });
            })
        }
        else
          activeObjects[0].set({
            top: canvasHeight - activeObjects[0].height * activeObjects[0].scaleY,
          });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }

    const alignLeft = () => {
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects.length > 1)
        {
            let minLeft = activeObjects[0].left
            activeObjects.map((activeObject) => {
                if (minLeft > activeObject.left)
                    minLeft = activeObject.left
            })
            activeObjects.map((activeObject) => {
                activeObject.set({
                    left: minLeft,
                });
            })
        }
        else
          activeObjects[0].set({
            left: 0,
          });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }
    
    const alignCenterHorizontally = () => {
        const activeObjects = canvas.getActiveObjects();
        let centerX = canvas.width / 2;
        if (activeObjects.length > 1)
        {
            let maxWidth = activeObjects[0].width * activeObjects[0].scaleX
            let maxWidthElementMiddle = activeObjects[0].left + ((activeObjects[0].width * activeObjects[0].scaleX) / 2)
            activeObjects.map((activeObject) => {
                if (maxWidth < (activeObject.width * activeObject.scaleX)) {
                    maxWidth = activeObject.width * activeObject.scaleX;
                    maxWidthElementMiddle = activeObject.left + ((activeObject.width * activeObject.scaleX) / 2)
                }
            })
            activeObjects.map((activeObject) => {
              activeObject.set({
                left: maxWidthElementMiddle - (( activeObject.width * activeObject.scaleX) / 2),
              });
            })
        }
        else
        activeObjects[0].set({
          left: centerX - (activeObjects[0].width * activeObjects[0].scaleX) / 2,
        });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }
    
    const alignRight = () => {
        const activeObjects = canvas.getActiveObjects();
        let canvasWidth = canvas.width;
        if (activeObjects.length > 1) {
            let maxLeft = activeObjects[0].left + activeObjects[0].width * activeObjects[0].scaleX
            activeObjects.map((activeObject) => {
                if (maxLeft < (activeObject.left + activeObject.width * activeObject.scaleX))
                    maxLeft = activeObject.left + activeObject.width * activeObject.scaleX
            })
            activeObjects.map((activeObject) => {
                activeObject.set({
                    left: maxLeft - activeObject.width * activeObject.scaleX,
                  });
            })
        } else
          activeObjects[0].set({
            left: canvasWidth - activeObjects[0].width * activeObjects[0].scaleX,
          });
        canvas._historySaveAction();
        canvas.renderAll();
        setOpenedThreePointsMenu(false);
    }
    const handleBringForwardClick = () => {
        const activeObject = canvas.getActiveObject();
        canvas.bringForward(activeObject)
        setOpenedThreePointsMenu(false);
        setLayers([]);
        regenerateLayers()
    };
    const handleBringToFrontClick = () => {
        const activeObject = canvas.getActiveObject();
        canvas.bringToFront(activeObject)
        setOpenedThreePointsMenu(false);
        setLayers([]);
        regenerateLayers()
    };
    const handleSendToBackClick = () => {
        const activeObject = canvas.getActiveObject();
        canvas.sendToBack(activeObject)
        setOpenedThreePointsMenu(false);
        setLayers([]);
        regenerateLayers()
    };
    const handleSendBackwardsClick = () => {
        const activeObject = canvas.getActiveObject();
        canvas.sendBackwards(activeObject)
        setOpenedThreePointsMenu(false);
        setLayers([]);
        regenerateLayers()
    };    
    const handleDisplayLayersClick = () => {
        setSelectedSection(3)
    };
    const copyObject = () => {
        const activeObjects = canvas.getActiveObjects();
        if (activeObjects) {
            let tab = [] ;
            activeObjects.forEach(activeObject => {
                activeObject.clone((cloned) => {
                    tab.push(cloned);
                });
            });
            setCopiedObject(tab)
        }
    };
    const pasteObject = (objectToPastes) => {
        canvas.offHistory();
        if (objectToPastes && canvas) {
            let clones = [];
            let top = objectToPastes[0].top
            let left = objectToPastes[0].left
            objectToPastes.forEach(objectToPaste => {
            objectToPaste.clone((clonedObj) => {
                clonedObj.set({
                    left: objectToPaste.left - left + 100,
                    top: objectToPaste.top - top + 100,
                    padding: clonedObj.padding,
                    id : clonedObj.id?clonedObj.id:generateUniqueId()
                });
                canvas.add(clonedObj);
                clones.push(clonedObj)
            });
            })
            const selectionGroup = new fabric.ActiveSelection(clones, {
                canvas: canvas,
              });
            canvas.setActiveObject(selectionGroup)
            canvas.renderAll();
            setLayers([]);
            regenerateLayers()
        }
        canvas.onHistory();
    };
    const duplicateObject = () => {
        const activeObjects = canvas.getActiveObjects();
        let tab = [] ;
        if (activeObjects) {
            activeObjects.forEach(activeObject => {
                activeObject.clone((cloned) => {
                    tab.push(cloned);
                });
            });
        }
        pasteObject(tab);
    };

    return (
        <div className="z-20 absolute bg-white w-[160px] min-w-max rounded p-2 flex flex-col gap-y-2 shadow-lg"
            style={{ left: positionThreePointsMenu.x, top: positionThreePointsMenu.y }}
            onMouseLeave={() => setHoveredButtonIndex(null)}>
            {functionList.map((item, index) => (
                <button className="w-full flex items-center gap-x-3 hover:bg-[#f2f4f7] p-1 rounded"
                    key={index}
                    onMouseEnter={() => setHoveredButtonIndex(index)}
                    onClick={(event) => {
                        if (index === 0) {
                            event.stopPropagation();
                            copyObject();
                            setOpenedThreePointsMenu(!openThreePointsMenu);
                        } else if (index === 1) {
                            event.stopPropagation();
                            pasteObject(copiedObject);
                            setOpenedThreePointsMenu(!openThreePointsMenu);
                        } else if (index === 2) {
                            event.stopPropagation();
                            duplicateObject();
                            setOpenedThreePointsMenu(!openThreePointsMenu);
                        } else if (index === 3) {
                            const activeObject = canvas.getActiveObject();
                            if (activeObject) {
                                deleteObject(activeObject, null);
                                setOpenedThreePointsMenu(!openThreePointsMenu);
                            }
                        }
                    }}
                >
                    <img src={item.icon} alt="Copy">
                    </img>
                    <div className="w-full flex justify-between">
                        <span>
                            {item.name}
                        </span>
                        <img src={item.next} className="ml-4">
                        </img>
                    </div>
                </button>
            ))}
            {4 === hoveredButtonIndex && (
                <div
                className="w-40 bg-white absolute right-[0] bottom-[0] pb-[40px] rounded p-2 flex flex-col gap-y-2 shadow-lg"
                onMouseLeave={() => setHoveredButtonIndex(null)}
                onMouseEnter={() => setHoveredButtonIndex(4)}
                >
                {layerList.map((item, index) => (
                    <button
                    className="w-full h-8 flex items-center gap-x-3 hover:bg-[#f2f4f7] p-1 rounded"
                    key={index}
                    onClick={item.action}
                    >
                    <img className="w-7 h-7" src={item.icon}></img>
                    <span className="text-[12px] text-left">{item.name}</span>
                    </button>
                ))}
                </div>
            )}
            {5 === hoveredButtonIndex && (
                <div
                className="w-40 bg-white absolute right-[0] bottom-[0] rounded p-2 flex flex-col gap-y-2 shadow-lg"
                onMouseLeave={() => setHoveredButtonIndex(null)}
                >
                {allineateList.map((item, index) => (
                    <button
                    className="w-full h-8 flex items-center gap-x-3 hover:bg-[#f2f4f7] p-1 rounded"
                    key={index}
                    onClick={item.action}
                    >
                    <img className="w-7 h-7" src={item.icon}></img>
                    <span className="text-[12px] text-left">{item.name}</span>
                    </button>
                ))}
                </div>
            )}
        </div>
    )
}
