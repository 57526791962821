import { uploadImage } from '../../../../apis/editor/editor';
import { toast } from 'react-toastify';

export const uploadCanvaJSON = async (canvasJSON, commonProps) => {
    commonProps.canvas.clear();
    commonProps.canvas.offHistory();
    commonProps.setLayers([]);
    commonProps.setSelectedCanvaColor(canvasJSON.backgroundColor);
    commonProps.canvas.backgroundColor = canvasJSON.backgroundColor;
    const oldCanvasWidth = canvasJSON?.canvasSize;

    for (const object of canvasJSON.objects) {
        if (object.type === 'i-text') {
            addText(object, commonProps, oldCanvasWidth);
        } else if (object.type === 'image') {
            await addImageFromTemplate(commonProps, object, oldCanvasWidth);
        } else {
            addElementFromTemplate(object, commonProps, oldCanvasWidth);
        }
    }
    commonProps.canvas.renderAll();
    commonProps.canvas.onHistory();
    commonProps.canvas.clearHistory();
};

export const resizer = (object, canvasWidth, oldCanvasWidth) => {
    const { scaleX, scaleY, top, left } = object;
    const ruleOfThreeCalculator = (number) =>
        (canvasWidth * number) / oldCanvasWidth;
    const newScaleX = ruleOfThreeCalculator(scaleX);
    const newScaleY = ruleOfThreeCalculator(scaleY);
    const newTop = ruleOfThreeCalculator(top);
    const newLeft = ruleOfThreeCalculator(left);
    const newStrokeWidth = object.strokeWidth
        ? ruleOfThreeCalculator(object.strokeWidth)
        : null;
    object.set({
        scaleX: newScaleX,
        scaleY: newScaleY,
        top: newTop,
        left: newLeft,
        strokeWidth: newStrokeWidth,
    });
};

export const addImageLayer = (id, src, klass) => {
    return {
        id: id,
        type: 'image',
        src,
        object: klass,
    };
};
const setImageProps = (img, id) => {
    img.set({
        left: 10,
        top: 70,
        angle: 0,
        padding: 10,
        cornersize: 10,
        opacity: 1.0,
        hasRotatingPoint: true,
        id,
    });
};
const setImageStates = (img, commonProps, setImageIsSelected) => {
    commonProps.canvas.add(img);
    commonProps.canvas.setActiveObject(img);
    setImageIsSelected(true);
    commonProps.canvas.renderAll();
};
export const addImageFromEditor = async (
    event,
    commonProps,
    canvasSize,
    setImageOpacities,
    setImageIsSelected,
    setUploadedFiles,
    uploadedFiles
  ) => {
    const file = event.target.files[0];
    if (file.size > 5000000)
      toast.error("Errore: non è stato possibile salvare la tua board.Questo file non può essere caricato poiché supera i 5 MB.");
    else {
      const toasta = toast.loading("Caricamento dell'immagine in corso...");
      try {
        await uploadImage(file).then((imageUrl) => {
          commonProps.fabric.Image.fromURL(imageUrl, (img) => {
            const scale = Math.min(
              canvasSize.width / img.width,
              canvasSize.height / img.height
            );
            img.scale(scale);
            const uniqueId = commonProps.generateUniqueId();
            setImageProps(img, uniqueId);
            const newLayer = addImageLayer(uniqueId, imageUrl, img);
            commonProps.setLayers((layers) => [newLayer, ...layers]);
            setImageStates(img, commonProps, setImageIsSelected);
            setImageOpacities((imageOpacities) => ({
              ...imageOpacities,
              [newLayer.id]: 1.0,
            }));
          },
          {crossOrigin: "Anonymous"}
          );
  
          setUploadedFiles([...uploadedFiles, imageUrl]);
          if (imageUrl)
            toast.update(toasta, {
              render: "Immagine caricata con successo.",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
          else
            toast.update(toasta, {
              render: "Errore durante il caricamento dell'immagine.",
              type: "error",
              isLoading: false,
              autoClose: 2000,
            });
        });
      } catch (error) {
        toast.update(toasta, {
          render: "Errore durante il caricamento dell'immagine.",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        console.error("Error uploading image:", error);
      }
    }
  };
export const addLogoFromEditor = (
    logo,
    commonProps,
    setImageOpacities,
    setImageIsSelected
) => {
    const uniqueId = commonProps.generateUniqueId();
    const imgElement = new Image();
    imgElement.onload = function () {
        const img = new commonProps.fabric.Image(imgElement);
        setImageProps(img, uniqueId);
        img.set({
            scaleX: 0.4,
            scaleY: 0.4,
        });

        const newLayer = addImageLayer(uniqueId, logo, img);
        commonProps.setLayers((layers) => [newLayer, ...layers]);
        setImageStates(img, commonProps, setImageIsSelected);
        setImageOpacities((imageOpacities) => ({
            ...imageOpacities,
            [newLayer.id]: 1.0,
        }));
    };
    imgElement.crossOrigin = 'anonymous';
    imgElement.src = logo;
};

export const addImageFromTemplate = (
    commonProps,
    APIObject,
    oldCanvasWidth
) => {
    const createImageLayerFromObject = (object) => {
        return new Promise((resolve, reject) => {
            commonProps.fabric.Image.fromObject(object, (img) => {
                const id = commonProps.generateUniqueId();
                resizer(img, commonProps.canvas.getWidth(), oldCanvasWidth);
                commonProps.canvas.add(img);
                const newLayer = addImageLayer(id, object.src, img);
                resolve(newLayer);
            });
        });
    };
    return createImageLayerFromObject(APIObject)
        .then((layer) => {
            commonProps.setLayers((layers) => [layer, ...layers]);
        })
        .catch((error) => {
            console.error('Error adding image from template:', error);
        });
};

export const addText = (APIObject, commonProps, rest) => {
    const uniqueId = commonProps.generateUniqueId();
    let defaultObject;
    if (!APIObject) {
        defaultObject = {
            text: 'Clicca per modificare',
            left: 50,
            top: 100,
            fontFamily: rest.fontFamily,
            fontSize: 30,
            fill: 'black',
            opacity: 1.0,
            fontSyle: 'normal',
            id: uniqueId,
        };
    } else {
        APIObject.id = uniqueId;
    }

    const text = new commonProps.fabric.IText(
        defaultObject?.text || APIObject.text,
        defaultObject || APIObject
    );
    APIObject && resizer(text, commonProps.canvas.getWidth(), rest);
    commonProps.canvas.add(text);

    commonProps.canvas.renderAll();
    const newLayer = {
        id: uniqueId,
        type: 'text',
        text: text.text,
        fontFamily: text.fontFamily,
        fontSize: text.fontSize,
        fill: text.fill,
        padding: text.padding,
        object: text,
    };

    commonProps.setLayers((layers) => [newLayer, ...layers]);
    if (!APIObject) {
        commonProps.canvas.setActiveObject(text);
        const activeObject = commonProps.canvas.getActiveObject();
        const textId = activeObject.get('id');
        rest.setTextIsSelected(true);
        rest.setTextOpacities({
            ...rest.textOpacities,
            [newLayer.id]: 1.0,
        });
        activeObject.set({ fontFamily: rest.fontFamily });
        rest.setSelectedFont({
            ...rest.selectedFont,
            [textId]: rest.fontFamily,
        });
        commonProps.setActiveObjectId(
            commonProps.canvas.getActiveObject().get('id')
        );
    }
};

export const addElementLayer = (elementAdded, id, icon) => ({
    id,
    type: 'element',
    fill: elementAdded.fill,
    width: elementAdded.width,
    height: elementAdded.height,
    radius: elementAdded.radius,
    object: elementAdded,
    src: icon,
});

const setElementStates = (
    setElementIsSelected,
    setElementOpacities,
    setElementBorderWidth,
    setElementColor,
    setSelectedElementBorderColor,
    setElementBorderColor,
    newLayer,
    id
) => {
    setElementIsSelected(true);
    setElementOpacities((elementOpacities) => ({
        ...elementOpacities,
        [newLayer.id]: 1.0,
    }));
    setElementBorderWidth((elementBorderWidth) => ({
        ...elementBorderWidth,
        [newLayer.id]: 0,
    }));
    setElementColor((elementsColor) => ({
        ...elementsColor,
        [id]: '#667085',
    }));
    setSelectedElementBorderColor('#ffffff');
    setElementBorderColor((elemenstBorderColor) => ({
        ...elemenstBorderColor,
        [id]: '#ffffff',
    }));
};

export const addElementFromTemplate = (object, commonProps, oldCanvasWidth) => {
    let newLayer;
    const id = commonProps.generateUniqueId();
    if (object.type === 'path') {
        if (object.clipPath) {
            commonProps.fabric.util.enlivenObjects(
                [object.clipPath],
                (arg1) => {
                    object.clipPath = arg1[0];
                }
            );
        }
        const path = new commonProps.fabric.Path(object.path, {
            ...object,
            id,
        });
        const canvasWidth = commonProps.canvas.getWidth();

        resizer(path, canvasWidth, oldCanvasWidth);
        path.set({ id });
        commonProps.canvas.add(path);
        newLayer = addElementLayer(path, id, 'template');
    } else if (object.type === 'group') {
        commonProps.fabric.Group.fromObject(object, (newGroup) => {
            newGroup.set({
                ...object,
                id,
            });
            const canvasWidth = commonProps.canvas.getWidth();

            resizer(newGroup, canvasWidth, oldCanvasWidth);
            commonProps.canvas.add(newGroup);
            newLayer = addElementLayer(newGroup, id, 'template');
        });
    } else if (object.type === 'rect') {
        const rect = new commonProps.fabric.Rect({
            ...object,
            id,
        });
        const canvasWidth = commonProps.canvas.getWidth();

        resizer(rect, canvasWidth, oldCanvasWidth);
        commonProps.canvas.add(rect);
        newLayer = addElementLayer(rect, id, 'template');
        rect.set({ id });
    } else if (object.type === 'circle') {
        const circle = new commonProps.fabric.Circle({
            ...object,
            id,
        });
        const canvasWidth = commonProps.canvas.getWidth();

        resizer(circle, canvasWidth, oldCanvasWidth);
        commonProps.canvas.add(circle);
        newLayer = addElementLayer(circle, id, 'template');
    }
    if (newLayer) {
        console.log(newLayer);
        commonProps.setLayers((layers) => [newLayer, ...layers]);
    }
};

export const addElementFromEditor = (
    icon,
    commonProps,
    setElementIsSelected,
    setElementOpacities,
    setElementBorderWidth,
    setElementColor,
    setSelectedElementBorderColor,
    setElementBorderColor
) => {
    const uniqueId = commonProps.generateUniqueId();
    commonProps.fabric.loadSVGFromURL(icon, (objects, options) => {
        const elementAdded = commonProps.fabric.util.groupSVGElements(
            objects,
            options
        );

        elementAdded.set({
            left: 10,
            top: 10,
            id: uniqueId,
            opacity: 1.0,
            padding: 0,
            scaleX: 3,
            scaleY: 3,
            strokeUniform: true,
            fill: '#667085',
        });
        commonProps.canvas.add(elementAdded);
        commonProps.canvas.setActiveObject(elementAdded);
        commonProps.canvas.renderAll();

        const newLayer = addElementLayer(elementAdded, uniqueId, icon);
        commonProps.setLayers((layers) => [newLayer, ...layers]);
        commonProps.setActiveObjectId(uniqueId);

        setElementStates(
            setElementIsSelected,
            setElementOpacities,
            setElementBorderWidth,
            setElementColor,
            setSelectedElementBorderColor,
            setElementBorderColor,
            newLayer,
            uniqueId
        );
    });
    return;
};

export const addTextLayer = (id, klass) => {
    return {
        id: id,
        type: 'text',
        text: klass.text,
        fontFamily: klass.fontFamily,
        fontSize: klass.fontSize,
        fill: klass.fill,
        padding: klass.padding,
        object: klass,
    };
};
