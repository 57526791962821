import React, { useState, useEffect } from 'react';
import Horizontal from "./icon/corner-down-right.svg";
import Opacity from "./icon/opacity.svg";
import Vertical from "./icon/corner-right-up.svg";
import './style.css';

export const RotateButton = ({ canvas, setImageOpacities, updateLayer, imageOpacities, activeObjectId }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMouseLeave = () => {
        setAnchorEl(null);
    };
    const handleMouseEnter2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleMouseLeave2 = () => {
        setAnchorEl2(null);
    };
    const [opacity, setOpacity] = useState(1.0);
    const flipImageHorizontally = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'image') {
            activeObject.set('flipX', !activeObject.flipX);
            canvas.renderAll();
        }
    };
    const flipImageVertically = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'image') {
            activeObject.set('flipY', !activeObject.flipY);
            canvas.renderAll();
        }
    };
    const handleRotateX = (event) => {
        flipImageHorizontally();
    };
    const handleRotateY = (event) => {
        flipImageVertically();
    };
    const changeImageOpacity = (newOpacity) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'image') {
            const imageId = activeObject.get('id');
            activeObject.set({ opacity: newOpacity });
            canvas.renderAll();
            setImageOpacities({
                ...imageOpacities,
                [imageId]: newOpacity,
            });
        }
        updateLayer(activeObject);
    };
    const handleOpacityChange = (event) => {
        const newOpacity = event.target.value / 100;
        setOpacity(newOpacity);
        changeImageOpacity(newOpacity);
        const value = ((newOpacity - event.target.min / 100) / (event.target.max / 100)) * 100;
        event.target.style.setProperty('--value', value + '%');
    };
    useEffect(() => {
        if (activeObjectId) {
            setOpacity(imageOpacities[activeObjectId] || 0);
        }
    }, [imageOpacities, activeObjectId]);
    return (
        <div className="text-[#667085] flex"
        >
            <div className='relative'>
                <button className="p-1" onMouseEnter={(e) => {
                    handleMouseEnter(e);
                }}
                    onMouseLeave={(e) => {
                        handleMouseLeave(e);
                    }}
                >
                    Capovolgi
                </button>
                {open &&
                    <div className="text-black absolute left-[-30px] bottom-[-70px] min-w-max z-20 rounded bg-white px-2 py-2 flex flex-col" onMouseEnter={(e) => handleMouseEnter(e)}
                        onMouseLeave={(e) => handleMouseLeave(e)}>
                        <div className=''>
                            <button className='flex items-center gap-x-1 hover:bg-[#f2f4f7] p-1 rounded' onClick={handleRotateX}>
                                <img src={Horizontal} alt="Horizontal">
                                </img>
                                <span className=''>
                                    Capovolgi orizzontalmente
                                </span>
                            </button>
                        </div>
                        <div className=''>
                            <button className='flex items-center gap-x-1 hover:bg-[#f2f4f7] p-1 rounded w-full' onClick={handleRotateY}>
                                <img src={Vertical} alt="Vertical">
                                </img>
                                <span className=''>
                                    Capovolgi verticalmente
                                </span>
                            </button>
                        </div>
                    </div>
                }
            </div>
            <div className='relative'>
                <button className='ml-2' >
                    <img src={Opacity} alt="Opacity" onMouseEnter={(e) => {
                        handleMouseEnter2(e);
                    }}
                        onMouseLeave={(e) => {
                            handleMouseLeave2(e);
                        }}>
                    </img>
                </button>
                {open2 &&
                    <div className="text-black absolute left-[-80px] bottom-[-65px] min-w-max z-20 rounded bg-white px-2 py-2 flex flex-col" onMouseEnter={(e) => handleMouseEnter2(e)}
                        onMouseLeave={(e) => handleMouseLeave2(e)}>
                        <div className='flex justify-between'>
                            <span>
                                Opacità
                            </span>
                            <div className='flex justify-center items-center p-1 bg-[#f9fafb] rounded-md'>
                                {Math.round(opacity * 100)}%
                            </div>
                        </div>
                        <div className=''>
                            <input
                                type="range"
                                id="volume"
                                name="volume"
                                min="0"
                                max="100"
                                step="1"
                                value={opacity * 100}
                                onChange={handleOpacityChange}
                                style={{ '--value': `${opacity * 100}%` }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}