import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";
import beeHacks from "../Utility/beeTemplateHacks";
import * as userService from "./userService";
import { getUserId } from "../Utility/getUserData";

const DEFAULT_ERROR = "C'è stato un errore. Riprova o ricarica la pagina.";

const BASE_ENDPOINT = `https://${
  process.env.REACT_APP_DASHBOARD_PREFIX
}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;
const BASE_TEMPLATES_ENDPOINT = `${BASE_ENDPOINT}/templates`;
const BASE_USER_TEMPLATES_ENDPOINT = `${BASE_ENDPOINT}/userTemplates`;
const BASE_FAVORITE_TEMPLATES_ENDPOINT = `${BASE_ENDPOINT}/pharmacist/favoriteTemplates`;

const getBaseThemesTemplatesEndpoint = () =>
  `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${
    process.env.REACT_APP_THEMES_API_PATH
  }/automaticMails`;

export const Type = Object.freeze({
  Marketing: "marketing",
  Social: "social",
  Newsletter: "newsletter",
  AutoMail: "autoMail",
});

// --------- Retrieving Templates ------------

/**
 * Get saved social templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getSocialTemplates(goal) {
  return await getTemplates(Type.Social, goal);
}

/**
 * Get saved marketing templates by goal and persona
 * @param {String} goal Goal of the template
 * @param {String} persona Buyer Persona of the template
 * @returns templates
 */
export async function getMarketingTemplates(goal, persona) {
  return await getTemplates(Type.Marketing, goal, persona);
}

/**
 * Get saved newsletter templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getNewslettersTemplates(goal) {
  return await getTemplates(Type.Newsletter, goal);
}

/**
 * Get saved AutoMail templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getAutoMailTemplates(goal) {
  return await getTemplates(Type.AutoMail, goal);
}

/**
 * Get saved AutoMail templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getSingleAutoMailTemplate(goal) {
  try {
    const url = `${BASE_TEMPLATES_ENDPOINT}?limit=1&goal=${goal}&type=${Type.AutoMail}&includeJson=true`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    const theOneAndOnlyTemplate =
      Array.isArray(data) && data.length === 1 ? data[0] : null;
    return theOneAndOnlyTemplate;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere il template predefinito."
    );
    console.error(error);
  }
}

/**
 * Get templates
 * @param {String} type Type of the template
 * @param {String} goal Goal of the template
 * @param {String} persona Buyer Persona of the template
 * @returns created tempalte data
 */
async function getTemplates(type, goal = "all", persona = "all") {
  try {
    // const personaQuery = persona ? `&persona=${persona}` : '';
    const personaQuery = "";
    const url = `${BASE_TEMPLATES_ENDPOINT}?limit=50&goal=${goal}&type=${type}${personaQuery}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere i template salvati."
    );
    console.error(error);
  }
}

/**
 * Get single template (with json)
 * @param {Object} template Template object
 * @param {Boolean} fill Fills tempalte with user logo + info
 * @returns Template data
 */
export async function getFullTemplate(template, fill = true) {
  try {
    const url = `${
      template.owner ? BASE_USER_TEMPLATES_ENDPOINT : BASE_TEMPLATES_ENDPOINT
    }/${template?._id}`;

    const { data } = await axios.get(url, getAuthConfig());

    if (!data || !data.json) throw new Error("No data received.");

    return !template.owner && fill ? await fillTemplate(data) : data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere il template scelto."
    );
    console.error(error);
  }
}

// --------- Retrieving User Templates ------------

/**
 * Get user's saved social templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getUserSocialTemplates(goal) {
  return await getUserTemplates(Type.Social, goal);
}

/**
 * Get user's saved marketing templates by goal and persona
 * @param {String} goal Goal of the template
 * @param {String} persona Buyer Persona of the template
 * @returns templates
 */
export async function getUserMarketingTemplates(goal, persona) {
  return await getUserTemplates(Type.Marketing, goal, persona);
}

/**
 * Get user's saved newsletter templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getUserNewslettersTemplates(goal) {
  return await getUserTemplates(Type.Newsletter, goal);
}

/**
 * Get user's saved AutoMail templates by goal
 * @param {String} goal Goal of the template
 * @returns templates
 */
export async function getUserAutoMailTemplates(goal) {
  return await getUserTemplates(Type.AutoMail, goal);
}

/**
 * Gets the automatic mail template for the specified goal
 * @param {String} goal goal/type of the template
 */
export const getCustomAutomatedMailByGoal = async goal => {
  try {
    if (!goal)
      throw new Error(
        "Missing 'goal' parameter for getSingleAutomatedMailByGoal api call"
      );

    const url = `${getBaseThemesTemplatesEndpoint()}?type=${goal}&includeJson=true&limit=1`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received");

    const theOneAndOnlyTemplate =
      Array.isArray(data) && data.length === 1 ? data[0] : null;
    return theOneAndOnlyTemplate;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get user templates
 * @param {String} type Type of the template
 * @param {String} goal Goal of the template
 * @param {String} persona Buyer persona
 * @returns created tempalte data
 */
async function getUserTemplates(type, goal = "all", persona = "all") {
  try {
    // const personaQuery = persona ? `&persona=${persona}` : "";
    // const url = `${BASE_USER_TEMPLATES_ENDPOINT}?goal=${goal}&type=${type}${personaQuery}`;
    const url = `${BASE_USER_TEMPLATES_ENDPOINT}?goal=${goal}&type=${type}&limit=200`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere i template salvati."
    );
    console.error(error);
  }
}

/**
 * Get user template
 * @param {String} id Id
 */
export async function getUserTemplate(id) {
  try {
    const url = `${BASE_USER_TEMPLATES_ENDPOINT}/${id}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere i template salvati."
    );
    console.error(error);
  }
}

// --------- Retrieving Favorite Templates ------------

/**
 * Get favourite templates
 */
export async function getFavoriteTemplates() {
  try {
    const { data } = await axios.get(
      BASE_FAVORITE_TEMPLATES_ENDPOINT,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    // toast.error(
    //   "C'è stato un errore. Non è possibile ottenere i template preferiti."
    // );
    console.error(error);
  }
}

/**
 * add to favourite templates
 * @param {String} id id of the template
 */
export async function addToFavoriteTemplates(id) {
  try {
    const url = `${BASE_FAVORITE_TEMPLATES_ENDPOINT}/${id}`;
    const { data } = await axios.patch(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere i template salvati."
    );
    console.error(error);
  }
}

/**
 * remove from favourite templates
 * @param {String} id id of the template
 */
export async function removeFromFavoriteTemplates(id) {
  try {
    const url = `${BASE_FAVORITE_TEMPLATES_ENDPOINT}/${id}`;
    const { data } = await axios.delete(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(
      "C'è stato un errore. Non è possibile ottenere i template salvati."
    );
    console.error(error);
  }
}

// --------- Creating User Templates ------------

/**
 * Creates a user template of marketing type
 * @param {Object} payload Payload for creating a user template
 * @returns created tempalte data
 */
export async function createUserMarketingTemplate(payload) {
  return await createUserTemplate(payload, Type.Marketing);
}

/**
 * Creates a user template of social media type
 * @param {Object} payload Payload for creating a user template
 * @returns created tempalte data
 */
export async function createUserSocialTemplate(payload) {
  return await createUserTemplate(payload, Type.Social);
}

/**
 * Creates a user template of newseltter type
 * @param {Object} payload Payload for creating a user template
 * @returns created tempalte data
 */
export async function createUserNewsletterTemplate(payload) {
  return await createUserTemplate(payload, Type.Newsletter);
}

/**
 * Creates a user template of autoMail type
 * @param {Object} payload Payload for creating a user template
 * @returns created tempalte data
 */
export async function createUserAutoMailTemplate(payload) {
  try {
    const body = {
      type: payload?.goal,
      json: payload?.json,
      image: payload?.image,
    };
    const { data } = await axios.post(
      getBaseThemesTemplatesEndpoint(),
      body,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

/**
 * Creates a user template of autoMail type
 * @param {String} id Id of the template
 * @param {Object} payload Payload for creating a user template
 * @returns created tempalte data
 */
export async function updateUserAutoMailTemplate(id, payload) {
  try {
    const body = {
      json: payload?.json,
      image: payload?.image,
    };
    const { data } = await axios.patch(
      `${getBaseThemesTemplatesEndpoint()}/${id}`,
      body,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

/**
 * Creates a user template
 * @param {Object} payload Payload for creating a user template
 * @param {String} type Type of the template
 * @returns created tempalte data
 */
async function createUserTemplate(payload, type) {
  if (!payload)
    return console.error(
      `Invalid payload ${payload} received at createUserTemplate in templateService.js.`
    );

  try {
    const body = {
      type,
      name: payload.name || `Template-${new Date().toLocaleDateString()}`,
      json: payload.json,
      goal: payload.goal,
      persona: payload.buyerPersonaValue || "all",
      image: payload.image,
      subject: payload.subject,
      notes: payload.notes,
      mailingListIds: payload.mailingListIds,
    };

    const { data } = await axios.post(
      BASE_USER_TEMPLATES_ENDPOINT,
      body,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

/**
 * Update user template with uploaded image
 * @param {String} id Id of the template
 * @param {String} imageUrl Url of the hosted image
 */
export async function updateUserTemplateImage(id, imageUrl) {
  try {
    const url = `${BASE_USER_TEMPLATES_ENDPOINT}/${id}/image`;
    const payload = { image: imageUrl };
    const { data } = await axios.patch(url, payload, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    // toast.error(
    //   "C'è stato un errore. Non è possibile ottenere i template salvati."
    // );
    console.error(error);
  }
}

export const isUserTemplate = template => !!template.owner;

/**
 * Delete user template
 * @param {String} id Id of the template
 */
export async function deleteUserTemplate(id) {
  try {
    const url = `${BASE_USER_TEMPLATES_ENDPOINT}/${id}`;
    const { data } = await axios.delete(url, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

// --------- Images ------------

/**
 * Generate base64 image
 * @param {Object} payload Payload for creating the image
 * @returns created tempalte data
 */
export async function generateBase64Image(payload) {
  if (!payload || !payload.json || !payload.width)
    return console.error(
      `Invalid payload ${payload} received at generateBase64Image in templateService.js.`
    );

  try {
    const { data } = await axios.post(
      `${BASE_ENDPOINT}/bee/jsonToImage`,
      payload,
      getAuthConfig()
    );

    if (!data) throw new Error("No data received.");

    const imageFormat = payload?.format === "png" ? "png" : "jpeg";
    return `data:image/${imageFormat};base64,${data.data}`;
  } catch (error) {
    toast.error(DEFAULT_ERROR);
    console.error(error);
  }
}

/**
 * Update template with uploaded image
 * @param {String} id Id of the template
 * @param {String} imageUrl Url of the hosted image
 */
export async function updateTemplateImage(id, imageUrl) {
  try {
    const url = `${BASE_TEMPLATES_ENDPOINT}/${id}/image`;
    const payload = { image: imageUrl };
    const { data } = await axios.patch(url, payload, getAuthConfig());

    if (!data) throw new Error("No data received.");

    return data;
  } catch (error) {
    // toast.error(
    //   "C'è stato un errore. Non è possibile ottenere i template salvati."
    // );
    console.error(error);
  }
}

// --------- Hacking Templates ------------

/**
 * Fill templates with user logo
 * @param {Array} rawTemplates Raw templates json
 * @returns Filled templates
 */
export async function fillTemplatesWithLogo(rawTemplates) {
  const logoUrl = await userService.getLogo();

  return rawTemplates?.map((t, i) => {
    // get user logo and insert it
    let json = logoUrl ? beeHacks.setLogo(t.json, logoUrl) : t;

    return {
      json,
      image: null,
      title: `Template #${i + 1}`,
    };
  });
}

/**
 * Fill templates with user info
 * @param {Array} templates Raw templates json
 * @returns Filled templates
 */
export async function fillTemplates(templates) {
  const info = await userService.getRestaurantInfo();
  if (!info) return templates;

  const pharmacyData = await userService.getPharmacyData();

  return templates.map(t => {
    const template = beeHacks.setMarketingData(t.json, {
      logoUrl: info.theme_photos?.logo,
      website: pharmacyData.website || "",
      contact: info.formatted_phone_number || "",
      address: info.formatted_address || "",
    });

    return { ...t, json: template };
  });
}

/**
 * Fill a template with user info
 * @param {Object} template Raw template json
 * @returns Filled templates
 */
export async function fillTemplate(json) {
  const pharmacyData = await userService.getPharmacyData();
  if (!pharmacyData) return json;

  const template = beeHacks.setMarketingData(json, {
    logoUrl: pharmacyData.logoUrl,
    website: pharmacyData.website,
    contact: pharmacyData.contact,
  });
  return template;
}
