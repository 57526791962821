import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../../../Reusable/CSS/Page-Layout.css";
import BuyerPersonaHeaderLottie from "../../../../Assets/Lottie/Buyer-Persona.json";
import OtherPersonaCard from "./OtherPersonaCard";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import Loader from "../../../../Reusable/Loader/Loader";
import BuyNavigation from "../BuyNavigation/BuyNavigation";
import useOtherPersonas from "../../../../Hooks/useOtherPersonas";
import { getCityAndCountry } from "../../../../apis/userService";
import { useState } from "react";
import "./OtherPersona.css";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner"
    )
  ).default;
}

function OtherPersona() {
  const navigate = useNavigate();

  const [personas, isLoading] = useOtherPersonas();
  const [location, setLocation] = useState();

  // get location
  useEffect(() => {
    (async () => {
      const data = await getCityAndCountry();
      data && setLocation(data);
    })();
  }, []);

  const handlePersonaSelected = persona =>
    navigate("/marketing/create-campaigns", { state: persona });

  return (
    <>
      <ScrollToTop />

      <div className="pagelayout">
        <InformationTopBanner
          pagetype="Sezione"
          sectionname="Cliente Tipo"
          description={
            process.env.REACT_APP_PROJECT === "Unifarco"
              ? "La tua farmacia"
              : "Scopri tutti i clienti-tipo disponibili e crea campagne pubblicitarie indirizzate al pubblico scelto.."
          }
          sectionicon={BuyerPersonaHeaderLottie}
        />

        <BuyNavigation />

        {isLoading && <Loader />}
        <div className="GridOtherTypeCards gap-10 justify-items-center w-fit mx-auto px-4">
          {!isLoading && personas?.map(p => (
            <OtherPersonaCard
              key={p.type}
              persona={p}
              onSelect={handlePersonaSelected}
              location={location}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default OtherPersona;
