import { useEffect, useCallback, useContext, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import Backdrop from "../../../Reusable/Backdrop/Backdrop";
import Loader from "../../../Reusable/Loader/Loader";
import { baseUrl } from "../../../Utility/Api/Urls";
import { UserContext } from "./../../../Contexts/UserContext";
import { getUserData } from "../../../Utility/getUserData";

const QUERY_TOKEN_KEY = "token";
const LANDING_LOGIN_URL = `https://${process.env.REACT_APP_LANDING_PREFIX}${baseUrl()}/login`;
const AUTH_USER_ENDPOINT = `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/user`;

const isLoginRoute = path =>
  path.includes("/getUser") || path.includes("/user/");

const getTokenFromPath = path => {
  const matches = path.match(/\/user\/(([\w-]*\.){2}[\w-]*$)/g);
  if (matches?.length > 0) return matches[0].split("/user/")[1];
  return null;
};

const AuthUserGetter = ({ isLoggedIn, onLoginSuccessfull }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, _] = useSearchParams();
  const { setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      let token;
      if (process.env.REACT_APP_NODE_ENV !== "prod")
        token = getTokenFromPath(location.pathname);

      token ??= searchParams.get(QUERY_TOKEN_KEY);



      const config = {
        headers: { authorization: `Bearer ${token}` },
      };
      const authResult = await axios.get(AUTH_USER_ENDPOINT, config);

      if (!authResult.data || authResult.data?.Error)
        throw new Error(authResult.data?.Error);

      const userData = authResult.data;
      setUser({ ...userData, token });
      setIsLoading(false);

      if (!userData.hasCompletedWizard) window.location = LANDING_LOGIN_URL;
      else {
        navigate("/");
        onLoginSuccessfull && onLoginSuccessfull();
      }
    } catch (error) {
      console.error("Failed to login.", error);
      window.location = LANDING_LOGIN_URL;
    }
  };

  const checkLocalStorage = async () => {
    try {
      if (isLoading || isLoggedIn) return;

      setIsLoading(true);
      const localData = getUserData();
      if (localData) {
        setUser(localData);
        onLoginSuccessfull && onLoginSuccessfull();
      }
      setIsLoading(false);
    } catch (error) {
      console.error("getUserData failed", error);
    }
  };

  const redirectToLoginPage = () => {
    window.location.href = 'https://restaurants.club/login';
  };

  useEffect(() => {
    if (isLoggedIn) return;

    if (isLoginRoute(location.pathname)) getUser();
    else checkLocalStorage();
  }, [location]);

  return isLoading ? (
    <Backdrop animation="w-[100vw] h-[100vh]">
      <div className="w-full h-full flex items-center">
        <Loader />
      </div>
    </Backdrop>
  ) : null;
};

export default AuthUserGetter;