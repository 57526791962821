import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import defaultImage from "../../Assets/Icons/tempCardImage.png";
import Loader from "../../Reusable/Loader/Loader";

import * as templateService from "../../apis/templateService";
import * as imageHostingService from "../../apis/imageHostingService";
import { getRandomNumber } from "../../Utility/random";

const IMAGE_LOAD_DELAY = getRandomNumber(1, 5) * 250;

function TemplateCard({
  template,
  onSelected,
  label,
  imageWidth = 512,
  imageHeight = 512,
  onShowPreview,
}) {
  const [json, setJson] = useState(null);

  // when template is selected, load the json
  const handleTempalteSelected = async () => {
    if (json) return onSelected(json);

    const fetchedTemplate = await templateService.getFullTemplate(template);
    if (!fetchedTemplate) return;

    onSelected(fetchedTemplate?.json);
    setJson(fetchedTemplate?.json);
  };

  // image generation states
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  // delaying image loading to prevent BEE api rate limiting
  useEffect(() => {
    (async () => {
      // ignoring th euser tempaltes becuase for now we dont have the endpoint in the backend 
      if (imageHostingService.isNewUrl(template?.image) || generatedImage) return;

      setIsLoading(true);

      setTimeout(async () => {
        // get template json
        let jsonPlease = json;
        if (!jsonPlease) {
          const fetchedTemplate = await templateService.getFullTemplate(
            template
          );
          jsonPlease = fetchedTemplate?.json;
        }

        // generate
        const base64Image = await templateService.generateBase64Image({
          json: jsonPlease,
          width: imageWidth,
          height: imageHeight,
        });
        if (!base64Image) return setIsLoading(false);

        // host image statically and get url
        const imageUrl = await imageHostingService.hostBase64Image(base64Image);
        if (!imageUrl) return setIsLoading(false);

        // check if user tempalte and update
        if (templateService.isUserTemplate(template))
          await templateService.updateUserTemplateImage(template._id, imageUrl);
        else
          await templateService.updateTemplateImage(template._id, imageUrl);

        setGeneratedImage(imageUrl || base64Image);
        setJson(jsonPlease);
        setIsLoading(false);
      }, IMAGE_LOAD_DELAY);
    })();
  }, [template, json, imageWidth, imageHeight]);

  // helper variables for rendering
  const image = generatedImage || template?.image || defaultImage;
  const name = label || template.title || template.name;

  // colour theme variation

  const location = useLocation();

  const handlePreview = e => {
    e.stopPropagation();
    onShowPreview({ ...template, image });
  };

  return (
    <>

      <div className="mx-auto max-w-sm overflow-hidden w-full h-[270px] elevation-1 my-5 rounded-md items-center justify-between">
        <div className=" w-full h-[150px] flex justify-center items-center">
          <img
            className="  h-full w-full rounded-md object-cover"
            src={image || generatedImage || defaultImage}
            alt="template"
          />

          {isLoading && <Loader />}
        </div>

        <div className="flex flex-col justify-center items-center h-[120px]">

          <div className="flex justify-center items-center h-[70px]">
            <div className="px-6">
              <p className={`text-base font-normal ${location.pathname.includes("marketing") ? "text-marketingtextcolour" : "text-newslettertextcolour "} `}>
                {name}
              </p>
            </div>
          </div>



          <div className="flex flex-row gap-x-2 px-4 mt-1 mx-auto w-full h-[50px] justify-center items-center">
            <button
              onClick={handlePreview}
              className={`
          
              ${location.pathname.includes("marketing") &&
                " border-marketingtextcolour text-marketingtextcolour hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:text-white"
                }
                ${location.pathname.includes("newsletter") &&
                " border-newslettertextcolour text-newslettertextcolour hover:bg-newsletterbtnhover hover:border-newsletterbtnhover hover:text-white"
                }

                border-2 py-1 px-1 text-sm rounded-md w-40 h-12 mb-4 hover:elevation-1`}
            >
              Mostra
            </button>

            <button
              onClick={handleTempalteSelected}
              className={`
          
   
              ${location.pathname.includes("marketing") &&
                "bg-marketingbtn border-marketingtextcolour text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover"
                } 
              ${location.pathname.includes("newsletter") &&
                "bg-newsletterbtn border-newslettertextcolour text-white hover:bg-newsletterbtnhover hover:border-newsletterbtnhover"
                } 

              border-2 py-1 px-1 text-sm rounded-md w-40 h-12 mb-4 elevation-1`}
            >
              Scegli
            </button>
          </div>
        </div>

      </div>
    </>
  );
}

export default TemplateCard;
