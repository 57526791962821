import { Backdrop, Fade, Modal } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ScheduleCamp from "../../../../Reusable/BeeTemplateScheduling/ScheduleCamp";

function SchedulerModal({ handlePublish, publishOpen, handleClosePublishModal, isValid, isBeeSaving, isPublishing }) {
    const stylePublish = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 15,
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={publishOpen}
            onClose={handleClosePublishModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={publishOpen}>
                <Box sx={stylePublish}>
                    <ScheduleCamp
                        disabled={!isValid || isBeeSaving || isPublishing}
                        onPublish={handlePublish}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default SchedulerModal