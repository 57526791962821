import React, { useState } from 'react';
import DragNDropIcon from './icon/drag_indicator.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FabricSvg from '../FabricSvg/FabricSvg';

export const LayersSection = ({
    commonProps,
    selectedCanvaColor,
    setCanvaIsSelected,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const selectLayer = (index) => {
        const selectedLayer = commonProps.layers[index];
        if (selectedLayer && commonProps.canvas) {
            commonProps.canvas.setActiveObject(selectedLayer.object);
            commonProps.canvas.renderAll();
        }
    };
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedLayers = Array.from(commonProps.layers);
        const [removed] = reorderedLayers.splice(result.source.index, 1);
        reorderedLayers.splice(result.destination.index, 0, removed);
        commonProps.setLayers(reorderedLayers);
        const reversedLayers = [...reorderedLayers].reverse();
        reversedLayers.forEach((layer, index) => {
            commonProps.canvas.moveTo(layer.object, index);
        });
        commonProps.canvas.renderAll();
    };
    return (
        <>
            <h6>Trascina per cambiare la posizione</h6>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='flex flex-col gap-y-4 mt-6'
                            id='layer-list-element'
                        >
                            {commonProps.layers.map((layer, index) => (
                                <Draggable
                                    key={layer.id}
                                    draggableId={String(layer.id)}
                                    index={index}
                                >
                                    {(provided) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className='bg-white py-4 px-4 rounded-lg flex items-center gap-x-5'
                                            id='layer-list-element'
                                            onClick={() => selectLayer(index)}
                                        >
                                            <img
                                                src={DragNDropIcon}
                                                className='h-[24px] w-[24px]'
                                                alt='DragIcon'
                                            ></img>
                                            {layer.type === 'image' && (
                                                <div className='h-[3.18rem] overflow-hidden flex items-center rounded'>
                                                    <img
                                                        src={layer.src}
                                                        className=''
                                                        alt=''
                                                        style={{
                                                            opacity:
                                                                layer.opacity,
                                                        }}
                                                    ></img>
                                                </div>
                                            )}
                                            {layer.type === 'text' && (
                                                <div
                                                    className='h-[3.18rem] flex items-center overflow-hidden bg-[#f2f4f7] w-full px-2 rounded'
                                                    style={{
                                                        fontFamily:
                                                            layer.fontFamily,
                                                        color: layer.fill,
                                                        opacity: layer.opacity,
                                                    }}
                                                >
                                                    {layer.text}
                                                </div>
                                            )}
                                            {layer.type === 'i-text' && (
                                                <div
                                                    className='h-[3.18rem] flex items-center overflow-hidden bg-[#f2f4f7] w-full px-2 rounded'
                                                    style={{
                                                        fontFamily:
                                                            layer.fontFamily,
                                                        color: layer.fill,
                                                        opacity: layer.opacity,
                                                    }}
                                                >
                                                    {layer.text}
                                                </div>
                                            )}
                                            {layer.type === 'element' && (
                                                <div className='h-[3.18rem] flex items-center rounded'>
                                                    <FabricSvg
                                                        commonProps={
                                                            commonProps
                                                        }
                                                        layer={layer}
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <div
                className='relative bg-white py-4 px-4 rounded-lg flex items-center gap-x-5 mt-4 hover:cursor-pointer'
                id='canva-layer'
                onClick={() => setCanvaIsSelected(true)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    className='h-[3.18rem] overflow-hidden flex items-center w-full border b-[#d0d5dd] rounded'
                    style={{ backgroundColor: selectedCanvaColor }}
                ></div>
                {isHovered && (
                    <div className='absolute min-w-max z-20 bottom-[-45px] left-[0px] bg-black rounded px-2 py-1'>
                        <span className='text-white'>Sfondo</span>
                    </div>
                )}
            </div>
        </>
    );
};
