import Branding from "../../../Assets/Icons/Newsletter_Branding.png";
import Promotion from "../../../Assets/Icons/SocialMedia_Promotion.png";
import Reservation from "../../../Assets/Icons/Newsletter_Reservation.png";
import Delivery from "../../../Assets/Icons/SocialMedia_Delivery.png";
import Themes from "../../../Assets/Icons/SocialMedia_Themes.png";
import Feedback from "../../../Assets/Icons/Newsletter_Feedback.png";
import Events from "../../../Assets/Icons/Newsletter_Events.png";

export const newsletterCampaigns = [
  {
    name: "Notorietà",
    value: "branding",
    color: "#2ACB91",
    icon: Branding,
    description: "Vuoi diventare il più famoso della città? Crea un post per parlare del tuo ristorante al meglio!",
  },

  {
    name: "Eventi",
    value: "events",
    color: "#FF5C66",
    icon: Events,
    description: "Sponsorizza i tuoi eventi per invitare i clienti al locale e fare il pieno di coperti nelle occasioni speciali!",
  },

  {
    name: "Promozioni, Offerte",
    value: "promotion",
    icon: Promotion,
    description: "Racconta le tue promo e offerte nei tuoi post e  conquista il cuore dei tuoi clienti.",
  },

  {
    name: "Vacanze, \n Giornate a tema",
    value: "themes",
    icon: Themes,
    description: "Festeggia con i tuoi clienti feste nazionali, ricorrenze, giornate a tema con un Post  creato per l'occasione.  ",
  },

  {
    name: "Delivery",
    value: "delivery",
    icon: Delivery,
    description: "Racconta ai tuoi seguaci perché dovrebbero ordinare qualcosa da mangiare a casa. ",
  },

  {
    name: "Prenotazioni",
    value: "reservation",
    icon: Reservation,
    description: "Invita i tuoi clienti a prenotare un tavolo attraverso i tuoi post sui social!",
  },

  {
    name: "Ringraziamenti, Feedback",
    value: "feedback",
    icon: Feedback,
    description: "Cosa pensano di te i tuoi clienti? Scopri le loro opinioni sui tuoi piatti, un ordine o una prenotazione.",
  },
];

export const getCampaignWithValue = value =>
  newsletterCampaigns.find(c => c.value === value) || newsletterCampaigns[0];

export default newsletterCampaigns;
