import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import LinearProgress from '@mui/material/LinearProgress';

function DemograficiData() {
    const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
    ];
    const COLORS = ['#FAD372', '#BE4E4E', '#4C145B'];
    return (
        <>
            <div className="flex flex-col w-[95%] mx-auto h-[515px] rounded-md elevation-1  " >
                <div className="flex flex-row items-center justify-center h-[100px] w-full  gap-x-6">
                    <p className="text-3xl text-newslettertextcolour mr-auto ml-6 ">Dati Demografici Previsti</p>
                    <p className="text-[#FAD372] font-normal text-2xl "><div className="w-[25px] h-[25px] bg-[#FAD372] mr-2 inline-block" />Umo</p>
                    <p className="text-[#BE4E4E] font-normal text-2xl "><div className="w-[25px] h-[25px] bg-[#BE4E4E] mr-2 inline-block" />Donne</p>
                    <p className="text-[#4C145B] font-normal text-2xl mr-12 "><div className="w-[25px] h-[25px] bg-[#4C145B] mr-2 inline-block" />Altra identità</p>

                </div>
                <div className="flex flex-row w-full h-[400px] mt-auto">

                    <div className="h-full w-1/2 relative" >
                        <div className="absolute left-0 right-0 mx-auto my-auto top-0 bottom-0 w-fit h-fit z-10">
                            <p className='text-black font-normal text-5xl'>Genere</p>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart  >
                                <Pie
                                    data={data}
                                    innerRadius={120}
                                    outerRadius={160}
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>


                    <div className="flex flex-col h-full justify-evenly pb-8  w-1/2 " >


                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">18-24</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">25-34</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">35-44</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">45-54</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">55-64</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-fit gap-x-4" >
                            <p className="h-fit  mt-5 text-black font-normal text-md">65++</p>
                            <div className="w-[90%] flex flex-col items-center" >
                                <p className="ml-auto text-newslettertextcolour font-normal text-md" >12%</p>
                                <LinearProgress transitionTime={1.2}
                                                elements={[
                                                    { value: 3, color: "#4C145B", },
                                                    { value: 24, color: "#FAD372", },
                                                    { value: 40, color: "#BE4E4E", },
                                                ]}
                                                height={15}
                                                backgroundColor="#DBDBDB" />
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default DemograficiData
