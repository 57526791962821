import React, { useEffect, useState } from "react";
import WalletIcon from "../../../../Assets/Icons/wallet.png";
import CreditCard from "../../../../Assets/Icons/creditCard.svg";
import Paypal from "../../../../Assets/Icons/PayPal.svg";
import Bonifico from "../../../../Assets/Icons/Bonifico.svg";
import { getUserId } from "../../../../Utility/getUserData";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { GrFormClose } from "react-icons/gr";

import TextField from "@mui/material/TextField";
import axios from "axios";
import { baseUrl } from '../../../../Utility/Api/Urls';

function CreditModal() {
  const userUtil = getUserId();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // use state to handel the input value
  const [showText, setShowText] = useState(false);
  const [UserId, setUserId] = useState(userUtil._id);
  const [amount, setAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [balance, setBalance] = useState(null);

  // this function to handel amount input
  const handleChange = (amount) => {
    setAmount(amount);
    showText && setShowText(false);
    console.log(amount);
  };
  const handleCustomPayment=(amount)=>{
    setAmount(amount)
  }

  // this function for the payment method
  const handlePaymentMethod = (e) => {
    setPaymentMethod(e);
    console.log(e);
  };
  // rembmber to make a scpfique  service when you try to refactor all this
  const getBalance = () => {
    axios
      .get(
        `https://${process.env.REACT_APP_WIZARD_PREFIX}${baseUrl()}${process.env.REACT_APP_WIZARD_API_PATH}/getBalance`,
        {
          headers: {
            Authorization: `Bearer ${userUtil.token}`,
          },
        }
      )
      .then((res) => {
        console.log("the balance is : " + res.data.balance);
        setBalance(res.data.balance);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // this function to submit the credit request
  const handleSubmit = (amount, paymentMethod) => {
    // check if the amount is not null and the payment method is not null
    // siwtch case for the payment method
    console.log(paymentMethod);
    // eslint-disable-next-line default-case
    switch (paymentMethod) {
      case "Wallet":
      // check if the amount is less than the balance
      // eslint-disable-next-line no-fallthrough
      case "CreditCard":
      // tripe shit

      // eslint-disable-next-line no-fallthrough
      case "Paypal":
        if (amount) {
          // send the request to the server
          axios
            .post(
              `https://${process.env.REACT_APP_WIZARD_PREFIX}${baseUrl()}${process.env.REACT_APP_WIZARD_API_PATH}/paypalSubscription`,
              {
                amount,
                UserId,
              }
            )
            .then((res) => {
              // open in new tab the payment link
              window.open(res.data.link, "_blank");
            });
        }
    }
  };

  // use effect to check the balance
  useEffect(() => {
    // await getBalance();
    getBalance();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 670,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "1rem",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "90vw",
    height: 600,
    borderRadius: "1rem",
  };
  return (
    <div>
      <div className="h-[140px] relative flex flex-col w-[358px] bg-gradient-to-r from-marketingbtn to-[#183258] rounded-md items-center justify-center">
        <div className="flex w-fit p-3 rounded-md bg-white absolute right-0 left-0 mx-auto -top-14">
          <img src={WalletIcon} alt="" />
        </div>
        <p className="text-white font-semibold text-2xl  ">
          Credito disponibile
        </p>
        <p className="text-white font-semibold text-2xl ">€ {balance}</p>
        <button
          className="w-[142px] h-[27px] bg-marketingbtn rounded-md text-md text-white font-normal "
          onClick={handleOpen}
        >
          Ricarica
        </button>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={window.innerWidth < 1200 ? style2 : style}>
          <button
            className=" col-span-5 absolute top-0 right-0 p-2 "
            onClick={() => {
              handleClose();
            }}
          >
            <GrFormClose size={30} className="rmv" />
          </button>

          <div className=" m-7 mt-4">
            <div className=" m-2">
              <div className=" m-2">
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold ">
                  Scegli un importo
                </p>
              </div>

              <div className=" m-2 grid grid-cols-3 gap-5">
                <button
                  // className="bg-white rounded-md w-48 text-marketingtextcolour border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white focus:bg-marketingbtn focus:text-white" old
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    amount === 20 && !showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => handleChange(20)}
                  value={20}
                >
                  20€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    amount === 50 && !showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => handleChange(50)}
                  value={50}
                >
                  50€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    amount === 80 && !showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => handleChange(80)}
                  value={80}
                >
                  80€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    amount === 100 && !showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => handleChange(100)}
                  value={100}
                >
                  100€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    amount === 200 && !showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => handleChange(200)}
                  value={200}
                >
                  200€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${
                    showText
                      ? "bg-marketingbtn text-white"
                      : "bg-white text-marketingtextcolour"
                  }`}
                  onClick={() => {
                    setShowText(!showText);
                    handleChange(null);
                  }}
                >
                  Personalizza
                </button>
                {showText && (
                  <div className=" ">
                    <div>
                      <TextField
                        id="standard-password-input"
                        label="Personalizza Importo"
                        type="number"
                        variant="standard"
                        helperText="Minimo 20€,massimo 200€"
                        onChange={(e) => handleCustomPayment(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" m-2">
              <div className=" m-2">
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold ">
                  Scegli un metodo di pagamento
                </p>
              </div>

              <div className=" m-2 gap-5 flex flex-row justify-center">
                <button
                  // className="bg-white rounded-md w-44 text-marketingtextcolour border border-white hover:border-marketingbtnhover focus:border-marketingbtn py-1 " old
                  className={`bg-white text-marketingtextcolour rounded-md w-44 border hover:border-marketingbtnhover ${paymentMethod==="CreditCard"?"border-marketingbtn":"border-white"} py-1 `}
                  onClick={() => handlePaymentMethod("CreditCard")}
                >
                  <img
                    src={CreditCard}
                    alt=""
                    className="m-auto p-2  max-h-[50%]"
                  />
                  <p className="text-marketingtextcolour text-xs  p-4 ">
                    Carta di Credito
                  </p>
                </button>
                <button
                  className={`bg-white text-marketingtextcolour rounded-md w-44 border hover:border-marketingbtnhover ${paymentMethod==="Paypal"?"border-marketingbtn":"border-white"} py-1 `}
                  onClick={() => handlePaymentMethod("Paypal")}
                >
                  <img src={Paypal} alt="" className="m-auto p-2 max-h-[50%]" />
                  <p className="text-marketingtextcolour text-xs  p-4 ">Paypal</p>
                </button>
                <button
                  className={`bg-white text-marketingtextcolour rounded-md w-44 border hover:border-marketingbtnhover ${paymentMethod==="Bonifico"?"border-marketingbtn":"border-white"} py-1 `}
                  onClick={() => handlePaymentMethod("Bonifico")}
                >
                  <img
                    src={Bonifico}
                    alt=""
                    className="m-auto p-2 max-h-[50%]"
                  />
                  <p className="text-marketingtextcolour text-xs  p-4 ">Bonifico</p>
                </button>
              </div>
            </div>

            <div className=" m-2  justify-center flex">
              <button
                className="bg-marketingbtn rounded-md w-96 text-white border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white focus:bg-marketingbtn focus:text-white m-auto my-4"
                onClick={() => handleSubmit(amount, paymentMethod)}
              >
                Paga
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CreditModal;
