import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Loader from '../../../../Reusable/Loader/Loader';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import TemplateCard from './TemplateCard';
import defaultImage from '../../../../Assets/Icons/tempCardImage.png';
import ReactPaginate from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
/*
Tailwind safelist:
(add classes for other color if there is an use case)
socialmediabtn
hover:bg-socialmediabtn
socialmediabtnhover
hover:bg-socialmediabtnhover
socialmediatextcolour
hover:bg-socialmediatextcolour
marketingbtn
hover:bg-marketingbtn
marketingbtnhover
hover:bg-marketingbtnhover
marketingtextcolour
hover:bg-marketingtextcolour
*/

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    zIndex: 1000,
    borderRadius: 4,
};
function Step1({
    campaign,
    setIsEditorOpen,
    setSelectedTemplateId,
    setTemplateAction,
    service,
    sectionColor,
}) {
    const [currentPage, SetCurrentPage] = React.useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [adminTemplates, isLoadingAdmingTemplates] = service.admin(campaign, currentPage);
    const [userTemplates, isLoadingUserTemplates] = service.user(campaign, currentPage);
    const [showPreview, setShowPreview] = React.useState(false);
    const [TemplateName, SetName] = React.useState(null);
    const [Image, SetImageTemplate] = React.useState(null);

    const handlePreview = (img, name) => {
        setShowPreview(true);
        SetImageTemplate(img);
        SetName(name);
    };
    const handleClose = () => {
        setShowPreview(false);
        SetImageTemplate(null);
        SetName(null);
    };

    const [activeTab, setActiveTab] = React.useState(0);

    const getButtonClases = (tab) => {
        const activeOrHidden =
            tab === activeTab
                ? `
                bg-${sectionColor}btn border-${sectionColor}textcolour text-white hover:bg-${sectionColor}btnhover hover:border-${sectionColor}btnhover
                border-2 py-1 px-1 text-sm rounded-md w-40 h-12 elevation-1`
                : `text-${sectionColor}textcolour hover:text-white bg-white border-2 border-${sectionColor}textcolour hover:bg-${sectionColor}btn  hover:elevation-1 active:border-white active:shadow-innerbig`;
        return `w-96 h-10 my-4 px-5 rounded-md ${activeOrHidden}`;
    };

    const handleSelectTemplate = (item) => {
        setIsEditorOpen(true);

        setSelectedTemplateId(item._id);
        isOpen && setIsOpen(false);
        if ((item.owner_is_admin)||(!item?._id)) {
            setTemplateAction('new');
        } else {
            setTemplateAction('modifying');
        }
    };

    // handling pagination
    const handlePageChange = async ({ selected: index }) => {
        const page = index + 1;
        if (page !== currentPage) SetCurrentPage(page);
    };
    
    return (
        <>
            <div className='flex flex-col w-full justify-center items-center'>
                <div className='flex flex-row gap-x-4 justify-center items-center mb-8 xsm:flex-col sm:flex-col md:flex-col '>
                    <button
                        className={getButtonClases(0)}
                        onClick={() => setActiveTab(0)}
                    >
                        Nuovo Template
                    </button>
                    <button
                        className={getButtonClases(1)}
                        onClick={() => setActiveTab(1)}
                    >
                        Template Social usati in Precedenza
                    </button>
                </div>
                <div className='flex flex-col items-center w-full justify-center gap-y-6'>
                    {(activeTab === 1 ? userTemplates.documents : adminTemplates.documents)
                        ?.length > 0 && (
                        <div
                            className={`grid grid-cols-4 gap-4 justify-center items-center transition-all px-10 w-full xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2`}
                        >
                            <TemplateCard
                                sectionColor={sectionColor}
                                label={`Modello Vuoto`}
                                key={"blank template"}
                                handlePreview={handlePreview}
                                handleSelect={handleSelectTemplate}
                            />
                            {(activeTab === 1 ? userTemplates.documents : adminTemplates.documents)
                                ?.slice(0, 3)
                                ?.map((template, index) => (
                                    <TemplateCard
                                        sectionColor={sectionColor}
                                        label={`Template ${index + 1}`}
                                        key={template.name}
                                        item={template}
                                        handlePreview={handlePreview}
                                        handleSelect={handleSelectTemplate}
                                    />
                                ))}
                        </div>
                    )}
                </div>

                {activeTab === 1 && isLoadingUserTemplates && (
                    <div className='w-full mx-auto my-5'>
                        <Loader />
                    </div>
                )}
                {activeTab === 0 && isLoadingAdmingTemplates && (
                    <div className='w-full mx-auto my-5'>
                        <Loader />
                    </div>
                )}

                {activeTab === 1 &&
                    !isLoadingUserTemplates &&
                    userTemplates.documents?.length === 0 && (
                        <p>
                            Oops. Non ci sono template salvati per questo
                            obiettivo.
                        </p>
                    )}
                {activeTab === 0 &&
                    !isLoadingAdmingTemplates &&
                    adminTemplates.documents?.length === 0 && (
                        <p>
                            Oops. Non c'è nessun template per questo obiettivo.
                        </p>
                    )}

                {((activeTab === 0 && adminTemplates.documents?.length > 4)||
                  (activeTab === 1 && userTemplates.documents?.length > 4)) 
                  &&(<button
                        onClick={() => setIsOpen(true)}
                        className={`bg-${sectionColor}btn border-${sectionColor}textcolour text-white hover:bg-${sectionColor}btnhover hover:border-${sectionColor}btnhover border-2 py-1 px-1 text-sm rounded-md w-40 h-12 elevation-1`}
                    >
                        {'Mostra tutti'}
                    </button>
                )}
            </div>
            {isOpen && (
                <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    open={isOpen}
                    onClose={() => {setIsOpen(false);SetCurrentPage(1)}}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={isOpen}>
                        <Box
                            sx={{
                                ...style,
                                width: '80%',
                                height: '90%',
                                overflow: 'scroll',
                                maxHeight: '90%',
                            }}
                        >
                            <p className={`text-xl ml-6 col-span-4 mt-6 text-${sectionColor}textcolour`}>
                                {activeTab === 1
                                ? "Riprendi un Template dalle Bozze"
                                : "Seleziona un Template dall’Archivio"}
                            </p>
                            <div className='flex flex-col items-center w-full h-auto justify-center gap-y-6'>
                                {(activeTab === 1
                                    ? userTemplates.documents
                                    : adminTemplates.documents
                                )?.length > 0 && (
                                    <div className='grid grid-cols-4 gap-4 justify-center items-center transition-all h-full px-6 w-full'>
                                        {(activeTab === 1
                                            ? userTemplates.documents
                                            : adminTemplates.documents
                                        )?.map((template, index) => (
                                            <TemplateCard
                                                sectionColor={sectionColor}
                                                label={`Template ${index + 1}`}
                                                key={template.name}
                                                item={template}
                                                handlePreview={handlePreview}
                                                handleSelect={
                                                    handleSelectTemplate
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <ReactPaginate
                                pageCount={(activeTab === 1)?userTemplates.total_pages:adminTemplates.total_pages}
                                forcePage={currentPage - 1}
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
                                breakLabel="..."
                                nextLabel={<FaChevronRight />}
                                nextClassName={`text-${sectionColor}textcolour text-2xl`}
                                pageClassName={`rounded-xl text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-${sectionColor}textcolour border-${sectionColor}textcolour border-2 hover:bg-${sectionColor}textcolour hover:border-${sectionColor}textcolour active:shadow-inner active:bg-${sectionColor}textcolour active:border-${sectionColor}textcolour`}
                                pageLinkClassName={`w-full h-full items-center justify-center flex `}
                                previousLabel={<FaChevronLeft />}
                                previousClassName={`text-${sectionColor}textcolour text-2xl`}
                                activeClassName={`bg-${sectionColor}btn rounded-xl border border-${sectionColor}textcolour w-10 h-10  textWhiteCssClass hover:bg-${sectionColor}textcolour hover:border-${sectionColor}textcolour active:shadow-inner active:bg-${sectionColor}textcolour active:border-${sectionColor}textcolour`}
                                containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto justify-center  "
                                renderOnZeroPageCount={false}
                                marginPagesDisplayed={1}
                            />
                        </Box>
                    </Fade>
                </Modal>
            )}

            {showPreview && (
                <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    open={showPreview}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showPreview}>
                        <Box sx={style}>
                            <div className='reltaive flex flex-col items-center h-full w-full'>
                                <div className='w-full flex items-center justify-center h-12 border-b-[1px] mb-5 border-gray-300'>
                                    <p className='font-normal text-black '>
                                        {TemplateName}
                                    </p>
                                </div>
                                <img
                                    src={Image || defaultImage}
                                    alt=''
                                    className='w-[90%] mx-auto h-min rounded-md grow'
                                />
                                <button
                                    className={`bg-${sectionColor}btn  border-2 border-${sectionColor}textcolour h-fit py-2 px-1 text-sm text-white rounded-md w-1/3 mx-auto self-center my-3`}
                                    onClick={() => handleClose()}
                                >
                                    {' '}
                                    Chiudi{' '}
                                </button>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    );
}

export default Step1;
