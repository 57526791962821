import React from 'react'
import Popup from "reactjs-popup";

function SuggestDescriptionModal({ allDescription,update, handleToggleDescription, activeDescriptions }) {
    return (
        <div className={`flex flex-row items-${update ? 'start' : 'center'} w-1/2 xmd:w-[65%] justify-${update ? 'start' : 'center'} xsm:w-full sm:w-full md:w-[60%] xsm:flex-col-reverse sm:flex-col-reverse`}>
<Popup
  trigger={
    <button
      className={`text-${update ? 'start' : 'center'} w-[50%] h-full xsm:w-full sm:w-full md:w-[80%] xmd:w-[60%] flex items-center justify-${update ? 'start' : 'center'} text-[#FC6371] gap-x-1 border-[#FC6371] border-2 py-1 px-4 rounded-md`}
      style={
        update
          ? {
              border: 'none',
              color: '#00B27A',
              textDecoration: 'underline',
            }
          : {}
      }
    >
      {update ? 'Vuoi un suggerimento?' : 'Choose a Description'}
    </button>
  }
  modal
  nested
>
                {(close) => (
                    <div className="modal p-5 ">
                        <div className="header text-[#FC6371] pb-5 text-bold">
                            Descrizioni Suggerite
                        </div>
                        <div
                            className="content flex flex-row gap-y-4 gap-x-5 h-fit justify-items-center flex-wrap">
                            {Array.isArray(allDescription) ? (
                                allDescription?.map((description, index) => (
                                    <div key={index}
                                        className="flex flex-row gap-x-2 items-center h-fit w-full rounded-md border-2 border-[#FC6371] cursor-pointer justify-between p-4"
                                        onClick={() => {
                                            handleToggleDescription(description);
                                            close();
                                        }}
                                        style={{ backgroundColor: activeDescriptions[description.text] ? "#FC6371" : "white", }}
                                    >
                                        <p className="font-normal"
                                            style={{ color: activeDescriptions[description.text] ? "white" : "black", }}
                                        >
                                            {description.text}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className="flex justify-center items-center text-center mx-auto">
                                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#FC6371]">
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {Array.isArray(allDescription) ? (

                            <>
                            </>

                        ) : (

                            <div className='text-center mt-4'>
                                <span>Lasciamo raffreddare le tue descrizioni prima di servirle </span>
                                <span>Giusto qualche secondo e il piatto è servito!</span>
                                <span>Un attimo, stiamo cucinando la tua descrizione…</span>
                            </div>

                        )
                        }
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default SuggestDescriptionModal