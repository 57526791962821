import React, { useEffect, useState } from "react";

function OtherPersonaCard({
  persona,
  location,
  onSelect,
  selected,
  isloading,
}) {
  // const { label, ageRange, otherParams, color, image, description } = persona;
  const [displayData, setDisplayData] = useState(null);

  useEffect(() => {
    if (!isloading && persona) {
      const { ageRange, color, description, otherParams, image, label } =
        persona;
      setDisplayData({
        ageRange,
        description,
        color,
        otherParams,
        image,
        label,
      });
    }
  }, [isloading, persona]);
  // const goToCampaignCreation = () => onSelect(persona);

  // const [isHovered, setIsHovered] = useState(false);
  const selectedId = selected?.id;
  return (
    <>
      <div className="buyerpersonacard w-full h-max p-0 rounded-t-md">
        {!isloading && displayData && (
          <div className="buyerpersonacard__page1 h-full flex flex-col w-full gap-y-4 items-center">
            <div className="w-96  overflow-hidden rounded-md">
              <img
                src={displayData.image}
                alt=""
                className="w-full h-[200px] object-cover"
              />
            </div>
            <p className="pop600 text-[22px] text-left text-marketingtextcolour pl-3">
              {displayData.label}
            </p>
            <p className="px-5 text-left">{displayData.description}</p>

            <div className="flex flex-col gap-y-1 w-[95%] mb-4">
              <p className="pop400 text-left text-black bg-[#d9d9d94d] p-4 flex flex-row justify-between mb-4">
                Età:{" "}
                <span className="font-normal text-black">
                  {" "}
                  {displayData.ageRange}
                </span>
              </p>

              {/* {location && (
                <p className="font-semibold text-xl text-[#2E2E2E] text-left">
                  Luogo: <span className="font-normal"> {location} </span>
                </p>
              )} */}

              {displayData.otherParams?.map((p) => (
                <p
                  className="pop400 text-black text-left bg-[#d9d9d94d] p-4 flex flex-row justify-between flex-wrap"
                  key={p.value}
                >
                  {p.label}: <span className="text-black">{p.value}</span>
                </p>
              ))}
            </div>

            {/* <div className="w-full mt-auto flex flex-col">
                <button
                  onClick={goToCampaignCreation}
                  className="w-full rounded-b-md self-center items-center flex justify-center text-black p-2 mt-auto mx-auto hover:elevation-1 hover:text-white"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    backgroundColor: isHovered ? displayData.color : "white",
                  }}
                >
                  Avvia Campagna Marketing con questo Cliente Tipo
                </button>
              </div> */}
          </div>
        )}
      </div>
      {/* <div className="flex w-[95%] justify-center items-center mx-auto mb-4">
        <button
          onClick={() => onSelect(persona)}
          className={`${
            selectedId === persona?.id
              ? "font-semibold text-xl elevation-1 text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig"
              : "font-semibold text-xl bg-white text-marketingtextcolour border-2 border-marketingbtn hover:bg-marketingbtnhover hover:text-white hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
          } w-full h-10 flex flex-row gap-x-2 rounded-md items-center justify-center`}
        >
          {selectedId === persona?.id ? "Scelto" : "Scegli"}
        </button>
      </div> */}
    </>
  );
}

export default OtherPersonaCard;
