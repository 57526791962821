import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineArrowUp } from 'react-icons/ai';
import UserImg from "../../Assets/Images/Buyer-Persona/IL VEGANO.webp"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { BsPersonCircle } from "react-icons/bs"

const AnalyticsPlugin = ({ section1, section2, section3, section4, section5, section6, section7, SectionName, AnalyticsName1, AnalyticsName2, AnalyticsName3, AnalyticsName4, AnalyticsName5, AnalyticsName6, AnalyticsData1, AnalyticsData2, AnalyticsData3, AnalyticsData4, AnalyticsData5, AnalyticsData6, Analyticspercentage1, Analyticspercentage2, Analyticspercentage3, Analyticspercentage4, Analyticspercentage5, Analyticspercentage6, text, imgOk }) => {

    const navigate = useNavigate();

    // colour theme variation

    const location = useLocation()

    const [open, SetOpen] = useState(false)
    const [active, setActive] = useState(1)

    function nextPage() {
        if (active >= 1 && active < 3 && section7 === true) {
            setActive(active + 1)
        } else if (active >= 1 && active < 2) {
            setActive(active + 1)
        }
    }

    function prevPage() {
        if (active > 1) {
            setActive(active - 1)
        } else {

        }
    }

    return (

        // <div className={`flex fixed top-[230px] ${open ? " right-0 " : "-right-[350px] "} z-20  transition-all duration-1000`}>

        //     <button

        //         onClick={() => SetOpen(!open)}
        //         className={`
        //         ${location.pathname.includes("products") && "bg-primarybtn"}
        //         ${location.pathname.includes("pending-orders") && "bg-primarybtn"}
        //         ${location.pathname.includes("reservation") && "bg-primarybtn"}
        //         ${location.pathname.includes("all-customers") && "bg-primarybtn"}
        //         ${location.pathname.includes("website/theme") && "bg-seobtn"}
        //         ${location.pathname.includes("social-media/social-manager") && "bg-seobtn"}
        //         ${location.pathname.includes("competition/trend") && "bg-seobtn"}
        //         ${location.pathname.includes("marketing/inprogress-campaigns") && "bg-marketingbtn"}
        //         ${["discounts/all", "discounts/active", "discounts/drafts", "discounts/history"].some(path => location.pathname.includes(path)) && "bg-marketingbtn"}
        //         ${location.pathname.includes("newsletter/completed-newsletter") && "bg-newsletterbtn"}
        //         ${location.pathname.includes("reviews/to-reply") && "bg-newsletterbtn"}
        //         ${location.pathname.includes("fidelity") && "bg-newsletterbtn"}
        //         w-14 h-14 text-white rounded-l-md font-semibold flex items-center justify-center elevation-1 mt-8`} >
        //         {open ? <IoIosArrowForward size={30} /> : <IoIosArrowBack size={30} />}

        //     </button>

        //     <div className={`w-[350px] p-4 bg-white flex flex-col h-fit gap-y-3 elevation-1 text-left rounded-md`}>

        //         <div className='flex flex-col'>

        //             <p className="font-semibold text-tertiarybtn text-lg"> {SectionName} </p>

        //             {active === 1 ? (

        //                 <div>

        //                     {section1 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName1} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData1} </p>

        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage1}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>

        //                             <div>
        //                                 {imgOk === true ?

        //                                     <img src={UserImg} alt="" className="w-20 h-20 rounded-md object-cover" />

        //                                     :

        //                                     null
        //                                 }
        //                             </div>

        //                         </div>

        //                         :
        //                         null

        //                     }

        //                     {section2 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center my-3 border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName2} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData2} </p>


        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage2}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>

        //                         </div>

        //                         :
        //                         null

        //                     }

        //                     {section3 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName3} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData3} </p>

        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage3}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>


        //                         </div>

        //                         :
        //                         null

        //                     }
        //                 </div>
        //             ) : (
        //                 <div></div>
        //             )

        //             }

        //             {active === 2 ? (
        //                 <div>
        //                     {section4 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName4} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData4} </p>

        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage4}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>

        //                             {imgOk === true ?

        //                                 <img src={UserImg} alt="" className="w-20 h-20 rounded-full object-cover" />

        //                                 :

        //                                 null
        //                             }

        //                         </div>

        //                         :
        //                         null

        //                     }

        //                     {section5 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center my-3 border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName5} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData5} </p>


        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage5}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>

        //                         </div>

        //                         :
        //                         null

        //                     }

        //                     {section6 === true ?

        //                         <div className="flex flex-row justify-between gap-x-5 items-center border rounded-md px-2 py-1" >

        //                             <div className="flex flex-col gap-y-2" >

        //                                 <p className="font-normal text-sm text-tertiarybtn"> {AnalyticsName6} </p>

        //                                 <p className="font-semibold text-tertiarybtn"> {AnalyticsData6} </p>

        //                                 <p className="flex flex-row gap-x-2 items-center text-xs font-normal text-tertiarybtn">

        //                                     <p className='font-normal text-white px-2 w-fit text-xs rounded-md py-1 bg-primarybtn flex flex-row gap-x-2 items-center justify-center'> <AiOutlineArrowUp />
        //                                         {Analyticspercentage6}
        //                                     </p>

        //                                     {text}

        //                                 </p>

        //                             </div>


        //                         </div>

        //                         :
        //                         null

        //                     }
        //                 </div>
        //             ) : (
        //                 <div></div>
        //             )

        //             }

        //             {section4 === true ?
        //                 <div className='flex justify-center items-center mt-3 gap-x-2'>
        //                     <button className='border rounded-md text-2xl w-10 h-10 flex justify-center items-center hover:text-white text-tertiarybtn hover:bg-primarybtn hover:border-primarybtn'>
        //                         <IoIosArrowBack onClick={prevPage} />
        //                     </button>
        //                     <button onClick={() => setActive(1)} className={`rounded-md text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-tertiarybtn hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig ${active === 1 ? "bg-primarybtn text-white" : ""} active:border-white active:text-white`}>
        //                         1
        //                     </button>
        //                     <button onClick={() => setActive(2)} className={`rounded-md text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-tertiarybtn hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig ${active === 2 ? "bg-primarybtn text-white" : ""} active:border-white active:text-white`}>
        //                         2
        //                     </button>
        //                     {section7 === true &&
        //                         <button onClick={() => setActive(3)} className={`rounded-md text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-tertiarybtn hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig ${active === 3 ? "bg-primarybtn text-white" : ""} active:border-white active:text-white`}>
        //                             3
        //                         </button>}
        //                     <button className='border rounded-md text-2xl w-10 h-10 flex justify-center items-center hover:text-white text-tertiarybtn hover:bg-primarybtn hover:border-primarybtn'>
        //                         <IoIosArrowForward onClick={nextPage} />
        //                     </button>
        //                 </div>
        //                 :
        //                 null}

        //         </div>

        //     </div>

        // </div>
<>
</>
    )
}

export default AnalyticsPlugin