import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import style from './GraphicEditorPortal.module.css';

const GraphicEditorPortal = ({ children, show, inlineStyles }) => {
    const scroll = useMemo(() => window.scrollY, []);
    useEffect(() => {
        if (show) {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
            scroll !== 0 && window.scrollTo(0, scroll);
        };
    }, [show]);
    return createPortal(
        <div
            style={inlineStyles}
            className={`${style.modal} ${show && style.show} ${
                show && style.show
            }`}
        >
            <div>{children}</div>
        </div>,
        document.body
    );
};

export default GraphicEditorPortal;
