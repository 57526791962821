import axios from "axios";
import { toast } from "react-toastify";
import { getUserToken } from "../Utility/getUserData";
import { getAuthConfig } from "../Utility/Api/auth";
import { baseUrl } from '../Utility/Api/Urls';

const postToFacebook = async (imageUrl, time, description, hashtags) => {
  try {
    const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/socials/fb/schedules`;
    const config = { headers: { Authorization: `Bearer ${getUserToken()}` } };
    //let desc = encodeURI(description + hashtags.map(hashtag => `${hashtag}`).join(" "));
    // descriptio + hashtag 
    let desc = description + hashtags.map(hashtag => `${hashtag}`).join(" ");
    const data = {
      message: desc,
      photoUrl: imageUrl,
      time: time,
    };
    const res = await axios.post(url, data, config);
    toast.success("Post Facebook creato con successo");
    return res.data;
  } catch (error) {
    toast.error(error.message);
    console.error(error);
  }
};

const postToInstagram = async (imageUrl, time, description, parentId,hashtags) => {
  try {
    let url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/socials/instagram/schedule`;
    let desc = description + hashtags.map(hashtag => `${hashtag}`).join(" ");
    const data = {
      image_url: imageUrl,
      caption: desc,
      at: time,
      parentId: parentId || null
    };
    if (!parentId) delete data.parentId;
    await axios.post(url, data, getAuthConfig());
    toast.success("Post Instagram creato con successo");
  } catch (error) {
    toast.error(error.message);
    console.error(error);
  }
};

export { postToFacebook, postToInstagram };
