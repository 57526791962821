import React, { useContext, useRef, useState } from "react";
import Backbutton from "../../../../Reusable/Back-Button/Back-Button";
import { SocialMediaContext } from "../Context/SocialMediaContext";
import SuggestDescriptionModal from "../CreatePost/SuggestDescriptionModal";
import { TextareaAutosize } from "@mui/material/node";

const UpdatePost = () => {
  const {
    image,
    setImage,
    json,
    setJson,
    setHtml,
    hashtags,
    setDescription,
    description,
    setHashtags,
    imageUrl,
    setImageUrl,
  } = useContext(SocialMediaContext);

  const [allDescription, setAllDescription] = useState();
  const [activeDescriptions, setActiveDescription] = useState({});

  const handleDescriptionChange = (e) => {
    setSelectedDescription(e.target.value);
    setDescription(e.target.value);
  };

  const getSelectedDescription = () =>
    allDescription?.filter((description) => description.isSelected);

  const [selectedDescription, setSelectedDescription] = useState("");
  const handleToggleDescription = (description) => {
    setSelectedDescription(description.text);
    setDescription(description.text);
  };

  const inputRef = useRef(true);

  const handleKeyDownDesc = (e) => {
    if (e.key === "Backspace" && selectedDescription === "") {
      // Cancella l'ultimo elemento dell'array
      const lastIndex = allDescription?.length - 1;
      const lastDescription = allDescription[lastIndex];
      setSelectedDescription(lastDescription);
      setDescription(lastDescription);
    }
  };

  return (
    <>
      {" "}
      <div className="flex flex-col bg-white w-full">
        <div className="flex mb-5 ">
          <Backbutton />
        </div>

        <div className="font-poppins font-weight-500 text-2xl text-[#344054]">
          Modifica Post
        </div>

        <div className="flex flex-col w-full rounded-md m-2 p-2 gap-y-5">
          <TextareaAutosize
            minRows={5}
            className="bg-[#F6F6F6] self-center block border-2 border-blue-600 w-full p-3 rounded-md mb-4 focus:text-black focus:font-normal"
            id="description"
            placeholder="Descrizione del post"
            onChange={handleDescriptionChange}
            value={selectedDescription}
            onKeyDown={handleKeyDownDesc}
          />
          <div className="flex flex-row flex-start border-0 items-start xsm:flex-col sm:flex-col xsm:items-start sm:items-start">
            <SuggestDescriptionModal
              update={true}
              allDescription={allDescription}
              handleToggleDescription={handleToggleDescription}
              activeDescriptions={activeDescriptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePost;
