import React from 'react';

export const SideBar = (props) => {
    const icon1 = () => (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21ZM5 21L16 10L21 15M10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z'
                stroke='#667085'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
    const icon2 = () => (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4 7V4H20V7M9 20H15M12 4V20'
                stroke='#667085'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
    const icon3 = () => (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M23.9998 22.9157V14.3491C23.9998 13.8831 23.6162 13.5056 23.1426 13.5056H14.4353C13.9617 13.5056 13.5781 13.8831 13.5781 14.3491V22.9157C13.5781 23.3817 13.9617 23.7592 14.4353 23.7592H23.142C23.6156 23.7592 23.9998 23.3817 23.9998 22.9157ZM22.2853 22.0723H15.2926V15.1925H22.2848V22.0723H22.2853Z'
                fill='#667085'
            />
            <path
                d='M18.5959 10.8735C18.7491 10.6125 18.7491 10.291 18.5959 10.03L12.9581 0.421718C12.8049 0.16078 12.522 0 12.2155 0C11.9091 0 11.6262 0.16078 11.473 0.421718L5.83514 10.03C5.68191 10.291 5.68191 10.6125 5.83514 10.8735C5.98836 11.1344 6.27125 11.2952 6.5777 11.2952H17.8539C18.1598 11.2952 18.4432 11.1344 18.5959 10.8735ZM8.0623 9.60833L12.2155 2.52978L16.3688 9.60833H8.0623Z'
                fill='#667085'
            />
            <path
                d='M10.9113 18.6326C10.9113 15.6727 8.46397 13.2646 5.45567 13.2646C2.44736 13.2646 0 15.6727 0 18.6326C0 21.5925 2.44736 24 5.45567 24C8.46397 24 10.9113 21.592 10.9113 18.6326ZM1.71444 18.6326C1.71444 16.6031 3.39245 14.9515 5.45567 14.9515C7.51889 14.9515 9.19689 16.6026 9.19689 18.6326C9.19689 20.6626 7.51889 22.3137 5.45567 22.3137C3.39245 22.3137 1.71444 20.6621 1.71444 18.6326Z'
                fill='#667085'
            />
        </svg>
    );
    const icon4 = () => (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2 14.5L12 19.5L22 14.5M12 4.5L2 9.5L12 14.5L22 9.5L12 4.5Z'
                stroke='#667085'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
    const icon5 = () => (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z'
                stroke='#667085'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
    const sideData = [
        {
            icon: icon1,
            title: 'Immagini',
        },
        {
            icon: icon2,
            title: 'Testo',
        },
        {
            icon: icon3,
            title: 'Elementi',
        },
        {
            icon: icon4,
            title: 'Livelli',
        },
        {
            icon: icon5,
            title: 'Caricamenti',
        },
    ];

    return (
        <div
            className=' bg-white'
            id='sidebar'
        >
            {sideData.map((item, index) => (
                <div
                    className={`rounded ${
                        index === props.selectedSection ? 'bg-[#f2f4f7]' : ''
                    }`}
                    key={index}
                >
                    <button
                        className='py-6 px-6 flex flex-col items-center mx-auto'
                        onClick={() => {
                            props.handleSectionChange(index);
                            props.setCanvaUnactive(false);
                            props.setImageIsSelected(false);
                            props.setTextIsSelected(false);
                            props.setElementIsSelected(false);
                        }}
                    >
                        {item.icon()}
                        <span className='mt-2'>{item.title}</span>
                    </button>
                </div>
            ))}
        </div>
    );
};
