import Loader from "../../Loader/Loader";
import BuyerPersonaCard from "./BuyerPersonaCard";
// import { useLocation } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  getBuyerPersonas,
  getOtherPersonas,
} from "../../../apis/buyerPersonaService";
import OtherPersonaCard from "../../../Components/Commons/BuyerPersona/OtherTypes/OtherPersonaCard";
import "./BuyerPersonaCard.css";
import { MarketingCampaignContext } from "../../../Contexts/MarketingCampaignContext";
function BuyerPersonasSelection({
  selected,
  onSelect,
  isRandom,
  externalBuyerPersona,
}) {
  // const handlePersonaLoad = data => {
  //   if (externalBuyerPersona)
  //     return onSelect(data.find(p => p.value === externalBuyerPersona.value));

  //   onSelect(isRandom ? getRandomElement(data) : data[0]);
  // };

  // const [personas, isLoading] = useBuyerPersonas({
  //   onLoad: handlePersonaLoad,
  // });
  // const { state: buyerPersona } = useLocation();
  // const [personas, isLoading] = useBuyerPersonas();
  // const [otherpersonas, otherpersonasisLoading] = useOtherPersonas();
  const [personas, setPersonas] = useState([]);
  const [otherPersonas, setOtherPersonas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [otherPersonasIsLoading, setOtherPersonasIsLoading] = useState(true);
  const allpersonas = [...(personas || ""), ...(otherPersonas || "")];
  const [selectedRadio, setSelectedRadio] = useState();

  const { handleGoalSelected } = useContext(MarketingCampaignContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [personasData, otherPersonasData] = await Promise.all([
          getBuyerPersonas(),
          getOtherPersonas(),
        ]);
        setPersonas(personasData);
        setOtherPersonas(otherPersonasData);
        setIsLoading(false);
        setOtherPersonasIsLoading(false);
        setSelectedRadio(personasData[0].label);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const styles = {
    formControlLabel: {
      minWidth: "33.33%",
      textAlign: "left",
      color: "#6392ff",
      whiteSpace: "no-wrap",
      span: {
        color: "#6392ff",
      },
      padding: "10px",
    },
    RadioGroup: {
      flexDirection: "row",
      "@media (max-width: 834px)": {
        display: "none",
      },
    },
    Select: {
      marginBottom: "20px",
      width: "100%",
      "@media (min-width: 834px)": {
        display: "none",
      },
    },
  };
  const handleRadioChange = (event) => {
    handleGoalSelected(event.target.value);
    setSelectedRadio(event.target.value);
  };
  const handleSelectPersona = (selectedPersona) => {
    setSelectedRadio(selectedPersona.label);
    onSelect(selectedPersona);
  };
  return (
    <div className="flex flex-row justify-center items-start h-max gap-x-4 rounded-md w-full xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse">
      {isLoading && <Loader />}

      {!isLoading && !otherPersonasIsLoading && (
        <div className="flex flex-col w-[50%] justify-center gap-y-8 bg-white rounded-md  elevation-1 xsm:w-full sm:w-full md:w-full">
          {selectedRadio === "Cliente ideale" ? (
            <BuyerPersonaCard
              persona={personas.find((p) => p.label === selectedRadio)}
              // persona={selectedRadio}

              isloading={isLoading}
              selected={selected}
              onSelect={onSelect}
            />
          ) : (
            <OtherPersonaCard
              persona={otherPersonas.find((p) => p.label === selectedRadio)}
              // persona={selectedRadio}

              isloading={otherPersonasIsLoading}
              selected={selected}
              onSelect={onSelect}
            />
          )}
        </div>
      )}
      {!isLoading && !otherPersonasIsLoading && (
        <div
          className={`grid items-center justify-content-center gap-3 grid-cols-3 w-1/2 h-full xsm:hidden sm:hidden md:hidden`}
        >
          {allpersonas?.map((item) => (
            <button
              onClick={() => handleSelectPersona(item)}
              className={`border border-marketingbtn rounded-md ${
                item.label === selectedRadio
                  ? "bg-marketingbtn text-white elevation-1"
                  : "bg-transparent text-marketingtextcolour hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig"
              }  py-6 px-2 `}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
      {!isLoading && !otherPersonasIsLoading && (
        <FormControl>
          <Select
            labelId="buyer-personas-label"
            id="buyer-personas"
            // value={selectedRadio}
            onChange={handleRadioChange}
            sx={styles.Select}
            defaultValue={personas[0].label}
          >
            {allpersonas?.map((persona, index) => (
              <MenuItem key={index} value={persona.label} sx={styles.MenuItem}>
                {persona.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}

export default BuyerPersonasSelection;
