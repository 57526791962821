import React, { useState, useEffect } from 'react';
import AlignCenterIcon from '../icon/align-center.svg';
import AlignLeftIcon from '../icon/align-left.svg';
import AlignRightIcon from '../icon/align-right.svg';
import Bold from '../icon/bold.svg';
import Italic from '../icon/italic.svg';
import MatchCase from '../icon/match_case.svg';
import Minus from '../icon/minus.svg';
import Opacity from '../icon/opacity.svg';
import Plus from '../icon/plus.svg';
import Through from '../icon/through.svg';
import Underlined from '../icon/underline.svg';
import '../fonts.css';

export const TextSectionNavBar = ({
    canvas,
    selectedFont,
    setSelectedFont,
    updateLayer,
    setSelectedFontFamily,
    selectedFontFamily,
    fontSize,
    setFontSize,
    fontDimension,
    setFontDimension,
    handleSectionChange,
    fontColor,
    setIsBold,
    isBold,
    setIsItalic,
    isItalic,
    setIsUnderline,
    isUnderline,
    setIsLineThrough,
    isLineThrough,
    setIsUpperCase,
    isUpperCase,
    setTextAlignment,
    setIsAligned,
    isAligned,
    italicIsSelected,
    boldIsSelected,
    underlineIsSelected,
    linethroughIsSelected,
    uppercaseIsSelected,
    textAlignment,
    textOpacities,
    changeTextOpacity,
    activeObjectId,
    setActiveObjectId,
}) => {
    const fontList = [
        'Poppins',
        'Abril Fatface',
        'Playfair Display',
        'Bebas Neue',
        'Caveat Brush',
        'Signika',
        'Dancing Script',
        'Pacifico',
        'Amatic SC',
        'Itim',
        'Londrina Shadow',
        'Arimo',
        'Tinos',
    ];
    const toggleBold = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({
                fontWeight:
                    activeObject.fontWeight === 'bold' ? 'normal' : 'bold',
            });
            canvas.renderAll();
            setIsBold({
                ...isBold,
                [textId]: activeObject.fontWeight,
            });

            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const toggleItalic = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({
                fontStyle:
                    activeObject.fontStyle === 'italic' ? 'normal' : 'italic',
                fontFamily: activeObject.get('fontFamily'),
            });
            canvas.renderAll();
            setIsItalic({
                ...isItalic,
                [textId]: activeObject.fontStyle,
            });
            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const toggleUnderline = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({ underline: !activeObject.underline });
            canvas.renderAll();
            setIsUnderline({
                ...isUnderline,
                [textId]: activeObject.underline,
            });

            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const toggleLineThrough = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({ linethrough: !activeObject.linethrough });
            canvas.renderAll();
            setIsLineThrough({
                ...isLineThrough,
                [textId]: activeObject.linethrough,
            });
            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const toggleUppercase = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            const isUppercase =
                activeObject.text === activeObject.text.toUpperCase();
            activeObject.set({
                text: isUppercase
                    ? activeObject.text.toLowerCase()
                    : activeObject.text.toUpperCase(),
            });
            canvas.renderAll();
            setIsUpperCase({
                ...isUpperCase,
                [textId]: !isUppercase,
            });
            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const changeTextAlignment = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            let newAlignment = 'left';

            if (textAlignment === 'left') {
                newAlignment = 'center';
            } else if (textAlignment === 'center') {
                newAlignment = 'right';
            }

            activeObject.set({ textAlign: newAlignment });
            canvas.renderAll();
            setTextAlignment(newAlignment);
            setIsAligned({
                ...isAligned,
                [textId]: newAlignment,
            });
            setActiveObjectId(canvas.getActiveObject().get('id'));
        }
    };
    const getButtons = (textAlignment) => {
        let alignIcon;
        if (textAlignment === 'left') {
            alignIcon = AlignLeftIcon;
        } else if (textAlignment === 'center') {
            alignIcon = AlignCenterIcon;
        } else if (textAlignment === 'right') {
            alignIcon = AlignRightIcon;
        }
        return [
            {
                icon: Bold,
                action: toggleBold,
            },
            {
                icon: Italic,
                action: toggleItalic,
            },
            {
                icon: Underlined,
                action: toggleUnderline,
            },
            {
                icon: Through,
                action: toggleLineThrough,
            },
            {
                icon: MatchCase,
                action: toggleUppercase,
            },
            {
                icon: alignIcon,
                action: changeTextAlignment,
            },
            {
                icon: Opacity,
                action: toggleBold,
            },
        ];
    };
    const buttons = getButtons(textAlignment);
    const [opacity, setOpacity] = useState(1.0);
    const handleOpacityChange = (event) => {
        const newOpacity = event.target.value / 100;
        setOpacity(newOpacity);
        changeTextOpacity(newOpacity);
        const value =
            ((newOpacity - event.target.min / 100) / (event.target.max / 100)) *
            100;
        event.target.style.setProperty('--value', value + '%');
    };
    useEffect(() => {
        if (activeObjectId) {
            setOpacity(textOpacities[activeObjectId] || 0);
        }
    }, [textOpacities, activeObjectId]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMouseLeave = () => {
        setAnchorEl(null);
    };
    const updateFontFamily = (fontFamily) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({ fontFamily: fontFamily });
            canvas.renderAll();
            setSelectedFont({
                ...selectedFont,
                [textId]: fontFamily,
            });
        }
        updateLayer(activeObject);
    };
    const handleFontFamilyChange = (font) => {
        setSelectedFontFamily(font);
        updateFontFamily(font);
    };
    const updateFontSize = (size) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            setFontSize(size);
            activeObject.set({ fontSize: size });
            canvas.renderAll();
            setFontDimension({
                ...fontDimension,
                [textId]: size,
            });
        }
    };
    return (
        <div className='flex justify-between w-[55%] items-center gap-x-1'>
            <div
                style={{
                    width: '32px',
                    height: '32px',
                    border: '1px solid',
                    borderRadius: '20%',
                    backgroundColor: fontColor,
                    overflow: 'hidden',
                }}
                onClick={(event) => {
                    handleSectionChange('FontColorPalette');
                    event.stopPropagation();
                }}
            ></div>
            <select
                className='bg-white border rounded-lg p-2'
                value={selectedFontFamily}
                onChange={(e) => {
                    handleFontFamilyChange(e.target.value);
                }}
            >
                {fontList.map((font, index) => (
                    <option
                        key={index}
                        value={font}
                    >
                        {font}
                    </option>
                ))}
            </select>
            <div className='rounded-lg border flex overflow-hidden'>
                <button className='flex items-center py-1 px-2 bg-white'>
                    <div
                        className=''
                        onClick={() => {
                            updateFontSize(fontSize - 1);
                        }}
                    >
                        <img
                            src={Minus}
                            alt='ArrowLeft'
                        ></img>
                    </div>
                </button>
                <div className='py-1 px-3 border-l border-r'>{fontSize}</div>
                <button className='flex items-center py-1 px-2 bg-white'>
                    <div
                        onClick={() => {
                            updateFontSize(fontSize + 1);
                        }}
                    >
                        <img
                            src={Plus}
                            alt='ArrowRight'
                        ></img>
                    </div>
                </button>
            </div>
            <div className='flex gap-x-1 relative'>
                {buttons.map((item, index) => (
                    <div className={`${index === 6 ? 'relative' : ''}`}>
                        <button
                            className={`rounded-md flex
                    ${
                        index === 0 && 'bold' === boldIsSelected
                            ? 'bg-[#d0d5dd]'
                            : ''
                    }
                    ${
                        index === 1 && 'italic' === italicIsSelected
                            ? 'bg-[#d0d5dd]'
                            : ''
                    }
                    ${
                        index === 2 && true === underlineIsSelected
                            ? 'bg-[#d0d5dd]'
                            : ''
                    }
                    ${
                        index === 3 && true === linethroughIsSelected
                            ? 'bg-[#d0d5dd]'
                            : ''
                    }
                    ${
                        index === 4 && true === uppercaseIsSelected
                            ? 'bg-[#d0d5dd]'
                            : ''
                    }
                    `}
                            onClick={() => item.action()}
                            onMouseEnter={(e) => {
                                if (index === 6) {
                                    handleMouseEnter(e);
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (index === 6) {
                                    handleMouseLeave(e);
                                }
                            }}
                        >
                            <img
                                src={item.icon}
                                alt=''
                            ></img>
                        </button>
                        {open && index === 6 && (
                            <div
                                className='text-black absolute left-1/2 -translate-x-1/2 bottom-[-70px] min-w-max z-20 rounded bg-white px-2 py-2 flex flex-col'
                                onMouseEnter={(e) => handleMouseEnter(e)}
                                onMouseLeave={(e) => handleMouseLeave(e)}
                            >
                                <div className='flex justify-between'>
                                    <span>Opacità</span>
                                    <div className='flex justify-center items-center p-1 bg-[#f9fafb] rounded-md'>
                                        {Math.round(opacity * 100)}%
                                    </div>
                                </div>
                                <div className=''>
                                    <input
                                        type='range'
                                        id='volume'
                                        name='volume'
                                        min='0'
                                        max='100'
                                        step='1'
                                        value={opacity * 100}
                                        onChange={handleOpacityChange}
                                        style={{
                                            '--value': `${opacity * 100}%`,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
