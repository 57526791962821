import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import defaultImage from "../../../../../Assets/Icons/tempCardImage.png"
import { baseUrl } from '../../../../../Utility/Api/Urls';
import { getUserToken } from '../../../../../Utility/getUserData';
import Loader from './../../../../../Reusable/Loader/Loader';

function TemplateCard({ template, onSelect }) {

    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    // generate an image
    useEffect(() => {
        setIsLoading(true);
        const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/bee/jsonToImage`;
        const axiosConfig = { 
            headers: { Authorization: `Bearer ${getUserToken()}` } 
        };
        const payload = {
            json: template.json,
            width: 512,
            height: 512,
        };

        axios
          .post(url, payload, axiosConfig)
          .then(res => {
            const dataString = `data:image/jpeg;base64,${res.data.data}`;
            setIsLoading(false);
            setImage(dataString);
          })
          .catch(err => {
            toast.error("Errore con l'immagine.");
            setIsLoading(false);
            console.error(err);
          });
    }, [template]);

    return (
        <div className='flex flex-col h-[350px] w-[300px] rounded-md elevation-1 gap-y-1 mx-auto' >
            <div className='w-[307px] h-[155px] mx-auto mb-auto' >
                <img src={image || template?.image || defaultImage} alt={template?.title || template?.name} />
                {isLoading && <Loader />}
            </div>
            <p className='font-normal text-md text-textcolour text-center px-2'>{template?.title || template?.name}</p>
            <div className="flex flex-row justify-center gap-x-5 mb-2" >
                <button className='bg-white rounded-md w-[104.87px] text-textcolour border-2 border-primarybtn py-1 ' >Mostra</button>
                <button onClick={() => onSelect(template)} className='bg-primarybtn rounded-md w-[104.87px] text-white border-2 border-primarybtn py-1' >Scegli</button>
            </div>
        </div>
    )
}

export default TemplateCard
