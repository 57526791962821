import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { GrClose } from "react-icons/gr"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderTopWidth: 18,
    borderTopColor: "#00B27A",
    boxShadow: 5,
    p: 4,
    borderRadius: 5
};
function ChangepasswordModal({ setOpen, open }) {
    const [ShowPassword, SetShowPassword] = React.useState({ old: true, new: true, NewAgain: true })
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableScrollLock
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <button onClick={() => setOpen(false)} style={{ position: "absolute", top: 0, right: 0, margin: 15 }}>
                        <GrClose size={25} color="#00b27A" />
                    </button>
                    <p style={{ fontWeight: "700", fontSize: 22, color: "#00B27A", marginVertical: 10, alignSelf: "center" }} className="mb-8">Modifica Password</p>
                    <p style={{ fontWeight: "600", fontSize: 18, color: "#323232", marginVertical: 10, alignSelf: "center" }} className="mb-2">Vecchia Password</p>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", position: "relative", marginBottom: 10, }} className="mb-4">
                        <input className="pl-3 " type={!ShowPassword.old ? "text" : "password"} style={{ width: "100%", height: 50, backgroundColor: "#F6F6F6", borderRadius: 10, paddingHorizontal: 10, alignSelf: 'center' }} placeholder="Vecchia Password" />
                        <button onClick={() => SetShowPassword({ ...ShowPassword, old: !ShowPassword.old })} style={{ position: "absolute", right: 0, marginRight: 15 }}>
                            {ShowPassword.old ?
                                <FaEye size={25} color="#00B27A" />
                                :
                                <FaEyeSlash size={25} color="#00B27A" />

                            }
                        </button>
                    </div>

                    <p style={{ fontWeight: "600", fontSize: 18, color: "#323232", marginVertical: 10, alignSelf: "center" }} className="mb-2">Nuova Password</p>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", position: "relative", marginBottom: 10, }} className="mb-4">
                        <input className="pl-3 " type={!ShowPassword.new ? "text" : "password"} style={{ width: "100%", height: 50, backgroundColor: "#F6F6F6", borderRadius: 10, paddingHorizontal: 10, alignSelf: 'center' }} placeholder="Nuova Password" />
                        <button onClick={() => SetShowPassword({ ...ShowPassword, new: !ShowPassword.new })} style={{ position: "absolute", right: 0, marginRight: 15 }}>
                            {ShowPassword.new ?
                                <FaEye size={25} color="#00B27A" />
                                :
                                <FaEyeSlash size={25} color="#00B27A" />

                            }
                        </button>
                    </div>

                    <p style={{ fontWeight: "600", fontSize: 18, color: "#323232", marginVertical: 10, alignSelf: "center" }} className="mb-2">Ripeti Password</p>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", position: "relative", marginBottom: 10, }} className="mb-4">
                        <input className="pl-3 " type={!ShowPassword.NewAgain ? "text" : "password"} style={{ width: "100%", height: 50, backgroundColor: "#F6F6F6", borderRadius: 10, paddingHorizontal: 10, alignSelf: 'center' }} placeholder="Ripeti Password" />
                        <button onClick={() => SetShowPassword({ ...ShowPassword, NewAgain: !ShowPassword.NewAgain })} style={{ position: "absolute", right: 0, marginRight: 15 }}>
                            {ShowPassword.NewAgain ?
                                <FaEye size={25} color="#00B27A" />
                                :
                                <FaEyeSlash size={25} color="#00B27A" />

                            }
                        </button>
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginVertical: 10 }}>
                        <button className="text-primarytextcolour" style={{ width: "40%", height: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: "white", alignSelf: "center", marginTop: 30, borderWidth: 1, borderColor: "#00B27A" }}>
                            Annulla
                        </button>
                        <button className="text-white" style={{ width: "45%", height: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: "#00B27A", alignSelf: "center", marginTop: 30 }}>
                            Salva
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ChangepasswordModal