import React from 'react'
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";

import data from "./Data"

function FourthSection() {
    return (
        <>
            <div className="w-[90%] relative mx-auto h-[715px] rounded-md elevation-1 border ">
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold">Piattaforme</p>
                <p className='text-[#AEAEAE] text-left font-normal px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>

                <select className='w-[249px] h-[66px] absolute rounded-md elevation-1 m-8 left-0 pl-2 '>
                    <option>
                        Instagram
                    </option>
                    <option>
                        Facebook
                    </option>
                </select>

                <div className='w-full h-3/4 mt-auto reltaive'>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} margin={{ top: 40, left: 10 }}>
                            <Legend iconType="square" iconSize={40} align="right" verticalAlign="top" height={160} margin={{ top: 10, left: 0, right: 0, bottom: 0 }} />

                            <XAxis dataKey="name" />
                            <YAxis />
                            <Bar dataKey="uv" fill="#8884d8" />
                            <Bar dataKey="pv" fill="#B8DBC1" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    )
}

export default FourthSection
