const fontWeight = {
  200: "Extra-light",
  300: "Light",
  400: "Regular",
  500: "Medium",
  600: "Semi-bold",
  700: "Bold",
  900: "Extra-Bold",
};

const fontNames = [
  "Poppins",
  "Titan One",
  "Yellowtail",
  "Playfair Display",
  "Bebas Neue",
  "Alata",
  "Autour One",
  "Antonio",
  "Alice",
  "Abhaya Libre",
  "Lexend",
  "Jost",
  "Bahiana",
  "Bubblegum Sans",
  "Bayon",
  "Nunito",
  "Baloo 2",
  "Montserrat Alternates",
  "Montserrat",
  "Raleway",
  "Rubik Dirt",
  "Fredoka One",
  "Alfa Slab One",
  "Staatliches",
  "Lobster Two",
  "Great Vibes",
  "Bangers",
  "Handlee",
  "Poiret One",
  "Dancing Script",
  "Bungee Spice",
  "Passion One",
  "Righteous",
  "Passions Conflict",
  "Dosis",
  "Permanent Marker",
  "Imperial Script",
  "Oleo Script",
  "Monoton",
  "Rock Salt",
  "Ranchers",
  "Vast Shadow",
  "Londrina Shadow",
  "Regular Brush",
  "Belleza",
  "Kanit",
  "Quicksand",
  "Cinzel",
  "Asap Condensed",
  "Nerko One",
  "Courgette",
  "Michroma",
  "GuyNeucha",
  "Special Elite",
  "Mark Script",
  "Playball",
  "Syncopate",
  "Berkshire Swash",
  "Merienda",
  "Adamina",
  "Pangolin",
  "Knewave",
  "Overlock",
  "Sofia",
  "Trocchi",
  "Noto serif",
  "Noto Serif Display",
  "Kalam",
  "Barlow Condensed",
];

export default function getCustomFonts() {
  return fontNames
    .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
    .map(fontName => {
      // remove outer spaces, convert to PascalCase, and replace inner spaces with +
      const modifiedName = fontName
        .trim()
        .split(" ")
        .map(n => n[0].toLocaleUpperCase() + n.slice(1))
        .join("+");

      return {
        name: fontName,
        fontFamily: `'${fontName}', Georgia, Times, serif`,
        url: `https://fonts.googleapis.com/css2?family=${modifiedName}:wght@200;300;400;500;600;700;900`,
        fontWeight,
      };
    });
}
