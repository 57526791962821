import Joi from "joi";
import { AutomatedMailType } from "./CardsData";

const GENERIC_ERROR_MESSAGE =
  "C'è stato un errore. Riprova o ricarica la pagina.";
const TEMPLATE_ERROR_MESSAGE =
  'Scegli un template per questa mail e clicca su "Salva".';

export const validationSchema = Joi.object({
  type: Joi.string()
    .valid(...Object.values(AutomatedMailType))
    .required()
    .messages({
      "any.required": GENERIC_ERROR_MESSAGE,
    }),

  subject: Joi.string()
    .min(1)
    .max(150)
    .messages({
      "any.required": "L'oggetto non può essere vuoto.",
      "string.empty": "L'oggetto non può essere vuoto.",
      "string.min": "L'oggetto deve avere almeno un carattere",
    })
    .required(),

  json: Joi.object().required().messages({
    "any.required": TEMPLATE_ERROR_MESSAGE,
    "object.base": TEMPLATE_ERROR_MESSAGE,
  }),

  html: Joi.string().messages({
    "any.required": TEMPLATE_ERROR_MESSAGE,
    "string.base": TEMPLATE_ERROR_MESSAGE,
  }),
  image: Joi.string().messages({
    "any.required": TEMPLATE_ERROR_MESSAGE,
    "string.base": TEMPLATE_ERROR_MESSAGE,
  }),

  specialParameters: Joi.object(),
});

/**
 * Converts dd/mm to a date object
 * @param {string} date String in format dd/mm
 */
export const getDate = date => {
  const [day, month] = date.split("/");
  return new Date(0, Number(month) - 1, day);
};

/**
 * Returns date in string format: day month
 * @param {string} date String in format dd/mm
 */
export const formatDate = date => {
  return new Intl.DateTimeFormat("it-IT", {
    month: "long",
    day: "numeric",
  }).format(getDate(date));
};
