import React, { useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';


function CopyFunction({ copyText, onClick, close }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
        onClick(copyText);
        close()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (

    <div className="flex flex-col w-full h-full gap-y-2">

      <TextareaAutosize type="text" value={copyText} onClick={handleCopyClick} readOnly className="w-full mx-auto text-left h-fit focus:text-black focus:text-xl resize-none rounded-md text-xl border-2 border-marketingbtn text-black  bg-transparent p-4 focus:outline-none placeholder:text-marketing  hover:cursor-pointer hover:bg-marketingbtnhover hover:text-white" />

      <span className="text-marketingtextcolour"> {isCopied ? 'Copiato!' : 'Clicca per copiare'} </span>

    </div>

  );
}

export default CopyFunction
