import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { GrClose } from "react-icons/gr"
import "./Account.css"

function CambiaPeriodoModa({ CambiaModalState, SetCambia }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth > 1000 ? 700 : "95%",
        bgcolor: 'background.paper',
        borderTopWidth: 18,
        borderTopColor: "#00B27A",
        boxShadow: 5,
        p: 4,
        borderRadius: 5
    };

    const [theReson, SetReason] = React.useState(1)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableScrollLock
            open={CambiaModalState}
            onClose={() => SetCambia(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={CambiaModalState}>

                <Box sx={style}>

                    <div className='flex flex-col justify-center items-center gap-8'>





                        <button onClick={() => SetCambia(false)} className="absolute top-0 right-0 m-5">
                            <GrClose size={25} color="#00B27A" />
                        </button>


                        <div className='flex flex-col w-full gap-4'>
                            <p className=" font-semibold text-xl text-primarytextcolour text-center">Cambia periodo di fatturazione</p>





                            <div id="RadiosCancella" className='flex flex-row gap-x-3 mt-3 w-full justify-evenly gap-y-3 sm:flex-col md:flex-col xsm:flex-col' >
                                <label onClick={() => SetReason(1)} for="one"
                                    className={`flex flex-col gap-y-3 rounded-lg border-2 ${theReson === 1 ? "border-green-400 " : "border-gray-400 "} py-5 px-8`} >
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input checked={theReson === 1} id="one" value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <p className="  font-semibold text-black xsm:text-sm">Pagamento Trimestrale</p>
                                    </div>
                                    <p className="  font-normal text-black ml-9 xsm:text-sm">  900,00 € al trimestre</p>
                                </label>

                                <label onClick={() => SetReason(2)} for="two"
                                    className={`flex flex-col gap-y-3 rounded-lg border-2 ${theReson === 2 ? "border-green-400 " : "border-gray-400 "} py-5 px-8 `} >
                                    <div className='flex flex-row gap-x-4 items-center' >
                                        <input checked={theReson === 2} id="two" value="Dashboard" type="radio" name='reason' className="w-5 h-5" />
                                        <p className="  font-semibold text-black xsm:text-sm">Pagamento Annuale</p>
                                    </div>
                                    <p className="  font-normal text-black ml-9 xsm:text-sm"> <span className="line-through mr-2" >4200,00 €</span>  3750,00 € </p>
                                </label>




                            </div>
                            {theReson === 2 &&
                                <p className={`font-base text-center font-normal text-deletebtn  `}>
                                    Questo metodo di pagamento sarà disponibile il 26/12/22, al termine del tuo attuale contratto
                                </p>
                            }



                        </div>








                        <div className='flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-4'>


                            <button
                                onClick={() => SetCambia(false)}
                                className={`w-40 h-10 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white`}>
                                Salva
                            </button>



                            <button onClick={() => SetCambia(false)} className="w-40 h-10 rounded-md  text-deletebtn bg-white border-2 border-deletebtn hover:text-white hover:bg-deletebtnhover hover:border-deletebtnhover active:shadow-innerbig active:border-white">
                                Annulla
                            </button>


                        </div>

                    </div>

                </Box>

            </Fade>

        </Modal>
    )
}

export default CambiaPeriodoModa