import React, { createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken, getUserData } from "../Utility/getUserData";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";

export const RestaurantInfoContext = createContext();

export default function RestaurantInfoContextProvider({ children }) {
  const [RestaurantInfo, SetResData] = React.useState();
  const [RestaurantInfoFULL, SetFULL] = React.useState();
  const userData = getUserData();

  async function GetRestaurantInfo() {
    try {
      const res = await axios.get(
        `https://${userData?._id}.${
          process.env.REACT_APP_THEMES_PREFIX
        }${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/restaurant-info`,
      );
      SetResData(res?.data.theme_photos);
      SetFULL(res?.data);
    } catch (error) {
      toast.error(
        "Errore nel caricamento dei tuoi dati. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto",
      );
    }
  }

  async function PatchNewInfo(imageObject) {
    GetRestaurantInfo();
    const payload = { ...RestaurantInfo, ...imageObject };
    try {
      const res = await axios.patch(
        `https://${userData?._id}.${
          process.env.REACT_APP_THEMES_PREFIX
        }${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/restaurant-info`,
        { theme_photos: payload },
        getAuthConfig(),
      );
      return res;
    } catch (error) {
      toast.error(
        "Errore durante il salvataggio dei dati. Riprova o contatta il nostro supporto nella sezione Supporto",
      );
    }
  }

  React.useEffect(() => {
    if (getToken) {
      GetRestaurantInfo();
    }
  }, [getToken]);

  const value = {
    RestaurantInfo,
    PatchNewInfo,
    SetResData,
    RestaurantInfoFULL,
  };

  return (
    <RestaurantInfoContext.Provider value={value}>
      {children}
    </RestaurantInfoContext.Provider>
  );
}
