import React, { useState } from "react";
import * as ThemeCustomerAPI from "../../../Utility/Api/ThemesCustomerAPI";
import "./Customers.css";
import Avatar1 from "../../../Assets/CustomersRandomAvatars/Frame 11.jpg";
import Avatar10 from "../../../Assets/CustomersRandomAvatars/Frame 20.jpg";
import Avatar11 from "../../../Assets/CustomersRandomAvatars/Frame 21.jpg";
import Avatar12 from "../../../Assets/CustomersRandomAvatars/Frame 22.jpg";
import Avatar2 from "../../../Assets/CustomersRandomAvatars/Frame 12.jpg";
import Avatar3 from "../../../Assets/CustomersRandomAvatars/Frame 13.jpg";
import Avatar4 from "../../../Assets/CustomersRandomAvatars/Frame 14.jpg";
import Avatar5 from "../../../Assets/CustomersRandomAvatars/Frame 15.jpg";
import Avatar6 from "../../../Assets/CustomersRandomAvatars/Frame 16.jpg";
import Avatar7 from "../../../Assets/CustomersRandomAvatars/Frame 17.jpg";
import Avatar8 from "../../../Assets/CustomersRandomAvatars/Frame 18.jpg";
import Avatar9 from "../../../Assets/CustomersRandomAvatars/Frame 19.jpg";
import CreateCustomerModal from "./CreateCustomerModal";
import ExportCSVButton from "./CSV_Button";
import ImportCSVButton from "./CSVImporter";
import MenuItem from "@mui/material/MenuItem";
import ModalContent from "./CustomerInfoModal/ModalContent";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Select from "@mui/material/Select";
import moment from "moment/moment";
import { newsletterSubscribe } from "../../../Utility/Api/ThemesCustomerAPI";
import { activeStatus } from "../../../Utility/Api/ThemesCustomerAPI";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "react-modal";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";

function CustomersMain({ flags }) {
  const [CustomersList, SetCustomers] = useState([]);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [isLoading, SetLoading] = React.useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [forReload, SetReload] = React.useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [activePageForPagination, setactivePage] = useState(0);
  const [filters, setFilters] = useState("Tutti");
  const [filtreCustomers, setFiltreCustomers] = useState([]);
  const [newsletterOption, setNewsletterOption] = useState("default");
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function getAllCustomers() {
    SetLoading(true);
    try {
      const all = await ThemeCustomerAPI.list();
      SetCustomers(
        all.data.sort((a, b) => {
          if (!a.dob) return 1;
          if (!b.dob) return -1;

          const dateA = new Date(a.dob);
          const dateB = new Date(b.dob);
          return dateB.getTime() - dateA.getTime();
        }),
      );
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }
  React.useEffect(() => {
    getAllCustomers();
  }, [forReload]);

  function handleSearch() {
    const filtered = CustomersList.filter((item) => {
      const toSearch = SearchTerm.toLowerCase().split(" ");
      return toSearch.some((val) => {
        return (
          item.firstName?.toLowerCase().startsWith(val) ||
          item.lastName?.toLowerCase().startsWith(val) ||
          item.email?.toLowerCase().startsWith(val) ||
          item.phoneNumber?.toLowerCase().startsWith(val)
        );
      });
    });
    return filtered;
  }

  async function creatCustomer(customer) {
    try {
      const fields = {
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phoneNumber,
      };
      const body = {
        fields,
        sharedFieldNames: Object.keys(fields),
      };
      const res = await ThemeCustomerAPI.create(body);
      console.log(res);
      SetReload(Math.random());
      handleCloseCreationModal();
    } catch (error) {
      console.log(error);
      // * Manage Backend tasks from FrontEnd
      if (error.response.data.error.includes("400"))
        return toast.error("Inserisci un numero di Telefono Valido");
      if (error.response.data.error.includes("500"))
        return toast.error("La mail inserita è già inserita nel database");
    }
  }

  async function handleDeleteCustomer(id) {
    setOpen(false);
    try {
      const res = await ThemeCustomerAPI.remove(id);
      SetReload(Math.random());
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }
  //Create Customer Modal -------------------------------------------------------------
  const [customerCreationModal, SetCustomerCreationModal] =
    React.useState(false);
  const handleOpenCreationModal = () => SetCustomerCreationModal(true);
  const handleCloseCreationModal = () => SetCustomerCreationModal(false);
  //Finish---------------------------------------------------------------------------------

  //Single Customer Modal-----------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [singleCustomer, SetCustomer] = React.useState();
  const handleOpen = (email) => {
    const singleCus = CustomersList?.filter((item) => item.email === email)[0];
    SetCustomer(singleCus);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  //FINISH---------------------------------------------------------------------------------
  // React.useEffect(() => {
  //   const reloadCount = sessionStorage.getItem('reloadCount');
  //   if (reloadCount < 1) {
  //     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem('reloadCount');
  //   }
  // }, [])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 9) % CustomersList.length;
    setItemOffset(newOffset);
  };

  React.useEffect(() => {
    if (
      CustomersList.length < 9 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      setCurrentItems(CustomersList);
      setPageCount(Math.ceil(CustomersList.length / 9));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      CustomersList.length >= 9 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 9;
      setCurrentItems(CustomersList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(CustomersList.length / 9));
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length < 9 &&
      !SearchTerm.length
    ) {
      setCurrentItems(filtreCustomers);
      setPageCount(Math.ceil(filtreCustomers.length / 9));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length >= 9 &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 9;
      setCurrentItems(filtreCustomers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filtreCustomers.length / 9));
      setactivePage(0);
    } else if (SearchTerm.length && handleSearch().length) {
      setCurrentItems(handleSearch());
      setPageCount(Math.ceil(handleSearch().length / 9));
      setItemOffset(0);
      setactivePage(0);
    } else {
      const endOffset = itemOffset + 9;
      setCurrentItems(handleSearch().slice(itemOffset, endOffset));
      setPageCount(Math.ceil(handleSearch().length / 9));
      setactivePage(0);
    }
  }, [itemOffset, CustomersList, filtreCustomers, SearchTerm]);

  const handleChange = (event) => {
    const { value } = event.target;
    setFilters(typeof value === "string" ? value.split(",") : value);
    if (value === "Tutti") return setFiltreCustomers(CustomersList);
    // @todo: Check the logic given that we are setting 2 different states that may not be related.
    setFiltreCustomers(
      CustomersList.filter((e) =>
        e.tags.some((v) => v === value.toUpperCase()),
      ),
    );
  };
  const [isNotCard, SetNotCard] = React.useState(false);
  const randomAvatars = [
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
    Avatar7,
    Avatar8,
    Avatar9,
    Avatar10,
    Avatar11,
    Avatar12,
  ];

  function randomIntFromInterval() {
    // min and max included
    return Math.floor(Math.random() * (randomAvatars.length - 1));
  }
  const [selectedCustomers2, setSelectedCustomers2] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const handleCustomerSelect = (customer) => {
    if (selectedCustomers.includes(customer)) {
      setSelectedCustomers(selectedCustomers.filter((c) => c !== customer));
    } else {
      setSelectedCustomers([...selectedCustomers, customer]);
    }
  };

  const handleSubscribeSelectedCustomers = () => {
    let shouldReloadPage = false; // Variabile per tracciare se è necessario ricaricare la pagina

    selectedCustomers.forEach((customer) => {
      if (newsletterOption === "Iscrivi" || newsletterOption === "Disiscrivi") {
        if (customer.customerIsActive === true) {
          shouldReloadPage = true; // Imposta la variabile a true se almeno un cliente è attivo

          const newsletterSubscribed = newsletterOption === "Iscrivi";
          newsletterSubscribe(customer.id, { newsletterSubscribed })
            .then((response) => {
              // Gestisci la risposta in caso di successo
            })
            .catch((error) => {
              console.error(
                "Errore durante l'iscrizione/disiscrizione:",
                error,
              );
            });
        } else {
          window.alert(
            "Impossibile Iscrivere o Disiscrivere un cliente inattivo. Messaggio personalizzato qui.",
          );
        }
      } else if (
        newsletterOption === "Attiva" ||
        newsletterOption === "Disattiva"
      ) {
        shouldReloadPage = true; // Imposta la variabile a true per qualsiasi opzione "Attiva" o "Disattiva"

        const customerData = {
          customerIsActive: newsletterOption === "Attiva",
        };
        activeStatus(customer.id, customerData)
          .then((response) => {
            // Gestisci la risposta in caso di successo
          })
          .catch((error) => {
            console.error(
              "Errore durante l'attivazione/disattivazione:",
              error,
            );
          });
      }
    });

    if (shouldReloadPage) {
      setModalIsOpen(false);
      window.location.reload();
    }
  };

  const [BloccoModal, setBloccoModal] = useState(false);

  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    setNewsletterOption(selectedOption);

    const isIscriviOrDisiscrivi =
      selectedOption === "Iscrivi" || selectedOption === "Disiscrivi";
    const hasInactiveCustomers = selectedCustomers.some(
      (customer) => !customer.customerIsActive,
    );

    if (isIscriviOrDisiscrivi && hasInactiveCustomers) {
      // Mostra un messaggio di avviso o un modale per informare che non è possibile operare su clienti inattivi
      setBloccoModal(true);
    } else {
      // Continua con la gestione normale
      setShowConfirmButton(true);
      setModalIsOpen(true);
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [customerIdToActivate, setCustomerIdToActivate] = useState(null);

  const handleCustomerSelect2 = (customerId) => {
    // Trova il cliente con l'ID corrispondente nella tua lista di clienti
    const customer = currentItems.find((cust) => cust.id === customerId);
    if (customer) {
      // Controlla se il cliente è già stato selezionato
      if (selectedCustomers2.includes(customer)) {
        // Deseleziona il cliente
        setSelectedCustomers2(
          selectedCustomers2.filter((cust) => cust !== customer),
        );
      } else {
        // Seleziona il cliente e mostra il modale di conferma
        setCustomerIdToActivate(customer.id);
        setShowConfirmationModal(true);
      }
    }
  };

  const confirmActivation = () => {
    // Chiama l'API
    const customerData = {
      customerIsActive: true,
    };
    activeStatus(customerIdToActivate, customerData)
      .then((response) => {
        // Gestisci la risposta se necessario
      })
      .catch((error) => {
        console.error("Errore durante l'attivazione", error);
      });

    // Chiudi il modale di conferma
    setShowConfirmationModal(false);

    // Ricarica la pagina
    window.location.reload();
  };

  console.log(flags);

  const filterBy = ["Tutti", "Cliente", "Iscritto alla newsletter"];
  return (
    <div className="flex flex-col m-2 rounded-lg border border-solid border-gray-200 bg-white shadow-sm">
      <div className="flex flex-col  gap-y-3 items-center w-full mx-auto h-fit  ">
        <div className="flex flex-col w-full ">
          <div className="p-3 flex flex-row gap-3 justify-between">
            <div className="">
              <div className="flex flex-row">
                <div className="text-center textfont h-full flex flex-col justify-center">
                  <p className="text-2xl font-medium textfont">Clienti</p>
                </div>
                <div className="text-center bg-[#EBFFF9] p-2 px-3 text-[#008F62] rounded-2xl textfont mx-5 h-full flex flex-col justify-center">
                  <p className="text-base font-medium textfont ">
                    {CustomersList.length} totali{" "}
                  </p>
                </div>
              </div>
              <p className="text-lg text-[#667085] textfont ">
                {" "}
                Ordina, filtra e gestisci. Puoi selezionare uno e più clienti.
              </p>
            </div>
            <div className="p-3 ">
              <div className="flex flex-row gap-x-2 xsm:flex-col gap-y-2 xmd:justify-between md:justify-between sm:justify-between xsm:justify-between  ">
                {flags.clientiImportCsvButton && <ImportCSVButton />}

                <ExportCSVButton />
                <button
                  onClick={() => handleOpenCreationModal()}
                  className=" h-10 px-4 elevation-1 rounded-md text-white textfont bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig sm:-w-full xsm:w-full">
                  <AddCircleOutlineIcon className=" m-1" />
                  Aggiungi Cliente
                </button>
                {/*<button onClick={handlePrintSelectedCustomers}>Stampa selezionati</button>*/}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-3 justify-between ">
            <div className="w-1/3 px-3">
              <input
                value={SearchTerm}
                onChange={(e) => SetSearchTerm(e.target.value)}
                type="text"
                placeholder="Cerca"
                className=" w-full h-11 px-2 border-2 rounded-md "
              />
            </div>
            <div className="flex flex-row gap-3 mx-5">
              <Select
                sx={{ "& > fieldset": { border: "none" } }}
                className="w-full h-11 rounded-md border-2  "
                value={filters}
                onChange={handleChange}
                defaultValue={filters[0]}>
                {filterBy?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <Select
                sx={{ "& > fieldset": { border: "none" } }}
                className="w-full h-11 rounded-md border-2"
                value={newsletterOption}
                onChange={handleOptionChange}>
                <MenuItem value="default">Azioni</MenuItem>
                <MenuItem value="Iscrivi">Iscrivi alla newsletter</MenuItem>
                <MenuItem value="Disiscrivi">
                  Disiscrivi alla newsletter
                </MenuItem>
                <MenuItem value="Attiva">Attiva Clienti</MenuItem>
                <MenuItem value="Disattiva">Disattiva Clienti</MenuItem>
              </Select>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Confirm Modal"
                className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4 p-4 bg-white rounded-md border shadow-sm">
                <div className="text-left textfont h-full flex flex-col justify-center my-5 ">
                  <p className="text-2xl font-medium textfont">
                    Conferma l'azione
                  </p>
                  <p className="text-lg textfont">
                    Sei sicuro di voler confermare questa azione?
                  </p>
                </div>

                <div className="flex flex-row gap-5 my-5 ">
                  <button
                    onClick={handleSubscribeSelectedCustomers}
                    className=" h-10 px-4 elevation-1 rounded-md text-white textfont bg-primarybtn border-2 border-primarybtn
                               hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig sm:-w-full xsm:w-full">
                    Conferma
                  </button>
                  <button
                    className=" h-10 px-4 elevation-1 rounded-md textfont  border-2 border sm:-w-full xsm:w-full"
                    onClick={() => setModalIsOpen(false)}>
                    Annulla
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Conferma Modal"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4 p-4 bg-white rounded-md border shadow-sm">
          <div className="text-left textfont h-full flex flex-col justify-center my-5 ">
            <p className="text-2xl font-medium textfont">
              Conferma l'attivazione
            </p>
            <p className="text-lg textfont">
              Sei sicuro di voler attivare questo Cliente?
            </p>
          </div>

          <div className="flex flex-row gap-5 my-5 ">
            <button
              onClick={confirmActivation}
              className=" h-10 px-4 elevation-1 rounded-md text-white textfont bg-primarybtn border-2 border-primarybtn
                               hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig sm:-w-full xsm:w-full">
              Conferma{" "}
            </button>
            <button
              className=" h-10 px-4 elevation-1 rounded-md textfont  border-2 border sm:-w-full xsm:w-full"
              onClick={() => setShowConfirmationModal(false)}>
              Annulla
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={BloccoModal}
          onRequestClose={() => setBloccoModal(false)}
          contentLabel="Blocco Modal"
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/4 bg-white rounded-md border shadow-sm p-5">
          <div className="text-left textfont h-full flex flex-col justify-center my-5 ">
            <p className="text-2xl font-medium textfont text-[#D92D20]">
              Cliente Inattivo
            </p>
            <p className="text-lg textfont">
              Non puoi operare su Clienti inattivi. Attivali prima di procedere
            </p>
          </div>

          <div className="flex flex-row gap-5 ">
            <button
              className=" h-10 px-4 elevation-1 rounded-md textfont  border-2 border sm:-w-full xsm:w-full"
              onClick={() => setBloccoModal(false)}>
              Indietro
            </button>
          </div>
        </Modal>

        {
          <table className="w-full">
            <thead className="">
              <tr className="">
                <th className=" border p-3 table-header-th text-[#667085]">
                  Nome
                </th>
                <th className=" border p-3 table-header-th text-[#667085]">
                  Numero di telefono{" "}
                </th>
                <th className=" border p-3 table-header-th text-[#667085]">
                  Data di nascita
                </th>
                <th className=" border p-3 table-header-th text-[#667085]">
                  Altro
                </th>
                <th className=" border p-3 table-header-th text-[#667085]"></th>
              </tr>
            </thead>
            {!isLoading &&
              currentItems.map((customer, index) => (
                <tbody
                  key={index}
                  onMouseEnter={() => SetNotCard(false)}
                  onMouseLeave={() => SetNotCard(true)}
                  className={
                    index % 2 === 0 ? "table-row-even" : "table-row-odd"
                  }>
                  <tr
                    className={`border-b ${
                      customer.customerIsActive
                        ? "text-[#101828]"
                        : "text-gray-300"
                    }`}>
                    <td className="flex flex-row p-2">
                      <div className="flex p-2 px-5">
                        <input
                          type="checkbox"
                          checked={selectedCustomers.includes(customer)}
                          onChange={() => handleCustomerSelect(customer)}
                        />
                      </div>
                      <div>
                        <p className="font-semibold text-left capitalize text-base textfont">
                          {customer?.firstName} {customer?.lastName}
                        </p>
                        <p className="font-normal text-left text-base textfont">
                          {customer?.email}
                        </p>
                      </div>
                    </td>
                    <td className="p-3">
                      {" "}
                      <p className="font-normal text-left text-base p-2 textfont">
                        {" "}
                        {customer?.phoneNumber
                          ? customer?.phoneNumber
                          : "Non fornito"}{" "}
                      </p>
                    </td>
                    <td className="p-3">
                      {" "}
                      <p className="font-normal text-left text-base p-2 textfont">
                        {customer?.dob
                          ? moment(customer?.dob).format("DD/MM/YYYY")
                          : "Non disponibile"}
                      </p>
                    </td>
                    <td className="p-3 flex flex-row">
                      {!customer.customerIsActive ? (
                        <div className="p-1 px-2 rounded-full bg-[#FFF1F3] text-[#C01048] text-center textfont">
                          Inattivo
                        </div>
                      ) : (
                        <div className="flex flex-row">
                          {customer.newsletterSubscribed && (
                            <div className="p-1 px-2 rounded-full bg-[#EBFFF9] text-[#008F62] text-center textfont">
                              Newsletter
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    <td className="px-5">
                      {!customer.customerIsActive && (
                        <div className="flex flex-row">
                          <div key={customer.id}>
                            <button
                              onClick={() => handleCustomerSelect2(customer.id)}
                              className="flex items-center justify-center p-2 px-3 rounded-lg border border-gray-300 bg-white shadow-xs textfont text-[#667085]">
                              Attiva
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="flex flex-row">
                        <div className="flex flex-row">
                          <Link to={`/customer/${customer.id}`}>
                            <button className="flex items-center justify-center p-2 px-3 rounded-lg border border-gray-300 bg-white shadow-xs textfont text-[#667085]">
                              Vedi
                            </button>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        }
        {CustomersList.length > 9 && activePageForPagination !== null && (
          <div className=" w-full flex items-center my-5">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
              pageCount={pageCount}
              // forcePage={activePageForPagination}
              nextLabel="❯"
              nextClassName="text-primarytextcolour text-2xl"
              pageClassName="rounded-md text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-primarybtn border-primarybtn border-2 hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig active:bg-primarybtn active:border-white active:text-white"
              pageLinkClassName="w-full h-full items-center justify-center flex"
              previousLabel="❮"
              previousClassName="text-primarytextcolour text-2xl"
              activeClassName="text-white flex w-10 h-10 rounded-md text-center border font-semibold items-center justify-center bg-primarybtn border-primarybtn border-2 hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig active:bg-primarybtn active:border-white"
              containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  "
              renderOnZeroPageCount={false}
              marginPagesDisplayed={1}
            />
          </div>
        )}
      </div>
      <CreateCustomerModal
        customerCreationModal={customerCreationModal}
        handleCloseCreationModal={handleCloseCreationModal}
        creatCustomer={creatCustomer}
      />
      {singleCustomer && open && (
        <ModalContent
          open={open}
          handleClose={handleClose}
          Data={singleCustomer}
        />
      )}
    </div>
  );
}

export default withLDConsumer()(CustomersMain);
