import { useContext } from "react";
import { SocialMediaContext } from "../../Context/SocialMediaContext";
import SocialCheckboxes from './SocialCheckboxes';

export default function PostView({ }) {
    const { image, description, hashtags } = useContext(SocialMediaContext);

    return (
        <>
            <div
                className="flex flex-col gap-y-2 items-center relative   bg-white p-5 rounded-md w-[550px] sm:w-full xsm:w-full justify-evenly">
                <p className=" text-socialmediatextcolour font-semibold  text-2xl ">
                    Scegli dove pubblicare il tuo Post
                </p>

                <div className="flex flex-row w-fit mx-auto gap-x-3">
                    <SocialCheckboxes />
                </div>

                <div className="flex flex-row w-full gap-x-3 mt-4 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col overflow-hidden">
                    <div className="flex flex-col w-3/5 h-[305px] gap-y-4 xsm:w-full sm:w-full md:w-full xmd:w-full ">
                        <img alt="" src={image} className="object-cover w-full h-full" />
                    </div>
                    <div
                        className="flex flex-col gap-y-4 w-2/5 xsm:w-full sm:w-full md:w-full xmd:w-full border-l-2  xsm:border-t-2 sm:border-t-2 md:border-t-2 xmd:border-t-2 xsm:border-l-0 sm:border-l-0 md:border-l-0 xmd:border-l-0 pl-2 overflow-wrap">
                        <p className="text-tiny text-left mb-4  whitespace-pre-line">{description}</p>

                        <div className="flex flex-row flex-wrap gap-2 mt-auto ml-auto">
                            {hashtags?.map(h =>
                                <span
                                    class="inline-block bg-themesettingstextcolour whitespace-nowrap rounded-full bg-neutral-50 px-[0.65em] pt-[0.35em] pb-[0.25em] text-center align-baseline text-[0.75em] font-light leading-none text-tiny text-left text-primary-700">
                                    {h}
                                </span>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
