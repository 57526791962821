import { useEffect } from "react";
import { createContext, useState } from "react";

import * as helpers from "../Utility/marketingHelpers";
import formatCurrency from "../Utility/formatCurrency";

const INITIAL_STATE = {
  campaign: null,
  isSuggestedCampaign: false,
  handleCampaignSet: () => {},

  externalBuyerPersona: null,
  selectedBuyerPersona: null,
  isOtherPersona: false,
  handleExternalBuyerPersonaSet: () => {},

  copy: null,
  handleCopyChange: () => {},

  image: null,
  handleImageSet: () => {},
  handleBuyerPersonaSelected: () => {},

  selectedTemplateJson: null,
  handleTemplateSelected: () => {},

  packages: null,
  originalPackages: null,
  selectedPackage: null,
  handlePackagesSet: () => {},
  handlePackagesLoaded: () => {},
  handlePackageSelected: () => {},
  handleDurationSet: () => {},

  suggestedNumberOfDays: null,
  numberOfDays: null,
  startDate: null,
  endDate: null,
  handleStartDateChange: () => {},
  handleEndDateChange: () => {},
};

export const MarketingCampaignContext = createContext(INITIAL_STATE);

export default function MarketingCampaignProvider({ children }) {
  //-------------- CAMPAIGN AND BUYER PERSONA --------------//
  const [campaign, setCampaign] = useState();
  const isSuggestedCampaign = !!campaign?.campaign_id;

  const handleCampaignSet = (campaign) => setCampaign(campaign);

  const [selectedGoal, setSelectedGoal] = useState("");

  const handleGoalSelected = (goal) => setSelectedGoal(goal);

  const [externalBuyerPersona, setExternalBuyerPersona] = useState();
  const [selectedBuyerPersona, setSelectedBuyerPersona] = useState();
  const isOtherPersona = helpers.isOtherPersona(externalBuyerPersona?.value);

  const [currentBalance, setCurrentBalance] = useState(null);

  const handleBuyerPersonaSelected = (persona) =>
    setSelectedBuyerPersona(persona);
  const handleExternalBuyerPersonaSet = (persona) =>
    setExternalBuyerPersona(persona);

  // if we get external persona (from buyer persona pages), set the current persona
  // useEffect(() => {
  //   if (externalBuyerPersona) setSelectedBuyerPersona(externalBuyerPersona);
  // }, [externalBuyerPersona]);

  //---------------------------- COPY ----------------------------//
  const [copy, setCopy] = useState("");

  const handleCopyChange = (text) => setCopy(text);

  //---------------------------- BEE DATA ----------------------------//
  const [image, setImage] = useState();

  //---------------------------- TEMPLATES ----------------------------//
  const [selectedTemplateJson, setSelectedTemplateJson] = useState(null);

  const handleTemplateSelected = (templateJson) =>
    setSelectedTemplateJson(templateJson);

  //---------------------------- PACKAGES ----------------------------//
  const [packages, setPackages] = useState([]);
  const [originalPackages, setOriginalPackages] = useState();
  const [selectedPackage, setSelectedPackage] = useState();

  const handlePackagesSet = (mappedPackages) => setPackages(mappedPackages);

  const handlePackagesLoaded = (originalPackages) =>
    setOriginalPackages(originalPackages);

  const handlePackageSelected = (p) => setSelectedPackage(p);

  const handleDurationSet = (numDays, suggestedNumDays, endDate) => {
    setNumberOfDays(numDays);
    setSuggestedNumberOfDays(suggestedNumDays);
    setEndDate(endDate);
  };

  //------------- SCHEDULING: SUGGESTED TIME + TIME PICKER -------------//
  const [suggestedNumberOfDays, setSuggestedNumberOfDays] = useState(10);
  const [numberOfDays, setNumberOfDays] = useState(10);

  const [startDate, setStartDate] = useState(helpers.getDateNow());
  const [endDate, setEndDate] = useState(helpers.getDateAfterTenDays());
  const [number, setNumber] = useState("");
  const handleStartDateChange = (dateString) => {
    const newStartDate = new Date(dateString);
    if (newStartDate.getTime() > Date.now()) setStartDate(dateString);
  };

  const handleEndDateChange = (date) => setEndDate(date);

  // calculate number of days whenever the start or end dates change
  useEffect(() => {
    setNumberOfDays(helpers.getNumberOfDaysBetween(startDate, endDate));
  }, [startDate, endDate]);

  // TODO: create initial state for reset, and combine related states into objects
  const resetForm = () => {
    setCampaign(null);

    setExternalBuyerPersona(null);
    setSelectedBuyerPersona(null);

    setCopy(null);

    setImage(null);
    setSelectedTemplateJson(null);

    setPackages([]);
    setSelectedPackage(null);
    setOriginalPackages(null);

    setSuggestedNumberOfDays(10);
    setNumberOfDays(10);
    setStartDate(helpers.getDateNow());
    setEndDate(helpers.getDateAfterTenDays());
  };

  const totalBudget = isSuggestedCampaign
    ? campaign.total_spend
    : numberOfDays * (number || packages[selectedPackage]?.budget);

  const value = {
    campaign,
    isSuggestedCampaign,
    handleCampaignSet,

    externalBuyerPersona,
    selectedBuyerPersona,
    isOtherPersona,
    handleExternalBuyerPersonaSet,

    copy,
    handleCopyChange,

    image,
    setImage,
    handleBuyerPersonaSelected,

    selectedTemplateJson,
    handleTemplateSelected,

    packages,
    originalPackages,
    selectedPackage,
    handlePackagesSet,
    handlePackagesLoaded,
    handlePackageSelected,
    handleDurationSet,

    suggestedNumberOfDays,
    numberOfDays,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    setNumber,
    number,
    resetForm,
    selectedGoal,
    setSelectedGoal,
    handleGoalSelected,
    totalBudget,
    setCurrentBalance,
    currentBalance,
  };

  return (
    <MarketingCampaignContext.Provider value={value}>
      {children}
    </MarketingCampaignContext.Provider>
  );
}
