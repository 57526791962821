import React from "react";
import ScrollToTop from '../../../../Reusable/ScrollToTop';
import Draftnewsletterlist from "./Draft Newsletter List/Draftnewsletterlist";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import './DraftNewsletter.css';
import NewsNavigation from "../NewsNavigation/NewsNavigation";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner')).default;
}

function DraftNewsletter() {

  return (

    <div className="w-full mx-auto ">

    <InformationTopBanner

      pagetype="Newsletter"
      sectionname="In Bozza"
      description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'In questa sezione puoi trovare tutte le bozze che hai realizzato per le tue mail o per le tue campagne di Mail Automatiche, es. Registrazione, Compleanni, Carrello Abbandonato, ecc.'}
      sectionicon={NewsletterHeaderLottie}

    />       
          
      <div className=" p-5  ">

        <NewsNavigation />

      </div>

      <ScrollToTop />

      <div className="flex flex-col px-4 pb-10 xsm:p-0 sm:p-0 md:p-0 xmd:p-0">

        <Draftnewsletterlist />

      </div>

    </div>

  );
}

export default DraftNewsletter;
