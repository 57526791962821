import React from 'react'
import { useNavigate } from 'react-router';
import DashNavigation from '../DashNavigation/resultsnavigation'
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import ChangepasswordModal from "./EmailAndPassword/ChangepasswordModal";
import ChangeEmailModal from "./EmailAndPassword/ChangeEmailModal";
import "./Account.css";
import EmailInupt from "./EmailAndPassword/EmailInupt";
import PasswordInput from "./EmailAndPassword/PasswordInput";
import CancellaAbbonamentoModal from "./CancellaAbbonamentoModal";
import CambiaPeriodoModa from "./CambiaPeriodoModa";
import Pagamento from "../../../../Assets/Images/logos_mastercard.png";

import CheckBox from "./Plans/CheckBox";
import Divider from '@mui/material/Divider';


function IndexSubscription() {

    const [isPasswordModalOpen, setOpen] = React.useState(false);
    const [isEmaildModalOpen, setOpen2] = React.useState(false);
    const [CancellaAbbonamentoModalState, SetCancellaModal] = React.useState(false)
    const [CambiaModalState, SetCambia] = React.useState(false)
    const [patchData, setPatchData] = React.useState();
    const navigate = useNavigate()




    const [isChoosen, SetChoose] = React.useState([1]);


    const EspressoIsChoosen = isChoosen.includes(1);
    const ViandanteIsChoosen = isChoosen.includes(2);
    const TavolaIsChoosen = isChoosen.includes(3);



    return (
        <div className='flex flex-col mt-2 mb-6 w-[95%] mx-auto' >

            <div className='flex xsm:mx-auto sm:mx-auto md:mx-auto'>

                <DashNavigation />

            </div>

            <div className="flex flex-col w-full">



                <ScrollToTop />

                <ChangepasswordModal open={isPasswordModalOpen} setOpen={setOpen} />
                <ChangeEmailModal open={isEmaildModalOpen} setOpen2={setOpen2} />
                <CancellaAbbonamentoModal CancellaAbbonamentoModalState={CancellaAbbonamentoModalState} SetCancellaModal={SetCancellaModal} />
                <CambiaPeriodoModa CambiaModalState={CambiaModalState} SetCambia={SetCambia} />

                <div className="flex flex-col gap-y-6 py-8 rounded-lg" >


                    <div className="flex flex-col gap-y-6" >

                        <div className=" flex flex-row xsm:flex-col sm:flex-col rounded-lg p-2 pl-4 pr-3 bg-[#D9F3EB]" >
                            <div className="flex flex-row gap-2  w-[80%] xsm:flex-col sm:flex-col" >
                                <p className="font-bold text-left text-primarybtn  w-fit py-3 "> Il tuo abbonamento si rinnova il </p>
                                <p className="font-bold text-left text-primarybtn  w-fit py-3 "> 26/04/2023 </p>
                            </div>
                            <div className=" w-[20%]  sm:w-full  xsm:w-full md:w-[40%] flex flex-row gap-x-6 md:flex-col xsm:flex-col sm:flex-col xmd:flex-col gap-y-2 items-center justify-end" >

                                <button
                                    onClick={() => navigate("/dashboard/account/subscription/plans")}
                                    className="w-fit px-3 h-10 rounded-md  bg-primarybtn elevation-2 text-white border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white">
                                    Cambia Piano
                                </button>
                            </div>

                        </div>




                        <div className="flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-8 m-2">
                            <div
                                className={` border-2 ${EspressoIsChoosen ? "border-[#114653] " : "border-[#D9D9D9]"
                                    } w-full flex flex-col justify-between  h-[150px]  py-5   `}
                            >
                                <div className="flex flex-row justify-between items-center px-3">
                                    <p
                                        className={`${EspressoIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                            }  text-xl font-semibold `}
                                    >
                                        L’Espresso
                                    </p>
                                    <div className="flex flex-row items-center justify-end ">
                                        <p
                                            className={`text-base ${EspressoIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                                }  font-normal text-base`}
                                        >
                                            Scegli{" "}
                                        </p>
                                        <CheckBox
                                            id={1}
                                            SetChoose={SetChoose}
                                            isChoosen={isChoosen}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`w-[105%] -ml-3 ${EspressoIsChoosen ? "bg-[#E5FFDD]" : "bg-[#D9D9D9]"
                                        } flex items-center py-2 `}
                                >
                                    <p
                                        className={`   ${EspressoIsChoosen ? " text-[#114653]" : " text-[#AEAEAE]"
                                            } font-medium italic text-sm text-left ml-6   `}
                                    >
                                        Delivery e asporto dal tuo sito
                                    </p>
                                </div>
                            </div>

                            <div
                                className={`border-2 ${ViandanteIsChoosen ? "border-[#114653] " : "border-[#D9D9D9]"
                                    }  w-full flex flex-col justify-between  h-[150px]  py-5   `}
                            >
                                <div className="flex flex-row justify-between items-center px-3">
                                    <p
                                        className={`${ViandanteIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                            } text-xl font-semibold `}
                                    >
                                        Il Viandante
                                    </p>
                                    <div className="flex flex-row items-center justify-end  ">
                                        <p
                                            className={`text-base  ${ViandanteIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                                } font-normal text-base`}
                                        >
                                            Scegli{" "}
                                        </p>
                                        <CheckBox
                                            id={2}
                                            SetChoose={SetChoose}
                                            isChoosen={isChoosen}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`w-[105%] -ml-3 ${ViandanteIsChoosen ? " bg-[#B1C8FF]" : "bg-[#D9D9D9]"
                                        } flex items-center  py-2  `}
                                >
                                    <p
                                        className={` ${ViandanteIsChoosen ? " text-[#114653]" : " text-[#AEAEAE]"
                                            } font-medium italic text-sm text-left ml-6  `}
                                    >
                                        Fatti conoscere nella tua zona
                                    </p>
                                </div>
                            </div>

                            <div
                                className={` border-2 ${TavolaIsChoosen ? "border-[#114653] " : "border-[#D9D9D9]"
                                    }  w-full flex flex-col justify-between  h-[150px]  py-5   `}
                            >
                                <div className="flex flex-row justify-between items-center pl-3 pr-3">
                                    <p
                                        className={`${TavolaIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                            } text-xl font-semibold `}
                                    >
                                        A Tavola
                                    </p>
                                    <div className="flex flex-row items-center justify-end  ">
                                        <p
                                            className={`text-base  ${TavolaIsChoosen ? "text-[#114653] " : "text-[#D9D9D9]"
                                                }   font-normal text-base`}
                                        >
                                            Scegli{" "}
                                        </p>
                                        <CheckBox
                                            id={3}
                                            SetChoose={SetChoose}
                                            isChoosen={isChoosen}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`w-[105%] -ml-3 ${TavolaIsChoosen ? "bg-[#FFDDE5]" : " bg-[#D9D9D9]"
                                        } flex items-center  py-2`}
                                >
                                    <p
                                        className={` ${TavolaIsChoosen ? " text-[#114653]" : " text-[#AEAEAE]"
                                            }  font-medium italic text-sm text-left ml-6 `}
                                    >
                                        Incrementa le prenotazioni tavolo
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-5 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col xsm:gap-y-5 sm:gap-y-5 md:gap-y-5" >

                            <div className="w-2/3  sm:w-full  xsm:w-full md:w-full " >

                                <div className="flex flex-row xsm:flex-col sm:flex-col gap-5 " >
                                    <div className="bg-white elevation-1 w-1/2 sm:w-full  xsm:w-full  rounded-lg p-3 px-5" >
                                        <p className="font-medium text-left text-[#114653]  text-xl">Abbonamento</p>
                                        <p className="font-normal text-left text-[#114653]  text-sm">Questo è il costo mensile del pacchetto a cui sei abbonato. Il costo varia in base al pacchetto perché i servizi inclusi sono diversi</p>
                                        <div className="my-3" >
                                            <Divider />
                                        </div>
                                        <p className=" text-left text-[#114653] font-semibold text-xl"> 300,00 € </p>
                                        <p className=" text-left text-[#111E25] font-normal text-base"> al mese + IVA</p>
                                    </div>

                                    <div className="bg-white elevation-1 w-1/2 sm:w-full  xsm:w-full rounded-lg p-3 px-5" >
                                        <p className="font-medium text-left text-[#114653]  text-xl">Credito Pubblicitario</p>
                                        <p className="font-normal text-left text-[#114653]  text-sm">Qui puoi vedere quanto credito ti resta da utilizzare per promuovere il tuo locale con campagne pubblicitarie online (Social Media e Google)</p>
                                        <div className="my-3" >
                                            <Divider />
                                        </div>
                                        <p className="text-left text-[#114653] font-semibold text-xl"> 150,00 € </p>
                                        <p className=" text-left text-[#111E25] font-normal text-base"> Scade il 27 Ott 2022</p>
                                    </div>
                                </div>

                                <div className=" bg-white elevation-1 flex flex-row  xsm:flex-col sm:flex-col rounded-lg mt-5 p-3 px-5">
                                    <div className="flex flex-col w-full" >
                                        <p className="font-medium text-left text-[#114653]  text-xl">Periodo di fatturazione</p>
                                        <p className="text-left text-[#111E25] font-normal text-base"> 27 set 2022 - 26 dic 2022 </p>
                                        <p onClick={() => { SetCambia(true) }} className="font-normal text-left text-primarybtn underline hover:cursor-pointer ">  Cambia periodo di fatturazione </p>

                                    </div>
                                    <div className="flex items-center" >
                                        <button
                                            onClick={() => navigate("/dashboard/account/subscription/invoces")}
                                            className="w-[250px] px-3 h-10 rounded-md xsm:text-xs bg-primarybtn elevation-2 text-white border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white">
                                            Visualizza o scarica fatture
                                        </button>

                                    </div>
                                </div>

                            </div>

                            <div className="w-1/3 sm:w-full  xsm:w-full md:w-full bg-white elevation-1 rounded-lg   xsm:ml-0 sm:ml-0  md:ml-0  p-3 px-5" >
                                <div className="flex flex-col w-full  " >

                                    <p className="font-medium text-left text-[#114653]  text-xl">Metodo di Pagamento</p>
                                    <p className="font-normal text-left text-[#114653]  text-sm">Qui puoi vedere e modificare il metodo pagamento che hai selezionato attualmente per i pagamenti del tuo abbonamento</p>
                                    <div className="my-3" >
                                        <Divider />
                                    </div>

                                    <div className="flex flex-row items-center gap-2 ">
                                        <img src={Pagamento} alt="Pagamento" className="w-[56px] h-[43px]" />
                                        <p className="text-left text-[#114653] font-semibold text-xl">  Mastercard </p>
                                    </div>

                                    <div className="flex flex-row xsm:flex-col sm:flex-col  w-full gap-8 xsm:gap-y-2 sm:gap-y-2  mt-5  " >
                                        <p className=" text-left text-[#111E25] font-normal text-base"> **** **** **** 884  </p>
                                        <p className=" text-left text-[#111E25] font-normal text-base">  05/24  </p>
                                        <p className=" text-left text-[#111E25] font-normal text-base">  123</p>
                                    </div>
                                    <p className=" text-left text-[#111E25] font-normal text-base"> Mario Rossi</p>

                                    <div className="flex  items-center w-full  my-5 mt-6" >
                                        <button
                                            onClick={() => navigate("/dashboard/account/subscription/invoces")}
                                            className="mx-auto w-[200px] px-3 h-10 rounded-md xsm:text-xs bg-primarybtn elevation-2 text-white border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white">
                                            Modifica
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>








                    {/*   <div id="accountfields" className="flex items-start w-full sm:flex-col xsm:flex-col md:flex-col gap-4 my-3 ">
                        <EmailInupt
                            setPatchData={setPatchData}
                            patchData={patchData}
                            OpenChangeEmailModal={setOpen2
                        />

                        <PasswordInput
                            setPatchData={setPatchData}
                            patchData={patchData}
                            OpenChangePWmodal={setOpen}
                        />
                    </div>
                    <div className="flex flex-col gap-y-6 w-full items-start sm:items-center xsm:items-center md:items-center" >
                        <p className="font-bold text-left text-primarybtn"> Abbonamento</p>
                        <div className="flex flex-row gap-x-6 sm:flex-col gap-y-3 xsm:flex-col md:flex-col items-center" >
                            <button className="w-[250px] px-3 h-10 rounded-md  bg-[#C25039] elevation-2 text-white border-[#C25039] ">
                                Recesso
                            </button>
                            <button
                                onClick={() => SetCancellaModal(true)}
                                className="w-[250px] px-3 h-10 rounded-md bg-white elevation-2 text-[#C25039] border-[#C25039] hover:bg-[#C25039] hover:text-white ">
                                Disdetta
                            </button>
                        </div>

                    </div>*/}


                </div>


            </div>

        </div>
    )
}

export default IndexSubscription