import jwt_decode from "jwt-decode";
import React from "react";
import ReactDOM from "react-dom";
import TokenExpireModal from "./Modal/TokenExpireModal";
import axios from "axios";
import { baseUrl } from "./Api/Urls";

const DATA_KEY = "userData";
const TOKEN_KEY = "userToken";

const getData = () => {
  try {
    return JSON.parse(localStorage.getItem(DATA_KEY));
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem(TOKEN_KEY));
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * If the token is expired, return true, otherwise return false.
 * @param {token} token Token to validate (default will be taken from localStorage)
 * @param {userData} userData User data object (optional)
 */
export const isTokenExpiredWithNetworkCheck = async (
  token = getToken(),
  userData,
) => {
  try {
    if (!token) return false;

    const { exp } = jwt_decode(token);
    if (!exp || Date.now() >= exp * 1000) {
      try {
        // Make a request to check if the token is expired based on network response
        const response = await axios.get(
          `https://${userData?._id}.${
            process.env.REACT_APP_THEMES_PREFIX
          }${baseUrl()}${
            process.env.REACT_APP_THEMES_API_PATH
          }/restaurant-info`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.status === 401) {
          showError();
          showModal();
          startCountdown(5);
          setTimeout(() => {
            redirectToLoginPage();
          }, 5000);
          return true;
        }
      } catch (error) {
        // Handle network request error
        console.error("Error checking token expiration:", error);
      }
    }

    return false;
  } catch (error) {
    // Handle decoding error
    return false;
  }
};

const showError = () => {
  console.error("Errore: Token scaduto");
};

const showModal = () => {
  ReactDOM.render(<TokenExpireModal />, document.getElementById("modal-root"));
};

const startCountdown = (seconds) => {
  let counter = seconds;
  // Display the counter value (e.g., in a UI element)
  // Implement your countdown logic here

  const countdownInterval = setInterval(() => {
    counter--;
    // Update the displayed counter value

    if (counter <= 0) {
      clearInterval(countdownInterval);
    }
  }, 1000);
};

const redirectToLoginPage = () => {
  window.location.href = "https://restaurants.club/login";
};

/**
 * @returns {Object} All user data from the local storage
 */
export function getUserData() {
  try {
    const data = getData();
    const token = getToken();

    if (!data || !token) throw new Error();

    return { ...data, token };
  } catch (error) {
    return null;
  }
}

/**
 * @returns {String} User id from the local storage
 */
export function getUserId() {
  return getUserData()?._id;
}

/**
 * @returns {String} User token from the local storage
 */
export function getUserToken() {
  return getUserData()?.token;
}
