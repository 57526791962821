import React from "react";
import { useNavigate } from "react-router";
import "./NewsNavigation.css";
import NewsCustomLink from "./CustomLink/NewsCustomLink";
import MobileNavigation from "./NewsNavigationMobileNavigation/NewsNavigationMobileNavigation";
import GoToButtonTriple from "../../../../Reusable/goToButton/GoToButtonTriple";
import GoToButtonDouble from "../../../../Reusable/goToButton/GoToButtonDouble";
import AddIcon from "../../../../Assets/Icons/add-icon.svg";
import EmailIcon from "../../../../Assets/Icons/Email-Icon.svg";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const NewsNavigation = ({ flags }) => {
  const navigate = useNavigate();

  const goToCreateaMail = () => {
    navigate("/newsletter/create-a-new-mail");
  };

  const goToMailingList = () => {
    navigate("/newsletter/mailing-list");
  };

  const goToMailAutomation = () => {
    navigate("/newsletter/mail-automation");
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="flex xmd:hidden lg:hidden xl:hidden 2xl:hidden">
        <MobileNavigation />
      </div>

      <div className="flex w-full justify-center items-center mb-8">
        {flags.newsletterAutomaticEmail ? (
          <GoToButtonTriple
            onClick1={goToCreateaMail}
            textname1="Crea un'email"
            icon1={AddIcon}
            onClick2={goToMailingList}
            textname2="Lista Clienti"
            icon2={AddIcon}
            onClick3={goToMailAutomation}
            textname3="Mail Automatiche"
            icon3={EmailIcon}
          />
        ) : (
          <GoToButtonDouble
            onClick1={goToCreateaMail}
            textname1="Crea un'email"
            icon1={AddIcon}
            onClick2={goToMailingList}
            textname2="Lista Clienti"
            icon2={AddIcon}
          />
        )}
      </div>

      <div className="flex flex-col justify-center items-center xsm:hidden sm:hidden md:hidden">
        <nav className="NewsNavigation__list flex w-full">
          <ul className="NewsNavigation__list">
            <li>
              <NewsCustomLink to="/newsletter/completed-newsletter">
                Completate
              </NewsCustomLink>
            </li>

            {flags.newsletterEmailDraft && (
              <li>
                <NewsCustomLink to="/newsletter/draft-newsletter">
                  Bozze
                </NewsCustomLink>
              </li>
            )}

            {flags.newsletterAutomaticEmail && (
              <li>
                <NewsCustomLink to="/newsletter/automails-list">
                  Mail Automatiche
                </NewsCustomLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default withLDConsumer()(NewsNavigation);
