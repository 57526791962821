// NOTE: These are hacks for modifying BEE templates inner json
// We hack it and pray it won't always fire back with errors 🙏

class BeeTemplate {
  static get Placeholders() {
    return Object.freeze({
      Address: "Via Vincenzo Gioberti, 23",
      Contact: "{{contact}}",
      ContactExample: "3352020200",
      ContactExample2: "334 5678244",
      CTA: "{{cta}}",
      ContactInfo: "{{contact_info}}",
      Delivery: "{{delivery}}",
      Headline: "{{headline}}",
      Graphic: "{{graphic}}",
      Location: "{{location}}",
      Logo: "{{logo}}",
      PlaceholderLogo: "https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/b820d40c-f9f4-43ea-8478-97fd02eb5df7/62d589d13913e7ad031ce7a8/LGOO.png",
      Name: "{{name}}",
      Reserve: "{{reserve}}",
      Website: "{{website}}",
      WebsiteExample: "www.ristorante-damarco.it",
    });
  }

  constructor(json, html) {
    this.json = json;
    this.html = html;
  }

  get hasNoTemplate() {
    return this.json === undefined || this.json === null;
  }

  logNoTempalteError = () => console.error("AdTemplateError: No JSON found.");
  logError = message => console.warn(`AdTemplateError: ${message}`);

  setBackground = bgUrl => {
    if (this.hasNoTemplate) return this.logNoTempalteError();
    this.json.page.body.container.style["background-image"] = `url(${bgUrl})`;
  };

  setBackgrounds = bgUrls => {
    if (this.hasNoTemplate) return this.logNoTempalteError();

    const bgCount = this.json.page.body.tgBackgroundCount || 1;
    const bgImages = [];
    for (let i = 0; i < bgCount; i++) bgImages.push(`url(${bgUrls[i]})`);

    this.json.page.body.container.style["background-image"] =
      bgImages.join(",");
  };

  getLogoDescriptor = () => {
    if (this.hasNoTemplate) return this.logNoTempalteError();

    try {
      for (let row of this.json.page.rows)
        for (let column of row.columns)
          for (let mod of column.modules)
            if (mod?.descriptor?.image?.alt === BeeTemplate.Placeholders.Logo 
              || mod?.descriptor?.image?.src === BeeTemplate.Placeholders.PlaceholderLogo)
              return mod.descriptor;
    } catch (error) {
      this.logError(error.message);
    }

    return null;
  };

  textPaths = [
    {
      getter: mod => mod?.descriptor?.heading?.text,
      setter: (mod, value) => (mod.descriptor.heading.text = value),
    },
    {
      getter: mod => mod?.descriptor?.button?.label,
      setter: (mod, value) => (mod.descriptor.button.label = value),
    },
    {
      getter: mod => mod?.descriptor?.paragraph?.html,
      setter: (mod, value) => (mod.descriptor.paragraph.html = value),
    },
  ];
  setText = (placeholder, text) => {
    try {
      if (this.hasNoTemplate) return this.logNoTempalteError();

      for (let row of this.json.page.rows)
        for (let column of row.columns)
          for (let mod of column.modules)
            for (let path of this.textPaths)
              if (path.getter(mod)?.includes(placeholder)) 
                path.setter(mod, path.getter(mod).replace(placeholder, text));
    } catch (error) {
      this.logError(error.message);
    }
  };

  setLogo = (logoUrl, options) => {
    if (this.hasNoTemplate) return this.logNoTempalteError();

    try {
      const descriptor = this.getLogoDescriptor();
      if (!descriptor) return;

      descriptor.image.src = logoUrl;

      if (options?.width) descriptor.image.width = options.width;
      if (options?.height) descriptor.image.height = options.height;
      if (options?.href) descriptor.image.href = options.href;
      if (options?.styles) descriptor.style = options.styles;
    } catch (error) {
      this.logError(error.message);
    }
  };

  removeRemainingPlaceholders = () => {
    Object.values(BeeTemplate.Placeholders).forEach(placeholder => {
      this.setText(placeholder, "");
    });
  };
}

const setMarketingData = (json, data) => {
  const template = new BeeTemplate(json);
  setLogo(template, data.logoUrl);
  setContact(template, data.contact);
  setWebsite(template, data.website);
  setAddress(template, data.address)
  return template.json;
};

const setLogo = (json, logoUrl, options) => {
  if (!logoUrl) return json;

  const template = json instanceof BeeTemplate ? json : new BeeTemplate(json);
  template.setLogo(logoUrl, options);
  return template.json;
};

const setContact = (json, contact) => {
  const template = json instanceof BeeTemplate ? json : new BeeTemplate(json);
  template.setText(BeeTemplate.Placeholders.ContactExample, contact);
  template.setText(BeeTemplate.Placeholders.ContactExample2, contact);
  return template.json;
};

const setWebsite = (json, website) => {
  const template = json instanceof BeeTemplate ? json : new BeeTemplate(json);
  template.setText(BeeTemplate.Placeholders.WebsiteExample, website);
  return template.json;
};

const setAddress = (json, address) => {
  const template = json instanceof BeeTemplate ? json : new BeeTemplate(json);
  template.setText(BeeTemplate.Placeholders.Address, address);
  return template.json;
};

const beeHacks = {
  setMarketingData,
  setLogo,
  setContact,
  setWebsite,
};
export default beeHacks;
