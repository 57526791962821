import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./OnProgressCampaign.css";
import '../../../../Reusable/CSS/Page-Layout.css';
import MarketingHeaderLottie from "../../../../Assets/Lottie/Marketing.json";
import Loader from './../../../../Reusable/Loader/Loader';
import MarNavigation from "../MarNavigation/MarNavigation";
import OnProgressCampaignCard from "./OnProgressCampaignCard";
import ModifyCampaignPopup from '../ModifyCampaignPopup/ModifyCampaignPopup';
import { MarketingCampaignContext } from '../../../../Contexts/MarketingCampaignContext';
import * as service from "../../../../apis/campaignService";
import AnalyticsPlugin from '../../../../Reusable/Analytics-Plugin/AnalyticsPlugin';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner')).default;
}
function Draftcampaign() {
  const { handleExternalBuyerPersonaSet } = useContext(
    MarketingCampaignContext
  );

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  // get all running campaigns
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const runningCampaigns = await service.getRunningCampaigns();
      runningCampaigns && setCampaigns(runningCampaigns);
      setIsLoading(false);
    })()
  }, []);


  const [isStopping, setIsStopping] = useState(false);
  const handleStopClick = async campaign => {
    setIsStopping(true);
    const data = await service.stopCampaign(campaign.campaign_id);
    data && setCampaigns(currentCamps => currentCamps.filter(c => c.campaign_id !== campaign.campaign_id));
    setIsStopping(false);
  };

  const navigate = useNavigate();

  const goToMarketingCreation = () => {
    handleExternalBuyerPersonaSet(null);
    navigate("/marketing/create-campaigns");
  };

  const [campaignIdModifying, setCampaignIdModifying] = useState();
  const handlePopupClose = () => setCampaignIdModifying(null);
  const handleModifyClick = campaign => setCampaignIdModifying(campaign?.campaign_id || null);

  const renderLoader = () => isLoading ? <Loader /> : <></>;
  return (
    <>
      <div className="flex flex-col w-full relative">

        <AnalyticsPlugin

          section1={true}
          section2={true}
          section3={true}
          section4={false}
          section5={false}
          section6={false}
          SectionName="Analytics generali"
          AnalyticsName1="Migliore Campagna"
          AnalyticsData1={56}
          Analyticspercentage1={31.5 + " %"}
          AnalyticsName2="Potenziali clienti raggiunti"
          AnalyticsData2={20}
          Analyticspercentage2={2.5 + " %"}
          AnalyticsName3="Click Totali"
          AnalyticsData3="12 min"
          Analyticspercentage3="-5 min"
          AnalyticsName4="Tempo medio per guardare il Menù"
          AnalyticsData4="12 min"
          Analyticspercentage4="30"
          AnalyticsName5="Tempo medio per guardare il Menù"
          AnalyticsData5="12 min"
          Analyticspercentage5="30"
          AnalyticsName6="Tempo medio per guardare il Menù"
          AnalyticsData6="12 min"
          Analyticspercentage6="30"
          text="rispetto al mese precedente"
          imgOk={true}

        />

        <InformationTopBanner

          pagetype="Sezione"
          sectionname="Pubblicità"
          description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'All’interno di questa pagina della sezione Pubblicità puoi creare le tue nuove campagne pubblicitarie online.'}
          sectionicon={MarketingHeaderLottie}

        />

        <div className=" p-5  ">

          <MarNavigation />

        </div>

        <div className="flex flex-col w-full justify-center content-center items-center mt-4">

          {renderLoader()}

          {!isLoading && campaigns?.length === 0 && (
            <p>Non ci sono campagne in corso.</p>
          )}

          <div className="flex flex-col w-[95%]">

            <div className="GridMarketingOngoingCards px-12 py-8 gap-x-4 gap-y-12 w-[95%] mx-auto mb-4 xsm:px-0 sm:px-0 md:px-4">

              {campaigns?.map((campaign, index) => (

                <div className="py-4 mx-2" key={campaign.campaign_id || `${index}-${campaign.name}`}>

                  <OnProgressCampaignCard
                    campaign={campaign}
                    onStop={handleStopClick}
                    isStopping={isStopping}
                    onModifyClick={handleModifyClick}
                  />

                </div>

              ))}

            </div>
          </div>
        </div>

      </div>

      {campaignIdModifying && (
        <ModifyCampaignPopup
          campaignId={campaignIdModifying}
          onClose={handlePopupClose}
        />
      )}

    </>
  );
}

export default Draftcampaign;