import axios from 'axios';
import { SocialMediaCopyUrl } from '../../Utility/Api/Urls';
import { getAuthConfig } from '../../Utility/Api/auth';
import { toast } from 'react-toastify';
import { getUserId } from '../../Utility/getUserData';

/**
 * Retrieves the ad copy descriptions
 * @param {String} goal Goal/objective
 * @param {String} buyerPersona Buyer persona string
 * @returns Ad copy descriptions
 */

export const getPersonaAdCopy = async (buyerPersona, goal) => {
  try {
    const postUrl = `https://${process.env.REACT_APP_BACKEND_HOSTNAME}/marketing/ad_copy_personas?buyer_persona=${buyerPersona}&goal=${goal}`;

    const { data: copies } = await axios.get(postUrl, getAuthConfig());

    if (!copies) throw new Error('Errore: nessun dato disponibile');

    return copies;
  } catch (error) {
    toast.error('Errore: non era possibile caricare le descrizioni suggerite.');
    console.error(error);
  }
};

/**
 * Get Suggested ad copy description
 * @returns suggested ad copy description
 */
export const getSuggestedAdCopy = async (campaign, location) => {
  try {
    const postUrl = `https://${
      process.env.REACT_APP_MARKETING_DS
    }${SocialMediaCopyUrl()}/social_media_copy`;
    const user_id = getUserId();
    const body = { goal: campaign, user_id, locations: location };

    // don't even ask why this is a post (ask the source)
    const { data } = await axios.post(postUrl, body, getAuthConfig());
    if (!data) throw new Error('Errore: nessun dato disponibile');

    return Object.values(data);
  } catch (error) {
    toast.error('Errore: non era possibile caricare le descrizioni suggerite.');
    console.error(error);
  }
};
