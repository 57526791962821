import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useContext } from "react";
import { SocialMediaContext } from "../../Context/SocialMediaContext";

export default function SocialCheckboxes() {
  const { socials, setSocial } = useContext(SocialMediaContext);

  const { facebook, instagram } = socials;

  const handleSocialChange = ({ target: checkbox }) => {
    setSocial({ [checkbox.name]: checkbox.checked });
  };

  return (
    <div className="w-full flex flex-row gap-x-3 h-14 items-center">
      <FormControlLabel
        className="px-4 bg-[#F6F6F6] text-black text-lg border border-transparent hover:border-[#e5e7eb] font-normal rounded-md h-12"
        value="facebook"
        control={
          <Checkbox
            checked={facebook}
            onChange={handleSocialChange}
            name="facebook"
          />
        }
        label="Facebook"
        labelPlacement="start"
      />
      <FormControlLabel
        className="px-4 bg-[#F6F6F6] text-black text-lg border border-transparent hover:border-[#e5e7eb] font-normal rounded-md h-12"
        value="instagram"
        control={
          <Checkbox
            checked={instagram}
            onChange={handleSocialChange}
            name="instagram"
          />
        }
        label="Instagram"
        labelPlacement="start"
      />
    </div>
  );
}
