import React, { Component } from "react";
import { exportCSV } from "../../../Utility/Api/ThemesCustomerAPI";
import DownloadIcon from "@mui/icons-material/Download";
import "./Customers.css";

class ExportCSVButton extends Component {
  handleExportCSV = async () => {
    try {
      const response = await exportCSV();
      const csvData = response.data;
      const csvDataWithSemicolon = csvData.replace(/,/g, ";");
      const blob = new Blob([csvDataWithSemicolon], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "exportedData.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore durante esportazione CSV", error);
    }
  };

  render() {
    return (
      <div>
        <button
          className="h-10 px-4 rounded-md text-[#008F62] font-medium textfont bg-[#EBFFF9] hover:bg-primarybtnhover hover:text-white drop-shadow"
          onClick={this.handleExportCSV}>
          <DownloadIcon className=" m-1" />
          Esporta Clienti
        </button>
      </div>
    );
  }
}

export default ExportCSVButton;
