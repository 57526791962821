import axios from "axios";
// import menuExample from "./menuExample.json";
// import sciencedMenuExample from "./sciencedMenuExample.json";
import { getUserId } from "../../../Utility/getUserData";
import { baseUrl } from "../../../Utility/Api/Urls";
import { getAuthConfig } from "../../../Utility/Api/auth";
import { toast } from "react-toastify"
const baseEndpoint = () => `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX
  }${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}`;

// const deploymentStaticEndpoint = `https://${userData?._id}.${process.env.REACT_APP_THEMES_PREFIX
//   }${baseUrl()}${process.env.REACT_APP_THEMES_API_PATH}/upload`;

// constants
export const availabilityOptions = [
  { label: "Tutto il giorno", value: "TUTTO IL GIORNO" },
  { label: "Pranzo", value: "PRANZO" },
  { label: "Cena", value: "CENA" },
];

export const defaultCategoryIcon = "Antipasto-Sub-Icon.svg";

// utility functions
const createError = error => ({
  error:
    error.response?.data?.error ||
    error.response?.data?.message ||
    error.message,
});

// const appendArrayToFormData = (formData, fieldName, array) => {
//   if (!array) return;

//   array.forEach((value, i) => {
//     formData.append(`${fieldName}[${i}]`, value);
//   });
// };

// const appendObjectToFormData = (formData, fieldName, data) => {
//   if (!data) return;

//   if (data && typeof data === "object")
//     Object.keys(data).forEach(key => {
//       appendObjectToFormData(
//         formData,
//         fieldName ? `${fieldName}[${key}]` : key,
//         data[key]
//       );
//     });
//   else formData.append(fieldName, data == null ? "" : data);
// };

// /**
//  * Returns url of an image file's name
//  * @param {String} image Image file name
//  * @returns Image url
//  */
// export const getImageUrl = async image => {
//   if (image === defaultCategoryIcon)
//     return (
//       await import(`../../../Assets/Icons/Product-Icons/${defaultCategoryIcon}`)
//     ).default;

//   return image?.includes("http")
//     ? image
//     : `${deploymentStaticEndpoint}/${image}`;
// };

// /**
//  * Checks availability of a product
//  * @param {Object} product Product data
//  * @returns true if product is available
//  */
// export const isProductAvailable = product =>
//   new Date(product?.availability_date)?.getTime() < Date.now() ||
//   product?.quantity > 0;

/**
 * Gets all catalogs
 * @returns Array of catalog summary objects
 */
export const getCatalogs = async () => {
  try {
    const url = `${baseEndpoint()}/catalogs`;
    const result = await axios.get(url);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Creates a catalog
 * @param {Object} menuData Data of the menu
 * @returns Created catalog
 */
export const createCatalog = async menuData => {
  try {
    const url = `${baseEndpoint()}/catalogs`;
    const result = await axios.post(url, menuData, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("Il menù è stato creato con successo")
    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Edits a catalog
 * @param {String} id Id of the catalog
 * @param {Object} menuData Data of the catalog menu
 * @returns Edited catalog
 */
export const editCatalog = async (id, menuData) => {
  try {
    const url = `${baseEndpoint()}/catalogs/${id}`;
    const result = await axios.patch(url, menuData, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("Le tue modifiche sono state salvate")

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Edits catalogs order
 * @param {Array} newOrder Array of ids of the catalogs with the new order
 * @returns Edited catalog
 */
export const reOrderCatalogs = async (newOrder) => {
  try {
    const url = `${baseEndpoint()}/catalogs/orders`;
    const result = await axios.patch(url, newOrder, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};


/**
 * Deletes a catalog
 * @param {String} id Id of the catalog
 * @returns Deleted catalog
 */
export const deleteCatalog = async id => {
  try {

    const res = await deleteEntity("catalogs", id, getAuthConfig());

    toast.success("Il menù è stato cancellato con successo")
    return res
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);

  }
}

/**
 * Gets all categories of a catalog
 * @param {String} catalogId Id of the catalog that owns the categories
 * @returns Array of category summary objects
 */
export const getCategories = async catalogId => {
  try {
    const url = `${baseEndpoint()}/categories/catalog/${catalogId}`;
    const result = await axios.get(url);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    const categories = result.data.categories;

    return {
      ...result.data,
      categories,
    };
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};



/**
 * Gets all categories 
 * @returns Array of category summary objects
 */
export const getAllCategories = async () => {
  try {
    const url = `${baseEndpoint()}/categories`;
    const result = await axios.get(url);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};




/**
 * Creates a category
 * @param {String} name Name of the category
 * @param {String} image Image/icon path
 * @param {String} id Id of the catalog
 * @returns Created category
 */
export const createCategory = async payload => {
  try {
    const data = {
      name: payload?.name,
      image: payload?.icon,
      // availability: payload?.availability,
      catalog: payload?.catalogId,
      isHidden: payload?.isHidden,
      isDeliverySelected: payload?.isDeliverySelected,
    };
    const url = `${baseEndpoint()}/categories`;
    const result = await axios.post(url, data, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("La categoria è stata creata con successo")

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Edits a category
 * @param {String} categoryId Id of the category
 * @param {Object} categoryData Data of the category
 * @param {String} catalogId Id of the category's catalog
 * @returns Edited category
 */
export const editCategory = async (categoryId, categoryData, catalogId) => {
  try {
    const data = {
      name: categoryData?.name,
      image: categoryData?.icon,
      isHidden: categoryData?.isHidden,
      isDeliverySelected: categoryData?.isDeliverySelected,
      // availability: categoryData?.availability,
      catalog: catalogId,
    };
    const url = `${baseEndpoint()}/categories/${categoryId}`;
    const result = await axios.patch(url, data, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("Le tue modifiche sono state salvate")

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};


/**
 * Edits Categories order
 * @param {Array} newOrder Array of ids of the Categories with the new order
 * @returns Edited catalog
 */
export const reOrderCategories = async (newOrder) => {
  try {
    const url = `${baseEndpoint()}/categories/orders`;
    const result = await axios.patch(url, newOrder, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};


/**
 * Deletes a category
 * @param {String} id Id of the category
 * @returns Deleted category
 */
export const deleteCategory = async id => {
  try {
    const res = await deleteEntity("categories", id, getAuthConfig());
    toast.success("La categoria è stata cancellata con successo")
    return res

  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
}

/**
 * Gets all products of a category
 * @param {String} categoryId Id of the category that owns the products
 * @returns Array of products
 */
export const getProducts = async categoryId => {
  try {
    const url = `${baseEndpoint()}/products/category/${categoryId}`;
    const result = await axios.get(url);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    const products = result.data.products;
    products.sort((a, b) => a.productOrder - b.productOrder);

    return {
      ...result.data,
      products,
    };
    // return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Gets all products of a category
 * @returns Array of products
 */
export const getAllProductsSummary = async (skip = 0, limit = 500) => {
  try {
    const url = `${baseEndpoint()}/products?summary=true&skip=${skip}&limit=${limit}`;
    const result = await axios.get(url);

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Creates a product
 * @param {Object} data Data of the product
 * @param {String} catalogId Id of the catalog that owns the product
 * @param {String} categoryId Id of the category that owns the product
 * @returns Created category
 */
export const createProduct = async (data, catalogId, categoryId) => {
  try {
    const url = `${baseEndpoint()}/products`;
    const payload = {
      ...data,
      catalog: catalogId,
      category: categoryId,
    };
    const result = await axios.post(url, payload, getAuthConfig());
    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("Il prodotto è stato creato con successo")
    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Updates a product
 * @param {String} id Id of the product
 * @param {String} data Data of the product
 * @returns Updated product
 */
export const updateProduct = async (id, data) => {
  try {
    const url = `${baseEndpoint()}/products/${id}`;
    const result = await axios.patch(url, data, getAuthConfig());
    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);
    toast.success("Le tue modifiche sono state salvate")
    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Edits Products order
 * @param {Array} newOrder Array of ids of the Products with the new order
 * @returns Edited catalog
 */
export const reOrderProducts = async (newOrder) => {
  try {
    const url = `${baseEndpoint()}/products/orders`;
    const result = await axios.patch(url, newOrder, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
};

/**
 * Deletes a product
 * @param {String} id Id of the product
 * @returns Deleted product
 */
export const deleteProduct = async id => {
  try {
    const res = await deleteEntity("products", id, getAuthConfig());
    toast.success("Il prodotto è stato cancellato con successo")
    return res
  } catch (error) {
    toast.error("Chiedi aiuto al supporto tecnico")
    return createError(error);
  }
}

/**
 * Deletes an entity at the given url endpoint
 * @param {String} collectionName Name of the collection
 * @param {String} id Id of the entity
 * @returns Deleted entity or error object
 */
const deleteEntity = async (collectionName, id) => {
  try {
    const url = `${baseEndpoint()}/${collectionName}/${id}`;
    const result = await axios.delete(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

// // JUST EAT MENU

// /**
//  * Cleans the image url
//  * @param {String} path image url
//  * @returns Url
//  */
// const cleanImagePath = path => path.replace(/,\{transformations\}/, "");

// /**
//  * COnverts just eat menu structure to RC menu structure
//  * @param {Object} menu Just Eat Menu
//  * @returns RC Menu
//  */
// const parseJustEatMenu = menu => {
//   if (!menu) return null;

//   return menu.menu.menus[0].categories.reduce((categories, currentCategory) => {
//     const products = currentCategory.itemIds.map(id => {
//       const item = menu.items.items.find(i => i.id === id);
//       if (!item) return null;

//       return {
//         id: item.id,
//         title: item.name,
//         price: item.variations.find(v => v.type === "NoVariation")?.basePrice,
//         description: item.description,
//         indications: item.labels.map(l => l.toLocaleUpperCase()),
//         variations: item.variations
//           .filter(v => v.type === "Variation")
//           .map(v => ({
//             id: v.id,
//             name: v.name,
//             price: v.basePrice,
//           })),
//         image:
//           item.imageSources.length > 0
//             ? cleanImagePath(item.imageSources[0].path)
//             : defaultCategoryIcon,
//       };
//     });
//     const category = {
//       id: currentCategory.id,
//       name: currentCategory.name,
//       products,
//     };

//     categories.push(category);
//     return categories;
//   }, []);
// };

// /**
//  * Uses data science api to insert ingredients and allergens into products
//  * @param {Object} menu RC menu
//  * @returns Scienced menu
//  */
// const getIngredientsAndAllergens = async menu => {
//   try {
//     const itemsPayload = menu.reduce(
//       (total, current) => [...total, ...current.products],
//       []
//     );

//     const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH
//       }/marketing/menu`;
//     const config = {
//       headers: { authorization: `Bearer ${getUserToken()}` },
//     };

//     const { data } = await axios.post(url, itemsPayload, config, getAuthConfig());

//     if (!data.resp || !data.succes) throw new Error("No data received");

//     return data.resp;
//   } catch (error) {
//     return createError(error);
//   }
// };

// /**
//  * Combine parsed menu with the data received from data science
//  * @param {Object} menu RC menu
//  * @param {Object} itemData data science data on products
//  * @returns overpowered menu
//  */
// const enhanceMenu = async (menu, itemData) => {
//   menu.forEach(category => {
//     category.products.forEach(product => {
//       const data = itemData.find(i => i.id === product.id);
//       product.allergens = data?.allergens || [];
//       product.ingredients = data?.ingredients || [];
//     });
//   });
//   return menu;
// };

// /**
//  * Converts a just eat menu into a catalog(menu)
//  * @param {String} url JustEat url
//  * @returns Created catalog(menu)
//  */
// export const getJustEatMenu = async justEatUrl => {
//   try {
//     const menuQuery = `?url=${encodeURIComponent(justEatUrl)}`;
//     const url = `https://${process.env.REACT_APP_WIZARD_PREFIX}${baseUrl()}${process.env.REACT_APP_WIZARD_API_PATH
//       }/getExistingMenu${menuQuery}`;

//     const config = {
//       headers: { authorization: `Bearer ${getUserToken()}` },
//     };

//     const { data: menu } = await axios.get(url, config);
//     if (!menu || menu?.error) throw new Error(menu?.error || "No menu received");

//     const parsedMenu = parseJustEatMenu(menu);

//     const sciencedData = await getIngredientsAndAllergens(parsedMenu);
//     if (!sciencedData || sciencedData?.error) throw new Error(sciencedData?.error || "No scienced data received");

//     const finalMenu = await enhanceMenu(parsedMenu, sciencedData);
//     return finalMenu;
//   } catch (error) {
//     return createError(error);
//   }
// };

// /**
//  * Creates the modified and scienced just eat menu
//  * @param {String} name Name of the catalog
//  * @param {String} url JustEat url
//  * @returns Created catalog(menu)
//  */
// export const createJustEatMenu = async (name, menu) => {
//   try {
//     const catalog = await createCatalog({
//       name,
//       availability: availabilityOptions[0].value,
//     });
//     if (!catalog || catalog?.error) throw new Error(catalog?.error || "Menu creation error.");

//     for (let categoryData of menu) {
//       const categoryPayload = {
//         name: categoryData.name,
//         icon: defaultCategoryIcon,
//         availability: availabilityOptions[0].value,
//         catalogId: catalog._id,
//       };
//       const category = await createCategory(categoryPayload);
//       if (!category || category?.error)
//         throw new Error(category?.error || "Category creation error.");

//       for (let productData of categoryData.products) {
//         const productPayload = {
//           ...productData,
//           description: productData.description || productData.title,
//           variations:
//             productData.variations?.map(v => ({
//               name: v.name,
//               price: v.price,
//             })) || [{ name: productData.title, price: productData.price }] ||
//             [],
//           inventory: 1,
//           indications: [], // need better detection + must be aligned with our backend enum
//           price: productData.price || productData?.variations[0]?.price || 0,
//         };
//         delete productPayload.id;

//         createProduct(productPayload, catalog._id, category._id);
//       }
//     }

//     return catalog;
//   } catch (error) {
//     return createError(error);
//   }
// };
