import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";
import * as helpers from "../Utility/marketingHelpers";
import * as creditService from "./creditService";
import * as imageHostingService from "./imageHostingService";

const DEFAULT_ERROR = "C'è stato un errore. Riprova o ricarica la pagina.";

const urlbaseEndpoint = `https://${process.env.REACT_APP_DASHBOARD_PREFIX
  }${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;

/**
 * Get a campaign by id
 * @param {String} id Id of the campaign
 * @returns campaign info
  */
export const getCampaign = async id => {
  try {
    // create campaign
    const url = `${urlbaseEndpoint}/marketing/campaign/${id}`;

    const { data } = await axios.get(url, getAuthConfig());

    if (data?.success !== true)
      throw new Error(
        "Non è possible caricare la campagna a questo momento. Riprova o contatta l'assistenza."
      );

    return data;
  } catch (error) {
    logError(error);
  }
};

/**
 * Creates a campaign
 * @param {Object} payload Payload for creating a campaign
 * @returns campaign id
 */
export const createCampaign = async payload => {
  try {
    // create campaign
    const url = `${urlbaseEndpoint}/marketing/campaign/create`;
    const body = {
      goal: payload.dsGoal,
      budget: payload.budget,
      copy: payload.copy,
      startDate: formatDate(payload.startDate),
      endDate: formatDate(payload.endDate),
    };

    // check if we have to send the other personas type
    if (helpers.isOtherPersona(payload.buyerPersona))
      body.buyerPersona = payload.buyerPersona;

    // host image statically and get url
    const imageUrl = await imageHostingService.hostBase64Image(payload?.image);
    if (!imageUrl) return;
    body.image = imageUrl;

    const campaignResult = await axios.post(url, body, getAuthConfig());

    if (
      campaignResult?.data?.success !== true
    )
      throw new Error(
        "Non è possible creare una campagna a questo momento. Riprova tra qualche minuto o contatta l'assistenza."
      );

    // get from start date to end date days and multiply by budget before reducing balance
    const days = moment(payload.endDate).diff(moment(payload.startDate), "days");
    const totalBudget = days * payload.budget;
    await creditService.reduceBalance(totalBudget);    
    return true;
  } catch (error) {
    logError(error);
  }
};



/**
 * Modifies a campaign
 * @param {String} id Id of the campaign
 * @param {Object} payload Payload for creating a campaign
 */
export const modifyCampaign = async (id, payload) => {
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/${id}`;
    const body = {
      copy: payload.copy,
      budget: payload.budget ? parseInt(payload.budget) * 100 : null,
      endDate: payload.endDate ? formatDate(payload.endDate) : null,
    };

    const { data } = await axios.patch(url, body, getAuthConfig());

    if (data?.success !== true)
      throw new Error(
        "Non è possible modifcare la campagna a questo momento. Riprova tra qualche minuto o contatta l'assistenza."
      );

    return data;
  } catch (error) {
    logError(error);
  }
};

/**
 * Get estimaes for Campaigns
 * @param {Object} data Payload for creating a campaign
 * @returns estiamtes
 */
export const getEstimates = async payload => {
  try {
    const query = `?campaignId=${payload.id}&goal=${payload.goal}`;
    const url = `${urlbaseEndpoint}/marketing/campaign/estimate${query}`;
    const { data } = await axios.get(url, getAuthConfig());

    checkResultError(data);

    return data["Estimate"];
  } catch (error) {
    logError(error);
    return null;
  }
};

/**
 * Get Suggested Campaigns
 * @returns suggested campaigns
 */
export const getSuggestedCampaigns = async () => {
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/suggest`;
    const { data } = await axios.get(url, getAuthConfig());

    checkResultError(data);

    return data?.map(c => {
      const goalData = helpers.getGoalDataFromDSGoal(c.goal);
      const estimates = helpers.mapGoalEstimateToViewModel(
        goalData,
        c.estimated_results
      );

      return {
        ...goalData,
        ...estimates,
        ...c,
      };
    });
  } catch (error) {
    logError(error);
  }
};

/**
 * Get goals estimates
 * @returns goals estimates
 */
export const getGoalsEstimates = async currentGoals => {
  try {
    const url = `${urlbaseEndpoint}/marketing/goalsEstimates`;
    const { data: estimates } = await axios.get(url, getAuthConfig());

    checkResultError(estimates);

    return helpers.combineGoalsAndEstimates(currentGoals, estimates);
  } catch (error) {
    logError(error);
  }
};

/**
 * Get budget estimates
 * @returns budget estimates
 */
export const getBudgetEstimates = async goal => {
  try {
    const { dsGoal } = helpers.getGoalData(goal);
    const url = `${urlbaseEndpoint}/marketing/budgetEstimates?goal=${dsGoal}`;
    const { data: estimates } = await axios.get(url, getAuthConfig());

    checkResultError(estimates);

    if (!!estimates.low && !!estimates.medium && !!estimates.high)
      return {
        data: estimates,
        viewData: [estimates.low, estimates.medium, estimates.high],
      };

    return null;
  } catch (error) {
    logError(error);
  }
};

/**
 * Get budget estimates
 * @returns budget estimates
 */
export const getSuggestedBudget = async goal => {
  try {
    const { dsGoal } = helpers.getGoalData(goal);
    const url = `${urlbaseEndpoint}/marketing/suggestedBudget?goal=${dsGoal}`;
    const { data } = await axios.get(url, getAuthConfig());

    if (!data?.response || data?.success !== true)
      throw new Error(DEFAULT_ERROR);

    return {
      budget: data.response.suggested_budget,
      endTime: new Date(data.response.suggested_end_time),
    };
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get running campaigns
 * @returns budget estimates
 */
export const getRunningCampaigns = async () => {
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/running`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data?.success || !result.data?.running_campaigns)
      throw new Error(DEFAULT_ERROR);

    return result.data.running_campaigns;
  } catch (error) {
    logError(error);
  }
};

/**
 * Stop a running campaign
 * @param {String} id Id of the campaign
 */
export const stopCampaign = async id => {
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/${id}/stop`;
    const result = await axios.patch(url, {}, getAuthConfig());

    if (!result.data?.success) throw new Error(DEFAULT_ERROR);

    return result.data;
  } catch (error) {
    logError(error);
  }
};

/**
 * Re-Start a campaign
 * @param {String} id Id of the campaign
 */
export const StartCampAgain = async (id, budget, endDate) => {
  const body = {
    endDate: endDate,
    budget: parseFloat(budget)
  }
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/${id}`;
    const result = await axios.patch(url, body, getAuthConfig());

    if (!result.data?.success) throw new Error(DEFAULT_ERROR);

    return result.data;
  } catch (error) {
    logError(error);
  }
};

/**
 * Get ended campaigns
 * @returns campaigns
 */
export const getEndedCampaigns = async (days = 60) => {
  try {
    const url = `${urlbaseEndpoint}/marketing/campaign/ended?days=${days}`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data?.success || !result.data?.ended_campaigns)
      throw new Error(DEFAULT_ERROR);

    return result.data.ended_campaigns;
  } catch (error) {
    logError(error);
  }
};

//------------------------- Helper Functions -------------------------//
function resultHasError(result) {
  return (
    !result ||
    result?.Error ||
    result?.error ||
    result?.err_msg === 500 ||
    result?.err_msg === 400 ||
    result?.success === false
  );
}

function getResultError(result) {
  return result?.error || result?.Error || DEFAULT_ERROR;
}

function checkResultError(result) {
  if (resultHasError(result)) throw new Error(getResultError(result));
}

function logError(error) {
  console.error(error);
  toast.error(DEFAULT_ERROR);
}

/**
 * Formats a date as yyyy-mm-ddThh:mm:ss+0000 with the help of moment
 * @param {String} date Date to be formatted
 */
function formatDate(date) {
  const momentDate = moment(date).format();
  const lastColonIndex = momentDate.lastIndexOf(":");
  return `${momentDate.slice(0, lastColonIndex)}${momentDate.slice(
    lastColonIndex + 1
  )}`;
}
