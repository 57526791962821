import Joi from "joi";

const createStringSchema = (label, min = 0, max = 150) =>
  Joi.string()
    .min(min)
    .max(max)
    .label(label)
    .optional()
    .messages({
      "string.empty": `${label} non può essere vuoto/a.`,
      "string.min": `${label} deve avere almeno ${
        min === 1 ? "un carattere." : `${min} caratteri`
      }`,
      "string.max": `${label} non può avere più di ${max} caratteri`,
    });

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .label("Email")
  .optional();


// Customers
export const customerSchema = Joi.string();

export const customersSchema = Joi.array()
  .max(30)
  .label("Lista dell'email")
  .optional()
  .empty();

// Mailing Lists
export const mailingListNameSchema = createStringSchema("Lista", 1, 150);

export const mailingListsSchema = Joi.array()
  .items(Joi.object())
  .min(1)
  .label("Liste dell'email")
  .optional()
  .empty()

export const mailingListCreationSchema = Joi.object({
  mailingListName: mailingListNameSchema.required(),
  customers: customersSchema.optional(),
}).optional();

export const mailingListUpdateSchema = Joi.object({
  mailingListName: mailingListNameSchema.required(),
  customers: customersSchema.optional(),
}).optional();

// Newseltters
export const subjectSchema = createStringSchema("Oggetto", 0, 150);

export const campaignNameSchema = createStringSchema(
  "Nome della Campagna",
  0,
  150
);

export const htmlSchema = Joi.string()
  .min(1)
  .label("Data dell'email")
  .required();

export const jsonSchema = Joi.object().label("Data dell'email").required();

export const emailCreationSchema = Joi.object({
  campaignName: campaignNameSchema,
  subject: subjectSchema,
  html: htmlSchema,
  json: jsonSchema
});

export const newsletterSchema = Joi.object({
  subject: subjectSchema,
  html: htmlSchema,
  campaignName: campaignNameSchema,
  json: jsonSchema,
  mailingListIds: Joi.array()
    .items(Joi.number().integer().min(1).max(1000000000))
    .min(1)
    .label("Liste dell'email")
    .required(),
});

