import React, { useEffect, useState } from "react";
import { newsletterCampaigns } from "../newsletterCampaigns";

export default function GoalNameSection({ goalName, SetGoalName }) {


  const currentPlan = localStorage.getItem("currentPlan");


  const [currentCampaigns, setCurrentCampaigns] = useState(newsletterCampaigns);

  useEffect(() => {
    if (currentPlan === "nodelivery") {
      setCurrentCampaigns((prevCampaigns) =>
        prevCampaigns.filter((campaign) => campaign.name !== "Delivery")
      );
    } else if (currentPlan === "nobooking") {
      setCurrentCampaigns((prevCampaigns) =>
        prevCampaigns.filter((campaign) => campaign.name !== "Prenotazioni")
      );
    } else if (currentPlan === "none") {
      setCurrentCampaigns((prevCampaigns) =>
        prevCampaigns.filter(
          (campaign) => campaign.name !== "Delivery" && campaign.name !== "Prenotazioni"
        )
      );
    } else {
      setCurrentCampaigns(newsletterCampaigns)
    }
  }, [currentPlan, newsletterCampaigns]);



  return (
    <div className="w-full flex flex-row h-fit gap-x-8 w-[95%] mx-auto xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse" >
      <div className="flex flex-col items-center gap-y-3 w-1/2 elevation-2 h-[240px] justify-center xsm:w-full sm:w-full md:w-full p-2" >
        {currentCampaigns?.filter((item) => item.value === goalName)?.map((item) => (
          <>
            <img src={item.icon} alt="" />
            <p className="font-semibold text-lg text-newsletterbtn text-left">{item.name}</p>
            <p className="font-normal text-[#000] px-8 text-center">{item.description}</p>
          </>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-3 w-1/2 h-full xsm:w-full sm:w-full md:w-full xsm:mb-4 sm:mb-4 md:mb-4" >
        {currentCampaigns?.map(campaign => (
          <label
            onClick={(e) => { SetGoalName(campaign.value) }}
            className={`border border-newsletterbtn rounded-lg ${campaign.value === goalName ? "bg-newsletterbtn text-white" : "bg-transparent text-newsletterbtn"} items-center justify-center flex py-2 px-6 `}>
            {campaign.name}
          </label>
        ))}
      </div>
    </div>
  );
}
