import React from 'react';
import { useNavigate } from 'react-router';
import './SocNavigation.css';
import GoToButtonSingle from '../../../../Reusable/goToButton/GoToButtonSingle';
import AddIcon from "../../../../Assets/Icons/add-icon.svg";

const SocNavigation = () => {

  const navigate = useNavigate();

  const goToCreatePost = () => {
      navigate("/social-media/create-a-new-post");
  };

  return (

    <div className='flex flex-col w-full justify-center items-center'>


      <div className='flex w-full justify-center items-center mb-8 xsm:hidden '>

        <GoToButtonSingle onClick1={goToCreatePost} textname1="Crea un Post" icon1={AddIcon}/>

      </div>

      {/*

      <div className='flex flex-col justify-center items-center xsm:hidden sm:hidden md:hidden'>

        <nav className='SocNavigation__list flex w-full'>

          <ul className='SocNavigation__list'>

            <li>
              <SocCustomLink to='/social-media/social-manager'>Social Manager</SocCustomLink>
            </li>

            <li>
              <SocCustomLink to='/social-media/reviews'>Recensioni</SocCustomLink>
            </li>

            <li>
              <SocCustomLink to='/social-media/questions-&-answers'>Domande e Risposte</SocCustomLink>
            </li>

          </ul>

        </nav>

      </div>

      */}

    </div>

  );
};

export default SocNavigation;