import React from 'react';
import CopyFunction from './CopyFunction';
import Loader from '../Loader/Loader';
import { personaAdCopyService } from '../../apis/adCopy/AdCopyService';
import './CopySection.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { GrClose } from 'react-icons/gr';

function CopySection({
  goal,
  buyerPersona,
  text,
  onChange,
  isRandom,
  SetStep,
  activeStep,
}) {
  // ad copy data from data science

  const [copies, isLoadingCopies] = personaAdCopyService.copies(
    buyerPersona,
    goal
  );
  // if ad copy is clicked, place text in description text box
  const handleCopy = (text) => onChange(text);

  // handle manual text input
  const handleTextAreaChange = (e) => onChange(e.target.value);

  const hasCopies = !!copies.length;

  const [open, SetOpen] = React.useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 1000 ? 800 : '95%',
    bgcolor: 'background.paper',
    borderTopWidth: 18,
    borderTopColor: '#6392FF',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    height: '95%',
    overflowY: 'scroll',
  };
  return (
    <div className='w-full h-full'>
      <div className='w-full bg-white flex flex-col gap-y-5 p-7'>
        <div className='flex flex-col gap-y-6 my-5'>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-left font-semibold text-marketingtextcolour text-3xl'>
              Descrizione
            </p>
            <button
              onClick={() => SetOpen(true)}
              className='text-center w-fit  h-full xsm:w-full sm:w-full md:w-[80%] xmd:w-[60%] flex items-center justify-center gap-x-1 px-4 py-1 rounded-md text-marketingbtn bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig'
            >
              Fatti ispirare da una descrizione suggerita!
            </button>

            {hasCopies && (
              <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                disableScrollLock
                open={open}
                onClose={() => SetOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <div className='flex flex-col justify-center items-center gap-8'>
                      <button
                        onClick={() => SetOpen(false)}
                        className='absolute top-0 right-0 m-5'
                      >
                        <GrClose
                          size={25}
                          color='#6392FF'
                        />
                      </button>

                      <div className='header text-marketingbtn pb-5 text-bold'>
                        {' '}
                        Descrizioni Suggerite{' '}
                      </div>
                      <div className='flex flex-col w-full gap-y-8 gap-x-4'>
                        {isLoadingCopies ? (
                          <Loader />
                        ) : (
                          <>
                            {copies.map((copy) => (
                              <div className='flex w-full justify-between items-start'>
                                <CopyFunction
                                  copyText={copy.text}
                                  onClick={handleCopy}
                                  close={() => setOpen(false)}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            )}
          </div>

          <textarea
            className='w-full mr-auto text-left focus:text-black focus:text-lg h-[210px] resize-none rounded-md text-lg border-2 border-marketingbtn text-black bg-transparent p-4 focus:outline-none'
            value={text}
            onChange={handleTextAreaChange}
          />
          {activeStep === 0 && (
            <div className='flex justify-center items-center'>
              <button
                onClick={() => SetStep(1)}
                className='w-40 h-12 rounded-md elevation-1 text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig'
              >
                Salva
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CopySection;
