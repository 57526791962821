import React from "react";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import Completednewsletterlist from "./Completed Newsletter List/Completednewsletterlist";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import './Completednewsletter.css';
import NewsNavigation from "../NewsNavigation/NewsNavigation";
import AnalyticsPlugin from "../../../../Reusable/Analytics-Plugin/AnalyticsPlugin";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import('../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner')).default;
}

function Completednewsletter() {

  return (

    <div className="w-full mx-auto ">

      <InformationTopBanner

        pagetype="Newsletter"
        sectionname="Completate"
        description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'In questa sezione puoi visualizzare tutte le tue campagne newsletter completate, vedere e aggiungere contatti o liste di contatti da utilizzare per le tue campagne, creare campagne di mail automation o  newsletter.'}
        sectionicon={NewsletterHeaderLottie}

      />

      <div className=" p-5  ">

        <AnalyticsPlugin

          section1={true}
          section2={true}
          section3={true}
          section4={false}
          section5={false}
          section6={false}
          SectionName="Analytics generali"
          AnalyticsName1="Numero di email inviate negli ultimi 30 giorni"
          AnalyticsData1={56}
          Analyticspercentage1={31.5 + " %"}
          AnalyticsName2="Tasso totale di apertura della posta"
          AnalyticsData2={20}
          Analyticspercentage2={2.5 + " %"}
          AnalyticsName3="Click totali dalle newsletter"
          AnalyticsData3="12 min"
          Analyticspercentage3="-5 min"
          AnalyticsName4="Tempo medio per guardare il Menù"
          AnalyticsData4="12 min"
          Analyticspercentage4="30"
          AnalyticsName5="Tempo medio per guardare il Menù"
          AnalyticsData5="12 min"
          Analyticspercentage5="30"
          AnalyticsName6="Tempo medio per guardare il Menù"
          AnalyticsData6="12 min"
          Analyticspercentage6="30"
          text="rispetto al mese precedente"
          imgOk={false}

        />

        <NewsNavigation />

      </div>

      <ScrollToTop />

      <div className="flex flex-col px-4 pb-10 xsm:p-0 sm:p-0 md:p-0 xmd:p-0">

        <Completednewsletterlist />

      </div>

    </div>

  );
}

export default Completednewsletter;
