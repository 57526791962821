import moment from "moment";
import Joi from "joi";
import { getRandomElement, getRandomNumber } from "./random";
import "moment-timezone";
// Goals/Campaigns
import BrandingIcon from "../Assets/Icons/Marketing-Goals-New/Brand.png";
import InteractionsIcon from "../Assets/Icons/Marketing-Goals-New/Interactions.png";
import Reservation from "../Assets/Icons/Marketing-Goals-New/Newsletter_Reservation.png";
import Delivery from "../Assets/Icons/Marketing-Goals-New/Delivery.png";
// import PromotionIcon from "../Assets/Icons/Marketing-Goals-New/Promotions.png";
import WebsiteTrafficIcon from "../Assets/Icons/Marketing-Goals-New/Marketing-Online-Traffic.png";
import OfflineTrafficIcon from "../Assets/Icons/Marketing-Goals-New/Offline-Traffic.png";
import StaffSearchIcon from "../Assets/Icons/Marketing-Goals-New/Staff-Search.png";

// Normal Buyer Persona
import Man1 from "../Assets/Icons/MarketingPerson.png";
import Man2 from "../Assets/Icons/MarketingPerson-Old.png";
import Woman1 from "../Assets/Icons/Buyer-Woman.png";
import Woman2 from "../Assets/Icons/Buyer-Old-Woman.png";
import NewWoman from "../Assets/Images/Buyer-Persona/primario.webp";
import NewMan from "../Assets/Images/Buyer-Persona/secondario.webp";

// Other Buyer Persona
import Anniversario3160 from "../Assets/Images/Buyer-Persona/GLI ANNIVERSARIO.webp";
import RitornoDalleFerie from "../Assets/Images/Buyer-Persona/DI RITORNO DALLE FERIE.webp";
import FuoriSede from "../Assets/Images/Buyer-Persona/FUORI SEDE.webp";
import Laureando from "../Assets/Images/Buyer-Persona/IL LAUREANDO.webp";
import Coppie from "../Assets/Images/Buyer-Persona/LE COPPIE.webp";
import Single from "../Assets/Images/Buyer-Persona/I SINGLE.webp";
import NovelliSposi from "../Assets/Images/Buyer-Persona/I NOVELLI SPOSI.webp";
import Pendolari from "../Assets/Images/Buyer-Persona/I PENDOLARI.webp";
import Risparmiatore from "../Assets/Images/Buyer-Persona/IL RISPARMIATORE.webp";
import Vegetariano from "../Assets/Images/Buyer-Persona/IL VEGETARIANO.webp";
import Vegano from "../Assets/Images/Buyer-Persona/IL VEGANO.webp";
import { MarketingCampaignContext } from "../Contexts/MarketingCampaignContext";
import { useContext } from "react";

//---------------------- GOALS / CAMPAIGNS ----------------------//

export const Goal = Object.freeze({
  Branding: "branding",
  Interactions: "interactions",
  Promotions: "promotion",
  Delivery: "delivery",
  Reservation: "reservation",
  OnlineTraffic: "online-traffic",
  MenuViews: "online-traffic",
  OfflineTraffic: "offline-traffic",
  LocalVisits: "offline-traffic",
  StaffSearch: "staff-search",
});

export const DSGoal = Object.freeze({
  Marchio: "MARCHIO",
  Interazioni: "INTERAZIONI",
  Promozioni: "PROMOZIONI",
  Delivery: "DELIVERY",
  Prenotazioni: "PRENOTAZIONI",
  VisiteAlMenu: "VISUALIZZAZIONE MENU",
  VisitelAlLocale: "VISITE AL LOCALE",
  Ricercadelpersonale: "RICERCA DI PERSONALE",
});

export const AdCopyGoal = Object.freeze({
  Marchio: "MARCHIO",
  Interazioni: "INTERAZIONI",
  Promozioni: "PROMOZION",
  Delivery: "DELIVERY",
  Prenotazioni: "PRENOTAZIONI",
  VisiteAlMenu: "VISUALIZZAZIONE MENU",
  VisitelAlLocale: "VISITE AL LOCALE",
  Ricercadelpersonale: "RICERCA DEL PERSONALE",
});

export const italianSpecialParamsMap = {
  delivery_page_view: "Visualizzazione pagina delivery",
  reservation_page_view: "Visualizzazione pagina prenotazioni",
  menu_page_view: "Visualizzazione menù",
};

// reusable estimate param
const potentialClientsReached = {
  fieldName: "potential_customers_reached",
  label: "Potenziali Clienti Raggiunti",
};

// main campaigns (goals)
export const campaigns = [
  {
    name: "Notorietà",
    color: "#2ACB91",
    description:
      "Aumenta la notorietà del tuo ristorante, attirando clienti che cosi ti ricorderanno.",
    route: "/marketing/create-campaigns/brand",
    value: Goal.Branding,
    dsGoal: DSGoal.Marchio,
    adCopyGoal: AdCopyGoal.Marchio,
    icon: BrandingIcon,
    estimates: [potentialClientsReached],
  },

  {
    name: "Interazioni",
    color: "#FC6371",
    description:
      "Ottieni più Mi piace, commenti e messaggi sulle tue pagine Social.",
    route: "/marketing/create-campaigns/interactions",
    value: Goal.Interactions,
    dsGoal: DSGoal.Interazioni,
    adCopyGoal: AdCopyGoal.Interazioni,
    icon: InteractionsIcon,
    estimates: [
      potentialClientsReached,
      {
        fieldName: "social_interactions",
        label: "Interazioni Social",
      },
    ],
  },

  // {
  //   name: "Promozioni",
  //   color: "#FFBC46",
  //   description: "Aumenta le prenotazioni dei tavoli per il tuo locale.",
  //   route: "/marketing/create-campaigns/promotions",
  //   value: Goal.Promotions,
  //   dsGoal: DSGoal.Promozioni,
  //   icon: PromotionIcon,
  //   estimates: [
  //     potentialClientsReached,
  //     {
  //       fieldName: "promotions_page_view",
  //       label: "Clic sulla promozione",
  //     },
  //   ],
  // },

  {
    name: "Delivery",
    color: "#FFB077",
    description: "Aumenta le tue consegne a domicilio tramite sito web e app.",
    route: "/marketing/create-campaigns/delivery",
    value: Goal.Delivery,
    dsGoal: DSGoal.Delivery,
    adCopyGoal: AdCopyGoal.Delivery,
    icon: Delivery,
    estimates: [
      potentialClientsReached,
      {
        fieldName: "delivery_page_view",
        label: "Visualizzazione Pagina Delivery",
      },
    ],
  },

  {
    name: "Prenotazioni",
    color: "#0C7292",
    description: "Aumenta le prenotazioni dei tavoli per il tuo locale.",
    route: "/marketing/create-campaigns/reservations",
    value: Goal.Reservation,
    dsGoal: DSGoal.Prenotazioni,
    adCopyGoal: AdCopyGoal.Prenotazioni,
    icon: Reservation,
    estimates: [
      potentialClientsReached,
      {
        fieldName: "reservation_page_view",
        label: "Visualizzazione Pagina Prenotazioni",
      },
    ],
  },

  {
    name: "Visualizzazione menù",
    color: "#7910AA",
    description:
      "Indirizza più clienti sul tuo sito Web o su altre piattaforme per avere più visibilità.",
    route: "/marketing/create-campaigns/online-traffic",
    value: Goal.MenuViews,
    dsGoal: DSGoal.VisiteAlMenu,
    adCopyGoal: AdCopyGoal.VisiteAlMenu,
    icon: WebsiteTrafficIcon,
    estimates: [
      potentialClientsReached,
      {
        fieldName: "menu_page_view",
        label: "Visualizzazione menù",
      },
    ],
  },

  {
    name: "Visite al locale",
    color: "#2B65BB",
    description:
      "Promuovi la posizione del tuo ristorante ai clienti che si trovano nelle vicinanze.",
    route: "/marketing/create-campaigns/offline-traffic",
    value: Goal.LocalVisits,
    dsGoal: DSGoal.VisitelAlLocale,
    adCopyGoal: AdCopyGoal.VisitelAlLocale,
    icon: OfflineTrafficIcon,
    estimates: [
      {
        fieldName: "potential_customers",
        label: "Clienti nelle Vicinanze",
      },
    ],
  },

  {
    name: "Ricerca del Personale",
    color: "#C2222B",
    description:
      "Pubblica un annuncio per far sapere che sei alla ricerca di personale per il tuo ristorante.",
    route: "/marketing/create-campaigns/staff-search",
    value: Goal.StaffSearch,
    dsGoal: DSGoal.Ricercadelpersonale,
    adCopyGoal: AdCopyGoal.Ricercadelpersonale,
    icon: StaffSearchIcon,
    estimates: [
      {
        fieldName: "potential_customers",
        label: "Potenziali persone nelle Vicinanze",
      },
    ],
  },
];

/**
 * Get main goal data of this goal.
 * Returns first goal as default.
 * @param {String} goal goal
 */
export const getGoalData = (goal) =>
  campaigns.find((c) => c.value === goal) || campaigns[0];

/**
 * Get main goal data of this data science goal.
 * Returns first goal as default.
 * @param {String} dsGoal data science goal
 */
export const getGoalDataFromDSGoal = (dsGoal) =>
  campaigns.find((c) => c.dsGoal === dsGoal) || campaigns[0];

/**
 * Get data science goal from main goal
 * Returns first goal as default.
 * @param {String} dsGoal data science goal
 */
export const getDSGoal = (goal) => getGoalData(goal)?.dsGoal;

/**
 * Get firstparams and secondparams for a goal's estimate
 * @param {Object} goal goal
 * @param {String} estimateData estimate data from backend
 * returns Object with { firstparams, firstparamsLabel, secondparams, secondparamsLabel }
 */
export function mapGoalEstimateToViewModel(goal, estimateData) {
  const defaultData = {
    firstparams: 0,
    firstparamsLabel: "",
    secondparams: 0,
    secondparamsLabel: "",
  };

  try {
    const params = ["firstparams", "secondparams"];
    return goal.estimates
      .map((estimate, index) => ({
        [params[index]]: estimateData[estimate.fieldName] || 0,
        [`${params[index]}Label`]: estimate.label || "",
      }))
      .reduce((ttl, curr) => ({ ...ttl, ...curr }), defaultData);
  } catch (error) {
    return defaultData;
  }
}

/**
 * Combines all goals and estiamtes into one object
 * @param {String} goals goals data
 * @param {String} estimate estimate data
 */
export function combineGoalsAndEstimates(goals, estimates) {
  return goals.map((goal) => {
    const estimate = estimates[goal.dsGoal];
    return {
      ...goal,
      ...mapGoalEstimateToViewModel(goal, estimate),
    };
  });
}

//---------------------- BUYER PERSONAS ----------------------//

const DEFAULT_PERSONA = "creative";

// list of buyer personas
export const buyerPersonas = [
  {
    value: "creative",
    label: "Creative",
  },
  {
    value: "business-woman",
    label: "Business Woman",
  },
  {
    value: "family",
    label: "Family",
  },
  {
    value: "student",
    label: "Student",
  },
  {
    value: "cat-lover",
    label: "Cat Lover",
  },
  {
    value: "custom-audience",
    label: "Custom Audience",
  },
];

// map of buyer persona strings - persona -> data science persona
export const buyerPersonaMap = Object.freeze({
  family: "family",
  "business-woman": "business_woman",
  creative: "creative",
  student: "student",
  "cat-lover": "cat_lover",
});

export const getBuyerPersona = (persona) =>
  buyerPersonaMap[persona] || DEFAULT_PERSONA;

function getOtherPersonaStyles({ image, color }) {
  return {
    imageDiv: {
      background: `url('${image}') no-repeat`,
      backgroundSize: "cover",
      boxShadow: "0px 1.98697px 34.772px -15.8958px rgba(0, 0, 0, 0.25)",
    },
    descriptionDiv: {
      background: color,
    },
    circularImageDiv: {
      background: `url('${image}') no-repeat 65% 0%`,
      backgroundSize: "cover",
    },
  };
}

export function getOtherPersonas(otherPersonas) {
  return otherPersonas.map((p) => ({
    ...p,
    value: p.type,
    styles: getOtherPersonaStyles(p),
    oldValue: "creative",
  }));
}

export function isOtherPersona(personaValue) {
  return personaValue === null || personaValue === undefined ? false : true;
  // return !MAIN_BUYER_PERSONAS.includes(personaValue);
}

/**
 * Converts API structure and returns an array of objects that are easier to work with
 * in the front end
 * @param dsData - this is the data that comes from the API
 * @returns An array of buyer personas
 */
export function mapToBuyerPersonaArray(dsData) {
  const personas = [
    {
      label: "Cliente ideale",
      value: "PRIMARY",
      data: dsData.primary_buyer_persona,
    },
  ];

  return personas.map(({ label, value, data }, index) => {
    // id for react state management
    const id = (index + 1).toString();

    // translate gender to italian
    const gender = translateGender(data.gender[0]);

    // select an image from our assets for a man/woman
    const image = getImage(gender, data.age_max);

    // create age range display eg. 15-30
    const ageInfo = `${data.age_min}-${data.age_max}`;

    // combine location information
    const location = data.geo_zone?.join("-") || "";

    // map and get google & facebook interests
    const googleInterests = data.google_interests_to_display || [];
    const facebookInterests = data.facebook_interests_to_display || [];

    // check if persona uses these devices
    const usesMobile = data?.device_category?.includes("mobile");
    const usesTablet = data?.device_category?.includes("tablet");
    const usesDesktop = data?.device_category?.includes("desktop");

    // the final object
    return {
      // to be deprecated soon (someone needs to fix the adCopy api)
      oldValue: "creative",

      id,
      label,
      value,
      data,
      displayData: {
        ageInfo,
        gender,
        location,
        googleInterests,
        facebookInterests,
        usesMobile,
        usesTablet,
        usesDesktop,
        image,
      },
    };
  });
}

function translateGender(gender) {
  const genders = { male: "Uomo", female: "Donna" };
  return genders[gender] || "Altro";
}

function getImage(gender, ageMax, index) {
  // if (gender === "Donna" && ageMax < 45) return Woman1;
  // if (gender === "Donna" && ageMax >= 45) return Woman2;
  // if (gender === "Uomo" && ageMax < 45) return Man1;
  // if (gender === "Uomo" && ageMax >= 45) return Man2;

  if (gender === "Uomo") return NewMan;
  if (gender === "Donna") return NewWoman;

  return getRandomNumber(1, 10) < 5 ? NewMan : NewWoman;
}

//---------------------- Dates and budgets ----------------------//

// dealing with dates
const ONE_HOUR_IN_MS = 1000 * 60 * 60;
const TWO_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 2;
const SIX_MONTHS_IN_MS = 6 * 30 * 24 * 60 * 60 * 1000;
const TEN_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 10;

const userTimezone = moment.tz.guess();
export const createFormattedDate = (dateString) =>
  moment(dateString).tz(userTimezone).format("YYYY-MM-DDTHH:mm");

export const getDateNow = () =>
  moment()
    .tz(userTimezone || "Europe/Rome")
    .format("YYYY-MM-DDTHH:mm");

export const getDateAfterTenDays = () =>
  createFormattedDate(
    moment()
      .tz(userTimezone || "Europe/Rome")
      .valueOf() + TEN_DAYS_IN_MS,
  );

export const getDateAfterAnHour = () =>
  createFormattedDate(
    moment()
      .tz(userTimezone || "Europe/Rome")
      .valueOf() + ONE_HOUR_IN_MS,
  );

export const getDateAfterAnHourAndTwoDays = () =>
  createFormattedDate(
    moment()
      .tz(userTimezone || "Europe/Rome")
      .valueOf() +
      ONE_HOUR_IN_MS +
      TWO_DAYS_IN_MS,
  );

export const getNumberOfDaysBetween = (start, end) =>
  moment(end).diff(moment(start), "days");

export const areSameDate = (first, second) =>
  new Date(first).getTime() === new Date(second).getTime();

export const calculateTotalBudget = (budget, endDate) =>
  moment(endDate).diff(
    moment(
      new Date(
        moment()
          .tz(userTimezone || "Europe/Rome")
          .valueOf(),
      ),
    ),
    "days",
  ) * parseInt(budget);

//---------------------- Validation ----------------------//

const areDatesInvalid = (startDate, endDate) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const duration = end - start;

  return duration < TWO_DAYS_IN_MS || duration > SIX_MONTHS_IN_MS;
};

// campaign creation validation
const GENERIC_ERROR_MESSAGE =
  "C'è stato un errore. Riprova o ricarica la pagina.";

const campaignCreationSchema = Joi.object({
  goal: Joi.string().required().messages({
    "any.required": GENERIC_ERROR_MESSAGE,
  }),
  dsGoal: Joi.string().required().messages({
    "any.required": GENERIC_ERROR_MESSAGE,
  }),

  startDate: Joi.string().required().messages({
    "string.empty": "Scegli una data per avviare la campagna.",
  }),
  endDate: Joi.string().required().messages({
    "string.empty": "Inserisci la data di fine per avviare la tua campagna!",
  }),

  budget: Joi.number().required().messages({
    "any.required": "Scegli un pacchetto per questa la campagna.",
  }),

  buyerPersona: Joi.string().required().messages({
    "any.required": "Scegli una persona per questa la campagna.",
  }),

  image: Joi.string().required().messages({
    "any.required":
      'Scegli un template per questa campagna e clicca su "Salva".',
  }),

  copy: Joi.string().min(1).required().messages({
    "any.required": "La descrizione non può essere vuota.",
    "string.empty": "La descrizione non può essere vuota.",
    "string.min": "La descrizione deve avere almeno un carattere",
  }),

  allSuggestedBudgets: Joi.object().required().messages({
    "any.required": GENERIC_ERROR_MESSAGE,
  }),
});

const campaignModificationSchema = Joi.object({
  endDate: Joi.string().required().messages({
    "string.empty": "Inserisci la data di fine per avviare la tua campagna!",
  }),

  budget: Joi.any().messages({
    "any.required": "Scegli un pacchetto per questa la campagna.",
  }),

  copy: Joi.any().messages({
    "any.required": "La descrizione non può essere vuota.",
  }),
});

/**
 * Validates data required to create the campaign
 * @param {Object} data Form data to validate
 * @returns error message
 */
export const validateCreation = (data) => {
  const { error } = campaignCreationSchema.validate(data);
  if (error) return error.details[0].message;
  if (areDatesInvalid(data?.startDate, data?.endDate))
    return "La durata della campagna deve essere almeno 2 giorni e al massimo 6 mesi.";

  return null;
};

/**
 * Validates data required to modify the campaign
 * @param {Object} data Form data to validate
 * @returns error message
 */
export const validateModification = (data) => {
  const { error } = campaignModificationSchema.validate(data);
  if (error) return error.details[0].message;

  if (areDatesInvalid(data.startDate, data.endDate))
    return "La durata della campagna campagna dev'essere almeno 2 giorni.";

  return null;
};
