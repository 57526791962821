import { useEffect, useState } from "react";
import * as service from "../apis/templateService";

export function useUserSocialTemplates({ goal, deps, onLoad } = {}) {
  return useUserTemplates(service.Type.Social, { goal, deps, onLoad });
}

export function useUserMarketingTemplates({ goal, persona, deps, onLoad } = {}) {
  return useUserTemplates(service.Type.Marketing, {
    goal,
    persona,
    deps,
    onLoad,
  });
}

export function useUserNewsletterTemplates({ goal, deps, onLoad } = {}) {
  return useUserTemplates(service.Type.Newsletter, { goal, deps, onLoad });
}

export function useUserAutoMailTemplates({ goal, deps, onLoad } = {}) {
  return useUserTemplates(service.Type.AutoMail, { goal, deps, onLoad });
}

const typeFunctionMap = {
  [service.Type.Social]: service.getUserSocialTemplates,
  [service.Type.Marketing]: service.getUserMarketingTemplates,
  [service.Type.Newsletter]: service.getUserNewslettersTemplates,
  [service.Type.AutoMail]: service.getUserAutoMailTemplates,
};

function useUserTemplates(type, { goal, persona, deps, onLoad } = {}) {
  const [userTemplates, setUserTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // load user saved templates from db
  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const templates = await typeFunctionMap[type](goal, persona);
      if (templates) {
        setUserTemplates(templates);
        onLoad && onLoad(templates);
      }

      setIsLoading(false);
    })();
  }, deps || []);

  return [userTemplates, isLoading, setUserTemplates];
}
