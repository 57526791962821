import { TextField } from "@mui/material";
import { useContext } from "react";
import { toast } from "react-toastify";
import HtppIcon from "../../Assets/Icons/http.png";
import {
  LinearProgressGreen,
  LinearProgressRed,
} from "../../Components/Commons/Marketing/OnProgressCampaign/AllProgressBars";
import { MarketingCampaignContext } from "../../Contexts/MarketingCampaignContext";
import formatCurrency from "../../Utility/formatCurrency";
import "./BudgetSelection.css";
const packageLabels = ["Piccolo", "Medio", "Grande"];
const suggestedPackageLabel = ["Suggerito"];

const TipoPachetto = ({ packages, selectedPackage, onPackageSelected }) => {
  const { setNumber, number } = useContext(MarketingCampaignContext);

  const hasEstimatedResults = packages.every((p) => !!p?.estimate);
  const potentialClients =
    packages[selectedPackage]?.estimate?.potential_customers_reached || 0;
  const socialInteractions =
    packages[selectedPackage]?.estimate?.social_interactions || 0;
  const linearProgressValue = (selectedPackage + 1) * 33.33;

  const labels = packages?.length === 1 ? suggestedPackageLabel : packageLabels;
  const getPackageClasses = (index) =>
    `${
      selectedPackage === index
        ? "text-white text-base elevation-1 bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig"
        : "text-marketingtextcolour text-base bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
    } flex flex-row w-40 h-12 gap-x-2 rounded-md items-center justify-center`;

  const handlePackageClick = (packageType) => {
    onPackageSelected(packageType);
    setNumber("");
  };
  const handleChange = (event) => {
    const value = event.target.value;

    setNumber(value);
    onPackageSelected("");
    if (value === "") {
      onPackageSelected(1);
    }

    if (value < 2) {
      toast.warning("You need enter a valid budget with more than 2£.");
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex flex-row w-full my-4 gap-x-4 elevation-1 rounded-md mx-auto p-4 divide-x-2 divide-marketingbtnhover bg-white justify-center">
        {/* ESTIMATED RESULTS */}
        {!number && hasEstimatedResults && (
          <div className="flex flex-col w-1/2 justify-start items-center gap-y-4">
            <div className="flex w-full justify-self-start self-start">
              <p className="text-marketingtextcolour font-semibold text-2xl text-left">
                Risultati Attesi al Giorno
              </p>
            </div>

            <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
              {!!potentialClients && (
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            Potenziali Clienti Raggiunti
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!!socialInteractions && (
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#2AD044] font-normal text-lg">
                            Interazioni Social
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#2AD044] w-full">
                            {socialInteractions}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressGreen value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* {types["branding"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["interactions"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#2AD044] font-normal text-lg">
                            {" "}
                            Interazioni Social{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#2AD044] w-full">
                            {socialInteractions}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressGreen value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["promotions"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["delivery"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#2AD044] font-normal text-lg">
                            {" "}
                            Visualizzazione Pagina Delivery{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#2AD044] w-full">
                            {deliveryPageViews}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressGreen value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["reservation"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#2AD044] font-normal text-lg">
                            {" "}
                            Iscrizioni{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#2AD044] w-full">
                            {subscriptions}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressGreen value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["websitetraffic"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#2AD044] font-normal text-lg">
                            {" "}
                            Persone che guardano il menù{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#2AD044] w-full">
                            {menuPageViews}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressGreen value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {types["offlinetraffic"] ? (
              <div className="flex flex-col w-full h-full mx-auto justify-evenly mt-3">
                <div className="flex flex-row w-full justify-center items-center gap-x-5">
                  <div className="flex">
                    <img
                      src={HtppIcon}
                      alt=""
                      className="w-full h-full object-fit"
                    />
                  </div>

                  <div className="flex flex-row w-full justify-center items-center gap-x-4">
                    <div className="flex flex-col gap-y-2 items-center w-full">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex">
                          <p className="text-[#D02A2A] font-normal text-lg">
                            {" "}
                            Potenziali Clienti Raggiunti{" "}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-3xl text-bold text-[#D02A2A] w-full">
                            {potentialClients}
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[7px]">
                        <LinearProgressRed value={linearProgressValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
          </div>
        )}

        {/* PACKAGE COSTS */}

        <div className="flex flex-col w-1/2 justify-center items-center gap-y-4">
          <div className="flex w-full justify-center items-center">
            <p className="text-marketingtextcolour font-semibold text-2xl text-left">
              Budget Giornaliero
            </p>
          </div>

          <div className="flex flex-row w-full h-full justify-center items-center mx-auto gap-x-4">
            {labels.map((label, i) => (
              <button
                key={label}
                onClick={() => handlePackageClick(i)}
                className={getPackageClasses(i)}>
                {label}:{" "}
                <span className="font-semibold">
                  {formatCurrency(packages[i]?.budget)}
                </span>
              </button>
            ))}
          </div>

          <div id="BudgedSelection" className="">
            <TextField
              id="outlined-name"
              label="Personalizzato"
              value={number}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipoPachetto;
