import React, { useRef } from 'react';
import { ButtonProva } from '../../../../Reusable/Button-NEW/ButtonProva';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { RestaurantInfoContext } from '../../../../Contexts/RestaurantInfoContext';
import { addImageFromEditor, addLogoFromEditor } from '../helpers/layerCreator';

export const ImageSection = ({
    commonProps,
    canvasSize,
    setImageIsSelected,
    setImageOpacities,
    imageOpacities,
    setUploadedFiles,
    uploadedFiles,
}) => {
    const fileInputRef = useRef();
    const { RestaurantInfo } = React.useContext(RestaurantInfoContext);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleUpload = (event) => {
        addImageFromEditor(
            event,
            commonProps,
            canvasSize,
            setImageOpacities,
            setImageIsSelected,
            setUploadedFiles,
            uploadedFiles
        );
    };
    const addLogoToCanvas = (logo) => {
        addLogoFromEditor(
            logo,
            commonProps,
            setImageOpacities,
            setImageIsSelected
        );
    };
    return (
        <div className='flex flex-col'>
            <h6>Carica un'immagine</h6>
            <div className='mt-4'>
                <ButtonProva
                    text='Aggiungi'
                    textcolor='white'
                    bgcolor='[#00b27a]'
                    Icon={AddCircleOutlineOutlinedIcon}
                    event={handleButtonClick}
                />
                <input
                    type='file'
                    onChange={handleUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
            </div>
            <h6 className='mt-8'>Clicca per inserire il tuo logo</h6>
            <button
                className='w-fit h-[80px] mt-4'
                onClick={() => addLogoToCanvas(RestaurantInfo.logo)}
            >
                <div className='w-full h-full overflow-hidden rounded-sm border-[1px] border-black'>
                    <img
                        src={RestaurantInfo?.logo}
                        className='w-full h-full'
                        alt='logo'
                    ></img>
                </div>
            </button>
        </div>
    );
};
