import React, { useEffect, useState } from "react";
import TemplateCard from "./TemplateCard";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import "./SelectTemplate.css";
import defaultImage from "../../Assets/Icons/tempCardImage.png";

export default function SelectTemplate({
  templates,
  onTemplateSelected,
  useName,
}) {
  // handling display of all templates
  const [isOpen, SetOpen] = useState(false);
  const closeAll = () => SetOpen(false);
  const showAll = () => SetOpen(true);


  // if template is selected from inside, close modal as well
  const handleSelectionInside = template => {
    closeAll();
    onTemplateSelected(template);
  };

  // handling display of singel template image
  const [singleTemplatePreview, setSingleTemplatePreview] = useState(false);


  const [TemplateName, SetName] = React.useState(null)
  const [Image, SetImage] = React.useState(null);

  const openSingleTemplatePreview = (img) => {
    console.log(img)
    setSingleTemplatePreview(true);
    SetImage(img?.image)
    SetName(img?.name)
  }
  const closeSingleTemplatePreview = () => {
    setSingleTemplatePreview(false);
    SetImage(null)
    SetName(null)
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    zIndex: 1000,
    borderRadius: 4
  };



  const [shuffledTemplates, setShuffledTemplates] = useState([]);



  useEffect(() => {
    const shuffleArray = (array) => {
      const newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    };

    if (templates) {
      setShuffledTemplates(shuffleArray(templates));
    }
  }, [templates,isOpen]);


  return (
    <>
      {isOpen &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={() => SetOpen(false)}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>
            <Box sx={{ ...style, width: "80%", height: "90%", overflow: "scroll", maxHeight: "90%" }}>
              <div className="flex flex-col items-center w-full h-full justify-center gap-y-6">
                {shuffledTemplates?.length > 0 && (
                  <div className="grid grid-cols-4 gap-4 justify-center items-center transition-all h-full px-6">
                    {shuffledTemplates?.map((template, index) => (
                      <TemplateCard
                        key={template?.name}
                        template={template}
                        onSelected={handleSelectionInside}
                        label={useName ? template.name : `Template ${index + 1}`}
                        index={index}
                        onShowPreview={openSingleTemplatePreview}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      }

      {/* MODAL FOR SINGLE TEMPLATE PREVIEW */}
      {singleTemplatePreview &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={singleTemplatePreview}
          onClose={closeSingleTemplatePreview}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={singleTemplatePreview}>
            <Box sx={style}>
              <div className="reltaive flex flex-col items-center h-full w-full">
                <div className="w-full flex items-center justify-center h-12 border-b-[1px] mb-5 border-gray-300" >
                  <p className="font-normal text-black " >{TemplateName}</p>
                </div>
                <img
                  src={Image || defaultImage}
                  alt=""
                  className="w-[90%] mx-auto h-min rounded-md grow"
                />

                <button
                  className="bg-newslettertextcolour  border-2 border-newslettertextcolour h-fit py-2 px-1 text-sm text-white rounded-md w-1/3 mx-auto self-center my-3"
                  onClick={() => closeSingleTemplatePreview()} > chiudi  </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      }

      {/* MAIN SECTION */}
      <div className="flex flex-col items-center w-full justify-center gap-y-6">
        {shuffledTemplates?.length > 0 && (
          <div className="grid grid-cols-4 gap-4 justify-center items-center transition-all px-6 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
            {shuffledTemplates?.slice(0, 4).map((template, index) => (
              <TemplateCard
                key={template?.name}
                template={template}
                onSelected={handleSelectionInside}
                label={useName ? template.name : `Template ${index + 1}`}
                index={index}
                onShowPreview={openSingleTemplatePreview}
              />
            ))}
          </div>
        )}




        {shuffledTemplates.length > 4 &&
          <button
            onClick={() => showAll()}
            className={`
            bg-newslettertextcolour border-newslettertextcolour text-white 
          border-2 py-1 px-1 text-sm rounded-md w-40 h-12 elevation-1`}
          >
            {"Mostra Tutti"}
          </button>

        }

      </div>

    </>

  );
}
