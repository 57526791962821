import { useNavigate } from "react-router-dom";
import BuyerPersonaHeaderLottie from "../../../../Assets/Lottie/Buyer-Persona.json";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import "../../../../Reusable/CSS/Page-Layout.css";
import Loader from "../../../../Reusable/Loader/Loader";
import PersonInfoCard from "./TypePersonaCard";
import BuyNavigation from "../BuyNavigation/BuyNavigation";
import useBuyerPersonas from "../../../../Hooks/useBuyerPersonas";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/2/Informational-Top-Banner"
    )
  ).default;
}

function BuyerPersonaType() {
  const navigate = useNavigate();

  const [personas, isLoading] = useBuyerPersonas();

  const handleGoToCampaignClick = persona =>  navigate("/marketing/create-campaigns", { state: persona });

  return (
    <>
      <ScrollToTop />

      <div className="w-full pb-[5rem]">
        <InformationTopBanner
          pagetype="Sezione"
          sectionname="Cliente Tipo"
          description={
            process.env.REACT_APP_PROJECT === "Unifarco"
              ? "La tua farmacia"
              : "All'interno di questa sezione puoi scoprire le caratteristiche del Cliente-tipo del tuo ristorante."
          }
          sectionicon={BuyerPersonaHeaderLottie}
        />

        <BuyNavigation />

        <div className="flex w-full h-full">

          {isLoading && <Loader className="flex my-10 justify-center items-center" />}

          <div className="flex flex-row w-full h-full justify-center items-center gap-10 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col xsm:px-4 sm:px-4 md:px-4">

            {personas?.map(persona => (

              <div
                className="flex flex-col w-[30em] h-[33em] justify-center items-center rounded-md elevation-1 xsm:w-full sm:w-full md:w-full"
                key={persona.value}
              >

                <p className="font-semibold text-2xl text-buyerpersonatextcolour text-left self-center py-2">
                  
                  {persona.label}

                </p>

                <PersonInfoCard
                  data={persona}
                  onGoToCampaignClick={handleGoToCampaignClick}
                />

              </div>

            ))}

          </div>

        </div>

      </div>

    </>

  );
}

export default BuyerPersonaType;
