import axios from "axios";
import { baseUrl } from "../Utility/Api/Urls";
import { getAuthConfig } from "../Utility/Api/auth";

const urlbaseEndpoint = `https://${
  process.env.REACT_APP_DASHBOARD_PREFIX
}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;

// generate strip link for credit
export const generateStripLink = async (amount, method) => {
    try
    {
    const obj = {
        price : amount,
    }
    // https://dashboard.develop.rc.aigotsrl-dev.com/api/payments/stripe/checkout
    const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/payments/stripe/checkout?method=${method}`;
    const {data} = await axios.post(url, obj, getAuthConfig());
    return data.link;
    }
    catch(err)
    {
        return err;
    }
}
// generate paypal link for credit
export const generatePaypalLink = async (credit) => {
    try
    {
    const obj = {
        price : credit,
    }
    const url = `https://${process.env.REACT_APP_WIZARD_PREFIX}${baseUrl()}${process.env.REACT_APP_WIZARD_API_PATH}/paypalSubscription`;
    const {data} = await axios.post(url, obj, getAuthConfig());
    return data.link;
    }
    catch(err)
    {
        return err;
    }
}
// get account balance
export const getAccountBalance = async () => {
    try
    {
        //https://dashboard.develop.rc.aigotsrl-dev.com/api/payments/getBalance
    const url =  `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/payments/getBalance`;
    const {data} = await axios.get(url, getAuthConfig());
    return data.balance;
    }
    catch(err)
    {
        return err;
    }
}

/**
 * Reduce balance amount
 * @param {Number} amount Amount (positive) to reduce balance
 * @returns something
 */
export const reduceBalance = async amount => {
    const url = `${urlbaseEndpoint}/pharmacist/balance`;
    const body = {
        balance: -Math.abs(Number(amount)),
    };
    const result = await axios.patch(url, body, getAuthConfig());

    if (!result?.data)
        throw new Error(
            "C'è stato un errore con il pagamento. Contatta l'assistenza."
        );

    return result.data;
};
