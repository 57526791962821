import { useCallback, useEffect, useState } from "react";

// screen type enum
const ScreenType = Object.freeze({
  xsm: "xsm",
  sm: "sm",
  md: "md",
  xmd: "xmd",
  lg: "lg",
  xl: "xl",
});

// screen sizes setup (taken from tailwind cofig file)
const SCREENS = {
  [ScreenType.xsm]: [{ min: "100px", max: "365px" }],
  [ScreenType.sm]: [{ min: "366px", max: "426px" }],
  [ScreenType.md]: [{ min: "427px", max: "834px" }],
  [ScreenType.xmd]: [{ min: "835px", max: "1099px" }],
  [ScreenType.lg]: [{ min: "1100px", max: "1399px" }],
  [ScreenType.xl]: "1400px",
};

// utility function to get the screen type
const getScreenType = width => {
  for (let screenType of Object.keys(SCREENS)) {
    const min = Number(SCREENS[screenType][0]?.min?.split("p")[0]);
    const max = Number(SCREENS[screenType][0]?.max?.split("p")[0]);
    if (width >= min && width <= max) return screenType;
  }
  return ScreenType.xl;
};

const useWindowSize = () => {
  const isClient = typeof window === "object";

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);
  const [windowSize, setWindowSize] = useState(getSize());
  

  const getType = useCallback(() => {
    return getScreenType(windowSize.width);
  }, [isClient, windowSize]);
  const [screenType, setScreenType] = useState(getType());
  
  const handleResize = () => {
    const size = getSize();
    setWindowSize(size);
    setScreenType(getScreenType(size.width));
  };
  
  useEffect(() => {
    if (!isClient) return false;

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return {windowSize, screenType};
};

export default useWindowSize;
