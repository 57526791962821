import React, { useEffect, useState } from "react";
import BeePlugin from "@mailupinc/bee-plugin";
import axios from "axios";
import { toast } from "react-toastify";

import "./BeePlugin.css";
import tmp from "./beeTemplate";
import { getUserId } from "../../Utility/getUserData";
import { getAuthConfig } from "../../Utility/Api/auth";
import { baseUrl } from "../../Utility/Api/Urls";
import getCustomFonts from "./getCustomFonts";
import advancedPermissions from "./advancedPermissions";

const ERROR_MESSAGE = "C'è stato un errore. Riprova o contatta l'assistenza.";

function BeeComponent({
  setImage,
  template = tmp,
  setIsSaving,
  isSaving,
  setJson,
  setHtml,
  imageWidth = 512,
  imageHeight,
  onError,
  imageType = "jpg",
  onImageCreated,
}) {
  const [bee, setBee] = useState();

  const beeConfig = {
    container: "bee-plugin-container",
    language: "it-IT",
    workspace: {
      popup: {
        layout: "bar-top",
      },
    },

    advancedPermissions,

    autosave: true,

    editorFonts: {
      showDefaultFonts: true,
      customFonts: getCustomFonts(),
    },

    onSave: (json, html) => {
      if (setJson) setJson(JSON.parse(json));
      if (setHtml) setHtml(html);

      if (!setImage || !imageWidth) return;

      let payload = { html, format: imageType };

      if (imageWidth && imageHeight) {
        payload.width = imageWidth;
        payload.height = imageHeight;
      } else payload.size = imageWidth;

      axios
        .post(
          `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
            process.env.REACT_APP_DASHBOARD_API_PATH
          }/bee/image`,
          payload,
          getAuthConfig()
        )
        .then(res => {
          const type = imageType !== "png" ? "jpeg" : "png";
          const data = `data:image/${type};base64,` + res?.data?.data;
          setImage(data);
          onImageCreated && onImageCreated(data);
        })
        .catch(err => {
          toast.error(ERROR_MESSAGE);
          console.error(err);
        });
    },

    onSaveAsTemplate: jsonFile => {},

    onSend: htmlFile => {},

    onError: errorMessage => {
      if (onError) onError(errorMessage);
    },
  };

  useEffect(() => {
    if (isSaving) {
      handleSave();
      setIsSaving(isSaving => !isSaving);
    }
  }, [isSaving, setIsSaving]);

  useEffect(() => {
    (async () => {
      if (bee) return bee.reload(template);

      try {
        const result = await axios.get(
          `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${
            process.env.REACT_APP_DASHBOARD_API_PATH
          }/bee/token`,
          getAuthConfig()
        );
        const { token } = result.data;
        const beeInstance = new BeePlugin(token);
        setBee(beeInstance);
        beeInstance.start({ ...beeConfig, uid: getUserId() }, template);
      } catch (error) {
        toast.error(ERROR_MESSAGE);
        console.error(error);
      }
    })();
  }, [template]);

  const handleSave = () => {
    bee?.save();
  };

  return <div id="bee-plugin-container" onClick={handleSave} />;
}
export default BeeComponent;
