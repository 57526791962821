import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import AddColor from './img/addcolor.svg';
import recursiveFillSetter from '../helpers/recursiveFillSetter';

export const ColorPalette = (props) => {
    const colorArray = [
        '#000000',
        '#686868',
        '#9a9a9a',
        '#d3d3d3',
        '#ffffff',
        '#d69253',
        '#fb9333',
        '#ffd382',
        '#fff4e0',
        '#ffe3e2',
        '#45342b',
        '#86291d',
        '#9d4c32',
        '#cf5542',
        '#ce8786',
        '#003447',
        '#2580bb',
        '#74b9f2',
        '#68cf64',
        '#368b70',
    ];
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const handleCanvaColorClick = (color) => {
        props.setSelectedCanvaColor(color);
    };
    const handleCanvaColorChange = (color) => {
        handleCanvaColorClick(color.hex);
    };
    const updateFontColor = (color) => {
        const activeObject = props.canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({ fill: color });
            props.canvas.renderAll();
            props.setFontColor({
                ...props.fontColor,
                [textId]: color,
            });
        }
        props.updateLayer(activeObject);
    };
    const handleFontColorChange = (color) => {
        props.setSelectedFontColor(color.hex);
        updateFontColor(color.hex);
    };
    const updateElementColor = (color) => {
        const activeObject = props.canvas.getActiveObject();
        if (activeObject) {
            const elementId = activeObject.get('id');
            recursiveFillSetter(activeObject, color);
            props.canvas.renderAll();
            props.setElementColor({
                ...props.elementColor,
                [elementId]: color,
            });
            props.updateLayer(activeObject);
        }
    };
    const handleElementColorChange = (color) => {
        props.setSelectedElementColor(color.hex);
        updateElementColor(color.hex);
    };
    const updateElementBorderColor = (color) => {
        const activeObject = props.canvas.getActiveObject();
        if (activeObject && activeObject.type === 'rect') {
            const elementId = activeObject.get('id');
            activeObject.set({ stroke: color });
            props.canvas.renderAll();
            props.setElementBorderColor({
                ...props.elementBorderColor,
                [elementId]: color,
            });
        } else if (activeObject && activeObject.type === 'circle') {
            const elementId = activeObject.get('id');
            activeObject.set({ stroke: color });
            props.canvas.renderAll();
            props.setElementBorderColor({
                ...props.elementBorderColor,
                [elementId]: color,
            });
        }
        props.updateLayer(activeObject);
    };
    const handleElementBorderColorChange = (color) => {
        props.setSelectedElementBorderColor(color.hex);
        updateElementBorderColor(color.hex);
    };
    return (
        <div
            className=''
            id='canva-color-section'
        >
            {'CanvaColorPalette' === props.section && (
                <>
                    <div>
                        <h6>Imposta un colore come sfondo</h6>
                        <div className='w-[50px] h-[50px] mt-6 relative'>
                            <button
                                onClick={(event) => {
                                    setOpenColorPicker(!openColorPicker);
                                    event.stopPropagation();
                                }}
                            >
                                <img
                                    src={AddColor}
                                    alt='AddColor'
                                ></img>
                            </button>
                            {openColorPicker && (
                                <div
                                    className='absolute bottom-[-250px]'
                                    id='chrome-picker-canva-container'
                                >
                                    <ChromePicker
                                        color={props.selectedCanvaColor}
                                        onChange={handleCanvaColorChange}
                                        disableAlpha
                                        className='rounded-md'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-14'>
                        <h6>Colori predefiniti</h6>
                        <div className='flex gap-x-3 flex-wrap gap-y-3 mt-6'>
                            {colorArray.map((color, index) => (
                                <button>
                                    <div
                                        key={index}
                                        className='w-[50px] h-[50px] rounded-sm border border-black '
                                        style={{ backgroundColor: color }}
                                        onClick={(event) => {
                                            handleCanvaColorClick(color);
                                            event.stopPropagation();
                                        }}
                                    />
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {'FontColorPalette' === props.section && (
                <>
                    <div>
                        <h6>Imposta un colore per il testo</h6>
                        <div className='w-[50px] h-[50px] mt-6 relative'>
                            <button
                                onClick={(event) => {
                                    setOpenColorPicker(!openColorPicker);
                                    event.stopPropagation();
                                }}
                            >
                                <img
                                    src={AddColor}
                                    alt='AddColor'
                                ></img>
                            </button>
                            {openColorPicker && (
                                <div
                                    className='absolute bottom-[-250px]'
                                    id='chrome-picker-text-container'
                                >
                                    <ChromePicker
                                        color={props.selectedFontColor}
                                        onChange={handleFontColorChange}
                                        disableAlpha
                                        className='rounded-md'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-14'>
                        <h6>Colori predefiniti</h6>
                        <div
                            className='flex gap-x-3 flex-wrap gap-y-3 mt-6'
                            id='font-color-palette'
                        >
                            {colorArray.map((color, index) => (
                                <button>
                                    <div
                                        key={index}
                                        className='w-[50px] h-[50px] rounded-sm border border-black '
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            updateFontColor(color);
                                            props.setSelectedFontColor(color);
                                        }}
                                    />
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {'ElementColorPalette' === props.section && (
                <>
                    <div>
                        <h6>Imposta un colore per l'elemento</h6>
                        <div className='w-[50px] h-[50px] mt-6 relative'>
                            <button
                                onClick={(event) => {
                                    setOpenColorPicker(!openColorPicker);
                                    event.stopPropagation();
                                }}
                            >
                                <img
                                    src={AddColor}
                                    alt='AddColor'
                                ></img>
                            </button>
                            {openColorPicker && (
                                <div
                                    className='absolute bottom-[-250px]'
                                    id='chrome-picker-text-container'
                                >
                                    <ChromePicker
                                        color={props.selectedElementColor}
                                        onChange={handleElementColorChange}
                                        disableAlpha
                                        className='rounded-md'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-14'>
                        <h6>Colori predefiniti</h6>
                        <div
                            className='flex gap-x-3 flex-wrap gap-y-3 mt-6'
                            id='font-color-palette'
                        >
                            {colorArray.map((color, index) => (
                                <button>
                                    <div
                                        key={index}
                                        className='w-[50px] h-[50px] rounded-sm border border-black '
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            updateElementColor(color);
                                            props.setSelectedElementColor(
                                                color
                                            );
                                        }}
                                    />
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {'ElementBorderColorPalette' === props.section && (
                <>
                    <div>
                        <h6>Imposta un colore per il bordo</h6>
                        <div className='w-[50px] h-[50px] mt-6 relative'>
                            <button
                                onClick={(event) => {
                                    setOpenColorPicker(!openColorPicker);
                                    event.stopPropagation();
                                }}
                            >
                                <img
                                    src={AddColor}
                                    alt='AddColor'
                                ></img>
                            </button>
                            {openColorPicker && (
                                <div
                                    className='absolute bottom-[-250px]'
                                    id='chrome-picker-text-container'
                                >
                                    <ChromePicker
                                        color={props.selectedElementBorderColor}
                                        onChange={
                                            handleElementBorderColorChange
                                        }
                                        disableAlpha
                                        className='rounded-md'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-14'>
                        <h6>Colori predefiniti</h6>
                        <div
                            className='flex gap-x-3 flex-wrap gap-y-3 mt-6'
                            id='font-color-palette'
                        >
                            {colorArray.map((color, index) => (
                                <button>
                                    <div
                                        key={index}
                                        className='w-[50px] h-[50px] rounded-sm border border-black '
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            updateElementBorderColor(color);
                                            props.setSelectedElementBorderColor(
                                                color
                                            );
                                        }}
                                    />
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
