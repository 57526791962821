import format from "date-fns/format"
import React from 'react'
import * as newslettersService from "./newslettersService";

function UseNewsletter(type) {
    const [AllNewsLetters, Setnewsletters] = React.useState([])
    const [newsLetterToShow, SetnewslettersToShow] = React.useState([])
    const [SelectedDate, SetDate] = React.useState(new Date())
    const [isLoading, SetLoading] = React.useState(false)
    async function getNewsletters() {
        SetLoading(true)
        try {
            const res = type === "completed" ? await newslettersService.getCompletedNewsletters() : await newslettersService.getDraftNewsletters()
            const eventsForToday = res?.newsletters?.filter((item) => format(new Date(item.dateOfPublish), "dd MMMM yyyy") === format(new Date(SelectedDate), "dd MMMM yyyy"))
            Setnewsletters(res?.newsletters)
            SetnewslettersToShow(eventsForToday)
            console.log(res)
        } catch (error) {
            console.log(error)
        }
        SetLoading(false)
    }
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [currentIdDeleting, setCurrentIdDeleting] = React.useState(null);
    const handleDeleteConfirm = async () => {
        setIsAlertOpen(false);
        await newslettersService.deleteNewsletter(currentIdDeleting);
        await getNewsletters()
        setCurrentIdDeleting(null);
    };

    const handleOpenDeleteRequest = id => {
        setCurrentIdDeleting(id);
        setIsAlertOpen(true);
    };

    const handleAlertClose = () => {
        setCurrentIdDeleting(null);
        setIsAlertOpen(false);
    };


    React.useEffect(() => { getNewsletters() }, [])

    return { handleDeleteConfirm, newsLetterToShow, SetnewslettersToShow, AllNewsLetters, SetDate, isAlertOpen, handleAlertClose, handleOpenDeleteRequest, SelectedDate, isLoading }
}

export default UseNewsletter