const DEFAULT_VALUE = "All Mailing Lists Customers";

const MailingListSelect = ({ mailingLists, selectedMailingList, onChange }) => {
  const handleChange = event => onChange(event.target.value);
  const value = selectedMailingList?.name || DEFAULT_VALUE;

  return (
    <select
      value={value}
      onChange={handleChange}
      className="focus:outline-none h-12 w-[250px] border-[2px] px-1 border-newsletterbtn rounded-md text-newsletterbtn"
    >
      <option value="All Mailing Lists Customers">
        Tutte le Liste
      </option>

      {mailingLists?.length > 0 &&
        mailingLists.map(ml => {
          const isSelected = selectedMailingList && selectedMailingList.id === ml.id;
          return (
            <option key={ml.id} value={ml.name} selected={isSelected}>
              {ml.name}
            </option>
          );
        })}
    </select>
  );
};

export default MailingListSelect;
