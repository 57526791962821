import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteAlert from "./../../../../../Reusable/DeleteAlert/DeleteAlert";
import "./Completednewsletterlist.css";
import format from "date-fns/format";
import NewsLetterCalendar from "./NewsLetterCalendar";
import Completednewslettercards from "../Cards/Completednewslettercards";
import { CircularProgress } from "@mui/material";
import UseNewsletter from "../../UseNewsletter";
import * as newslettersService from "../../newslettersService";
import useServiceResource from "../../../../../Hooks/useServiceResource";

function Completednewsletterlist() {
  const navigate = useNavigate();

  const {
    handleDeleteConfirm,
    newsLetterToShow,
    SetnewslettersToShow,
    AllNewsLetters,
    SetDate,
    isAlertOpen,
    handleAlertClose,
    handleOpenDeleteRequest,
    SelectedDate,
    isLoading,
  } = UseNewsletter("completed");

  function handleFilterPosts(date) {
    SetDate(date);
    const events = AllNewsLetters.filter(
      (item) =>
        format(new Date(item.dateOfPublish), "dd MMMM yyyy") ===
        format(new Date(date), "dd MMMM yyyy"),
    );
    SetnewslettersToShow(events);
  }

  // Viewing newsletter details
  const handleView = (id) => navigate(`/newsletter/newsletter-review/${id}`);

  const [mailingLists] = useServiceResource({
    apiCall: newslettersService.getMailingLists,
    mapResponse: (res) => res.mailingLists,
  });

  function getNamesByIds(ids) {
    if (!mailingLists || !Array.isArray(mailingLists)) {
      return "";
    }
    const names = mailingLists
      .filter((item) => ids.includes(item.id))
      .map((item) => item.name);
    return names.join(", ");
  }

  return (
    <div className="flex flex-col items-center mt-8">
      <div className="flex flex-row gap-x-8 w-full min-w-full">
        <div className="w-1/3 min-w-[25%]">
          <NewsLetterCalendar
            handleFilterPosts={handleFilterPosts}
            AllNewsLetters={AllNewsLetters}
            SelectedDate={SelectedDate}
          />
        </div>
        <div className="w-2/3 grid grid-cols-3 gap-5 p-3 rounded-md elevation-1">
          {isLoading && (
            <div className="col-span-3 flex flex-col items-center justify-center gap-y-2 mt-8">
              <CircularProgress />
              <p className="text-black font-normal text-center">
                Caricamento...
              </p>
            </div>
          )}
          {!isLoading &&
            Array.isArray(newsLetterToShow).length !== 0 &&
            newsLetterToShow?.map((item) => (
              <Completednewslettercards
                key={item._id}
                item={item}
                mailingListsNames={getNamesByIds(item?.mailingListIds)}
                id={item._id}
                image={item?.imageString}
                title={item?.subject}
                goal={item?.goal}
                date={item?.dateOfPublish}
                onRemove={handleOpenDeleteRequest}
              />
            ))}
          {!isLoading && Array.isArray(newsLetterToShow).length === 0 && (
            <div className="col-span-3 flex flex-col items-center justify-center gap-y-2 mt-8">
              <p className="text-black font-normal text-center">
                Nessuna newsletter creata per questo giorno
              </p>
            </div>
          )}
        </div>
      </div>
      {isAlertOpen && (
        <DeleteAlert
          isOpen={isAlertOpen}
          onConfirm={handleDeleteConfirm}
          onClose={handleAlertClose}
          onReject={handleAlertClose}
          message="Questa campagna verrà eliminata definitivamente!"
        />
      )}
    </div>
  );
}

export default Completednewsletterlist;
