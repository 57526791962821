import React, { useState, useEffect } from 'react';
import Opacity from '../../ImageSection/icon/opacity.svg';
import Border from '../icon/Border.svg';
import './style.css';

export const ElementSectionNavBar = ({
    setElementBorderWidth,
    canvas,
    setElementOpacities,
    updateLayer,
    handleSectionChange,
    elementColor,
    elementOpacities,
    activeObjectId,
    elementBorderColor,
    elementBorderWidth,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMouseLeave = () => {
        setAnchorEl(null);
    };
    const handleMouseEnter2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleMouseLeave2 = () => {
        setAnchorEl2(null);
    };
    const [opacity, setOpacity] = useState(1.0);
    const [borderWidth, setBorderWidth] = useState(0);
    const handleOpacityChange = (event) => {
        const newOpacity = event.target.value / 100;
        setOpacity(newOpacity);
        changeElementOpacity(newOpacity);
        const value =
            ((newOpacity - event.target.min / 100) / (event.target.max / 100)) *
            100;
        event.target.style.setProperty('--value', value + '%');
    };
    const handleBorderWidthChange = (event) => {
        const newWidth = event.target.value / 10;
        setBorderWidth(newWidth);
        changeElementBorderWidth(newWidth);
        const value =
            ((newWidth - event.target.min / 100) / (event.target.max / 100)) *
            100;
        event.target.style.setProperty('--value', value + '%');
    };
    useEffect(() => {
        if (activeObjectId) {
            setOpacity(elementOpacities[activeObjectId] || 0);
        }
    }, [elementOpacities, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setBorderWidth(elementBorderWidth[activeObjectId] || 0);
        }
    }, [elementBorderWidth, activeObjectId]);
    const changeElementOpacity = (newOpacity) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
            const elementId = activeObject.get('id');
            activeObject.set({ opacity: newOpacity });
            canvas.renderAll();
            setElementOpacities({
                ...elementOpacities,
                [elementId]: newOpacity,
            });
            updateLayer(activeObject);
        }
    };
    const changeElementBorderWidth = (newWidth) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'rect') {
            const elementId = activeObject.get('id');
            activeObject.set({ strokeWidth: newWidth });
            canvas.renderAll();
            setElementBorderWidth({
                ...elementBorderWidth,
                [elementId]: newWidth,
            });
        } else if (activeObject && activeObject.type === 'circle') {
            const elementId = activeObject.get('id');
            activeObject.set({ strokeWidth: newWidth });
            canvas.renderAll();
            setElementBorderWidth({
                ...elementBorderWidth,
                [elementId]: newWidth,
            });
        }
        updateLayer(activeObject);
    };
    return (
        <div className='text-[#667085] flex gap-x-3'>
            <div
                style={{
                    width: '32px',
                    height: '32px',
                    border: '1px solid',
                    borderRadius: '20%',
                    backgroundColor: elementColor,
                    overflow: 'hidden',
                }}
                onClick={(event) => {
                    handleSectionChange('ElementColorPalette');
                    event.stopPropagation();
                }}
            ></div>
            {(canvas.getActiveObject()?.type === 'circle' ||
                canvas.getActiveObject()?.type === 'rect') && (
                <div className='relative'>
                    <button className='ml-2'>
                        <img
                            src={Border}
                            alt='Opacity'
                            onMouseEnter={(e) => {
                                handleMouseEnter(e);
                            }}
                            onMouseLeave={(e) => {
                                handleMouseLeave(e);
                            }}
                        ></img>
                    </button>
                    {open && (
                        <div
                            className='text-black absolute left-[-75px] bottom-[-113px] min-w-max z-20 rounded bg-white px-2 py-2 flex flex-col gap-y-2'
                            onMouseEnter={(e) => handleMouseEnter(e)}
                            onMouseLeave={(e) => handleMouseLeave(e)}
                        >
                            <div className='flex justify-between items-center'>
                                <span>Colore bordo</span>
                                <div
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        border: '1px solid',
                                        borderRadius: '20%',
                                        backgroundColor: elementBorderColor,
                                        overflow: 'hidden',
                                    }}
                                    onClick={(event) => {
                                        handleSectionChange(
                                            'ElementBorderColorPalette'
                                        );
                                        event.stopPropagation();
                                    }}
                                ></div>
                            </div>
                            <div className='flex justify-between items-center'>
                                <span>Spessore bordo</span>
                                <div className='flex justify-center items-center p-1 bg-[#f9fafb] rounded-md'>
                                    {borderWidth + ' px'}
                                </div>
                            </div>
                            <div className=''>
                                <input
                                    type='range'
                                    id='volume'
                                    name='volume'
                                    min='0'
                                    max='2000'
                                    step='1'
                                    value={borderWidth * 10}
                                    onChange={handleBorderWidthChange}
                                    style={{
                                        '--value': `${borderWidth * 100}%`,
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className='relative'>
                <button className=''>
                    <img
                        src={Opacity}
                        alt='Opacity'
                        onMouseEnter={(e) => {
                            handleMouseEnter2(e);
                        }}
                        onMouseLeave={(e) => {
                            handleMouseLeave2(e);
                        }}
                    ></img>
                </button>
                {open2 && (
                    <div
                        className='text-black absolute left-[-80px] bottom-[-65px] min-w-max z-20 rounded bg-white px-2 py-2 flex flex-col'
                        onMouseEnter={(e) => handleMouseEnter2(e)}
                        onMouseLeave={(e) => handleMouseLeave2(e)}
                    >
                        <div className='flex justify-between items-center'>
                            <span>Opacità</span>
                            <div className='flex justify-center items-center p-1 bg-[#f9fafb] rounded-md'>
                                {Math.round(opacity * 100)}%
                            </div>
                        </div>
                        <div className=''>
                            <input
                                type='range'
                                id='volume'
                                name='volume'
                                min='0'
                                max='100'
                                step='1'
                                value={opacity * 100}
                                onChange={handleOpacityChange}
                                style={{ '--value': `${opacity * 100}%` }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
