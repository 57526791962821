import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBookingsForCustomer, get, getOrdersForCustomer } from "../../../Utility/Api/ThemesCustomerAPI";
import CustomersHeaderLottie from "../../../Assets/Lottie/Customers.json";
import '../../../Reusable/CSS/Page-Layout.css';
import Backbutton from "../../../Reusable/Back-Button/Back-Button";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Package from '../../../Assets/Icons/package.svg';
import InformationTopBanner from '../../../Reusable/Informational-Top-Banner/RC/1/Informational-Top-Banner';

const CustomerDetailsPage = () => {
  const { id } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('orders');
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const DeliveryStatus = {
    INCOMING: 'incoming',
    PENDING: 'pending',
    REFUSED: 'refused',
    CONFIRMED: 'confirmed',
    OTHER: 'other'
  };

  const EnumTag = {
    LAST_SIX_MONTH: 'ACQUISTATO NEGLI ULTIMI 6 MESI',
    PREFER_DELIVERY: 'PREFERISCE DELIVERY',
    SUBSCRIBED_TO_NEWSLETTER: 'ISCRITTO ALLA NEWSLETTER'
  };

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await getBookingsForCustomer(id);
        setBookingDetails(response.data);
      } catch (error) {
        console.error('Errore durante il recupero dei dettagli della prenotazione:', error);
      }
    };

    fetchBookingDetails();
  }, [id]);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await get(id);
        setCustomerDetails(response.data);
      } catch (error) {
        console.error('Errore durante il recupero dei dettagli del cliente:', error);
      }
    };

    fetchCustomerDetails();
  }, [id]);

  const OrdersComponent = ({ customerDetails }) => {
    const [ordersDetails, setOrdersDetails] = useState([]);

    useEffect(() => {
      const fetchOrdersForCustomer = async () => {
        try {
          if (customerDetails && customerDetails.email) {
            const response = await getOrdersForCustomer(customerDetails.email);
            setOrdersDetails(response.data.data);
          }
        } catch (error) {
          console.error('Errore durante il recupero degli ordini del cliente:', error);
        }
      };

      fetchOrdersForCustomer();
    }, [customerDetails]);

    const toggleTab = (tab) => {
      setActiveTab(tab);
    };

    const formatDateOfBirth = (dateString) => {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    };

    const InputField = ({ label, value }) => {
      const formattedValue = label === 'Data di nascita' ? formatDateOfBirth(value) : value;

      return (
        <div className='w-full flex flex-col gap-y-1'>
          <p className={`font-semibold flex flex-row gap-x-2 items-center`}>{label}:</p>
          <input
            type="text"
            disabled
            value={formattedValue}
            className={`w-full noShadow focus:shadow-none rounded-md border focus:outline-none disabled:cursor-auto h-10 p-3`}
          />
        </div>
      );
    };

    const showOrderDetails = (products, paymentMethod, totalPrice) => {
      setSelectedOrder({
        products: products,
        paymentMethod: paymentMethod,
        totalPrice: totalPrice,
      });
    };

    const closeOrderDetails = () => {
      setSelectedOrder(null);
    };

    function getStatusProperties(status) {
      switch (status) {
        case DeliveryStatus.INCOMING:
          return { className: 'text-[#026AA2] bg-[#F0F9FF]', text: 'Spedito' };
        case DeliveryStatus.PENDING:
          return { className: 'text-[#B54708] bg-[#FFFAEB]', text: 'Da Confermare' };
        case DeliveryStatus.REFUSED:
          return { className: 'text-[#B42318] bg-[#FEF3F2]', text: 'Rifiutato' };
        case DeliveryStatus.CONFIRMED:
          return { className: 'text-[#008F62] bg-[#EBFFF9]', text: 'Confermato' };
        default:
          return { className: 'text-[#667085] bg-gray-100', text: 'Altro' };
      }
    }

    function getTagClasses(tag) {
      let classes = ['rounded-full', 'text-center', 'textfont', 'text-sm', 'p-1'];
      if (-1 < tag.indexOf(EnumTag.LAST_SIX_MONTH)) {
        classes.push('bg-[#F4F3FF]', 'text-[#5925DC]');
      } else if (-1 < tag.indexOf(EnumTag.PREFER_DELIVERY)) {
        classes.push('bg-[#FDF2FA]', 'text-[#C11574]');
      } else if (-1 < tag.indexOf(EnumTag.SUBSCRIBED_TO_NEWSLETTER)) {
        classes.push('bg-[#EBFFF9]', 'text-[#008F62]', 'px-3');
      } else {
        classes.push('bg-[#FFF6ED]', 'text-[#8F4700]');
      }
      return classes.join(' ');
    }

    return (
      <>
        <InformationTopBanner
          pagetype="Lista"
          sectionname="Clienti"
          description="In questa sezione puoi trovare tutti i clienti che hanno già acquistato da te e ricordare le loro caratteristiche principali."
          sectionicon={CustomersHeaderLottie}
        />
        <div className='flex w-1/3 my-5 mx-10 text-[#344054] font-pop font-semibold text-base '>
          <Backbutton />
        </div>
        <div className="w-[95%] flex flex-row xsm:flex-col sm:flex-col  md:flex-col m-auto my-5 font-pop ">
          <div className="w-1/3 sm:w-full xsm:w-full md:w-full xmd:w-[50%]" >
            <div>
              {customerDetails && (
                <div className='flex w-[90%] flex-col items-start border-2 rounded-lg shadow-sm sm:mx-auto xsm:mx-auto md:mx-auto '>
                  <div className='h-11 w-full rounded-t-md bg-gradient-to-r from-[#006646] to-[#66CCAC]'> </div>
                  <div className='w-[90%] flex flex-col mx-auto mt-5'>
                    <p className=" font-pop font-semibold text-2xl">{customerDetails.firstName} {customerDetails.lastName}</p>
                    <div className='flex flex-wrap gap-2 mt-3'>
                      {customerDetails.tags.map((tag, index) => (
                        <p key={index} className={getTagClasses(tag)}>
                          {tag}
                        </p>
                      ))}
                    </div>
                  </div>

                  <div className='w-[90%] flex flex-col gap-5 m-auto my-5 md:grid md:grid-cols-2'>
                    <InputField label="Email" value={customerDetails.email} />
                    <InputField label="Numero di telefono" value={customerDetails.phoneNumber} />
                    <InputField label="Data di nascita" value={customerDetails.dateofbirth} />
                    <InputField label="Newsletter" value={customerDetails.newsletterSubscribed ? 'Sì' : 'No'} />
                    <InputField label="Indirizzo" value={customerDetails.address} />
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="w-2/3 border-l-2 sm:w-full xsm:w-full md:w-full xmd:w-[50%] sm:mt-5 xsm:mt-5 md:mt-5 sm:border-l-0 xsm:border-l-0 md:border-l-0">
            <div className=' flex flex-col items-start ml-5 xmd:ml-10 lg:ml-8 xl:ml-8'>
              <div className="flex mb-5 font-semibold ">
                <button
                  onClick={() => toggleTab('orders')}
                  className={` py-2 px-4 focus:outline-none ${activeTab === 'orders' ? 'border-b-2 border-[#008F62] text-[#008F62]' : 'border-b-2 text-[#667085] '}`}
                >
                  Storico Ordini
                </button>
                <button
                  onClick={() => toggleTab('bookings')}
                  className={` py-2 px-4 focus:outline-none ${activeTab === 'bookings' ? 'border-b-2 border-[#008F62] text-[#008F62]' : 'border-b-2 text-[#667085] '}`}
                >
                  Storico Prenotazioni
                </button>

              </div>
              {activeTab === 'bookings' && (
                <div className='w-[90%] flex flex-wrap gap-6'>
                  {bookingDetails && bookingDetails.map((booking, index) => {
                    const bookedDate = new Date(booking.booked_at);
                    const date = bookedDate.toLocaleDateString();
                    const time = bookedDate.toLocaleTimeString();

                    return (
                      <div className='flex w-80 flex-col items-start border-2 rounded-lg p-4 shadow-sm' key={index}>
                        <div>
                          <p className='font-pop font-semibold text-lg'>Prenotazione n° {index + 1}</p>
                          <p className='text-[#667085]'>{date}</p>
                        </div>
                        <div className='mt-4 flex flex-col gap-4 items-center '>
                          <div className='flex items-center mr-4'>
                            <AccessTimeIcon style={{ width: '24px', height: '24px' }} />
                            <p className='ml-2 text-[#667085]'>{time}</p>
                          </div>
                          <div className='flex items-center'>
                            <PeopleOutlineIcon style={{ width: '24px', height: '24px' }} />
                            <p className='ml-2 text-[#667085]'>{booking.persons} Persone</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {activeTab === 'orders' && (
                <div className='w-[90%] flex flex-wrap gap-6'>
                  {ordersDetails.map((order, index) => {
                    const orderDate = new Date(order.createdAt);
                    const orderDateFormatted = orderDate.toLocaleDateString();
                    const { className, text } = getStatusProperties(order.deliveryStatus);
                    return (
                      <div key={order._id} className='flex flex-col items-start border-2 rounded-lg shadow-sm '>
                        <div className='flex flex-col p-4'>
                          <div className='flex flex-row gap-3'>
                            <div>
                              <p className='font-pop font-semibold text-lg'>Ordine n° {index + 1}</p>
                              <p className='text-[#667085]'>{orderDateFormatted}</p>
                            </div>
                            <div>
                              <p className={`text-center textfont text-sm p-1 px-2 font-medium rounded-lg ${className}`}>
                                {text}
                              </p>
                            </div>
                          </div>
                          <div className='mt-4 flex flex-col gap-4'>
                            <div className='flex items-center'>
                              <AccessTimeIcon style={{ width: '24px', height: '24px' }} />
                              <p className='ml-2 text-[#667085]'>{order.deliveryTime}</p>
                            </div>
                            <div className='flex items-center'>
                              <LocationOnIcon style={{ width: '24px', height: '24px' }} />
                              <p className='ml-2 text-[#667085]'>{order.billingAddress}</p>
                            </div>
                          </div>
                        </div>
                        <div className='w-full border-t-2 flex  justify-end  '>
                          <button className='p-4' onClick={() => showOrderDetails(order.products, order.paymentMethod, order.totalPrice)}>
                            <p className='text-[#008F62] font-medium text-base'>Mostra ordine</p>
                          </button>   </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedOrder && (
                <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 '>
                  <div className='bg-white p-8 rounded-lg shadow-lg w-1/3 sm:w-[80%]  xsm:w-[80%] md:w-[80%] xmd:w-[80%] '>
                    <div className="flex items-center justify-center h-16 w-16 bg-green-100 rounded-full border-[9px] border-green-50 mb-3">
                      <div className="text-green-600">
                        <img src={Package} alt="Package Icon" className="h-8 w-8" />
                      </div>
                    </div>
                    <h2 className='text-2xl font-bold mb-2'>Dettagli dell'ordine</h2>
                    <div className='flex flex-col gap-5'>
                      <div className='flex flex-row'>
                        <p className='font-semibold mb-4 '>Pagamento :  </p>
                        <p>
                          {selectedOrder.paymentMethod === 'cash' ? 'Contanti'
                            : selectedOrder.paymentMethod === 'stripe' ? 'Carta'
                              : 'Altro'
                          }
                        </p>
                      </div>
                      {selectedOrder.products.map((product, index) => {
                        const totalPrice = product.price * product.quantity;
                        return (
                          <div key={index} className='flex flex-col items-start border-2 rounded-lg shadow-sm p-3'>
                            <p className='flex flex-col text-[#344054] gap-5 font-medium'>X {product.quantity} </p>
                            <p className='flex flex-col text-[#344054] gap-5 font-medium'>{product.title} </p>
                            <p className='flex flex-col text-[#667085] gap-5'>{totalPrice} €</p>
                          </div>
                        );
                      })}
                      <div className='flex flex-row justify-between items-end border-2 rounded-lg shadow-sm p-3 font-semibold text-[#344054] mb-6'>
                        <p className='flex items-end ml-auto mr-5 '> Totale : {selectedOrder.totalPrice}€</p>
                      </div>
                      <button onClick={() => closeOrderDetails()} className={`border-2 border-[#D0D5DD] w-full h-12 rounded-lg text-[#344054] text-xl textfont font-semibold`}>
                        Indietro
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return <OrdersComponent customerDetails={customerDetails} />;
};

export default CustomerDetailsPage;
