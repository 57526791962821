import React, { useState } from "react";
import suggestions from "../../../../Assets/Images/suggestion.png"
function Suggestions() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="relative flex flex-col gap-y-3 w-[60%] sm:w-[90%] xsm:w-[90%] md:w-[70%] xmd:w-[70%] gap-x-4">

    {isModalOpen && (
              <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white rounded-md p-4 w-1/2">
                  <p className="text-tertiarytextcolour text-xl font-semibold mb-2">Suggerimenti per il tuo Sito Web</p>
                  <p className="text-tertiarytextcolour mb-4 text-left">Nella sezione “Suggerimenti per il tuo sito web”, che sarà presto disponibile, riceverai consigli su come ottimizzare il sito web della tua attività, modificando le foto, ecc</p>
                  <button
                    className="w-40 h-12 rounded-md elevation-1 text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            )}
        
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 z-10">
                
                <div className='flex'>
                  
                    <p className='text-tertiarytextcolour text-2xl'>Questa Sezione sarà <br/> disponibile a breve</p>
               
                </div>

                <div className='flex'>

                    <button onClick={() => setIsModalOpen(true)} className='w-40 h-12 rounded-md elevation-1 text-white bg-tertiarybtn hover:bg-tertiarybtnhover active:bg-primarybtn active:shadow-innerbig'>Scopri di più</button>
                
                </div>

            </div>

      <div className="flex flex-row h-fit w-full bg-white elevation-1 rounded-md p-5 justify-between items-center xsm:flex-col md:flex-col sm:flex-col xmd:flex-col blur-[3px]">
        <div className="flex flex-col w-[55%] xsm:w-full sm:w-full md:w-full xmd:w-full">
          <p className="font-semibold mb-1 block text-themesettingsbtn text-left">
            Suggerimenti per il tuo sito web
          </p>
          <div className="p-4 bg-themesettingsbtn my-2 rounded-md">
            <p className="text-white text-left pb-2">
              Tanti tuoi visitatori non concludono l’acquisto dopo aver visitato
              il tuo sito, che ne dici di aggiungere qualche offerta?
            </p>
            <div className="flex flex-row justify-end ">
              <button className="bg-white text-themesettingsbtn px-4 py-2">
                Crea ora
              </button>
            </div>
          </div>
          <div className="p-4 bg-themesettingsbtn rounded-md">
            <p className="text-white text-left pb-2">
              Fra pochi giorni è Pasqua, che ne pensi di cambiare l’immagine di
              banner del tuo sito web?
            </p>
            <div className="flex flex-row justify-end ">
              <button className="bg-white text-themesettingsbtn px-4 py-2">
                Modifica ora
              </button>
            </div>
          </div>
        </div>
        <div className="w-[40%] min-w-[40%] h-full flex flex-row xsm:w-full md:w-[60%] sm:w-[70%] xsm:w-[70%] xmd:w-[60%] sm:w-full xsm:mt-4 sm:mt-4 xmd:mt-4 md:mt-4">
          <img
            src={suggestions}
            alt=""
            className="w-full bg-transparent overflow-hidden "
          />
        </div>
      </div>
    </div>
  );
}

export default Suggestions;
