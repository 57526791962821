import React from 'react'
import { useNavigate } from 'react-router';
import { DiscountsType } from '../Components/Commons/Discounts/DiscountUtils';
import { GetSingleDiscount } from '../apis/DiscountService';
import moment from 'moment';


export const DiscountsContextProvider = React.createContext();

export default function DiscountsContext({ children }) {
  const init = {
    "available": {
      "delivery": false,
      "reservation": false
    },
    "days": {
      "all": true,
      "sunday": false,
      "monday": false,
      "tuesday": false,
      "wednesday": false,
      "thursday": false,
      "friday": false,
      "saturday": false
    },
    "startDate": new Date().toISOString().slice(0, 10) + ' ' + new Date().toLocaleTimeString(), // Set to current date
    "endDate": null, // Set to current date
    "fixedPrice": null,
    "type": null,
    "name": null,
    "description": "",
    "repeat": null,
    "bundle": {
      "firstBundle": [],
      "secondBundle": [],
      "freeProduct": []
    },
    "threshold": null,
    "dateTypes": true,
    "freeShippingThreshold": null,
    "giftType": "product",
    "occasion": ['Nessuna condizione'],
  };

  const [Discountsbody, SetDiscountBody] = React.useState(init)

  async function getCurrentDiscount(id) {
    try {
      const res = await GetSingleDiscount(id)
      console.log(res?.data?.data, "ttttttt")
      SetDiscountBody(res?.data?.data)
    } catch (error) {
      throw error
    }



  }


  const navigate = useNavigate()
  function handleDiscountBody(key, value) {
    // @todo: Change the datepicker for one that filter years that are invalid. If we do not validate this a year with 6 char can be setted
    if (moment(value).isValid())
      SetDiscountBody(old => {
        return {
          ...old, [key]: moment(value).format("YYYY-MM-DD HH:mm:ss")
        }
      })
  }

  function handleDiscountBodyDeep(MainKey, KeyInsideIt, value) {
    SetDiscountBody(old => ({ ...old, [MainKey]: { ...old[MainKey], [KeyInsideIt]: value } }))
  }

  function emptyFreeProduct() {
    SetDiscountBody(old => ({
      ...old,
      bundle: {
        ...old.bundle,
        freeProduct: []
      }
    }));
  }


  // function handleDiscountBodyDeep(MainKey, KeyInsideIt, value) {
  //     if (MainKey === 'occasion') {
  //       SetDiscountBody(old => ({ ...old, [MainKey]: [...old[MainKey], value] }));
  //     } else {
  //       SetDiscountBody(old => ({ ...old, [MainKey]: { ...old[MainKey], [KeyInsideIt]: value } }));
  //     }
  //   }

  function handleDiscountBodyOccasion(MainKey, index, value) {
    if (MainKey === 'occasion') {
      if (Discountsbody[MainKey].includes(value)) {
        const updatedOccasion = Discountsbody[MainKey].filter(item => item !== value);
        SetDiscountBody(old => ({ ...old, [MainKey]: updatedOccasion }));
      } else {
        SetDiscountBody(old => ({ ...old, [MainKey]: [...old[MainKey], value] }));
      }
    } else {
      SetDiscountBody(old => ({ ...old, [MainKey]: { ...old[MainKey], [index]: value } }));
    }
  }




  function resetValues() {
    SetDiscountBody(init)
  }


  function EditDiscount(discount) {
    const DiscountType = discount.type
    SetDiscountBody({ ...discount, type: { discountType: DiscountType, name: DiscountsType(DiscountType) } })
    if (DiscountType === "Fixed" || DiscountType === "Free shipping") navigate("/discounts/create/DiscountInfo")
    else navigate("/discounts/create/productSelection")
  }

  const [WhichBox, SetWhichBox] = React.useState("firstBundle")

  const { bundle } = Discountsbody

  function handleAdd(product, Manual) {
    const index = bundle[Manual || WhichBox].findIndex((item) => item._id === product._id)
    const RestOftheResult = bundle[Manual || WhichBox].filter((item) => item._id !== product._id)
    if (index !== -1) return handleDiscountBodyDeep("bundle", [Manual || WhichBox], RestOftheResult)
    else return handleDiscountBodyDeep("bundle", [Manual || WhichBox], bundle[Manual || WhichBox].concat(product))
  }

  function handleCheckBox(product) {
    const index = bundle[WhichBox]?.findIndex((item) => item._id === product._id)
    return index === -1 ? false : true
  }




  const Body = {
    Discountsbody,
    handleDiscountBody,
    handleDiscountBodyDeep,
    resetValues,
    EditDiscount,
    handleAdd,
    SetWhichBox,
    WhichBox,
    handleCheckBox,
    handleDiscountBodyOccasion,
    emptyFreeProduct,
    SetDiscountBody,
    getCurrentDiscount
  };

  return <DiscountsContextProvider.Provider value={Body}>{children}</DiscountsContextProvider.Provider>
}
