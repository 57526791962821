import React from "react";
import "./Modal.css";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({
  title,
  children,
  onClose,
  headerStyles,
  titleStyles,
  className = "",
  ...rest
}) => {
  return (
    <div className={`Modal ${className}`} {...rest}>
      <header
        className="Modal__header flex items-center justify-between"
        style={headerStyles}
      >
        <h1 className=" font-regular" style={titleStyles}>
          {title}
        </h1>
        <div onClick={onClose}>
          <CloseIcon className="Modal__header__icon" />
        </div>
      </header>
      <div className="Modal__body">{children}</div>
    </div>
  );
};

export default Modal;
