import React, { useEffect, useState } from "react";
import "./FormUserInfo.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { baseUrl } from '../../../../../Utility/Api/Urls';
import { getUserData } from "../../../../../Utility/getUserData";
import validationSchema from "./validationSchema";
import { useNavigate } from "react-router";
import UseGoogleAutoComplete from '../RestaurantInfo/UseGoogleAutoComplete/UseGoogleAutoComplete';

const FormUserInfo = () => {
  const url = `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/user`;

  const notify = () => {
    toast.success("Dati salvati con successo", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const notifyError = () => {
    toast.error("Errore nell'invio dei dati", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const navigate = useNavigate();

  const [billingInformation, setBillingInformation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, SetChange] = React.useState(false)
  const [formError, setFormError] = useState(false);

  const userData = getUserData();
  const { token } = userData;

  useEffect(() => {
    if (!userData?.socialAccounts?.gmbLocationResourceIdentifier)
      navigate("/dashboard/account/general-settings");

    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setBillingInformation(res.data.billingInformation);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("error occured : ", err);
        // setErrors(err);
        notifyError();
      });
  }, [userData?.socialAccounts?.gmbLocationResourceIdentifier]);

  function handleSubmit(e) {
    e.preventDefault();

    // validate form info before submission
    const { error: validationError } = validationSchema.validate(billingInformation);
    if (validationError) return setFormError(validationError.details[0].message);
    setFormError(null);

    axios
      .patch(
        url,
        { data: { billingInformation: billingInformation, userId: userData._id } },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        notify();
      })
      .catch((err) => {
        console.error("error occured : ", err);
        // setErrors(err);
        notifyError();
      });
  }
  const handleChange = (event) => {
    !isChanged && SetChange(true)
    setBillingInformation({
      ...billingInformation,
      [event.target.name]: event.target.value,
    });
  };
  return (

    <>

      <div className="w-full rounded-md    p-3 justify-center items-center">

        {isLoading ? (
          <CircularProgress />
        ) : (
          (
            <form onSubmit={handleSubmit} id="form-user-info" className="w-full flex flex-col justify-center items-center gap-y-4">

              <div className="w-full">
                <UseGoogleAutoComplete
                  patchData={{ address: [billingInformation.billingAddress] }}
                  setPatchData={data => handleChange({ target: { name: 'billingAddress', value: data?.address[0] } })}
                />
              </div>

              <div className="flex items-center xsm:flex-col sm:flex-col md:flex-col w-full gap-4">
                <div className="flex flex-col w-full ">
                  <label className="FormUserInfo__label block  font-semibold text-left">
                    Nome
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="Nome"
                    name="name"
                    onChange={handleChange}
                    value={billingInformation.name}
                  />


                </div>
                <div className="flex flex-col w-full ">
                  <label className="FormUserInfo__label block  font-semibold text-left">
                    Cognome
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="Cognome"
                    name="lastname"
                    onChange={handleChange}
                    value={billingInformation.lastname}
                  />


                </div>
              </div>

              <div className="flex items-center xsm:flex-col sm:flex-col md:flex-col w-full gap-4">
                <div className="flex flex-col w-full">
                  <label className="FormUserInfo__label block  font-semibold text-left">
                    Ragione Sociale
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="Ragione Sociale"
                    name="socialReason"
                    onChange={handleChange}
                    value={billingInformation.socialReason}
                  />


                </div>
                <div className="flex flex-col w-full">
                  <label className="FormUserInfo__label block  font-semibold text-left">
                    Partita IVA
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="VAT"
                    name="vatNumber"
                    onChange={handleChange}
                    value={billingInformation.vatNumber}
                  />


                </div>
              </div>

              <div className="flex items-center xsm:flex-col sm:flex-col md:flex-col w-full gap-4 ">
                <div className="flex flex-col w-full">
                  <label className="FormUserInfo__label block  font-semibold text-left ">
                    Codice Fiscale
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="Codice Fiscale"
                    name="fiscalCode"
                    onChange={handleChange}
                    value={billingInformation.fiscalCode}
                  />


                </div>
                <div className="flex flex-col w-full">
                  <label className="FormUserInfo__label block  font-semibold text-left">
                    PEC oppure codice SDI
                  </label>


                  <input
                    className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
                    type="text"
                    placeholder="Codice destinatario/a P.IVA"
                    name="invoicingEndpoint"
                    onChange={handleChange}
                    value={billingInformation.invoicingEndpoint}
                  />


                </div>
              </div>

              {formError && <p className="text-red-600">{formError}</p>}
            </form>
          )
        )}

      </div>

      {isChanged &&
        <div className="mt-8 flex justify-center">
          <button type="submit" className="w-40 h-12 rounded-md bg-primarybtn elevation-1 text-white border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white" form="form-user-info">
            Salva
          </button>
        </div>
      }


    </>

  );
};

export default FormUserInfo;
