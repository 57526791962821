import React, { useEffect, useState } from 'react'
import{ getUserData }from './../../../../Utility/getUserData';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../../Reusable/Loader/Loader';
import Joi from 'joi';
import { baseUrl } from '../../../../Utility/Api/Urls';

const answerValidationSchema = Joi.string().min(2).max(4096).label("Answer");

const getLocationFromName = name => {
  try {
    return name
      .match(/locations\/.*\/question/)[0]
      .slice(0, name.indexOf("/question"))
  } catch (error) {
    return null;
  }
};

function Questionsanswers() {
  const userData = getUserData();
  let [questions, setQuestions] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [answerText, setAnswerText] = useState("");
  let [answerError, setAnswerError] = useState("");
  let [answeringIndex, setAnsweringIndex] = useState(null);

  const getQuestions = async () => {
    setIsLoading(true);
    try {
      const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/questions`;
      const config = {
        headers: { authorization: `Bearer ${userData.token}` }
      };
      const result = await axios.get(url, config);

      if (!result.data || result.data?.Error || result.data?.error || result.status !== 200)
        throw new Error(result.data?.Error || result.data?.message);

      // setQuestions(result.data.questions);
      setQuestions(result.data?.data?.questions);
      console.log("resule",result.data.data.questions)
    } catch (error) {
      console.error(error);
      toast.error("Errore nel caricare le Domande e Risposte");
    }
    setIsLoading(false);
  };

  const [btnText, SetBtnText] = useState("Guarda")
  const answerQuestionAt = index => {
    SetBtnText("Chiudi ")
    setAnswerText("");
    setAnswerError(null);
    setAnsweringIndex(answeringIndex === index ? null : index);
    if (answeringIndex === index) {
      SetBtnText("Guarda")
    }
  };

  const postAnswer = async question => {
    const { error } = answerValidationSchema.validate(answerText);
    if (error)
      return setAnswerError(error.details[0].message.replace(/"/ig, ""));

    setIsLoading(true);
    try {
      const answerIndex = question.name.indexOf("/answers");
      const name = question.name.slice(
        question.name.indexOf("questions/") + "questions/".length,
        answerIndex !== -1 ? answerIndex : question.name.length
      );
      const url = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/getGmbQuestions/${name}`;
      const config = {
        headers: { authorization: `Bearer ${userData.token}` }
      };
      const data = { text: answerText };
      const result = await axios.post(url, data, config);

      if (!result.data || result.data?.Error || result.data?.error || result.status !== 200)
        throw new Error(result.data?.message || result.data?.Error || result.data?.error);

      const index = questions.indexOf(question);
      const updatedQuestions = JSON.parse(JSON.stringify(questions));

      // if answer already exists, modify it, otherwise show answer's "semi-fake" data
      // another option is to wait 1-2 seconds for gmb to update itself, then getQuestions from it
      const existingAnswer = updatedQuestions[index].topAnswers.find(
        answer =>
          getLocationFromName(answer.name) === userData?.socialAccounts?.gmbLocationResourceIdentifier
      );
      if (existingAnswer) {
        existingAnswer.updateTime = new Date(Date.now()).toISOString();
        existingAnswer.text = answerText;
      }
      else {
        const newAnswer = {
          author: {
            displayName: `${userData.name} ${userData.lastName}`,
          },
          createTime: new Date(Date.now()).toISOString(),
          updateTime: new Date(Date.now()).toISOString(),
          text: answerText
        };
        updatedQuestions[index].topAnswers.push(newAnswer);
      }

      setQuestions(updatedQuestions);
      setAnsweringIndex(null);
      toast.success("Risposta pubblicata");
    } catch (error) {
      console.error(error);
      toast.error("Errore nel pubblicare risposta");
    }
    setIsLoading(false);
  };

  // when page loads, check if user has connected GMB account
  useEffect(() => {
    const abortController = new AbortController();

    if (!userData?.socialAccounts?.gmbLocationResourceIdentifier)
      toast.warning("Per favore connetti la tua scheda Google MyBusiness.");
    else
      getQuestions();

    return () => abortController.abort();
  }, []);

  return (
    <>
      <div className='  w-full px-8 '>
        <div>
          <nav className='DisFilter flex w-full '>
            {/* <ul className='DisFilter__list'>
              <li className="handleFilter" onClick={handleFilter}>
                <CaRButtonLink />
              </li>
            </ul> */}
          </nav>

          {isLoading && <Loader />}

          <div className="flex flex-col gap-y-10 w-[95%] my-5 mx-auto">
            {!isLoading && (!questions || questions.length === 0) && (
              <p className='font-normal text-black text-md uppercase'>Nessuna domanda disponibile</p>
            )}

            {questions && questions.length > 0 && questions.map((question, index) => (
              <div className="flex flex-col  bg-white elevation-1 border w-full rounded-md  " key={question.name}>

                <div className='flex flex-row items-center gap-5 space-x-4 justify-between hover:bg-[#F8F8F8] p-5'>
                  <div className='flex flex-row gap-x-2 items-center'>

                    <img src={question.author.profilePhotoUri} className="w-14 rounded-full h-14" alt="someone's face" />
                    <div className='flex flex-col text-left gap-y-1 justify-evenly' >
                      <p className='text-[#3366BB] font-normal text-md'>{question.author.displayName}</p>
                      <p className='text-md font-semibold text-black tracking-wide'>{question.text}</p>
                      <div className='flex gap-5'>
                        <p className='font-normal text-black text-sm'>upvotes: {question.upVoteCount || "0"}</p>
                        <p className='font-normal text-black text-sm'>Created: {new Date(question.createTime).toDateString()}</p>
                        <p className='font-normal text-black text-sm'>Updated: {new Date(question.updateTime).toDateString()}</p>
                        <p className='font-normal text-black text-sm'>total answers: {question.totalAnswerCount}</p>
                      </div>
                    </div>
                  </div>

                  {/*
                  <button className='w-fit py-1 px-6 font-normal rounded-md bg-[#3366BB] text-white ' onClick={() => answerQuestionAt(index)}>{btnText}</button>
                  */}
                  
                </div>
                {answeringIndex !== null && answeringIndex === index && (
                  <div className='flex flex-col bg-[#F8F8F8] px-5 py-7 gap-y-2  rounded-b-lg'>
                    <div className='flex flex-row items-center justify-between' >
                      <div className='flex flex-col w-5/6 gap-y-2 '>

                        <textarea className="resize-none p-4 w-full h-20 rounded-md" placeholder="Aggiungi testo qui.." value={answerText} onChange={e => setAnswerText(e.target.value)}></textarea>
                        {answerError !== null && <p className='text-red-600'>{answerError}</p>}
                      </div>
                      <button className='w-fit py-1 px-6 font-normal rounded-md bg-[#3366BB] text-white h-fit' onClick={() => postAnswer(question)}>Rispondi</button>
                    </div>

                    {question.topAnswers.map(answer => (
                      <div key={answer.name} className='flex gap-x-4 items-center mt-3'>
                        <img src={answer?.author?.profilePhotoUri} className="w-10 h-10 rounded-full" alt="someone's face" />
                        <div className='flex flex-col' >
                          <p className='text-[#3366BB] font-normal text-md'>{answer?.author?.displayName}</p>
                          <p className='text-sm font-semibold text-black tracking-wide'>{answer?.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              </div>
            ))}
          </div>

        </div>
      </div>

    </>
  )
}

export default Questionsanswers
