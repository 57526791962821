import React, { useState } from "react";
import CustomerCARD from "./CustomerCARD";
import * as ThemeCustomerAPI from "../../../Utility/Api/ThemesCustomerAPI";
import "./Customers.css";
import CreateCustomerModal from "./CreateCustomerModal";
import ModalContent from "./CustomerInfoModal/ModalContent";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";


const filterBy = ["Tutti", "Cliente", "Iscritto alla newsletter"];

function CustomersMain() {
  const [CustomersList, SetCustomers] = useState([]);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [isLoading, SetLoading] = React.useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [forReload, SetReload] = React.useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [activePageForPagination, setactivePage] = useState(0);
  const [filters, setFilters] = useState("Tutti");
  const [filtreCustomers, setFiltreCustomers] = useState([]);
  const [openTab, setOpenTab] = React.useState(1)

  async function getAllCustomers() {
    SetLoading(true);
    try {
      const all = await ThemeCustomerAPI.list();
      SetCustomers(all.data);
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }
  React.useEffect(() => {
    getAllCustomers();
  }, [forReload]);

  function handleSearch() {
    const Filterd = CustomersList.filter((item) =>
      item.firstName?.toLowerCase().startsWith(SearchTerm.toLowerCase()) ||
      item.lastName?.toLowerCase().startsWith(SearchTerm.toLowerCase()) ||
      item.email?.toLowerCase().startsWith(SearchTerm.toLowerCase())
    );
    return Filterd;
  }

  async function creatCustomer(customer) {

    try {
      const fields = {
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phoneNumber,
      };
      const body = {
        fields,
        sharedFieldNames: Object.keys(fields),
      };
      const res = await ThemeCustomerAPI.create(body);
      console.log(res);
      SetReload(Math.random());
      handleCloseCreationModal();
    } catch (error) {
      console.log(error);

      // * Manage Backend tasks from FrontEnd 
      if (error.response.data.error.includes("400")) return toast.error("Inserisci un numero di Telefono Valido")
      if (error.response.data.error.includes("500")) return toast.error("La mail inserita è già inserita nel database")

    }
  }

  async function handleDeleteCustomer(id) {
    setOpen(false)
    try {
      const res = await ThemeCustomerAPI.remove(id)
      SetReload(Math.random());
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }
  //Create Customer Modal -------------------------------------------------------------
  const [customerCreationModal, SetCustomerCreationModal] = React.useState(false);
  const handleOpenCreationModal = () => SetCustomerCreationModal(true);
  const handleCloseCreationModal = () => SetCustomerCreationModal(false);
  //Finish---------------------------------------------------------------------------------

  //Single Customer Modal-----------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [singleCustomer, SetCustomer] = React.useState();
  const handleOpen = (email) => {
    const singleCus = CustomersList?.filter((item) => item.email === email)[0];
    SetCustomer(singleCus);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  //FINISH---------------------------------------------------------------------------------

  // React.useEffect(() => {
  //   const reloadCount = sessionStorage.getItem('reloadCount');
  //   if (reloadCount < 1) {
  //     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem('reloadCount');
  //   }
  // }, [])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 12) % CustomersList.length;
    setItemOffset(newOffset);
  };

  React.useEffect(() => {
    if (
      CustomersList.length < 12 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      setCurrentItems(CustomersList);
      setPageCount(Math.ceil(CustomersList.length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      CustomersList.length >= 12 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 12;
      setCurrentItems(CustomersList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(CustomersList.length / 12));
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length < 12 &&
      !SearchTerm.length
    ) {
      setCurrentItems(filtreCustomers);
      setPageCount(Math.ceil(filtreCustomers.length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length >= 12 &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 12;
      setCurrentItems(filtreCustomers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filtreCustomers.length / 12));
      setactivePage(0);
    } else if (SearchTerm.length && handleSearch().length) {
      setCurrentItems(handleSearch());
      setPageCount(Math.ceil(handleSearch().length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else {
      const endOffset = itemOffset + 12;
      setCurrentItems(handleSearch().slice(itemOffset, endOffset));
      setPageCount(Math.ceil(handleSearch().length / 12));
      setactivePage(0);
    }
  }, [itemOffset, CustomersList, filtreCustomers, SearchTerm]);

  const handleChange = (event) => {
    const { value } = event.target;
    setFilters(typeof value === "string" ? value.split(",") : value);
    if (value === "Tutti") setFiltreCustomers(CustomersList)
    if (value === "Cliente") setFiltreCustomers(CustomersList.filter(
      (e) =>
        e.tags.join('').toLowerCase() === "Cliente".toLowerCase())
    )
    if (value === "Iscritto alla newsletter") setFiltreCustomers(CustomersList.filter(
      (e) =>
        e.tags.join('').toLowerCase() === "Iscritto alla newsletter".toLocaleLowerCase())
    )
    // setFiltreCustomers(
    //   CustomersList.filter(
    //     (e) =>
    //       e.tags.length === value.length &&
    //       e.tags.every((e, i) => e.toLowerCase() === value[i].toLowerCase())
    //   )
    // );
  };
  return (
    <div className="flex flex-col  gap-y-3 items-center w-[95%] mx-auto h-fit mt-8 ">

      <div className="flex flex-col  gap-y-3 items-center w-full mx-auto h-fit  ">

        <div className="flex flex-row justify-between xmd:flex-col md:flex-col sm:flex-col xsm:flex-col gap-y-3 w-full">
          <div className="flex flex-row items-center relative  rounded-md">

            <FormControl>
              <Select
                sx={{ "& > fieldset": { border: "none" } }}
                className="w-60 h-12 rounded-md text-primarytextcolour bg-white border-2 border-primarybtn hover:text-white hover:bg-primarybtnhover hover:border-primarybtnhover hover:elevation-1 active:border-white active:shadow-innerbig sm:-w-full xsm:w-full focus:outline-none"
                value={filters}
                onChange={handleChange}
                defaultValue={filters[0]}
              >
                {filterBy?.map((item, index) => (

                  <MenuItem value={item} key={index}>{item}</MenuItem>
                ))}

              </Select>
            </FormControl>

            <input
              value={SearchTerm}
              onChange={(e) => SetSearchTerm(e.target.value)}
              placeholder="&#128269; Digita per cercare un cliente"
              className="h-12 px-2 w-[400px] xsm:w-full   sm:w-full    md:w-full    xmd:w-full   border-2 border-[#F6F6F6] bg-[#F6F6F6] rounded-md focus:outline-none"
            />

          </div>

          <div className="flex flex-row gap-x-2 xsm:flex-col gap-y-2 xmd:justify-between md:justify-between sm:justify-between xsm:justify-between  ">

            <button
              onClick={() => handleOpenCreationModal()}
              className="w-60 h-12 elevation-1 rounded-md text-white bg-primarybtn border-2 border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:border-white active:shadow-innerbig sm:-w-full xsm:w-full"
            >
              Inserisci Cliente
            </button>
          </div>






        </div>
        {
          <div className=" w-full grid grid-cols-4 sm:grid-cols-1 xsm:grid-cols-1 md:grid-cols-1 xmd:grid-cols-2 xmd:h-fit xmd:pb-3 lg:grid-cols-3 gap-y-3 pt-3  sm:h-fit xsm:h-fit md:h-fit sm:py-3 xsm:py-3 md:py-3 content-start h-[590px]">
            {!isLoading &&
              currentItems.map((customer, index) => (
                <div key={index}>
                  <CustomerCARD handleDeleteCustomer={handleDeleteCustomer} customer={customer} handleOpen={handleOpen} />
                </div>
              ))}
          </div>
        }

        {CustomersList.length > 9 && activePageForPagination !== null && (
          <div className=" w-full flex items-center my-5">
            <ReactPaginate
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={window.innerWidth < 700 ? 2 : 5}
              pageCount={pageCount}
              // forcePage={activePageForPagination}
              nextLabel="❯"
              nextClassName="text-primarytextcolour text-2xl"
              pageClassName="rounded-md text-center border font-semibold w-10 h-10 flex items-center justify-center hover:text-white text-primarybtn border-primarybtn border-2 hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig active:bg-primarybtn active:border-white active:text-white"
              pageLinkClassName="w-full h-full items-center justify-center flex"
              previousLabel="❮"
              previousClassName="text-primarytextcolour text-2xl"
              activeClassName="text-white flex w-10 h-10 rounded-md text-center border font-semibold items-center justify-center bg-primarybtn border-primarybtn border-2 hover:bg-primarybtn hover:border-primarybtn active:shadow-innerbig active:bg-primarybtn active:border-white"
              containerClassName="flex flex-row gap-x-4 xsm:gap-x-1 items-center  mx-auto  "
              renderOnZeroPageCount={false}
              marginPagesDisplayed={1}
            />
          </div>
        )}
      </div>

      <CreateCustomerModal
        customerCreationModal={customerCreationModal}
        handleCloseCreationModal={handleCloseCreationModal}
        creatCustomer={creatCustomer}
      />
      {singleCustomer && open && (
        <ModalContent
          open={open}
          handleClose={handleClose}
          Data={singleCustomer}
        />
      )}
    </div>
  );
}

export default CustomersMain;
