import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import ThemeCard from "./ThemeCard";
function OtherThemeModal({
  open,
  handleClose,
  otherThemes,
  TheUserData,
  themeChoicedDirectly,
  isLoading2,
  handleRedirectToTheWebSite,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="Modall--Backdrop">
          <div className={`Modall `}>
            <header className="Modall__header">
              <div className="Modall__close" onClick={handleClose}>
                <CloseIcon className="Modall__close-icon" />
              </div>
            </header>
            <div className="w-full mx-auto GridThemeCards justify-items-center gap-4 mt-8">
              {!isLoading2 &&
                otherThemes?.map((theme, index) => {
                  return (
                    
                      <ThemeCard
                        key={index}
                        id={theme.id}
                        title={theme.title}
                        image={theme.image}
                        description={theme.description}
                        longDescription={theme.longDescription}
                        isSelect={TheUserData?.themeId === theme.id}
                        theme={theme}
                        handleThemeChosing={themeChoicedDirectly}
                        handleRedirectToTheWebSite={handleRedirectToTheWebSite}
                      />
                    
                  );
                })}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default OtherThemeModal;
