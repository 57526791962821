import React from "react";
import SocialAccounts from "./SocialAccounts/Index-SocialAccounts";
import DashNavigation from "../DashNavigation/resultsnavigation";
import RestaurantInfo from "./RestaurantInfo/RestaurantInfo";
import Paymentsinfo from "./Payments/Index-Payments";
import FormUserInfo from "./FormUserInfo/FormUserInfo";
import UseService from "./UseService/UseService";

function IndexSettings() {

    return (
        <div className='flex flex-col w-[95%] mx-auto' >

            <div className='flex mt-2 mb-6 xsm:mx-auto sm:mx-auto md:mx-auto'>
    
            <DashNavigation />
    
            </div>

            <div className="flex flex-col gap-y-6"  >
                <RestaurantInfo />
                <div className="flex flex-row xsm:flex-col sm:flex-col w-full gap-x-7">
                    <div className="w-1/2  sm:w-full  xsm:w-full flex flex-col gap-y-3 px-3 py-6 elevation-1 rounded-md">
                        <p className="font-semibold text-[#00B27A] text-left text-lg">Account Social</p>
                        <SocialAccounts />
                    </div>
                    <div className="w-1/2  sm:w-full  xsm:w-full flex flex-col gap-y-3 px-3  py-6 elevation-1 rounded-md">
                        <p className="font-semibold text-[#00B27A] text-left text-lg">Metodo di Pagamento</p>
                        <Paymentsinfo />
                    </div>
                </div>
                <div className="flex flex-row xsm:flex-col sm:flex-col w-full gap-x-7">
                    <div className="w-1/2  sm:w-full  xsm:w-full flex flex-col gap-y-1 px-3 py-6 elevation-1 rounded-md">
                        <p className="font-semibold text-[#00B27A] text-left text-lg">Dati di Fatturazione</p>
                        <FormUserInfo />
                    </div>
                    <div className="w-1/2  sm:w-full  xsm:w-full flex flex-col gap-y-3 px-3 py-6 elevation-1 rounded-md">
                        <p className="font-semibold text-[#00B27A] text-left text-lg">Servizi Aggiuntivi</p>
                        <UseService />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexSettings