import React, { useState } from "react";

const ModifyPopUp = ({
  item,
  inputData,
  onConfirm,
  onDecline,
  ModifySocailMediapost,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [editedInputData, setEditedInputData] = useState(inputData);

  const handleConfirm = () => {
    setIsConfirmationOpen(true);
  };

  const handleDecline = () => {
    onDecline();
  };

  const handleAccept = () => {
    ModifySocailMediapost(item, editedInputData);
    onDecline();
  };

  const handleChange = (event) => {
    setEditedInputData(event.target.value);
  };

  return (
    <div className="fixed w-full top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 z-50">
      {!isConfirmationOpen && (
        <div className="bg-white p-10 w-1/3 md:w-[90%] sm:w-[90%] xsm:w-[90%]  rounded-md shadow-lg ">
          {" "}
          <div>
            <div className="text-2xl font-bold text-black mb-2">
              Modifica post
            </div>
            <div className="text-xl font-medium text-poppins font-normal">
              Descrizione del post
            </div>
          </div>
          <textarea
            value={editedInputData}
            onChange={handleChange}
            className="w-full h-72 px-6 py-4 mt-6 border border-gray-300 rounded-md resize-none"
          />
          <span className="font-poppins mt-2 mb-2 text-base font-semibold leading-5 text-green-600 underline">
            Vuoi un suggerimento?
          </span>
          <div className="flex justify-between mt-8">
            <button
              onClick={handleDecline}
              className="w-full mr-2 py-4 bg-white border border-gray-300 text-black rounded-md hover:bg-gray-100 focus:outline-none font-semibold text-lg">
              Indietro
            </button>

            <button
              onClick={handleConfirm}
              className="w-full py-4 bg-[#00b27a] text-white rounded-md hover:bg-[#00b2479d] focus:outline-none font-semibold text-lg">
              Salva Modifiche
            </button>
          </div>
        </div>
      )}

      {isConfirmationOpen && (
        <div className="bg-white p-10 w-1/4 md:w-[90%] sm:w-[90%] xsm:w-[90%]  rounded-md shadow-lg ">
          {" "}
          {/* Set width to one-third of the screen */}
          <div className="mb-4">
            <svg
              width="56"
              height="57"
              viewBox="0 0 56 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                x="4"
                y="4.5"
                width="48"
                height="48"
                rx="24"
                fill="#D6FFF2"
              />
              <rect
                x="4"
                y="4.5"
                width="48"
                height="48"
                rx="24"
                stroke="#ECFDF3"
                stroke-width="8"
              />
              <path
                d="M38 27.58V28.5C37.9988 30.6564 37.3005 32.7547 36.0093 34.4818C34.7182 36.209 32.9033 37.4725 30.8354 38.0839C28.7674 38.6953 26.5573 38.6219 24.5345 37.8746C22.5117 37.1273 20.7847 35.7461 19.611 33.9371C18.4373 32.128 17.8798 29.9881 18.0217 27.8363C18.1636 25.6846 18.9972 23.6363 20.3983 21.9971C21.7994 20.3578 23.6928 19.2154 25.7962 18.7401C27.8996 18.2649 30.1003 18.4823 32.07 19.36M38 20.5L28 30.51L25 27.51"
                stroke="#008F62"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="text-2xl font-bold text-black mb-2">
            Post modificato con successo
          </div>
          <div className=" h-42 text-xl">
            Il tuo post è stato modificato.Potrai trovarlo su facebook e
            instagram.Nel momento in cui sarà pubblicato le tue modifiche
            potranno essere apportate solo su facebook.{" "}
          </div>
          <div className="flex justify-between mt-8 ">
            <button
              onClick={handleAccept}
              className="w-full py-4 bg-[#00b27a] text-white rounded-md hover:bg-[#00b2479d] focus:outline-none font-semibold text-lg">
              Salva Modifiche
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyPopUp;
