import RegistrationIcon from "../../../../../Assets/Icons/RegistrationIcon.png";
import BirthdayIcon from "../../../../../Assets/Icons/BirthdayIcon.png";
import SpecificProductIcon from "../../../../../Assets/Icons/SpecificProductIcon.png";
import AbandonedCartIcon from "../../../../../Assets/Icons/AbandonedCartIcon.png";
import RecurrentDateIcon from "../../../../../Assets/Icons/RecurrentDateIcon.png";
import SpecificDateIcon from "../../../../../Assets/Icons/SpecificDateIcon.png";
import LastTimeShopIcon from "../../../../../Assets/Icons/LastTimeShopIcon.png";

export const AutomatedMailType = Object.freeze({
  Registration: "REGISTRATION",
  Birthday: "BIRTHDAY",
  DateOfSubscription: "DATE OF SUBSCRIPTION",
  BuyASpecificProduct: "BUY A SPECIFIC PRODUCT",
  LeaveTheCart: "LEAVE THE CART",
  RecurringDate: "RECURRING DATE",
  SpecificDate: "SPECIFIC DATE",
  LastPurchaseDate: "LAST PURCHASE DATE",
});

export const cardsData = [
  {
    id: 1,
    name: "Registrazione",
    description: "Invia una mail per coinvolgere i tuoi nuovi iscritti al sito e farli affezionare al tuo ristorante.",
    icon: RegistrationIcon,
    type: AutomatedMailType.Registration,
  },
  {
    id: 2,
    name: "Compleanno",
    description: "Invia una mail per festeggiare il loro compleanno e offrirgli come regalo una promo speciale pensata solo per loro.",
    icon: BirthdayIcon,
    type: AutomatedMailType.Birthday,
    specialParameters: ["daysBefore"],
  },
  {
    id: 4,
    name: "Anniversario data d'iscrizione",
    description: "Invia una mail automatica ai tuoi clienti per festeggiare il loro anniversario di iscrizione al tuo sito. Coccolali con uno sconto esclusivo per l'occasione.",
    icon: RegistrationIcon,
    type: AutomatedMailType.DateOfSubscription,
    specialParameters: ["days", "date"],
  },
  {
    id: 5,
    name: "Acquista un Prodotto Specifico",
    description: "Invia una mail automatica per spingere i tuoi clienti ad effettuare l'acquisto di un piatto specifico del tuo menù.",
    icon: SpecificProductIcon,
    type: AutomatedMailType.BuyASpecificProduct,
    specialParameters: ["productId", "daysAfterPurchase"],
  },
  {
    id: 6,
    name: "Abbandona il carrello",
    description: "Invia una mail automatica ai tuoi clienti per ricordargli che hanno dimenticato qualcosa nel tuo carrello e spingili a completare il loro acquisto.",
    icon: AbandonedCartIcon,
    type: AutomatedMailType.LeaveTheCart,
    specialParameters: ["daysAfter"],
  },
  {
    id: 7,
    name: "Data Ricorrente",
    description: "Invia una mail automatica ai tuoi clienti per offrire sconti, promozioni o invitarli a gustare i piatti del tuo menù in occasione di festività e ricorrenze speciali.",
    icon: RecurrentDateIcon,
    type: AutomatedMailType.RecurringDate,
    specialParameters: ["date"],
  },
  {
    id: 8,
    name: "Data Specifica",
    description: "Invia una mail automatica a tutti i tuoi clienti per offrire sconti o promozioni in una data da te scelta.",
    icon: SpecificDateIcon,
    type: AutomatedMailType.SpecificDate,
    specialParameters: ["specficDate"],
  },
  {
    id: 9,
    name: "Tempo dall’ultimo acquisto",
    description: "Invia una mail automatica ai tuoi clienti per ricordargli che è da un po' di tempo che non ordinano qualcosa di delizioso dal tuo menù.",
    icon: LastTimeShopIcon,
    type: AutomatedMailType.LastPurchaseDate,
    specialParameters: ["daysSince"],
  },
];

export const getNameByType = type =>
  cardsData.find(cd => cd.type === type)?.name || "";

export const FrequencyOption = Object.freeze({
  Once: "once",
  Daily: "daily",
  Weekly: "weekly",
  Monthly: "monthly",
  Yearly: "yearly",
});

export const frequencyOptions = [
  {
    label: "Una volta",
    value: FrequencyOption.Once,
  },
  {
    label: "Giornaliere",
    value: FrequencyOption.Daily,
  },
  {
    label: "Settimanale",
    value: FrequencyOption.Weekly,
  },
  {
    label: "Mensile",
    value: FrequencyOption.Monthly,
  },
  {
    label: "Annuale",
    value: FrequencyOption.Yearly,
  },
];
