import React from 'react';
import './WebSiteThemeCustomLink.css';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const WebSiteThemeCustomLink = ({ to, children }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (

    <Link
      to={to}
      className={
        match
          ? 'SocCustomLink SocCustomLink--active'
          : 'SocCustomLink'
      }
    >

      {children}

    </Link>

  );
};

export default WebSiteThemeCustomLink;
