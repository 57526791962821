import React, { useState } from "react";
import CustomerCARD from "./CustomerCARD";
import * as ThemeCustomerAPI from "../../../Utility/Api/ThemesCustomerAPI";
import "./Customers.css";
import CreateCustomerModal from "./CreateCustomerModal";
import ModalContent from "./CustomerInfoModal/ModalContent";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

import CustomersCardPage from "./CustomersCardPage";
import CustomersTabPage from "./CustomersTabPage";
import AnalyticsPlugin from "../../../Reusable/Analytics-Plugin/AnalyticsPlugin";


import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';


const filterBy = ["Tutti", "Cliente", "Iscritto alla newsletter"];

function CustomersMain() {
  const [CustomersList, SetCustomers] = useState([]);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [isLoading, SetLoading] = React.useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [forReload, SetReload] = React.useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [activePageForPagination, setactivePage] = useState(0);
  const [filters, setFilters] = useState("Tutti");
  const [filtreCustomers, setFiltreCustomers] = useState([]);
  const [openTab, setOpenTab] = React.useState(1)

  async function getAllCustomers() {
    SetLoading(true);
    try {
      const all = await ThemeCustomerAPI.list();
      SetCustomers(all.data);
    } catch (error) {
      console.log(error);
    }
    SetLoading(false);
  }
  React.useEffect(() => {
    getAllCustomers();
  }, [forReload]);

  function handleSearch() {
    const Filterd = CustomersList.filter((item) =>
      item.firstName?.toLowerCase().startsWith(SearchTerm.toLowerCase()) ||
      item.lastName?.toLowerCase().startsWith(SearchTerm.toLowerCase()) ||
      item.email?.toLowerCase().startsWith(SearchTerm.toLowerCase())
    );
    return Filterd;
  }

  async function creatCustomer(customer) {

    try {
      const fields = {
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phoneNumber,
      };
      const body = {
        fields,
        sharedFieldNames: Object.keys(fields),
      };
      const res = await ThemeCustomerAPI.create(body);
      console.log(res);
      SetReload(Math.random());
      handleCloseCreationModal();
    } catch (error) {
      console.log(error);

      // * Manage Backend tasks from FrontEnd 
      if (error.response.data.error.includes("400")) return toast.error("Inserisci un numero di Telefono Valido")
      if (error.response.data.error.includes("500")) return toast.error("La mail inserita è già inserita nel database")

    }
  }

  async function handleDeleteCustomer(id) {
    setOpen(false)
    try {
      const res = await ThemeCustomerAPI.remove(id)
      SetReload(Math.random());
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }
  //Create Customer Modal -------------------------------------------------------------
  const [customerCreationModal, SetCustomerCreationModal] = React.useState(false);
  const handleOpenCreationModal = () => SetCustomerCreationModal(true);
  const handleCloseCreationModal = () => SetCustomerCreationModal(false);
  //Finish---------------------------------------------------------------------------------

  //Single Customer Modal-----------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [singleCustomer, SetCustomer] = React.useState();
  const handleOpen = (email) => {
    const singleCus = CustomersList?.filter((item) => item.email === email)[0];
    SetCustomer(singleCus);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  //FINISH---------------------------------------------------------------------------------

  // React.useEffect(() => {
  //   const reloadCount = sessionStorage.getItem('reloadCount');
  //   if (reloadCount < 1) {
  //     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem('reloadCount');
  //   }
  // }, [])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 12) % CustomersList.length;
    setItemOffset(newOffset);
  };

  React.useEffect(() => {
    if (
      CustomersList.length < 12 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      setCurrentItems(CustomersList);
      setPageCount(Math.ceil(CustomersList.length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      CustomersList.length >= 12 &&
      !filtreCustomers.length &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 12;
      setCurrentItems(CustomersList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(CustomersList.length / 12));
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length < 12 &&
      !SearchTerm.length
    ) {
      setCurrentItems(filtreCustomers);
      setPageCount(Math.ceil(filtreCustomers.length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else if (
      filtreCustomers.length &&
      filtreCustomers.length >= 12 &&
      !SearchTerm.length
    ) {
      const endOffset = itemOffset + 12;
      setCurrentItems(filtreCustomers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filtreCustomers.length / 12));
      setactivePage(0);
    } else if (SearchTerm.length && handleSearch().length) {
      setCurrentItems(handleSearch());
      setPageCount(Math.ceil(handleSearch().length / 12));
      setItemOffset(0);
      setactivePage(0);
    } else {
      const endOffset = itemOffset + 12;
      setCurrentItems(handleSearch().slice(itemOffset, endOffset));
      setPageCount(Math.ceil(handleSearch().length / 12));
      setactivePage(0);
    }
  }, [itemOffset, CustomersList, filtreCustomers, SearchTerm]);



  const handleChange = (event) => {
    const { value } = event.target;
    setFilters(typeof value === "string" ? value.split(",") : value);
    if (value === "Tutti") setFiltreCustomers(CustomersList)
    if (value === "Cliente") setFiltreCustomers(CustomersList.filter(
      (e) =>
        e.tags.join('').toLowerCase() === "Cliente".toLowerCase())
    )
    if (value === "Iscritto alla newsletter") setFiltreCustomers(CustomersList.filter(
      (e) =>
        e.tags.join('').toLowerCase() === "Iscritto alla newsletter".toLocaleLowerCase())
    )
    // setFiltreCustomers(
    //   CustomersList.filter(
    //     (e) =>
    //       e.tags.length === value.length &&
    //       e.tags.every((e, i) => e.toLowerCase() === value[i].toLowerCase())
    //   )
    // );
  };
  return (
    <div className="flex flex-col  gap-y-3 items-center w-[95%] mx-auto h-fit mt-8 ">

      <AnalyticsPlugin

        section1={true}
        section2={true}
        section3={true}
        section4={true}
        section5={false}
        section6={false}
        SectionName="Analytics generali"
        AnalyticsName1="Numero di iscritti"
        AnalyticsData1="Analytics Data 1"
        Analyticspercentage1="10"
        AnalyticsName2="Numero clienti (anche anonimi)"
        AnalyticsData2="Analytics Data 2"
        Analyticspercentage2="20"
        AnalyticsName3="Numero in percentuale di clienti fidelizzati"
        AnalyticsData3="12 min"
        Analyticspercentage3="30"
        AnalyticsName4="Clienti dormienti"
        AnalyticsData4="12 min"
        Analyticspercentage4="30"
        AnalyticsName5="Numero di visite alla pagina prenotazioni"
        AnalyticsData5="12 min"
        Analyticspercentage5="30"
        AnalyticsName6="Tempo medio per guardare il Menù"
        AnalyticsData6="12 min"
        Analyticspercentage6="30"
        text="rispetto al mese precedente"
        imgOk={false}

      />

<div className="flex flex-col w-full bg-white rounded-md  xsm:w-full sm:w-full md:w-full">
        <div className="flex flex-row  justify-end w-[95%] gap-3  m-auto">
            {/*  <button
            onClick={() => setOpenTab(1)}
            className={`${openTab === 1
              ? "text-primarytextcolour underline underline-offset-2 bg-[#D9F4EB]"
              : " text-[#989898]"
              }   p-2 rounded-md `}
          >
            <FormatListBulletedIcon /></button>
        <button
            onClick={() => setOpenTab(2)}
            className={`${openTab === 2
              ? "text-primarytextcolour underline underline-offset-2  bg-[#D9F4EB]"
              : "border-[#989898] text-[#989898] "
              }   p-2 rounded-md `}
          >
            <GridViewIcon />
          </button>*/}

        </div>
        <div className="w-full">
          {openTab === 1 && <CustomersTabPage />}
          {openTab === 2 && <CustomersCardPage />}


        </div>
      </div>



    </div>
  );
}

export default CustomersMain;
