import axios from 'axios';
import { baseUrl } from '../../Utility/Api/Urls';
import { getAuthConfig } from '../../Utility/Api/auth';
import { toast } from 'react-toastify';
import { getUserId } from '../../Utility/getUserData';

const getMethod = (action) => (action === 'modifying' ? 'put' : 'post');

export const createTemplate = async ({
    templateName = '',
    availableFor = [],
    restaurantType = [],
    section = [],
    objective = [],
    buyerPersona = [],
    packages = [],
    restaurantId = [],
    action,
    objects,
    canvasSize,
    templateId,
    thumbnail,
    ownerIsAdmin,
    backgroundColor,
}) => {
    const formData = new FormData();
    formData.append("template_name",templateName)
    formData.append("available_for",availableFor)
    formData.append("restaurant_type",restaurantType)
    formData.append("section",section)
    formData.append("objective",objective)
    formData.append("buyer_persona",buyerPersona)
    formData.append("packages",packages)
    formData.append("restaurant_id",restaurantId)
    objects.forEach((obj) => {
        formData.append(`objects[]`, JSON.stringify(obj));
      });
    formData.append("canvasSize",canvasSize)
    formData.append("owner_is_admin",ownerIsAdmin)
    formData.append("thumbnail",thumbnail)
    formData.append("backgroundColor",backgroundColor)
    const url = (id) =>
        `https://${'dashboard.'}${baseUrl()}/api/illustration/canvas/${id}`;
    const apiCall = async (url, requestMethod) => {
       return await axios[requestMethod](url, formData, getAuthConfig());
    };

    const requestMethod = getMethod(action);
    try {
        if (requestMethod === 'post') {
           return await apiCall(url(getUserId()), requestMethod);
        } else {
           return await apiCall(url(templateId), requestMethod);
        }
    } catch (e) {
        toast.error('Errore: non è stato possibile salvare la tua board.');
        console.error(e);
    }
};

export const getSavedTemplate = async (objective, section, currentPage) => {
    try {
        const getUrl = `https://${
            process.env.REACT_APP_DASHBOARD_PREFIX
        }${baseUrl()}/api/illustration/canvas/byOwner/${getUserId()}?objective=${objective}&section=${section}&bring_admin_illustrations=false&buyer_persona=all&limit=${8}&page=${currentPage}`;

        const { data } = await axios.get(getUrl, getAuthConfig());
        return data;
    } catch (e) {
        toast.error('Errore: non è stato possbile salvare la tua board.');
        console.error(e);
    }
};
export const getAdminTemplates = async (objective, section, currentPage) => {
    try {
        const getUrl = `https://${
            process.env.REACT_APP_DASHBOARD_PREFIX
        }${baseUrl()}/api/illustration/canvas/admin/paginated?objective=${objective}&section=${section}&buyer_persona=all&limit=${8}&page=${currentPage}`;

        const { data } = await axios.get(getUrl, getAuthConfig());
        return data;
    } catch (e) {
        toast.error('Errore: non è stato possbile salvare la tua board.');
        console.error(e);
    }
};

export const getSavedTemplateById = async (id) => {
    try {
        const getUrl = `https://${
            process.env.REACT_APP_DASHBOARD_PREFIX
        }${baseUrl()}/api/illustration/canvas/${id}`;

        const { data } = await axios.get(getUrl, getAuthConfig());
        return data;
    } catch (e) {
        toast.error('Errore: non è stato possibile salvare la tua board.');
        console.error(e);
    }
};

export const deleteTemplateById = async (id) => {
    const url = `https://${
        process.env.REACT_APP_DASHBOARD_PREFIX
    }${baseUrl()}/api/illustration/canvas/${id}`;
    try {
        const { data } = await axios.delete(url, getAuthConfig());
        return data;
    } catch (error) {
        toast.error('Errore: non è stato possibile eliminare il template.');
        console.error(error);
    }
};

export const uploadImage = async (img) => {
    const formData = new FormData();
    formData.append("file",img)
    const url = `https://${
        process.env.REACT_APP_DASHBOARD_PREFIX
    }${baseUrl()}/api/uploader/myUploads`;
    try {
        const { data } = await axios.post(url, formData, getAuthConfig());
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteUpload = async (id) => {

    const url = `https://${
        process.env.REACT_APP_DASHBOARD_PREFIX
    }${baseUrl()}/api/uploader/deleteUpload/${id}`;
    try {
        const { data } = await axios.delete(url, getAuthConfig());
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const getMyUploads = async () => {
    const url = `https://${
        process.env.REACT_APP_DASHBOARD_PREFIX
    }${baseUrl()}/api/uploader/myUploads`;
    try {
        const { data } = await axios.get(url, getAuthConfig());
        return data;
    } catch (error) {
        console.error(error);
    }
};
