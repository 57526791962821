import UploadImageToCDN from "../Utility/UploadImage";

/**
 * Host the base64 encoded image on themes server and return a url
 * @param {String} base64Image Base64 image string
 * @returns Image url
 */
async function compressImage(file, maxSize) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const width = img.width;
        const height = img.height;

        let newWidth = width;
        let newHeight = height;

        const maxSizeInBytes = maxSize * 1024 * 1024; 
        let compressionQuality = 1.0;
        do {
          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const compressedDataUrl = canvas.toDataURL('image/jpeg', compressionQuality);
          const compressedFileSize = (compressedDataUrl.length * 3) / 4;

          if (compressedFileSize > maxSizeInBytes) {
            compressionQuality *= 0.9; 
          } else {
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], "compressed-image.jpg", { type: "image/jpeg" });
                resolve(compressedFile);
              },
              'image/jpeg',
              compressionQuality
            );
            break;
          }
        } while (compressionQuality > 0.1);

        if (compressionQuality <= 0.1) {
          reject(new Error('Image size could not be reduced to the specified limit.'));
        }
      };
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export async function hostBase64Image(base64Image) {
  try {
    const res = await fetch(base64Image);
    const blob = await res.blob();
    const file = new File([blob], "temp-image.jpg", { type: "image/jpg" });

    const maxSize = 0.6; 
    const compressedFile = await compressImage(file, maxSize);

    return await UploadImageToCDN(compressedFile);
  } catch (error) {
    console.error(error);
  }
}

export function isNewUrl(url) {
  return url && url.includes("imagedelivery.net");
}
