import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { fabric } from 'fabric';
import 'fabric-history';
import { ColorPalette } from './ColorPicker/colorpalette';
import { ColorPicker } from './ColorPicker/colorpicker';
import { ElementSection } from './ElementSection/elementsection';
import { ElementSectionNavBar } from './ElementSection/ElementSectionNavBaR/elementsectionnavbar';
import { ImageSection } from './ImageSection/imagesection';
import { LayersSection } from './LayersSection/layerssection';
import { NavBar } from './NavBar/navbar';
import { RotateButton } from './ImageSection/imagefunctionbuttons';
import { SideBar } from './SideBar/sidebar';
import { TextSection } from './TextSection/textsection';
import { TextSectionNavBar } from './TextSection/TextSectionNavBar/textsectionnavbar';
import { ThreePointsMenu } from './threepointsmenu';
import { UploadSection } from './UploadSection/uploadsection';
import ThreePoints from './ImageSection/icon/threepoints.svg';
import Trash from './ImageSection/icon/trash.svg';
import {
    deleteUpload,
    getMyUploads,
    getSavedTemplateById,
} from '../../../apis/editor/editor';
import {
    addImageLayer,
    addTextLayer,
    addElementLayer,
    resizer,
} from './helpers/layerCreator.js';
import { uploadCanvaJSON } from './helpers/layerCreator';

export const elementSectionTypes = ['rect', 'circle', 'group', 'path'];

const GraphicEditor = ({
    templateName,
    templateId,
    restaurantType,
    section,
    objective,
    buyerPersona,
    packages,
    restaurantId,
    action,
    setIsEditorOpen,
}) => {
    const [hover, setHover] = useState(false);
    const handleMouseOver = () => setHover(true);
    const handleMouseOut = () => setHover(false);
    const [canvaIsSelected, setCanvaIsSelected] = useState(false);
    const [selectedSection, setSelectedSection] = useState(0);
    const [selectedCanvaColor, setSelectedCanvaColor] = useState();
    const [fontSize, setFontSize] = useState(30);
    const [canvas, setCanvas] = useState(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const grayBackgroundRef = useRef(null);
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
    const [openedThreePointsMenu, setOpenedThreePointsMenu] = useState(false);
    const [positionThreePointsMenu, setPositionThreePointsMenu] =
        useState(null);
    const [layers, setLayers] = useState([]);
    const [activeObject, setActiveObject] = useState(
        canvas && canvas.getActiveObject()
            ? canvas.getActiveObject()
            : null
    );
    const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
    const [copiedObject, setCopiedObject] = useState(null);
    const [selectedFont, setSelectedFont] = useState({});
    const [imageOpacities, setImageOpacities] = useState({});
    const [elementOpacities, setElementOpacities] = useState({});
    const [textOpacities, setTextOpacities] = useState({});
    const [elementBorderWidth, setElementBorderWidth] = useState({});
    const [fontColor, setFontColor] = useState({});
    const [elementColor, setElementColor] = useState({});
    const [elementBorderColor, setElementBorderColor] = useState({});
    const [fontDimension, setFontDimension] = useState({});
    const [isBold, setIsBold] = useState({});
    const [isItalic, setIsItalic] = useState({});
    const [isUnderline, setIsUnderline] = useState({});
    const [isLineThrough, setIsLineThrough] = useState({});
    const [isUpperCase, setIsUpperCase] = useState({});
    const [isAligned, setIsAligned] = useState({});
    const [activeObjectId, setActiveObjectId] = useState(
        canvas && canvas.getActiveObject()
            ? canvas.getActiveObject().get('id')
            : null
    );
    const [imageIsSelected, setImageIsSelected] = useState(false);
    const [textIsSelected, setTextIsSelected] = useState(false);
    const [elementIsSelected, setElementIsSelected] = useState(false);
    const [selectedFontColor, setSelectedFontColor] = useState('');
    const [selectedFontFamily, setSelectedFontFamily] = useState('');
    const [selectedElementColor, setSelectedElementColor] = useState('');
    const [selectedElementBorderColor, setSelectedElementBorderColor] =
        useState('');
    const [italicIsSelected, setItalicIsSelected] = useState(false);
    const [boldIsSelected, setBoldIsSelected] = useState(false);
    const [underlineIsSelected, setUnderlineIsSelected] = useState(false);
    const [linethroughIsSelected, setLineThroughIsSelected] = useState(false);
    const [uppercaseIsSelected, setUppercaseIsSelected] = useState(false);
    const [textAlignment, setTextAlignment] = useState('left');
    const handleSectionChange = (index) => {
        setSelectedSection(index);
    };
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [templateData, setTemplateData] = useState({
        templateName: null,
        templateId: null,
        restaurantType: null,
        section: section==="marketing"?"marketing":"social",
        objective: objective,
        buyerPersona: "all",
        packages: null,
        restaurantId: null,
    });
    const [myUploads, setMyUploads] = useState([])
    fabric.Canvas.prototype.clearRedoHistory = function () {
        this.historyRedo = [];
        this.fire('history:redo-cleared');
    };

    // push to historyRedo array manually
    fabric.Canvas.prototype.pushToHistoryRedo = function (action) {
        if (!this.historyRedo) {
            this.historyRedo = [];
        }
        this.historyRedo.pop();
        this.historyRedo.push(action);
        this.fire('history:pushed-redo', { action });
    };

    // undo method with a different fire call
    fabric.Canvas.prototype.undoEvent = function (t) {
        this.historyProcessing = true;
        const i = this.historyUndo.pop();
        if (i) {
            this.historyRedo.push(this._historyNext());
            this.historyNextState = i;
            this._loadHistory(i, 'history:undo-event', t);
        } else {
            this.historyProcessing = false;
        }
    };

    // add id to extra props
    fabric.Canvas.prototype.changeExtraProps = function () {
        this.extraProps = ['selectable', 'editable', 'id'];
    };

    //set initail canvas size
    useLayoutEffect(() => {
        if (grayBackgroundRef.current) {
            const size = grayBackgroundRef.current.offsetWidth * 0.8;
            setCanvasSize({ width: size, height: size });
        }
    }, []);
    //update canvas and elements size on screen resize
    useEffect(() => {
        let initialWidth = grayBackgroundRef.current.offsetWidth * 0.8;
        const updateCanvasSize = () => {
            const size = grayBackgroundRef.current.offsetWidth * 0.8;
            setCanvasSize({ width: size, height: size });
            canvas.getObjects().forEach((object) => {
                canvas.remove(object);
                resizer(object, size, initialWidth, canvas);
                canvas.add(object);
            });
            initialWidth = size;
        };
        window.addEventListener('resize', updateCanvasSize);
        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, [canvas]);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = new fabric.Canvas(canvasRef.current, {
                width: canvasSize.width,
                height: canvasSize.height,
                backgroundColor: 'white',
            });
            canvas.preserveObjectStacking = true;
            setCanvas(canvas);
            canvas.changeExtraProps();
            canvas.on('mouse:down', function (options) {
                if (options.target) {
                    if (options.target.type === 'i-text') {
                        setSelectedSection(1);
                        setTextIsSelected(true);
                        setImageIsSelected(false);
                        setCanvaIsSelected(false);
                        setActiveObjectId(canvas.getActiveObject().get('id'));
                    } else if (options.target.type === 'image') {
                        setSelectedSection(0);
                        setImageIsSelected(true);
                        setTextIsSelected(false);
                        setCanvaIsSelected(false);
                        setActiveObjectId(canvas.getActiveObject().get('id'));
                    } else if (
                        elementSectionTypes.includes(options.target.type)
                    ) {
                        setSelectedSection(2);
                        setElementIsSelected(true);
                        setTextIsSelected(false);
                        setImageIsSelected(false);
                        setCanvaIsSelected(false);
                        setActiveObjectId(canvas.getActiveObject().get('id'));
                    }
                } else {
                    if (
                        !imageIsSelected &&
                        !textIsSelected &&
                        !elementIsSelected &&
                        !selectedSection
                    ) {
                        setCanvaIsSelected(true);
                    }
                }
            });
            canvas.on('before:selection:cleared', function (options) {
                if (options.target) {
                    if (options.target.type === 'image') {
                        setImageIsSelected(false);
                        setOpenedThreePointsMenu(false);
                    } else if (options.target.type === 'i-text') {
                        setTextIsSelected(false);
                        setSelectedSection(1);
                        setOpenedThreePointsMenu(false);
                    } else if (options.target.type === 'rect') {
                        setElementIsSelected(false);
                        setSelectedSection(2);
                        setOpenedThreePointsMenu(false);
                    } else if (options.target.type === 'circle') {
                        setElementIsSelected(false);
                        setSelectedSection(2);
                        setOpenedThreePointsMenu(false);
                    }
                }
            });
        }
    }, []);
    function deleteObject(eventData, transform) {
        let target = transform ? transform.target : eventData;
        let canvas = target.canvas;
        if (!canvas) return;
        canvas.remove(target);
        canvas.renderAll();
        setLayers((prevLayers) => {
            const newLayers = prevLayers.filter(
                (layer) => layer.object !== target
            );
            return newLayers;
        });
    }
    function openThreePointsMenu(eventData, transform) {
        let canvas = transform.target.canvas;
        let pointer = canvas.getPointer(eventData.e);
        let posX = (canvas.height>(pointer.x + 160)?pointer.x - 10:(pointer.x-160)) ;
        let posY = (canvas.width>(pointer.y + 250)?pointer.y + 10:(pointer.y-250)) ;
        setPositionThreePointsMenu({ x: posX, y: posY });
        setOpenedThreePointsMenu((prevState) => !prevState);
        canvas.requestRenderAll();
    }
    const handleKeyPress = (e) => {
        if (
            (e.ctrlKey && e.key === 'z' && e.altKey) ||
            (e.ctrlKey && e.key === 'y')
        ) {
            // Ctrl+Z+Alt or Ctrl+Y  for redo
            e.preventDefault();
            canvas.redo();
        } else if (
            e.ctrlKey &&
            e.key === 'z' &&
            canvas.historyUndo.length > 0
        ) {
            // Ctrl+Z for undo
            e.preventDefault();
            let action = canvas.historyNextState;
            cleanCanvas().then(() => {
                canvas.undoEvent();
                canvas.pushToHistoryRedo(action);
            });
        } else if (e.key === 'Backspace' || e.key === 'Delete') {
            // Backspace or Delete for delete
            const activeObject = canvas.getActiveObject();
            if (activeObject && !activeObject.isEditing) {
                e.preventDefault();
                deleteObject(activeObject, null);
            }
        }
    };

    function cleanCanvas() {
        return new Promise((resolve) => {
            canvas.offHistory();
            canvas.getObjects().forEach(function (object) {
                deleteObject(object);
            });
            resolve();
        });
    }

    function regenerateLayers() {
        canvas.getObjects().forEach(function (object) {
            if (object.type === 'i-text') {
                const uniqueId = generateUniqueId();
                const newLayer = addTextLayer(uniqueId, object);
                setLayers((layers) => [newLayer, ...layers]);
            } else if (object.type === 'image') {
                const uniqueId = generateUniqueId();
                const newLayer = addImageLayer(uniqueId, object.src, object);
                setLayers((layers) => [newLayer, ...layers]);
            } else {
                const newLayer = addElementLayer(object, object.id, '');
                setLayers((layers) => [newLayer, ...layers]);
            }
        });
    }

    useEffect(() => {
        if (canvas) {
            document.addEventListener('keydown', handleKeyPress);
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }
    }, [canvas]);
    let trashImage = new Image();
    trashImage.src = Trash;
    trashImage.onload = function () {};
    let threePointsImage = new Image();
    threePointsImage.src = ThreePoints;
    threePointsImage.onload = function () {};
    function renderIcon(ctx, left, top, fabricObject) {
        let size = 27;
        let padding = size * 0.25;
        let imgSize = 24;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.beginPath();
        ctx.arc(
            -size / 2 + padding,
            -size / 2 + padding,
            padding,
            Math.PI,
            1.5 * Math.PI
        );
        ctx.lineTo(size / 2 - padding, -size / 2);
        ctx.arc(
            size / 2 - padding,
            -size / 2 + padding,
            padding,
            1.5 * Math.PI,
            2 * Math.PI
        );
        ctx.lineTo(size / 2, size / 2 - padding);
        ctx.arc(
            size / 2 - padding,
            size / 2 - padding,
            padding,
            0,
            0.5 * Math.PI
        );
        ctx.lineTo(-size / 2 + padding, size / 2);
        ctx.arc(
            -size / 2 + padding,
            size / 2 - padding,
            padding,
            0.5 * Math.PI,
            Math.PI
        );
        ctx.closePath();
        ctx.fill();
        ctx.drawImage(trashImage, -imgSize / 2, -imgSize / 2, imgSize, imgSize);
        ctx.restore();
    }
    function renderThreePointsIcon(ctx, left, top, fabricObject) {
        let size = 27;
        let padding = size * 0.25;
        let imgSize = 24;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.beginPath();
        ctx.arc(
            -size / 2 + padding,
            -size / 2 + padding,
            padding,
            Math.PI,
            1.5 * Math.PI
        );
        ctx.lineTo(size / 2 - padding, -size / 2);
        ctx.arc(
            size / 2 - padding,
            -size / 2 + padding,
            padding,
            1.5 * Math.PI,
            2 * Math.PI
        );
        ctx.lineTo(size / 2, size / 2 - padding);
        ctx.arc(
            size / 2 - padding,
            size / 2 - padding,
            padding,
            0,
            0.5 * Math.PI
        );
        ctx.lineTo(-size / 2 + padding, size / 2);
        ctx.arc(
            -size / 2 + padding,
            size / 2 - padding,
            padding,
            0.5 * Math.PI,
            Math.PI
        );
        ctx.closePath();
        ctx.fill();
        ctx.drawImage(
            threePointsImage,
            -imgSize / 2,
            -imgSize / 2,
            imgSize,
            imgSize
        );
        ctx.restore();
    }
    useEffect(() => {
        if (canvas) {
            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = '#00b27a';
            fabric.Object.prototype.cornerStyle = 'circle';
            canvas.setWidth(canvasSize.width);
            canvas.setHeight(canvasSize.height);
            canvas.renderAll();
        }
    }, [canvasSize, canvas]);
    const changeTextOpacity = (newOpacity) => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
            const textId = activeObject.get('id');
            activeObject.set({ opacity: newOpacity });
            canvas.renderAll();
            setTextOpacities({
                ...textOpacities,
                [textId]: newOpacity,
            });
        }
        updateLayer(activeObject);
    };

    useEffect(() => {
        let position=activeObject?.left>(canvas?.width-(activeObject?.width*activeObject?.scaleX+activeObject?.left))?-1:1
        fabric.Object.prototype.controls.deleteControl = new fabric.Control(
            {
                x: 0.36*position,
                y: -0.43,
                offsetX: -11*position, 
                cursorStyle: 'pointer',
                mouseUpHandler: deleteObject,
                render: renderIcon,
                cornerSize: 24,
            }
        );
        fabric.Object.prototype.controls.threePointsControl =
            new fabric.Control({
                x: 0.4*position,
                y: -0.43,
                offsetX: 11*position,
                cursorStyle: 'pointer',
                mouseUpHandler: openThreePointsMenu,
                render: renderThreePointsIcon,
                cornerSize: 24,
            });
    }, [activeObject?.left])

    useEffect(() => {
        if (canvas) {
            canvas.on('selection:created', () => {
                setActiveObject(canvas.getActiveObject());
            });
            canvas.on('selection:updated', () => {
                setActiveObject(canvas.getActiveObject());
            });
            canvas.on('selection:cleared', () => {
                setActiveObject(null);
            });
            canvas.on('history:undo-event', () => {
                regenerateLayers();
            });
            canvas.on('history:redo', () => {
                setLayers([]);
                regenerateLayers();
            });
            canvas.on('history:undo', () => {
                setLayers([]);
                regenerateLayers();
            });
        }
    }, [canvas]);
    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    useEffect(() => {
        if (canvas && selectedCanvaColor) {
            canvas.setBackgroundColor(selectedCanvaColor);
            canvas.renderAll();
        }
    }, [canvas, selectedCanvaColor]);
    const updateLayer = (modifiedObject) => {
        const index = layers.findIndex(
            (layer) => layer.object === modifiedObject
        );
        if (index !== -1) {
            const updatedLayer = {
                ...layers[index],
                text: modifiedObject.text,
                fontFamily: modifiedObject.fontFamily,
                fontSize: modifiedObject.fontSize,
                fill: modifiedObject.fill,
                opacity: modifiedObject.opacity,
                stroke: modifiedObject.stroke,
                strokeWidth: modifiedObject.strokeWidth,
            };
            setLayers([
                ...layers.slice(0, index),
                updatedLayer,
                ...layers.slice(index + 1),
            ]);
        }
    };
    useEffect(() => {
        if (canvas) {
            canvas.on('object:modified', (e) => {
                updateLayer(e.target);
                canvas._historySaveAction();
                canvas.clearRedoHistory();
            });
            canvas.on('object:scaling', (e) => {
                updateLayer(e.target);
            });
            canvas.on('object:moving', (e) => {
                updateLayer(e.target);
            });
        }
        return () => {
            if (canvas) {
                canvas.off('object:modified');
                canvas.off('object:scaling');
                canvas.off('object:moving');
            }
        };
    }, [canvas, layers]);
    useEffect(() => {
        function handleDocumentClick(event) {
            const clickedElement = event.target;
            const isInsideTextChromePicker = clickedElement.closest(
                '#chrome-picker-text-container'
            );
            const isInsideCanvaChromePicker = clickedElement.closest(
                '#chrome-picker-canva-container'
            );
            const isInsideLayerSection = clickedElement.closest(
                '#layer-list-element'
            );
            const isInsideFunctionSection =
                clickedElement.closest('#function-section');
            const isInsideSidebar = clickedElement.closest('#sidebar');
            const isInsideFontColorPalette = clickedElement.closest(
                '#font-color-palette'
            );
            const isInsideTextSection = clickedElement.closest('#text-section');
            const isInsideCanvaLayer = clickedElement.closest('#canva-layer');
            const isInsideElementSection =
                clickedElement.closest('#element-section');
            const isInsideNavBar = clickedElement.closest('#navbar');
            const isInsideCanvaColorSection = clickedElement.closest(
                '#canva-color-section'
            );
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                !isInsideTextChromePicker &&
                !isInsideCanvaChromePicker &&
                !isInsideLayerSection &&
                !isInsideFunctionSection &&
                !isInsideSidebar &&
                !isInsideFontColorPalette &&
                !isInsideTextSection &&
                !isInsideCanvaLayer &&
                !isInsideElementSection &&
                !isInsideNavBar &&
                !isInsideCanvaColorSection
            ) {
                if (canvas) {
                    canvas.discardActiveObject();
                    setCanvaIsSelected(false);
                    // setSelectedSection(0);
                    setOpenedThreePointsMenu(false);
                    canvas.renderAll();
                }
            }
        }
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [canvas, containerRef]);
    useEffect(() => {
        if (canvas && layers) {
            const reversedLayers = [...layers].reverse();
            reversedLayers.forEach((layer, index) => {
                canvas.moveTo(layer.object, index);
            });
            canvas.renderAll();
        }
    }, [layers]);
    useEffect(() => {
        if (activeObjectId) {
            setSelectedFontColor(fontColor[activeObjectId] || 'black');
        }
    }, [fontColor, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setSelectedFontFamily(selectedFont[activeObjectId] || '');
        }
    }, [selectedFont, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setFontSize(fontDimension[activeObjectId] || 30);
        }
    }, [fontDimension, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setSelectedElementColor(elementColor[activeObjectId] || 'black');
        }
    }, [elementColor, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setSelectedElementBorderColor(
                elementBorderColor[activeObjectId] || ''
            );
        }
    }, [elementBorderColor, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setItalicIsSelected(isItalic[activeObjectId] || false);
        }
    }, [isItalic, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setBoldIsSelected(isBold[activeObjectId] || false);
        }
    }, [isBold, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setUnderlineIsSelected(isUnderline[activeObjectId] || false);
        }
    }, [isUnderline, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setLineThroughIsSelected(isLineThrough[activeObjectId] || false);
        }
    }, [isLineThrough, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setUppercaseIsSelected(isUpperCase[activeObjectId] || false);
        }
    }, [isUpperCase, activeObjectId]);
    useEffect(() => {
        if (activeObjectId) {
            setTextAlignment(isAligned[activeObjectId] || 'left');
        }
    }, [isAligned, activeObjectId]);
    const commonProps = {
        canvas: canvas,
        fabric: fabric,
        generateUniqueId: generateUniqueId,
        setLayers: setLayers,
        layers: layers,
        setActiveObjectId: setActiveObjectId,
        setSelectedCanvaColor,
    };
    //load template from id in superadmin
    useEffect(() => {
        if (templateId && canvas) {
            getSavedTemplateById(templateId).then((data) => {
                uploadCanvaJSON(data, commonProps);
                let oldImages = [];
                if (data.objects.length > 0)
                    data.objects.map((element) => {
                        if (element.type === 'image') {
                            oldImages.push(element.src);
                        }
                    });
                setUploadedFiles(...uploadedFiles, oldImages);
                setTemplateData({
                    templateName: data.template_name,
                    buyerPersona: data.buyer_persona,
                    objective: data.objective,
                    packages: data.packages,
                    restaurantId: data.restaurant_id,
                    restaurantType: data.restaurant_type,
                    section: data.section,
                    templateId,
                });
            });
        }
    }, [templateId, canvas]);

    useEffect(() => {
        getMyUploads().then((res)=>{
            setMyUploads(res)
        })
    }, [])

    return (
        <div className='w-full bg-[#f2f4f7] poppins'>
            <NavBar
                action={action}
                canvas={canvas}
                setIsEditorOpen={setIsEditorOpen}
                template={templateData}
            />
            <div className='flex justify-between'>
                <SideBar
                    handleSectionChange={handleSectionChange}
                    setCanvaUnactive={setCanvaIsSelected}
                    selectedSection={selectedSection}
                    setImageIsSelected={setImageIsSelected}
                    setTextIsSelected={setTextIsSelected}
                    setElementIsSelected={setElementIsSelected}
                />
                <div
                    style={{
                        height:
                            grayBackgroundRef?.current?.clientHeight || 'auto',
                    }}
                    className='w-[25%] mt-6 p-2 overflow-x-auto '
                >
                    {0 === selectedSection && (
                        <ImageSection
                            commonProps={commonProps}
                            canvasSize={canvasSize}
                            setImageIsSelected={setImageIsSelected}
                            setImageOpacities={setImageOpacities}
                            imageOpacities={imageOpacities}
                            setUploadedFiles={setUploadedFiles}
                            uploadedFiles={uploadedFiles}
                        />
                    )}
                    {1 === selectedSection && (
                        <TextSection
                            commonProps={commonProps}
                            setTextIsSelected={setTextIsSelected}
                            setTextOpacities={setTextOpacities}
                            textOpacities={textOpacities}
                            setSelectedFontFamily={setSelectedFontFamily}
                            setSelectedFontColor={setSelectedFontColor}
                            setCanvaUnactive={setCanvaIsSelected}
                            setFontSize={setFontSize}
                            setSelectedFont={setSelectedFont}
                            selectedFont={selectedFont}
                        />
                    )}
                    {2 === selectedSection && (
                        <ElementSection
                            commonProps={commonProps}
                            setElementIsSelected={setElementIsSelected}
                            setElementOpacities={setElementOpacities}
                            setElementColor={setElementColor}
                            setSelectedElementBorderColor={
                                setSelectedElementBorderColor
                            }
                            elementOpacities={elementOpacities}
                            setElementBorderWidth={setElementBorderWidth}
                            elementBorderWidth={elementBorderWidth}
                            setElementBorderColor={setElementBorderColor}
                        />
                    )}
                    {3 === selectedSection && (
                        <LayersSection
                            commonProps={commonProps}
                            selectedCanvaColor={selectedCanvaColor}
                            setCanvaIsSelected={setCanvaIsSelected}
                        />
                    )}
                    {4 === selectedSection && (
                        <UploadSection
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            commonProps={commonProps}
                            setImageIsSelected={setImageIsSelected}
                            setImageOpacities={setImageOpacities}
                            imageOpacities={imageOpacities}
                            myUploads={myUploads}
                            setMyUploads={setMyUploads}
                            deleteUpload={deleteUpload}
                        />
                    )}
                    {'CanvaColorPalette' === selectedSection && (
                        <ColorPalette
                            section={selectedSection}
                            selectedCanvaColor={selectedCanvaColor}
                            setSelectedCanvaColor={setSelectedCanvaColor}
                        />
                    )}
                    {'FontColorPalette' === selectedSection && (
                        <ColorPalette
                            section={selectedSection}
                            canvas={canvas}
                            setFontColor={setFontColor}
                            fontColor={fontColor}
                            updateLayer={updateLayer}
                            selectedFontColor={selectedFontColor}
                            setSelectedFontColor={setSelectedFontColor}
                        />
                    )}
                    {'ElementColorPalette' === selectedSection && (
                        <ColorPalette
                            section={selectedSection}
                            canvas={canvas}
                            updateLayer={updateLayer}
                            setElementColor={setElementColor}
                            elementColor={elementColor}
                            selectedElementColor={selectedElementColor}
                            setSelectedElementColor={setSelectedElementColor}
                        />
                    )}
                    {'ElementBorderColorPalette' === selectedSection && (
                        <ColorPalette
                            section={selectedSection}
                            canvas={canvas}
                            updateLayer={updateLayer}
                            elementBorderColor={elementBorderColor}
                            setElementBorderColor={setElementBorderColor}
                            selectedElementBorderColor={
                                selectedElementBorderColor
                            }
                            setSelectedElementBorderColor={
                                setSelectedElementBorderColor
                            }
                        />
                    )}
                </div>
                <div className='w-[65%] border overflow-hidden'>
                    <div
                        className='py-6 bg-[#f2f4f7] flex items-center pl-8'
                        id='function-section'
                    >
                        {canvaIsSelected && (
                            <ColorPicker
                                handleSectionChange={handleSectionChange}
                            />
                        )}
                        {imageIsSelected && (
                            <RotateButton
                                canvas={canvas}
                                setImageOpacities={setImageOpacities}
                                imageOpacities={imageOpacities}
                                activeObjectId={activeObjectId}
                                updateLayer={updateLayer}
                            />
                        )}
                        {textIsSelected && 1 === selectedSection && (
                            <TextSectionNavBar
                                canvas={canvas}
                                selectedFont={selectedFont}
                                setSelectedFont={setSelectedFont}
                                updateLayer={updateLayer}
                                selectedFontFamily={selectedFontFamily}
                                setSelectedFontFamily={setSelectedFontFamily}
                                setFontSize={setFontSize}
                                fontSize={fontSize}
                                fontDimension={fontDimension}
                                setFontDimension={setFontDimension}
                                handleSectionChange={handleSectionChange}
                                fontColor={selectedFontColor}
                                setIsBold={setIsBold}
                                isBold={isBold}
                                setIsItalic={setIsItalic}
                                isItalic={isItalic}
                                setIsUnderline={setIsUnderline}
                                isUnderline={isUnderline}
                                setIsLineThrough={setIsLineThrough}
                                isLineThrough={isLineThrough}
                                setIsUpperCase={setIsUpperCase}
                                isUpperCase={isUpperCase}
                                setTextAlignment={setTextAlignment}
                                setIsAligned={setIsAligned}
                                isAligned={isAligned}
                                italicIsSelected={italicIsSelected}
                                boldIsSelected={boldIsSelected}
                                underlineIsSelected={underlineIsSelected}
                                linethroughIsSelected={linethroughIsSelected}
                                uppercaseIsSelected={uppercaseIsSelected}
                                textAlignment={textAlignment}
                                changeTextOpacity={changeTextOpacity}
                                textOpacities={textOpacities}
                                activeObjectId={activeObjectId}
                                setActiveObjectId={setActiveObjectId}
                            />
                        )}
                        {'FontColorPalette' === selectedSection && (
                            <TextSectionNavBar
                                canvas={canvas}
                                selectedFont={selectedFont}
                                setSelectedFont={setSelectedFont}
                                updateLayer={updateLayer}
                                selectedFontFamily={selectedFontFamily}
                                setSelectedFontFamily={setSelectedFontFamily}
                                setFontSize={setFontSize}
                                fontSize={fontSize}
                                fontDimension={fontDimension}
                                setFontDimension={setFontDimension}
                                handleSectionChange={handleSectionChange}
                                fontColor={selectedFontColor}
                                setIsBold={setIsBold}
                                isBold={isBold}
                                setIsItalic={setIsItalic}
                                isItalic={isItalic}
                                setIsUnderline={setIsUnderline}
                                isUnderline={isUnderline}
                                setIsLineThrough={setIsLineThrough}
                                isLineThrough={isLineThrough}
                                setIsUpperCase={setIsUpperCase}
                                isUpperCase={isUpperCase}
                                setTextAlignment={setTextAlignment}
                                setIsAligned={setIsAligned}
                                isAligned={isAligned}
                                italicIsSelected={italicIsSelected}
                                boldIsSelected={boldIsSelected}
                                underlineIsSelected={underlineIsSelected}
                                linethroughIsSelected={linethroughIsSelected}
                                uppercaseIsSelected={uppercaseIsSelected}
                                textAlignment={textAlignment}
                                changeTextOpacity={changeTextOpacity}
                                textOpacities={textOpacities}
                                activeObjectId={activeObjectId}
                                setActiveObjectId={setActiveObjectId}
                            />
                        )}
                        {elementIsSelected && 2 === selectedSection && (
                            <ElementSectionNavBar
                                canvas={canvas}
                                setElementOpacities={setElementOpacities}
                                updateLayer={updateLayer}
                                handleSectionChange={handleSectionChange}
                                elementColor={selectedElementColor}
                                elementBorderColor={selectedElementBorderColor}
                                elementOpacities={elementOpacities}
                                elementBorderWidth={elementBorderWidth}
                                setElementBorderWidth={setElementBorderWidth}
                                setElementBorderColor={setElementBorderColor}
                                activeObjectId={activeObjectId}
                            />
                        )}
                        {'ElementColorPalette' === selectedSection && (
                            <ElementSectionNavBar
                                canvas={canvas}
                                setElementOpacities={setElementOpacities}
                                updateLayer={updateLayer}
                                handleSectionChange={handleSectionChange}
                                elementColor={selectedElementColor}
                                elementBorderColor={selectedElementBorderColor}
                                elementOpacities={elementOpacities}
                                elementBorderWidth={elementBorderWidth}
                                setElementBorderWidth={setElementBorderWidth}
                                activeObjectId={activeObjectId}
                                setElementBorderColor={setElementBorderColor}
                            />
                        )}
                        {'ElementBorderColorPalette' === selectedSection && (
                            <ElementSectionNavBar
                                canvas={canvas}
                                setElementOpacities={setElementOpacities}
                                updateLayer={updateLayer}
                                handleSectionChange={handleSectionChange}
                                elementColor={selectedElementColor}
                                elementBorderColor={selectedElementBorderColor}
                                elementOpacities={elementOpacities}
                                elementBorderWidth={elementBorderWidth}
                                setElementBorderWidth={setElementBorderWidth}
                                activeObjectId={activeObjectId}
                                setElementBorderColor={setElementBorderColor}
                            />
                        )}
                    </div>
                    <div
                        ref={grayBackgroundRef}
                        className='bg-[#d0d5dd] py-4 grid place-content-center'
                    >
                        <div
                            className='relative '
                            ref={containerRef}
                        >
                            <div
                                className={`relative rounded-lg  bg-white ${
                                    hover ? 'ring-[#00b27a] ring-2' : ''
                                } ${
                                    canvaIsSelected
                                        ? 'ring-[#00b27a] ring-2'
                                        : ''
                                }`}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                style={{
                                    width: canvasSize.width,
                                    height: canvasSize.height,
                                    backgroundColor: selectedCanvaColor,
                                }}
                            >
                                <canvas
                                    id='canvas'
                                    ref={canvasRef}
                                    className='rounded-lg'
                                ></canvas>
                                {openedThreePointsMenu === true &&
                                    openedThreePointsMenu !== false && (
                                        <ThreePointsMenu
                                            canvas={canvas}
                                            copiedObject={copiedObject}
                                            setCopiedObject={setCopiedObject}
                                            positionThreePointsMenu={
                                                positionThreePointsMenu
                                            }
                                            setHoveredButtonIndex={
                                                setHoveredButtonIndex
                                            }
                                            setLayers={setLayers}
                                            layers={layers}
                                            setOpenedThreePointsMenu={
                                                setOpenedThreePointsMenu
                                            }
                                            openThreePointsMenu={
                                                openThreePointsMenu
                                            }
                                            deleteObject={deleteObject}
                                            hoveredButtonIndex={
                                                hoveredButtonIndex
                                            }
                                            setSelectedSection={
                                                setSelectedSection
                                            }
                                            regenerateLayers={regenerateLayers}
                                            generateUniqueId={generateUniqueId}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphicEditor;
