import {
  CUSTOMER_SEARCH,
  RESEARCH_DEVICES,
  SEARCH_DETAILS,
  VIEWS_ON_GOOGLE,
} from "../constants";

export const localSeoReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_SEARCH:
      return {
        ...state,
        customerSearch: action.payload,
      };
    case RESEARCH_DEVICES:
      return {
        ...state,
        researchDevices: action.payload,
      };
    case VIEWS_ON_GOOGLE:
      return {
        ...state,
        viewsOnGoogle: action.payload,
      };
    case SEARCH_DETAILS:
      return {
        ...state,
        searchDetails: action.payload,
      };
    default:
      return state;
  }
};
