import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "./../../../../../Reusable/Loader/Loader";
import { getUserData } from "../../../../../Utility/getUserData";
import setUserData from "./../../../../../Utility/setUserData";
import { toast } from "react-toastify";
import DeleteAlertButton from "../../../../../Reusable/DeleteAlert/DeleteAlertButton";
import Stripe from "../../../../../Assets/Icons/StripeIcon.png";
import * as StripFunctions from "./StripeFunctions";
import tilby from "../../../../../Assets/Icons/tilby.svg";

function Paymentsinfo() {
  const { state: redirectStatus } = useLocation();
  const userData = getUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const [TilbyConnected, setTilbyConnected] = useState(false);
  const hasCreatedAccount = () =>
    userData?.paymentAccounts?.stripeAccount?.accountCreated !== undefined &&
    userData?.paymentAccounts?.stripeAccount?.accountCreated === true;
  const isOnboarded = () =>
    userData?.paymentAccounts?.stripeAccount?.onBoarded !== undefined &&
    userData?.paymentAccounts?.stripeAccount?.onBoarded === true;
  const hasStripeId = () =>
    !!userData?.paymentAccounts?.stripeAccount?.stripeID;

  const handleConnectStripeClick = async () => {
    setIsLoading(true);

    if (hasCreatedAccount() && isOnboarded()) {
      const accountData = await StripFunctions.getStripeAccountData();
      setStripeData(accountData);
      setIsLoading(false);
      return;
    }

    let accountLinkData;
    if (!hasCreatedAccount())
      accountLinkData = await StripFunctions.connectStripe();

    if (hasCreatedAccount() && !isOnboarded())
      accountLinkData = await StripFunctions.generateStripeAccountLink();

    if (!accountLinkData) return setIsLoading(false);

    if (!hasStripeId()) {
      const isSaveSuccessful = await StripFunctions.saveStripeAccount(
        accountLinkData.accountId
      );
      if (!isSaveSuccessful) return setIsLoading(false);
    }

    if (!userData.paymentAccounts) userData.paymentAccounts = {};
    if (!userData.paymentAccounts.stripeAccount)
      userData.paymentAccounts.stripeAccount = {};
    userData.paymentAccounts.stripeAccount.accountCreated = true;
    userData.paymentAccounts.stripeAccount.stripeID = accountLinkData.accountId;
    setUserData(userData);
    setIsLoading(false);

    if (!accountLinkData?.Link?.url) {
      console.error("No redirect link found.");
      return;
    }

    window.location = accountLinkData?.Link?.url;
  };

  // handling redirection back from stripe
  const handleStripeRedirectFailure = () => {
    toast.error(
      "Errore nel connettersi a Stripe. Per favore riprova oppure contatta la nostra assistenza nella sezione Supporto"
    );
    setStripeData(null);
  };
  const handleStripeRedirectSuccess = async () => {
    const hasOnboarded = await StripFunctions.onBoardUser();
    if (!hasOnboarded) return handleStripeRedirectFailure();

    userData.paymentAccounts.stripeAccount.accountCreated = true;
    userData.paymentAccounts.stripeAccount.onBoarded = true;
    setUserData(userData);
    setStripeData(userData.paymentAccounts.stripeAccount);
  };

  // check for connection status via stripe redirection
  useEffect(() => {
    const stripeConnectionSuccess =
      redirectStatus && redirectStatus.stripeConnection;
    const stripeConnectionFailure =
      redirectStatus && !redirectStatus.stripeConnection;
    if (stripeConnectionSuccess) {
      handleStripeRedirectSuccess();
    } else if (stripeConnectionFailure) handleStripeRedirectFailure();
  }, [redirectStatus]);

  // check for already existing stripe account info in localStorage
  useEffect(() => {
    if (hasCreatedAccount() && isOnboarded() && hasStripeId()) {
      setStripeData(userData.paymentAccounts.stripeAccount);
    }
  }, []);

  async function handleDissconnectStripe() {
    const success = await StripFunctions.disconnectStripeAccount();
    console.log(success);
    if (success) toast.success("Disconnessione avvenuta con successo.");
    window.location.reload();
    // change the state of the stripe account
    userData.paymentAccounts.stripeAccount.accountCreated = false;
    userData.paymentAccounts.stripeAccount.onBoarded = false;
    userData.paymentAccounts.stripeAccount.stripeID = null;
    setUserData(userData);
    setStripeData(null);
  }

  const ConnectButton =
    "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn hover:bg-primaryhover active:shadow-innerbig";

  const RedButton =
    "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-deletebtn hover:bg-deletebtnhover active:shadow-innerbig";

  return (
    <>
      <div className="bg-gradient-to-r from-[#333259] to-[#2B65BB] w-full rounded-md flex flex-row justify-between px-4  py-2  items-center ">
        <div className="flex flex-row gap-x-4 items-center">
          <img src={Stripe} alt="" />
          <p className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px] ">
            {stripeData || hasStripeId()
              ? "Disconnetti Carte"
              : "Connetti Carte"}
          </p>
        </div>

        {!stripeData && !hasStripeId() && (
          <button
            className={ConnectButton}
            onClick={() => handleConnectStripeClick()}
            disabled={isLoading}
          >
            Connetti
          </button>
        )}
        {(stripeData || hasStripeId()) && (
          <DeleteAlertButton
            message="Sei sicuro?"
            subTitle="Questo ti farà disconnettere"
            onConfirm={() => handleDissconnectStripe()}
            renderButton={(props) => (
              <button {...props} className={RedButton}>
                Disconnetti
              </button>
            )}
          />
        )}
        {isLoading && <Loader />}
      </div>
      {TilbyConnected && (
        <div className=" py-2 h-[60px] items-center bg-gradient-to-r from-[#333259] to-[#2B65BB] w-full rounded-md flex flex-row justify-between px-4 ">
          <div className="flex flex-row gap-x-4  items-center">
            <img src={tilby} alt="" />
            <h1 className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px] ">
              Pagamento con Tilby{" "}
            </h1>
          </div>
        </div>
      )}
    </>
  );
}

export default Paymentsinfo;
