import axios from "axios";
import { getAuthConfig } from "../Utility/Api/auth";




const API = axios.create({ baseURL: `https://superadmin.develop.rc.aigotsrl-dev.com/api` });


 const createChat = (data) => API.post('/chat/', data,getAuthConfig());

 const userChats = (id) => API.get(`/chat/${id}`,getAuthConfig());

 const AllChats = (id) => API.get(`/chat/`,getAuthConfig());

 const findChat = (firstId, secondId) => API.get(`/chat/find/${firstId}/${secondId}`,getAuthConfig());

 const getPharmacist = (id) => API.get(`/pharmacist/${id}`,getAuthConfig());

 const getMessages = (id) => API.get(`/message/${id}`,getAuthConfig());

 const addMessage = (data) => API.post(`/message`,data,getAuthConfig());

 const updateChat = (id,data) => API.put(`/chat/edit/${id}`,data,getAuthConfig());

 const chatById =(id) => API.get(`/chat/getone/${id}`,getAuthConfig());

 const getPharmacyByOwner = () => API.get(`/pharmacies/me`,getAuthConfig());





const chatbotApi = {
  createChat,
  userChats,
  findChat,
  getPharmacist,
  getMessages,
  addMessage,
  AllChats,
  updateChat,
  chatById,
  getPharmacyByOwner
  
};
export default chatbotApi;
