import React from 'react';
import defaultImage from '../../../../Assets/Icons/tempCardImage.svg';
import BlankBackground from '../../../../Assets/Icons/blankBackground.svg';

/*
Tailwind safelist:
(add classes for other color if there is an use case)
socialmediabtn
socialmediabtnhover
socialmediatextcolour
marketingbtn
marketingbtnhover
marketingtextcolour
*/

function TemplateCard({
    label,
    item,
    handlePreview,
    handleSelect,
    sectionColor,
}) {
    const name = item?.template_name?.length ? item.template_name : label;
    if ((!item?._id)&&(!item?.thumbnail)) {
        item={thumbnail:BlankBackground}
    }
    return (
        <>
            <div className='mx-auto max-w-sm overflow-hidden w-full elevation-1 my-5 rounded-md items-center justify-between'>
                <div className='  w-full aspect-square flex justify-center items-center'>
                    <img
                        className='  h-full w-full rounded-md object-cover'
                        src={item.thumbnail || defaultImage}
                        alt='template'
                    />
                </div>

                <div className='flex flex-col justify-center items-center h-[120px]'>
                    <div className='flex justify-center items-center h-[70px]'>
                        <div className='px-6'>
                            <p
                                className={`text-base font-normal text-${sectionColor}textcolour`}
                            >
                                {name}
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-row gap-x-2 px-4 mt-1 mb-4 mx-auto w-full h-[50px] justify-center items-center'>
                        <button
                            onClick={() =>
                                handlePreview(
                                    item.thumbnail || defaultImage,
                                    name
                                )
                            }
                            className={`border-2  border-${sectionColor}textcolour text-${sectionColor}textcolour h-12 w-40 py-1 px-1 text-sm rounded-md w-1/`}
                        >
                            Mostra
                        </button>

                        <button
                            className={`bg-${sectionColor}btn  border-2 border-${sectionColor}textcolour h-12 w-40 py-1 px-1 text-sm text-white rounded-md w-1/`}
                            onClick={() => handleSelect(item)}
                        >
                            Scegli
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TemplateCard;
