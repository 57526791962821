import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function Tags({ patchData, setPatchData, hanleIsChanged }) {

  const handleDelete = (option) => {
    setPatchData({
      ...patchData,
      secondaryCategory: patchData.secondaryCategory.filter(
        (element) => element !== option
      ),
    });
    hanleIsChanged()
  };
  return (
    <Autocomplete
      className="bg-[#F5F5F5] px-4 py-2 w-full rounded-md noShadow border-primarybtn text-gray-800 focus:outline-none"
      multiple
      id="tags-filled"
      options={subcategory.map((sub) => sub.secondaryCategoryDisplayName)}
      freeSolo
      value={patchData.secondaryCategory}
      onChange={() => hanleIsChanged()}
      onClose={(e, opt) => {
        return opt === "selectOption"
          ? setPatchData({
            ...patchData,
            secondaryCategory: [
              ...patchData.secondaryCategory,
              subcategory.find(
                (element) =>
                  element.secondaryCategoryDisplayName ===
                  e.target.innerText
              ),
            ],
          }) && hanleIsChanged()
          : setPatchData({
            ...patchData,
            secondaryCategory: patchData.secondaryCategory.filter(
              (element) =>
                element.secondaryCategoryDislayName !== e.target.innerText
            ),

          }) && hanleIsChanged()

      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.secondaryCategoryDisplayName}
            {...getTagProps({ index })}
            onDelete={() => handleDelete(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // onChange={(e) => console.log("clicked")}
          variant="standard"
          disabled
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
}
const subcategory = [
  {
    secondaryCategory: "gcid:pharmacy",
    secondaryCategoryDisplayName: "Farmacia",
  },
  {
    secondaryCategory: "gcid:drugstore",
    secondaryCategoryDisplayName: "Parafarmacia",
  },
  {
    secondaryCategory: "gcid:perfume_store",
    secondaryCategoryDisplayName: "Profumeria",
  },
  {
    secondaryCategory: "gcid:herb_shop",
    secondaryCategoryDisplayName: "Negozio di erboristeria",
  },
  {
    secondaryCategory: "gcid:herbalist",
    secondaryCategoryDisplayName: "Erborista",
  },
];
