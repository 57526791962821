import React from "react";
import "./CreateCampCard.css";
import Box from "@mui/material/Box";
import {
  LinearProgressCustom,
} from "../../OnProgressCampaign/AllProgressBars";
import formatCurrency from "../../../../../Utility/formatCurrency";
import { Goal } from "../../../../../Utility/marketingHelpers";

const CreateCampCard = ({ campaign, onSelect, disabled }) => {
  const {
    name,
    description,
    firstparams,
    secondparams,
    firstparamsLabel,
    secondparamsLabel,
    end_date: endDate,
    value: campaignGoal,
    budget,
    color
  } = campaign;

  const isCampaignType = (goalValue) => campaignGoal === goalValue;

  return (
    <>
      <div className="w-[440px] h-[450px] pt-[10%] rounded-md relative elevation-1 mx-auto flex flex-col justify-between text-left pb-4 gap-y-4">
        <div
          className="h-[7%] w-full rounded-t-md flex items-center absolute top-0 justify-center" style={{ backgroundColor: color, opacity: "25%" }}>
        </div>
          <p
            className="campaignnamespace text-xl text-center mx-auto left-0 right-0   absolute top-[.5%]"
            style={{ color: color, opacity: "100%" }}
          >
            {name}
          </p>




        <p className="campaigndescriptionspace text-tertiarytextcolour font-normal px-3">
          {description}
        </p>

        {endDate && (
          <div className="flex flex-col justify-start items-start px-3">
            <p className="text-tertiarytextcolour text-lg font-semibold">
              Data di Fine
            </p>

            <p className="campaigndescriptionspace text-tertiarytextcolour font-normal">
              {endDate}
            </p>
          </div>
        )}

        {isCampaignType(Goal.Branding) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {firstparams !== 0 && (
              <>
                <div className="flex w-full h-max justify-start items-start">
                  <p className="text-tertiarytextcolour text-lg font-semibold">
                    Risultati Giornalieri Previsti
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row justify-between items-center w-full gap-x-4">
                    <p style={{ color: color }} className="text-xs font-normal  text-left">
                      {firstparamsLabel}
                    </p>
                    <p style={{ color: color }} className="text-xs font-normal pr-3 ">
                      {firstparams}
                    </p>
                  </div>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressCustom value={100} color={color} />
                  </Box>
                </div>
              </>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.Interactions) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {firstparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Interazioni Social"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>

                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.Promotions) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <>
                <div className="flex w-full h-max justify-start items-start">
                  <p className="text-tertiarytextcolour text-lg font-semibold">
                    Risultati Giornalieri Previsti
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row justify-between items-center w-full gap-x-4">
                    <p style={{ color: color }} className="text-xs font-normal  text-left">
                      {firstparamsLabel}{" "}
                    </p>
                    <p style={{ color: color }} className="text-xs font-normal pr-3">
                      {firstparams}
                    </p>
                  </div>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressCustom value={100} color={color} />
                  </Box>
                </div>
              </>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.Delivery) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {firstparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Visualizzazione Pagina Delivery"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.Reservation) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {firstparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Visualizzazione Pagina Prenotazioni"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.MenuViews) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel}{" "}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {" "}
                    {firstparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel ||
                      "Persone che guardano il menù"}{" "}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>

                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        {isCampaignType(Goal.LocalVisits) ? (
          <div className="w-full  flex flex-col justify-start items-start gap-y-2 px-2">
            {(firstparams !== 0 || secondparams !== 0) && (
              <div className="flex w-full h-max justify-start items-start">
                <p className="text-tertiarytextcolour text-lg font-semibold">
                  Risultati Giornalieri Previsti
                </p>
              </div>
            )}

            {firstparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {firstparamsLabel}{" "}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {firstparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}

            {secondparams !== 0 && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full gap-x-4">
                  <p style={{ color: color }} className="text-xs font-normal  text-left">
                    {secondparamsLabel || "Visite al locale"}
                  </p>
                  <p style={{ color: color }} className="text-xs font-normal pr-3">
                    {secondparams}
                  </p>
                </div>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressCustom value={100} color={color} />
                </Box>
              </div>
            )}
          </div>
        ) : null}

        <p className="text-tertiarytextcolour font-semibold text-base text-center text-2xl">
          Costo Giornaliero: {formatCurrency(budget)}
        </p>

        <button
          onClick={() => onSelect(campaign)}
          disabled={disabled}
          style={{ background: color }}
          className="px-10 w-fit mx-auto py-2 text-white text-base  rounded-lg flex items-center justify-center  "
        >
          Avvia Campagna
        </button>
      </div>
    </>
  );
};

export default CreateCampCard;
