import React from 'react'
import {
    LinearProgressWithLabelGreen,
    LinearProgressWithLabelPurple,
    LinearProgressWithLabelRed
} from "../OnProgressCampaign/AllProgressBars"
import Box from '@mui/material/Box';

const getIconStyles = (url, color) => ({
    '-webkit-mask-size': 'cover',
    'mask-size': 'cover',
    '-webkit-mask-image': `url(${url})`,
    'mask-image': `url(${url})`,
    'background-color': color,
    'width': '100px', 
    'height': '100px', 
    "margin": "0 auto",
    'background-size': 'cover'
});

function ChooseCampCard({ data, onSelect }) {
    const { icon, name, description, color } = data;

    return (
        <>
            <div className='w-[430px] h-[340px]  rounded-md elevation-1 mx-auto flex flex-row  text-left ' >

                <div className="w-[213px] h-full elevation-1 rounded-md flex flex-col justify-evenly px-5 text-center" >
                    <i style={getIconStyles(icon, color)}></i>
                    <p className='font-semibold text-lg' style={{color}}>{name}</p>
                    <p className='font-normal text-black '>{description}</p>
                </div>
                
                <div className='w-[213px] flex flex-col gap-y-1 px-2 justify-evenly py-2' >

                    <p className='text-textcolour font-semibold text-xl text-center'>Risultati Giornalieri Previsti</p>

                    <div>
                        <div className="flex flex-col gap-y-z" >
                            <p className='text-sm font-normal text-[#AEAEAE]'>Lorem ipsum</p>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabelRed value={35} />
                            </Box>
                        </div>
                        <div className="flex flex-col" >
                            <p className='text-sm font-normal text-[#AEAEAE]'>Lorem ipsum</p>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabelPurple value={80} />
                            </Box>
                        </div>
                        <div className="flex flex-col" >
                            <p className='text-sm font-normal text-[#AEAEAE]'>Lorem ipsum</p>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabelGreen value={60} />
                            </Box>
                        </div>
                    </div>

                    <button onClick={() => onSelect(data)} className='bg-primarybtn ml-auto border-2 border-primarybtn h-fit py-1 px-12 text-white w-full rounded-md '>Scegli</button>
                </div>

            </div>

        </>
    )
}

export default ChooseCampCard
