import { useEffect, useState } from 'react';
import { getSavedTemplate, getAdminTemplates } from './editor';

const useTemplates = (apiFunction, objective, section, currentPage) => {
    const [templates, setTemplates] = useState([]);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
    useEffect(() => {
        (async () => {
            setIsLoadingTemplates(true);
            const templates = await apiFunction(objective, section, currentPage);
            setTemplates(templates);
            setIsLoadingTemplates(false);
        })();
    }, [objective,currentPage]);
    return [templates, isLoadingTemplates];
};
// Social templates
const useSocialUserTemplates = (objective, currentPage) =>
    useTemplates(getSavedTemplate, objective, 'social', currentPage);

const useSocialAdminTemplates = (objective, currentPage) =>
    useTemplates(getAdminTemplates, objective, 'social', currentPage);

// Marketing templates
const useMarketingUserTemplates = (objective, currentPage) =>
    useTemplates(getSavedTemplate, objective, 'marketing', currentPage);

const useMarketingAdminTemplates = (objective, currentPage) =>
    useTemplates(getAdminTemplates, objective, 'marketing', currentPage);

export const editorSocialTemplatesService = {
    user: useSocialUserTemplates,
    admin: useSocialAdminTemplates,
};
export const editorMarketingTemplatesService = {
    user: useMarketingUserTemplates,
    admin: useMarketingAdminTemplates,
};
