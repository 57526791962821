import React, { useContext } from "react";
import Facebook from "./Icons/Facebook.svg";
import Instagram from "./Icons/Instagram.svg";
import Negative from "./Icons/ChartDown.svg";
import Positive from "./Icons/ChartUp.svg";
import { ResultsCalendarContext } from "../../Contexts/ResultsCalendarContext";
function ResultsBigCard({ title, data, index }) {
    const { type } = useContext(ResultsCalendarContext);
    return (
        <div className={`xsm:mt-6 sm:mt-6 md:mt-6 xmd:py-8 py-6 rounded-lg mt-8 w-full ${title === "Risultati Social" ? "bg-[#d6fff2]" : "bg-[#d6e5ff]"}`}>
            <div className="flex">
                <h2 className="text-2xl ml-6">
                    {title}
                </h2>
                {index === 0 &&
                    <div className="flex gap-x-2 ml-3">
                        <img src={Facebook}>
                        </img>
                        <img src={Instagram}>
                        </img>
                    </div>
                }
            </div>
            <div className="h-full text-center mt-6">
                <div className="flex xsm:flex-col sm:flex-col md:flex-col xmd:flex-col lg:justify-center xl:justify-center 2xl:justify-center h-full">
                    {data.map((datas, index) => (
                        <>
                            <div className="px-11">
                                <h3 className="text-3xl">
                                    {datas.value}
                                </h3>
                                <h4>
                                    {datas.title2}
                                </h4>
                                <div className="flex mt-1 justify-center">
                                    {0 > datas.percentage ? (
                                        <img src={Negative}>
                                        </img>
                                    ) : (
                                            <img src={Positive}>
                                            </img>
                                    )}
                                    <span className={`ml-1 font-bold ${0 > datas.percentage ? "text-[#f04438]" : "text-[#17b26a]"}`}>
                                        {datas.percentage}%
                                    </span>
                                </div>
                                {"single" === type ? (
                                    <span className="ml-2 text-[#667085]">
                                        vs mese scorso
                                    </span>
                                ) : (
                                    <span className="ml-2 text-[#667085]">
                                        vs periodo scorso
                                    </span>
                                )}
                            </div>
                            {data.length - 1 !== index &&
                                <div className={`xsm:w-[80%] xsm:h-[2px] xsm:mx-auto xsm:my-4 sm:w-[80%] sm:h-[2px] sm:mx-auto sm:my-4 md:w-[80%] md:h-[2px] md:mx-auto md:my-4 xmd:w-[80%] xmd:h-[2px] xmd:mx-auto xmd:my-4 h-[65%] w-[2px] ${title === "Risultati Social" ? "bg-[#17b26a]" : "bg-[#73a6ff]"}`}>
                                </div>
                            }
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ResultsBigCard;