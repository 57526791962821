import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Trashicon from "../../../../Assets/Icons/Trash_light.svg";
import PlusIcon from "../../../../Assets/Icons/PlusIcon.svg";
import * as newslettersService from "../newslettersService";
import MailingListForm from "./MailingListForm";
import useServiceResource from "../../../../Hooks/useServiceResource";
import useServiceCall from "../../../../Hooks/useServiceCall";
import DeleteAlertButton from "./../../../../Reusable/DeleteAlert/DeleteAlertButton";
import MailingListSelect from "./MailingListSelect";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import "./Mailing-List.css";
import Backbutton from "../../../../Reusable/Back-Button/Back-Button";
import { toast } from "react-toastify";
import GoToButtonSingle from "../../../../Reusable/goToButton/GoToButtonSingle";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner"
    )
  ).default;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2B65BB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
  // hide last border
  "td, th": {
    border: 0,
    color: "#818181",
  },
}));

const Newslettermailinglist = () => {
  const [subscribedCustomers, setSubscribedCustomers] = useState();
  useEffect(() => {
    newslettersService
      .getSubscribers()
      .then((subs) => subs && setSubscribedCustomers(subs))
      .catch(() =>
        toast.error(
          "Non è possibile caricare i clienti. Riprova o contatta l'assistenza.",
        ),
      );
  }, []);

  const [isEditingList, setIsEditingList] = useState(false);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [selectedMailingList, setSelectedMailingList] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  // Mailing lists data
  const [
    mailingLists,
    setMailingLists,
    getMailingLists,
    renderMailingListsLoader,
  ] = useServiceResource({
    apiCall: newslettersService.getMailingLists,
    mapResponse: (res) => res.mailingLists,
    onSuccess: () => {
      setSelectedMailingList(null);
      getAllCustomers();
    },
  });

  // Customers data
  const [customers, setCustomers] = useState(null);
  const [getAllCustomers, renderGetCustomersLoader] = useServiceCall({
    apiCall: newslettersService.getCustomers,
    mapResponse: (res) => res.customers,
    onSuccess: (data) => setCustomers(data),
  });
  const [getMailingListCustomers, renderGetMLCustomersLoader] = useServiceCall({
    apiCall: newslettersService.getMailingList,
    mapResponse: (res) => res.customers,
    onSuccess: (data) => setCustomers(data),
  });

  const getCustomers = async () => {
    if (!mailingLists) return;

    if (selectedMailingList?.id)
      getMailingListCustomers(selectedMailingList.id);
    else getAllCustomers();
  };

  // gets customers when selected mailing list changes
  useEffect(() => {
    getCustomers();
  }, [selectedMailingList]);

  const handleCreateListClicked = () => {
    setIsEditingList(false);
    setIsCreatingList(true);
  };

  const handleEditListClicked = () => {
    setIsCreatingList(false);
    setIsEditingList(true);
  };

  const handleCancelledListForm = () => {
    setIsCreatingList(false);
    setIsEditingList(false);
  };

  const handleListFormFinished = async () => {
    setIsEditingList(false);
    setIsCreatingList(false);
    await getMailingLists();
  };

  // Deleting mailing list
  const deleteSelectedMailingListLocally = () => {
    const updatedMailingLists = [...mailingLists];
    const index = mailingLists.findIndex(
      (ml) => ml.id === selectedMailingList.id,
    );
    updatedMailingLists.splice(index, 1);
    setMailingLists(updatedMailingLists);
    setSelectedMailingList(null);
  };

  const [
    deleteMailingList,
    renderDeleteMailingListLoader,
    isDeletingMailingList,
  ] = useServiceCall({
    apiCall: newslettersService.deleteMailingList,
    onSuccess: deleteSelectedMailingListLocally,
  });

  const handleDeleteMailingListConfirm = async () => {
    await deleteMailingList(selectedMailingList.id);
  };

  // when user selects an option from the selection
  const handleSelectChange = (value) => {
    const mailingList = mailingLists.find((ml) => ml.name === value);
    setSelectedMailingList(mailingList);
  };

  // Deleting a customer
  const [currentCustomerIdDeleting, setCurrentCustomerIdDeleting] =
    useState(null);
  const [deleteCustomer, renderDeleteCustomerLoader] = useServiceCall({
    apiCall: newslettersService.deleteCustomer,
  });

  const deleteCustomerLocally = (customerId) => {
    const updatedCustomers = [...customers];
    const index = customers.findIndex((c) => c.id === customerId);
    updatedCustomers.splice(index, 1);
    setCustomers(updatedCustomers);
  };

  const handleDeleteCustomerConfirm = async (listId,customerId) => {
    setCurrentCustomerIdDeleting(customerId);
    const data = await deleteCustomer(listId,customerId);
    if (data) deleteCustomerLocally(customerId);
    setCurrentCustomerIdDeleting(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    const filtered = customers.filter(
      (customer) =>
        customer.firstName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        customer.lastName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        customer.email.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setFilteredCustomers(filtered);
  };

  // render the customers in edit or view mode
  const renderCustomers = () => {
 const data = searchQuery ? filteredCustomers : customers;
  if (!data || data.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <p className=" text-[#818181] text-base mt-8 ">Nessun risultato trovato.</p>
      </div>
    );
  }
    return data.map((customer) => (
      <StyledTableRow key={customer.id}>
        <StyledTableCell align="center">{customer.firstName}</StyledTableCell>
        <StyledTableCell align="center">{customer.lastName}</StyledTableCell>
        <StyledTableCell
          align="center"
          className="w-[500px] xsm:w-[150px] sm:w-[200px] md:w-[200px] xmd:w-[250px] lg:w-[400px]">
          {customer.email}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            alignItems="center"
            className="justify-center"
            spacing={1}>
            <DeleteAlertButton
              message="Questo cliente verrà eliminato definitivamente!"
              onConfirm={() => handleDeleteCustomerConfirm(selectedMailingList?.id,customer.id)}
              renderButton={(props) => (
                <Button
                  aria-label="delete"
                  size="small"
                  className="font-semibold actionBtn delBtn"
                  disabled={
                    customer.id === currentCustomerIdDeleting ||
                    isDeletingMailingList
                  }
                  {...props}>
                  {customer.id === currentCustomerIdDeleting ? (
                    renderDeleteCustomerLoader()
                  ) : (
                    <div className="flex flex-row gap-x-1 bg-[#C2222B] rounded-md p-1 items-center">
                      <img src={Trashicon} alt="Delete Icon" />
                    </div>
                  )}
                </Button>
              )}
            />
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  const renderNoDataMessage = () => {
    if (mailingLists?.length === 0)
      return (
        <p className="text-center text-base mx-auto mt-8">
          Nessuna lista trovata.
        </p>
      );

    if (customers?.length === 0)
      return (
        <p className="text-center text-base mt-8 mx-auto">
          Non ci sono clienti.
        </p>
      );

    return null;
  };

  return (
    <>
      <InformationTopBanner
        pagetype="Mailing"
        sectionname="List"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "In questa sezione puoi visualizzare la lista dei tuoi contatti, aggiungerli o eliminarli e creare una mailing list da utilizzare per inviare le tue mail o Mail automatiche"
        }
        sectionicon={NewsletterHeaderLottie}
      />

      <div className="flex w-full justify-center items-center mt-8 px-24">
        <div className="flex flex-row w-full justify-between xsm:flex-col sm:flex-col md:flex-col xmd:flex-col">
          <div className="flex justify-center items-center w-1/5">
            <Backbutton />
          </div>

          <div className="flex justify-center items-center w-3/5">
            <GoToButtonSingle
              textname1="Crea Nuova Lista Clienti"
              icon1={PlusIcon}
              onClick1={handleCreateListClicked}
            />
          </div>

          <div className="flex justify-center items-center w-1/5 xsm:hidden sm:hidden md:hidden xmd:hidden">
            <></>
          </div>
        </div>
      </div>

      <div className="Newslettermailinglistinformation mt-12 ">
        <div className="flex flex-row w-full my-12 justify-between items-center gap-4">
          <div className="flex items-center">
            <MailingListSelect
              mailingLists={mailingLists}
              selectedMailingList={selectedMailingList}
              onChange={handleSelectChange}
            />

            <div className="ml-12">{renderMailingListsLoader()}</div>

            <div className="flex items-center justify-center">
              <input
                type="text"
                placeholder="Cerca per nome, cognome o email"
                className="focus:outline-none h-12 w-[300px] border-[2px] px-1 border-newsletterbtn rounded-md focus:border-blue-500"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          {selectedMailingList && (
            <div className="flex flex-row items-center gap-x-2">
              <button
                className="w-40 h-12 flex items-center justify-center gap-x-2 rounded-md elevation-1 text-white bg-newsletterbtn border-2 border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-white active:shadow-innerbig"
                onClick={handleEditListClicked}>
                Modifica
              </button>

              <DeleteAlertButton
                message="Questa lista verrà eliminata definitivamente!"
                onConfirm={handleDeleteMailingListConfirm}
                renderButton={(props) => (
                  <button
                    className="w-40 h-12 flex items-center justify-center gap-x-2 rounded-md text-deletetextcolour bg-white border-2 border-deletebtn hover:text-white hover:bg-deletebtnhover hover:border-deletebtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    disabled={isDeletingMailingList}
                    {...props}>
                    Elimina
                  </button>
                )}
              />
            </div>
          )}
        </div>

        {mailingLists?.length > 0 && customers?.length !== 0 && (
          <div className="rounded-md newsletterTableContainer">
            <Table sx={{ minWidth: 800 }} className="newsletterTable">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Cognome</StyledTableCell>
                  <StyledTableCell align="center" className="w-40">
                    Email
                  </StyledTableCell>
                  <StyledTableCell align="center">Azioni</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>{renderCustomers()}</TableBody>
            </Table>
          </div>
        )}

        {renderNoDataMessage()}

        <div className="my-3">
          {renderMailingListsLoader() ||
            renderGetCustomersLoader() ||
            renderGetMLCustomersLoader() ||
            renderDeleteMailingListLoader()}
        </div>

        {(isCreatingList || (isEditingList && selectedMailingList)) && (
          <MailingListForm
            isCreatingList={isCreatingList || isEditingList}
            existingList={
              isEditingList && { ...selectedMailingList, customers }
            }
            onCancelled={handleCancelledListForm}
            onFinished={handleListFormFinished}
            subscribedCustomers={subscribedCustomers}
          />
        )}
      </div>
    </>
  );
};

export default Newslettermailinglist;
