import axios from "axios";
import { getUserId } from "../getUserData";
import { getAuthConfig } from "./auth";
import { baseUrl } from "./Urls";

const DASHBOARD_BASE_ENDPOINT = `https://${
  process.env.REACT_APP_DASHBOARD_PREFIX
}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;

const SYSTEM_CUSTOMERS_ENDPOINT = `${DASHBOARD_BASE_ENDPOINT}/systemCustomers`;
const ORDERS_ENDPOINT = `${DASHBOARD_BASE_ENDPOINT}/orders`;

const getThemesBaseEndpoint = () =>
  `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${
    process.env.REACT_APP_THEMES_API_PATH
  }`;
const getSubscribersEndpoint = () =>
  `${getThemesBaseEndpoint()}/newsletters/subscribers`;

export const list = async () =>
  await axios.get(SYSTEM_CUSTOMERS_ENDPOINT, getAuthConfig());

export const subscribersList = async () =>
  await axios.get(getSubscribersEndpoint(), getAuthConfig());

export const get = async (id) =>
  axios.get(`${getThemesBaseEndpoint()}/customers/${id}`, getAuthConfig());

export const create = (customer) =>
  axios.post(SYSTEM_CUSTOMERS_ENDPOINT, customer, getAuthConfig());

export const edit = (id, customer) =>
  axios.patch(`${SYSTEM_CUSTOMERS_ENDPOINT}/${id}`, customer, getAuthConfig());

export const remove = (id) =>
  axios.delete(`${SYSTEM_CUSTOMERS_ENDPOINT}/${id}`, getAuthConfig());

export const getBookingsForCustomer = (id) =>
  axios.get(
    `${getThemesBaseEndpoint()}/booking/customer/${id}`,
    getAuthConfig(),
  );

export const getOrdersForCustomer = (email) =>
  axios.get(
    `${getThemesBaseEndpoint()}/orders/customer/email/${email}`,
    getAuthConfig(),
  );

export const exportCSV = () =>
  axios.get(`${SYSTEM_CUSTOMERS_ENDPOINT}/exportCSV`, getAuthConfig());

export const newsletterSubscribe = (id, customer) =>
  axios.patch(
    `${SYSTEM_CUSTOMERS_ENDPOINT}/${id}/newsletterSubscription`,
    customer,
    getAuthConfig(),
  );

export const activeStatus = (id, customer) =>
  axios.patch(
    `${SYSTEM_CUSTOMERS_ENDPOINT}/${id}/activeStatus`,
    customer,
    getAuthConfig(),
  );

export const uploadCSV = (data) => {
  return axios.post(
    `${SYSTEM_CUSTOMERS_ENDPOINT}/uploadCSV`,
    data,
    getAuthConfig(),
  );
};
