import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import Trashicon from "../../../../Assets/Icons/Trash_light.svg";
import ViewIcon from "../../../../Assets/Icons/View_light.svg";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import * as service from "../../../../apis/automatedMailService";
import NewsNavigation from "../NewsNavigation/NewsNavigation";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import DeleteAlertButton from "../../../../Reusable/DeleteAlert/DeleteAlertButton";
import Modal from "../../../../Reusable/Modal/Modal";
import Backdrop from "../../../../Reusable/Backdrop/Backdrop";
import { getNameByType } from "./Helpers/CardsData";
import "./MailAutomation.css";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner"
    )
  ).default;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFA563",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
  // hide last border
  "td, th": {
    border: 0,
    color: "#818181",
  },
}));

function MailAutomationTable() {
  const [autoMails, setAutomails] = useState();
  useEffect(() => {
    (async () => {
      const data = await service.getAutomaticNewsletterMails();
      data && setAutomails(data);

      // setAutomails(testMailData); // test data
    })();
  }, []);

  const handleDelete = async id => {
    const data = await service.deleteAutomatedMail(id);
    data && setAutomails(mails => mails.filter(m => m._id !== id));
  };

  // handling display of singel template image
  const [templateInPreview, setTemplateInPreview] = useState(false);
  const closePreviewModal = () => setTemplateInPreview(null);
  const openPreviewModal = template => setTemplateInPreview(template);

  return (
    <>
      {/* MODAL FOR SINGLE TEMPLATE PREVIEW */}
      {templateInPreview && (
        <Backdrop animation="Backdrop--entered" onClick={closePreviewModal}>
          <Modal
            title={templateInPreview?.name}
            className={templateInPreview.image ? "h-[90%] overflow-scroll" : "h-[50%]"}
          >
            {templateInPreview.image ? (
              <img
                src={templateInPreview.image}
                alt=""
                className="w-full h-min rounded-md grow"
                onClick={closePreviewModal}
              />
            ) : (
              <div className="flex flex-col justify-center h-full w-full text-center text-[#AEAEAE] font-normal p-2 text-base">
                Non c'è nessuna immagine per questo template.
              </div>
            )}
          </Modal>
        </Backdrop>
      )}

      <InformationTopBanner
        pagetype="Mail"
        sectionname="Ricorrenti"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "Da questa sotto sezione puoi gestire tutte le Mail Automatiche che hai creato e che sono attive, con la possibilità di eliminarle"
        }
        sectionicon={NewsletterHeaderLottie}
      />

      <div className=" p-5  ">
        <NewsNavigation />
      </div>

      <ScrollToTop />

      <div className="flex flex-col ">
        {/* <div className="flex flex-row ml-[3%]">
          <div className="-mr-8 z-10 h-[50px] flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#2B65BB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>

          <input
            type="search"
            className="w-[40%]  h-[50px] bg-[#F6F6F6] rounded-md px-8 placeholder:text-[#989898]"
            placeholder="Cerca per Tipologia, Oggetto..."
          />
        </div> */}

        <div className="w-[95%] mx-auto ">
          {!autoMails && (
            <div className="w-full text-center text-[#AEAEAE] font-normal p-5 text-base">
              Non ci sono mail automatiche.
            </div>
          )}

          {autoMails?.length > 0 && (
            <>
              <TableContainer
                component={Paper}
                className="orderTableContainer mt-3"
              >
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="customized table"
                  className="orderTable"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        Tipologia
                      </StyledTableCell>
                      <StyledTableCell align="center">Oggetto</StyledTableCell>
                      {/* <StyledTableCell align="center">
                          Email Mittente
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Lista Clienti
                        </StyledTableCell> */}
                      <StyledTableCell align="center">Azioni</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  {autoMails?.map(mail => (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          style={{ fontSize: "13px", width: 200, padding: 0 }}
                          className="flex flex-row justify-start items-center "
                        >
                          <p className="text-[#AEAEAE] font-normal text-left text-base p-5">
                            {getNameByType(mail.type)}
                          </p>
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          style={{ fontSize: "13px", width: 600, padding: 0 }}
                          className="flex flex-row justify-center "
                        >
                          <p className="text-[#AEAEAE] font-normal text-left p-5 text-base">
                            {mail.subject}
                          </p>
                        </StyledTableCell>

                        {/* <StyledTableCell
                          align="center"
                          style={{ fontSize: "13px", width: 400, padding: 0 }}
                          className="flex flex-row justify-center "
                        >
                          <p className="text-[#AEAEAE] font-normal text-left p-5 text-base">
                            giovanni.santonastaso@gmail.com
                          </p>
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          style={{ fontSize: "13px", width: 400, padding: 0 }}
                        >
                          <p className="text-[#AEAEAE] font-normal text-left p-5 text-base">
                            Lista Utente Medio
                          </p>
                        </StyledTableCell> */}

                        <StyledTableCell
                          align="center"
                          style={{ fontSize: "13px", width: 100, padding: 0 }}
                        >
                          <div className="flex flex-row place-content-center place-items-start gap-x-2 mx-auto w-[210px]">
                            <div className="flex gap-x-4">
                              {/* <div className="flex flex-row items-center gap-x-1">
                                <button className="p-2 bg-newsletterbtn rounded-full flex flex-row items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#ffffff"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </button>
                                <p className="text-newslettertextcolour  font-normal text-base hover:cursor-pointer">
                                  Vedi
                                </p>
                              </div> */}

                              <div className="flex flex-row items-center gap-x-1  hover:cursor-pointer">
                                <button
                                  onClick={() => openPreviewModal(mail)}
                                  className=" bg-newslettertextcolour text-newslettertextcolour hover:bg-newsletterbtnhover hover:text-white flex w-10 h-10 items-center elevation-1 rounded-md active:shadow-innerbig"
                                >
                                  <Tooltip
                                    title="Mostra"
                                    placement="bottom"
                                    arrow
                                  >
                                    <img
                                      src={ViewIcon}
                                      alt="Delete Icon"
                                      className="mx-auto"
                                    />
                                  </Tooltip>
                                </button>
                                <DeleteAlertButton
                                  message="Questo cliente verrà eliminato definitivamente!"
                                  onConfirm={() => handleDelete(mail._id)}
                                  renderButton={props => (
                                    <Button
                                      aria-label="delete"
                                      size="small"
                                      className="font-semibold actionBtn delBtn"
                                      {...props}
                                    >
                                      <div className="flex w-10 h-10 items-center elevation-1 rounded-md text-white bg-deletebtn border-2 border-deletebtn hover:bg-deletebtnhover hover:border-deletebtnhover active:border-white active:shadow-innerbig">
                                        <Tooltip
                                          title="Cancella"
                                          placement="bottom"
                                          arrow
                                        >
                                          <img
                                            src={Trashicon}
                                            alt="Delete Icon"
                                            className="mx-auto"
                                          />
                                        </Tooltip>
                                      </div>
                                    </Button>
                                  )}
                                />
                              </div>

                              {/* <div className="flex flex-row items-center gap-x-1  hover:cursor-pointer">
                                <button className="p-2 bg-deletebtn rounded-full flex flex-row items-center">
                                  <BsStopFill size={18} color="white" />
                                </button>
                                <p className="text-[#E22032]  font-normal text-base hover:cursor-pointer">
                                  Stop
                                </p>
                              </div> */}
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MailAutomationTable;
