import Branding from "../../../../Assets/Icons/SocialMedia_Branding.png";
import Promotion from "../../../../Assets/Icons/SocialMedia_Promotion (2).png";
import Reservation from "../../../../Assets/Icons/SocialMedia_Reservation.png";
import Delivery from "../../../../Assets/Icons/SocialMedia_Delivery (2).png";
import Themes from "../../../../Assets/Icons/SocialMedia_Themes (2).png";
import Menu from "../../../../Assets/Icons/SocialMedia_Menu.png";
import Joi from "joi";
import Loader from "../../../../Reusable/Loader/Loader";
import { fetchMockDescriptions } from "../../../../apis/socialMediaDescription";

export const campaigns = [
    {
        name: "Notorietà",
        value: "branding",
        color: "#FF5C66",
        icon: Branding,
        description: "Vuoi diventare il più famoso della città? Crea un post per parlare del tuo ristorante al meglio!",
    },

    {
        name: "Promozioni",
        value: "promotion",
        color: "#FFBC46",
        icon: Promotion,
        description: " Racconta le tue promo e offerte nei tuoi post e  conquista il cuore dei tuoi clienti.",
    },

    {
        name: "Prenotazioni",
        value: "reservation",
        color: "#2ACB91",
        icon: Reservation,
        description: "Invita i tuoi clienti a prenotare un tavolo attraverso i tuoi post sui social!",
    },

    {
        name: "Delivery",
        value: "delivery",
        color: "#FF7325",
        icon: Delivery,
        description: "Racconta ai tuoi seguaci perché dovrebbero ordinare qualcosa da mangiare a casa. ",
    },

    {
        name: "Giornate a Tema",
        value: "themes",
        color: "#CB2A77",
        icon: Themes,
        description: "Vuoi diventare il più famoso della città? Crea un post per parlare del tuo ristorante al meglio! ",
    },

    {
        name: "Menu",
        value: "menu",
        color: "#46D3FF",
        icon: Menu,
        description: "Festeggia con i tuoi clienti feste nazionali, ricorrenze, giornate a tema con un Post  creato per l'occasione. ",
    },
];


export const validationSchema = Joi.object({
    description: Joi.string().allow('').max(2200).messages({
        "string.max": `La descrizione non può avere più di 2200 caratteri`,
    }),
    hasClickedSave: Joi.boolean().sensitive().valid(true).required().messages({
        "any.only": "Clicca su Salva per per continuare.",
    }),
    hashtags: Joi.array().items(Joi.string().min(1).max(50)),
});

export const createNewHashtag = (text) => ({
    text: `#${text}`,
    isSelected: true,
    isSuggested: false,
});


export const SaveDraftButton = ({ onClick, isLoading }) => (
    <button
        className="flex w-40 h-12 px-4 py-2 justify-center items-center rounded-md elevation-1 text-white bg-socialmediabtn border-2 border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover active:border-white active:shadow-innerbig"
        onClick={onClick}
        disabled={isLoading}
    >
        {isLoading ? <Loader /> : "Salva in Bozze"}
    </button>
);

export const PublishButton = ({ onClick }) => (
    <button
        className="flex w-40 h-12 px-4 py-2 justify-center items-center rounded-md elevation-1 text-white bg-socialmediabtn border-2 border-socialmediabtn hover:bg-socialmediabtnhover hover:border-socialmediabtnhover active:border-white active:shadow-innerbig"
        onClick={onClick}
    >
        Pubblica
    </button>
);

export const sciencedHashtags = [
    { text: "#instafood", isSelected: false, isSuggested: true },
    { text: "#foodie", isSelected: false, isSuggested: true },
    { text: "#delicious", isSelected: false, isSuggested: true },
    { text: "#food", isSelected: false, isSuggested: true },
    { text: "#foodstagram", isSelected: false, isSuggested: true },
    { text: "#foodoporn", isSelected: false, isSuggested: true },
    { text: "#aigot", isSelected: false, isSuggested: true },
    { text: "#2023", isSelected: false, isSuggested: true },
    { text: "#frontend", isSelected: false, isSuggested: true },
    { text: "#allthebest", isSelected: false, isSuggested: true },
    { text: "#dev", isSelected: false, isSuggested: true },
    { text: "#react", isSelected: false, isSuggested: true },
]

export const MockDescriptions = [
    {
        text: fetchMockDescriptions,
        isSelected: false,
        isSuggested: true,
    },
    {
        text: "Le brutte intenzioni la maleducazione, la tua brutta figura di ieri sera e l’ingratitudine la tua arroganza. Fai ciò che vuoi mettendo i piedi in testa. Il tuo disordine è una forma d’arte ma tu sai solo coltivare invidia. Ringrazia il cielo sei su questo palco, rispetta chi ti ci ha portato dentro e questo sono io! Lo faccio volentieri.",
        isSelected: false,
        isSuggested: true,
    },
    {
        text: "John and Mary had been married for five years, but they had been together for a total of ten. They met in college and fell in love quickly, despite their many differences. John was an outgoing, adventurous type, while Mary was more reserved and practical. However, they complemented each other well and were happy together. They had two young children and lived in a cozy house in the suburbs. Despite the challenges of balancing work and family, they were content and grateful for what they had.",
        isSelected: false,
        isSuggested: true,
    },
]

export const renderErrorMessage = (formError) => (
    <>
        {formError && (
            <div className="flex justify-center items-center">
                <p className="text-xl text-red-600 font-semibold text-center">
                    {formError}
                </p>
            </div>
        )}
    </>
);