import axios from "axios";
import { getAuthConfig } from "../Utility/Api/auth";
import { baseUrl } from "../Utility/Api/Urls";
import { getUserId } from "../Utility/getUserData";

const getBaseEndpoint = () => `https://${getUserId()}.${process.env.REACT_APP_THEMES_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}`;

export const fetchDeliverySettings = () => axios.get(`${getBaseEndpoint()}/orders/delivery/settings`, getAuthConfig());
export const fetchDeliverySetting = (id) => axios.get(`${getBaseEndpoint()}/orders/delivery/settings/${id}`, getAuthConfig());
export const createDelivery = (Delivery) => axios.post(`${getBaseEndpoint()}/orders/delivery/settings`, Delivery, getAuthConfig());
export const updateDelivery = (id, Delivery) => axios.patch(`${getBaseEndpoint()}/orders/delivery/settings/${id}`, Delivery, getAuthConfig());
