import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./EmptyModal.css";

const EmptyModal = ({
  title,
  children,
  onClose,
  mainDivClasses,
  showHeader = true,
  headerClasses,
  titleClasses,
  ...rest
}) => {
  return (
    <div className={`EmptyModal ${mainDivClasses}`} {...rest}>
      {showHeader && (
        <header
          className={`EmptyModal__header flex items-center justify-between ${headerClasses}`}
        >
          <h1 className={` font-regular ${titleClasses}`}>{title}</h1>
          <div onClick={onClose}>
            <CloseIcon className="EmptyModal__header__icon" />
          </div>
        </header>
      )}
      <div className="EmptyModal__body">{children}</div>
    </div>
  );
};

export default EmptyModal;
