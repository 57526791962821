import React, { useContext, useEffect, useRef, useState } from 'react';
import BeeComponent from '../../../../Reusable/BeePlugin/BeePlugin';
import Loader from '../../../../Reusable/Loader/Loader';
import { SocialMediaContext } from '../Context/SocialMediaContext';
import * as templateService from '../../../../apis/templateService';
import * as imageHostingService from '../../../../apis/imageHostingService';
import { toast } from 'react-toastify';
import 'reactjs-popup/dist/index.css';
import './Index-Create-Post.css';
import TextareaAutosize from 'react-textarea-autosize';
import Backbutton from '../../../../Reusable/Back-Button/Back-Button';
import SuggestedGoalsContent from './SuggestedGoalsContent';
import {
  validationSchema,
  createNewHashtag,
  renderErrorMessage,
} from './Utils';
import SuggestedTemplates from './SuggestedTemplates';
import SuggestDescriptionModal from './SuggestDescriptionModal';
import SchedulaModal from './SchedulaModal/SchedulaModal';
import { campaigns } from './Utils';
import { getSuggestedAdCopy } from '../../../../apis/adCopy/adCopyFunctions';
import GraphicEditorPortal from '../../GraphicEditor-NEW/GraphicEditorPortal/GraphicEditorPortal';
import GraphicEditor from '../../GraphicEditor-NEW/graphiceditor';
import TemplatesEditorWrapper from '../../GraphicEditor-NEW/TemplatesEditorWrapper/TemplatesEditorWrapper';
import { editorSocialTemplatesService } from '../../../../apis/editor/EditorService';
import { getUserData } from '../../../../Utility/getUserData';

export default function CreatePost() {
  const isFirstRender = useRef(true);

  const {
    image,
    setImage,
    json,
    setJson,
    setHtml,
    hashtags,
    setDescription,
    description,
    setHashtags,
    imageUrl,
    setImageUrl,
  } = useContext(SocialMediaContext);
  const handleDescriptionChange = (e) => {
    setSelectedDescription(e.target.value);
    setDescription(e.target.value);
  };

  const handleKeyDownDesc = (e) => {
    if (e.key === 'Backspace' && selectedDescription === '') {
      // Cancella l'ultimo elemento dell'array
      const lastIndex = allDescription.length - 1;
      const lastDescription = allDescription[lastIndex];
      setSelectedDescription(lastDescription);
      setDescription(lastDescription);
    }
  };

  const [isSaving, setIsSaving] = useState(false);
  const [hasClickedSave, setHasClickedSave] = useState(false);
  const [campaign, SetCampName] = React.useState(campaigns[0].value);
  const [isOpen, SetOpen] = React.useState(false);
  // note: hashtag is an object:
  // { text: String, isSelected: Boolean, isSuggested: Boolean }
  // when they are selected/unselected, we toggle isSelected boolean
  const [allHashtags, setAllHashtags] = useState([]);
  const [newHashtag, setNewHashtag] = useState([]);
  const [hashtagInput, setHashtagInput] = useState('');
  // Used to keep track of the hashtags that are currently active (to be posted)
  const [activeHashtags, setActiveHashtags] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [templateJson, setSelectedTemplateJson] = useState();
  /*  useEffect(() => {

    // fetch from data science api in the future
    setAllHashtags(sciencedHashtags);
    // fetch from data science api in the future
    setAllDescription(MockDescriptions);
  }, []);
*/

  const [allDescription, setAllDescription] = useState([]);
  const [activeDescriptions, setActiveDescription] = useState({});

  const getSelectedDescription = () =>
    allDescription?.filter((description) => description.isSelected);

  const [selectedDescription, setSelectedDescription] = useState('');
  const handleToggleDescription = (description) => {
    setSelectedDescription(description.text);
    setDescription(description.text);
  };

  const inputRef = useRef(true);

  const handleAddNewHashtag = (text) => {
    if (text === '') return;
    const NewHashtag = createNewHashtag(text);
    const updatedHashtags = [...newHashtag, NewHashtag];
    if (updatedHashtags.length <= 30) {
      setNewHashtag(updatedHashtags);
    } else {
      toast.warning('hai raggiunto il limite massimo di hashtag');
    }
    setHashtagInput('');
    setActiveHashtags((prevActiveHashtags) => ({
      ...prevActiveHashtags,
      [newHashtag.text]: true,
    }));
  };

  // helper functions

  const getSelectedHashTags = () => {
    const selectedAllHashtags = allHashtags?.filter((tag) => tag?.isSelected);
    const selectedNewHashtags = newHashtag?.filter((tag) => tag?.isSelected);
    return [...selectedAllHashtags, ...selectedNewHashtags];
  };
  // updates context with the selected hashtags
  useEffect(() => {
    if (!allHashtags) return;
    setHashtags(getSelectedHashTags()?.map((h) => h.text));
  }, [allHashtags, activeHashtags]);
  // handle saving of plugin
  const handleSaveClick = () => {
    setHasClickedSave(true);
    setIsSaving(true);
    setIsCreatingImage(true);
  };

  useEffect(() => {
    const userData = getUserData();
    const location = userData.socialAccounts?.gmbLocationResourceIdentifier;

    const fetchSuggestedAdCopy = async () => {
      if (location) {
        const suggestedAdCopy = await getSuggestedAdCopy(campaign, location);
        if (
          suggestedAdCopy &&
          Array.isArray(suggestedAdCopy) &&
          suggestedAdCopy.length > 0
        ) {
          setAllDescription(Object.values(suggestedAdCopy));
          setAllHashtags(suggestedAdCopy.map((s) => s.hashtags).flat());
        } else {
          setAllDescription([]);
          setAllHashtags([]);
        }
      } else {
        setAllDescription([]);
        setAllHashtags([]);
      }

      setIsLoading(false);
    };

    fetchSuggestedAdCopy();
  }, [templateJson]);

  // validation for the form
  const [formError, setFormError] = useState(null);

  const isFormInvalid = () => {
    const { error } = validationSchema.validate({
      hasClickedSave,
      hashtags,
      description,
    });

    setFormError(error ? error.details[0].message : null);
    return !!error;
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    isFormInvalid();
  }, [hasClickedSave]);

  // preview image
  const [isCreatingImage, setIsCreatingImage] = useState(false);
  const handleImageCreated = () => setIsCreatingImage(false);

  const [isSavingDraft, setIsSavingDraft] = useState(false);

  const handleSaveDraftClick = async () => {
    if (isFormInvalid()) {
      toast.error('Informazioni non valide');
      return;
    }
    setIsSavingDraft(true);

    let url = imageUrl;
    if (!url && image) {
      url = await imageHostingService.hostBase64Image(image);
      url && setImageUrl(url);
    }

    const name = `Template ${new Date(Date.now()).toLocaleString()}`;
    const result = await templateService.createUserSocialTemplate({
      name,
      json,
      goal: campaign,
      image: url,
    });
    result && toast.success(`${name} Salvato!`);
    setIsSavingDraft(false);
  };

  //to Prevent adding the same hashtag twice -> because it is causing issues removing a repeated hashtag
  function handleAddHashTAG(hashtagInput) {
    const isHashTagAlreadyThere = newHashtag?.findIndex(
      (t) => t.text === `#${hashtagInput}`
    );
    if (isHashTagAlreadyThere !== -1) {
      setHashtagInput('');
      toast.warning("l'hashtag è già stato aggiunto");
    } else {
      handleAddNewHashtag(hashtagInput.replace(/\s/g, ''));
    }
  }
  //enter event
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      handleAddHashTAG(event.target.value.replace(/\s/g, ''));
      inputRef.current.blur();
    }
  }

  return (
    <div className='flex flex-col bg-white w-full'>
      <div className='flex mb-5 '>
        <Backbutton />
      </div>
      <SuggestedGoalsContent
        SetCampName={SetCampName}
        campaign={campaign}
      />
      <TemplatesEditorWrapper
        imageUploadCard
        sectionColor={'socialmedia'}
        service={editorSocialTemplatesService}
        campaign={campaign}
      />
      {image && (
        <>
          {campaign && (
            <div className='flex flex-row w-full mt-4 border-y-2 border-[#D9D9D9] '>
              <div className='flex flex-col w-full rounded-md m-2 p-2 gap-y-5'>
                <div className='flex flex-row justify-between items-center xsm:flex-col sm:flex-col xsm:items-start sm:items-start'>
                  <p className='text-xl text-[#FC6371] xmd:w-[35%] font-semibold text-left xsm:pb-3 sm:pb-3'>
                    Descrizione del Post
                  </p>

                  <SuggestDescriptionModal
                    allDescription={allDescription}
                    handleToggleDescription={handleToggleDescription}
                    activeDescriptions={activeDescriptions}
                  />
                </div>

                <TextareaAutosize
                  minRows={5}
                  className='bg-[#F6F6F6] self-center block border-2 border-blue-600 w-full p-3 rounded-md mb-4 focus:text-black focus:font-normal'
                  id='description'
                  placeholder='Descrizione del post'
                  onChange={handleDescriptionChange}
                  value={selectedDescription}
                  onKeyDown={handleKeyDownDesc}
                />
              </div>
            </div>
          )}
        </>
      )}

      {image && (
        <div className='flex justify-center gap-4 my-2'>
          <SchedulaModal
            handleSaveDraftClick={handleSaveDraftClick}
            isSavingDraft={isSavingDraft}
          />
        </div>

      )}
    </div>
  );
}

