import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { baseUrl } from '../../../../../Utility/Api/Urls';
import { useNavigate } from 'react-router-dom';
import { getUserData, getUserToken } from "../../../../../Utility/getUserData";

const gray = {
  50: "#647487",
};

const UseService = () => {
  const notify = () => {
    toast.info("Changes might take up to 3 days!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const axiosGetURL = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/labels`;
  const axiosPostUrl = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/labels`;
  const [attributeValues, setAttributeValues] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataName, setDataName] = useState("");
  const navigate = useNavigate();

  const [isChanged, SetChange] = React.useState(false)
  useEffect(() => {
    try {
      const userData = getUserData();
      const token = userData?.token;

      if (!userData.socialAccounts.gmbLocationResourceIdentifier) {
        navigate("/dashboard/account/general-settings");
      }
      axios
        .get(axiosGetURL, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (Array.isArray(res.data.attributesValue))
            setAttributeValues(res.data.attributesValue);
          setDataName(res.data.name);
          setIsLoading(false);
        });
    } catch (error) {
      setErrors(true);
      console.error(error);
    }
  }, [axiosGetURL]);

  const handleSubmit = (event) => {
    const token = getUserToken();
    event.preventDefault();
    //patch api
    axios
      .patch(
        axiosPostUrl,
        {
          attributesValue: attributeValues,
          name: dataName,
          eTag: null,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => console.log("response", res))
      .catch((err) => {
        console.error(err);
        setErrors(true);
      });

    console.log({ attributesValue: attributeValues });
    notify();
  };

  const handleChange = (event) => {
    !isChanged && SetChange(true)
    setAttributeValues(
      attributeValues.map((element) =>
        element["name"] === event.target.name
          ? { ...element, values: [event.target.checked] }
          : element
      )
    );
  };
  // console.log(attributeValues);

  return (

    <form
      className="bg-white grid grid-cols-2 xsm:flex xsm:flex-col sm:flex sm:flex-col xmd:flex xmd:flex-col md:flex md:flex-col p-3 items-center justify-center"
      onSubmit={(e) => handleSubmit(e)}
      id="informazioni-aggiuntive"
    >
      {
        /*if data is still not received render progress*/
        isLoading ? (
          <div className="col-span-2 w-full items-center flex justify-center" >
            <CircularProgress />
          </div>
            
        ) : (
          /*the values render only if there is no error */
          !errors &&
          attributeValues.map((attributeValue, idx) => (
            <div
              className="flex flex-col w-full"
              key={idx}
            >
              <label className="font-semibold text-left">
                {attributeValue.name && (
                  <span>
                    {attributeValue.name}
                  </span>
                )}
              </label>
              <div className="flex  flex-row xsm:flex-col sm:flex-col ">
                {/*if attribute.values exists render form control label*/}
                {attributeValue.values && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ color: gray[50] }}
                        checked={
                          attributeValue.values[0]
                            ? attributeValue.values[0]
                            : false
                        }
                        onChange={(e) => handleChange(e)}
                        name={attributeValue.name}
                        //link value to the current attributeVale.value[0]
                        value={attributeValues
                          .filter(
                            (attribute) =>
                              attribute.name === attributeValue.name
                          )
                          .map(
                            (filteredAttribute) =>
                              filteredAttribute.values[0]
                          )}
                      />
                    }
                    label={attributeValue.name}
                    className="text-slate-500 font-semibold text-left lowercase"
                  />
                )}
              </div>
            </div>
          ))
        )
      }

      {isChanged &&
        <div className="mt-8 flex justify-center col-span-2">
          <button type="submit" className="w-40 h-12 rounded-md bg-primarybtn elevation-1 text-white border-primarybtn hover:bg-primarybtnhover hover:border-primarybtnhover active:shadow-innerbig active:border-white" form="form-user-info">
            Salva
          </button>
        </div>
      }

    </form>
  );
};

export default UseService;
