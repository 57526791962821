import { useEffect, useState } from "react";
import * as service from "../apis/buyerPersonaService";

export default function useOtherPersonas({ onLoad } = {}) {
  const [otherpersonasisLoading, setIsLoading] = useState(false);
  const [otherpersonas, setPersonas] = useState();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const data = await service.getOtherPersonas();
      if (data) {
        setPersonas(data);
        onLoad && onLoad(data);
      }

      setIsLoading(false);
    })();
  }, []);

  return [otherpersonas, otherpersonasisLoading];
}
