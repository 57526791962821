import React from 'react'
import ReservationCard from './ReservationCard'
import ModalOrderDetailsPopup from './ModalOrderDetailsPopup'
import * as ThemeCustomerAPI from "../../../../../Utility/Api/ThemesCustomerAPI";
import "./Prenotazioni.css";


function Prenotazioni({ customer }) {
    const [customerBookings, SetBookings] = React.useState([])
    const [BookingsProducts, SetProducts] = React.useState([])
    const [isTheLoadingCard, SetLoadingCard] = React.useState(null)
    const [isLoading, SetLoading] = React.useState(true)
    const [open2, setOpen2] = React.useState(false);
    const handleOpeOrdernModal = () => setOpen2(true);
    const handleCloseOrderModal = () => setOpen2(false);


    async function getCustomerBookings() {
        try {
            const res = await ThemeCustomerAPI.getBookingsForCustomer(customer.email)
            SetBookings(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    React.useEffect(() => { getCustomerBookings() }, [])




    async function getBookings(bookingID) {
        const singleBooking = customerBookings.filter((item) => item._id === bookingID)
        SetProducts(singleBooking[0].products)
        handleOpeOrdernModal()
    }
    return (
        <>
            <div className="flex flex-col items-center w-full h-full" >
                {customerBookings.length === 0 && <p className="text-lg text-black font-semibold">Nessuna Prenotazione Effettuata</p>}
                <div className="ReservationDetailsCards w-full content-center gap-4 justify-items-center" >
                    {customerBookings?.map((item) => (
                        <ReservationCard
                            getBookings={getBookings}
                            item={item}
                            isLoading={isLoading}
                            isTheLoadingCard={isTheLoadingCard}
                        />
                    ))}
                </div>
            </div>
            {BookingsProducts &&
                <ModalOrderDetailsPopup
                    handleCloseOrderModal={handleCloseOrderModal}
                    ModalState={open2}
                    products={BookingsProducts}
                />}
        </>
    )
}

export default Prenotazioni
