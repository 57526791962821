import { TextField } from "@mui/material";
import { useContext } from "react";

import { MarketingCampaignContext } from "../../../../../../Contexts/MarketingCampaignContext";

export default function MarketingTimePickers() {
  const { startDate, endDate, handleStartDateChange, handleEndDateChange } =
    useContext(MarketingCampaignContext);

  const handleFormStartDateChange = e => handleStartDateChange(e.target.value);
  const handleFormEndDateChange = e => handleEndDateChange(e.target.value);

  return (
    <>
      <div className="flex">
        <TextField
          onChange={handleFormStartDateChange}
          id="datetime-local"
          label="Data di Inizio"
          type="datetime-local"
          value={startDate}
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <div className="flex">
        <TextField
          onChange={handleFormEndDateChange}
          id="datetime-local"
          label="Data di Fine"
          type="datetime-local"
          value={endDate}
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </>
  );
}
