import React, { useEffect } from 'react'
import Carousel from "react-multi-carousel";
import CreateCampCard from './CreateCampCard';
import "react-multi-carousel/lib/styles.css";
import ChooseCampCard from './ChooseCampCard';
import BackArrow from '../../../../Assets/Icons/BackArrow.svg';
import { useNavigate } from "react-router-dom"
import MarketingHeaderLottie from "../../../../Assets/Lottie/Marketing.json";
import { ButtonGroup, CampaignTypes, responsive, buyerPersonas } from "./Imports"
import CampModal from './CampModal';
import CreditModal from './CreditModal';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner')).default;
}
if (process.env.REACT_APP_PROJECT === 'RC') {
  InformationTopBanner = (await import ('../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner')).default;
}


const data = [1, 2, 3, 4, 5, 7, 6]
function CreateCampaign() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const navigate = useNavigate();
    
    const [buyerPersonaData, setBuyerPersonaData] = React.useState(null);

    // get buyer persona from data science
    useEffect(() => {
        const receivedPersona = buyerPersonas[0];
        setBuyerPersonaData(receivedPersona);
    }, []);

    const handleGoalSelect = goalData => 
        navigate(
            '/marketing/create-campaigns/choose-template', 
            { state: { goalData, buyerPersonaData } }
        );
        
    return (

        <div className="flex flex-col w-full">

            <InformationTopBanner
    
            pagetype="Sezione"
            sectionname="Pubblicità"
            description={process.env.REACT_APP_PROJECT === 'Unifarco' ? 'La tua farmacia' : 'Il tuo ristorante'}
            sectionicon={MarketingHeaderLottie}
    
            />

            <CampModal open={open} handleClose={handleClose} />

            <div className="flex flex-col gap-y-3 w-full justify-center content-center items-center mt-4" >

                <div className="flex flex-row items-center justify-between w-[95%] mx-auto">


                    <button
                        className="bg-[#3366BB]  py-2 my-3 px-8 h-fit rounded-md mr-auto ml-5 text-white flex flex-row items-center gap-x-1"
                        onClick={() => navigate("/marketing/completed-campaigns")}>
                        <img src={BackArrow} alt="Back Arrow" /> Back
                    </button>
                    <CreditModal open={open} handleClose={handleClose} />
                </div>

                <div className="relative px-5  w-[95%] self-center">
                    <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold">Suggested Goal</p>
                    <p className='text-[#AEAEAE] text-left font-normal px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>
                    <Carousel
                        additionalTransfrom={0}
                        autoPlay={false}
                        autoPlaySpeed={9999999999}
                        arrows={false}
                        centerMode={false}
                        draggable={false}
                        infinite={false}
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        responsive={responsive}
                        focusOnSelect={false}
                        slidesToSlide={1}

                    >
                        {data.map((item) => {
                            return (
                                <div className='py-10'>

                                    <CreateCampCard handleOpen={handleOpen} />
                                </div>
                            )
                        })}

                    </Carousel>

                </div>


                <div className="relative px-2   w-[1130px] xl:w-[1400px] md-[900px]">

                    <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold">Scegli una Campagna tra le altre </p>
                    <p className='text-[#AEAEAE] text-left font-normal px-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi scelerisque elit laoreet condimentum imperdiet. Mauris at libero pretium, commodo mauris eu, iaculis erat. In vitae pharetra erat.</p>

                    <Carousel
                        additionalTransfrom={0}
                        autoPlay={false}
                        autoPlaySpeed={9999999999}
                        arrows={false}
                        centerMode={false}
                        draggable={false}
                        infinite={false}
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                        responsive={responsive}
                        focusOnSelect={false}
                        slidesToSlide={1}

                    >

                        {CampaignTypes.map(goal => (
                            <div key={goal.value} className='py-10'>
                                <ChooseCampCard key={goal.value} data={goal} onSelect={handleGoalSelect} />
                            </div>
                        ))}

                    </Carousel>

                </div>

            </div>

        </div>
    )
}

export default CreateCampaign