import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import WalletIcon from "../../../../Assets/Icons/wallet.png";
import CreditCard from "../../../../Assets/Icons/creditCard.svg";
import Paypal from "../../../../Assets/Icons/PayPal.svg";
import Bonifico from "../../../../Assets/Icons/Bonifico.svg";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";

import * as creditService from "../../../../apis/creditService";

function CreditModal({ balance }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // use state to handel the input value
  const [showText, setShowText] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  // this function to handel amount input
  const handleChange = (amount) => {
    setAmount(amount);
    showText && setShowText(false);
  };
  const handleCustomPayment = (amount) => {
    setAmount(amount)
  }
  // handel paypal payment
  const handelPaymentMethod = async (e) => {
    setPaymentMethod(e);
  }


  // handel the submit button
  const handleSubmit = async (amount, e) => {
    try {
      // check if the e is PayPal or CreditCard
      if (e === "PayPal") {
        const credit = await creditService.generatePaypalLink(amount);
        window.open(credit, "_blank");

      }
      if (e === "card") {
        // call the credit service to create the credit
        const credit = await creditService.generateStripLink(amount, paymentMethod);
        window.open(credit, "_blank");
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 670,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "1rem",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "90vw",
    height: 600,
    borderRadius: "1rem",
  };
  return (

    <div className="w-full">

      <div className="relative w-full flex flex-row justify-between  h-fit p-4  bg-marketingbtn bg-opacity-[15%] rounded-md items-center elevation-1">


        <p className="text-[#6392FF] font-semibold text-xl">
          Credito disponibile:   {balance || 0} €
        </p>



        <button
          className="w-40 h-10 rounded-md elevation-1 text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-[#E8EFFF] active:shadow-innerbig"
          onClick={handleOpen}
        >
          Ricarica
        </button>

      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={window.innerWidth < 1200 ? style2 : style}>
          <button
            className=" col-span-5 absolute top-0 right-0 p-2 "
            onClick={() => {
              handleClose();
            }}
          >
            <GrFormClose size={30} className="rmv" />
          </button>

          <div className=" m-7 mt-4">
            <div className=" m-2">
              <div className=" m-2">
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold ">
                  Scegli un importo
                </p>
              </div>

              <div className=" m-2 grid grid-cols-3 gap-5">
                <button
                  // className="bg-white rounded-md w-48 text-marketingtextcolour border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white focus:bg-marketingbtn focus:text-white" old
                  className={`rounded-md w-48  ${amount === 20 && !showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => handleChange(20)}
                  value={20}
                >
                  20€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${amount === 50 && !showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => handleChange(50)}
                  value={50}
                >
                  50€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${amount === 80 && !showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => handleChange(80)}
                  value={80}
                >
                  80€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${amount === 100 && !showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => handleChange(100)}
                  value={100}
                >
                  100€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${amount === 200 && !showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => handleChange(200)}
                  value={200}
                >
                  200€
                </button>
                <button
                  className={`rounded-md w-48 border border-marketingbtn py-1 hover:bg-marketingbtnhover hover:text-white  ${showText
                      ? "bg-marketingbtn text-white elevation-1"
                      : "text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
                    }`}
                  onClick={() => {
                    setShowText(!showText);
                    handleChange(null);
                  }}
                >
                  Personalizza
                </button>
                {showText && (
                  <div className=" ">
                    <div>
                      <TextField
                        id="standard-password-input"
                        label="Personalizza Importo"
                        type="number"
                        variant="standard"
                        helperText="Minimo 20€,massimo 200€"
                        onChange={(e) => handleCustomPayment(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" m-2">
              <div className=" m-2">
                <p className="text-marketingtextcolour text-2xl text-left p-4 font-semibold ">
                  Scegli un metodo di pagamento
                </p>
              </div>

              <div className=" m-2 gap-5 flex flex-row justify-center">
                <button
                  // className="bg-white rounded-md w-44 text-marketingtextcolour border border-white hover:border-marketingbtnhover focus:border-marketingbtn py-1 " old
                  className={`w-44 cursor-default ${paymentMethod === "CreditCard" ? "border-marketingbtn" : "border-white"} py-1 `}
                  onClick={() => handelPaymentMethod("card")}
                >
                  <img
                    src={CreditCard}
                    alt=""
                    className="m-auto p-2  max-h-[50%]"
                  />
                  <p className="text-marketingtextcolour text-xs  p-4 ">
                    Carta di Credito
                  </p>
                </button>
                {/* comment the paypal button

                <button
                  className={`text-marketingtextcolour bg-white border-2 border-marketingbtn hover:text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig rounded-md w-44 border hover:border-marketingbtnhover ${paymentMethod==="Paypal"?"border-marketingbtn":"border-white"} py-1 `}
                  onClick={() => handelPaymentMethod("PayPal")}
                >
                  <img src={Paypal} alt="" className="m-auto p-2 max-h-[50%]" />
                  <p className="text-marketingtextcolour text-xs  p-4 ">Paypal</p>
                </button>
                */}

              </div>
            </div>

            <div className=" m-2  justify-center flex">
              <button
                className="w-40 h-10 py-1 my-4 rounded-md elevation-1 text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig"
                onClick={() => handleSubmit(amount, paymentMethod)}
              >
                Paga
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CreditModal;
