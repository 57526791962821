import React from 'react'
import { Checkbox } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import { CgEnter } from "react-icons/cg"
import { MdDelete } from "react-icons/md"
import DeleteAlertButton from "../../../Reusable/DeleteAlert/DeleteAlertButton";
import Avatar1 from "../../../Assets/CustomersRandomAvatars/Frame 11.jpg"
import Avatar2 from "../../../Assets/CustomersRandomAvatars/Frame 12.jpg"
import Avatar3 from "../../../Assets/CustomersRandomAvatars/Frame 13.jpg"
import Avatar4 from "../../../Assets/CustomersRandomAvatars/Frame 14.jpg"
import Avatar5 from "../../../Assets/CustomersRandomAvatars/Frame 15.jpg"
import Avatar6 from "../../../Assets/CustomersRandomAvatars/Frame 16.jpg"
import Avatar7 from "../../../Assets/CustomersRandomAvatars/Frame 17.jpg"
import Avatar8 from "../../../Assets/CustomersRandomAvatars/Frame 18.jpg"
import Avatar9 from "../../../Assets/CustomersRandomAvatars/Frame 19.jpg"
import Avatar10 from "../../../Assets/CustomersRandomAvatars/Frame 20.jpg"
import Avatar11 from "../../../Assets/CustomersRandomAvatars/Frame 21.jpg"
import Avatar12 from "../../../Assets/CustomersRandomAvatars/Frame 22.jpg"
import moment from 'moment/moment';

function OrderCard({ customer, handleOpen, handleDeleteCustomer }) {
    const CustomColorCheckbox = withStyles({
        root: {
            color: "#00B27A",
            "&$checked": {
                color: "#00B27A"
            }
        },
        checked: {}
    })((props) => <Checkbox color="default" {...props} />);

    const [isNotCard, SetNotCard] = React.useState(false)
    const randomAvatars = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8, Avatar9, Avatar10, Avatar11, Avatar12]
    function randomIntFromInterval() { // min and max included 
        return Math.floor(Math.random() * (randomAvatars.length - 1))
    }

    const [checked, SetCheck] = React.useState(true)
    const [theRandomNumber, SetNumber] = React.useState()
    React.useEffect(() => {
        SetNumber(randomIntFromInterval())
    }, [])
    return (
        <div
            onMouseEnter={() => SetNotCard(false)}
            onMouseLeave={() => SetNotCard(true)}
            //onClick={() => !isNotCard && handleOpen(!isNotCard && customer?.email)}
            className={`w-[95%] relative flex flex-col  elevation-1 hover:cursor-pointer hover:ring-2 hover:ring-primarybtnhover  mx-auto rounded-md h-[180px] min-h-[180px]  bg-white`} >

            {/*

            <div
                onMouseEnter={() => SetNotCard(true)}
                onMouseLeave={() => SetNotCard(false)}
                className='absolute top-0 right-0 m-1 z-10' >
                <CustomColorCheckbox
                    onChange={() => SetCheck(old => !old)}
                    checked={checked}
                    // defaultChecked
                />
            </div>
            
            */}

            <div className="h-[40%] min-h-[40%] w-full bg-gray-100 flex flex-row items-center gap-x-2 p-2 overflow-x-hidden rounded-t-md" >

                <img src={randomAvatars[customer?.img || theRandomNumber]} alt="" className='bg-blue-200 rounded-lg h-[90%] my-auto w-[50px] min-w-[50px]' />

                <div className="flex flex-col" >

                    <p className='font-semibold text-primarytextcolour text-left capitalize text-base'>{customer?.firstName} {customer?.lastName}</p>
                    <p className='font-normal text-black text-left text-base'>{customer?.email}</p>

                </div>

            </div>

            <div className="flex flex-col justify-start p-2" >

                <p className='text-left items-center text-primarytextcolour font-normal sm:text-sm xsm:text-sm'>Tel: <span className='text-black ml-1 text-xs'>{customer?.phoneNumber ? customer?.phoneNumber : "Non fornito"}</span></p>
                <p className='text-left items-center text-primarytextcolour font-normal sm:text-sm xsm:text-sm '>Data di nascita: <span className='text-black ml-1 text-xs'>{customer?.dob ? moment(customer?.dob).format("DD/MM/YYYY") : "Non disponibile"}</span></p>
                <p className='text-left items-center text-primarytextcolour font-normal sm:text-sm xsm:text-sm '>Tag: <span className='text-black ml-1 text-xs'> {customer?.tags?.map((item) => (`#${item} `))}</span></p>

                {/*
                
                <p className='text-left items-center text-primarytextcolour font-normal sm:text-sm xsm:text-sm'>Fidelity card:<span className='text-black ml-1 text-xs'>Si</span></p>
                
                */}
            </div>

            <div className='flex flex-row gap-x-1 absolute bottom-0 right-0 m-1 ' >
                <DeleteAlertButton
                    message="Questo cliente verrà eliminato definitivamente!"
                    onConfirm={() => handleDeleteCustomer(customer?.id)}
                    renderButton={props => (
                        <button
                            {...props}
                            onMouseEnter={() => SetNotCard(true)}
                            className="z-50" >
                            <MdDelete size={25} color="#BE4E4E" />
                        </button>
                    )}
                ></DeleteAlertButton>
            </div>
        </div>

    )
}

export default OrderCard