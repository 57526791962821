import axios from "axios";
import React, { useEffect, useState } from "react";
import setUserData from "../../../../../../Utility/setUserData";
import Loader from "../../../../../../Reusable/Loader/Loader";
import SuccessModal from "./SuccessModal";
import VerificationModal from "./VerificationModal";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import { getUserData } from "../../../../../../Utility/getUserData";
import DeleteAlertButton from "../../../../../../Reusable/DeleteAlert/DeleteAlertButton";
import GMBiCon from "../../../../../../Assets/Icons/GMPicon.png"
export default function Gmb() {
  const userData = getUserData();
  const userToken = userData?.token;

  const [claimedStatus, setClaimedStatus] = useState("");
  const [status, setStatus] = useState(false);
  const [gmbVerificationData, setGmbVerificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSucess, setOpenSucess] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenSucess = () => setOpenSucess(true);
  const handleClose = () => setOpen(false);
  const handleCloseSucess = () => setOpenSucess(false);
  const config = { headers: { Authorization: `Bearer ${userToken}` } };


  async function CheckLocationGMB() {
    try {
      const res = await axios.get(`https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/gmb/status`, config);

      if (res.data.Message.includes("locations")) {
        userData.socialAccounts.gmbLocationResourceIdentifier =
          res.data.Message;
        setUserData(userData);
        setStatus(true);
      }

      if (res.data.Message === "user is diconnected from gmb service") {
        userData.socialAccounts.gmbLocationResourceIdentifier = null
        setUserData(userData);
        setStatus(false);
      }
    } catch (error) {
      setStatus(false);
      console.error("error detected", error);
    }
  }


  // async function handleLogin() {
  //   setIsLoading(true);

  //   try {
  //     const res = await axios.get(
  //       `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH
  //       }/gmb/connect`,
  //       config
  //     );
  //     if (res.data.Message.includes("locations")) {
  //       userData.socialAccounts.gmbLocationResourceIdentifier =
  //         res.data.Message;
  //       setUserData(userData);
  //       setStatus(true);
  //     }
  //     console.log(res);
  //   } catch (error) {
  //     UnclaimedVerification();
  //     console.log(error);
  //   }
  //   // if (userData.socialAccounts.gmbPendingVerification == 'CLAIMED') {

  //   //   setStatus(true);
  //   //   setClaimedStatus("claimed")
  //   // }
  //   // else {
  //   //   setStatus(false);
  //   //   setClaimedStatus("unclaimed")
  //   // }

  //   setIsLoading(false);
  // }

  async function disConnectGMP() {
    setStatus(false);
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH
        }/gmb/disconnect`,
        config
      );
      console.log("res", res);
      if (res.status === 200) {
        userData.socialAccounts.gmbLocationResourceIdentifier = null;
        setUserData(userData);
        setStatus(false);
      }
    } catch (error) {
      console.err("error is", error);
    }
  }

  async function UnclaimedVerification() {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH
        }/gmb/InitGoogleMyBusinessReclamation`,
        config
      );
      if (res.status === 200) {
        //setGmbVerificationData(res.data.options[0])
        //handleOpen()
        setStatus(true);
      }
    } catch (error) {
      console.err("error is", error);
    }
  }

  useEffect(() => {
    CheckLocationGMB();
  }, []);

  useEffect(() => {
    if (userData?.socialAccounts?.gmbLocationResourceIdentifier)
      setStatus(true);
    else setStatus(false);
  }, []);

  useEffect(() => {
    if (claimedStatus === "unclaimed") UnclaimedVerification();
    else if (claimedStatus === "claimed") {
      handleOpenSucess();
    }
  }, [claimedStatus, status]);

  const ConnectButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn hover:bg-primarybtnhover active:shadow-innerbig";

  const RedButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-deletebtn hover:bg-deletebtnhover active:shadow-innerbig";

  return (
    <div className="bg-gradient-to-r from-[#3A5989] to-[#6E99FD] w-full rounded-md flex flex-row justify-between px-4 py-2  items-center">
      {open && <VerificationModal
        open={open}
        handleClose={handleClose}
        Gmbdata={gmbVerificationData}
        userData={userData}
      />}
      {openSucess && <SuccessModal open={openSucess} handleClose={handleCloseSucess} />}
      <div className="flex flex-row gap-x-4 items-center">
        <img src={GMBiCon} alt="" />
        <p className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px]">
          Google MyBusiness
        </p>
      </div>

      {!isLoading && status ?
        <DeleteAlertButton
          message="Sei sicuro?"
          subTitle="Questo ti farà disconnettere"
          onConfirm={() => disConnectGMP()}
          renderButton={props => (
            <button
              {...props}
              className={RedButton}>
              Disconnetti
            </button>
          )}
        />
        :
        <button className={`${ConnectButton}`} onClick={() => handleOpen()}>
          Connetti
        </button>
      }

      {isLoading && (
        <div >
          <Loader />
        </div>
      )}


    </div>
  );
}
