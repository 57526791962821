import axios from "axios";
import { baseUrl } from "../../../../../../Utility/Api/Urls";
import { getAuthConfig } from "../../../../../../Utility/Api/auth";

const base = `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${
  process.env.REACT_APP_AUTH_API_PATH
}`;

const FacebookService = {
  async getFBToken(mainResponse) {
    return await axios.get(
      `${base}/socials/facebook/exchangeToken?token=${mainResponse.accessToken}`,
      getAuthConfig(),
    );
  },

  async getFacebookePages(mainResponse) {
    let { data } = await axios.get(
      `https://graph.facebook.com/${mainResponse.userID}/accounts?access_token=${mainResponse.accessToken}`,
      getAuthConfig()
    );
    return data.data;
  },

  async handlePageChoose(id, fbTokenn) {
    const pageId = parseInt(id);
    let { data } = await axios.get(
      `${base}/socials/facebook/pageAccessToken?pageId=${pageId}&token=${fbTokenn}`,
      getAuthConfig()
    );
    return data;
  },

  async FacebookPageToken(fbTokenn) {
    let { data } = await axios.patch(
      `${base}/user`,

      { data: { "socialAccounts.facebookAuthLongLiveToken": fbTokenn } },
      getAuthConfig()
    );
    return data;
  },
  async Logout() {
    let { data } = await axios.get(
      `${base}/socials/facebook/removefacebook`,
      getAuthConfig()
    );
    return data;
  },
};

export default FacebookService;
