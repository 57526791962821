import React from 'react'
import { AiOutlineCheck, AiOutlineEye } from "react-icons/ai"
import Backbutton from '../../../../../Reusable/Back-Button/Back-Button'
import DashNavigation from '../../DashNavigation/DashNavigation'
import "../Account.css"

function IndexFatture() {
    return (
        <div id="Fatture" className='flex flex-col gap-y-8 w-full' >

            <div className='flex my-3 xsm:mx-auto sm:mx-auto md:mx-auto'>
    
            <DashNavigation />
    
            </div>

            <div className='flex w-1/4 ml-10 mb-6 xsm:w-full sm:w-full md:w-full xsm:ml-0 sm:ml-0 md:ml-0 '>

                <Backbutton />

            </div>
            <div className="w-[95%] mx-auto">

                <table >
                    <tr>
                        <th className="font-semibold text-black">Data di Emissione</th>
                        <th className="font-semibold text-black">Dettagli</th>
                        <th className="font-semibold text-black">Prodotto/i</th>
                        <th className="font-semibold text-black">Importo</th>
                        <th className="font-semibold text-black">Stato</th>
                        <th className="font-semibold text-black">Azioni</th>
                    </tr>
                    <tr>
                        <td className='text-[#7D8398]'>10 gennaio 2023</td>
                        <td className='text-[#7D8398]'>Fattura n.103456781</td>
                        <td className='text-[#7D8398]'>Pagamento trimestrale abbonamento</td>
                        <td className='text-[#7D8398]'>€ 900,00</td>
                        <td>
                            <div className="rounded-xl text-[#91C561] bg-[#91C5611F] w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineCheck size={15} />
                                Elaborato
                            </div>

                        </td>
                        <td>
                            <button className="rounded-md text-black bg-white elevation-1 w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineEye size={15} />
                                Mostra
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className='text-[#7D8398]'>10 gennaio 2023</td>
                        <td className='text-[#7D8398]'>Fattura n.103456781</td>
                        <td className='text-[#7D8398]'>Pagamento trimestrale abbonamento</td>
                        <td className='text-[#7D8398]'>€ 900,00</td>
                        <td>
                            <div className="rounded-xl text-[#91C561] bg-[#91C5611F] w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineCheck size={15} />
                                Elaborato
                            </div>

                        </td>
                        <td>
                            <button className="rounded-md text-black bg-white elevation-1 w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineEye size={15} />
                                Mostra
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className='text-[#7D8398]'>10 gennaio 2023</td>
                        <td className='text-[#7D8398]'>Fattura n.103456781</td>
                        <td className='text-[#7D8398]'>Pagamento trimestrale abbonamento</td>
                        <td className='text-[#7D8398]'>€ 900,00</td>
                        <td>
                            <div className="rounded-xl text-[#91C561] bg-[#91C5611F] w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineCheck size={15} />
                                Elaborato
                            </div>

                        </td>
                        <td>
                            <button className="rounded-md text-black bg-white elevation-1 w-[120px] h-8 flex flex-row items-center justify-evenly text-xs " >
                                <AiOutlineEye size={15} />
                                Mostra
                            </button>
                        </td>
                    </tr>

                </table>
            </div>


        </div>
    )
}

export default IndexFatture