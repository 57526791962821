import React from 'react'
import SelectTemplate from "../../../../Reusable/BeeTemplateScheduling/SelectTemplate";
function TemplatesModal({ handleTemplateSelected, templatesToShow }) {


    return (
        <>
            {templatesToShow?.length > 0 ? (
                <SelectTemplate
                    onTemplateSelected={handleTemplateSelected}
                    templates={templatesToShow}
                />
            ) : (
                <p className="text-xl text-newslettertextcolour text-center mx-auto">
                    Non ci sono template.
                </p>
            )}
        </>
    )
}

export default TemplatesModal