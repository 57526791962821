import React from 'react';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';

//--------- Commons ---------//

import Marketingpage from '../Components/Commons/Marketing/Marketing';
import OnProgressCampaign from '../Components/Commons/Marketing/OnProgressCampaign/OnProgressCampaign';
import Completedcampaign from '../Components/Commons/Marketing/Completedcampaign/Completedcampaign';
import RisultatiCampagna from '../Components/Commons/Marketing/RisultatiCampagna/RisultatiCampagna';

import CreateMarketingCampaign from '../Components/Commons/Marketing/CreateCampaign/CreateCampaign';
import CreateMarketingCampaignOld from '../Components/Commons/Marketing/CreateCampaign-OLD/CreateCampaign';
import ChooseTemplate from '../Components/Commons/Marketing/CreateCampaign-OLD/ChooseTemplate/ChooseTemplate';

import CreateCampaignForm from '../Components/Commons/Marketing/CreateCampaign/CreateCampaignForm/CreateCampaignForm';

import Seo from '../Components/Commons/Seo/Index-Seo';

import Newsletter from '../Components/Commons/Newsletter/Index-Newsletter';
import Completednewsletter from '../Components/Commons/Newsletter/CompletedNewsletter/Completed-Newsletter';
import DraftNewsletter from '../Components/Commons/Newsletter/DraftNewsletter/DraftNewsletter';
import Newsletterreview from '../Components/Commons/Newsletter/NewsletterInsights/NewsletterReview/Index-Newsletter-Review';
import Newslettermailinglist from '../Components/Commons/Newsletter/MailingList/Mailing-List';
import SeeAllEmails from '../Components/Commons/Newsletter/NewsletterInsights/NewsletterReview/SeeAllEmails';
import MailAutomationMain from '../Components/Commons/Newsletter/MailAutomation/MailAutomationMain';
import SingleEmailAutomation from '../Components/Commons/Newsletter/MailAutomation/SingleEmailAutomation';
import MailAutomationTable from '../Components/Commons/Newsletter/MailAutomation/MailAutomationTable';

import CreateNewMail from '../Components/Commons/Newsletter/CreateNewMail/CreateNewMail';

import Socialmedia from '../Components/Commons/SocialMedia/Index-Social-Media';
import Socialmanager from '../Components/Commons/SocialMedia/SocialManager/Index-Social-Manager';
import Questionsandanswers from '../Components/Commons/SocialMedia/Questions-&-Answers/Index-Questions-and-Answers';
import CreatePost from '../Components/Commons/SocialMedia/CreatePost/CreateCampaign';
import UpdatePost from '../Components/Commons/SocialMedia/updatePost/updateCampaign';

import Chatbot from '../Components/Commons/Chatbot/Chatbot';

import Singleticket from '../Components/Commons/Support/Tickets/Singleticket';

import BuyerPersona from '../Components/Commons/BuyerPersona/Index-Buyer-Persona';
import BuyerPersonaType from '../Components/Commons/BuyerPersona/BuyerPersonaType/BuyerPersonaType';
import OtherPersona from '../Components/Commons/BuyerPersona/OtherTypes/OtherPersona';

import CustomerHistoryIndex from '../Components/Commons/Customers/Customers-index';
import CustomersMain from '../Components/Commons/Customers/CustomersMain';

import Errornotfound from '../Components/Commons/Errors/404';
import Chatmanager from '../Components/Commons/Chatbot/Chat-manager/Chat-manager';
import Chatmessages from '../Components/Commons/Chatbot/Chat-messages/Chat-messages';
import Chatlist from '../Components/Commons/Chatbot/Chat-list/Chat-list';

import IndexDiscounts from '../Components/Commons/Discounts/Index-Discounts';
import AllDiscounts from '../Components/Commons/Discounts/All-Discounts/Index-All-Discounts';
import DraftDiscounts from '../Components/Commons/Discounts/Draft-Discounts/Index-Draft-Discounts';
import CreateDiscounts from '../Components/Commons/Discounts/Create-Discounts/Index-Create-Discounts';
import ManageDiscountItem from '../Components/Commons/Discounts/Create-Discounts/ManageDiscountItems/Index-manage-discount';
import ProdcutsSelection from '../Components/Commons/Discounts/Create-Discounts/ProductSelection/Index-Product-Selection';
import DiscountInfo from '../Components/Commons/Discounts/Create-Discounts/Discounts-Info-Step/Index-Discounts-Info';
import HistoryDiscounts from '../Components/Commons/Discounts/History-Discounts/Index-Discounts-History';
import WebSiteTheme from '../Components/Commons/WebSiteTheme/Index-Theme';

import IndexRisultati from '../Components/RC/Dashboard/Risultati/results';
import Risultati from '../Components/RC/Dashboard/Risultati/Index-Risultati';
import Settings from '../Components/RC/Dashboard/Settings/Index-Settings';
import Subscription from '../Components/RC/Dashboard/Subscription/Index-Subscription';
import Fatture from '../Components/RC/Dashboard/Subscription/Fatture/Index-Fatture';
import Plans from '../Components/RC/Dashboard/Subscription/Plans/Index-Plans';
import Stripesuccess from '../Components/RC/Dashboard/Settings/Payments/Stripe Success/Stripe-Success';
import Stripefailure from '../Components/RC/Dashboard/Settings/Payments/Stripe Failure/Stripe-Failure';
import SitoPage from '../Components/Commons/WebSiteTheme/Sito/Sito';
import VisitatorePage from '../Components/Commons/WebSiteTheme/Visitatore/Visitatore';

import Reviews from '../Components/Commons/Reviews/index';
import Commentsandreviews from '../Components/Commons/Reviews/Comments-&-Reviews/Index-Comments-and-Reviews';
import Attract from '../Components/Commons/Attract/attract';
import Trend from '../Components/Commons/Attract/trend/trend';
import ChooseDiscount from '../Components/RC/Fidelity/CreateCoupon/ChooseDiscount';
import FidelityScore from '../Components/RC/Fidelity/FidelityScore/FidelityScore';
import Competitor from '../Components/Commons/Attract/competitor/competitor';
import GraphicEditor from '../Components/Commons/GraphicEditor-NEW/graphiceditor';
import HelpRequests from '../Components/Commons/SupportSuperAdmin/HelpRequests';
import Chat from '../Components/Commons/SupportSuperAdmin/Chat';
import EditorProvider from '../Contexts/editor/editorProvider';
import ActiveDiscounts from '../Components/Commons/Discounts/Active-Discounts/index-Disccounts-Active';
import CreateDiscountModal from '../Components/Commons/Discounts/Create-Discounts/CreateDiscountModal';

import CustomerDetailsPage from '../Components/Commons/Customers/CustomerDetailsPage';

let Dashboard = null;
let Products = null;
let Submenu = null;
let Prod = null;
let Delivery = null;
let Pendingorders = null;
let Ordershistory = null;

//--------- End Commons ---------//

//--------- Unifarco ---------//

let Createanewservice = null;
let Services = null;
let Agenda = null;
let PickUp = null;
let Appointments = null;
let Pickuppendingorders = null;
let Pickupordershistory = null;
let Profileprogress = null;
let Medalpageprogress = null;
let Trophypageprogress = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
    Dashboard = (
        await import('../Components/Unifarco/Dashboard/Index-Dashboard')
    ).default;
    Products = (await import('../Components/Unifarco/Products/Index-Products'))
        .default;
    Submenu = (
        await import('../Components/Unifarco/Products/Submenu/Subproducts')
    ).default;
    Prod = (await import('../Components/Unifarco/Products/Items/Items'))
        .default;
    Delivery = (await import('../Components/Unifarco/Delivery/Index-Delivery'))
        .default;
    Pendingorders = (
        await import(
            '../Components/Unifarco/Delivery/Pending-Orders/Pendingorders'
        )
    ).default;
    Ordershistory = (
        await import(
            '../Components/Unifarco/Delivery/Orders-History/Ordershistory'
        )
    ).default;

    PickUp = (await import('../Components/Unifarco/Pick-Up/Index-Pick-Up'))
        .default;
    Pickuppendingorders = (
        await import(
            '../Components/Unifarco/Pick-Up/Pending-Orders/Pickuppendingorders'
        )
    ).default;
    Pickupordershistory = (
        await import(
            '../Components/Unifarco/Pick-Up/Orders-History/Pickupordershistory'
        )
    ).default;
    Agenda = (await import('../Components/Unifarco/Agenda/Index-Agenda'))
        .default;
    Appointments = (
        await import(
            '../Components/Unifarco/Agenda/Appointments/Index-Appointments'
        )
    ).default;
    Services = (
        await import('../Components/Unifarco/Agenda/Services/Index-Services')
    ).default;
    Createanewservice = (
        await import(
            '../Components/Unifarco/Agenda/Create-a-New-Service/Index-Create-a-New-Service'
        )
    ).default;
    Profileprogress = (
        await import(
            '../Components/Unifarco/Dashboard/Gamification/Profile-Progress/Profile-Progress'
        )
    ).default;
    Medalpageprogress = (
        await import(
            '../Components/Unifarco/Dashboard/Gamification/Profile-Progress/Medals/Medals-Page'
        )
    ).default;
    Trophypageprogress = (
        await import(
            '../Components/Unifarco/Dashboard/Gamification/Profile-Progress/Trophy/Trophy-Page'
        )
    ).default;
}

//--------- End Unifarco ---------//

//--------- Restaurants Club ---------//

let DeliverySettings = null;
let Reservation = null;
let ReservationIndexCalendar = null;
let ReservationTables = null;
let Fidelity = null;
let CreateCoupon = null;
let FidelityLevels = null;
let DeliverySystemMail = null;
let ReservationSystemMail = null;
let ConfirmedBookings = null;
let SingleEmailAutomationDelivery = null;
let SingleEmailAutomationReservation = null;

if (process.env.REACT_APP_PROJECT === 'RC') {
    Dashboard = (await import('../Components/RC/Dashboard/Index-Dashboard'))
        .default;
    Products = (await import('../Components/RC/Products/Index-Products'))
        .default;
    Submenu = (await import('../Components/RC/Products/Submenu/Subproducts'))
        .default;
    Prod = (await import('../Components/RC/Products/Items/Items')).default;
    Delivery = (await import('../Components/RC/Delivery/Index-Delivery'))
        .default;
    DeliverySystemMail = (
        await import(
            '../Components/RC/Delivery/DeliverySystemMail/DeliverySystemMail'
        )
    ).default;

    SingleEmailAutomationDelivery = (
        await import(
            '../Components/RC/Delivery/DeliverySystemMail/SingleEmailAutomation'
        )
    ).default;
    SingleEmailAutomationReservation = (
        await import(
            '../Components/RC/Reservation/ReservationSystemMail/SingleEmailAutomation'
        )
    ).default;

    Pendingorders = (
        await import('../Components/RC/Delivery/Pending-Orders/Pendingorders')
    ).default;
    Ordershistory = (
        await import('../Components/RC/Delivery/Orders-History/Ordershistory')
    ).default;

    DeliverySettings = (
        await import(
            '../Components/RC/Delivery/DeliverySettings/DeliverySettings'
        )
    ).default;
    Reservation = (
        await import('../Components/RC/Reservation/Index-Reservation')
    ).default;
    ConfirmedBookings = (
        await import(
            '../Components/RC/Reservation/ConfirmedBookings/ConfirmedBookings'
        )
    ).default;
    ReservationIndexCalendar = (
        await import('../Components/RC/Reservation/Calendar/Index-Calendar')
    ).default;
    ReservationTables = (
        await import('../Components/RC/Reservation/Tables/Index-Tables')
    ).default;
    ReservationSystemMail = (
        await import(
            '../Components/RC/Reservation/ReservationSystemMail/ReservationSystemMail'
        )
    ).default;
    Fidelity = (await import('../Components/RC/Fidelity/Index-Fidelity'))
        .default;
    CreateCoupon = (
        await import('../Components/RC/Fidelity/CreateCoupon/CreateCoupon')
    ).default;
    FidelityLevels = (
        await import('../Components/RC/Fidelity/FidelityLevels/FidelityLevels')
    ).default;
}
//--------- End Restaurants Club ---------//

const currentPlan = localStorage.getItem('currentPlan');

export const exclusive = {
    Unifarco: [
        'dashboarduf',
        'gamification',
        'productsuf',
        'deliveryuf',
        'pickupuf',
        'agendauf',
    ],

    RC: [
        'dashboardrc',
        'productsrc',
        'deliveryrc',
        'reservationrc',
        'fidelityrc',
    ],
};

const commons = () => (
    <>
        <Route
            exact
            path='/'
            element={<Navigate to='/dashboard/account/results' />}
        />

        <Route
            path='/dashboard'
            element={<Dashboard />}
        >
            <Route
                path='/dashboard/account/results'
                element={<IndexRisultati />}
            />
            <Route
                path='/dashboard/account/general-settings'
                element={<Settings />}
            />
            <Route
                path='/dashboard/account/subscription'
                element={<Subscription />}
            />
            <Route
                path='/dashboard/account/subscription/invoces'
                element={<Fatture />}
            />
            {/* <Route path="/dashboard/account/subscription/plans" element={<Plans />} />
      <Route path="/dashboard/support" element={<HelpRequests />} />
      <Route path="/dashboard/support/single-chat/:id" element={<Chat />} /> */}
        </Route>

        <Route
            exact
            path='/products'
            element={<Products />}
        />
        <Route
            exact
            path='/products/:id'
            element={<Submenu />}
        />
        <Route
            exact
            path='/products/:id/:pid'
            element={<Prod />}
        />

        <Route
            path='/user-information/payments/stripe-success'
            element={<Stripesuccess />}
        />
        <Route
            path='/user-information/payments/stripe-failure'
            element={<Stripefailure />}
        />

        <Route
            path='/website'
            element={<WebSiteTheme />}
        >
            <Route
                path='/website/theme'
                element={<SitoPage />}
            />
            <Route
                path='/website/Visitatore'
                element={<VisitatorePage />}
            />
        </Route>

        {/* <Route path="/discounts" element={<IndexDiscounts />}>
      <Route path="/discounts/all" element={<AllDiscounts />} />
      <Route path="/discounts/active" element={<ActiveDiscounts />} />
      <Route path="/discounts/drafts" element={<DraftDiscounts />} />
      <Route path="/discounts/history" element={<HistoryDiscounts />} />
      <Route path="/discounts/create/:id?/:discountId?" element={<CreateDiscountModal />} />
      
      <Route
        path="/discounts/create/manage-discount-items"
        element={<ManageDiscountItem />}
      />
      <Route
        path="/discounts/create/productSelection"
        element={<ProdcutsSelection />}
      />
      <Route path="/discounts/create/DiscountInfo" element={<DiscountInfo />} />
    </Route> */}

        <Route
            path='/marketing'
            element={<Marketingpage />}
        >
            <Route
                path='/marketing/completed-campaigns'
                element={<Completedcampaign />}
            />
            <Route
                path='/marketing/inprogress-campaigns'
                element={<OnProgressCampaign />}
            />
            <Route
                path='/marketing/RisultatiCampagna'
                element={<RisultatiCampagna />}
            />

            <Route
                path='/marketing/create-campaigns'
                element={<CreateMarketingCampaign />}
            />

            <Route
                path='/marketing/create-campaigns/:goalName'
                element={<CreateCampaignForm />}
            />

            <Route
                path='/marketing/create-campaigns-old'
                element={<CreateMarketingCampaignOld />}
            />
            <Route
                path='/marketing/create-campaigns-old/choose-template'
                element={<ChooseTemplate />}
            />
            <Route
                path='/marketing/create-campaigns/camp-personlization'
                element={<CreateCampaignForm />}
            />
        </Route>

        <Route
            exact
            path='/seo'
            element={<Seo />}
        />

        <Route
            path='/buyer-persona'
            element={<BuyerPersona />}
        >
            <Route
                path='/buyer-persona/client-type'
                element={<BuyerPersonaType />}
            />
            <Route
                path='/buyer-persona/other-clients-types'
                element={<OtherPersona />}
            />
        </Route>

        <Route
            path='/customers'
            element={<CustomerHistoryIndex />}
        >
            <Route
                path='/customers/all-customers'
                element={<CustomersMain />}
            />
        </Route>

        <Route
            path='/newsletter'
            element={<Newsletter />}
        >
            <Route
                path='/newsletter/completed-newsletter'
                element={<Completednewsletter />}
            />
            <Route
                path='/newsletter/draft-newsletter'
                element={<DraftNewsletter />}
            />

            <Route
                path='/newsletter/newsletter-review/:id'
                element={<Newsletterreview />}
            />
            <Route
                path='/newsletter/seeAllEmails'
                element={<SeeAllEmails />}
            />
            <Route
                path='/newsletter/mailing-list'
                element={<Newslettermailinglist />}
            />
            <Route
                path='/newsletter/mail-automation'
                element={<MailAutomationMain />}
            />
            <Route
                path='/newsletter/mail-automation/single-email'
                element={<SingleEmailAutomation />}
            />
            <Route
                path='/newsletter/automails-list'
                element={<MailAutomationTable />}
            />

            <Route
                path='/newsletter/create-a-new-mail'
                element={<CreateNewMail />}
            />
        </Route>

        <Route
            path='/social-media'
            element={<Socialmedia />}
        >
            <Route
                path='/social-media/social-manager'
                element={<Socialmanager />}
            />
            <Route
                path='/social-media/questions-&-answers'
                element={<Questionsandanswers />}
            />
            <Route
                path='/social-media/create-a-new-post'
                element={<CreatePost />}
            />
            <Route
                path='/social-media/update-post'
                element={<UpdatePost />}
            />
        </Route>
        <Route
            path='/graphic-editor'
            element={<GraphicEditor />}
        />

        {/* <Route path="/reviews" element={<Reviews />}> */}
        {/* <Route path="/reviews/to-reply" element={<Commentsandreviews />} /> */}
        {/* <Route path="replied" element={<p>rispose</p>} /> */}
        {/* </Route> */}

        {/* <Route path="/competition" element={<Attract />}>
      <Route path="/competition/trend" element={<Trend />} />
      <Route path="/competition/competitor" element={<Competitor />} />
    </Route> */}

        {/* <Route path="/chatbot" element={<Chatbot />}>
      <Route path="/chatbot/chat-send" element={<Chatmanager />} />
      <Route path="/chatbot/chat-messages" element={<Chatmessages />} />
      <Route path="/chatbot/chat-list" element={<Chatlist />} />
    </Route>
   */}

        <Route
            path='*'
            element={<Errornotfound />}
        />
    </>
);

let additional = null;

if (process.env.REACT_APP_PROJECT === 'Unifarco') {
    additional = () => (
        <>
            <Route
                exact
                path='/gamification/profile-progress'
                element={<Profileprogress />}
            />

            <Route
                exact
                path='/gamification/profile-progress/medals'
                element={<Medalpageprogress />}
            />

            <Route
                exact
                path='/gamification/profile-progress/trophies'
                element={<Trophypageprogress />}
            />

            <Route
                path='/pick-up'
                element={<PickUp />}
            >
                <Route
                    path='/pick-up/pending-orders'
                    element={<Pickuppendingorders />}
                />
                <Route
                    path='/pick-up/orders-history'
                    element={<Pickupordershistory />}
                />
            </Route>

            <Route
                path='/agenda'
                element={<Agenda />}
            >
                <Route
                    path='/agenda/appointments'
                    element={<Appointments />}
                />
                <Route
                    path='/agenda/services'
                    element={<Services />}
                />
                <Route
                    path='/agenda/create-a-new-service'
                    element={<Createanewservice />}
                />
            </Route>

            {currentPlan === 'nodelivery' || currentPlan === 'none' ? (
                <Route
                    path='/delivery/*'
                    element={<Errornotfound />}
                />
            ) : (
                <Route
                    path='/delivery'
                    element={<Delivery />}
                >
                    <Route
                        path='/delivery/pending-orders'
                        element={<Pendingorders />}
                    />
                    <Route
                        path='/delivery/orders-history'
                        element={<Ordershistory />}
                    />
                </Route>
            )}
        </>
    );
}
if (process.env.REACT_APP_PROJECT === 'RC') {
    additional = () => (
        <>
            {currentPlan === 'nobooking' || currentPlan === 'none' ? (
                <Route
                    path='/reservation/*'
                    element={<Errornotfound />}
                />
            ) : (
                <Route
                    path='/reservation'
                    element={<Reservation />}
                >
                    <Route
                        path='/reservation'
                        element={<ReservationIndexCalendar />}
                    />
                    <Route
                        path='/reservation/confirmed'
                        element={<ConfirmedBookings />}
                    />
                    <Route
                        path='/reservation/tables'
                        element={<ReservationTables />}
                    />
                    <Route
                        path='/reservation/system-email'
                        element={<ReservationSystemMail />}
                    />
                    <Route
                        path='/reservation/system-email/single-email'
                        element={<SingleEmailAutomationReservation />}
                    />
                </Route>
            )}

            {/* <Route exact path="/fidelity" element={<Fidelity />} />

      <Route exact path="/fidelity/CreateCoupon" element={<CreateCoupon />} />
      <Route exact path="/fidelity/Level" element={<FidelityLevels />} />
      <Route
        exact
        path="/fidelity/Choose_discount"
        element={<ChooseDiscount />}
      /> */}
            {/* <Route exact path="/fidelity/Score" element={<FidelityScore />} /> */}

            {currentPlan === 'nodelivery' || currentPlan === 'none' ? (
                <Route
                    path='/delivery/*'
                    element={<Errornotfound />}
                />
            ) : (
                <Route
                    path='/delivery'
                    element={<Delivery />}
                >
                    <Route
                        path='/delivery/pending-orders'
                        element={<Pendingorders />}
                    />
                    <Route
                        path='/delivery/orders-history'
                        element={<Ordershistory />}
                    />
                    <Route
                        path='/delivery/delivery-settings'
                        element={<DeliverySettings />}
                    />
                    <Route
                        path='/delivery/system-email'
                        element={<DeliverySystemMail />}
                    />
                    <Route
                        path='/delivery/system-email/single-email'
                        element={<SingleEmailAutomationDelivery />}
                    />
                </Route>
            )}
        </>
    );
}

const Routing = () => (
    <Routes>
        {commons()}
        {additional()}
        <Route path="/customer/:id" element={<CustomerDetailsPage />} />
    </Routes>
);

export default Routing;
