import { isBefore } from "date-fns";
import format from "date-fns/format";
import { it } from "date-fns/locale";
import Joi from "joi";

const currentPlan = localStorage.getItem("currentPlan");


const cards = [
  {
    _id: "01",
    title: "Scarica l’app",
    offerType: "App_Review_Registration",
    describtion:
      "Qualcuno ha scaricato l'app del tuo locale? Crea un'offerta dedicata per dargli il benvenuto",
  },
  {
    _id: "02",
    title: "Registrazione",
    offerType: "App_Review_Registration",
    describtion:
      "Prepara un'offerta per ringraziare gli utenti che si sono registrati al sito del tuo locale",
  },
  {
    _id: "03",
    title: "Promo per Recensioni",
    offerType: "App_Review_Registration",
    describtion:
      "Prepara una promozione per ringraziare chi ha dedicato del tempo per lasciarti una recensione",
  },
  {
    _id: "04",
    title: "Ordine dal sito/App",
    offerType: "Book_Lunch_Dinner",
    describtion:
      "Offri una promozione esclusiva a chi sceglie di ordinare dal sito web o dall'app del tuo locale",
  },
  {
    _id: "05",
    title: "Spedizione Omaggio",
    discountType: "Free shipping",
    offerType: "Free_Shipping",
    describtion:
      "Pensa ad un'offerta che abbia come obiettivo quello di offrire la consegna gratuita dell'ordine",
  },
  {
    _id: "06",
    title: "Prenota pranzo/cena",
    offerType: "Book_Lunch_Dinner",
    describtion:
      "I tuoi clienti prenotano nel tuo locale per pranzo o per cena? Crea uno sconto adatto a loro",
  },
  {
    _id: "07",
    title: "Bundle",
    offerType: "Bundle",
    describtion:
      "Scegli un gruppo di prodotti, selezionali tu stesso, crea un'offerta: i clienti ne vanno matti!",
  },
  {
    _id: "08",
    title: "Menù Fisso",
    discountType: "Complimentary",
    offerType: "Set_Menu",
    describtion:
      "Proponi un menù fisso con piatti selezionati da te e prepara un'offerta dedicata ai tuoi clienti",
  },
  {
    _id: "09",
    title: "Sconto fisso con soglia",
    discountType: "Fixed",
    offerType: "Flat",
    describtion:
      "Imposta un prezzo soglia a partire dal quale offrirai uno sconto ai clienti applicato sul totale",
  },
];

export const discounts = [
  {
    _id: 1,
    name: "Bundle fisso",
    discountType: "Bundle",
    describtion: "Scegli prodotti o categorie, abbinali per creare offerte",
    example: "Un prodotto tra gli ",
    example2: "+ uno tra i ",
    example3: "(opzionale) = ",
  },
  {
    _id: 2,
    name: "Bundle più prodotto omaggio",
    discountType: "Bundle plus product",
    describtion: "Preseleziona prodotti o categorie e stabilisci un omaggio.",
    example: "Un prodotto tra gli ",
    example2: "+ uno tra i ",
    example3: "(opzionale) = ",
  },
  {
    _id: 3,
    name: "Prodotto omaggio con soglia",
    discountType: "Complimentary",
    describtion: "Stabilisci un prezzo soglia da raggiungere per l' omaggio",
    example: "Es. Se spendi 10 € ti regaliamo un caffè",
  },
  {
    _id: 4,
    name: "Sconto fisso con soglia",
    discountType: "Fixed",
    describtion: "Applica uno sconto fisso e indica la soglia per riceverlo",
    example: "",
  },
  {
    _id: 5,
    name: "Menù fisso",
    discountType: "Bundle",
    describtion: "Seleziona tu i prodotti e stabilisci un prezzo unico",
    example: "Es. Bruschette, pasta alle vongole, calamaro, macedonia = 25,00€",
  },
];

export const WeekDays = [
  {
    dayName: "Tutti i giorni",
    name: "all",
  },
  {
    dayName: "Ogni Lunedì",
    name: "monday",
  },
  {
    dayName: "Ogni Martedì",
    name: "tuesday",
  },
  {
    dayName: "Ogni Mercoledì",
    name: "wednesday",
  },
  {
    dayName: "Ogni Giovedì",
    name: "thursday",
  },
  {
    dayName: "Ogni Venerdì",
    name: "friday",
  },
  {
    dayName: "Ogni Sabato",
    name: "saturday",
  },
  {
    dayName: "Ogni Domenica",
    name: "sunday",
  },
];

export const DiscountsConditions = [
  {
    name: "Nessuna condizione",
  },
  {
    name: "Se ti registri",
  },
  {
    name: "Se scarichi l’APP",
  },
  {
    name: "Se ordini dal sito/APP",
    condition: currentPlan !== "nodelivery" && currentPlan !== "none",
  },
  {
    name: "Recensione 5 stelle",
  },
  {
    name: "Se prenoti a pranzo o cena",
    condition: currentPlan !== "nobooking" && currentPlan !== "none",
  },
  {
    name: "Se ti registri alla Newsletter",
  },
].filter((condition) => condition.condition !== false);



export const DatesType = [
  {
    name: "Inizio e fine",
    value:true
  },
  {
    name: "Solo Inizio",
    value:false
  },


];

export const CASE01_02_03 = (DiscountTypeID) =>
  DiscountTypeID === "01" || DiscountTypeID === "02" || DiscountTypeID === "03";
export const CASE04_06 = (DiscountTypeID) =>
  DiscountTypeID === "04" || DiscountTypeID === "06";
export const CASE07 = (DiscountTypeID) => DiscountTypeID === "07";
export const CASE05_10 = (DiscountTypeID) =>
  DiscountTypeID === "05" || DiscountTypeID === "10";
export const CASE08_09 = (DiscountTypeID) =>
  DiscountTypeID === "08" || DiscountTypeID === "09";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 1000 ? 600 : "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  background: "#FFFFFF",
  borderRadius: 5,
  height: window.innerWidth < 550 ? "95%" : "fit-content",
  overflowY: window.innerWidth < 550 && "scroll",
};

// ! DONT CHANGE THE ORDER OF THESE ARRAYS
const DiscountItalianNames = [
  "Menù Fisso",
  "Sconto con soglia",
  "Bundle",
  "Sconto Percentuale",
  "Prodotto omaggio con soglia",
];
const DiscountBEnames = [
  "FixedMenu",
  "DiscountWithThreshold",
  "Bundle",
  "Percentage",
  "FreeProduct",
];
export const DiscountsType = (name) => {
  const index = DiscountBEnames.findIndex((item) => item === name);
  return DiscountItalianNames[index];
};

export function handleActivePeriodText(startDate, endDate) {
  const StartDayNumber = format(new Date(startDate), "d", { locale: it });
  const StartItalianMonth = format(new Date(startDate), "MMMM", { locale: it });
  const StartItalianYear = format(new Date(startDate), "yyyy", { locale: it });

  const EndtDayNumber = format(new Date(endDate), "d", { locale: it });
  const EndItalianMonth = format(new Date(endDate), "MMMM", { locale: it });
  const EndItalianYear = format(new Date(endDate), "yyyy", { locale: it });


  return `Dal ${StartDayNumber} ${StartItalianMonth} , ${StartItalianYear} al ${EndtDayNumber} ${EndItalianMonth} , ${EndItalianYear}`;
}
export const isFinished = (eventEnd) =>
  isBefore(new Date(`${eventEnd}`), new Date());

export const ValidationScheme = (type) =>
  Joi.object({
    _id: Joi.allow(),
    value: Joi.allow(),
    validity: Joi.allow(),
    code: Joi.allow(),
    draft: Joi.allow(),
    offerType: Joi.allow(),
    spentMoney: Joi.allow(),
    usedBy: Joi.allow(),
    updatedAt: Joi.allow(),
    createdAt: Joi.allow(),
    name: Joi.string().min(6).max(50).required().messages({
      "string.base": 'Il campo "Nome Sconto" non può essere vuoto',
      "string.min": 'Il campo "Nome Sconto" deve essere minimo di 6 caratteri',
      "string.max": 'Il campo "Nome Sconto" non può avere più di 50 caratteri',
    }),
    totalPrice:Joi.allow(),
    percentage:Joi.any().optional(),
    fixedPrice:Joi.any().optional(),
    userId:Joi.allow(),
    // repeat: Joi.number().min(1).required().messages({
    //   "number.base": 'Il campo "Utilizzi per Persona" non può essere vuoto',
    //   "number.min":
    //     'Il campo "Utilizzi per Persona" non può essere inferiore ad 1',
    // }),
    occasion:Joi.array().min(1).message(
      "Le condizioni deve contenere almeno un elemento."
    ),
    repeat:Joi.any().optional(),
    // available:
    //   type?.discountType !== "Free shipping"
    //     ? Joi.object({
    //         delivery: Joi.boolean(),
    //         reservation: Joi.boolean(),
    //       })
    //         .pattern(/./, Joi.boolean())
    //         .custom((value, helpers) => {
    //           const hasTrueValue = Object.values(value).some(
    //             (val) => val === true
    //           );
    //           if (!hasTrueValue) {
    //             return helpers.message(
    //               'Selezionare almeno una voce su "Disponibile per"'
    //             );
    //           }
    //           return value;
    //         })
    //         .required()
            available : Joi.object().allow(),
    // for now i will keep it any date even in past
    // TO:DO figure out the right context for this
    startDate: Joi.date()
      .greater("now")
      .required()
      .messages({
        "date.base": 'Il campo "Data di inizio" deve essere una data valida',
        "date.greater": 'Il campo "Data di inizio" deve essere successiva alla data odierna',
        "any.required": 'Il campo "Data di inizio" è obbligatorio',
      }),
      endDate: Joi.date()
      .min(Joi.ref("startDate"))
      .optional()
      .messages({
        "date.base": 'Il campo "Data di fine" deve essere una data valida',
        "date.min": 'Il campo "Data di fine" deve essere successiva alla data di inizio',
      }),
    days: Joi.object({
      all: Joi.boolean().required(),
      sunday: Joi.boolean().required(),
      monday: Joi.boolean().required(),
      tuesday: Joi.boolean().required(),
      wednesday: Joi.boolean().required(),
      thursday: Joi.boolean().required(),
      friday: Joi.boolean().required(),
      saturday: Joi.boolean().required(),
    })
      .pattern(/./, Joi.boolean())
      .custom((value, helpers) => {
        const hasTrueValue = Object.values(value).some((val) => val === true);
        if (!hasTrueValue) {
          return helpers.message(
            'Devi scegliere almeno un giorno oppure "Tutti i Giorni"'
          );
        }
        return value;
      })
      .required(),
    
      // type?.discountType === "Bundle" ||
      // type?.discountType === "Menus for special occasion" ||
      // type?.discountType === "Complimentary" ||
      // type?.discountType === "Fixed"
      //   ? Joi.number().min(1).required().messages({
      //       "number.base":
      //         'Il campo "Utilizzi per Persona" non può essere vuoto',
      //       "number.min":
      //         'Il valore di "Utilizzi per Persona" non può essere inferiore ad 1',
      //     })
      //   : Joi.number().allow(null),
    type: Joi.any().optional(),
    bundle: Joi.object().optional(),
    description: Joi.any().optional(),
    // .min(4).required().messages({
    //   "string.base": 'La "Descrizione Sconto" non può essere vuota',
    //   "string.min": 'La "Descrizione Sconto" deve essere minimo di 4 caratteri',
    // }),
    // occasion:
    //   type?.discountType === "Menus for special occasion"
    //     ? Joi.string().min(1).required().messages({
    //         "string.base": 'Il campo "Occasione Speciale" non può essere vuota',
    //         "string.min":
    //           'Il valore di "Occasione Speciale" non può essere inferiore ad 1€',
    //       })
    //     : Joi.string().allow(""),
    threshold:Joi.any().optional(),
      // type?.discountType === "Fixed"
      //   ? Joi.number().min(1).required().messages({
      //       "number.base": 'La "Soglia per lo sconto" non può essere vuota',
      //       "number.min":
      //         'Il valore di "Soglia per lo sconto" non può essere inferiore ad 1€',
      //     })
      //   : Joi.number().allow(null),
    freeShippingThreshold:Joi.any().optional(),
      // type?.discountType === "Free shipping"
      //   ? Joi.number().min(1).required().messages({
      //       "number.base":
      //         'La "Soglia per la spedizione gratuita" non può essere vuota',
      //       "number.min":
      //         'Il valore di "Soglia per la spedizione gratuita" non può essere inferiore ad 1€',
      //     })
      //   : Joi.number().allow(null),
      giftType:Joi.any().optional(),
      occasion:Joi.array().optional(),
      ApplyTo:Joi.any().optional(),
    
    });
export default cards;
