import React, { useCallback, useEffect, useState } from "react"
import "./tripadvisor.css"
import tripadvisorIcons from "../../../../../../Assets/Icons/tripadvisor.png"
import RsImage from "../../../../../../Assets/Icons/cutlery.png"
import { tripadvisorSearchRestaurant } from "../../../../../../apis/searchTripRestaurant"
import { tripadvisorSaveLocationId } from "../../../../../../apis/saveTripLocationId"
import TripIcon from "../../../../../../Assets/Icons/TripIcon.svg"
const Tripadvisor = () => {
  const [showForm, setShowForm] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [restaurant, setRestaurant] = useState("")
  const [locationId, setLocationId] = useState(null)
  const [restaurants, setRestaurants] = useState([])
  const [showSidebar, setShowSidebar] = useState(false)
  const [isConnect, setIsConnect] = useState(
    JSON.parse(localStorage.getItem("tripadvisor"))
  )

  // save location id
  const saveLocationId = useCallback(async () => {
    try {
      setLoader2(prev => !prev)
      const id = await tripadvisorSaveLocationId(locationId)
      localStorage.setItem("tripadvisor", JSON.stringify(id))
      setLoader2(prev => !prev)
      setShowSidebar(false)
      setShowForm(false)
      setIsConnect(true)
    } catch (e) {
      alert(e.message)
      setLocationId(null)
      setLoader2(prev => !prev)
    }
  }, [locationId])

  // check change location id
  useEffect(() => {
    if (!locationId) return

    saveLocationId()
  }, [locationId, saveLocationId])

  // search restaurant
  const searchRestaurant = async () => {
    try {
      setLoader(prev => !prev)
      const restaurants = await tripadvisorSearchRestaurant(restaurant)
      setRestaurants([...restaurants])
      setLoader(prev => !prev)
      setShowSidebar(true);
    } catch (e) {
      alert(e.message)
      setLoader(prev => !prev)
    }
  }

  // select restaurant
  const selectRestaurantHandler = id => {
    setLocationId(id)
  }

  // disconnect account trip
  const disconnectTripadvisor = () => {
    localStorage.removeItem("tripadvisor")
    setIsConnect(false)
  }

  const ConnectButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-primarybtn hover:bg-primarybtnhover active:shadow-innerbig";

  const RedButton = "w-36 h-fit px-4 py-2 font-semibold rounded-md elevation-1 text-white bg-deletebtn hover:bg-deletebtnhover active:shadow-innerbig";

  return (
    <>
      {!showForm && (
        <div className=" py-2 h-fit items-center bg-gradient-to-r from-[#3A5989] to-[#6E99FD] w-full rounded-md flex flex-row justify-between px-4 ">

          <div className="flex flex-row gap-x-4 items-center">
            <img src={TripIcon} alt="" />
            <h1 className="w-full text-white  text-xl sm:text-[16px] xsm:text-[16px] ">Tripadvisor</h1>
          </div>

          <button
            className={isConnect ? RedButton : ConnectButton}
            onClick={() => {
              if (isConnect) {
                disconnectTripadvisor()
              } else {
                setShowForm(prev => !prev)
              }
            }}
          >
            {isConnect ? "Disconetti" : "Connetti"}
          </button>
        </div>
      )}
      {showForm && (
        <div className="Tripadvisor w-full p-4 rounded-md bg-gradient-to-r from-[#3A5989] to-[#6E99FD]">
          <label className="text-white fond-bold text-lg text-left">Ristorante</label>

          <div className=" w-full flex flex-row  ">

            <img
              className="w-10 h-10 bg-white rounded-l-md"
              src={tripadvisorIcons}
              alt="tripadvisor"
            />

            <input
              className="Tripadvisor__input w-full pl-4"
              type="text"
              placeholder="Inserisci il nome del tuo ristorante"
              onChange={e => setRestaurant(e.target.value)}
            />

            <button onClick={searchRestaurant} className="Tripadvisor__btn w-fit">
              {loader ? "Caricamento..." : "Cerca"}
            </button>

          </div>


          {showSidebar && (
            <div className="Tripadvisor__drawer">
              <h1 className="Tripadvisor__drawer-title">
                Scegli il tuo ristorante
              </h1>
              {!loader2 && (
                <ul className="Tripadvisor__drawer-list">
                  {restaurants.map(restaurant => (
                    <li
                      key={restaurant.location_id}
                      className="Tripadvisor__restaurantItem"
                      onClick={() =>
                        selectRestaurantHandler(restaurant.location_id)
                      }
                    >
                      <img width={20} src={RsImage} alt="icon" />
                      <p className="Tripadvisor__restaurant-name">
                        {restaurant.name}
                        <span className="Tripadvisor__restaurant-city">
                          (
                          {restaurant.address_obj.city ??
                            restaurant.address_obj.country}
                          )
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              )}
              {loader2 && <p>Caricamento...</p>}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Tripadvisor
