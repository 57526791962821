import React from 'react';

const FabricSvg = ({ commonProps, layer }) => {
    const removePositioning = (object) => {
        const newObject = {};
        Object.setPrototypeOf(newObject, object);
        newObject.left = 0;
        newObject.top = 0;
        newObject.scaleY = 1;
        newObject.scaleX = 1;
        newObject.angle = 0;
        return newObject;
    };
    const element = removePositioning(
        commonProps.canvas
            .getObjects()
            .find((element) => element.id === layer.id)
    ).toSVG();

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='62'
            height='62'
            viewBox='0 0 62 62'
            fill='none'
            // it's safe because we now what we are appending, a fabric object converted to svg
            dangerouslySetInnerHTML={{ __html: element }}
        ></svg>
    );
};

export default FabricSvg;
