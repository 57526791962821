import React from "react";
import * as BookingApi from "../apis/bookingsService";
import format from "date-fns/format";
import { isAfter, isBefore } from "date-fns";
import { toast } from "react-toastify";
import { zonedTimeToUtc } from "date-fns-tz";
import { subHours } from "date-fns";
export const ReservationContextProvider = React.createContext();

export default function ReservationContext({ children }) {
  const [eventAppointment, setEventAppointment] = React.useState([]);
  const [value, onChange] = React.useState(new Date());
  const [dateRange, setDateRange] = React.useState();
  const [PendingEvents, SetPendingEvents] = React.useState([]);
  const [NonPendingEvents, SetNonPendingEvents] = React.useState([]);
  const [FinishedEventts, SetFinishedEvents] = React.useState([]);
  const isPendingEvent = (item) => item?.status === 0 && !isFinished(item?.end);
  const isNotPendingEvent = (item) =>
    item?.status !== 0 && !isFinished(item?.end);
  const isFinished = (eventStart) =>
    isBefore(new Date(`${eventStart}`), new Date());
  const [AllBookings, SetBookings] = React.useState([]);
  const [PastAndConfirmedReservations, SetPastReservations] = React.useState(
    [],
  );

  async function getBookingsFoPeriod(Period) {
    try {
      const res = await BookingApi.getBookingsForYear(Period);
      const allData = res?.data;
      SetPastReservations(allData.filter((item) => item?.status !== 0));
    } catch (error) {
      console.log(error);
    }
  }

  async function getBookingsForFullYear() {
    try {
      const res = await BookingApi.getBookingsForYear();
      const allData = res?.data;
      SetBookings(allData.filter((item) => !isFinished(item?.booked_at)));
    } catch (error) {
      console.log(error);
    }
  }

  const italianTimeZone = "Europe/Rome";

  const GETbookings = async () => {
    try {
      const res = await BookingApi.getBookings(dateRange);
      const allData = res?.data;
      if (allData) {
        const NewForm = allData
          ?.map((item) => {
            const startAdjusted = subHours(new Date(item?.booked_at), 2);
            const endAdjusted = subHours(new Date(item?.booked_until), 2);
            const startInItalianTime = zonedTimeToUtc(
              startAdjusted,
              italianTimeZone,
            );
            const endInItalianTime = zonedTimeToUtc(
              endAdjusted,
              italianTimeZone,
            );
            return {
              ...item,
              start: startInItalianTime,
              end: endInItalianTime,
            };
          })
          .filter((item) => item?.status !== 7);
        const events = NewForm.filter(
          (item) =>
            format(new Date(item?.start), "dd MMMM yyyy") ===
            format(new Date(value), "dd MMMM yyyy"),
        );
        const PendingEvents = events?.filter((item) => isPendingEvent(item));
        const NonPendingEvents = events?.filter((item) =>
          isNotPendingEvent(item),
        );
        const FinishedEventts = events?.filter((item) => isFinished(item?.end));
        setEventAppointment(NewForm);
        SetPendingEvents(PendingEvents);
        SetNonPendingEvents(NonPendingEvents);
        SetFinishedEvents(FinishedEventts);
      }
    } catch (e) {
      console.log("Errore nel caricare le prenotazioni");
    }
  };

  // This function returns the name of the person who booked the event. If the event was a walk-in, it returns the customer info.
  const handleBookingWalkin = (item) =>
    !item.walkIn ? item?.booked_by : item?.customer_info;
  // This function filters the table names of an event based on the query string. Returns true if the query is included in any of the table names.
  const handleTablesSearch = (item, query) => {
    const tables = item?.tableIdentifiers?.filter((tableName) =>
      tableName.startsWith(query),
    );
    return tables.length !== 0 ? true : false;
  };

  // This function checks if the query string is included in the first name, last name or table names of an event.
  const queryIsIncluded = (item, query) =>
    handleBookingWalkin(item)
      ?.firstName?.toLowerCase()
      .startsWith(query.toLowerCase()) ||
    handleBookingWalkin(item)
      ?.lastName?.toLowerCase()
      .startsWith(query.toLowerCase()) ||
    handleTablesSearch(item, query);

  const RequiredDateFormat = (date) => format(new Date(date), "dd MMMM yyyy");

  const isAtTheSelectedDay = (date1, date2) =>
    RequiredDateFormat(date1) === RequiredDateFormat(date2);

  const theSelectedDayIsTodayOrAfterToday =
    isAfter(new Date(value), new Date()) ||
    isAtTheSelectedDay(value, new Date());

  function ResetResults() {
    SetPendingEvents(
      eventAppointment?.filter(
        (item) =>
          isPendingEvent(item) && isAtTheSelectedDay(item?.start, value),
      ),
    );
    SetNonPendingEvents(
      eventAppointment?.filter(
        (item) =>
          isNotPendingEvent(item) && isAtTheSelectedDay(item?.start, value),
      ),
    );
    SetFinishedEvents(
      eventAppointment?.filter(
        (item) =>
          isFinished(item?.end) && isAtTheSelectedDay(item?.start, value),
      ),
    );
  }
  /**
   * Handles searching for events that match a given query.
   * If the query is empty, resets the results to show all events.
   * @param {string} query - The search query.
   */
  function handleSearch(query) {
    // If the query is empty, reset the results
    if (query === "") {
      ResetResults();
    } else {
      // If the selected day is today or after today
      if (theSelectedDayIsTodayOrAfterToday) {
        // Filter events by pending, non-pending, and finished, and set the state for each category
        const isPending = eventAppointment.filter(
          (item) =>
            item?.status === 0 &&
            queryIsIncluded(item, query) &&
            isAtTheSelectedDay(item?.start, value),
        );
        const isNotPending = eventAppointment.filter(
          (item) =>
            item?.status !== 0 &&
            queryIsIncluded(item, query) &&
            isAtTheSelectedDay(item?.start, value),
        );
        const isfinished = eventAppointment.filter(
          (item) =>
            isFinished(item) &&
            queryIsIncluded(item, query) &&
            isAtTheSelectedDay(item?.start, value),
        );
        SetPendingEvents(isPending);
        SetNonPendingEvents(isNotPending);
        SetFinishedEvents(isfinished);
      } else {
        // If the selected day is before today, filter events by the search query and set the state for finished events
        const isPending = eventAppointment.filter(
          (item) =>
            queryIsIncluded(item, query) &&
            isAtTheSelectedDay(item?.start, value),
        );
        SetFinishedEvents(isPending);
      }
    }
  }
  //* Search Functions END ================================================ ================================================ ================================================

  /**
   * Sets the start and end dates of the current month as the date range
   * @param {Date} dateInfo - The date for which to set the date range
   */
  const handleDatesSet = (dateInfo) => {
    // Create a new Date object using the provided dateInfo
    var date = new Date(dateInfo);
    // Set the first day of the month as the start date of the range
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // Set the last day of the month as the end date of the range
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // Format the start and end dates using the "yyyy-MM-dd" pattern and set the date range
    setDateRange({
      start: format(firstDay, "yyyy-MM-dd"),
      end: format(lastDay, "yyyy-MM-dd"),
    });
  };

  const Body = {
    AllBookings,
    PendingEvents,
    NonPendingEvents,
    FinishedEventts,
    handleSearch,
    GETbookings,
    eventAppointment,
    value,
    onChange,
    handleDatesSet,
    SetPendingEvents,
    SetNonPendingEvents,
    SetFinishedEvents,
    getBookingsForFullYear,
    getBookingsFoPeriod,
    PastAndConfirmedReservations,
    setEventAppointment,
    dateRange,
  };
  return (
    <ReservationContextProvider.Provider value={Body}>
      {children}
    </ReservationContextProvider.Provider>
  );
}
