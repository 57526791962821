import axios from "axios"
import { baseUrl } from "../Utility/Api/Urls"

export const tripadvisorSaveLocationId = async locationId => {
  try {
    const token = JSON.parse(localStorage.getItem("userToken"))
    const response = await axios({
      url: `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH}/tripadvisor/update`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({ location_id: locationId }),
    })
    const { data } = response;

    // check if id exist
    if(!data._id) return;

    return data._id;
  } catch (e) {
    throw e
  }
}
