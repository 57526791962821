import { useEffect, useState } from 'react';
import { getPersonaAdCopy } from './adCopyFunctions';

const useCopy = (apiFunction, buyerPersona, goal) => {
  const [copies, setCopies] = useState([]);
  const [isLoadingCopies, setIsLoadingCopies] = useState(true);
  useEffect(() => {
    (async () => {
      setIsLoadingCopies(true);
      const copies = await apiFunction(buyerPersona, goal);
      setCopies(copies);
      setIsLoadingCopies(false);
    })();
  }, [buyerPersona, goal]);
  return [copies, isLoadingCopies];
};

const usePersonaAdCopy = (buyerPersona, goal) =>
  useCopy(getPersonaAdCopy, buyerPersona, goal);

export const personaAdCopyService = {
  copies: usePersonaAdCopy,
};
