import React, { useEffect, useState } from "react";
import "./BuyerPersonaCard.css";

function BuyerPersonaCard({
  isloading,
  persona,
  maxFacebookInterests = 3,
  maxGoogleInterests = 5,
  selected,
  onSelect,
}) {
  // const { label } = persona;

  const [displayData, setDisplayData] = useState(null);

  useEffect(() => {
    if (!isloading && persona && persona.displayData) {
      const {
        ageInfo,
        gender,
        location,
        googleInterests,
        facebookInterests,
        usesMobile,
        usesTablet,
        usesDesktop,
        image,
      } = persona.displayData;
      setDisplayData({
        ageInfo,
        gender,
        location,
        googleInterests,
        facebookInterests,
        usesMobile,
        usesTablet,
        usesDesktop,
        image,
      });
    }
  }, [isloading, persona]);
  const selectedId = selected?.id;

  // const name =
  //   label?.slice(label?.indexOf("Cliente")) || label || "Tipo Cliente";

  // const hasDevices = displayData.usesMobile || displayData.usesTablet || displayData.usesDesktop;

  return (
    <>
      <div className="buyerpersonacard w-full h-max p-0 rounded-t-md">
        {!isloading && displayData && (
          <div className="buyerpersonacard__page1 h-full flex flex-col w-full gap-y-4 items-center mb-4">
            <div className="w-96 overflow-hidden rounded-md">
              <img
                src={displayData.image}
                alt=""
                className="w-full h-[200px] object-cover"
              />
            </div>

            <p className="pop600 text-[22px] text-left text-marketingtextcolour pl-3">
              {persona.label}
            </p>

            <p className="px-5 text-left">
              Scopri chi sono i clienti che potrebbero essere più interessati al
              tuo locale in base ai loro interessi e alle loro caratteristiche.
            </p>

            {/* USER INFO */}

            <div className="flex flex-row w-[95%] gap-y-1 gap-x-4">
              <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d]">
                <p className="pop400 text-left text-black">Età</p>

                <p className="pop400 text-left text-black">
                  {displayData.ageInfo}
                </p>
              </div>

              <div className="flex w-full justify-between items-center p-4 bg-[#d9d9d94d]">
                <p className="pop400 text-left text-black">Genere</p>

                <p className="pop400 text-left text-black">
                  {displayData.gender}
                </p>
              </div>

              {/* {displayData.location && (
                <p className="font-normal text-xl text-left text-[#2E2E2E]">
                  Luogo: {displayData.location}
                </p>
              )} */}
            </div>

            {/* GOOGLE INTERESTS */}
            {/* {displayData.googleInterests?.length > 0 && (
              <div className="w-full flex flex-col gap-y-1 pl-3">
                <p className="font-semibold text-2xl text-left text-[#2E2E2E] mb-1">
                  Interessi Google
                </p>
                {displayData.googleInterests
                  ?.slice(0, maxGoogleInterests)
                  .map((gi) => (
                    <p
                      className="font-normal text-xl text-left text-[#2E2E2E]"
                      key={gi}
                    >
                      {gi}
                    </p>
                  ))}
              </div>
            )} */}

            {/* FACEBOOK INTERESTS */}
            {displayData.facebookInterests?.length > 0 && (
              <div className="flex flex-row w-[95%]  bg-[#d9d9d94d] justify-between flex-wrap">
                <p className="pop400 text-left text-black mb-1 p-4">
                  Interessi Facebook
                </p>
                <div className="flex flex-row p-4 ">
                  {displayData.facebookInterests
                    ?.slice(0, maxFacebookInterests)
                    .map((fi, index) => (
                      <p
                        className="font-semibold text-left text-black "
                        key={index}
                      >
                        {fi}
                        {index !==
                          displayData.facebookInterests.slice(
                            0,
                            maxFacebookInterests
                          ).length -
                            1 && <span className="mx-1">, </span>}
                      </p>
                    ))}
                </div>
              </div>
            )}

            {/* DEVICES */}
            {/* {hasDevices && (
        <div className="w-full flex flex-col gap-y-1 pl-3">
          <p className="font-semibold text-2xl text-left text-[#2E2E2E] mb-1">
            Dispositivi
          </p>

          {displayData.usesDesktop && (
            <p className="font-normal text-xl text-left text-[#2E2E2E]">
              Desktop
            </p>
          )}
          {displayData.usesMobile && (
            <p className="font-normal text-xl text-left text-[#2E2E2E]">
              Mobile
            </p>
          )}
          {displayData.usesTablet && (
            <p className="font-normal text-xl text-left text-[#2E2E2E]">
              Tablet
            </p>
          )}
        </div>
      )} */}
          </div>
        )}
      </div>
      {/* <div className="flex w-[95%] justify-center items-center mx-auto mb-4">
        <button
          onClick={() => onSelect(persona)}
          className={`${
            selectedId === persona?.id
              ? "font-semibold text-xl elevation-1 text-white bg-marketingbtn border-2 border-marketingbtn hover:bg-marketingbtnhover hover:border-marketingbtnhover active:border-white active:shadow-innerbig"
              : "font-semibold text-xl bg-white text-marketingtextcolour border-2 border-marketingbtn hover:bg-marketingbtnhover hover:text-white hover:border-marketingbtnhover hover:elevation-1 active:border-white active:shadow-innerbig"
          } w-full h-10 flex flex-row gap-x-2 rounded-md items-center justify-center`}
        >
          {selectedId === persona?.id ? "Scelto" : "Scegli"}
        </button>
      </div> */}
    </>
  );
}

export default BuyerPersonaCard;
