import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../../../../../Reusable/CSS/Page-Layout.css";
import MarketingHeaderLottie from "../../../../../Assets/Lottie/Marketing.json";
import Backbutton from "../../../../../Reusable/Back-Button/Back-Button";
import CopySection from "../../../../../Reusable/MarketingCreateCampaign/CopySection";
import Loader from "../../../../../Reusable/Loader/Loader";
import MarketingCampaignHeader from "./Form-Sections/MarketingCampaignHeader";
import MarketingBeePlugin from "./Form-Sections/MarketingBeePlugin";
import MarketingTemplateSelection from "./Form-Sections/MarketingTemplateSelection";
import MarketingBudget from "./Form-Sections/MarketingBudget";
import MarketingSuggestionDisplay from "./Form-Sections/MarketingSuggestionDisplay";
import MarketingTimePickers from "./Form-Sections/MarketingTimePickers";
import MarkertingBuyerPersonas from "./Form-Sections/MarketingBuyerPersonas";
import { MarketingCampaignContext } from "../../../../../Contexts/MarketingCampaignContext";
import * as helpers from "../../../../../Utility/marketingHelpers";
import * as campaignService from "../../../../../apis/campaignService";
import TemplatesEditorWrapper from "../../../GraphicEditor-NEW/TemplatesEditorWrapper/TemplatesEditorWrapper";
import { editorMarketingTemplatesService } from "../../../../../apis/editor/EditorService";

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../../Reusable/Informational-Top-Banner/RC/3/Informational-Top-Banner"
    )
  ).default;
}

export default function CreateCampaignForm() {
  const navigate = useNavigate();

  const {
    campaign,
    selectedBuyerPersona,
    externalBuyerPersona,
    isSuggestedCampaign,
    image,
    isOtherPersona,
    copy,
    handleCopyChange,
    selectedTemplateJson,
    packages,
    originalPackages,
    selectedPackage,
    startDate,
    endDate,
    number,
    resetForm,
    currentBalance,
    totalBudget,
  } = useContext(MarketingCampaignContext);

  const [isCreating, setIsCreating] = useState(false);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  // group all data together
  const getAllData = () => ({
    // to campaign creation endpoint
    goal: campaign?.value,
    dsGoal: campaign?.dsGoal,

    startDate,
    endDate,

    budget: number || packages[selectedPackage]?.budget,
    buyerPersona: selectedBuyerPersona?.value,

    // to ad creative endpoint
    copy,
    image,

    // to runing campaigns endpoint
    allSuggestedBudgets: originalPackages,
  });

  // validation errors
  let formError = helpers.validateCreation(getAllData());

  const handleCreate = async () => {
    setTriedToSubmit(true);

    if (Number(currentBalance) < Number(totalBudget)) {
      toast.warning("Insufficiente budget");
      return;
    }

    if (formError) return;
    const allData = getAllData();

    // start creation process
    const promiseToastId = toast.loading("Creazione in corso..."); // create toast
    setIsCreating(true); // activate spinning loader

    // create campaign
    const created = await campaignService.createCampaign(allData);
    if (!created) {
      toast.dismiss(promiseToastId);
      return setIsCreating(false);
    }

    setIsCreating(false);
    toast.update(promiseToastId, {
      render: "Finito!",
      type: "success",
      isLoading: false,
      autoClose: true,
    });
    resetForm();
    navigate("/marketing/inprogress-campaigns");
  };
  const [activeStep, SetStep] = React.useState(0);

  useEffect(() => {
    if (image) {
      SetStep(2);
    }
  }, [image]);

  const hasEnoughBalance = () =>
    currentBalance !== null &&
    currentBalance !== 0 &&
    currentBalance >= Number(totalBudget);

  if (!campaign) return <Navigate to="/marketing/create-campaigns" />;
  return (
    <>
      {/* --- START FIXED PARTS --- */}

      <InformationTopBanner
        pagetype="Sezione"
        sectionname="Pubblicità"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "Scegli il target, la descrizione, il budget e la durata della tua campagna pubblicitaria"
        }
        sectionicon={MarketingHeaderLottie}
      />

      <div className="flex ml-8 mt-6 mb-12">
        <Backbutton />
      </div>

      {/* --- END FIXED PARTS --- */}

      {/* --- START CAMPAIGN FIELDS --- */}

      <div className="pagelayout flex flex-col justify-center items-center w-full gap-y-8 px-8">
        <div className="flex flex-col w-full gap-6 justify-center items-center gap-y-6">
          {/* {!isOtherPersona && <MarketingCampaignHeader />} */}

          <div className="flex flex-col w-full mx-auto gap-y-6">
            <MarkertingBuyerPersonas />

            {selectedBuyerPersona && (
              <CopySection
                goal={campaign?.adCopyGoal}
                buyerPersona={
                  isOtherPersona
                    ? externalBuyerPersona?.value
                    : selectedBuyerPersona?.value
                }
                text={copy}
                onChange={handleCopyChange}
                isRandom={isSuggestedCampaign}
                SetStep={SetStep}
                activeStep={activeStep}
              />
            )}
          </div>
        </div>

        {/* --- START BEETEMPLATE PART --- */}
        {/* {selectedBuyerPersona && activeStep >= 1 && (
                    <MarketingTemplateSelection />
                )}
                {selectedTemplateJson && activeStep >= 1 && (
                    <MarketingBeePlugin SetStep={SetStep} />
                )} */}
        {selectedBuyerPersona && activeStep >= 1 && (
          <>
            <TemplatesEditorWrapper
              campaign={campaign.value}
              sectionColor={"marketing"}
              service={editorMarketingTemplatesService}
            />
            {image && <img src={image} alt="template" />}
          </>
        )}

        {/* --- END BEETEMPLATE PART --- */}

        {/* --- START BUDGET + SCHEDULING --- */}

        {activeStep >= 2 && <MarketingBudget />}

        {activeStep >= 2 && (
          <div className="flex flex-col w-full justify-center items-center gap-y-8 mt-8 py-4 elevation-1 rounded-b-md px-4">
            <div className="flex self-start">
              <p className="text-marketingtextcolour font-semibold text-2xl text-left">
                Programma la tua campagna!
              </p>
            </div>

            <div className="flex flex-col w-fit gap-y-6 justify-center items-center">
              <MarketingSuggestionDisplay />
            </div>

            <div className="flex flex-row w-full justify-center items-center gap-x-20">
              <MarketingTimePickers />

              <div className="flex">
                <button
                  className="bg-marketingbtn rounded-md w-fit h-10 px-4 py-2 my-7 mx-auto text-white hover:bg-marketingbtnhover hover:border-marketingbtnhover elevation-1"
                  disabled={isCreating}
                  onClick={handleCreate}>
                  {isCreating ? <Loader /> : "Programma la Campagna"}
                </button>
              </div>
            </div>

            {triedToSubmit && formError && (
              <p className="text-red-600">{formError}</p>
            )}
          </div>
        )}

        {/* --- END BUDGET + SCHEDULING --- */}
      </div>

      {/* --- END CAMPAIGN FIELDS --- */}
    </>
  );
}
