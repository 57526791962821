import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../../Reusable/CSS/Page-Layout.css";
import NewsletterHeaderLottie from "../../../../Assets/Lottie/Newsletter.json";
import BeeComponent from "../../../../Reusable/BeePlugin/BeePlugin";
import Backbutton from "../../../../Reusable/Back-Button/Back-Button";
import ScrollToTop from "../../../../Reusable/ScrollToTop";
import { useUserNewsletterTemplates } from "../../../../Hooks/useUserTemplates";
import { useNewsletterTemplates } from "../../../../Hooks/useTemplates";
import { getAuthConfig } from "../../../../Utility/Api/auth";
import { baseUrl } from "../../../../Utility/Api/Urls";
import * as imageHostingService from "../../../../apis/imageHostingService";
import * as templateService from "../../../../apis/templateService";
import Loader from "../../../../Reusable/Loader/Loader";
import MailingChecklist from "./MailingChecklist/MailingChecklist";
import useFavoriteTemplates from "../../../../Hooks/useFavoriteTemplates";
import { getSortedTemplates } from "../../../../Utility/templatesHelpers";
import SchedulerModal from "./SchedulerModal";
import TemplatesModal from "./TemplatesModal";
import GoalNameSection from "./GoalNameSection";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

let InformationTopBanner = null;

if (process.env.REACT_APP_PROJECT === "Unifarco") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/Unifarco/Informational-Top-Banner"
    )
  ).default;
}
if (process.env.REACT_APP_PROJECT === "RC") {
  InformationTopBanner = (
    await import(
      "../../../../Reusable/Informational-Top-Banner/RC/4/Informational-Top-Banner"
    )
  ).default;
}
 
  const CreateNewMail = ({flags}) => {
  // the campaign goal received by navigation state
  const { search, } = useLocation();
  const [goalName, SetGoalName] = React.useState("branding")

  // if we have a draft id in the url, we are editing a draft
  const query = new URLSearchParams(search);
  const draftId = query.get("draftId");
  useEffect(() => {
    (async () => {
      if (!draftId) return;

      const template = await templateService.getUserTemplate(draftId);
      if (!template) return toast.error("C'era un problema con il template");

      setSelectedTemplateJson(template.json);
      setSubject(template.subject);
      setNotes(template.notes);
      setChosenLists(template.mailingListIds);
    })()
  }, [draftId]);

  // handling mailing lists 
  const [allLists, setAllLists] = useState([]);
  useEffect(() => {
    const mailingListsEndpoint = `https://${process.env.REACT_APP_DASHBOARD_PREFIX
      }${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/mailingLists`;

    axios
      .get(mailingListsEndpoint, getAuthConfig())
      .then(res => {
        setAllLists(res.data.mailingLists);
      })
      .catch(err => {
        console.error(err);
        toast.error("Errore, riprova o ricarica la pagina.");
      });

    return () => {
      setAllLists([]);
    };
  }, []);

  // update the mailing lists if we have a draft
  useEffect(() => {
    if (!draftId) return;

    const areAllNumbers = chosenLists.every(item => typeof item === "number");
    if (areAllNumbers && allLists?.length > 0)
      setChosenLists(allLists.filter(list => chosenLists.includes(list.id)));
  }, [draftId, allLists]);


  const navigate = useNavigate();
  const [step, SetStep] = React.useState(1)
  const [isFocused, SetFocus] = React.useState(false)

  // bee plugin states and handlers
  const [isBeeSaving, setIsBeeSaving] = useState(false);
  const [saveError, setSaveError] = useState();
  const [json, setJson] = useState(null);
  const [html, setHtml] = useState(null);
  const [image, setImage] = useState(null);
  const [hasClickedSave, setHasClickedSave] = useState(false);
  const handleSaveClick = () => {
    setHasClickedSave(true);
    setSaveError(null);
    setIsBeeSaving(true);
    setIsCreatingImage(true);
  };
  // templates states
  const [selectedTemplateJson, setSelectedTemplateJson] = useState(null);
  // gets the template selected inside SelectTemplate component
  const handleTemplateSelected = templateJson => {
    setSelectedTemplateJson(templateJson);
  };

  // preview image
  const [isCreatingImage, setIsCreatingImage] = useState(false);
  const handleImageCreated = () => setIsCreatingImage(false);

  const [isSavingDraft, setIsSavingDraft] = useState(false);

  const handleSaveDraftClick = async () => {
    setIsSavingDraft(true);

    const url = await imageHostingService.hostBase64Image(image);
    const name = `Template ${new Date(Date.now()).toLocaleString()}`;
    const result = await templateService.createUserNewsletterTemplate({
      name,
      json,
      goal: goalName,
      image: url,
      subject,
      notes,
      mailingListIds: chosenLists.map(list => list.id),
    });
    result && toast.success(`${name} Salvato!`);
    setIsSavingDraft(false);
  };

  // load templates from db
  const [templates, isLoadingTemplates] = useNewsletterTemplates({
    goal: goalName,
    deps: [goalName],
  });

  // load the campaigns templates from db that the user already sent before
  const [userTemplates, isLoadingUserTemplates] = useUserNewsletterTemplates({
    goal: goalName,
    deps: [goalName],
  });

  const [favorites, isLoadingFavorites] = useFavoriteTemplates();
  const sortedTemplates = useMemo(
    () => getSortedTemplates(templates, favorites),
    [templates, favorites]
  );
  const isHandlingTemplates = isLoadingTemplates || isLoadingFavorites;

  // controlling template select modal and the buttons that open it
  const [templatesToShow, setTemplatesToShow] = useState(sortedTemplates);
  const [isActive, SetActive] = React.useState(true)

  const handleSelectTemplates = () => {
    setTemplatesToShow(sortedTemplates);
    SetActive(true)
  };
  const handleSelectSavedTemplates = () => {
    setTemplatesToShow(userTemplates);
    SetActive(false)
  };

  // form states
  const [subject, setSubject] = useState("");
  const [notes, setNotes] = useState("");
  const [chosenLists, setChosenLists] = React.useState([]);
  const handleListsChosen = lists => setChosenLists(lists);

  // validation: enable publish button when all required data is available
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (!subject || !notes || chosenLists?.length === 0) setIsValid(false);
    else setIsValid(true);
  }, [subject, notes, chosenLists]);

  // controlling publish modal
  const [publishOpen, setPublishOpen] = useState(false);
  const handleOpenPublishModal = () => {
    setPublishOpen(true);
    setIsBeeSaving(true); // force bee plugin to save incase user didn't save
  };
  const handleClosePublishModal = () => setPublishOpen(false);

  // publishing (sending) the newsletter
  const [isPublishing, setIsPublishing] = useState(false);
  React.useEffect(() => { handleSteps() }, [isFocused])

  const handlePublish = date => {
    // make sure we have the BEE plugin template was saved
    if (!hasClickedSave)
      return setSaveError(
        'Il Template deve essere salvato per continuare. Clicca su "Salva"'
      );

    setSaveError(null);
    setIsPublishing(true);
    handleClosePublishModal();

    const newslettersEndpoint = `https://${process.env.REACT_APP_DASHBOARD_PREFIX
      }${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}/newsletters`;
    const payload = {
      subject,
      notes,
      mailingListIds: chosenLists.map(list => list.id),
      html,
      json,
      dateOfPublish: new Date(date).toISOString(),
      goal: goalName,
      imageString: image,
    };

    axios
      .post(newslettersEndpoint, payload, getAuthConfig())
      .then(res => {
        setIsPublishing(false);
        toast.success("Finito!");
        navigate("/newsletter/completed-newsletter");
      })
      .catch(err => {
        setIsPublishing(false);
        toast.error("Errore, riprova.");
        console.error(err);
      });
  };

  // if (!draftId)
  //   return <Navigate to="/newsletter/create-a-new-mail" />;

  function handleSteps() {

    if (!notes || !subject) {
      SetStep(1)
      setChosenLists([])
      setSelectedTemplateJson(null)
    }

    if (notes && subject && chosenLists.length === 0) {
      SetStep(2)
    }
    if (notes && subject && chosenLists.length !== 0) {
      SetStep(3)
    }

  }

  return (
    <>
      <ScrollToTop />

      {/* --- START TEMPLATE MODAL --- */}



      {/* --- END TEMPLATE MODAL --- */}

      {/* --- SCHEDULERMODAL --- */}

      {publishOpen &&
        <SchedulerModal
          handlePublish={handlePublish}
          publishOpen={publishOpen}
          handleClosePublishModal={handleClosePublishModal}
          isValid={isValid}
          isBeeSaving={isBeeSaving}
          isPublishing={isPublishing} />
      }
      {/* --- END SCHEDULER MODAL --- */}

      {/* --- START FIXED PARTS --- */}

      <InformationTopBanner
        pagetype="Sezione"
        sectionname="Newsletter"
        description={
          process.env.REACT_APP_PROJECT === "Unifarco"
            ? "La tua farmacia"
            : "Inserisci i dati relativi alla campagna, progetta la tua email partendo da un template e decidi quando inviarla."
        }
        sectionicon={NewsletterHeaderLottie}
      />



      <div className='flex ml-16 mt-6 mb-12'>

        <Backbutton />

      </div>

      {/* --- END FIXED PARTS --- */}

      <div className="pagelayout flex flex-col w-full gap-y-8 px-6 ">
        {/* --- START CAMPAIGN FIELDS --- */}

        <GoalNameSection goalName={goalName} SetGoalName={SetGoalName} />
        <div className="flex flex-row w-full mx-auto gap-6 justify-center items-center xsm:flex-col sm:flex-col md:flex-col xmd:flex-col">
          {/* --- START GOAL TYPE --- */}
          {/* --- END GOAL TYPE --- */}
          <div className="flex flex-col gap-y-6 w-[50em] xsm:w-full sm:w-full md:w-full xmd:w-full">
            {/* --- START NAME AND SUBJECT FIELDS --- */}

            <div className="flex flex-row gap-x-6 gap-y-4 xsm:flex-col sm:flex-col md:flex-col xmd:flex-col">

              <div className="flex flex-col gap-y-2  ">
                <div className="flex">
                  <p className="text-xl text-newslettertextcolour font-semibold text-left">
                    Nome Campagna
                  </p>
                </div>

                <input
                  type="text"
                  value={notes}
                  onBlur={() => !notes && SetFocus(Math.random())}
                  className="w-[391px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3 xsm:w-full sm:w-full md:w-full xmd:w-full"
                  placeholder="Scrivi qui il nome della campagna..."
                  onChange={e => setNotes(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="flex">
                  <p className="text-xl text-newslettertextcolour font-semibold text-left">
                    Oggetto Campagna
                  </p>
                </div>

                <input
                  value={subject}
                  type="text"
                  className="w-[391px] h-[50px] rounded-md bg-[#F6F6F6] placeholder:text-[#989898] px-3 xsm:w-full sm:w-full md:w-full xmd:w-full"
                  placeholder="Scrivi qui l’oggetto della campagna..."
                  onChange={e => setSubject(e.target.value)}
                  onBlur={() => !subject && SetFocus(Math.random())}
                />
              </div>

            </div>

            {
              step === 1 &&

              <button onClick={() => SetFocus(Math.random())} className="w-40 h-12 rounded-md elevation-1 text-white bg-newsletterbtn border-2 border-newsletterbtn hover:bg-newsletterbtnhover hover:border-newsletterbtnhover active:border-white active:shadow-innerbig xsm:place-self-center sm:place-self-center md:place-self-center xmd:place-self-center"  >

                Avanti

              </button>}

            {/* --- END NAME AND SUBJECT FIELDS --- */}

            {/* --- START SEND TO FIELD --- */}

            {step >= 2 &&
              <div className="flex flex-col gap-y-2">
                <div className="flex">
                  <p className="text-xl text-newslettertextcolour font-semibold text-left">
                    Liste Clienti Destinatarie
                  </p>
                </div>

                <div className="flex flex-row items-center w-full">

                  <MailingChecklist handleSelectTemplates={handleSelectTemplates} allLists={allLists} SetFocus={SetFocus} chosenLists={chosenLists} onListChosen={handleListsChosen} />

                </div>

              </div>
            }
            {/* --- END SEND TO FIELD --- */}
          </div>

        </div >

        {/* --- START MAILING LIST --- */}

        < div className="flex flex-col w-full justify-center items-center gap-y-6" >

        </div >

        {/* --- END MAILING LIST --- */}

        {/* --- END CAMPAIGN FIELDS --- */}

        {/* --- START BEETEMPLATE PART --- */}

        {
          step === 3 &&
          <div className="flex flex-col w-full items-center gap-y-8">

            {/* --- START BEETEMPLATE SELECTION --- */}

            <p className="text-newslettertextcolour font-semibold text-2xl text-left">
              Crea la tua mail partendo da un modello grafico
            </p>

            <div className="flex flex-row gap-x-6 w-full justify-center items-center mx-auto">
              <button
                className={`w-fit ${isActive ? "bg-newsletterbtn text-white" : "bg-white text-newslettertextcolour"} h-10 px-4 py-2   border-2 border-newslettertextcolour rounded-md  `}
                onClick={handleSelectTemplates}
              // disabled={isHandlingTemplates}
              >
                {isHandlingTemplates ? <Loader /> : "Seleziona un Template"}
              </button>

              <button
                className={`w-fit ${isActive ? "bg-white text-newslettertextcolour" : "bg-newsletterbtn text-white"}  h-10 px-4 py-2  border-2 border-newslettertextcolour rounded-md`}
                onClick={handleSelectSavedTemplates}
              // disabled={isLoadingUserTemplates}
              >
                {isLoadingUserTemplates ? <Loader /> : "Utilizza una Campagna Precedente"}
              </button>
            </div>
            <TemplatesModal
              handleTemplateSelected={handleTemplateSelected}
              templatesToShow={templatesToShow}
            />
            {/* --- END BEETEMPLATE SELECTION --- */}
          </div>
        }
      </div >

      {/* --- START BEEPLUGIN TEMPLATE --- */}

      < div className="w-full flex flex-col gap-y-2" >
        {selectedTemplateJson && (
          <>
            <BeeComponent
              template={selectedTemplateJson}
              setIsSaving={setIsBeeSaving}
              isSaving={isBeeSaving}
              setJson={setJson}
              setHtml={setHtml}
              setImage={setImage}
              imageWidth={1080}
              onImageCreated={handleImageCreated}
            />
            <button
              className="bg-newsletterbtn rounded-md w-fit h-10 px-4 py-2 mx-auto text-white hover:bg-newsletterbtnhover hover:border-newsletterbtnhover elevation-1"
              onClick={handleSaveClick}
            >
              Vedi l'anteprima e conferma
            </button>

            {saveError && <p className="text-red-600">{saveError}</p>}

            {isCreatingImage && <Loader className="my-2" />}
            {image && (
              <img
                src={image}
                className="w-[800px] md:w-full xmd:w-full self-center mx-auto"
                alt=""
              />
            )}
      
            {flags.newsletterEmailDraft && json && image && (
              <button
                className="bg-newsletterbtn rounded-md w-fit h-10 px-4 py-2 mx-auto text-white hover:bg-newsletterbtnhover hover:border-newsletterbtnhover elevation-1"
                onClick={handleSaveDraftClick}
                disabled={isSavingDraft}
              >
                {isSavingDraft ? <Loader /> : "Salva in Bozze"}
              </button>
            )}
          </>
        )
        }
      </div >

      {/* --- END BEEPLUGIN TEMPLATE --- */}

      {/* --- END BEETEMPLATE PART --- */}

      {
        notes && subject && chosenLists.length !== 0 && selectedTemplateJson && hasClickedSave &&
        <div className="flex w-full justify-center items-center">
          {/* --- START SCHEDULING OPTION --- */}

          <button
            onClick={handleOpenPublishModal}
            className="bg-newsletterbtn rounded-md w-fit h-10 px-4 py-2 my-7 mx-auto text-white hover:bg-newsletterbtnhover hover:border-newsletterbtnhover elevation-1"
          >
            Programma la Campagna
          </button>

          {/* --- END SCHEDULING OPTION --- */}
        </div>
      }
    </>
  );
}

export default withLDConsumer()(CreateNewMail);