import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AnalyticsLineChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="79%" debounce={1}>
      <LineChart
        data={Object.values(data)[0]}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        
        {/*
        <Legend />
        */}

        <Line
          type="monotone"
          dataKey="Click"
          stroke="#00B17A"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="Posizione" stroke="#FC6371" 
          activeDot={{ r: 8 }}/>
        <Line type="monotone" dataKey="Visualizzazioni" stroke="#6392FF" 
          activeDot={{ r: 8 }}/>
        <Line type="monotone" dataKey="ctr" stroke="#FFA563" 
          activeDot={{ r: 8 }}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AnalyticsLineChart;
