import axios from "axios";
import { getUserData } from "./../../../Utility/getUserData";
import { baseUrl } from "../../../Utility/Api/Urls";
import * as ThemeCustomerAPI from "../../../Utility/Api/ThemesCustomerAPI";

const baseEndpoint = `https://${process.env.REACT_APP_DASHBOARD_PREFIX}${baseUrl()}${process.env.REACT_APP_DASHBOARD_API_PATH}`;

const getAuthConfig = () => ({
  headers: { authorization: `Bearer ${getUserData().token}` },
});

const createError = error => error.response?.data || { error: error.message };

/**
 * Gets all completed newsletters
 * @param {Number} limit Number of items to fetch
 * @param {Number} skip Number of items to skip from start
 * @returns Array of newsletter summary objects
 */
export const getCompletedNewsletters = async (limit = 10, skip = 0) =>
  getNewsletters("completed", 10000, 0);

/**
 * Gets all draft newsletters
 * @param {Number} limit Number of items to fetch
 * @param {Number} skip Number of items to skip from start
 * @returns Array of newsletter summary objects
 */
export const getDraftNewsletters = async (limit = 10, skip = 0) =>
  getNewsletters("draft", 1000, 0);

/**
 * Gets all completed or draft newsletters
 * @param {String} status Status of newsletter to fetch
 * @param {Number} limit Number of items to fetch
 * @param {Number} skip Number of items to skip from start
 * @returns Array of newsletter summary objects
 */
const getNewsletters = async (status, limit, skip) => {
  try {
    const query = `?status=${status}&limit=${limit}&skip=${skip}`;
    // const url = `${baseEndpoint}/subscribersNewsletters${query}`;
    const url = `${baseEndpoint}/newsletters${query}`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Gets a newsletter's complete data
 * @param {Number} id Id of the newsletter
 * @returns Newsletter object
 */
export const getNewsletter = async id => {
  try {
    const url = `${baseEndpoint}/newsletters/${id}`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Creates a newsletter
 * @param {Object} body Data of the newsletter to create
 * @returns Object with newsletter id and success message.
 */
export const createNewsletter = async body => {
  try {
    // const url = `${baseEndpoint}/subscribersNewsletters`;
    const url = `${baseEndpoint}/newsletters`;
    const result = await axios.post(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Creates automated newsletter
 * @param {Object} body Data of the newsletter to create
 * @returns Object with newsletter id and success message.
 */
export const createAutomatedNewsletter = async body => {
  try {
    const url = `${baseEndpoint}/automatedNewsletters`;
    const result = await axios.post(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * updates a newsletter
 * @param {Number} id Id of the newsletter to update
 * @param {Object} body Data of the newsletter to update
 * @returns Object with newsletter id and success message.
 */
export const updateNewsletter = async (id, body) => {
  try {
    const url = `${baseEndpoint}/newsletters/${id}`;
    const result = await axios.put(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Deletes a newsletter
 * @param {Number} id Id of the newsletter
 * @returns Object with newsletter id and success message.
 */
export const deleteNewsletter = async id => {
  try {
    const url = `${baseEndpoint}/newsletters/${id}`;
    const result = await axios.delete(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Sends a newsletter
 * @param {Number} id Id of the newsletter
 * @returns Send result object.
 */
export const sendNewsletter = async id => {
  try {
    const url = `${baseEndpoint}/newsletters/${id}/send`;
    const result = await axios.post(url, null, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Schedules a newsletter
 * @param {Number} id Id of the newsletter
 * @param {String} date Date to schedule newsletter
 * @returns Schedule result object.
 */
export const scheduleNewsletter = async (id, date) => {
  try {
    const scheduleDate = date.toISOString();
    const url = `${baseEndpoint}/newsletters/${id}/schedule?sendingDate=${scheduleDate}`;
    const result = await axios.post(url, null, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Gets all mailing lists
 * @returns Array of mailing lists summary objects
 */
export const getMailingLists = async () => {
  try {
    const url = `${baseEndpoint}/mailingLists`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Get a mailing list
 * @returns Mailing list object
 */
export const getMailingList = async id => {
  try {
    const url = `${baseEndpoint}/mailingLists/${id}`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Deletes a mailing list
 * @returns Mailing list object
 */
export const deleteMailingList = async id => {
  try {
    const url = `${baseEndpoint}/mailingLists/${id}`;
    const result = await axios.delete(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Creates a mailing list
 * @param {Object} body Data of the mailing list to be created
 * @returns Created mailing list
 */
export const createMailingList = async body => {
  try {
    const url = `${baseEndpoint}/mailingLists`;
    const result = await axios.post(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Updates a mailing list
 * @param {Number} id Id of the mailing list
 * @param {Object} body Data of the mailing list to be updated
 * @returns Updated mailing list
 */
export const updateMailingList = async (id, body) => {
  try {
    const url = `${baseEndpoint}/mailingLists/${id}`;
    const result = await axios.put(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Adds customers to a mailing list
 * @param {Number} id Id of the mailing list
 * @param {Object} body Data of the mailing list to be updated
 * @returns Updated mailing list
 */
export const addCustomersToMailingList = async (id, body) => {
  try {
    const url = `${baseEndpoint}/mailingLists/${id}/customers`;
    const result = await axios.put(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Gets all customers
 * @returns Array of customer objects
 */
export const getCustomers = async () => {
  try {
    const url = `${baseEndpoint}/customers/any`;

    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Updates a customer
 * @param Object Data to update for customer
 * @returns Customer object
 */
export const updateCustomer = async body => {
  try {
    const url = `${baseEndpoint}/customers`;
    const result = await axios.put(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Deletes a customer
 * @returns Deletion success object
 */
export const deleteCustomer = async( listid,customerid) => {
  try {
    const url = `${baseEndpoint}/mailingLists/delete/${listid}/customers/${customerid}`;
    const result = await axios.delete(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Gets the token used for the email creator plugin
 * @returns Token object.
 */
export const getPluginToken = async () => {
  try {
    const url = `${baseEndpoint}/bee/token`;
    const result = await axios.get(url, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Gets a newsletter's preview image
 * @param {Object} body Data of the body request
 * @returns Base64-encoded string
 */
export const getNewsletterImage = async body => {
  try {
    const url = `${baseEndpoint}/bee/image`;
    const result = await axios.post(url, body, getAuthConfig());

    if (!result.data || result.data?.Error || result.data?.error)
      throw new Error(result.data?.Error || result.data?.error);

    return result.data;
  } catch (error) {
    return createError(error);
  }
};

/**
 * Get subsribers
 * @param {Object} body Data of the body request
 * @returns Base64-encoded string
 */
export const getSubscribers = async () => {
  try {
    const { data: systemCustomers } = await ThemeCustomerAPI.list();
    const { data: subscriberList } = await ThemeCustomerAPI.subscribersList();

    if (!subscriberList || !systemCustomers)
      throw new Error("No data recevied");

    const subscribers = subscriberList.map(s => {
      const systemData = systemCustomers.find(sc => sc.email === s.email);
      return {
        ...s,
        firstName: systemData.firstName,
        lastName: systemData.lastName,
      };
    });

    return subscribers;
  } catch (error) {
    console.error(error);
  }
};
