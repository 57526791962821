import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export function LinearProgressWithLabelRed(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#D02A2A" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <p className="text-[#D02A2A] font-normal text-sm">{`${Math.round(
                    props.value,
                )}%`}</p>
            </Box>
        </Box>
    );
}

export function LinearProgressWithLabelPurple(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#4B2AD0" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <p className="text-[#4B2AD0] font-normal text-sm">{`${Math.round(
                    props.value,
                )}%`}</p>
            </Box>
        </Box>
    );
}

export function LinearProgressWithLabelGreen(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#2AD044" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <p className="text-[#2AD044] font-normal text-sm">{`${Math.round(
                    props.value,
                )}%`}</p>
            </Box>
        </Box>
    );
}

export function LinearProgressRed(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#D02A2A" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
        </Box>
    );
}

export function LinearProgressPurple(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#4B2AD0" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
        </Box>
    );
}

export function LinearProgressGreen(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: "#2AD044" }}>
                <LinearProgress variant="determinate" color="inherit" {...props} />
            </Box>
        </Box>
    );
}
export function LinearProgressCustom({ value, color }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, color: color }}>
                <LinearProgress variant="determinate" color="inherit" value={value} />
            </Box>
        </Box>
    );
}
