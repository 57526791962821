import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "../../Assets/Icons/DeleteIcon.svg";
import DeleteAlert from "./DeleteAlert";

const DeleteAlertButton = ({
  message,
  onClose,
  onConfirm,
  onReject,
  renderButton,
  buttonDisabled,
  subTitle
}) => {
  const [isModalOpen, setIsOpen] = useState(false);

  const handleOpenModal = e => {
    e.stopPropagation();
    setIsOpen(true);
  }

  const handleClose = e => {
    e.stopPropagation();

    setIsOpen(false);
    if (onClose) onClose();
  };

  const handleConfirm = (e) => {
    setIsOpen(false);
    if (onConfirm) onConfirm(e);
  };

  const handleReject = e => {
    e.stopPropagation();

    setIsOpen(false);
    if (onReject) onReject();
  };

  return (
    <>
      {renderButton && renderButton({ onClick: handleOpenModal })}

      {!renderButton && (
        <IconButton onClick={handleOpenModal} disabled={buttonDisabled}>
          <img src={DeleteIcon} alt="Delete Icon" />
        </IconButton>
      )}

      {isModalOpen && (
        <DeleteAlert
          subTitle={subTitle}
          isOpen={isModalOpen}
          message={message}
          onConfirm={handleConfirm}
          onClose={handleClose}
          onReject={handleReject}
        />
      )}
    </>
  );
};

export default DeleteAlertButton;